import { API, httpParamsSerialize } from "./api";
import { ProposalsRequestParams, VotingParams, SkippingVoteParams } from "../types/Voting.interface"

const baseUrl = "/api/voting";


const VotingService = {
  getProposalsByStageId: (params: ProposalsRequestParams) => {
    return API.get(`${baseUrl}/getProposals?${httpParamsSerialize(params)}`).then(({ data }) => {
      return data;
    });
  },
  getProposalFolderCounters: (stageId: string) => {
    return API.get(`${baseUrl}/counters?stageId=${stageId}`).then(({ data }) => {
      return data;
    });
  },
  getProposalById: (id: string) => {
    return API.get(`${baseUrl}/getProposal?id=${id}`).then(({ data }) => {
      return data;
    });
  },
  vote: (params: VotingParams) => {
    return API.post(`${baseUrl}/vote`, params).then(({ data }) => {
      return data;
    });
  },
  skipVote: (params: SkippingVoteParams) => {
    return API.post(`${baseUrl}/skip`, params).then(({ data }) => {
      return data;
    });
  },
};

export default VotingService;
