import { Loader } from "@crowd/ui-kit";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import BlogService from "../services/blogService";
import CommentService from "../services/commentService";
import DiscussionService from "../services/discussionService";
import ProposalService from "../services/proposalService";
import StageService from "../services/stageService";
import { getUrlParam } from "../utils/urlUtils";

const legacyRoutes = [
  <Route
    path="/projdisc"
    component={() => {
      const stageId = getUrlParam("stageId", window.location.search);
      return stageId && <Redirect to={`/discussion/${stageId}`} />;
    }}
    key={"projdisc"}
  />,

  <Route
    exact
    path="/generation"
    component={() => {
      const stageId = getUrlParam("stageId", window.location.search);
      return stageId && <Redirect to={`/generation/${stageId}`} />;
    }}
    key={"generation"}
  />,

  <Route
    exact
    path="/voting/incoming"
    component={() => {
      const stageId = getUrlParam("stageId", window.location.search);
      return stageId && <Redirect to={`/voting/${stageId}`} />;
    }}
    key={"voting"}
  />,

  <Route exact path="/stage" render={() => <StageRoute />} key={"stage"} />,

  <Route
    exact
    path="/users/user/proposal"
    render={() => <ProposalRoute />}
    key={"propose"}
  />,

  <Route
    exact
    path="/users/user/proposals"
    component={() => {
      const userId = getUrlParam("userId", window.location.search);
      return userId && <Redirect to={`/users/user/proposals/${userId}`} />;
    }}
    key={"proposals"}
  />,

  <Route
    exact
    path="/discussion"
    render={() => {
      const commentId = getUrlParam("commentId", window.location.search);

      return commentId ? (
        <DiscussionCommentRoute commentId={commentId} />
      ) : (
        <DiscussionPlainRoute />
      );
    }}
    key={"discussion"}
  />,

  <Route
    exact
    path="/redirect/notifications/post"
    render={() => <NotificationsRoute />}
    key={"redirect"}
  />,

  <Route
    exact
    path="/blogs"
    render={() => <Redirect to={`/`} />}
    key={"project_diary_id"}
  />,

  <Route
    exact
    path="/nodes/node"
    render={() => <Redirect to={`/nodes`} />}
    key={"nodes"}
  />,

  <Route
    exact
    path="/personal/comments"
    render={() => <Redirect to={`/profile/comments`} />}
    key={"comments"}
  />,

  <Route
    exact
    path="/personal/proposals"
    render={() => <Redirect to={`/profile/ideas`} />}
    key={"ideas"}
  />,

  <Route
    path="/personal/proposal"
    render={() => <ProposalRoute />}
    key={"idea"}
  />,

  <Route
    exact
    path="/personal/profile"
    render={() => <Redirect to={`/profile`} />}
    key={"profile"}
  />,

  <Route
    exact
    path="/personal/favorites"
    render={() => <Redirect to={`/profile/favorites`} />}
    key={"favorites"}
  />,

  <Route
    exact
    path="/personal/discussions"
    render={() => <Redirect to={`/profile/blog`} />}
    key={"blog"}
  />,

  <Route
    exact
    path="/personal/notifications"
    render={() => <Redirect to={`/profile/notifications`} />}
    key={"notifications"}
  />,

  <Route
    exact
    path="/personal/mail/inbox"
    render={() => <Redirect to={`/profile/mail`} />}
    key={"inbox"}
  />,

  <Route
    exact
    path="/personal/blog"
    render={() => <Redirect to={`/profile/blog`} />}
    key={"pblog"}
  />,
];

export default legacyRoutes;

const mapStageType = {
  PROJECT_DISCUSSION: "discussion",
  SIMPLE_DISCUSSION: "discussion",
  EXPERT_SELECTION: "selection",
};
const StageRoute = () => {
  const [stage, setStage] = useState(null);

  useEffect(() => {
    const stageId = getUrlParam("id", window.location.search);
    StageService.getStages(stageId).then((response) => {
      const stage = response.data;
      setStage(stage);
    });
  }, []);

  const getRoute = () => {
    if (!stage) return <Loader />;
    if (stage.type === "EXPERT_SELECTION")
      return (window.location.href = `/selection/incoming?stageId=${stage.id}`);
    return (
      <Redirect to={`/${mapStageType[stage.type] || stage.type}/${stage.id}`} />
    );
  };

  return <Route path={"/stage"} render={(props) => getRoute()} />;
};

const NotificationsRoute = () => {
  const [post, setPost] = useState(null);

  useEffect(() => {
    const postId = getUrlParam("id", window.location.search);
    BlogService.getBlogPost(postId).then((response) => {
      setPost(response.data);
    });
  }, []);

  const getRedirect = (post) => {
    if (post.blogId === "project_diary_id") {
      return (
        <Redirect
          to={`/actual?date=${dayjs(post?.createdTimestamp).format(
            "DD.MM.YYYY"
          )}`}
        />
      );
    } else {
      return <Redirect to={`/users/user/post/${post.author.id}/${post.id}`} />;
    }
  };

  return <>{post ? getRedirect(post) : <Loader />}</>;
};

const ProposalRoute = () => {
  const [proposal, setProposal] = useState(null);

  useEffect(() => {
    const proposalId =
      getUrlParam("proposalId", window.location.search) ||
      getUrlParam("id", window.location.search);
    ProposalService.getById(proposalId).then((response) => {
      setProposal(response.data);
    });
  }, []);

  return (
    <>
      {proposal ? (
        <Redirect
          to={`/users/user/proposal/${proposal.authorId}/${proposal.id}`}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

const DiscussionCommentRoute = (props) => {
  const commentId = props.commentId;
  const [comment, setComment] = useState(null);
  const [discussion, setDiscussion] = useState(null);

  useEffect(() => {
    commentId &&
      CommentService.getById(commentId)
        .then((response) => {
          let comment;
          const helper = (comments) => {
            comments.forEach((_comment) => {
              if (_comment.id === commentId) return (comment = _comment);
              if (!_comment.responses) return;
              return helper(_comment?.responses);
            });
          };

          helper(response.data);

          if (comment.stageId) {
            setComment(comment);
          } else {
            DiscussionService.getById(comment.discussionId).then((response) => {
              setDiscussion(response.data);
              setComment(comment);
            });
          }
        })
        .catch((err) => alert(err.message));
  }, []);

  const getRedirLink = () => {
    if (comment.stageId)
      return `/discussion/${comment?.stageId}/${comment?.id}?navigate=true`;

    return `/users/user/post/${discussion.author.id}/${discussion.ownerId}/${comment?.id}?navigate=true`;
  };

  return <>{comment ? <Redirect to={getRedirLink()} /> : <Loader />}</>;
};

const DiscussionPlainRoute = () => {
  const [discussion, setDiscussion] = useState(null);
  const discussionId = getUrlParam("id", window.location.search);

  useEffect(() => {
    DiscussionService.getById(discussionId).then((response) => {
      setDiscussion(response.data);
    });
  }, []);

  const getRedirLink = () => {
    if (discussion.stageId) return `/discussion/${discussion.stageId}`;
    return `/users/user/post/${discussion.author.id}/${discussion.ownerId}`;
  };

  return discussion ? <Redirect to={getRedirLink()} /> : <Loader />;
};
