import { User } from "../types/User.interface";

const userMock: User = {
  acceptedProposals: null,
  accountType: "NATIVE",
  atLeastParticipant: false,
  birthYear: null,
  blocked: false,
  blockingExpires: null,
  chiefExpert: false,
  commentCount: null,
  complaints: null,
  controlAnswer: null,
  controlQuestion: null,
  email: "_-1",
  emailVerified: true,
  exParticipant: false,
  expert: false,
  expertOrStaff: false,
  facilitator: false,
  projectFacilitator: false,
  firstName: "anonymous",
  frontman: false,
  gender: null,
  highestRole: "POTENTIAL",
  id: "_-1",
  lastActivityDate: null,
  lastName: "anonymous",
  main: false,
  mainUserId: null,
  manager: false,
  moscowDistrict: null,
  moscowRegion: null,
  needToCompleteQuestionnaire: false,
  online: false,
  partiallyFilled: false,
  participant: false,
  permissions: [],
  personalPostCount: null,
  phone: null,
  pictureId: "undefined",
  position: null,
  projectBoss: false,
  roles: [],
  ssoid: null,
  submittedProposals: null,
  teamMember: false,
  warnings: null,
  passwordLastChanged: null,
  passwordExpired: null,
  loggedIn: null,
  userType: null,
};

export { userMock };
