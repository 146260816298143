import { ProposalInterface } from "../../../../../types/Proposal.interface";
import ClosingCard from "../../../../presentational/Cards/ClosingCard/ClosingCard";

interface TProps {
  idea: ProposalInterface;
}

export function SelectionIdeaForwardComment({ idea }: TProps) {
  return (
    <ClosingCard isOpened isSmall type="gray" className="selection-idea__forwarded-card">
      <label className="selection-page__card-label-info">Причина передачи</label>
      <div className="break-word">{idea.comment}</div>
    </ClosingCard>
  );
}
