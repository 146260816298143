import { useDispatch, useSelector } from "react-redux";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { Button } from "@crowd/ui-kit";
import {
  SELECTION_SET_IS_TAG_EDITING,
  SELECTION_SET_SELECTED_TAGS_FOR_COMBINING,
  SELECTION_SET_IDEAS_FOR_COMBINING,
  selectionSetFilter,
} from "../../../../../store/actions/SelectionActions";
import { ProposalTag } from "../../../../../types/Proposal.interface";
import { EPopupSide } from "../../../../presentational/Popup/Popup.constants";
import PopupSearchTable from "../../../../presentational/PopupSearchTable/PopupSearchTable";
import Tooltip from "../../../../presentational/Tooltip/Tooltip";
import { SelectionTagSwitcher } from "../SelectionTagSwitcher/SelectionTagSwitcher";
import {
  selectionFilterSelector,
  selectionIsTagEditingSelector,
  selectionSelectedTagsForCombiningSelector,
  selectionTagsSelector,
} from "../../../../../store/selectors/selection";
import { useMemo, useState } from "react";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { TableProps } from "../../../../presentational/Table/Table";
import ProposalService from "../../../../../services/proposalService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { profileUserDetailsSelector } from "../../../../../store/selectors/profile";
import { useSelectionFinalize } from "../../hooks/hooks";
import { useSelectionParams } from "../../helpers/params";
import "./SelectionTagFilter.scss";

const tagsColumns: TableProps<keyof ProposalTag>["columns"] = [
  {
    header: "Название",
    accessor: "value",
    renderValue: ({ value }) => <span className="selection-page__table-primary">{value}</span>,
    sortEnabled: true,
    className: "selection-tag-filter__table-name-column",
  },
  {
    header: <span className="ui-icon ui-icon-idea" />,
    accessor: "proposalCount",
    sortEnabled: true,
    className: "selection-tag-filter__table-proposal-count-column",
  },
  {
    header: <span className="ui-icon ui-icon-sports_score_black_24dp" />,
    accessor: "finalized",
    renderValue: ({ value }) => value && <span className="ui-icon ui-icon-done-symbol selection-page__table-icon" />,
    sortEnabled: true,
    className: "selection-tag-filter__table-finalized-column",
  },
  {
    header: "Автор главной идеи",
    accessor: "mainProposalAuthor",
    renderValue: ({ value }) => `${value.firstName} ${value.lastName}`,
    sortEnabled: true,
    className: "selection-tag-filter__table-author-column",
  },
];

export function SelectionTagFilter() {
  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const { stageId } = useSelectionParams();

  const tags = useSelector(selectionTagsSelector);
  const filter = useSelector(selectionFilterSelector);
  const selectedTagsForCombining = useSelector(selectionSelectedTagsForCombiningSelector);
  const isTagEditing = useSelector(selectionIsTagEditingSelector);
  const { chiefExpert } = useSelector(profileUserDetailsSelector);

  const [tagsFilterOpened, setTagsFilterOpened] = useState(false);
  const [tagsSelectCombiningOpened, setTagsSelectCombiningOpened] = useState(false);

  const tagFilter = useMemo<ProposalTag>(() => tags?.find(({ id }) => id === filter.tagId), [tags, filter.tagId]);
  const tagsForCombining = useMemo(() => (tagFilter ? tags.filter((tag) => tag !== tagFilter) : tags), [tags, tagFilter]);

  const finilize = useSelectionFinalize();

  const unite = async () => {
    setTagsSelectCombiningOpened(false);
    try {
      const data = await Promise.all(
        [tagFilter, ...selectedTagsForCombining].map(async (tag) => ProposalService.selectionGetMainProposal(stageId, tag.value))
      );
      const fail = data.find(({ status }) => status !== ApiStatusCode.OK);
      if (fail) {
        throw new Error(fail.message);
      }
      dispatch({ type: SELECTION_SET_IDEAS_FOR_COMBINING, payload: data.map(({ data }) => data) });
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    }
  };

  const handlePrevTagClick = () => {
    if (tagFilter) {
      const i = tags.indexOf(tagFilter);
      const pi = i >= 1 ? i - 1 : tags.length - 1;
      dispatch(selectionSetFilter({ ...filter, tagId: tags[pi].id }));
    }
  };

  const handleNextTagClick = () => {
    if (tagFilter) {
      const i = tags.indexOf(tagFilter);
      const ni = i < tags.length - 1 ? i + 1 : 0;
      dispatch(selectionSetFilter({ ...filter, tagId: tags[ni].id }));
    }
  };

  const handleAllGroupsClick = () => {
    dispatch(selectionSetFilter({ ...filter, tagId: undefined }));
    setTagsFilterOpened(false);
  };

  const handleTagSelect = (tag: ProposalTag) => {
    dispatch(selectionSetFilter({ ...filter, tagId: tag.id }));
    setTagsFilterOpened(false);
  };

  const handleOpenTagFilter = () => {
    setTagsFilterOpened(true);
  };
  const handleCloseTagFilter = () => {
    setTagsFilterOpened(false);
  };

  const handleTagSelectCombiningOpen = () => {
    setTagsSelectCombiningOpened(true);
  };
  const handleTagSelectCombiningClose = () => {
    setTagsSelectCombiningOpened(false);
  };

  return (
    <div>
      {tagFilter && isMobile && <SelectionTagSwitcher prevTag={handlePrevTagClick} nextTag={handleNextTagClick} />}
      <div className="selection-page__tag">
        <PopupSearchTable
          isOpen={tagsFilterOpened}
          onClose={handleCloseTagFilter}
          header={
            <span className={!!tagFilter ? "pointer" : ""} onClick={!!tagFilter ? handleAllGroupsClick : undefined}>
              Все группы ({tags?.length})
            </span>
          }
          columns={tagsColumns}
          data={tags}
          onRowClick={handleTagSelect}
          activeRow={tagFilter}
          bindTo="value"
          searchPlaceholder="Поиск группы"
          className="selection-tag-filter__table"
        >
          <Button
            classNames="selection-page__tag-name"
            text={!!tagFilter ? tagFilter.value : "Все группы"}
            type="text-button"
            onClick={handleOpenTagFilter}
          />
        </PopupSearchTable>

        {tagFilter ? (
          <>
            {chiefExpert && (
              <>
                <Tooltip
                  className={`selection-page__tag-control ${isTagEditing ? "selection-page__tag-control_active" : ""}`}
                  idx="selectop-tag-edit"
                  text="Редактировать"
                >
                  <Button
                    type="text-button"
                    text=""
                    icon="ui-icon ui-icon-edit"
                    onClick={() => dispatch({ type: SELECTION_SET_IS_TAG_EDITING, payload: true })}
                  />
                </Tooltip>
                <PopupSearchTable<keyof ProposalTag>
                  isOpen={tagsSelectCombiningOpened}
                  onClose={handleTagSelectCombiningClose}
                  header="Объединить с группой"
                  columns={tagsColumns}
                  data={tagsForCombining}
                  searchPlaceholder="Поиск группы"
                  bindTo="value"
                  selectEnabled
                  onSelect={(tags) => dispatch({ type: SELECTION_SET_SELECTED_TAGS_FOR_COMBINING, payload: tags })}
                  footer={
                    <>
                      <Button type="outlined" size="m" text="Отменить" onClick={handleTagSelectCombiningClose} />
                      <Button type="filled" size="m" text="Объединить" isDisabled={!selectedTagsForCombining.length} onClick={unite} />
                    </>
                  }
                  className="selection-page__tag-combine-popup"
                  side={EPopupSide.LEFT}
                >
                  <Tooltip
                    className={`selection-page__tag-control ${tagsSelectCombiningOpened ? "selection-page__tag-control_active" : ""}`}
                    idx="selectop-tag-unite"
                    text="Объединить"
                  >
                    <Button type="text-button" text="" icon="ui-icon ui-icon-add-group" onClick={handleTagSelectCombiningOpen} />
                  </Tooltip>
                </PopupSearchTable>
              </>
            )}
            <Tooltip
              className={`selection-page__tag-control ${tagFilter.finalized ? "selection-page__tag-control_active" : ""}`}
              idx="selectop-tag-finalize"
              text={tagFilter.finalized ? "Финализировано" : chiefExpert ? "Финализировать" : "Не финализировано"}
            >
              <Button
                type="text-button"
                text=""
                icon="ui-icon ui-icon-sports_score_black_24dp"
                onClick={() => {
                  if (chiefExpert && !tagFilter.finalized) {
                    finilize(tagFilter.id);
                  }
                }}
              />
            </Tooltip>
            {!isMobile && <SelectionTagSwitcher prevTag={handlePrevTagClick} nextTag={handleNextTagClick} />}
          </>
        ) : (
          <span className="selection-page__tag-counter">({tags?.length})</span>
        )}
      </div>
    </div>
  );
}
