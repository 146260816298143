export function getUrlParam(parameter, searchQuery?) {
  return new URLSearchParams(searchQuery).get(parameter);
}

export function setUrlParam(key, value, history, reload = false) {
  const params = new URLSearchParams(history.location.search);
  params.set(key, value);
  if (reload) {
    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  } else {
    history.replace({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  }
}

export function removeUrlParam(parameter, history, addToHistory?) {
  const queryParams = new URLSearchParams(history.location.search);
  if (queryParams.get(parameter)) {
    queryParams.delete(parameter);
    if (addToHistory) {
      history.push({
        pathname: history.location.pathname,
        search: queryParams.toString(),
      });
    } else {
      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString(),
      });
    }
  }
}

export function queryBuilder(query) {
  var queryBuilder = "?";

  Object.keys(query).forEach((key) => {
    queryBuilder += `${key}=${
      typeof query[key] === "string" ? query[key] : JSON.stringify(query[key])
    }&`;
  });

  return queryBuilder;
}
