import { Button, Input } from "@crowd/ui-kit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProposalService from "../../../../../services/proposalService";
import { showErrorToast } from "../../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import "./SelectionEditTagForm.scss";
import { selectionTagFilterSelector } from "../../../../../store/selectors/selection";
import { useSelectionOnEditTagSuccess } from "../../hooks/hooks";
import { useSelectionParams } from "../../helpers/params";

const SelectionEditTagForm = () => {
  const dispatch = useDispatch();

  const { stageId } = useSelectionParams();
  const tag = useSelector(selectionTagFilterSelector);

  const [value, setValue] = useState(tag.value);
  const [isSaving, setIsSaving] = useState(false);

  const handleEditTagSuccess = useSelectionOnEditTagSuccess();

  const save = async () => {
    setIsSaving(true);
    try {
      const { status, message } = await ProposalService.selectionRenameTag({
        stageId,
        tagId: tag.id,
        newValue: value,
      });

      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      handleEditTagSuccess();
    } catch (e: any) {
      dispatch(showErrorToast(e.message));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setValue(tag.value);
  }, [tag.value]);

  return (
    <div className="selection-edit-tag-form">
      <label>Редактирование группы</label>
      <Input value={value} onChange={setValue} />
      <Button text="Изменить" size="m" type="filled" isDisabled={!value} isLoading={isSaving} onClick={save} />
    </div>
  );
};

export default SelectionEditTagForm;
