import React from "react";
import { useParams } from "react-router-dom";

import CommentCardList from "../../../../../containers/Lists/CommentCardList/CommentCardList";
import { BlogPost } from "../../../../../../types/BlogPost.interface";

import "./PersonalPostComments.scss";

interface IPersonalPostCommentsProps {
  post: BlogPost;
}

const PersonalPostComments = ({ post }: IPersonalPostCommentsProps) => {
  const params = useParams();

  const formNavigationLinkFn = (item, shouldNavigate?) => {
    let link = `/users/user/post/${(params as any).userId}/${
      (params as any).postId
    }/${item.id}`;
    if (shouldNavigate) {
      link += "?navigate=true";
    }
    return link;
  };

  return (
    <div className="personal-post-comments">
      <CommentCardList
        post={post}
        emptyText={"Нет комментариев"}
        showPerPage={10}
        formNavigationLinkFn={formNavigationLinkFn}
        addComment={true}
      />
    </div>
  );
};

export default PersonalPostComments;
