import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { ProposalTypeEnum } from "../../../../types/Voting.interface";
import { Stage } from "../../../../types/Stage.interface";

import Pagination from "../../../presentational/Pagination/Pagination";
import VotingProposal from "../VotingProposal/VotingProposal";

import "./VotingProposalList.scss";

interface Props {
  proposals: any[];
  currentFolderId: ProposalTypeEnum;
  pagingData: any;
  stage: Stage;
  isExpertOrTeamMember: boolean;
  onPageClick: any;
  onInfiniteScroll: any;
  afterNewVote: Function;
  isLoading: boolean;
  isFiltered?: boolean;
}

const VotingProposalList = (props: Props) => {
  const getNoProposalsMessage = () => {
    if (props.currentFolderId === ProposalTypeEnum.PENDING) {
      if (props.isFiltered) {
        return "Нет идей, доступных для оценки. Выберите другое значение."
      } else {
        return "Вы оценили все идеи по теме";
      }
    } else if (props.currentFolderId === ProposalTypeEnum.VOTED) {
      return "У вас нет оцененных идей по теме";
    } else if (props.currentFolderId === ProposalTypeEnum.SKIPPED) {
      return "У вас нет отложенных идей по теме";
    }
  };

  const renderNoProposalsMessage = () => {
    return (
      <>
        <div className="proposal-list proposal-list--empty">
          <div className="proposal-list__message">
            {getNoProposalsMessage()}
          </div>
        </div>
      </>
    );
  };

  const getProposalList = (infinite?) => {
    const refreshProposals = () => {
      if (infinite) {
        props.onInfiniteScroll(infinite, true);
      } else {
        props.onPageClick(
          { selected: props.pagingData?.pageNumber || 0 },
          true
        );
      }
    };

    const afterVotingAction = (proposal, voted?) => {
      if (voted && !proposal.voted) props.afterNewVote();
      refreshProposals();
    };

    return props.proposals?.map((proposal) => (
      <VotingProposal
        proposal={proposal}
        stage={props.stage}
        currentFolder={
          infinite ? ProposalTypeEnum.PENDING : props.currentFolderId
        }
        key={proposal.id}
        afterVotingAction={(voted) => afterVotingAction(proposal, voted)}
        hideButtons={props.isExpertOrTeamMember}
      />
    ));
  };

  const getClassesForList = () => {
    let classList = "proposal-list";
    classList += " proposal-list--votable";
    classList += ` proposal-list--${props.currentFolderId.toLowerCase()}`;

    return classList;
  };

  const renderVotableProposalList = () => {
    if (props.currentFolderId === ProposalTypeEnum.PENDING) {
      return (
        <div className={getClassesForList()}>
          <InfiniteScroll
            key={props.pagingData?.totalElements}
            dataLength={props.proposals?.length || 0}
            next={props.onInfiniteScroll}
            hasMore={
              props.pagingData?.pageNumber < props.pagingData?.totalPages - 1
            }
            loader={<h4>Загрузка...</h4>}
            scrollThreshold={0.8}
          >
            {getProposalList(true)}
          </InfiniteScroll>
        </div>
      );
    } else {
      return (
        <>
          <div className={getClassesForList()}>{getProposalList()}</div>

          {props.pagingData?.totalPages && props.pagingData?.totalPages > 1 && (
            <Pagination
              totalPages={props.pagingData?.totalPages || 0}
              onPageChange={props.onPageClick}
              currentPage={props.pagingData?.pageNumber || 0}
              pageName="page"
            />
          )}
        </>
      );
    }
  };

  return !props.isLoading && props.proposals?.length === 0
    ? renderNoProposalsMessage()
    : renderVotableProposalList();
};

export default VotingProposalList;
