import { Button } from "@crowd/ui-kit";
import { FC, useState } from "react";
import { useOuterClick } from "../../../utils/hooks/useOnOutsideClick";
import { ActionTagItem, ActionTagProps } from "./ActionTag.interface";
import "./ActionTag.scss";

const ActionTag: FC<ActionTagProps> = ({ items, title }) => {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useOuterClick<HTMLDivElement>(() => setIsOpened(false));

  const toggleIsOpened = () => setIsOpened((prev) => !prev);

  const handleItemClick = (e: React.MouseEvent<HTMLLIElement>, item: ActionTagItem) => {
    e.stopPropagation();
    item.action();
    setIsOpened(false);
  };

  return (
    <div ref={ref} className="action-tag">
      <Button
        classNames="action-tag__btn"
        type="filled"
        text={`${title}${isOpened ? '<i class="ic ui-icon-chevron-up"></i>' : '<i class="ic ui-icon-chevron-down"></i>'}`}
        onClick={toggleIsOpened}
      />
      {isOpened && !!items.length && (
        <ul>
          {items.map((item, i) => (
            <li onClick={(e) => handleItemClick(e, item)} key={i}>
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ActionTag;
