import { Button } from "@crowd/ui-kit";
import { useMemo } from "react";
import { SelectionProposalCounters, SelectionFolders } from "../../../../../utils/constants/proposal";
import { useSelector } from "react-redux";
import {
  selectionCountProposalsSelector,
  selectionCountsSelector,
  selectionGenerationStageSelector,
  selectionTagsSelector,
} from "../../../../../store/selectors/selection";
import { profileUserDetailsSelector } from "../../../../../store/selectors/profile";
import { stageSelector } from "../../../../../store/selectors/stage";
import { useSelectionFinalize, useSelectionUnfinalize } from "../../hooks/hooks";

export function SelectionFinalizationButtons() {
  const generationStage = useSelector(selectionGenerationStageSelector);
  const { chiefExpert } = useSelector(profileUserDetailsSelector);
  const countProposals = useSelector(selectionCountProposalsSelector);
  const tags = useSelector(selectionTagsSelector);
  const counts = useSelector(selectionCountsSelector);
  const stage = useSelector(stageSelector);

  const finalize = useSelectionFinalize();
  const unfinalize = useSelectionUnfinalize();

  const canFinalizeAll = useMemo(
    () =>
      chiefExpert &&
      countProposals &&
      !countProposals.submitted &&
      !countProposals.forwardedTotal &&
      generationStage?.status === "FINISHED" &&
      tags?.some(({ finalized }) => !finalized),
    [counts?.[SelectionProposalCounters.SUBMITTED], counts?.[SelectionFolders.FORWARDED], generationStage?.status, tags, chiefExpert]
  );
  const canUnfinafileAll = useMemo(
    () => chiefExpert && !stage?.finishConfirmed && !!tags?.length && tags.every(({ finalized }) => finalized),
    [tags, stage?.finishConfirmed, chiefExpert]
  );

  return canFinalizeAll ? (
    <div className="selection-page__block-indent">
      <Button type="outlined" text="Финализировать все группы" size="m" onClick={() => finalize()} />
    </div>
  ) : (
    canUnfinafileAll && (
      <div className="selection-page__block-indent">
        <Button type="outlined" text="Отменить финализацию всех групп" size="m" onClick={() => unfinalize()} />
      </div>
    )
  );
}
