import { API } from "./api";
import { NotificationSet } from "../components/containers/Forms/NotificationConfugurationForm/NotificationConfugurationForm.interface";
import { NotificationsTypeEnum } from "../components/pages/Profile/MyNotifications/MyNotifications.interface";

const baseUrl = "/api/notifications";

export interface NotificationData {
  notifications: NotificationSet[];
  sound: boolean;
}

export interface GetQuery {
  page: number;
  size: number;
}

export interface ReadQuery {
  ids: string[];
}

export interface DeleteOneQuery {
  id: string;
}

export interface DeleteManyQuery {
  ids: string[];
}

const NotificationsService = {
  countUnread: () => {
    // Counter of notifications that user haven't read yet
    let url = `${baseUrl}/countUnread?exclude=NEW_COMMENT_REPLY`;
    return API.get(url).then((data) => {
      return data?.data;
    });
  },

  countAllUnread: () =>
    API.get(`${baseUrl}/countAllUnread`).then((data) => {
      return data?.data;
    }),

  getPaginated: (page, size, type = "") => {
    // Получение уведомлений с возможностью фильтрации.
    type = type === NotificationsTypeEnum.UNREAD ? "unreadOnly=true" : "";
    const param = `?${type}&page=${page}&size=${size}`;
    let url = `${baseUrl}/get${param}`;
    return API.get(url).then((response) => {
      const { data, paging } = response.data;
      return { data, paging };
    });
  },

  getSettings: () => {
    let url = `${baseUrl}/settings/get`;
    return API.get(url).then((response) => {
      const { data, paging } = response.data;
      return { data, paging };
    });
  },

  update: (params: NotificationData) => {
    return API.post(`${baseUrl}/settings/update`, params).then((data) => {
      return data;
    });
  },

  markRead: (id) => {
    return API.post(`${baseUrl}/markRead`, { id }).then((data) => {
      return data;
    });
  },

  markAllRead: (ids, exclude?) => {
    const query = exclude ? { exclude } : { ids };
    return API.post(`${baseUrl}/markAllRead`, query).then((data) => {
      return data;
    });
  },

  markClosed: (id) => {
    return API.post(`${baseUrl}/markClosed`, { id }).then((data) => {
      return data;
    });
  },
};

export default NotificationsService;
