// @flow

import React from "react";
import { Modal, Button } from "@crowd/ui-kit";

import "./SubmitForm.scss";
import EventsTrackModal from "../../../containers/EventsTrack/wrappers/helpers/EventsTrackModal";
import EventsTrackClickButton from "../../../containers/EventsTrack/wrappers/helpers/EventsTrackClickButton";

type Props = {
  isLoading?: Boolean;
  isSubmitting?: Boolean;
  isOpen: Boolean;
  onClose: () => any;
  onSubmit?: Function;
  top?: Boolean;
  submitText?: string;
  classNames?: any;
  root?: string;
  children?: any;
  focusTrapped?: boolean;
};

class SubmitForm extends React.Component<Props> {
  renderByKey = (key) => {
    return this.props?.children?.find((c) => c.key === key);
  };

  renderHead = () => {
    return (
      <div className="submit-form__head">{this.renderByKey("header")}</div>
    );
  };

  renderSubhead = () => {
    return (
      <div className="submit-form__subhead">{this.renderByKey("subhead")}</div>
    );
  };

  renderBody = () => {
    return <div className="submit-form__body">{this.renderByKey("body")}</div>;
  };

  getClassList = () => {
    let classlist = "submit-form";
    if (this.props.classNames) classlist += ` ${this.props.classNames}`;
    if (this.props.isSubmitting) classlist += " submit-form__submitting";
    return classlist;
  };

  renderFooter = () => {
    return (
      <div className="submit-form__footer">
        {this.renderByKey("footer")}
        <EventsTrackClickButton
          type="filled"
          text={this.props.submitText || "Отправить"}
          size="m"
          isLoading={this.props.isSubmitting}
          onClick={this.props.onSubmit}
          params={{
            partial: true,
            rememberPrev: true,
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <EventsTrackModal
        classNames={this.getClassList()}
        root={this.props.root}
        isOpen={!!this.props.isOpen}
        onClose={() => {
          this.props.onClose();
        }}
        blockScroll={false}
        adjustPaddings={[".app-wrapper", ".cr-header"]}
        adjustMargins={[".scroll-top-button"]}
        focusTrapped={this.props.focusTrapped}
      >
        <React.Fragment key="header">{this.renderHead()}</React.Fragment>

        <React.Fragment key="subhead">{this.renderSubhead()}</React.Fragment>

        <React.Fragment key="body">{this.renderBody()}</React.Fragment>

        <React.Fragment key="footer">{this.renderFooter()}</React.Fragment>
      </EventsTrackModal>
    );
  }
}

export default SubmitForm;
