import { ProposalInterface } from "../../../../../types/Proposal.interface";

export interface SelectionAcceptFormProps {
  type?: SelectionAcceptFormType;
  stageId: string;
  idea: ProposalInterface;
  currentTag?: string;
  onSuccess?: () => void;
  onClose: () => void;
}

export enum SelectionAcceptFormType {
  NEW = "NEW",
  EDIT = "EDIT",
}
