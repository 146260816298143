import React from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import PostAddFavorite from "../../../../../presentational/PostAddFavorite/PostAddFavorite";
import { RootState } from "../../../../../../types/State.interface";
import { BlogPost } from "../../../../../../types/BlogPost.interface";
import "./PersonalPostStatistic.scss";

const PersonalPostStatistic = () => {
  const commentCount: number = useSelector((state: RootState) => state.blog.activePostCommentCount);
  const favoriteCount: number = useSelector((state: RootState) => state.blog.activePostFavoriteCount);
  const post: BlogPost = useSelector((state: RootState) => state.blog.activePost);

  return (
    <div className="personal-post-statistic">
      <div className="personal-post-statistic__date-time">{dayjs(post?.createdTimestamp).format("DD.MM.YYYY HH:mm")}</div>

      <div className="flex">
        {post.commentsEnabled && post.active && !post.hidden && (
          <>
            <div className="personal-post-statistic__comment-count">
              <span className="ui-icon-comments ui-icon"></span>
              {commentCount} <div className="personal-post-statistic__comment-count__text">&nbsp;комментариев</div>
            </div>

            <div className="personal-post-statistic__favorite-count">
              <PostAddFavorite post={post} tooltipPlace="bottom" />
              {favoriteCount}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalPostStatistic;
