import { AttachmentPreview, Button, Modal } from "@crowd/ui-kit";
import React, { useEffect } from "react";
import "./PreviewModal.scss";

const PreviewModal = (props) => {
  useEffect(() => {
    //scroll to top
    setTimeout(() => {
      const modalEls: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".react-responsive-modal-container"
      );
      modalEls.forEach((el) => el.scrollTo({ top: 0 }));
    }, 100);
  }, []);

  const getTitle = () => {
    return props.children.props?.post?.title || "";
  };

  const hasTitle = () => Boolean(getTitle);

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      classNames={"preview-modal"}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <React.Fragment key="header">Предпросмотр</React.Fragment>

      <React.Fragment key="body">
        <div className="preview-modal__title">{getTitle()}</div>

        <div
          className="preview-modal__main"
          dangerouslySetInnerHTML={{
            __html: props.children.props?.post?.text || "",
          }}
        ></div>

        {props.children.props?.post.attachments?.length > 0 && (
          <div className="preview-modal__attachments scroll-x">
            {props.children.props?.post.attachments.map((attachment) => (
              <AttachmentPreview attachment={attachment} key={attachment.id} />
            ))}
          </div>
        )}
      </React.Fragment>

      <React.Fragment key="footer">
        <Button
          text="Закрыть"
          type="outlined"
          size={"m"}
          onClick={props.onClose}
        />
      </React.Fragment>
    </Modal>
  );
};

export default PreviewModal;
