import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { AppColorsEnum } from "../../../App.interface";
import {
  hideFooter,
  hideHeader,
  setAppColor,
} from "../../../store/actions/LayoutActions";

import "./NotStartedPage.scss";

const NotStartedPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));
    dispatch(hideFooter());
    dispatch(hideHeader());
  }, []);

  return (
    <div className="not-started-page">
      <h2>ПРОЕКТ ЕЩЁ НЕ ЗАПУЩЕН</h2>
    </div>
  );
};

export default NotStartedPage;
