import React, { useEffect, useState } from "react";
import CommonService from "../../../services/commonService";

// import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import "./LicensePage.scss";

const LicensePage = () => {
  const [license, setLicense] = useState("");
  useEffect(() => {
    fetchLicense();
  }, []);

  const fetchLicense = async () => {
    try {
      const res = await CommonService.getLicense();
      setLicense(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div
        className="license-wrapper"
        dangerouslySetInnerHTML={{ __html: license }}
      />
    </>
  );
};

export default LicensePage;
