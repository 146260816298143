import React, { useState } from "react";
import { Button, Checkbox, Input, Textarea, Radio, Select } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";

import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";

import { RootState } from "../../../../types/State.interface";
import { showOkInfoModal } from "../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { Questionnaire, QuestionnaireField, QuestionnaireFieldReg } from "../../../../types/Projects.interface";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import "./ProjectQuestionnaireForm.scss";

import { useEffect } from "react";
import ProjectService from "../../../../services/projectService";
import { isObject } from "../../../../utils";

const ProjectQuestionnaireForm = (props) => {
  const dispatch = useDispatch();
  const user: any = useSelector((state: RootState) => state.user.userDetails);
  const projectTitle = useSelector((state: RootState) => state.project?.current?.title);

  const [questionnaire, setQuestionnaire] = useState<Questionnaire>(null);
  const [questionnaireFields, setQuestionnaireFields] = useState<QuestionnaireField[]>([]);
  const [fieldValues, setFieldValues] = useState(null);

  const [validators, setValidators] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>();

  const setFieldValue = (newValue, fieldName, checkbox?, add?) => {
    const getNewArrayForCheckbox = (state, name) => {
      let newArray = state[name];
      if (add) {
        !newArray.includes(newValue) && newArray.push(newValue);
      } else {
        newArray = newArray.filter((value) => value !== newValue);
      }
      return newArray;
    };
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: checkbox ? getNewArrayForCheckbox(prevState, fieldName) : newValue,
    }));
  };

  useEffect(() => {
    getQuestionnaire();
  }, []);

  useEffect(() => {
    toggleFieldsOnConditions();
  }, [fieldValues]);

  const toggleFieldsOnConditions = () => {
    if (!questionnaireFields) return;
    const joinedFieldNames = questionnaireFields.map((field) => field.name).join(",");

    const valuesArr = questionnaireFields.map((field) => fieldValues[field.name]);

    questionnaireFields
      .filter((field) => !!field.condition)
      .forEach((field) => {
        try {
          const meetsConditions = new Function(joinedFieldNames, "return " + field.condition).apply(this, valuesArr);

          if (meetsConditions === field.hidden) {
            toggleFieldVisibily(meetsConditions, field.name);
          }

          if (!meetsConditions && fieldValues[field.name]) {
            setFieldValue("", field.name);
          }
        } catch (error) {
          if (!field.hidden) {
            toggleFieldVisibily(false, field.name);
          }

          if (fieldValues[field.name]) {
            setFieldValue("", field.name);
          }

          console.warn(error);
          dispatch(showErrorToast("Ошибка формирования анкеты"));
        }
      });
  };

  const toggleFieldVisibily = (visible, fieldName) => {
    setQuestionnaireFields((prev) => prev.map((field) => (field.name === fieldName ? { ...field, hidden: !visible } : field)));
  };

  const getQuestionnaire = () => {
    ProjectService.getQuestionnaire()
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          const fields = response.data?.fields || [];
          fields?.filter((field) => field.condition).forEach((field) => (field.hidden = true));

          setQuestionnaireFields(fields);

          const wrapCheckboxValueToArr = (field) => {
            let value = parseDefaultValue(field) || "";
            if (typeof value === "string") value = [value];
            return value;
          };

          setFieldValues((currentValues) => {
            const newValues = fields?.reduce((obj, field) => {
              obj[field.name] = field.type === "check" ? wrapCheckboxValueToArr(field) : parseDefaultValue(field) || "";
              return obj;
            }, {});
            return Object.assign({}, newValues, currentValues);
          });

          const { title, hint, submitMessage } = response.data;
          setQuestionnaire({ title, hint, submitMessage });
        } else {
          dispatch(showErrorToast(response.message));
        }
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  };

  const getFieldsForRequest = (fieldValues) => {
    const fields: QuestionnaireFieldReg[] = [];

    for (const [key, value] of Object.entries(fieldValues)) {
      // checkbox can store multiple values as array, those should be sent as separate fields with the same key (for backend validation to work)
      if (typeof value === "string") {
        if (value !== "")
          fields.push({
            id: key,
            value: value as string,
          });
      } else if (Array.isArray(value)) {
        value.forEach((val) => {
          if (val !== "")
            fields.push({
              id: key,
              value: val as string,
            });
        });
      } else if (isObject(value)) {
        const { value: _value } = value as any;
        if (_value) {
          fields.push({
            id: key,
            value: _value,
          });
        }
      }
    }

    return fields;
  };

  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);

    const fieldsForRequest: QuestionnaireFieldReg[] = getFieldsForRequest(fieldValues);

    if (fieldsForRequest && formValid()) {
      setSubmitting(true);

      ProjectService.registerQuestionnaire(fieldsForRequest)
        .then((response) => {
          if (response.status === ApiStatusCode.OK) {
            let afterSubmitText = `Добро пожаловать на проект «${projectTitle}». О ходе проекта вы можете узнать в разделе <a href='/nodes'>«График проекта»</a>.`;
            if (questionnaire.submitMessage) {
              afterSubmitText = questionnaire.submitMessage;
            }
            dispatch(showOkInfoModal(null, afterSubmitText));
            props.onClose();
          } else if (response.status === ApiStatusCode.QUESTIONNAIRE_ALREADY_SUBMITTED) {
            dispatch(showOkInfoModal(null, "Мы уже получили вашу анкету! Спасибо.", () => props.onClose()));
          } else {
            dispatch(showErrorToast(response?.message || "При обращении к серверу произошла ошибка."));
          }
        })
        .catch((err) => dispatch(showErrorToast(err.message)))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  // VALIDATION

  const addValidator = (validator) => {
    // пуш нужен потому что validators изначально будет пустым массивом для всех вызовов

    validators.push(validator);
    setValidators([...validators]);
  };

  const formValid = (): boolean => {
    const validateField = (validator): boolean => {
      const { required, value } = validator.input;

      if (!!required || (!required && validator.checkNotEmpty(value))) {
        return validator.isValid();
      } else {
        return true;
      }
    };

    const hasEmptyRequiredFields = questionnaireFields
      ?.filter((field) => field.required && !field.hidden && (field.type === "radio" || field.type === "select" || field.type === "check"))
      .some((field) => !fieldValues[field.name] || (Array.isArray(fieldValues[field.name]) && !fieldValues[field.name].length));

    return validators.every(validateField) && !hasEmptyRequiredFields;
  };

  // RENDER

  const parseDefaultValue = (field) => {
    const { defaultValue } = field;
    if (defaultValue) {
      const regExp = /\${([^)]+)}/;
      const matches = regExp.exec(defaultValue);

      if (matches) {
        const parameterName = matches[1];
        if (user && parameterName.toLowerCase().indexOf("region") !== -1) {
          return user.region;
        } else if (user && parameterName.toLowerCase().indexOf("district") !== -1) {
          return user.district;
        }
      } else {
        return defaultValue || (field.type === "check" ? [] : "");
      }
    } else {
      return field.type === "check" ? [] : "";
    }
  };

  const getValidateRules = (field) => {
    const validateRules: any = {};
    if (field.required) validateRules.notEmpty = true;
    if (field.length) validateRules.maxLength = field.length;
    return validateRules || null;
  };

  const getLabel = (htmlFor, text, required?) => {
    return (
      <label htmlFor={htmlFor} className="project-questionnaire-form__field__label">
        {text}
        <span className="project-questionnaire-form__field__label--required">{required ? "*" : ""}</span>
      </label>
    );
  };

  const getHint = (hint, classes = "") => {
    return <div className={"project-questionnaire-form__field__hint " + classes}>{hint}</div>;
  };

  const getInputField = (field) => {
    return (
      <>
        {getLabel(field.name, field.label, field.required)}

        <Input
          value={fieldValues[field.name]}
          isRequired={field.required}
          maxLength={field.length || null}
          placeholder={field.placeholder}
          hint={field.hint}
          shouldValidate={true}
          validateRules={getValidateRules(field)}
          onInitValidator={addValidator}
          onChange={(value) => setFieldValue(value, field.name)}
          showError={submitted}
        />
      </>
    );
  };

  const getTextareaField = (field) => {
    return (
      <>
        {getLabel(field.name, field.label, field.required)}

        <Textarea
          value={fieldValues[field.name]}
          placeholder={field.placeholder}
          hint={field.hint}
          maxLength={field.length || null}
          isRequired={field.required}
          onChange={(value) => setFieldValue(value, field.name)}
          shouldValidate={true}
          validateRules={getValidateRules(field)}
          onInitValidator={addValidator}
          showError={submitted}
        />
      </>
    );
  };

  const getRadioField = (field) => {
    return (
      <>
        {getLabel(field.name, field.label, field.required)}

        <div className="project-questionnaire-form__field__radio__options">
          {field.options?.map((option, index) => {
            return (
              <div className="project-questionnaire-form__field__radio__option" key={index}>
                <Radio
                  id={`${field.name}_${index}`}
                  label={option.name}
                  value={option.id}
                  onChange={(value) => setFieldValue(value, field.name)}
                  isSelected={fieldValues[field.name] === option.id}
                  isInvalid={submitted && field.required && !fieldValues[field.name]}
                />
              </div>
            );
          })}
        </div>

        {getHint(field.hint)}
      </>
    );
  };

  const getSelectField = (field) => {
    const items = field.options.map((option) => ({
      name: option.name,
      value: option.id,
    }));
    const defaultValue = parseDefaultValue(field);
    const defaultValueItem = defaultValue ? items.find((item) => item.value === defaultValue) : null;

    return (
      <>
        {getLabel(field.name, field.label, field.required)}

        <Select
          items={items}
          value={defaultValueItem || null}
          closeOnSelect={true}
          bindTo="name"
          placeholder={field.placeholder}
          onItemSelect={(value) => setFieldValue(value?.name, field.name)}
          isInvalid={submitted && field.required && !fieldValues[field.name]}
        />

        {getHint(field.hint)}
      </>
    );
  };

  const getCheckboxField = (field) => {
    const defaultValue = parseDefaultValue(field);
    const isChecked = (option) => {
      if (fieldValues[field.name]) {
        return fieldValues[field.name] === option.name || fieldValues[field.name].includes(option.name);
      } else if (defaultValue) {
        return fieldValues[field.name] === defaultValue;
      }
    };

    const isInvalid =
      submitted &&
      field.required &&
      (!fieldValues[field.name] || (Array.isArray(fieldValues[field.name]) && !fieldValues[field.name].length));

    return (
      <>
        {getLabel(field.name, field.label, field.required)}

        <div className="project-questionnaire-form__field__checkbox__options">
          {field.options?.map((option, index) => {
            return (
              <div className="project-questionnaire-form__field__checkbox__option" key={index}>
                <Checkbox
                  html={option.name}
                  onChange={(value) => setFieldValue(option.id, field.name, true, value)}
                  defaultValue={isChecked(option) || false}
                  isInvalid={isInvalid}
                />
              </div>
            );
          })}
        </div>

        {getHint(field.hint)}
      </>
    );
  };

  const getFields = () => {
    return questionnaireFields?.map((field, index) => {
      // use logic from crowdapp/platform/src/main/resources/static/lib/crowd/api/qGenerator.js
      // and validation and messages from crowdapp/platform/src/main/resources/static/lib/crowd/pages/questionnaire.js

      return (
        !field.hidden && (
          <div className="project-questionnaire-form__field" key={index}>
            {(() => {
              switch (field.type) {
                case "text":
                  return getInputField(field);
                case "textarea":
                  return getTextareaField(field);
                case "radio":
                  return getRadioField(field);
                case "select":
                  return getSelectField(field);
                case "check":
                  return getCheckboxField(field);
              }
            })()}
          </div>
        )
      );
    });
  };

  const getClassesForFormBody = () => {
    let classes = "project-questionnaire-form__body";

    if (submitting) {
      classes += " project-questionnaire-form__body-disabled";
    }

    return classes;
  };

  const renderContent = () => {
    return (
      <form onSubmit={submitForm} className="project-questionnaire-form">
        <div className="project-questionnaire-form">
          <div className="project-questionnaire-form__title" dangerouslySetInnerHTML={{ __html: questionnaire?.title || "" }}></div>

          {fieldValues && <div className={getClassesForFormBody()}>{getFields()}</div>}

          {getHint(questionnaire?.hint, "project-questionnaire-form__field__hint--questionnaire")}
        </div>
      </form>
    );
  };

  return (
    <SubmitForm
      isSubmitting={submitting}
      isOpen={props.isOpen}
      onClose={props.closeWithLogout}
      onSubmit={submitForm}
      root="project-questionnaire-form__root"
      classNames="project-questionnaire-form"
      focusTrapped={false}
    >
      <React.Fragment key="header">Анкета участника</React.Fragment>
      <React.Fragment key="body">{renderContent()}</React.Fragment>
      <React.Fragment key="footer">
        <Button size={"m"} type="outlined" text="Выйти" onClick={props.closeWithLogout} />
      </React.Fragment>
    </SubmitForm>
  );
};

export default ProjectQuestionnaireForm;
