import React from "react";
import ContentLoader from "react-content-loader";
import "./TimetableLoader.scss";

export default function TimetableLoader(props) {
  return (
    <div className="four-column-loader">
      <ContentLoader
        height="100%"
        width="100%"
        viewBox="0 0 800 575"
        gradientRatio={0.2}
        speed={2}
        {...props}
        backgroundColor="#e8e8e8"
        foregroundColor="#ecebeb"
        title="Загрузка..."
      >
        {/* titles */}

        <rect x="0" y="5%" rx="0" ry="0" width="25%" height="20" />
        <rect x="0" y="11%" rx="0" ry="0" width="100%" height="30" />
        <rect x="0" y="17%" rx="0" ry="0" width="100%" height="30" />
        <rect x="0" y="23%" rx="0" ry="0" width="100%" height="30" />
        <rect x="0" y="29%" rx="0" ry="0" width="100%" height="30" />
        <rect x="0" y="35%" rx="0" ry="0" width="100%" height="30" />

        <rect x="0" y="50%" rx="0" ry="0" width="25%" height="20" />
        <rect x="0" y="56%" rx="0" ry="0" width="100%" height="30" />
        <rect x="0" y="62%" rx="0" ry="0" width="100%" height="30" />
      </ContentLoader>
    </div>
  );
}
