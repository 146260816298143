import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./Chat.scss";
import { useDispatch, useSelector } from "react-redux";
import { lessenPage, setAppColor, showConfirmInfoModal, showInfoModal, widenPage } from "../../../../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../../../../App.interface";
import MessageCreatePanel from "./MessageCreatePanel/MessageCreatePanel";
import { ChatMessageRaw, ChatMessage, ChatMessageStatus } from "./chat.types";
import MessageViewWindow, { MessageWindowController } from "./MessageViewWindow/MessageViewWindow";
import PeerPanel from "./PeerPanel/PeerPanel";
import { RootState } from "../../../../../../types/State.interface";
import { useWebSocket } from "../../../../../containers/Wrappers/WebSocketProvider/WebSocketProvider";
import { useHistory, useParams } from "react-router";
import { ApiStatusCode, PagingResponseParameters } from "../../../../../../types/Common.interface";
import { showErrorToast, showSuccessToast } from "../../../../../../store/actions/ToastActions";
import { User } from "../../../../../../types/User.interface";
import { uuidv4 } from "../../../../../../utils";
import ChatService, { RemoveMessagePayload, SendMessagePayload } from "../../../../../../services/chat.service";
import { Loader } from "@crowd/ui-kit";
import { chatMessageRawToChatMessage } from "./chat.utils";
import { onEmptyChatCache, onHistoryChatMessages } from "../../../../../../store/actions/SocketActions";
import dayjs from "dayjs";
import { MessageRemoveType } from "./MessageBubble/MessageBubble";
import { useMediaQuery } from "react-responsive";
import { tabletWidth } from "../../../../../../utils/constants/widthConstants";
import { UpdateType } from "../../../../../../store/reducers/chat";

const MESSAGES_PER_PAGE = 20;
const HISTORY_FETCH_THRESHOLD = 100;

interface RouteParams {
  id: string;
}

const Chat: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: dialogId } = useParams<RouteParams>();
  const stompClient = useWebSocket();
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  const [sendingMessages, setSendingMessages] = useState<ChatMessageRaw[]>([]);
  const [peer, setPeer] = useState<User>(null);

  const updateType = useSelector<RootState>((state: RootState) => state.chat.updateType);
  const peerMessages = useSelector<RootState, ChatMessageRaw[]>((state: RootState) => {
    return state.chat.peerToMe?.[peer?.id] || [];
  });
  const myMessages = useSelector<RootState, ChatMessageRaw[]>((state: RootState) => state.chat.meToPeer?.[peer?.id] || []);
  const [messageWindowController, setMessageWindowController] = useState<MessageWindowController>(null);
  const isMessagesLoadingRef = useRef(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [messagePaging, setMessagePaging] = useState<PagingResponseParameters>(null);
  const [unreadMessage, setUnreadMessage] = useState<ChatMessage>(null);
  const lastRememberMessageRef = useRef(null);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    fetchDialogInfo(dialogId);
    return () => {
      lastRememberMessageRef.current = null;
      dispatch(lessenPage());
    }
  }, []);

  useEffect(() => {
    if (isTablet) {
      dispatch(widenPage());
    } else {
      dispatch(lessenPage());
    }
  }, [isTablet]);

  useEffect(() => {
    peer && fetchMessageHistory(dialogId, 0);
  }, [peer]);

  const messageList = useMemo(() => {
    const myMessagesIds = new Map(myMessages.map((message) => [message.id, true]));
    const filteredSendingMessages = sendingMessages.filter((sendingMessage) => !myMessagesIds.has(sendingMessage.id));
    const combinedMessages = [...myMessages, ...peerMessages, ...filteredSendingMessages];
    const sortedMessages = combinedMessages.sort((a, b) => dayjs(a.created).valueOf() - dayjs(b.created).valueOf());
    return sortedMessages.map((messageRaw) => chatMessageRawToChatMessage(messageRaw, currentUser, peer));
  }, [myMessages, peerMessages, sendingMessages, currentUser, peer]);

  // Навигация по сообщения
  useEffect(() => {
    const getFirstUnreadMessage = () => messageList.find(message => message.sender.id !== currentUser.id && !message.read);
  
    const handleUnreadMessage = (firstUnreadMessage) => {
      setUnreadMessage(firstUnreadMessage || null);
      if (firstUnreadMessage) {
        messageWindowController.scrollToMessageById(firstUnreadMessage.id);
      } else {
        messageWindowController.scrollToBottom();
      }
    };
  
    if (messageWindowController && messageList.length) {
      const firstUnreadMessage = getFirstUnreadMessage();
      
      switch (updateType) {
        case UpdateType.DELETE_MESSAGE:
          break;
        case UpdateType.READ_MESSAGE:
          setUnreadMessage(firstUnreadMessage || null);
          break;
        case UpdateType.NEW_MESSAGE:
          handleUnreadMessage(firstUnreadMessage);
          break;
        case UpdateType.LOAD_HISTORY_MESSAGES:
          if (firstUnreadMessage) {
            handleUnreadMessage(firstUnreadMessage);
          } else if (lastRememberMessageRef.current) {
            messageWindowController.scrollToMessageById(lastRememberMessageRef.current.id);
          } else {
            messageWindowController.scrollToBottom();
          }
          break;
        default:
          messageWindowController.scrollToBottom();
      }
    }
  }, [messageList, messageWindowController, currentUser, updateType, lastRememberMessageRef]);

  const fetchDialogInfo = async (id) => {
    try {
      const response = await ChatService.getDialogById({ id });
      if (response.status === ApiStatusCode.OK) {
        const { peer } = response.data;
        setPeer(peer);
      } else {
        handleNoDialogFound();
      }
    } catch (err) {
      handleNoDialogFound();
    }
  };

  const fetchMessageHistory = async (dialogId, page) => {
    if (isMessagesLoadingRef.current) return;
    isMessagesLoadingRef.current = true;
    
    if (page !== 0) {
      lastRememberMessageRef.current = messageList[0];
    }
    try {
      const response = await ChatService.getMessagesByDialogId({ dialogId, page, size: MESSAGES_PER_PAGE, sort: "created,desc" });
      if (response.status === ApiStatusCode.OK) {
        setMessagePaging(response.paging);
        const reversed = response.data.reverse();
        const mineMessages = reversed.filter((message) => message.senderId === currentUser.id);
        const peerMessages = reversed.filter((message) => message.senderId === peer.id);
        dispatch(
          onHistoryChatMessages({
            mineMessages: { id: peer.id, messages: mineMessages },
            peerMessages: { id: peer.id, messages: peerMessages },
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      isMessagesLoadingRef.current = false;
    }
  };

  const handleSendMessage = ({ text, attachments }: { text: string; attachments: any[] }) => {
    if (text.trim() !== "" || attachments.length) {
      const id = uuidv4();
      const payload: SendMessagePayload = {
        messageId: id,
        dialogId,
        senderId: currentUser.id,
        text,
        attachmentIds: attachments.map((file) => file.id),
      };

      const newMessageRaw = {
        id,
        text,
        senderId: currentUser.id,
        recipientId: peer?.id,
        attachments,
        status: ChatMessageStatus.SENDING,
      };
      setSendingMessages((messages) => [...messages, newMessageRaw]);
      lastRememberMessageRef.current = newMessageRaw;
      stompClient.send("/app/chat", {}, JSON.stringify(payload));
    }
  };

  const handleNoDialogFound = () => {
    dispatch(showErrorToast("Диалог не найден"));
    navigateToDialogList();
  };

  const getCurrentPage = useCallback(() => Math.floor(messageList.length / MESSAGES_PER_PAGE), [messageList.length]);

  const handleScroll = useCallback(
    (e) => {
      if (e.currentTarget) {
        const { scrollTop } = e.currentTarget;
        if (
          scrollTop <= HISTORY_FETCH_THRESHOLD &&
          !isMessagesLoadingRef.current &&
          messagePaging?.totalPages > 1 &&
          messagePaging?.pageNumber + 1 < messagePaging?.totalPages
        ) {
          fetchMessageHistory(dialogId, getCurrentPage());
        }
      }
    },
    [dialogId, getCurrentPage, messagePaging?.pageNumber, messagePaging?.totalPages]
  );

  const handleRemoveMessage = useCallback((message: ChatMessage, type: MessageRemoveType) => {
    const payload: RemoveMessagePayload = {
      id: message.id,
      toAll: type === MessageRemoveType.REMOVE_ALL,
      deletedByUserId: currentUser.id,
    };
    stompClient.send("/app/delete", {}, JSON.stringify(payload));
  }, []);

  const handleReadMessage = useCallback(
    (messages: ChatMessage[]) => {
      const payload = {
        readByUserId: currentUser.id,
        ids: messages.map((message) => message.id),
      };
      stompClient.send("/app/read", {}, JSON.stringify(payload));
    },
    [peer]
  );

  const handleRemoveDialog = () => {
    dispatch(
      showConfirmInfoModal("Удалить всю историю сообщений?", async () => {
        try {
          const response = await ChatService.deleteDialog({ id: dialogId });
          if (response.status === ApiStatusCode.OK) {
            navigateToDialogList();
            dispatch(onEmptyChatCache({peerId: peer.id}));
            dispatch(showSuccessToast("Сообщения успешно удалены"));
          }
        } catch (error) {
          console.log(error);
        }
      })
    );
  };

  const navigateToDialogList = () => history.push("/profile/mail");

  return (
    <div className="cr-chat">
      {peer ? (
        <>
          <PeerPanel
            peer={peer}
            onRemoveDialog={handleRemoveDialog}
            hasMessages={messageList.length > 0}
          />
          <MessageViewWindow
            onInit={setMessageWindowController}
            isLoading={isLoaderVisible}
            messageList={messageList}
            unreadMessage={unreadMessage}
            onScroll={handleScroll}
            onRemove={handleRemoveMessage}
            onRead={handleReadMessage}
          />
          <MessageCreatePanel onSend={handleSendMessage} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Chat;
