import { Button, Loader } from "@crowd/ui-kit";
import { useHistory } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppColorsEnum } from "../../../App.interface";
import {
  setAppColor,
  showFooter,
  showHeader,
} from "../../../store/actions/LayoutActions";
import { RootState } from "../../../types/State.interface";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import "./ActualPage.scss";
import { rusStringToDate } from "../../../utils";
import { getUrlParam } from "../../../utils/urlUtils";
import ComposePost from "./CreatePost/ComposePost";
import BlogService from "../../../services/blogService";
import ExpertCardList from "../../containers/Lists/ExpertCardList/ExpertCardList";
import { showErrorToast } from "../../../store/actions/ToastActions";
import ActualPost from "./ActualPost/ActualPost";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ActualDateSlider from "./ActualDateSlider/ActualDateSlider";
import ActualAside from "./ActualAside/ActualAside";
import { useMediaQuery } from "react-responsive";
import { tabletWidth } from "../../../utils/constants/widthConstants";
import ActionTeamBlog from "./ActionTeamBlog/ActionTeamBlog";

dayjs.extend(customParseFormat);
dayjs.locale("ru");

const ActualPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.userDetails);
  const allStages = useSelector((state: RootState) => {
    return user.loggedIn ? state.stage.accessibleStages : state.stage.allStages;
  });
  const project = useSelector((state: RootState) => state.project.current);
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  const [isLoading, setIsLoading] = useState(true);
  const [actualPosts, setActualPosts] = useState(null);
  const [editedPost, setEditedPost] = useState(null);
  const [batchedPosts, setBatchedPosts] = useState<any>(null);
  const [stages, setStages] = useState<any[]>(null);
  const [teamPosts, setTeamPosts] = useState<any[]>([]);
  const [date, setDate] = useState<Dayjs>(null);
  const [isComposePostMode, setIsComposePostMode] = useState(
    Boolean(getUrlParam("create", window.location.search))
  );
  const [linkedPosts, setLinkedPosts] = useState(null);
  const [posts, setPosts] = useState<any[]>([]);

  useEffect(() => {
    dispatch(showHeader());
    dispatch(showFooter());

    const urlDate = getUrlParam("date", window.location.search);
    if (!urlDate) return history.push("/");

    loadPosts();
    loadTeamBlogPosts();
  }, []);

  useEffect(() => {
    if (batchedPosts) {
      const urlDate = getUrlParam("date", window.location.search);
      const posts = batchedPosts[urlDate];
      setDate(dayjs(rusStringToDate(urlDate)));

      if (posts) {
        loadLinkedPosts(urlDate);
      }

      setPosts(
        posts?.sort((a, b) =>
          dayjs(b.createdTimestamp).isAfter(dayjs(a.createdTimestamp)) ? 1 : -1
        )
      );
    }
  }, [history.location.search, batchedPosts]);

  useEffect(() => {
    isComposePostMode || !Boolean(linkedPosts?.length)
      ? dispatch(setAppColor(AppColorsEnum.WHITE))
      : dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));
  }, [isComposePostMode, linkedPosts]);

  useEffect(() => {
    _setStages();
  }, [allStages]);

  const getInitialStages = () => {
    let result: any = {
      GENERATION: { items: [] },
      EXPERT_SELECTION: { items: [] },
      SIMPLE_DISCUSSION: { items: [] },
      VOTING: { items: [] },
    };

    user?.expert || user?.chiefExpert
      ? delete result.GENERATION
      : delete result.EXPERT_SELECTION;

    return result;
  };

  const _setStages = () => {
    const _stages = getInitialStages();
    const started =
      allStages && allStages.filter((stage) => stage.status === "STARTED");
    started &&
      started.forEach((stage) => {
        switch (stage.type) {
          case "GENERATION":
            return _stages.GENERATION?.items.push(stage);
          case "EXPERT_SELECTION":
            return _stages.EXPERT_SELECTION?.items.push(stage);
          case "PROJECT_DISCUSSION":
          case "SIMPLE_DISCUSSION":
            return _stages.SIMPLE_DISCUSSION?.items.push(stage);
          case "VOTING":
            return _stages.VOTING?.items.push(stage);
        }
      });

    setStages(_stages);
  };

  const loadPosts = () => {
    setIsLoading(true);
    return BlogService.getAllByBlogId(
      "project_diary_id",
      "createdTimestamp,asc"
    )
      .then((response) => {
        setActualPosts(response.data);
        setBatchedPosts(batchByDate(response.data));
      })
      .finally(() => setIsLoading(false));
  };

  const loadTeamBlogPosts = () => {
    const query = {
      page: 0,
      sort: "createdTimestamp,desc",
      type: "USER" as const,
    };
    BlogService.getTeamPosts(query).then((response) => {
      setTeamPosts(response.data);
    });
  };

  const batchByDate = (posts) => {
    if (!posts) return;

    const batched = posts.reduce((acc, curr) => {
      const date = dayjs(curr.createdTimestamp).format("DD.MM.YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(curr);
      acc[date] = acc[date].sort(
        (a, b) =>
          (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any)
      );

      return acc;
    }, {});

    return batched;
  };

  const loadLinkedPosts = (date) => {
    const linkedPostsIds = batchedPosts[date]
      .map((post) => post.properties?.linkToTeamPost)
      .filter(Boolean);
    const reqs = linkedPostsIds.map((id) => BlogService.getBlogPost(id));
    Promise.all(reqs)
      .then((response) => {
        setLinkedPosts(
          response
            .map((res: any) => res.data)
            .filter(Boolean)
            .sort((a, b) =>
              dayjs(b.createdTimestamp).isAfter(dayjs(a.createdTimestamp))
                ? 1
                : -1
            )
        );
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  };

  const edit = (post) => {
    setEditedPost(post);
    setIsComposePostMode(true);
  };

  const hasPosts = () => {
    return posts?.length;
  };

  // RENDER

  const renderPosts = () => {
    return (
      <div className="actual-page__list">
        {posts?.map((post, id) => {
          return (
            <div key={post.id}>
              <ActualPost
                post={post}
                stages={stages}
                user={user}
                onRemove={loadPosts}
                onEdit={edit}
              >
                {date && id === posts.length - 1 && (
                  <ActualDateSlider date={date} />
                )}
              </ActualPost>
            </div>
          );
        })}
      </div>
    );
  };

  const renderExpertButton = () => {
    if (isComposePostMode) return;

    return (
      <div className="actual-page__expbtn">
        <Button
          type="filled"
          size="m"
          text="Создать пост"
          onClick={() => setIsComposePostMode(true)}
        />
      </div>
    );
  };

  const renderComposePostForm = () => {
    return (
      <ComposePost
        editedPost={editedPost}
        onAddPost={() => {
          setIsComposePostMode(false);
          loadPosts();
        }}
        onClose={() => {
          setEditedPost(null);
          setIsComposePostMode(false);
        }}
      />
    );
  };

  const renderLinkedPosts = () => {
    return (
      <>
        {linkedPosts?.length > 0 && (
          <div className="actual-page__linked">
            <h3>
              {posts.length > 0 &&
                (posts[0].properties?.alternativeTitle ||
                  "Информация от экспертов проекта")}
            </h3>
            {<ExpertCardList posts={linkedPosts} />}
          </div>
        )}
      </>
    );
  };

  const renderNoPosts = () => {
    return <div className="actual-page__empty">Записей пока нет</div>;
  };

  return (
    <section className="actual-page">
      <PageTitle text="Актуальное" />

      <div className="actual-page__main">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {user?.teamMember && renderExpertButton()}

            {isComposePostMode && renderComposePostForm()}

            {!isComposePostMode && (
              <>
                <div className="actual-page__top">
                  <div className="actual-page__posts">
                    {hasPosts() ? (
                      renderPosts()
                    ) : (
                      <>
                        {renderNoPosts()}
                        {date && (
                          <>
                            <div></div>
                            <ActualDateSlider date={date} />
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {!isTablet && (
                    <div>
                      {date && project && (
                        <ActualAside
                          date={date}
                          actualPosts={actualPosts}
                          batchedPosts={batchedPosts}
                        />
                      )}

                      {<ActionTeamBlog teamPosts={teamPosts} />}
                    </div>
                  )}
                </div>

                {hasPosts() && renderLinkedPosts()}

                {isTablet && <ActionTeamBlog teamPosts={teamPosts} />}
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default ActualPage;
