import React, { useEffect, useState, Fragment } from "react";
import ReactCrop from "react-image-crop";
import imageCompression from "browser-image-compression";
import { Button, Modal } from "@crowd/ui-kit";
import { useDispatch } from "react-redux";
import "react-image-crop/lib/ReactCrop.scss";

import "./CropAvatarModal.scss";
import UserService from "../../../../../services/userService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { showErrorToast } from "../../../../../store/actions/ToastActions";
import { hideInfoModal, showInfoModal } from "../../../../../store/actions/LayoutActions";

interface CropAvatarProps {
  file: File;
  isOpened: boolean;
  onSuccess: Function;
  onClose: () => void;
}

interface CropAvatarState {
  croppedImageUrl: string;
  type: string;
  src: string;
  crop: {
    unit: string;
    width: number;
    height: number;
    aspect: number;
    x: number;
    y: number;
  };
  user?: Object;
  minWidth: number;
  minHeight: number;
  MAX_SIZE_FILE: number;
  compressOptions: {
    maxSizeMB: number;
    maxWidthOrHeight: number;
  };
  isLoading?: boolean;
}

const CropAvatarModal = (props: CropAvatarProps) => {
  const baseSize = 160;
  const dispatch = useDispatch();

  const [state, setState] = useState<CropAvatarState>({
    croppedImageUrl: "",
    type: "",
    src: "",
    crop: {
      unit: "px",
      width: baseSize,
      height: baseSize,
      aspect: 1,
      x: 0,
      y: 0,
    },
    compressOptions: {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    },
    minWidth: baseSize,
    minHeight: baseSize,
    MAX_SIZE_FILE: 1048576,
    isLoading: false,
  });
  const [imgRef, setImgRef] = useState(null);
  const [error, setError] = useState<string>("");

  async function init() {
    const { file } = props;
    const { MAX_SIZE_FILE, compressOptions } = state;

    if (file) {
      const resultedFile = file.size >= MAX_SIZE_FILE ? await imageCompression(file, compressOptions) : file;
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setState({
          ...state,
          type: resultedFile.type,
          src: reader.result as string,
        })
      );
      reader.readAsDataURL(resultedFile);
    }
  }

  useEffect(() => {
    init();
  }, []);

  const closeCropAvatarModal = () => {
    props.onClose();
  };

  const updateAvatar = () => {
    const { type, src } = state;
    let { x, y, height, width } = state.crop;

    x = ((x || 0) * imgRef.naturalWidth) / 100;
    y = ((y || 0) * imgRef.naturalHeight) / 100;
    height *= imgRef.naturalHeight / 100;
    width *= imgRef.naturalWidth / 100;

    const params = {
      coords: {
        x1: Math.max(0, Math.floor(x)),
        x2: Math.max(0, Math.floor(x + width)),
        y1: Math.max(0, Math.floor(y)),
        y2: Math.max(0, Math.floor(y + height)),
      },
      type,
      src,
    };

    setState({
      ...state,
      isLoading: true,
    });

    UserService.uploadPicture(params)
      .then((res) => {
        if (res.status === ApiStatusCode.OK) {
          props.onSuccess(res.data);
          props.onClose();
        } else {
          setError(res.message);

          if (res.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
            dispatch(showErrorToast(res.message));
          }
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setState({
          ...state,
          isLoading: false,
        });
      });
  };

  const centerCrop = (ref) => {
    // TODO remove setTimeout
    setTimeout(() => {
      const yPxPercent = ref.height / 100; // 100 - percents
      const xPxPercent = ref.width / 100;
      const height = baseSize / yPxPercent; // percents
      const width = baseSize / xPxPercent; // percents
      const y = 50 - height / 2; // percents
      const x = 50 - width / 2; // percents

      setState({
        ...state,
        crop: {
          y,
          x,
          height,
          width,
          unit: "%",
          aspect: 1,
        },
      });
    });
  };

  const onImageLoaded = (image) => {
    setImgRef(image);
    centerCrop(image);
  };

  const okButton = () => {
    return (
      <div className="image__error">
        <Button
          text="Хорошо"
          type="outlined"
          onClick={() => {
            dispatch(hideInfoModal());
            props.onClose();
          }}
        />
      </div>
    );
  };

  const onImageError = () => {
    props.onClose();
    dispatch(showInfoModal("Некорректные входные данные.", null, okButton()));
  };

  const onCropChange = (crop, percentCrop) => {
    setState({
      ...state,
      crop: percentCrop,
    });
  };

  const getClassesForModal = () => {
    return "crop-avatar" + (state.isLoading ? " crop-avatar-disabled" : "");
  };

  const { src, crop, minWidth, minHeight } = state;

  return (
    <Modal isOpen={!!props.isOpened} onClose={props.onClose} classNames="crop-avatar-modal">
      <Fragment key={"header"}>
        <div className="crop-avatar__title">Выбор фотографии</div>
      </Fragment>

      <Fragment key={"body"}>
        <div className={getClassesForModal()}>
          {error && <p className="crop-avatar__error error">{error}</p>}

          <div className="crop-avatar__content">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                minWidth={minWidth}
                minHeight={minHeight}
                circularCrop={true}
                onImageLoaded={onImageLoaded}
                onImageError={onImageError}
                onChange={onCropChange}
              />
            )}
          </div>
        </div>

        <div className="crop-avatar__buttons">
          <Button type="outlined" text="Отменить" size={"m"} onClick={closeCropAvatarModal} />

          <Button
            type="filled"
            text="Сохранить"
            size={"m"}
            isDisabled={state.isLoading}
            isLoading={state.isLoading}
            onClick={updateAvatar}
          />
        </div>
      </Fragment>
    </Modal>
  );
};

export default CropAvatarModal;
