import ProjectService from "../../services/projectService";
import { AxiosResponse } from "axios";
import { Project, ProjectStatus } from "../../types/Projects.interface";

export const GET_CURRENT_PROJECT = "GET_CURRENT_PROJECT";
export const GET_PROJECT_STATISTIC = "GET_PROJECT_STATISTIC";
export const GET_PROJECT_RULES = "GET_PROJECT_RULES";
export const GET_PROJECT_STRUCTURE = "GET_PROJECT_STRUCTURE";
export const GET_PROJECT_STATUS = "GET_PROJECT_STATUS";

export const getProjectStatus = () => (dispatch) => {
  return ProjectService.getProjectStatus()
    .then((data: AxiosResponse<ProjectStatus>) => {
      dispatch({
        type: GET_PROJECT_STATUS,
        payload: data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getCurrentProjects = () => (dispatch) => {
  return ProjectService.getProject()
    .then((data: AxiosResponse<Project>) => {
      dispatch({
        type: GET_CURRENT_PROJECT,
        payload: data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getProjectStatistics = () => (dispatch) => {
  return ProjectService.getStatistics()
    .then((data: AxiosResponse<Project>) => {
      dispatch({
        type: GET_PROJECT_STATISTIC,
        payload: data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getProjectRules =
  (raw = false) =>
  (dispatch) => {
    return ProjectService.getRules(raw).then(({ data }) => {
      dispatch({
        type: GET_PROJECT_RULES,
        payload: data,
      });
    });
  };

export const getProjectStructure =
  (raw = false) =>
  (dispatch) => {
    return ProjectService.getProjectStructure().then(({ data }) => {
      dispatch(setProjectStructure(data));
    });
  };

export const setProjectStructure = (payload) => ({
  type: GET_PROJECT_STRUCTURE,
  payload: payload,
});
