import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BlogPost } from "../../../types/BlogPost.interface";
import { ToggleSubscribeParams } from "../../../services/commentService";
import { toggleSubscribe } from "../../../store/actions/CommentActions";
import Tooltip from "../Tooltip/Tooltip";
import "./PostAddFavorite.scss";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { RootState } from "../../../types/State.interface";
import { AppContext } from "../../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";

export enum PostType {
  FAVORITE,
}

export interface PostAddFavoriteProps {
  post: BlogPost;
  postType?: PostType;
  count?: number;
  size?: "SMALL" | "DEFAULT";
  tooltipPlace?: "top" | "bottom";
  onChange?: (post) => void;
}

const PostAddFavorite = ({
  post,
  postType,
  size = "DEFAULT",
  tooltipPlace = "bottom",
  count,
  onChange,
}: PostAddFavoriteProps) => {
  const isSubscribed = () => {
    return (
      post.discussionStatistics?.subscribed ||
      post.statistics?.subscribed ||
      postType === PostType.FAVORITE
    );
  };

  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [subscribed, setSubscribed] = useState<boolean>(isSubscribed());
  const [currentCount, setCurrentCount] = useState<number | undefined>(count);
  const user = useSelector((state: RootState) => state.user.userDetails);

  const isShowCount = (c: number | undefined): boolean =>
    !!count || count === 0;

  //ACTIONS AFTER AUTH
  useEffect(() => {
    if (user?.loggedIn && !subscribed) {
      const actions = appContext.sudirService.getActions(ComponentType.PostAddFavorite);
      if (actions.length) {
        const postAddAction = actions.find((act) => act.type === AuthActionType.PostAddFavorite);
        if (postAddAction?.args.postId === post.id) {
          _toggleSubscribe();
        }
      }
    }
  }, [user]);

  useEffect(() => setSubscribed(isSubscribed()), [post]);

  const _toggleSubscribe = () => {
    if (!user?.loggedIn) {
      const containerData: AuthActionParam = {
        component: ComponentType.PostAddFavorite,
        type: AuthActionType.PostAddFavorite,
        args: { postId: post.id },
      };

      const action = new AuthAction(containerData);

      return appContext.sudirService.authWithActions([action]);
    }

    const data: ToggleSubscribeParams = {
      discussionId:
        post.discussionId || post.statistics?.discussionId || post.id,
      type: subscribed ? "unsubscribe" : "subscribe",
    };
    dispatch(
      toggleSubscribe(data, () => {
        setSubscribed(!subscribed);
        onChange && onChange(post);
        isShowCount(currentCount) &&
          setCurrentCount(
            subscribed
              ? (currentCount as number) - 1
              : (currentCount as number) + 1
          );
      })
    );
  };

  return (
    <Tooltip
      text={!subscribed ? "Добавить в избранное" : "Убрать из избранного"}
      idx={post.id + "subscribe"}
      place={tooltipPlace}
    >
      <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Добавить в избранное"], remember: true }}>
        <div
          className={`post-add-favorite ${
            size === "SMALL" ? "post-add-favorite__small" : ""
          }`}
          onClick={_toggleSubscribe}
        >
          <i className={"ui-icon-favorite" + (subscribed ? "-filled" : "")} />
          {isShowCount(currentCount) && (
            <div className={"post-add-favorite__count"}>{currentCount}</div>
          )}
        </div>
      </EventsTrackWrapperClick>
    </Tooltip>
  );
};

export default PostAddFavorite;
