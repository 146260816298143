import { FC, useState } from "react";
import { ExpanderProps } from "./Expander.types";
import "./Expander.scss";
import { Button } from "@crowd/ui-kit";

const Expander: FC<ExpanderProps> = ({ title, titleIcon, hiddenElement, className }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className={`expander ${opened ? "expander-opened" : ""} ${className || ""}`}>
      <div className="expander__title">
        <Button type="text-button" text={title} icon={titleIcon} onClick={() => setOpened((prev) => !prev)} />
      </div>
      <div className="expander__hidden-wrapper">
        <div className="expander__hiddent-element">{hiddenElement}</div>
        <Button text="Свернуть" icon="ui-icon-chevron-up" type="text-button" onClick={() => setOpened(false)} />
      </div>
    </div>
  );
};

export default Expander;
