import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  lessenPage,
  widenPage,
  hideHeader,
  showHeader,
  hideFooter,
  showFooter,
  setAppColor,
} from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import TeamLoginForm from "../../containers/Forms/TeamLoginForm/TeamLoginForm";
import "./TeamLogin.scss";

interface Props {
  history: any;
}

const TeamLoginPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideHeader());
    dispatch(hideFooter());
    dispatch(widenPage());
    dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));

    return function cleanup() {
      dispatch(lessenPage());
      dispatch(showHeader());
      dispatch(showFooter());
    };
  }, []);

  return (
    <div className="team-login-page">
      <TeamLoginForm history={history} />
    </div>
  );
};

export default TeamLoginPage;
