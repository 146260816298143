import { Button } from "@crowd/ui-kit";
import { FC } from "react";
import FadeIn from "../../FadeIn/FadeIn";
import { ClosingCardProps } from "./ClosingCard.interface";
import "./ClosingCard.scss";

const ClosingCard: FC<ClosingCardProps> = ({ className, children, onClose, isOpened, type = "blue", isSmall = false }) => {
  if (!isOpened) return null;

  return (
    <FadeIn>
      <div className={`closing-card closing-card_${type} ${isSmall ? "closing-card_small" : ""} ${className || ""}`}>
        {!!onClose && <Button classNames="closing-card__close-btn" type="text-button" text="" icon="ui-icon-close" onClick={onClose} />}
        {children}
      </div>
    </FadeIn>
  );
};

export default ClosingCard;
