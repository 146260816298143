import React, { useEffect } from "react";

import "./AccessDenied.scss";
import { Link } from "react-router-dom";
import { hideFooter, hideHeader, setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { useDispatch } from "react-redux";
import { AppColorsEnum } from "../../../App.interface";

const AccessDenied = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(hideHeader());
    dispatch(hideFooter());

    return () => {
      showHeader();
      showFooter();
    };
  }, []);

  return (
    <div className="access-denied">
      <h1 className="access-denied__title">Ошибка прав доступа</h1>
      <p className="access-denied__text">У вас нет доступа к данной странице</p>
      <p className="access-denied__text">
        Попробуйте начать с <Link to="/">Главной</Link>
      </p>
    </div>
  );
};

export default AccessDenied;
