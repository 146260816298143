import React from "react";
import { NavLink } from "react-router-dom";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

import { IRoute } from "../Header.interface";
import "./Dropdown.scss";

interface DropdownProps {
  items: IRoute[];
  hideDropdown: () => void;
}

const Dropdown = ({ items, hideDropdown }: DropdownProps) => {
  return (
    <ul className="dropdown-menu">
      {items.map(
        (item: IRoute, index: number) =>
          !item.hidden && (
            <span
              className="dropdown-menu__link"
              key={item.id.toString() + index}
            >
              <EventsTrackWrapperClick id={item.trackClickId} needParent>
                <NavLink
                  onClick={() => {
                    hideDropdown();
                  }}
                  to={item.route}
                  key={item.text}
                  activeClassName="active-link"
                >
                  {item.text}
                </NavLink>
              </EventsTrackWrapperClick>
            </span>
          )
      )}
    </ul>
  );
};

export default Dropdown;
