import { Tab } from "../../presentational/Tabs/Tabs";

export const criteriaDescription: string =
  "Проекты Правительства Москвы дают возможность жителям участвовать в процессе формирования предложений по решению вопросов жизнедеятельности города. Команда экспертов отбирает наиболее эффективные предложения с целью их последующей реализации. Отбор идей осуществляется в соответствии со следующими критериями:";

  
export const getGenerationFolders = (proposals = [], drafts = []): Tab[] => {
    return [
      {
        id: "propose",
        name: "Предложить идею",
        iconClass: "ui-icon-idea",
        activeIconClass: "ui-icon-idea-filled",
      },
      {
        id: "criteria",
        name: "Критерии отбора идей",
        iconClass: "ui-icon-idea-description",
        activeIconClass: "ui-icon-idea-description-filled",
      },
      {
        id: "drafts",
        name: "Черновики",
        count: drafts?.length || 0,
        iconClass: "ui-icon-idea-draft",
        activeIconClass: "ui-icon-idea-draft-filled",
      },
      {
        id: "sent",
        name: "Отправленные идеи",
        count: proposals?.filter((propos) => propos.status !== "DRAFT").length,
        iconClass: "ui-icon-idea-sent",
        activeIconClass: "ui-icon-idea-sent-filled",
      },
    ];
  };
