import React from "react";
import { Route } from "react-router-dom";
import AboutPage from "../components/pages/About/About";
import ActualPage from "../components/pages/Actual/ActualPage";
import FaqPage from "../components/pages/Faq/Faq";
import NotFoundPage from "../components/pages/NotFound/NotFoundPage";
import ParticipantsPage from "../components/pages/ParticipantsPage/ParticipantsPage";
import PersonalBlog from "../components/pages/Personal/pages/PersonalBlog/PersonalBlog";
import RulesPage from "../components/pages/Rules/Rules";
import LoggedInGuard from "./guards/LoggedInGuard";
import SmartRoute from "./SmartRoute";
import PersonalProposals from "../components/pages/Personal/pages/PersonalProposals/PersonalProposals";
import PersonalComments from "../components/pages/Personal/pages/PersonalComments/PersonalComments";
import PersonalPost from "../components/pages/Personal/pages/PersonalPost/PersonalPost";
import PersonalHeader from "../components/pages/Personal/PersonalHeader/PersonalHeader";
import PersonalProposal from "../components/pages/Personal/pages/PersonalProposal/PersonalProposal";
import NotStartedPage from "../components/pages/NotStarted/NotStartedPage";
import TeamBlogs from "../components/pages/TeamBlogs/TeamBlogs";
import RestorePasswordPage from "../components/pages/RestorePassword/RestorePasswordPage";
import ChangePasswordPage from "../components/pages/ChangePassword/ChangePasswordPage";
import Selection from "../components/pages/Selection/Selection";
import SelectionGuard from "./guards/SelectionGuard";
import AccessDenied from "../components/pages/AccessDenied/AccessDenied";
import EnhancedRoute from "./EnhancedRoute";
import TeamLoginPage from "../components/pages/TeamLogin/TeamLogin";
import ProfilePage from "../components/pages/Profile/Profile";
import VotingPage from "../components/pages/Voting/Voting";
import GenerationPage from "../components/pages/Generation/Generation";
import { selectionPath } from "../components/pages/Selection/config/router";

const baseRoutes = [
  <Route exact path="/faq" component={FaqPage} key={"faq"} />,
  <Route path="/users/user" key={"personal"}>
    <Route path="/users/user/:type/:userId/:postId?" component={PersonalHeader} key={"personalHeader"} />
    <Route exact path="/users/user/blog/:userId" component={PersonalBlog} key={"personalBlog"} />
    <Route exact path="/users/user/proposals/:userId" component={PersonalProposals} key={"personalIdeas"} />
    <Route exact path="/users/user/proposal/:userId/:proposalId" component={PersonalProposal} key={"personalProposal"} />
    <Route exact path="/users/user/comments/:userId" component={PersonalComments} key={"personalComments"} />
    <Route exact path="/users/user/post/:userId/:postId/:commentId?" component={PersonalPost} key={"personalPost"} />
  </Route>,

  <Route exact path="/about" component={AboutPage} key={"about"} />,
  <Route exact path="/teamBlogs" component={TeamBlogs} key={"teamBlogs"} />,
  <Route exact path="/rules" component={RulesPage} key={"rules"} />,
  <Route exact path="/users/participants" component={ParticipantsPage} key={"participants"} />,
  <Route path="/teamLogin" exact component={TeamLoginPage} key={"teamlogin"} />,

  <EnhancedRoute
    path={"/profile"}
    component={ProfilePage}
    guards={[LoggedInGuard]}
    key={"profile"}
  />,
  <EnhancedRoute
    path="/voting/:stageId"
    component={VotingPage}
    guards={[LoggedInGuard]}
    key={"voting"}
  />,
  <EnhancedRoute
    path="/generation/:stageId"
    component={GenerationPage}
    guards={[LoggedInGuard]}
    key={"generation"}
  />,
  <EnhancedRoute
    path={selectionPath}
    exact
    component={Selection}
    guards={[SelectionGuard]}
  />,

  <Route exact path="/actual" component={ActualPage} key={"actual"} />,
  <Route exact path="/notStarted" component={NotStartedPage} key={"notStartedPage"} />,
  <Route exact path="/denied" component={AccessDenied} key={"AccessDenied"} />,
  <Route component={NotFoundPage} key={"notFoundPage"} />,
];

export default baseRoutes;
