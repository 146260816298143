import { useMediaQuery } from "react-responsive";
import { shortDesktopWidth, phoneWidth, tabletWidth } from "../constants/widthConstants";

export function useIsMobile() {
  return useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
}

export function useIsTablet() {
  return useMediaQuery({ query: `(min-width: ${phoneWidth}px) and (max-width: ${tabletWidth}px)` });
}

export function useIsShortDesktop() {
  return useMediaQuery({ query: `(min-width: ${phoneWidth}px) and (max-width: ${shortDesktopWidth}px)` });
}

export function useIsDesktop() {
  return useMediaQuery({ query: `(min-width: ${shortDesktopWidth}px)` });
}
