import React, { useEffect } from "react";
import { Button } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hideInfoModal, showInfoModal } from "../../../store/actions/LayoutActions";
import "./SignInFail.scss";
import { RootState } from "../../../types/State.interface";
import PrivatePage from "../Private/PrivatePage";
import { logout } from "../../../store/actions/UserActions";

const SignInFail = () => {
  const history = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const promoUrl = useSelector((state: RootState) => state.common.environment?.promoUrl);

  useEffect(() => {
    if (location.search === "?showMessage=1") {
      dispatch(
        showInfoModal(
          "Если вам пришло приглашение, но вы не можете присоединиться к проекту, напишите в раздел «Помощь», указав данные от личного кабинета портала mos.ru.",
          "Ошибка авторизации",
          <Button
            type="filled"
            text="Хорошо"
            onClick={() => {
              dispatch(logout(history));
              dispatch(hideInfoModal());
              history.push('/');
            }}
            size="m"
          />
        )
      );
    }
  }, [location.search]);

  return <section className={"close__sign"}>
    <PrivatePage />
  </section>;
};

export default SignInFail;
