import BadWordsService from "../../services/bad-words.service";

export const GET_BAD_WORDS = "GET_BAD_WORDS";

export const getBadWords = () => (dispatch) => {
  return BadWordsService.getAll()
    .then((data) => {
      dispatch({
        type: GET_BAD_WORDS,
        payload: data?.data,
      });
    })
    .catch((err) => console.log(err));
};
