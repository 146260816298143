import { IRoute } from "./Header.interface";

const getMainMenuRoutes = (speechEnabled: boolean): IRoute[] => {
  return [
    {
      id: 0,
      route: "/about",
      text: "О проекте",
      trackClickId: ["ABOUT_PROJECT", "CLICK"],
      children: [
        { id: 1001, route: "/about", text: "Описание проекта", trackClickId: ["ABOUT_PROJECT", "PROJECT_DESC", "CLICK"] },
        {
          id: 1002,
          route: "/speech",
          text: "Обращение к участникам",
          hidden: !speechEnabled,
          trackClickId: ["ABOUT_PROJECT", "PARTICIPANTS_APPEAL", "CLICK"],
        },
        { id: 1003, route: "/rules", text: "Правила проекта", trackClickId: ["ABOUT_PROJECT", "PROJECT_RULES", "CLICK"] },
      ],
    },
    {
      id: 1,
      route: "/nodes",
      text: "График проекта",
      trackClickId: ["PROJECT_SCHEDULE", "CLICK"],
    },
    {
      id: 2,
      route: "/team",
      text: "Команда",
      trackClickId: ["TEAM", "CLICK"],
    },
    {
      id: 3,
      route: "/users/participants",
      text: "Участники",
      trackClickId: ["PARTICIPANTS", "CLICK"],
    },
    {
      id: 4,
      route: "/faq",
      text: "Помощь",
      trackClickId: ["HELP", "CLICK"],
    },
  ];
};

export default getMainMenuRoutes;
