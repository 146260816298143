import { NotificationsTypeEnum } from "./MyNotifications.interface";

const notificationsPerRequest = 10;

const Tabs = [
  {
    title: "Все",
    value: NotificationsTypeEnum.ALL,
  },
  {
    title: "Непрочитанные",
    value: NotificationsTypeEnum.UNREAD,
  },
  {
    title: "Отметить все как прочитанные",
    value: NotificationsTypeEnum.MARK_ALL_AS_READ,
  },
];

export { notificationsPerRequest, Tabs };
