import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Route, useLocation } from "react-router-dom";

import { RootState } from "../../../types/State.interface";
import { scrollToTop } from "../../../utils";
import Logo from "./Logo/Logo";
import "./Header.scss";
import Dropdown from "./Dropdown/Dropdown";
import { IRoute } from "./Header.interface";
import LoggedIn from "./LoggedIn/LoggedIn";
import LoggedOut from "./LoggedOut/LoggedOut";
import getMainMenuRoutes from "./Header.data";
import { tabletWidth } from "../../../utils/constants/widthConstants";
import LoggedInAvatar from "./LoggedInAvatar/LoggedInAvatar";
import { logout } from "../../../store/actions/UserActions";
import { showMosruForm } from "../../../store/actions/LayoutActions";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackParentIdContext from "../EventsTrack/EventsTrackParentIdContext";
import SelectionInfo from "../../pages/Selection/components/SelectionInfo/SelectionInfo";
import { selectionPath } from "../../pages/Selection/config/router";
import { useIsShortDesktop } from "../../../utils/hooks/useMedia";
import { profileUserDetailsSelector } from "../../../store/selectors/profile";

const HeaderContainer = () => {
  const linksVisible = useSelector((state: RootState) => state.layout.headerLinksVisible);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isShortDesktop = useIsShortDesktop();
  const speechEnabled = useSelector((state: RootState) => state.project.current?.speech.enabled);
  const dispatch = useDispatch();
  const promoUrl = useSelector((state: RootState) => state.common.environment?.promoUrl);
  const isUserExpert = useSelector((state: RootState) => profileUserDetailsSelector(state)?.expert);

  useEffect(() => {
    setRoutes(getMainMenuRoutes(speechEnabled));
  }, [speechEnabled]);

  const isItemActive = (item: IRoute): boolean => {
    return location.pathname.includes(item.route) || (item.children && item.children.some((childItem) => isItemActive(childItem)));
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleChildrenActivity = (routeId: number, active: boolean): void => {
    const updatedRoutes = routes.map((route) => {
      if (route.children && route.id === routeId) {
        route.isChildrenOpen = active;
      }

      return route;
    });

    setRoutes(updatedRoutes);
  };

  const getRouteWithChildrenClass = (item: IRoute) => {
    let cls = "";

    if (item.isChildrenOpen) {
      cls += "open-link-with-children ";
    }

    if (isItemActive(item)) {
      cls += "active-link ";
    }

    return cls;
  };

  const selectionInfo = isUserExpert && (isTablet || isShortDesktop) && (
    <Route path={selectionPath}>
      <SelectionInfo />
    </Route>
  );

  const renderRouteWithChildren = (item: IRoute, key: number) => {
    return (
      <li
        className="cr-header__link"
        key={key}
        onMouseEnter={() => (!isTablet ? toggleChildrenActivity(item.id, true) : undefined)}
        onMouseLeave={() => (!isTablet ? toggleChildrenActivity(item.id, false) : undefined)}
      >
        <EventsTrackWrapperClick id={item.trackClickId} needParent>
          <span
            className={getRouteWithChildrenClass(item)}
            onClick={isTablet ? () => toggleChildrenActivity(item.id, !item.isChildrenOpen) : undefined}
          >
            {item.text}
          </span>
        </EventsTrackWrapperClick>

        {item.isChildrenOpen && isTablet && renderRoutes(item.children, true)}

        {item.isChildrenOpen && !isTablet && <Dropdown items={item.children} hideDropdown={() => toggleChildrenActivity(item.id, false)} />}
      </li>
    );
  };

  const renderRoute = (item: IRoute, key: number) => {
    return (
      !item.hidden && (
        <li className="cr-header__link" key={key} onClick={() => setMenuOpen(false)}>
          <EventsTrackWrapperClick id={item.trackClickId} needParent>
            <NavLink to={item.route} activeClassName="active-link">
              {item.text}
            </NavLink>
          </EventsTrackWrapperClick>
        </li>
      )
    );
  };

  const renderRoutes = (menuRoutes: IRoute[], isChildren: boolean = false) => {
    const className = isChildren ? "cr-header__routes children" : "cr-header__routes";

    return (
      <ul className={className}>{menuRoutes.map((item, key) => (item.children ? renderRouteWithChildren : renderRoute)(item, key))}</ul>
    );
  };

  const renderDesktopHeader = () => {
    return (
      <div className="cr-header__inner">
        <Logo onLogoClick={() => scrollToTop(location.pathname === "/")} />
        <div className="cr-header__toggle" style={{ visibility: linksVisible ? "visible" : "hidden" }}>
          <ul className="cr-header__routes">
            <EventsTrackParentIdContext.Provider value="HEADER">{renderRoutes(routes)}</EventsTrackParentIdContext.Provider>
          </ul>
          {selectionInfo}
          <div className="header-menu">{loggedIn ? <LoggedIn /> : <LoggedOut />}</div>
        </div>
      </div>
    );
  };

  const logOut = () => dispatch(logout());

  const logIn = () => {
    dispatch(showMosruForm());
    setMenuOpen(false);
  };

  const renderMobileLoginBtn = () => {
    return (
      <EventsTrackWrapperClick id={["HEADER", "BUTTON", "CLICK"]} params={{ replace: ["Войти"], remember: true }}>
        <div onClick={() => logIn()} className="cr-header__toggle__entry flex">
          <i className="ui-icon ui-icon-enter-arrow"></i>
        </div>
      </EventsTrackWrapperClick>
    );
  };

  const renderMobileLogoutBtn = () => {
    return (
      <EventsTrackWrapperClick id={["HEADER", "BUTTON", "CLICK"]} params={{ replace: ["Выйти"] }}>
        <div onClick={() => logOut()} className="cr-header__toggle__entry flex">
          <i className="ui-icon ui-icon-exit-arrow"></i>
        </div>
      </EventsTrackWrapperClick>
    );
  };

  const renderMobileCloseMenuBtn = () => {
    return (
      <div onClick={() => toggleMenu()} className="cr-header__toggle__entry flex">
        <i className="ui-icon ui-icon-close pointer"></i>
      </div>
    );
  };

  const renderMobileHeader = () => {
    const toggleClass = isMenuOpen ? "ui-icon ui-icon-close pointer" : "ui-icon ui-icon-hamburger pointer";

    return (
      <>
        <div className="cr-header__inner mobile">
          <Logo
            onLogoClick={() => {
              scrollToTop(location.pathname === "/");
              setMenuOpen(false);
            }}
          />

          <div className="cr-header__toggle" style={{ visibility: linksVisible ? "visible" : "hidden" }}>
            {selectionInfo}

            {!isMenuOpen && (
              <EventsTrackWrapperClick id={["HEADER", "ICON_LIST", "CLICK"]}>
                <span className={toggleClass} onClick={() => toggleMenu()}></span>
              </EventsTrackWrapperClick>
            )}

            {!loggedIn && isMenuOpen && renderMobileCloseMenuBtn()}

            {!loggedIn && renderMobileLoginBtn()}

            {loggedIn && <LoggedInAvatar />}

            {loggedIn && isMenuOpen && renderMobileCloseMenuBtn()}

            {loggedIn && !isMenuOpen && renderMobileLogoutBtn()}
          </div>
        </div>

        <EventsTrackParentIdContext.Provider value={["HEADER", "ICON_LIST"]}>
          <div className={"cr-header__items" + (isMenuOpen ? " active-menu" : "")}>
            {renderRoutes(routes)}

            <h6 className="cr-header__items__home">
              <EventsTrackWrapperClick id={["LINK", "CLICK"]} needParent params={{ replace: ["Перейти на платформу «Город идей»"] }}>
                <Link to={{ pathname: promoUrl }} target="_blank">
                  <i className="ui-icon ui-icon-home"></i>
                  Перейти на платформу «Город идей»
                </Link>
              </EventsTrackWrapperClick>
            </h6>
          </div>
        </EventsTrackParentIdContext.Provider>
      </>
    );
  };

  return (
    <div className={"cr-header" + (loggedIn ? " cr-header-logged-in" : "")}>{isTablet ? renderMobileHeader() : renderDesktopHeader()}</div>
  );
};

export default HeaderContainer;
