export function mapResponseToCountData(response, name) {
  switch (name) {
    case "ideas":
      return {
        count: Object.values(response.data).reduce(
          (acc: any, curr) => acc + curr,
          0
        ),
      };
    case "comments":
      return {
        count: response.data,
      };
    case "answers":
      return {
        count: response.data.count,
        newCount: response.data.unreadCount,
      };
    case "mail":
      return {
        count: response
          .filter((folder) => folder.type === "INBOX")
          .map((d) => d.count)
          .reduce((acc: any, curr) => acc + curr, 0),
        newCount: response
          .filter((folder) => folder.type === "INBOX")
          .map((d) => d.unreadCount)
          .reduce((acc: any, curr) => acc + curr, 0),
      };
    case "blog":
      return {
        count: Object.values(response.data).reduce(
          (acc: any, curr) => acc + curr,
          0
        ),
      };
    case "favorite":
      return {
        count: response.data,
      };
    default:
      return {
        count: 0,
      };
  }
}
