export const GET_SCREEN_WIDTH = "GET_SCREEN_WIDTH";
export const GET_SCROLL = "GET_SCROLL";

export function getScreenWidth() {
  return function (dispatch) {
    const getWidth = () =>
      document.documentElement.clientWidth ||
      window.innerWidth ||
      document.body.clientWidth;

    dispatch({
      type: GET_SCREEN_WIDTH,
      payload: getWidth(),
    });
  };
}

export const getScroll = () => (dispatch) => {
  return dispatch({
    type: GET_SCROLL,
    payload: window.scrollY,
  });
};
