import React from "react";

import "./Dotter.scss";

interface Dotter {
  size: number;
  activeIndex?: number;
  onClick?: (index) => void;
}

export default function Dotter(props: Dotter) {
  const getDotClasslist = (id) => {
    let classlist = "dotter-dot simple-btn";

    if (props.activeIndex || props.activeIndex === 0) {
      if (props.activeIndex === id) {
        classlist += " dotter-dot--active";
      }
    }

    return classlist;
  };

  return (
    <div className="dotter">
      {Array(props.size)
        .fill(1)
        .map((dot, id) => {
          return (
            <button
              key={id}
              className={getDotClasslist(id)}
              onClick={(e) => props.onClick(id)}
            ></button>
          );
        })}
    </div>
  );
}
