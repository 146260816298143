// @flow

import React from "react";
import { Modal } from "@crowd/ui-kit";

import "./MobailModal.scss";
import Logo from "../../Header/Logo/Logo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import LoggedIn from "../../Header/LoggedIn/LoggedIn";
import LoggedOut from "../../Header/LoggedOut/LoggedOut";

type Props = {
  isOpen: Boolean;
  onClose: () => any;
  classNames?: any;
  children?: any;
};

const MobailModal = ({ isOpen, onClose, classNames, children }: Props) => {
  const renderByKey = (key) => {
    return children?.find((c) => c.key === key);
  };
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const renderHead = () => {
    return (
      <>
        {" "}
        {renderByKey("header") ? (
          <>{renderByKey("header")}</>
        ) : (
          <div className="modal__wrapper-head">
            <div className="modal__wrapper-head__title">
              <Logo />
              <div className="header-menu">
                {loggedIn ? <LoggedIn /> : <LoggedOut />}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderBody = () => {
    return <div className="modal__wrapper-body">{renderByKey("body")}</div>;
  };

  const getClassList = () => {
    let classlist = "modal__wrapper";
    if (classNames) classlist += ` ${classNames}`;
    return classlist;
  };

  const renderFooter = () => {
    return <div className="modal__wrapper-footer">{renderByKey("footer")}</div>;
  };

  return (
    <Modal
      classNames={getClassList()}
      isOpen={!!isOpen}
      onClose={onClose}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <React.Fragment key="header">{renderHead()}</React.Fragment>
      <React.Fragment key="body">{renderBody()}</React.Fragment>

      <React.Fragment key="footer">{renderFooter()}</React.Fragment>
    </Modal>
  );
};

export default MobailModal;
