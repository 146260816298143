import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Modal } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Facebook } from "react-content-loader";
import { useMediaQuery } from "react-responsive";

import { RootState } from "../../../../types/State.interface";
import UserAvatar from "../../../presentational/UserAvatar/UserAvatar";
import ComplaintModal from "../ComplaintModal/ComplaintModal";
import MessageModal from "../MessageModal/MessageModal";
import { UserModalProps } from "./UserModal.interface";
import WarningModal from "../WarningModal/WarningModal";
import UserService from "../../../../services/userService";
import { User, UserPermissions } from "../../../../types/User.interface";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import { ERR_MSG } from "../../../../services/api";
import "./UserModal.scss";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { getDisplayName, renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickButton from "../../EventsTrack/wrappers/helpers/EventsTrackClickButton";
import EventsTrackModal from "../../EventsTrack/wrappers/helpers/EventsTrackModal";
import useEventsTrackControl from "../../../../utils/hooks/useEventsTrackControl";

const UserModal = ({ userId, onClose }: UserModalProps) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const isOpenAuthModal = useSelector((state: RootState) => state.layout.showMosRuAuthForm);
  const [isOpenComplaintModal, setIsOpenComplaintModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [user, setUser] = useState<User>(null);
  const [isError, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const eventsTrackControl = useEventsTrackControl();
  const lastEventsTrackDataRef = useRef(eventsTrackControl.getLastEventTrackData());

  useEffect(() => {
    setLoading(true);
    UserService.getPerson(userId)
      .then(({ data, message, status }) => {
        if (ApiStatusCode.OK === status) {
          setUser(data);
          return;
        }

        setError(true);
        dispatch(showErrorToast(message || ERR_MSG));
      })
      .catch((err) => {
        setError(true);
        dispatch(showErrorToast(ERR_MSG));
        console.warn(err);
      })
      .finally(() => setLoading(false));
  }, [userId]);

  //ACTIONS AFTER AUTH
  useEffect(() => {
    if (currentUser && user) {
      const actions = appContext.sudirService.getActions(ComponentType.UserModal);
      if (actions.length) {
        const sendMessageAction = actions.find((act) => act.type === AuthActionType.SendMessage);
        if (sendMessageAction) {
          handleSendMessage(user);
        }
      }
    }
  }, [currentUser, user]);

  // принудительный сброс данных событий на начальные после закрытия модалок второго уровня
  useLayoutEffect(() => {
    if (!isOpenComplaintModal && !isOpenWarningModal && !messageModalOpen && !isOpenAuthModal) {
      eventsTrackControl.setLastEventTrackData(lastEventsTrackDataRef.current);
    }
  }, [isOpenComplaintModal, isOpenWarningModal, messageModalOpen, isOpenAuthModal]);

  const handleSendMessage = (user) => {
    if (!currentUser.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.UserModal,
        type: AuthActionType.SendMessage,
        args: { userId: user.id },
      };

      const redirectToChatAction = new AuthAction(thisContainerData);

      const mainContainerData: AuthActionParam = {
        component: ComponentType.ProjectParticipants,
        type: AuthActionType.OpenUserDetailsModal,
        args: { userId: user.id },
      };

      const openUserDetailsModalAction = new AuthAction(mainContainerData);
      
      return appContext.sudirService.authWithActions([openUserDetailsModalAction, redirectToChatAction], true);
    }

    history.push(`/profile/mail?userId=${user.id}`);
    onClose();
  };

  const renderStatsItem = (iconName: string, text: string, count: number | undefined, link?: string) => (
    <div className="user-modal__stats-item">
      <i className={`ui-icon-${iconName} ui-icon`} />
      {!link ? (
        <div className="user-modal__stats-text">{text}</div>
      ) : (
        <Link to={link} className="user-modal__stats-text" onClick={onClose}>
          {text}
        </Link>
      )}
      <div className="user-modal__stats-count">{count || 0}</div>
    </div>
  );

  return (
    <>
      <EventsTrackModal
        isOpen={true}
        onClose={onClose}
        classNames="user-modal"
        adjustPaddings={[".app-wrapper", ".cr-header"]}
        adjustMargins={[".scroll-top-button"]}
      >
        <Fragment key="body">
          {isError && !user && <div className="user-modal__error">{ERR_MSG}</div>}

          {isLoading ? (
            <Facebook />
          ) : (
            user && (
              <>
                <div className="user-modal__header">
                  <div className="user-modal__avatar">{<UserAvatar user={user} size={"L"} />}</div>
                  <div className="user-modal__user-info">
                    <div className="user-modal__user-name">
                      {getDisplayName(user)}
                      {renderHatIfUserNameIsHidden(user, currentUser)}
                    </div>
                    <div className="user-modal__user-place">
                      {!user.expertOrStaff && !user.frontman ? (
                        <>
                          {!!user?.moscowRegion && (
                            <p>
                              {user.moscowRegion} округ
                              {!!user?.moscowDistrict && ","}
                            </p>
                          )}
                          {!!user?.moscowDistrict && <p>район {user.moscowDistrict}</p>}
                        </>
                      ) : (
                        <p>{user.position}&nbsp;</p>
                      )}
                    </div>

                    {user?.online && <div className="user-modal__user-online">Онлайн</div>}

                    {user?.blocked && <div className="user-modal__user-blocked">Заблокирован</div>}
                  </div>
                </div>

                <div className="user-modal__stats">
                  {user?.participant && renderStatsItem("idea", "Отправленные идеи", user?.submittedProposals)}
                  {user?.participant && (
                    <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: ["Принятые идеи"], partial: true }}>
                      {renderStatsItem("idea-good", "Принятые идеи", user?.acceptedProposals, `/users/user/proposals/${user?.id}`)}
                    </EventsTrackWrapperClick>
                  )}
                  <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: ["Комментарии"], partial: true }}>
                    {renderStatsItem("comments", "Комментарии", user?.commentCount, `/users/user/comments/${user?.id}`)}
                  </EventsTrackWrapperClick>
                  <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: ["Записи в личном блоге"], partial: true }}>
                    {renderStatsItem("record", "Записи в личном блоге", user?.personalPostCount, `/users/user/blog/${user?.id}`)}
                  </EventsTrackWrapperClick>
                  {currentUser?.teamMember && renderStatsItem("appeal", "Предупреждения", user?.warnings)}
                </div>

                <div className="user-modal__footer">
                  {user.permissions?.includes(UserPermissions.USER_MAKE_COMPLAINT) && (
                    <EventsTrackClickButton
                      text={"Пожаловаться"}
                      type={"outlined"}
                      size={"m"}
                      onClick={() => setIsOpenComplaintModal(true)}
                      params={{
                        partial: true,
                        remember: true,
                      }}
                    />
                  )}

                  {user.permissions?.includes(UserPermissions.USER_WARN) && (
                    <Button
                      text={isPhone ? "Отправить предупреждение" : "Предупреждение"}
                      type={"outlined"}
                      size={"m"}
                      onClick={() => setIsOpenWarningModal(true)}
                    />
                  )}

                  <EventsTrackClickButton
                    text={"Написать сообщение"}
                    type={"filled"}
                    size={"m"}
                    onClick={() => handleSendMessage(user)}
                    params={{
                      partial: true,
                      remember: true,
                    }}
                  />
                </div>
              </>
            )
          )}
        </Fragment>
      </EventsTrackModal>

      {!!user && isOpenComplaintModal && <ComplaintModal user={user} type={"USER"} onClose={() => setIsOpenComplaintModal(false)} />}

      {isOpenWarningModal && (
        <WarningModal
          isOpen={isOpenWarningModal}
          onClose={() => setIsOpenWarningModal(false)}
          userId={user.id}
          onSuccess={() => {
            user.warnings++;
          }}
        ></WarningModal>
      )}

      {messageModalOpen && (
        <MessageModal
          recipients={[user]}
          isOpen={messageModalOpen}
          canDraft={true}
          sentOptions={{
            modal: {
              message: "Сообщение успешно отправлено.",
            },
          }}
          draftOptions={{
            toast: {
              message: "Черновик успешно сохранен",
            },
          }}
          onClose={() => setMessageModalOpen(false)}
        />
      )}
    </>
  );
};

export default UserModal;
