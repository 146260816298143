import React from "react";
import { Button } from "@crowd/ui-kit";

import UserAvatar from "../../UserAvatar/UserAvatar";
import "./ExpertPostCard.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";

const rolesMapping = {
  FACILITATOR: "Координатор проекта",
  CHIEF_EXPERT: "Главный эксперт проекта",
  EXPERT: "Эксперт проекта",
};

const ExpertPostCard = (props) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const isFooterVisible = () => {
    if (props.post.discussionPermissions.includes("DISCUSSION_ADD_COMMENT")) return true;
  };

  return (
    <div className="post-card expert-post-card" style={{ backgroundColor: props.background || "white" }}>
      <header className="post-card__header">
        <UserAvatar user={props.post.author} size={isPhone ? "S" : "M"} />

        <div className="expert-post-card__info">
          <p className="post-card--hb">{props.post.author.firstName + ` ${props.post.author.lastName}`}</p>
          <p className="post-card--hi">{rolesMapping[props.post.author.highestRole]}</p>
          <p className="post-card--hn">{props.post.author.position || ""}</p>
        </div>
      </header>
      <p className="post-card--hn phone">{props.post.author.position || ""}</p>
      <main className="expert-post-card__body">
        <p className="post-card--bb">{props.post.title}</p>
        <div
          className="post-card--bn"
          dangerouslySetInnerHTML={{
            __html: props.post.content,
          }}
        ></div>
      </main>

      {isFooterVisible() && (
        <footer className="expert-post-card__footer">
          <Button
            size={"m"}
            type={"filled"}
            hrefTarget={"_self"}
            text={"Написать комментарий"}
            onClick={() => props.onWriteComment(props.post)}
            isDisabled={!props.post.discussionPermissions.includes("DISCUSSION_ADD_COMMENT")}
          />
        </footer>
      )}
    </div>
  );
};

export default ExpertPostCard;
