import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Button, Select } from "@crowd/ui-kit";
import { useHistory } from "react-router-dom";

import ProfileSubContent from "../ProfileSubContent/ProfileSubContent";
import UserAvatar from "../../../presentational/UserAvatar/UserAvatar";
import { relativeTime, replaceHtmlEntites, scrollToTop } from "../../../../utils";
import NotificationConfigurationForm from "../../../containers/Forms/NotificationConfugurationForm/NotificationConfigurationForm";
import NotificationsService from "../../../../services/notificationsService";
import Pagination from "../../../presentational/Pagination/Pagination";
import {
  ApiStatusCode,
  PagingResponseParameters,
} from "../../../../types/Common.interface";
import { notificationsPerRequest, Tabs } from "./MyNotifications.constants";
import { getUrlParam } from "../../../../utils/urlUtils";
import { NotificationsTypeEnum } from "./MyNotifications.interface";
import { showConfirmInfoModal } from "../../../../store/actions/LayoutActions";
import { ElementObserver } from "../../../presentational/ElementObserver/ElementObserver";
import { ElementObserverItem } from "../../../presentational/ElementObserver/ElementObserverItem/ElementObserverItem";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import "./MyNotifications.scss";
import { getCountAllUnread } from "../../../../store/actions/NotificationsActions";

dayjs.locale("ru");

const MyNotifications = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [batchedByDate, setBatchedByDate] = useState(null);
  const [configurationFormOpen, setConfigurationFormOpen] = useState(false);
  const [pagination, setPagination] = useState<PagingResponseParameters>(null);
  const [page, setPage] = useState(getPageFromUrl());
  const [type, setType] = useState(getTypeFromUrl());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadNotifications();
  }, [page, type]);

  // API calls

  const loadNotifications = () => {
    NotificationsService.getPaginated(
      page,
      notificationsPerRequest,
      type.toUpperCase()
    )
      .then((response) => {
        setPagination(response.paging);
        setBatchedByDate(batchByDate(response.data));
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => setLoading(false));
  };

  const markAsRead = (notification) => {
    if (notification.processed) return;

    NotificationsService.markAllRead([notification.id]).catch((err) =>
      dispatch(showErrorToast(err.message))
    );
  };

  const markAllAsRead = () => {
    NotificationsService.markAllRead(null, ["NEW_COMMENT_REPLY"])
      .then((response) => {
        if (response.data?.status === ApiStatusCode.OK) {
          dispatch(
            showSuccessToast("Все уведомления были отмечены как прочитанные.")
          );
          loadNotifications();
          dispatch(getCountAllUnread());
        }
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  };

  // Methods

  const batchByDate = (notifications) => {
    if (!notifications) return;

    return Object.entries(
      notifications.reduce((acc, curr) => {
        const date = dayjs(curr.timestamp).format("DD.MM.YYYY");
        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(curr);
        acc[date] = acc[date].sort(
          (a, b) =>
            (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any)
        );

        return acc;
      }, {})
    );
  };

  const switchByType = (value) => {
    if (value === NotificationsTypeEnum.MARK_ALL_AS_READ) {
      return dispatch(
        showConfirmInfoModal(
          "Вы действительно хотите отметить все уведомления как прочитанные?",
          () => markAllAsRead()
        )
      );
    }

    setType(value);
  };

  function getPageFromUrl() {
    return Math.max(
      Number(getUrlParam("page", history.location.search)) - 1,
      0
    );
  }

  function getTypeFromUrl() {
    return (
      getUrlParam("type", history.location.search)?.toUpperCase() ||
      NotificationsTypeEnum.ALL
    );
  }

  const getSelectValue = () => {
    return Tabs.find((i) => i.value === type) || null;
  };

  // Render

  const renderNoNotifications = () => {
    return (
      <div className="my-notifications__empty">У вас нет уведомлений.</div>
    );
  };

  const renderPanel = () => {
    return (
      <div className="my-notifications__panel">
        <div className="my-notifications__panel__fixed-text-select">
          <Select
            label="Дополнительно"
            items={Tabs}
            closeOnSelect={true}
            placeholder="Дополнительно"
            value={getSelectValue()}
            bindTo="title"
            onItemSelect={(item) => switchByType(item.value)}
          />
        </div>

        <Button
          type="text-button" //isPhone
          size="m"
          text="Настройка уведомлений"
          icon={<span className="ui-icon ui-icon-settings"></span>}
          onClick={() => setConfigurationFormOpen(true)}
        />
      </div>
    );
  };

  const getNotificationHtml = (text) => {
    let _text = replaceHtmlEntites(text);
    return _text;
  }

  const renderList = () => {
    return (
      <div className="my-notifications__list">
        {(batchedByDate &&
          batchedByDate.length &&
          batchedByDate.map(([date, items], index) => {
            return (
              <div className="notification" key={date + index}>
                <div className="notification__parent">
                  <div className="notification__parent-time">{date}</div>

                  <ElementObserver
                    autoUnobserve={true}
                    onIntersect={(notification) => markAsRead(notification)}
                  >
                    {items.map((item) => (
                      <ElementObserverItem
                        data={item}
                        id={date + item.id}
                        key={item.id}
                      >
                        <div className="notification__child">
                          <div className="notification__child-time">
                            {dayjs(item.timestamp).format("HH:mm")}
                          </div>
                          <div
                            className="notification__child-text"
                            dangerouslySetInnerHTML={{ __html: getNotificationHtml(item.longText) }}
                          />
                          <div>
                            {item.linkedUser && (
                              <div className="notification__child__linked-user">
                                <UserAvatar user={item.linkedUser} size={"M"} />
                                <div className="notification__child__linked-user__info">
                                  <div className="notification__child__linked-user__name">
                                    {item.linkedUser?.firstName +
                                      " " +
                                      item.linkedUser?.lastName}
                                  </div>
                                  <div className="notification__child__linked-user__rel">
                                    {relativeTime(item.timestamp)}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </ElementObserverItem>
                    ))}
                  </ElementObserver>
                </div>
              </div>
            );
          })) ||
          renderNoNotifications()}
      </div>
    );
  };

  return (
    <div className="my-notifications">
      <ProfileSubContent
        module="my-notifications"
        title="Уведомления"
        panel={renderPanel()}
        paging={null}
        isLoading={loading}
      >
        <div className="my-notifications__content">{renderList()}</div>
        {configurationFormOpen && (
          <NotificationConfigurationForm
            onClose={() => setConfigurationFormOpen(false)}
          />
        )}

        {pagination?.totalElements > 0 && (
          <Pagination
            totalPages={pagination?.totalPages || 0}
            onPageChange={(event) => {
              setPage(event.selected);
              scrollToTop();
            }}
            currentPage={page}
            pageName="page"
          />
        )}
      </ProfileSubContent>
    </div>
  );
};

export default MyNotifications;
