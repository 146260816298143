import { RootState } from "../../types/State.interface";
import { SelectionProposalCounters } from "../../utils/constants/proposal";
import { createSelector } from "reselect";
import { profileUserDetailsSelector } from "./profile";
import { ProposalTypeEnum } from "../../types/Proposal.interface";
import { stageSelector } from "./stage";

const selectionSelector = (state: RootState) => state.selection;

export const selectionGenerationStageSelector = (state: RootState) => selectionSelector(state).generationStage;
export const selectionCountProposalsSelector = (state: RootState) => selectionSelector(state).counts;
export const selectionTagsSelector = (state: RootState) => selectionSelector(state).tags;
export const selectionRejectReasonsSelector = (state: RootState) => selectionSelector(state).rejectReasons;
export const selectionExpertsSelector = (state: RootState) => selectionSelector(state).experts;
export const selectionIdeasSelector = (state: RootState) => selectionSelector(state).ideas;
export const selectionIdeasLoadingSelector = (state: RootState) => selectionSelector(state).ideasLoading;
export const selectionIdeasPaginationSelector = (state: RootState) => selectionSelector(state).ideasPagination;
export const selectionPageSelector = (state: RootState) => selectionSelector(state).page;
export const selectionFilterSelector = (state: RootState) => selectionSelector(state).filter;
export const selectionFilterTypeSearchSelector = (state: RootState) => selectionSelector(state).filterTypeSearch;
export const selectionSearchFilterValueSelector = (state: RootState) => selectionSelector(state).searchFilterValue;
export const selectionFilterSearchResSelector = (state: RootState) => selectionSelector(state).filterSearchRes;
export const selectionSelectedTagsForCombiningSelector = (state: RootState) => selectionSelector(state).selectedTagsForCombining;
export const selectionIdeasForCombiningSelector = (state: RootState) => selectionSelector(state).ideasForCombining;
export const selectionIsTagEditingSelector = (state: RootState) => selectionSelector(state).isTagEditing;
export const selectionIsCombiningTagsSelector = (state: RootState) => selectionSelector(state).isCombiningTags;
export const selectionIdeaProcessIdSelector = (state: RootState) => selectionSelector(state).ideaProcessId;
export const selectionIdeaProcessTypeSelector = (state: RootState) => selectionSelector(state).ideaProcessType;
export const selectionIdeaShowTransferHistorySelector = (state: RootState) => selectionSelector(state).ideaShowTransferHistory;
export const selectionIsStageFinishedSelector = (state: RootState) => selectionSelector(state).isStageFinished;
export const selectionIsForwardedShowAllSelector = (state: RootState) => selectionSelector(state).isForwardedShowAll;

export const selectionIsLoadingSelector = (state: RootState) =>
  !selectionIdeasSelector(state) || !stageSelector(state) || !selectionCountProposalsSelector(state);

export const selectionCountsSelector = createSelector(selectionCountProposalsSelector, (counts) =>
  counts
    ? {
        [SelectionProposalCounters.SUBMITTED]: counts.submitted,
        [SelectionProposalCounters.ACCEPTED]: counts.accepted,
        [SelectionProposalCounters.REJECTED]: counts.rejected,
        [SelectionProposalCounters.FORWARDED]: counts.forwarded,
        [SelectionProposalCounters.FORWARDED_TOTAL]: counts.forwardedTotal,
        [SelectionProposalCounters.UNREVIEWED]: counts.forwardedTotal + counts.submitted,
      }
    : null
);

export const selectionExpertsListSelector = createSelector(
  selectionExpertsSelector,
  (experts) => experts?.map(({ firstName, lastName, id }) => ({ value: id, text: `${firstName} ${lastName}` })) || []
);

export const selectionExpertsListWithoutCurrentUserSelector = createSelector(
  selectionExpertsListSelector,
  (state: RootState) => profileUserDetailsSelector(state).id,
  (experts, currentUserId) => experts?.filter(({ value }) => value !== currentUserId) || []
);

export const selectionTagFilterSelector = createSelector(selectionTagsSelector, selectionFilterSelector, (tags, filter) =>
  tags?.find(({ id }) => id === filter.tagId)
);

export const selectionTagsNormalizedSelector = createSelector(selectionTagsSelector, (tags) =>
  tags?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr,
    }),
    {}
  )
);

export const selectionIdeasExpertsSelector = createSelector(
  selectionIdeasSelector,
  selectionIsForwardedShowAllSelector,
  (ideas, isForwardedShowAll) =>
    ideas?.reduce((sum, idea) => {
      sum[idea.id] =
        idea.status === ProposalTypeEnum.FORWARDED
          ? isForwardedShowAll
            ? idea.expert
            : idea.statusHistory?.findLast((item) => item.status === ProposalTypeEnum.FORWARDED)?.forwardedBy
          : idea.expert ||
            idea.statusHistory
              ?.slice()
              .reverse()
              .find((item) => item.user.expert)?.user;
      return sum;
    }, {}) || {}
);
