import { useLocation } from "react-router-dom";

/**
 * @alias useQuery
 * @description get object from query params string.
 * "?&page=1&test=hello" => { page: "1", test: "hello" }
 */
const useQuery = (): { [key: string]: string } => {
  return Object.fromEntries(new URLSearchParams(useLocation().search));
};

export default useQuery;
