import { IBaseResponse } from "../App.interface";
import { District } from "../types/District.interface";
import { httpParamsSerialize, API } from "./api";

const DistrictService = {
  getByQuery: (query?: string): Promise<IBaseResponse<District[]>> =>
    API.get(`/api/district/getByQuery${query ? `?${httpParamsSerialize({ query })}` : ""}`).then(({ data }) => data),
};

export default DistrictService;
