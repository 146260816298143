import ProposalService from "../../services/proposalService";

export const GET_DRAFTS = "GET_DRAFTS";
export const GET_PROPOSAL_COUNT = "GET_PROPOSAL_COUNT";
export const STORE_LOCAL_DRAFT = "STORE_LOCAL_DRAFT";
export const CLEAR_LOCAL_DRAFT = "CLEAR_LOCAL_DRAFT";

export const getDrafts = (params?) => (dispatch) => {
  return ProposalService.getDraftCount()
    .then(({ data }) => {
      dispatch({ type: GET_DRAFTS, payload: data });
    })
    .catch((err) => console.log(err));
};

export const getProposalCount = (params?) => (dispatch) => {
  return ProposalService.getProposalCount()
    .then(({ data }) => {
      dispatch({ type: GET_PROPOSAL_COUNT, payload: data });
    })
    .catch((err) => console.log(err));
};

export const storeLocalDraft = (draft) => (dispatch) => {
  return dispatch({
    type: STORE_LOCAL_DRAFT,
    payload: draft,
  });
};

export const clearLocalDraft = () => (dispatch) => {
  return dispatch({
    type: CLEAR_LOCAL_DRAFT,
  });
};
