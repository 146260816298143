import { API } from "./api";


export const loadMetrics = (callback?) => {
    return API.get("/api/settings/env").then((res) => {
    
        try {
            const env = res?.data?.data;
            const { yandexClientId, yandexNtuClientId = "85911885" } = env;

            if (yandexClientId) {
                env.DitAnalyticTracker = new AnalyticTracker();
                setupYandex(yandexClientId);
                env.DitAnalyticTracker.setYandexId(yandexClientId);
            }
    
            if (yandexNtuClientId) {
                env.NtuAlyticTracker = new AnalyticTracker();
                setupYandex(yandexNtuClientId);
                env.NtuAlyticTracker.setYandexId(yandexNtuClientId);
            }
    
            // if (env?.googleClientId) {
            //   ReactGA.initialize(env.googleClientId);
            //   ReactGA.ga("create", env.googleClientId, "auto");
            //   ReactGA.ga("send", "pageview");
            // }
    
            // if (env?.facebookClientId) {
            //   ReactPixel.init(`${env.facebookClientId}`);
    
            //   ReactPixel.track("PageView");
            //   ReactPixel.track("CompleteRegistration");
            // }
    
            // if (env?.vkClientId) {
            //     setupVk(env.vkClientId);
            // }
    
            return env;
        } catch (err) {
            console.table(err)
        }

    }, console.error);
};

export class AnalyticTracker {
    yandexClientId;

    constructor() { }
    setYandexId(id) {
        this.yandexClientId = id;
    }

    sendEventRegistrationConfirm() {
        // if (window.ga)
        //   window.ga("send", "event", "Регистрация", "Подтверждение регистрации");

        try {
            window[`yaCounter${this.yandexClientId}`].reachGoal(
                "Подтверждение регистрации"
            );
        } catch (err) {
            console.log(err);
        }
    }

    sendEventRegistrationTwitterCompleted() {
        // if (window.ga)
        //   window.ga("send", "event", "Регистрация Tw ", "Завершение регистрации");

        try {
            window[`yaCounter${this.yandexClientId}`].reachGoal(
                "Завершение регистрации Tw"
            );
        } catch (err) {
            console.log(err);
        }
    }

    sendEventSuccessFormAuthorization() {
        // if (window.ga)
        //   window.ga("send", "event", "Авторизация форма", "Авторизация");

        try {
            window[`yaCounter${this.yandexClientId}`].reachGoal("Авторизация");
        } catch (err) {
            console.log(err);
        }
    }

    sendEventSuccessApplyRequest() {
        // if (window.ga) window.ga("send", "event", "Заявка", "Подача заявки");

        try {
            window[`yaCounter${this.yandexClientId}`].reachGoal("Подача заявки");
        } catch (err) {
            console.log(err);
        }
    }

    sendEventObserve() {
        // if (window.ga) window.ga("send", "event", "Наблюдение", "Наблюдение");

        try {
            window[`yaCounter${this.yandexClientId}`].reachGoal("Наблюдение");
        } catch (err) {
            console.log(err);
        }
    }
}

function setupYandex(clientId) {
    (function (d, w, c) {
        (w[c] = w[c] || []).push(function () {
            try {
                w[`yaCounter${clientId}`] = new w.Ya.Metrika({
                    id: clientId,
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true,
                });
            } catch (e) { }
        });
        var n = d.getElementsByTagName("script")[0],
            s = d.createElement("script"),
            f = function () {
                n.parentNode.insertBefore(s, n);
            };
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://mc.yandex.ru/metrika/watch.js";
        if (w.opera == "[object Opera]") {
            d.addEventListener("DOMContentLoaded", f, false);
        } else {
            f();
        }
    })(document, window, "yandex_metrika_callbacks");

    let noscript = document.createElement("noscript"),
        div = document.createElement("div"),
        img = document.createElement("img");

    img.src = `https://mc.yandex.ru/watch/${clientId}`;
    img.alt = clientId;
    img.style.position = "absolute";
    img.style.left = "-9999px;";
    img.style.alt = "";

    div.appendChild(img);
    noscript.appendChild(div);
    document.body.appendChild(noscript);
}