import { useDispatch, useSelector } from "react-redux";
import { SELECTION_SET_IDEAS_FOR_COMBINING, selectionConfirmSelectedMainIdea } from "../../../../../store/actions/SelectionActions";
import {
  selectionIdeasForCombiningSelector,
  selectionIsCombiningTagsSelector,
  selectionTagFilterSelector,
} from "../../../../../store/selectors/selection";
import { ProposalInterface } from "../../../../../types/Proposal.interface";
import SelectMainIdeaModal from "../SelectMainIdeaModal/SelectMainIdeaModal";
import { useSelectionParams } from "../../helpers/params";

export function SelectionIdeasCombining() {
  const { folder } = useSelectionParams();

  const dispatch = useDispatch();

  const tagFilter = useSelector(selectionTagFilterSelector);
  const ideasForCombining = useSelector(selectionIdeasForCombiningSelector);
  const isCombiningTags = useSelector(selectionIsCombiningTagsSelector);

  const onConfirmSelectedMainIdea = async (selectedIdea: ProposalInterface) => {
    dispatch(selectionConfirmSelectedMainIdea(folder, selectedIdea));
  };

  return (
    <SelectMainIdeaModal
      title={`Выбор главной идеи для группы "${tagFilter.value}"`}
      onClose={() => dispatch({ type: SELECTION_SET_IDEAS_FOR_COMBINING, payload: undefined })}
      ideas={ideasForCombining}
      isConfirming={isCombiningTags}
      onConfirm={onConfirmSelectedMainIdea}
    />
  );
}
