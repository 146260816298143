import { Modal, Search } from "@crowd/ui-kit";
import { Fragment, useEffect, useMemo, useState } from "react";
import { escapeRegExp } from "../../../utils";
import Popup from "../Popup/Popup";
import Table from "../Table/Table";
import { PopupSearchTableProps } from "./PopupSearchTable.interface";
import "./PopupSearchTable.scss";
import { useIsMobile } from "../../../utils/hooks/useMedia";
import cn from "classnames";

const PopupSearchTable = <T extends string>({
  isOpen,
  onClose,
  header,
  footer,
  data,
  columns,
  onRowClick,
  activeRow,
  searchPlaceholder,
  bindTo,
  children,
  selectEnabled,
  onSelect,
  side,
  className,
}: PopupSearchTableProps<T>) => {
  const [searchTag, setSearchTag] = useState("");
  const isMobile = useIsMobile();

  const filteredData = useMemo(
    () => data?.filter((item) => item[bindTo].match(new RegExp(escapeRegExp(searchTag), "i"))),
    [data, searchTag]
  );

  useEffect(() => {
    if (isOpen) {
      setSearchTag("");
      onSelect?.([]);
    }
  }, [isOpen]);

  const body = (
    <>
      <Search
        placeholder={searchPlaceholder}
        value={searchTag}
        onChange={setSearchTag}
        items={[]}
        icon={<span className="ui-icon ui-icon-search"></span>}
      />

      <Table
        columns={columns}
        data={filteredData}
        onRowClick={onRowClick}
        activeRow={activeRow}
        selectEnabled={selectEnabled}
        onSelect={onSelect}
        classNames="popup-search-table__table"
      />
    </>
  );

  const wrapperClassName = cn("popup-search-table", className);

  if (isMobile) {
    return (
      <>
        {children}
        <Modal isOpen={isOpen} onClose={onClose} classNames={wrapperClassName}>
          <Fragment key="header">{header}</Fragment>
          <Fragment key="body">{body}</Fragment>
          <Fragment key="footer">{footer}</Fragment>
        </Modal>
      </>
    );
  }

  return (
    <Popup className={wrapperClassName} isOpen={isOpen} onClose={onClose} header={header} footer={footer} body={body} side={side}>
      {children}
    </Popup>
  );
};

export default PopupSearchTable;
