import { Select, Search } from "@crowd/ui-kit";
import {
  SELECTION_SET_FILTER_SEARCH_RES,
  SELECTION_SET_FILTER_TYPE_SEARCH,
  SELECTION_SET_SEARCH_FILTER_VALUE,
  selectionSetFilter,
} from "../../../../../store/actions/SelectionActions";
import { getLocalDate } from "../../../../../utils";
import DatePicker from "../../../../presentational/Controls/DatePicker/DatePicker";
import { DatePickerType } from "../../../../presentational/Controls/DatePicker/DatePicker.interface";
import Switcher from "../../../../presentational/Controls/Switcher/Switcher";
import { filterTypesSearchSelect } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserService from "../../../../../services/userService";
import { SelectionFolders } from "../../../../../utils/constants/proposal";
import {
  selectionExpertsListSelector,
  selectionFilterSearchResSelector,
  selectionFilterSelector,
  selectionFilterTypeSearchSelector,
  selectionSearchFilterValueSelector,
} from "../../../../../store/selectors/selection";
import { selectionGetCleanedTypeSearchFilter } from "../../helpers/helpers";

export function SelectionInputsFilters() {
  const dispatch = useDispatch();
  const { folder } = useParams<{ folder: SelectionFolders }>();

  const filterTypeSearch = useSelector(selectionFilterTypeSearchSelector);
  const searchFilterValue = useSelector(selectionSearchFilterValueSelector);
  const filterSearchRes = useSelector(selectionFilterSearchResSelector);
  const filter = useSelector(selectionFilterSelector);
  const expertsList = useSelector(selectionExpertsListSelector);

  const onItemFilterTypeSelect = (value) => {
    dispatch({ type: SELECTION_SET_FILTER_TYPE_SEARCH, payload: value });
    dispatch({ type: SELECTION_SET_SEARCH_FILTER_VALUE, payload: "" });
    dispatch({ type: SELECTION_SET_FILTER_SEARCH_RES, payload: [] });
  };

  const onChangeSearchFilter = (value) => {
    dispatch({ type: SELECTION_SET_SEARCH_FILTER_VALUE, payload: value });
    if (value) {
      switch (filterTypeSearch.value) {
        case "title":
        case "text":
          dispatch(
            selectionSetFilter({
              ...selectionGetCleanedTypeSearchFilter(filter),
              [filterTypeSearch.value]: value,
            })
          );
          break;

        case "authorId":
          UserService.findUser({ type: "PARTICIPANT", query: value }).then(({ data }) =>
            dispatch({
              type: SELECTION_SET_FILTER_SEARCH_RES,
              payload: data?.map(({ firstName, lastName, id }) => ({ value: id, text: `${firstName} ${lastName}` })) || [],
            })
          );
          break;
        case "expertId":
          const r = new RegExp(value, "i");
          dispatch({ type: SELECTION_SET_FILTER_SEARCH_RES, payload: expertsList?.filter(({ text }) => text.match(r)) || [] });
          break;
      }
    } else {
      dispatch(selectionSetFilter(selectionGetCleanedTypeSearchFilter(filter)));
    }
  };

  const onItemFilterSearchSelect = ({ value, text }) => {
    dispatch({ type: SELECTION_SET_SEARCH_FILTER_VALUE, payload: text });
    dispatch(
      selectionSetFilter({
        ...selectionGetCleanedTypeSearchFilter(filter),
        [filterTypeSearch.value]: value,
      })
    );
  };

  return (
    <div className="selection-page__inputs">
      <Select value={filterTypeSearch} items={filterTypesSearchSelect} bindTo="text" onItemSelect={onItemFilterTypeSelect} closeOnSelect />
      <Search
        value={searchFilterValue}
        onChange={onChangeSearchFilter}
        items={filterSearchRes}
        bindTo="text"
        isWithList
        onItemSelect={onItemFilterSearchSelect}
        isCloseOnSelect
        icon={<span className="ui-icon ui-icon-search"></span>}
        placeholder="Поиск идеи"
      />
      <DatePicker
        type={DatePickerType.RANGED}
        date={{ from: filter.from ? new Date(filter.from) : null, to: filter.to ? new Date(filter.to) : null }}
        onChange={({ from, to }) => {
          dispatch(
            selectionSetFilter({
              ...Object.fromEntries(Object.entries(filter).filter(([k]) => !["from", "to"].includes(k))),
              ...(from && { from: getLocalDate(from) }),
              ...(to && { to: getLocalDate(to) }),
            })
          );
        }}
      />
      {folder === "accepted" && (
        <Switcher
          defaultValue={filter.mainOnly || false}
          onChange={(value) => dispatch(selectionSetFilter({ ...filter, mainOnly: value }))}
          html="Только главные"
        />
      )}
    </div>
  );
}
