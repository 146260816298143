import React from "react";
import { Modal } from "@crowd/ui-kit";

import "./MaintenanceModal.scss";

interface Props {
  isOpened: boolean;
  onClose: () => {};
}
const MaintenanceModal = (props: Props) => {
  return (
    <Modal
      classNames={`maintenance-modal`}
      isOpen={!!props.isOpened}
      onClose={props.onClose}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <React.Fragment key={"subhead"}>
        <div className="went-wrong">
          <img src={require("../../../../assets/lock-24px@2x.png").default} alt="Логотип"></img>
          <b>Внимание!</b>
          <p>На сайте ведутся технические работы.</p>
          <p>Приносим извинения за доставленные неудобства.</p>
        </div>
      </React.Fragment>
    </Modal>
  );
};

export default MaintenanceModal;
