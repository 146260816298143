import React, { useState, Fragment } from "react";

import "./AvatarModal.scss";
import { Button, Modal } from "@crowd/ui-kit";
import { User } from "../../../../../types/User.interface";
import { getAvatarSrc } from "../../../../../utils";
import UserService from "../../../../../services/userService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { showErrorToast } from "../../../../../store/actions/ToastActions";
import { useDispatch } from "react-redux";
import { hideInfoModal, showInfoModal } from "../../../../../store/actions/LayoutActions";

interface AvatarModalProps {
  onClose: () => void;
  upload?: boolean;
  gender: string;
  onSuccess: (id: string) => void;
  isOpened?: boolean;
  pictureId?: string;
  onSelectFile?: (evt: any) => void;
  user?: User;
  isPhone?: boolean;
}

const allowedExt = ["image/jpeg", "image/png"];

const AvatarModal = (props: AvatarModalProps) => {
  const [pictureId, setPictureId] = useState(props.pictureId);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const submit = () => {
    if (pictureId === props.pictureId) {
      props.onClose();
      return;
    } else if (!props.upload) {
      props.onSuccess(pictureId);
      props.onClose();
      return;
    }

    setSubmitting(true);
    UserService.uploadDefaultPicture(pictureId)
      .then((res) => {
        if (res.status === ApiStatusCode.OK) {
          props.onSuccess(pictureId);
          props.onClose();
          return;
        }

        if (res.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
          dispatch(showErrorToast(res.message));
        }

        console.warn(res.message);
      })
      .catch((err) => {
        console.warn(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const changeAvatar = (e) => {
    const parent = e.target.closest(".modal-avatar__item");
    const previous = document.querySelector(".modal-avatar__item.selected");
    if (previous) {
      previous.classList.remove("selected");
    }
    parent.classList.add("selected");

    if (e.target.dataset.pictureId) {
      setPictureId(e.target.dataset.pictureId);
    }
  };

  const passFormat = (file) => {
    return allowedExt.includes(file.type);
  };

  const onSelectFile = (e) => {
    if (!passFormat(e.target.files[0])) {
      return dispatch(
        showInfoModal(
          "Вы можете загрузить только файлы форматов jpeg, png.",
          null,
          <Button type="outlined" text="Хорошо" onClick={() => dispatch(hideInfoModal())} size="m" />
        )
      );
    }

    if (e.target.files && e.target.files.length > 0) {
      props.onSelectFile && props.onSelectFile(e.target.files[0]);

      // reset is needed for onChange trigger to fire if the same file was reuploaded
      e.target.value = null;
    }
  };

  const getAvatarList = () => {
    const count = 9;
    const avatarsList: any[] = [];
    for (let i = 1; i <= count; i++) {
      avatarsList.push(getDefaultAvatar(i));
    }

    avatarsList.push(getCurrentAvatar());

    return avatarsList;
  };

  const getDefaultAvatar = (index: number) => {
    return (
      <div key={index} className={`modal-avatar__item ${pictureId === `${props.gender.toLowerCase()}_${index}` ? "selected" : ""}`}>
        <img
          onClick={changeAvatar}
          data-picture-id={index}
          src={getAvatarSrc(`${props.gender.toLowerCase()}_${index}`)}
          alt="logo images"
          className="modal-avatar__avatar"
        />
      </div>
    );
  };

  const getCurrentAvatar = () => {
    return (
      <div key="load avatar" className="modal-avatar__item modal-avatar__item_load">
        <label className="modal-avatar__label">
          <input type="file" onChange={onSelectFile} />
          {props.pictureId && (
            <img onClick={changeAvatar} src={getAvatarSrc(props.pictureId)} alt="logo images" className="modal-avatar__avatar" />
          )}
          <span className="ui-icon-photo"></span>
        </label>
      </div>
    );
  };

  return (
    <Modal isOpen={props.isOpened} onClose={props.onClose} classNames={"modal-avatar"}>
      <Fragment key={"header"}>
        <div className="modal-avatar__title">Выбор и загрузка аватара</div>
      </Fragment>

      <Fragment key={"body"}>
        <div className="modal-avatar__container">{getAvatarList()}</div>
      </Fragment>

      <Fragment key={"footer"}>
        <Button type={props.isPhone ? "outlined-grey" : "outlined"} text="Отменить" size={"m"} onClick={props.onClose} />

        <Button type="filled" text="Сохранить" size={"m"} isLoading={submitting} onClick={submit} />
      </Fragment>
    </Modal>
  );
};

export default AvatarModal;
