import { Button, Loader, Modal } from "@crowd/ui-kit";
import { Fragment, useEffect, useState } from "react";

import "./SelectionIdeaTransferHistoryModal.scss";
import dayjs from "dayjs";
import Table from "../../../../presentational/Table/Table";
import ProposalService from "../../../../../services/proposalService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { useDispatch, useSelector } from "react-redux";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { columns, columnsMobile } from "./SelectionIdeaTransferHistoryModal.helper";
import {
  ProposalStatusChangeEnum,
  ProposalStatusChangeMap,
  ProposalStatusMap,
  ProposalTypeEnum,
} from "../../../../../types/Proposal.interface";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { TableList } from "../../../../presentational/TableList/TableList";
import { selectionIdeaShowTransferHistorySelector } from "../../../../../store/selectors/selection";
import { SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY } from "../../../../../store/actions/SelectionActions";

const IdeaTransferHistoryModal = () => {
  const dispatch = useDispatch();

  const proposal = useSelector(selectionIdeaShowTransferHistorySelector);

  const [history, setHistory] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (proposal) {
      fetchProposal(proposal);
    }
  }, [proposal]);

  const onClose = () => {
    dispatch({ type: SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY, payload: undefined });
  };

  const fetchProposal = async (proposal) => {
    setIsFetching(true);
    try {
      const { status, message, data } = await ProposalService.readHistory(proposal.id);
      if (status === ApiStatusCode.OK) {
        const _history = data.map((item) => {
          const author = item.status === ProposalTypeEnum.FORWARDED ? item.forwardedBy : item.user;
          return {
            ...item,
            type: ProposalStatusChangeMap[item.type],
            status:
              item.type === ProposalStatusChangeEnum.NODE_CHANGED ? ProposalStatusChangeMap[item.type] : ProposalStatusMap[item.status],
            date: dayjs(item.timestamp).format("DD.MM.YYYY HH:mm"),
            author: author ? `${author.firstName} ${author.lastName}` : "",
          };
        });
        setHistory(_history);
      } else {
        throw new Error(message);
      }
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    } finally {
      setIsFetching(false);
    }
  };

  const table = isMobile ? (
    <TableList columns={columnsMobile} data={history} dataHeaderKey="date" />
  ) : (
    <Table columns={columns} data={history} />
  );

  return (
    <div className="idea-transfer-history">
      <Modal
        isOpen={true}
        onClose={onClose}
        classNames={"idea-transfer-history-modal"}
        adjustPaddings={[".app-wrapper", ".cr-header"]}
        adjustMargins={[".scroll-top-button"]}
      >
        <Fragment key={"header"}>История перемещения идеи</Fragment>
        <Fragment key={"body"}>{isFetching ? <Loader /> : table}</Fragment>
        <Fragment key={"footer"}>
          <Button text="Закрыть" type="outlined" size={"m"} onClick={onClose} />
        </Fragment>
      </Modal>
    </div>
  );
};

export default IdeaTransferHistoryModal;
