import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Input, Modal, Radio, Select } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";

import UserService from "../../../../services/userService";
import { RootState } from "../../../../types/State.interface";
import addresses from "../../../../utils/constants/addresses";
import { showOkInfoModal } from "../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import AvatarModal from "../../../pages/Profile/avatar/AvatarModal/AvatarModal";
import CropAvatarModal from "../../../pages/Profile/avatar/CropAvatarModal/CropAvatarModal";
import ProfilePic from "../../../pages/Profile/avatar/ProfilePic/ProfilePic";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import "./AfterRegistrationForm.scss";
import { getRandomInt } from "../../../../utils";
import PointsService, { ActionEventEnum } from "../../../../services/pointsService";
import { User, UserTypeEnum } from "../../../../types/User.interface";
import { ProjectStatus } from "../../../../types/Projects.interface";

const AfterRegistrationForm = (props) => {
  const dispatch = useDispatch();
  const user: User = useSelector((state: RootState) => state.user.userDetails);
  const userRef: { current: User } = useRef();
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  const status: ProjectStatus = useSelector((state: RootState) => state.project.status);

  const [firstName, setFirstName] = useState(user.firstName);
  const [firstNameValidator, setFistNameValidator] = useState<any>(null);
  const [lastName, setLastName] = useState(user.lastName);
  const [lastNameValidator, setLastNameValidator] = useState<any>(null);
  const [email, setEmail] = useState(user.email);
  const [gender, setGender] = useState(user.gender === "UNKNOWN" ? "MALE" : user.gender);
  const [birthYear, setBirthYear] = useState("");

  const [moscowRegion, setMoscowRegion] = useState<any>(addresses.find((ad) => ad.name === user.moscowRegion) || null);
  const [moscowDistrict, setMoscowDistrict] = useState<string>(user.moscowDistrict || null);

  const [consent, setConsent] = useState<boolean>();
  const [file, setFile] = useState(null);
  const [avatarIdx, setAvatarIdx] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [editAvatarModalOpen, setEditAvatarModalOpen] = useState(false);
  const [cropAvatarModalOpen, setCropAvatarModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>();

  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);

    if (formValid()) {
      const formData = {
        ...user,
        firstName,
        lastName,
        email,
        gender,
        emailVerified: user.email === email,
        birthYear,
        moscowRegion: moscowRegion.name,
        moscowDistrict,
        pictureId: getProfilePicId(),
      };

      setSubmitting(true);
      const baseReqs = [updateUserWithDelay(formData)];
      let updates = imageId ? [...baseReqs] : [...baseReqs, UserService.uploadDefaultPicture(avatarIdx || getRandomInt(1, 9))];

      Promise.all(updates)
        .then((responses) => {
          let withError = responses.filter(Boolean).filter((res) => res.status !== ApiStatusCode.OK);

          if (!withError.length) {
            props.onClose();
            showPointsModal();
          } else {
            let error = withError.map((res) => res.message).join(" <br>");
            setError(error);
          }
        })
        .catch((err) => dispatch(showErrorToast(err.message)))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const showPointsModal = () => {
    const msg = `Вы стали пользователем платформы «Город идей» и участником вашего первого проекта.`;
    dispatch(showOkInfoModal("Благодарим за проявленный интерес!", msg));

    // PointsService.getPoints(ActionEventEnum.REGISTRATION)
    //   .then((response) => {
    //     const points = response.data;
    //     let msg = "";
    //     if (userRef.current.userType === UserTypeEnum.SHORT) {
    //       //msg = `Вы стали пользователем платформы «Город идей» и участником вашего первого краудсорсинг-проекта. При наличии полной или стандартной учетной записи на портале <a href="https://my.mos.ru/my/#/" target="_blank">mos.ru </a> вы сможете получать <a href='/pointsRules' target="blank">баллы</a> за участие в проектах.`;
    //       msg = `Вы стали пользователем платформы «Город идей» и участником вашего первого проекта.`;
    //     } else {
    //       //msg = `Вы стали пользователем платформы «Город идей» и участником вашего первого проекта. Вам начислено ${points} приветственных баллов. Ознакомьтесь с информацией о <a href='/pointsRules' target="blank">программе лояльности</a> на платформе.`;
    //       msg = `Вы стали пользователем платформы «Город идей» и участником вашего первого проекта.`;
    //     }
    //     dispatch(showOkInfoModal("Благодарим за проявленный интерес!", msg));
    //   })
    //   .catch((err) => dispatch(showErrorToast(err.message)));
  };

  const getClassesForFormBody = () => {
    let classes = "after-registration-form__body";

    if (submitting) {
      classes += " after-registration-form__body-disabled";
    }

    return classes;
  };

  const getProfilePicId = () => {
    return imageId || (avatarIdx && gender.toLowerCase() + "_" + avatarIdx) || "";
  };

  const getYears = (from, to) => {
    const years: any[] = [];

    while (to >= from) {
      const year = to--;

      years.push({
        name: year,
        value: year,
      });
    }

    return years;
  };

  function updateUserWithDelay(data): Promise<any> {
    return new Promise((res, rej) => {
      UserService.updateUser(data).then((response) => {
        setTimeout(() => {
          res(response);
        }, 3000);
      });
    });
  }

  // VALIDATION

  const formValid = () => {
    return (
      firstNameValidator.isValid() && lastNameValidator.isValid() && gender && birthYear && moscowRegion.name && moscowDistrict && consent
    );
  };

  const openCropAvatarModal = (file) => {
    setFile(file);
    setCropAvatarModalOpen(true);
  };

  const getMoscowDistricts = () => {
    return addresses.find((reg) => reg.name === moscowRegion?.name)?.districts || [];
  };

  // RENDER

  const renderEditAvatar = () => {
    return (
      <>
        {editAvatarModalOpen && (
          <AvatarModal
            isOpened={editAvatarModalOpen}
            gender={gender}
            pictureId={getProfilePicId()}
            onClose={() => setEditAvatarModalOpen(false)}
            onSelectFile={(file) => {
              openCropAvatarModal(file);
            }}
            onSuccess={(imageId) => {
              setImageId(null);
              setAvatarIdx(imageId);
            }}
          />
        )}

        {cropAvatarModalOpen && (
          <CropAvatarModal
            isOpened={cropAvatarModalOpen}
            file={file}
            onClose={() => setCropAvatarModalOpen(false)}
            onSuccess={(imageId) => {
              setImageId(imageId);
              setCropAvatarModalOpen(false);
              setEditAvatarModalOpen(false);
            }}
          />
        )}
      </>
    );
  };

  const renderContent = () => {
    return (
      <form onSubmit={submitForm} className="after-registration-form">
        <div className="after-registration-form">
          <div className="after-registration-form__title">
            <p>
              Вы вошли при помощи <span className="sudir-img"></span> <span className="cr-red">mos.ru</span>
            </p>
            <p>Все поля обязательны для заполнения</p>
          </div>

          <div className={getClassesForFormBody()}>
            <ProfilePic imageId={getProfilePicId() || "undefined"} onClick={() => setEditAvatarModalOpen(true)} showHoverEffect={true} />

            {error && <div className="after-registration-form__errors cr-error" dangerouslySetInnerHTML={{ __html: error }}></div>}

            <Input
              label="Имя"
              value={firstName}
              maxLength={20}
              onChange={(value) => setFirstName(value)}
              shouldValidate={true}
              validateRules={{
                maxLength: 20,
                minLength: 1,
                docName: true,
              }}
              onInitValidator={(validator) => setFistNameValidator(validator)}
              showError={submitted}
            />

            <Input
              label="Фамилия"
              maxLength={20}
              value={lastName}
              onChange={(value) => setLastName(value)}
              shouldValidate={true}
              validateRules={{
                maxLength: 20,
                minLength: 1,
                docName: true,
              }}
              onInitValidator={(validator) => setLastNameValidator(validator)}
              showError={submitted}
            />

            <Select
              items={getYears(1915, new Date().getFullYear() - 14)}
              closeOnSelect={true}
              restrictBy={10}
              bindTo="name"
              label="Год рождения"
              placeholder=""
              onItemSelect={(item) => setBirthYear(item.value)}
              isInvalid={(submitted && !birthYear) || false}
            />

            <div className="after-registration-form__body-radios">
              <p className="after-registration-form__body-radios-title">Пол</p>
              <Radio
                onChange={() => setGender("MALE")}
                id="id1"
                isSelected={gender === "MALE"}
                value="MALE"
                label="M"
                isInvalid={(submitted && !gender) || false}
              />
              <Radio
                onChange={() => setGender("FEMALE")}
                id="id2"
                isSelected={gender === "FEMALE"}
                value="FEMALE"
                label="Ж"
                isInvalid={(submitted && !gender) || false}
              />
            </div>

            <Select
              items={addresses}
              value={moscowRegion}
              closeOnSelect={true}
              bindTo="name"
              label="Округ Москвы"
              onItemSelect={(item) => {
                setMoscowRegion(item);
                setMoscowDistrict(null);
              }}
              isInvalid={(submitted && !moscowRegion) || false}
            />

            <Select
              items={getMoscowDistricts()}
              value={moscowDistrict}
              closeOnSelect={true}
              bindTo="value"
              label="Район Москвы"
              onItemSelect={(item) => {
                setMoscowDistrict(item);
              }}
              isInvalid={(submitted && !moscowDistrict) || false}
            />

            <Checkbox
              html="Я принимаю условия <a href='/license' target='_blank'>Пользовательского соглашения</a>"
              onChange={(value) => setConsent(value)}
              isInvalid={(submitted && !consent) || false}
            />
          </div>

          <div className="after-registration-form__footer">
            <Button size={"m"} type="outlined" text="Отменить" onClick={props.closeWithLogout} />

            <Button size={"m"} type="filled" text="Подтвердить" isLoading={submitting} onClick={submitForm} />
          </div>
        </div>
      </form>
    );
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.closeWithLogout} classNames="after-register-form" root="after-register-form__root">
      <React.Fragment key="body">
        {renderContent()}
        {renderEditAvatar()}
      </React.Fragment>
    </Modal>
  );
};

export default AfterRegistrationForm;
