import { MailTypeEnum } from "../../../types/Mail.interface";
import {
  BlogType,
  DiscussionTypeEnum,
} from "../../../types/Personal.interface";
import { ProposalTypeEnum } from "../../../types/Proposal.interface";

export enum ModuleTypeEnum {
  IDEAS = "IDEAS",
  COMMENTS = "COMMENTS",
  ANSWERS = "ANSWERS",
  MAIL = "MAIL",
  BLOG = "BLOG",
  FAVORITES = "FAVORITES",
  REPORTS = "REPORTS",
  MANAGE = "MANAGE",
}

export interface IModuleCard {
  type: ModuleTypeEnum;
  title: string;
  icon: string;
  items?: any;
  tabs?: IModuleCardTab[];
  iconPhone?: string;
  externalLink?: string;
  titlePhone?:string;
}

export interface IModuleCardTab {
  id: number;
  type: MailTypeEnum | ProposalTypeEnum | DiscussionTypeEnum | BlogType;
  name: string;
  count?: number;
  iconClass?: string;
  activeIconClass?: string;
}
