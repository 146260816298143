import { SelectionFolders } from "../../../../../utils/constants/proposal";
import { SelectionCriteria } from "../SelectionCriteria/SelectionCriteria";
import { SelectionFilters } from "../SelectionFilters/SelectionFilters";
import { SelectionFolderButtons } from "../SelectionFolderButtons/SelectionFolderButtons";
import { SelectionIdea } from "../SelectionIdea/SelectionIdea";
import SelectionInfo from "../SelectionInfo/SelectionInfo";
import { SelectionLoader } from "../SelectionLoader/SelectionLoader";
import { useSelector } from "react-redux";
import {
  selectionIdeasLoadingSelector,
  selectionIdeasSelector,
  selectionIsStageFinishedSelector,
} from "../../../../../store/selectors/selection";
import { useIsMobile, useIsShortDesktop } from "../../../../../utils/hooks/useMedia";
import { useSelectionParams } from "../../helpers/params";
import { SelectionForwardedShowAll } from "../SelectionForwardedShowAll/SelectionForwardedShowAll";

export function SelectionMainContent() {
  const isMobile = useIsMobile();
  const isShortDesktop = useIsShortDesktop();
  const { folder } = useSelectionParams();

  const isStageFinished = useSelector(selectionIsStageFinishedSelector);
  const ideasLoading = useSelector(selectionIdeasLoadingSelector);
  const ideas = useSelector(selectionIdeasSelector);

  if (isStageFinished) return null;

  return (
    <>
      <div className="selection-page__block-indent selection-page__main">
        <div className="flex-fill">
          <SelectionFolderButtons />

          {folder === SelectionFolders.FORWARDED && <SelectionForwardedShowAll />}

          {folder === SelectionFolders.INCOMING || folder === SelectionFolders.FORWARDED ? <SelectionCriteria /> : <SelectionFilters />}

          {ideasLoading && !ideas.length && <SelectionLoader />}

          <div className={ideasLoading ? "selection-page__fetching" : ""}>
            {ideas.map((idea) => (
              <SelectionIdea key={idea.id} idea={idea} />
            ))}
          </div>
        </div>

        {!isMobile && !isShortDesktop && <SelectionInfo />}
      </div>
    </>
  );
}
