import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stage, StageTypes } from "../../../../types/Stage.interface";
import GenerationForm, { GenerationDraft, GenerationFormType } from "../GenerationForm/GenerationForm";
import StageSelectorModal from "../../../containers/Modals/StageSelectorModal/StageSelectorModal";
import { RootState } from "../../../../types/State.interface";
import { showSuccessToast } from "../../../../store/actions/ToastActions";
import { LocalStorageKeys, LocalStorageService } from "../../../../services/LocalStorage.service";

const successMessage = `Спасибо за ваше предложение, оно будет рассмотрено экспертами проекта. За изменением статуса своих идей можно следить в <a class="base-link" href="/profile/ideas">личном кабинете.</a>`;

export interface GenerationFormContainerProps {
  stage: Stage;
  showFormDescription?: boolean;
  showTaskDescription?: boolean;
  showFullScreenButton?: boolean;
  showDraftButton?: boolean;
  showRejectReason?: boolean;
  canSubmitDraft?: boolean;
  draftAutoSubmitDelay?: number;
  onStageSelect: (stage) => void;
  onFormClose: () => void;
  onStageModalOpen?: () => void;
  onStageModalClose: () => void;
  onSubmit?: () => void;
  onLocalDraftId?: (draftId: GenerationDraft["draftId"]) => void;
}

const GenerationFormContainer = forwardRef<GenerationFormType, GenerationFormContainerProps>(({
  stage,
  showTaskDescription,
  showFormDescription,
  showFullScreenButton,
  showDraftButton,
  showRejectReason,
  canSubmitDraft,
  draftAutoSubmitDelay,
  onFormClose,
  onStageSelect,
  onStageModalOpen,
  onStageModalClose,
  onSubmit,
  onLocalDraftId
}, ref) => {
  const dispatch = useDispatch();

  const allStages = useSelector((state: RootState) => state.stage.allStages);

  const [generationStageSelectorModalOpen, setGenerationStageSelectorModalOpen] = useState(false);
  const [activeGenerationStages, setActiveGenerationStages] = useState<Stage[]>([]);

  useEffect(() => {
    const _generationStages = allStages?.filter((_stage) => _stage.type === StageTypes.GENERATION && _stage.status === "STARTED");
    setActiveGenerationStages(_generationStages);
  }, [allStages, stage?.id]);

  const handleSelectStage = (stage) => {
    setGenerationStageSelectorModalOpen(false);
    setTimeout(() => {
      onStageSelect(stage);
    });
  };

  const handleOnSubmitSuccess = (): void => {
    const hasGenerationStages = Boolean(activeGenerationStages.length);
    if (hasGenerationStages) {
      setGenerationStageSelectorModalOpen(true);
      onStageModalOpen?.();
    } else {
      dispatch(showSuccessToast("Ваша идея отправлена"));
    }
    onSubmit?.();
  };

  const handleOnSubmitDraftSuccess = (): void => {
    dispatch(showSuccessToast("Черновик успешно сохранен."));
    onSubmit?.();
  };

  const handleOnFormClose = () => {
    onFormClose?.();
    LocalStorageService.removeData(LocalStorageKeys.GenerationDraft);
  };

  return (
    <>
      <GenerationForm
        ref={ref}
        stage={stage}
        showFormDescription={showFormDescription}
        showTaskDescription={showTaskDescription}
        showFullScreenButton={showFullScreenButton}
        showDraftButton={showDraftButton}
        showRejectReason={showRejectReason}
        draftAutoSubmitDelay={draftAutoSubmitDelay}
        canSubmitDraft={canSubmitDraft}
        onSubmitSuccess={handleOnSubmitSuccess}
        onSubmitDraftSuccess={handleOnSubmitDraftSuccess}
        onClose={handleOnFormClose}
        onLocalDraftId={onLocalDraftId}
      />

      {generationStageSelectorModalOpen && (
        <StageSelectorModal
          isOpen={generationStageSelectorModalOpen}
          close={() => {
            setGenerationStageSelectorModalOpen(false);
            onStageModalClose?.();
          }}
          header={"Ваша идея отправлена"}
          message={successMessage}
          listHeader={"Предложить идею по теме:"}
          onSelect={handleSelectStage}
          stages={activeGenerationStages}
        />
      )}
    </>
  );
});

export default GenerationFormContainer;
