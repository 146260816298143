import { PersonalState } from "../../types/Personal.interface";
import {
  GET_PERSONAL_COMMENTS,
  GET_PERSONAL_PROPOSALS,
  GET_PERSONAL_PROPOSALS_COUNT,
  GET_PERSONAL_COMMENTS_COUNT,
  GET_PERSONAL_ANSWERS_COUNT,
  GET_FAVORITE_DISCUSSIONS,
  GET_FAVORITE_DISCUSSIONS_COUNT,
} from "../actions/PersonalActions";

const initialState: PersonalState = {
  proposals: null,
  proposalsCount: null,
  comments: null,
  commentsCount: null,
  answersCount: null,
  favorite: null,
  favoriteCount: null,
};

export function personalReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_PERSONAL_PROPOSALS:
      return { ...state, proposals: action.payload };

    case GET_PERSONAL_PROPOSALS_COUNT:
      return { ...state, proposalsCount: action.payload };

    case GET_PERSONAL_COMMENTS:
      return { ...state, comments: action.payload };

    case GET_PERSONAL_COMMENTS_COUNT:
      return { ...state, commentsCount: action.payload };

    case GET_PERSONAL_ANSWERS_COUNT:
      return { ...state, answersCount: action.payload };

    case GET_FAVORITE_DISCUSSIONS:
      return { ...state, favorite: action.payload };

    case GET_FAVORITE_DISCUSSIONS_COUNT:
      return { ...state, favorite: action.payload };

    default:
      return state;
  }
}
