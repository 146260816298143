import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FileSelector, Loader } from "@crowd/ui-kit";
import { DefaultFile } from "@crowd/ui-kit/build/components/FileSelector/FileSelector";

import { AddCommentFormProps } from "./AddCommentForm.interface";
import { RootState } from "../../../../types/State.interface";
import useQuoteWindowSelect from "../../../../utils/hooks/useQuoteWindowSelect";
import { addComment, updateComment } from "../../../../store/actions/CommentActions";
import CommentService, { AddCommentParams, UpdateCommentParams } from "../../../../services/commentService";
import { ApiResponse, ApiValidationError } from "../../../../types/Common.interface";
import { Comment } from "../../../../types/Comment.interface";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import "./AddCommentForm.scss";
import { getPlainFromHtml, declOfNum, stripHtmlExcludeLinksAndQuotes, replaceHtmlEntites } from "../../../../utils";
import allowedExtensions from "../../../../utils/constants/allowedExtensions";
import { showSuccessToast } from "../../../../store/actions/ToastActions";
import { showOkInfoModal, showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import LinkPopup, { LinkPopupProps } from "../../../presentational/LinkPopup/LinkPopup";
import TextAreaEditor from "../../../presentational/Controls/TextAreaEditor/TextAreaEditor";
import classNames from "classnames";

export interface ITextQuote {
  authorName: string;
  text: string;
}

const MAX_COMMENT_LEN = 1500; // 100000 for teamMember

const AddCommentForm = ({ parentId, discussionId, cancel, onAddComment, comment, onUpdateComment }: AddCommentFormProps) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [value, setValue] = useState("");
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [valueErrors, setValueErrors] = useState<ApiValidationError[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [defaultFiles, setDefaultFiles] = useState<DefaultFile[]>([]);
  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [currentLink, setCurrentLink] = useState(null);
  const [selection, setSelection] = useState(null);
  const handlerRef = useRef<HTMLLIElement>(null);
  const [isFilesUploading, setIsFilesUploading] = useState(false)

  const user = useSelector((state: RootState) => state.user.userDetails);

  const selectedQuote = useQuoteWindowSelect();
  const editorRef = useRef<HTMLInputElement>();

  const isPrivilegedUser = user.teamMember || user.expert || user.chiefExpert;

  useEffect(() => {
    if (comment) {
      const commentText = comment?.rawText ? CommentService.toHTMLQuote(comment?.rawText) : "";
      editorRef.current.innerHTML = commentText;
      setValue(commentText);
      setDefaultValue(commentText);
      setDefaultFiles(comment.attachments);
    }
  }, [comment]);

  const getQuoteTemplate = (q: ITextQuote): string => {
    return (
      '<div class="textAreaEditor__quote" contenteditable="false">' +
      (q.authorName ? '<div class="textAreaEditor__quote__author">' + q.authorName + "</div>" : "") +
      '<div class="textAreaEditor__quote__text"><pre>' +
      q.text +
      "</pre></div></div><div><br></div>"
    );
  };

  const setSelectedText = (): void => {
    selectedQuote?.text
      ? setValue((editorRef.current.innerHTML += getQuoteTemplate(selectedQuote)))
      : dispatch(showOkInfoModal(null, "Для цитирования выделите какой-либо текст."));
  };

  const getQuoteLen = (): number => {
    return CommentService.getPlainCommentText(value).length;
  };

  const isEmpty = (text: string) => !getPlainFromHtml(text).trim();

  const isFilesChanged = (): boolean => {
    return JSON.stringify(defaultFiles.map((f) => f.id).join("-")) !== JSON.stringify(files.map((f) => f.id).join("-"));
  };

  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setSubmitted(true);

    if (isEmpty(value)) {
      setLoading(false);
      return;
    }

    const data: AddCommentParams = {
      attachments: files.map((file) => file.id),
      discussionId,
      text: stripHtmlExcludeLinksAndQuotes(CommentService.toBlockquote(value)),
      parentId,
    };

    dispatch(addComment(data, successSubmit, finallySubmit, validateErrSubmit));
  };

  const submitUpdateForm = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setSubmitted(true);

    if (isEmpty(value) || (defaultValue === value && !isFilesChanged())) {
      setLoading(false);
      return;
    }

    const data: UpdateCommentParams = {
      attachments: files.map((file) => file.id),
      id: comment.id,
      text: stripHtmlExcludeLinksAndQuotes(CommentService.toBlockquote(value)),
    };

    dispatch(updateComment(data, successUpdateSubmit, finallySubmit));
  };

  const successUpdateSubmit = (data: ApiResponse<Comment>) => {
    data.data && onUpdateComment && onUpdateComment(data.data);
    cancel();
  };

  const successSubmit = (data: ApiResponse<Comment>) => {
    data.data && onAddComment(data.data);
    cancel();
    dispatch(showSuccessToast("Комментарий успешно опубликован."));
  };

  const finallySubmit = () => {
    setLoading(false);
  };

  const validateErrSubmit = (errors: ApiValidationError[]) => {
    setValueErrors(errors);
    setLoading(false);
  };

  const handleFilesUploadingChange = (uploading) => {
    setIsFilesUploading(uploading)
  }

  const getDisabled = () => {
    if (!value) return true;
    if (value === defaultValue && !isFilesChanged()) return true;
    if (!isPrivilegedUser && getQuoteLen() > MAX_COMMENT_LEN) return true;
    return false;
  };

  const onAddLink = (link) => {
    const newValue = selection ? createSelectionValue(link) : value + link;
    editorRef.current.innerHTML = newValue;
    setValue(newValue);
  };

  const createSelectionValue = (link) => {
    const { focusOffset, text, backward } = selection;
    const textLength = text?.length || 0;
    selection.focusNode.textContent = selection.focusNode.textContent.splice(
      !backward && textLength ? focusOffset - textLength : focusOffset,
      textLength,
      link
    );

    return replaceHtmlEntites(editorRef.current.innerHTML);
  };

  return (
    <>
      {isLoading && (
        <div className="add-comment__loader">
          <Loader />
        </div>
      )}

      <form className={"add-comment"} style={{ display: isLoading ? "none" : "block" }}>
        <TextAreaEditor
          maxLength={MAX_COMMENT_LEN}
          placeholder="Ваш комментарий"
          value={value}
          beforeValidaton={() => !isPrivilegedUser}
          onSetSelection={(selection) => setSelection(selection)}
          onChange={(value) => setValue(value)}
          getRef={(ref) => (editorRef.current = ref)}
          error={!!valueErrors?.length && submitted ? valueErrors[0].description : ""}
          onSetCurrentLink={(link) => {
            setCurrentLink(link);
            setShowLinkPopup(true);
          }}
        />

        <FileSelector
          handlerRef={handlerRef}
          defaultFiles={defaultFiles}
          text={null}
          isMultiple={true}
          maxTotalSizeMB={20}
          maxFiles={5}
          onChange={setFiles}
          upload={true}
          allowedExtensions={allowedExtensions}
          showInfoModal={(msg) => {
            dispatch(showErrorInfoModal(msg));
          }}
          attachmentsLength={files.length}
          onUploadingChange={handleFilesUploadingChange}
        />

        <div className="add-comment__buttons">
          <Button
            text={!!comment ? "Сохранить" : "Опубликовать"}
            type={"filled"}
            size={"m"}
            isDisabled={getDisabled()}
            onClick={!comment ? submitForm : submitUpdateForm}
          />

          <Button text={"Отменить"} type={"outlined"} size={"m"} onClick={cancel} />

          <div className="add-comment__buttons__icon-btns">
            <Tooltip text={"Цитировать"} idx={(comment?.id || parentId || discussionId) + "quote"} className={"add-comment__icon"}>
              <i className={"ui-icon ui-icon-quote"} onClick={setSelectedText} />
            </Tooltip>
            <Tooltip text={"Добавить файл"} idx={(comment?.id || parentId || discussionId) + "pic"} className={"add-comment__icon"}>
              <i
                className={classNames("ui-icon ui-icon-pic pointer", { "disabled-btn": isFilesUploading })}
                ref={handlerRef}
              />
            </Tooltip>

            <div className="add-comment__link add-comment__icon" style={{ position: "relative" }}>
              <Tooltip text={"Добавить ссылку"} idx={(comment?.id || parentId || discussionId) + "link"} className={"add-link__icon"}>
                <i data-lp className="ui-icon ui-icon-add-link pointer" onClick={() => setShowLinkPopup(true)}></i>
              </Tooltip>
              {showLinkPopup && (
                <LinkPopup
                  text={selection?.text}
                  link={currentLink}
                  onEditSave={() => setValue(replaceHtmlEntites(editorRef.current.innerHTML))}
                  onSubmit={onAddLink}
                  onClose={() => {
                    setCurrentLink(null);
                    setShowLinkPopup(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCommentForm;
