import { UserRoleString } from "../../types/User.interface";

export const UserRoleLabels: Record<UserRoleString, string> = {
  PROJECT_BOSS: "Руководитель проекта",
  MANAGER: "Менеджер проекта",
  FACILITATOR: "Координатор",
  PROJECT_FACILITATOR: "Координатор проекта",
  CHIEF_EXPERT: "Главный эксперт",
  EXPERT: "Эксперт",
  ADMINISTRATOR: "Администратор",
  ANALYST: "Аналитик",
  SUPPORT: "Специалист службы ТП",
  VIP: "VIP",
  PARTICIPANT: "Участник",
  EX_PARTICIPANT: "Бывший участник",
  POTENTIAL: "Потенциальный участник",
  FACILITATOR_IZRG: "Координатор ИЖРГ"
};
