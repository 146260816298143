import { Modal } from "@crowd/ui-kit";
import { ComponentProps, FC, useEffect, useRef } from "react";
import { setTrackIdToEl } from "../../../../../utils/eventsTrack";

const EventsTrackModal: FC<ComponentProps<typeof Modal>> = (props) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    setTimeout(() => {
      if (props.isOpen) {
        const el = ref.current?.querySelector("#close-modal-icon") as HTMLElement;
        setTrackIdToEl(el, ["ICON", "CLICK"], "click", { replace: ["Крестик"], partial: true });
      }
    });
  }, [props.isOpen]);

  return <Modal ref={ref} {...props} />;
};

export default EventsTrackModal;
