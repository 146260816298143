import { ComplaintAddParams } from "../../types/Complaint.interface";
import ComplaintService from "../../services/complaintService";
import { ApiStatusCode } from "../../types/Common.interface";
import { showErrorToast, showSuccessToast } from "./ToastActions";

export const ADD_COMPLAIN_COMPLETED = "ADD_COMPLAIN_COMPLETED";
export const ADD_COMPLAIN_FAILED = "ADD_COMPLAIN_FAILED";

export const addComplaint =
  (data: ComplaintAddParams, onSuccess?: () => any, onFail?: () => any) =>
  (dispatch) => {
    ComplaintService.addComplaint(data).then((res) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: ADD_COMPLAIN_COMPLETED });
        dispatch(showSuccessToast("Жалоба успешно отправлена."));
        if (onSuccess) onSuccess();
      } else {
        dispatch({ type: ADD_COMPLAIN_FAILED });
        dispatch(showErrorToast(res.message));
        if (onFail) onFail();
      }
    });
  };
