import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideFooter, hideHeader } from "../../../store/actions/LayoutActions";
import { declination } from "../../../utils";

import "./BlockedPage.scss";

const BlockedPage = (props) => {
  const dispatch = useDispatch();
  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesleft] = useState(0);

  useEffect(() => {
    dispatch(hideHeader());
    dispatch(hideFooter());
  }, []);

  // const setExpirationTime = () => {
  //   const _minute = 1000 * 60;
  //   const _hour = _minute * 60;
  //   const _day = _hour * 24;
  //   const blockExpirationDate = new Date(props.location.state.blockExpiration);
  //   const now = new Date();
  //   const distance = blockExpirationDate.getTime() - now.getTime();

  //   setDaysLeft(Math.floor(distance / _day));
  //   setHoursLeft(Math.floor((distance % _day) / _hour));
  //   setMinutesleft(Math.floor((distance % _hour) / _minute));
  // };

  return (
    <div className="blocked-page">
      <div className="blocked-page__content">
        <div className="blocked-page__image"></div>

        <div className="blocked-page__text">Учётная запись</div>
        <div className="blocked-page__text blocked-page__text--error">Заблокирована</div>
        <div className="blocked-page__text">
          Информация о блокировке направлена на вашу электронную почту, указанную при регистрации на платформе.
        </div>
        {props.location.state?.blockExpiration && (
          <>
            <div className="blocked-page__text blocked-page__text">До разблокировки осталось:</div>
            <div className="blocked-page__text blocked-page__text--countdown">
              <span className="blocked-page__text--countdown__digits">{daysLeft}</span>
              <span> {declination(daysLeft, [" день", " дня", " дней"])}</span>
              <span className="blocked-page__text--countdown__digits">{hoursLeft}</span>
              <span> {declination(hoursLeft, [" час", " часа", " часов"])}</span>
              <span className="blocked-page__text--countdown__digits">{minutesLeft}</span>
              <span> {declination(minutesLeft, [" минута", " минуты", " минут"])}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlockedPage;
