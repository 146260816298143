import React, { useState, useLayoutEffect, useRef } from "react";
import { AttachmentPreview, Button } from "@crowd/ui-kit";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { ProposalTypeEnum, VotingParams, SkippingVoteParams } from "../../../../types/Voting.interface";
import VotingService from "../../../../services/votingService";
import VotingOptions from "../VotingOptions/VotingOptions";
import "./VotingProposal.scss";
import { showErrorToast, showSuccessToast } from "../../../../store/actions/ToastActions";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import { debounce } from "../../../../utils";
import { ColoredIcons } from "../Voting.constants";

interface Props {
  proposal: any;
  currentFolder: any;
  stage: any;
  afterVotingAction: Function;
  hideButtons?: boolean;
}

const VotingProposal = (props: Props) => {
  const dispatch = useDispatch();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const touchScreenOptions = true;
  const canEditVote = props.currentFolder === ProposalTypeEnum.VOTED && !props.hideButtons;
  const canSkipVote = props.currentFolder === ProposalTypeEnum.PENDING && !props.hideButtons;
  const canVote = props.currentFolder !== ProposalTypeEnum.MINE && !props.hideButtons;

  const [isVotingOptionsOpen, setIsVotingOptionsOpen] = useState(touchScreenOptions && !canEditVote);
  const [isLoadingVoting, setIsLoadingVoting] = useState(false);
  const [isLoadingSkipping, setIsLoadingSkipping] = useState(false);

  const votingCriteria = props.stage.taskSpecification?.criterias[0];
  const defaultValue = votingCriteria.items?.find((item) => item.default)?.value || null;
  const showItemNumbers = props.proposal.items?.length > 1;

  const [selectedVoteValue, setSelectedVoteValue] = useState(
    (props.proposal.voted && props.proposal.votes[0]?.value?.toString()) || defaultValue
  );

  const proposalQuestionRef = useRef<any>();
  const [proposalActionsMarginTop, setProposalActionsMarginTop] = useState<any>("");

  // INIT

  const updateMarginTop = () => {
    if (proposalQuestionRef.current) {
      let nodeStyle = window.getComputedStyle(proposalQuestionRef.current);
      let marginTop = parseFloat(nodeStyle.getPropertyValue("margin-top"));
      if (touchScreenOptions) {
        setProposalActionsMarginTop(proposalQuestionRef.current.offsetHeight + marginTop + 16);
      } else {
        setProposalActionsMarginTop(24);
      }
    }
  };
  useLayoutEffect(() => {
    updateMarginTop();
  }, [proposalQuestionRef.current?.offsetHeight, touchScreenOptions]);

  window.addEventListener(
    "resize",
    debounce((e) => updateMarginTop(), 500)
  );

  const getItemTitle = (item) => {
    let title = "";
    const taskItems = props.proposal.stage?.taskItems || [];
    if (taskItems.length > 0 && !!item?.metaId) {
      title = taskItems.find((taskItem) => taskItem.id === item?.metaId)?.title || "";
    }
    return title;
  };

  const getProposalVotingQuestion = () => {
    return votingCriteria?.title || "";
  };

  const getProposalSelectedOption = () => {
    if (votingCriteria?.id === props.proposal.votes[0]?.criteriaId) {
      return votingCriteria?.items.find((item) => item.value === props.proposal.votes[0]?.value);
    } else {
      return "";
    }
  };

  const toggleVotingOptions = (e?) => {
    if (e) e.preventDefault();
    if (!canVote) return;
    setIsVotingOptionsOpen(!isVotingOptionsOpen);
  };

  const voteAction = (e?, update?, passedValue?) => {
    if (e) e.preventDefault();
    if (isVotingOptionsOpen) {
      const value = passedValue || selectedVoteValue || null;
      if (value === null) return;

      const votingParams: VotingParams = {
        answers: { [votingCriteria.id]: value },
        proposalId: props.proposal.id,
        stageId: props.stage.id,
      };
      setIsLoadingVoting(true);
      VotingService.vote(votingParams)
        .then((response) => {
          if (update) {
            toggleVotingOptions();
          }

          dispatch(showSuccessToast("Идея оценена."));
          props.afterVotingAction(true);
        })
        .catch((err) => {
          console.warn(err);
          dispatch(showErrorToast("Ошибка оценки."));
        })
        .finally(() => setIsLoadingVoting(false));
    } else {
      setIsVotingOptionsOpen(true);
    }
  };

  const skipAction = (e) => {
    if (e) e.preventDefault();
    const skippingVoteParams: SkippingVoteParams = {
      proposalId: props.proposal.id,
      skipped: true,
      stageId: props.stage.id,
    };
    setIsLoadingSkipping(true);
    VotingService.skipVote(skippingVoteParams)
      .then((response) => {
        dispatch(showSuccessToast("Идея успешно отложена."));
        props.afterVotingAction();
      })
      .catch((err) => {
        dispatch(showErrorToast(err?.message || "Ошибка"));
        console.warn(err);
      })
      .finally(() => setIsLoadingSkipping(false));
  };

  const onAnswerChange = (value, immediate) => {
    setSelectedVoteValue(value);
    if (immediate) voteAction(null, true, value);
  };

  const renderVotingProposalDescription = () => {
    return (
      <div className="proposal__description">
        {props.proposal.items?.map((item, idx) => (
          <div className={`proposal__description__item`} key={idx}>
            <div className="proposal__description__item__title">
              {showItemNumbers && <div className="proposal__description__item__number">{`${item.orderNumber + 1}. `}</div>}
              {getItemTitle(item)}
            </div>
            <div
              className="proposal__description__item__value"
              dangerouslySetInnerHTML={{ __html: !!item.valueRaw ? item.valueRaw : <i>Не указано</i> }}
            ></div>
          </div>
        ))}
      </div>
    );
  };

  const renderEditableSelectedOption = () => {
    const selectedOption = getProposalSelectedOption();
    return (
      <div className="proposal__voting__selected-vote">
        <div
          className={"ui-icon rating-icon " + (ColoredIcons[selectedOption.rating]?.icon || "ui-icon-done")}
          style={{
            color: ColoredIcons[selectedOption.rating]?.color || "#00CC88",
          }}
        />
        <div className="proposal__voting__selected-vote__text">{selectedOption.name}</div>
        <Tooltip text={"Изменить оценку"} idx={"changeVote" + props.proposal.id}>
          <div className="ui-icon ui-icon-edit" onClick={(e) => toggleVotingOptions(e)} />
        </Tooltip>
      </div>
    );
  };

  const renderVotingProposal = () => {
    return (
      <div className="proposal">
        <div className="proposal__clickable-area" onClick={(e) => !touchScreenOptions && toggleVotingOptions(e)}>
          <div className="proposal__title">{props.proposal.title}</div>

          {renderVotingProposalDescription()}
          {Boolean(props.proposal.attachments?.length) && (
            <div className="proposal__attachments scroll-x" onClick={(e) => e.stopPropagation()}>
              {props.proposal.attachments.map((attch) => (
                <AttachmentPreview attachment={attch} key={attch.id} />
              ))}
            </div>
          )}
        </div>

        <div className="proposal__footer">
          {canEditVote && !isVotingOptionsOpen && renderEditableSelectedOption()}

          {canVote && isVotingOptionsOpen && (
            <div className="proposal__voting">
              <div className="proposal__voting__question" ref={proposalQuestionRef}>
                {getProposalVotingQuestion()}
              </div>
              <VotingOptions
                proposalId={props.proposal.id}
                votingCriteria={votingCriteria}
                selectedVoteValue={selectedVoteValue}
                onAnswerChange={onAnswerChange}
                key={`${props.proposal.id}-${votingCriteria.id}`}
                touchScreenOptions={touchScreenOptions}
              />
            </div>
          )}

          {(!canEditVote || (canEditVote && isVotingOptionsOpen)) && (
            <div
              className="proposal__actions"
              style={{ marginTop: !isPhone && proposalActionsMarginTop ? proposalActionsMarginTop + "px" : "" }}
            >
              {canVote && !touchScreenOptions && (
                <Button
                  type={"filled"}
                  text="Оценить"
                  onClick={(e) => voteAction(e, canEditVote)}
                  size="m"
                  isDisabled={isVotingOptionsOpen && selectedVoteValue === null}
                  isLoading={isLoadingVoting}
                />
              )}
              {canEditVote && <Button type="outlined" text="Отменить" onClick={(e) => toggleVotingOptions(e)} size="m" />}
              {canSkipVote && (
                <Button
                  type="outlined"
                  text={isPhone ? "Отложить" : "Отложить идею"}
                  onClick={(e) => skipAction(e)}
                  size="m"
                  isLoading={isLoadingSkipping}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return renderVotingProposal();
};

export default VotingProposal;
