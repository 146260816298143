import { AxiosResponse } from "axios";
import { Project } from "../../types/Projects.interface";
import StageService from "../../services/stageService";
import { ApiResponse, ApiStatusCode } from "../../types/Common.interface";
import { showSuccessToast } from "./ToastActions";

export const GET_ALL_STAGES = "GET_ALL_STAGES";
export const GET_STAGE_BY_ID = "GET_STAGE_BY_ID";
export const GET_ACCESSIBLE_IDS = "GET_ACCESSIBLE_IDS";
export const STOP_STAGE_COMPLETE = "STOP_STAGE_COMPLETE";

export const getAllStages = (id?: string) => (dispatch) => {
  return StageService.getStages(id)
    .then((data: AxiosResponse<Project>) => {
      dispatch({
        type: id ? GET_STAGE_BY_ID : GET_ALL_STAGES,
        payload: data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getAccessibleIds = () => (dispatch) => {
  return StageService.getAccessibleIds()
    .then(({ data }: AxiosResponse<string[]>) => {
      dispatch({
        type: GET_ACCESSIBLE_IDS,
        payload: data,
      });
    })
    .catch((err) => console.log(err));
};

export const stopStage = (id: string, successCb?: () => any) => (dispatch) => {
  return StageService.stop(id).then((res: ApiResponse) => {
    if (res.status === ApiStatusCode.OK) {
      dispatch({ type: STOP_STAGE_COMPLETE });
      dispatch(showSuccessToast("Этап успешно завершен."));
      successCb && successCb();
    }
  });
};
