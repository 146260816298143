import React from "react";
import ItemStats from "../ItemStats/ItemStats";
import {
  calculateRemainedTime,
  itemIsAnnouncement,
  itemIsCurrent,
  itemIsFinished,
} from "../../../utils";
import { Project } from "../../../types/Projects.interface";
import { Stage } from "../../../types/Stage.interface";

export interface ItemLifecycleProps {
  item: Stage | Project;
  isMain?: boolean;
  type?: "STAGE" | "PROJECT";
}

export const ItemLifecycle = ({
  item,
  isMain = false,
  type = "STAGE",
}: ItemLifecycleProps) => {
  const getRemainedTime = (): string[] => calculateRemainedTime(item);

  const itemIsProject = () => type === "PROJECT";

  const renderLifecycleText = (): string => {
    const [_time, _unit] = getRemainedTime();
    const time = `<b>${_time}</b> ${_unit}`;

    if (itemIsAnnouncement(item))
      return `${time} до старта` + (itemIsProject() ? " проекта" : "");
    if (itemIsCurrent(item))
      return `${time} до завершения` + (itemIsProject() ? " проекта" : "");
    if (itemIsFinished(item)) {
      return itemIsProject() ? "Проект завершён" : "Завершён";
    }
    return "";
  };

  return (
    <ItemStats icon={"clock"} text={renderLifecycleText()} isMain={isMain} />
  );
};

export default ItemLifecycle;
