const postMock = {
  active: null,
  activityStopper: null,
  attachments: null,
  author: null,
  blogId: null,
  commentsEnabled: null,
  complaintMade: null,
  content: null,
  createdTimestamp: null,
  discussionId: null,
  discussionStatistics: null,
  discussionPermissions: null,
  statistics: null,
  draft: null,
  editedTimestamp: null,
  hidden: null,
  id: null,
  lastEditDate: null,
  lastEditUser: null,
  permissions: null,
  rawContent: null,
  shortContent: null,
  summary: null,
  title: null,
  stageId: null,
  displayMain: null,
};

export { postMock };
