import { ProposalTaskItem } from "./Proposal.interface";

export interface Stage {
  active: boolean;
  description: string;
  shortStructureDescription: string;
  discussion: boolean;
  finish: string;
  finishConfirmed: string;
  firstRing: string;
  id: string;
  blogId: string;
  node: StageNode;
  permissions: StagePermissionsString[];
  secondRing: string;
  start: string;
  startedAutomatically: boolean;
  stats: StageStatistics;
  status: StageStatusString;
  taskSpecification: any;
  thirdRing: string;
  title: string;
  type: StageType;
  winnerProposalIds: string[];
  priority: number;
  taskItems: ProposalTaskItem[];
}

export enum StageTypes {
  GENERATION = "GENERATION",
  VOTING = "VOTING",
  EXPERT_SELECTION = "EXPERT_SELECTION",
  IMPROVEMENT = "IMPROVEMENT",
  SIMPLE_DISCUSSION = "SIMPLE_DISCUSSION",
  PROJECT_DISCUSSION = "PROJECT_DISCUSSION",
}

export enum StageLinks {
  GENERATION = "generation",
  VOTING = "voting",
  EXPERT_SELECTION = "selection",
  IMPROVEMENT = "improvement",
  SIMPLE_DISCUSSION = "simpdisc",
  PROJECT_DISCUSSION = "projdisc",
}

export type StageType = keyof typeof StageTypes;

export interface StageState {
  allStages: Stage[];
  stage: Stage | null;
  accessibleStages: Stage[];
  accessibleIds: string[];
}

export enum StagePermissions {
  STAGE_VIEW_CHOSEN,
  STAGE_VIEW_STRUCTURES,
  STAGE_EDIT_STRUCTURES,
  STAGE_APPROVE_STRUCTURES,
  STAGE_CANCEL,
  STAGE_STOP,
  STAGE_SUBMIT_PROPOSAL,
  STAGE_CONFIRM_FINISH,
  STAGE_VIEW_FINISH_CONDITIONS,
  STAGE_MAKE_VOTES,
  STAGE_ALTER_RESULTS,
}

export type StagePermissionsString = keyof typeof StagePermissions;

export interface StageStatistics {
  chosenByDiscussion: any;
  chosenCount: number;
  hitCount: number;
  pendingProposalCount: number;
  proposalCount: number;
  proposalGroupCount: number;
  stageId: string;
  stageType: StageType[];
  structCount: number;
  userCount: number;
}

export enum StageStatus {
  NEW,
  STARTED,
  FINISHED,
  CANCELLED,
}

export type StageStatusString = keyof typeof StageStatus;

export interface StageNode {
  blogId: string;
  children: any[];
  description: string;
  id: string;
  stages: StageSetup;
  title: string;
}

export interface StageSetup {
  description: string;
  finish: string;
  id: string;
  start: string;
  startedAutomatically: boolean;
  taskSpecification: AbstractTaskSpecification;
  title: string;
  type: StageType[];
}

export interface AbstractTaskSpecification {
  type: TaskSpecificationString[];
}

export enum TaskSpecification {
  GENERATION,
  VOTING,
  DISCUSSION,
  SELECTION,
}

export type TaskSpecificationString = keyof typeof TaskSpecification;
