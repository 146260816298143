import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { RootState } from "../../../../types/State.interface";
import addresses from "../../../../utils/constants/addresses";
import "./EditInfo.scss";
import UserService from "../../../../services/userService";
import { Button, Input, Select } from "@crowd/ui-kit";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { getUser } from "../../../../store/actions/UserActions";
import { showErrorToast, showSuccessToast } from "../../../../store/actions/ToastActions";
import ProfilePic from "../avatar/ProfilePic/ProfilePic";
import HiddenFIOWarning from "../HiddenFIOWarning/HiddenFIOWarning";

const EditInfo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [fName, setFName] = useState(user?.firstName);
  const [lName, setLName] = useState(user?.lastName);
  const [moscowRegion, setMoscowRegion] = useState<any>(addresses.find((ad) => ad.name === user.moscowRegion));
  const [moscowDistrict, setMoscowDistrict] = useState<string>(user.moscowDistrict);
  const [email, setEmail] = useState(user?.email);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const save = () => {
    if (!isValid()) return setSubmitted(true);

    const formData = {
      firstName: fName,
      lastName: lName,
      email,
      moscowRegion: moscowRegion.name,
      moscowDistrict,
    };

    setSubmitting(true);
    UserService.updateUser(formData)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          dispatch(showSuccessToast("Профиль успешно изменен."));
          history.push("/profile");
          return;
        }

        dispatch(showErrorToast(response.message));
      })
      .catch((err) => {
        dispatch(showErrorToast(err.message));
      })
      .finally(() => {
        setSubmitting(false);
        dispatch(getUser());
      });
  };

  const isValid = () => {
    return fName && lName && moscowRegion;
  };

  const getMoscowDistricts = () => {
    return addresses.find((reg) => reg.name === moscowRegion?.name)?.districts || [];
  };

  // RENDER

  return (
    <div className="edit-info">
      <ProfilePic imageId={user.pictureId} onClick={props.onAvatarClick} showHoverEffect={true} />

      <Input
        label="Имя"
        type="text"
        value={fName}
        onChange={(value) => setFName(value)}
        shouldValidate={true}
        validateRules={{
          maxLength: 20,
          minLength: 1,
          docName: true,
        }}
        showError={submitted}
      />
      <Input
        label="Фамилия"
        type="text"
        value={lName}
        onChange={(value) => setLName(value)}
        shouldValidate={true}
        validateRules={{
          maxLength: 20,
          minLength: 1,
          docName: true,
        }}
        showError={submitted}
      />

      <Select
        items={addresses}
        value={moscowRegion}
        closeOnSelect={true}
        bindTo="name"
        onItemSelect={(item) => {
          setMoscowRegion(item);
          setMoscowDistrict(item.districts[0]);
        }}
        isInvalid={(submitted && !moscowRegion) || false}
      />

      <Select
        items={getMoscowDistricts()}
        value={moscowDistrict}
        closeOnSelect={true}
        bindTo="value"
        onItemSelect={(item) => {
          setMoscowDistrict(item);
        }}
        isInvalid={(submitted && !moscowDistrict) || false}
      />

      <Input
        label="Электронная почта"
        placeholder="Электронная почта"
        type="text"
        value={email}
        readonly={true}
        onChange={(value) => setEmail(value)}
      />

      <div className="edit-info__actions">
        {!user.showName && <HiddenFIOWarning inline />}

        <Button type="filled" size="m" text="Сохранить" onClick={save} isLoading={submitting} />
        <Button type="outlined-grey" size="m" text="Отменить" onClick={() => history.push("/profile")} />
      </div>
    </div>
  );
};

export default EditInfo;
