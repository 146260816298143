export const ColoredIcons: any = {
  POSITIVE: {
    icon: "ui-icon-done",
    color: "#00cc88",
  },
  NEGATIVE: {
    icon: "ui-icon-close-round",
    color: "#FF6677",
  },
  NEUTRAL: {
    icon: "ui-icon-help",
    color: "#FFA95C",
  },
};
