import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { scrollTop } from "../../../../utils";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

import "./ScrollTopButton.scss";

export default function ScrollTopButton(props) {
  const scroll = useSelector((state: RootState) => state.globalEvents.scroll);

  return (
    <>
      {scroll > 800 && (
        <div className="scroll-top-button__wrapper">
          <EventsTrackWrapperClick id={["ICON", "CLICK"]} needParent params={{ replace: ["Назад"] }}>
            <div className="scroll-top-button" onClick={() => scrollTop()}>
              <div className="ui-icon-arrow-up"></div>
            </div>
          </EventsTrackWrapperClick>
        </div>
      )}
    </>
  );
}
