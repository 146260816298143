import { User } from "../../../../../../types/User.interface";

export interface ChatMessageRaw {
  id: string;
  senderId: string;
  recipientId: string;
  text: string;
  attachments?: any[];
  created?: number;
  read?: boolean;
  status?: ChatMessageStatus;
  removed?: boolean;
}

export interface ChatMessage extends Omit<ChatMessageRaw, 'senderId' | 'recipientId'> {
  sender: User;
  recipient: User;
}

export enum ChatMessageStatus {
  DELETED_BY_ME = "DELETED_BY_ME",
  DELETED_BY_PEER = "DELETED_BY_PEER",
  VISIBLE = "VISIBLE",
  SENDING = "SENDING"
}

export interface MessageGroup {
  date: string;
  label: string;
  messages: ChatMessage[];
}

export interface Dialog {
  peer: User;
  id: string;
  lastMessageStatus: ChatMessageStatus;
  lastMessageText: string;
	lastMessageCreated: string;
	isAllRead: boolean;
  unreadCount: number;
}