import React from "react";
import { Link } from "react-router-dom";

import "./ArrowBack.scss";

interface Props {
  text: string;
  onClick?: () => void;
  linkUrl?: string;
}

const ArrowBack = (props: Props) => {
  const onClick = () => {
    if (props.onClick && typeof props.onClick === "function") {
      props.onClick();
    } else {
      console.error("Please provide a callback for ArrowButton component");
    }
  };

  return (
    <div className="cr-back-button" onClick={onClick}>
      <div className="cr-back-button__icon">
        <div className="ui-icon-arrow-back" />
      </div>
      <div className="cr-back-button__text">{props.text}</div>
      {props.linkUrl && (
        <Link to={props.linkUrl} className="cr-back-button__link" />
      )}
    </div>
  );
};

export default ArrowBack;
