import React from "react";
import ContentLoader from "react-content-loader";
import "./CommentListLoader.scss";

export default function CommentListLoader(props) {
  return (
    <div className="comment-list-loader">
      <ContentLoader
        height="100%"
        width="100%"
        viewBox="0 0 800 575"
        gradientRatio={0.2}
        speed={2}
        {...props}
        backgroundColor="#e8e8e8"
        foregroundColor="#ecebeb"
        title="Загрузка..."
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="130" />
        <rect x="0" y="145" rx="0" ry="0" width="100%" height="130" />
        <rect x="0" y="290" rx="0" ry="0" width="100%" height="130" />
      </ContentLoader>
    </div>
  );
}
