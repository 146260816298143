import React, { Children } from "react";
import "./DirtyWordsChecker.scss";

interface DirtyWordsCheckerProps {
  words: any;
  onInit: (controller) => void;
}

export class DirtyWordsChecker extends React.Component<DirtyWordsCheckerProps, null> {
  ref;
  highlightRef;
  text = "";
  element = null;

  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.highlightRef = React.createRef();
  }

  componentDidMount() {
    this.element = this.getElement();
    if (this.element) {
      this.element.addEventListener("input", (e) => this.onChange(e));
      this.element.addEventListener("scroll", (e) => this.onScroll(e));
    }

    this.props.onInit({ isValid: () => this.isValid() });
  }

  getElement() {
    if (this.ref?.current) {
      return (
        this.ref.current.querySelector("textarea") ||
        this.ref.current.querySelector("input") ||
        this.ref.current.querySelector(".textAreaEditor__editable")
      );
    }

    return null;
  }

  onChange(e) {
    this.text = (e.target.value || e.target.innerText)
      .split(" ")
      .map((i) => (this.props.words.includes(i.trim().toLowerCase()) ? `<mark>${i.trim()}</mark>` : i))
      .join(" ");

    this.forceUpdate();
  }

  isValid() {
    return !this.text.includes("mark");
  }

  renderElement() {
    const childElement = React.Children.only(this.props.children) as any;

    return React.cloneElement(childElement);
  }

  getRootStyles() {
    let styles = {};

    if (this.element) {
      styles["height"] = this.element.offsetHeight + "px";
    }

    return styles;
  }

  onScroll(e) {
    this.highlightRef.current.querySelectorAll("mark").forEach((element) => {
      element.style.top = -e.target.scrollTop + "px";
    });
  }

  getValue() {}

  render() {
    return (
      <div className="spell-checker" style={this.getRootStyles()} ref={this.ref}>
        <div className="spell-checker__backdrop">
          <div
            className="spell-checker__highlight"
            ref={this.highlightRef}
            dangerouslySetInnerHTML={{
              __html: this.text,
            }}
          ></div>
        </div>

        <div className="spell-checker__element">{this.props.children}</div>
      </div>
    );
  }
}
