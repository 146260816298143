import { API } from "./api";

const baseUrl = "/api/stage";
const baseAdminUrl = "/api/project/stage";

const StageService = {
  getStages: (id?: string) => {
    // Получение этапа по идентификатору. Или списка всех этапов, если идентификатор не передан.
    let url = `${baseUrl}/get`;
    if (id) url += `?id=${id}&showStats=true`;

    return API.get(url).then((data) => {
      return data?.data;
    });
  },
  getGenerationStageBySelectionStage: (id: string) => {
    return API.get(`${baseUrl}/getGenerationStageBySelectionStage?id=${id}`).then(({ data }) => data);
  },
  getAccessibleIds: () => {
    // Возвращает список идентификаторов этапов, которые доступны авторизованному пользователю.
    return API.get(`${baseUrl}/getAccessibleIds`).then(({ data }) => {
      return data;
    });
  },
  stop: (id: string) => {
    return API.post(`${baseAdminUrl}/stop`, { id }).then(({ data }) => {
      return data;
    });
  },
};

export default StageService;
