import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "@crowd/ui-kit";

import Logo from "../../../containers/Header/Logo/Logo";
import { getUser } from "../../../../store/actions/UserActions";
import { getCountAllUnread } from "../../../../store/actions/NotificationsActions";
import { RootState } from "../../../../types/State.interface";
import { getAccessibleIds, getAllStages } from "../../../../store/actions/StageActions";
import UserService from "../../../../services/userService";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import "./TeamLoginForm.scss";
import { User } from "../../../../types/User.interface";

const TeamLoginForm = (props) => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.user.error);
  const promoUrl = useSelector((state: RootState) => state.common.environment?.promoUrl);
  const [email, setEmail] = useState("");
  const [emailValidator, setEmailValidator] = useState<any>(null);
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submitForm = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setSubmitted(true);
    if (formValid()) {
      setSubmitting(true);
      UserService.login({ login: email, password })
        .then((response) => {
          if (response.status === ApiStatusCode.OK) {
            dispatch(
              getUser(async (user: User) => {
                if (!user.passwordLastChanged) {
                  props.history.push("/changepassword?firstTime=true");
                  return;
                }
                if (user.passwordExpired) {
                  props.history.push("/changepassword");
                  return;
                }

                dispatch(getCountAllUnread());
                dispatch(getAccessibleIds());
                await dispatch(getAllStages());
                props.history.push("/");
              })
            );
            return;
          }

          dispatch(showErrorToast(response.message));
        })
        .catch((err) => dispatch(showErrorToast(err.message)))
        .finally(() => setSubmitting(false));
    }
  };

  const formValid = () => {
    return emailValidator.isValid() && Boolean(password.length);
  };

  return (
    <div className="team-login-form">
      <form onSubmit={submitForm}>
        <Logo />

        <p className="cr-error team-login-error">{error}</p>

        <Input
          type="email"
          label="Электронная почта"
          value={email}
          onChange={setEmail}
          shouldValidate={true}
          validateRules={{
            email: true,
          }}
          onInitValidator={(validator) => setEmailValidator(validator)}
          showError={submitted}
        />
        <Input type="password" label="Пароль" value={password} onChange={setPassword} />

        <div className="team-login-form__footer">
          <Button type="filled" text="Войти" isLoading={submitting} onClick={submitForm} size="m" />

          <a target="_self" className="link-blue" rel="noopener noreferrer" href={`${promoUrl}/restore`}>
            Забыли пароль?
          </a>
        </div>
      </form>
    </div>
  );
};

export default TeamLoginForm;
