import { useDispatch } from "react-redux";
import ProposalService from "../../../../services/proposalService";
import { showConfirmInfoModal, showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import { selectionGetIdeas, selectionGetTags, SELECTION_SET_IS_TAG_EDITING } from "../../../../store/actions/SelectionActions";
import { getAllStages } from "../../../../store/actions/StageActions";
import { showSuccessToast } from "../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { useSelectionParams } from "../helpers/params";

export const useSelectionFinalize = () => {
  const dispatch = useDispatch();
  const { stageId } = useSelectionParams();

  return (tagId?: string) => {
    dispatch(
      showConfirmInfoModal(
        tagId
          ? "При финализации группы идей, назначенная главной в группе, будет зафиксирована и перейдет на следующий этап. Продолжить?"
          : "Вы действительно хотите финализировать все группы?",
        async () => {
          try {
            const { status, message } = await ProposalService.selectionFinalize(stageId, tagId);
            if (status !== ApiStatusCode.OK) {
              throw new Error(message);
            }

            dispatch(getAllStages(stageId));
            dispatch(selectionGetTags(stageId));
            if (!tagId) {
              dispatch(showSuccessToast("Все группы финализированы"));
            }
          } catch (e: any) {
            dispatch(showErrorInfoModal(e.message));
          }
        }
      )
    );
  };
};

export const useSelectionUnfinalize = () => {
  const dispatch = useDispatch();
  const { stageId } = useSelectionParams();

  return (tagId?: string) => {
    dispatch(
      showConfirmInfoModal(tagId ? "" : "Вы действительно хотите отменить финализацию всех групп?", async () => {
        try {
          const { status, message } = await ProposalService.selectionUnfinalize(stageId, tagId);
          if (status !== ApiStatusCode.OK) {
            throw new Error(message);
          }
          dispatch(getAllStages(stageId));
          dispatch(selectionGetTags(stageId));
          if (!tagId) {
            dispatch(showSuccessToast("Финализация всех групп отменена"));
          }
        } catch (e: any) {
          dispatch(showErrorInfoModal(e.message));
        }
      })
    );
  };
};

export const useSelectionOnEditTagSuccess = () => {
  const dispatch = useDispatch();
  const { stageId, folder } = useSelectionParams();

  return () => {
    dispatch(selectionGetTags(stageId));
    dispatch(
      selectionGetIdeas({
        stageId,
        folder,
      })
    );
    dispatch({ type: SELECTION_SET_IS_TAG_EDITING, payload: false });
  };
};
