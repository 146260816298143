import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJS from "sockjs-client";
import Stomp, { Frame } from "stompjs";
import { RootState } from "../../../../types/State.interface";
import { WebSocketMessageType } from "./Socket.types";
import { onNewChatMessage, onReadChatMessage, onRemoveChatMessage } from "../../../../store/actions/SocketActions";
import { frameToChatMessageRaw, frameToChatReadResponse, frameToChatRemoveResponse } from "./Socket.helper";
import { displayError } from "../../../../utils";
import { getCountAllUnread } from "../../../../store/actions/NotificationsActions";
import { showSuccessToast } from "../../../../store/actions/ToastActions";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children, url }) => {
  const dispatch = useDispatch();
  const [stompClient, setStompClient] = useState(null);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (currentUser?.loggedIn) {
      const socket = new SockJS(url);
      setStompClient(Stomp.over(socket));
    }
  }, [currentUser]);

  useEffect(() => {
    if (stompClient) {
      stompClient.connect({}, onConnected, (err) => displayError("STOMP connect:", err));
      return () => {
        if (stompClient !== null) {
          stompClient.disconnect(() => {
            console.log("Disconnected");
          });
        }
      };
    }
  }, [stompClient]);

  const onConnected = () => {
    stompClient?.subscribe("/user/" + currentUser.id + "/queue/messages", (frame) =>
      handleReceiveFrame(WebSocketMessageType.CHAT_MESSAGE_RECEIVE, frame)
    );
    stompClient?.subscribe("/user/" + currentUser.id + "/queue/deleted", (frame) =>
      handleReceiveFrame(WebSocketMessageType.CHAT_MESSAGE_REMOVE, frame)
    );
    stompClient?.subscribe("/user/" + currentUser.id + "/queue/read", (frame) =>
      handleReceiveFrame(WebSocketMessageType.CHAT_MESSAGE_READ, frame)
    );
  };

  const handleReceiveFrame = (messageType: WebSocketMessageType, frame: Frame) => {
    const response = JSON.parse(frame.body);
    if (response.error) {
      return displayError("STOMP receive message:", response.error)
    }

    switch (messageType) {
      case WebSocketMessageType.CHAT_MESSAGE_RECEIVE: {
        const messageRaw = frameToChatMessageRaw(frame);
        const isMine = messageRaw.senderId === currentUser.id;
        dispatch(onNewChatMessage({ peerId: isMine ? messageRaw.recipientId : messageRaw.senderId, isMine, message: messageRaw }));
        dispatch(getCountAllUnread());
        break;
      }
      case WebSocketMessageType.CHAT_MESSAGE_REMOVE: {
        const response = frameToChatRemoveResponse(frame);
        const isMine = response.authorId === currentUser.id;
        dispatch(onRemoveChatMessage({ id: response.id, peerId: isMine ? response.peerId : response.authorId, deletionStatus: response.deletionStatus, isMine }));
        isMine && dispatch(showSuccessToast("Сообщение успешно удалено"));
        break;
      }
      case WebSocketMessageType.CHAT_MESSAGE_READ: {
        const response = frameToChatReadResponse(frame);
        const isMine = response.senderId === currentUser.id;
        dispatch(onReadChatMessage({ ids: response.ids, peerId: isMine ? response.recipientId : response.senderId }));
        dispatch(getCountAllUnread());
        break;
      }
      default: {
        console.log(`WebSocketProvider: ${messageType}`);
      }
    }
  };

  return <WebSocketContext.Provider value={stompClient}>{children}</WebSocketContext.Provider>;
};
