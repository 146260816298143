import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../../../store/actions/UserActions";
import { RootState } from "../../../../types/State.interface";
import "./LoggedIn.scss";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import LoggedInAvatar from "../LoggedInAvatar/LoggedInAvatar";
import { useHistory } from "react-router-dom";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

const LoggedIn = () => {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const promoUrl = useSelector((state: RootState) => state.common.environment?.promoUrl);
  const dispatch = useDispatch();

  return (
    <div className="logged-in">
      <Tooltip text={"Личный кабинет"} idx={"profile" + user?.id} className={"logged-in-avartItem"}>
        <LoggedInAvatar />
      </Tooltip>
      <EventsTrackWrapperClick id={["HEADER", "BUTTON", "CLICK"]} params={{ replace: ["Выйти"] }}>
        <div onClick={() => dispatch(logout(history))} className="pointer">
          <Tooltip text={"Выйти"} idx={"exit" + user?.id}>
            <i className="ui-icon ui-icon-exit-arrow"></i>
          </Tooltip>
        </div>
      </EventsTrackWrapperClick>
      {promoUrl && (
        <EventsTrackWrapperClick id={["HEADER", "ICON", "CLICK"]} params={{ replace: ["Домой"] }}>
          <a href={promoUrl} className="logged-in-exit">
            <Tooltip text={"Перейти на платформу «Город идей»"} idx={"goToPromo" + user?.id}>
              <i className="ui-icon ui-icon-home"></i>
            </Tooltip>
          </a>
        </EventsTrackWrapperClick>
      )}
    </div>
  );
};

export default LoggedIn;
