import React from "react";

import "./SomethingWentWrong.scss";

const SomethingWentWrong = (props) => {
  return (
    <div className="went-wrong">
      <h1>Что-то пошло не так...</h1>
      <p>Мы уже работаем над ошибкой</p>
      <p>
        Попробуйте начать с <a href="/">Главной</a>
      </p>
    </div>
  );
};

export default SomethingWentWrong;
