import { Checkbox } from "@crowd/ui-kit";
import { CheckboxProps } from "@crowd/ui-kit/build/components/Checkbox/Checkbox.types";
import { FC } from "react";
import "./Switcher.scss";

const Switcher: FC<CheckboxProps> = (props) => (
  <div className="switcher">
    <Checkbox {...props} />
  </div>
);

export default Switcher;
