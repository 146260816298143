import React from "react";
import "./VotingOptions.scss";
import { Radio } from "@crowd/ui-kit";
import { ColoredIcons } from "../Voting.constants";

interface Props {
  proposalId: string;
  votingCriteria: any;
  selectedVoteValue: string;
  onAnswerChange: (value?: any, immediate?: boolean) => void;
  touchScreenOptions?: boolean;
}

const VotingOptions = (props: Props) => {
  // TODO make into enum?
  const voteType: string = props.votingCriteria.type;

  // TODO use templates/voting/proposal.ftl for different types, ask to create different types in constructor
  const renderVotingOptions = () => {
    return (
      <div className="proposal__voting__options">
        {voteType === "LIST" &&
          props.votingCriteria.items?.map((item, idx) => {
            const value = item.value.toString();
            return (
              <div className="proposal__voting__option" key={idx}>
                <Radio
                  onChange={props.onAnswerChange}
                  id={`${props.proposalId}-${item.value}`}
                  isSelected={value === props.selectedVoteValue}
                  value={value}
                  label={item.name}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderMobileVotingOptions = () => {
    return (
      <div className="proposal__voting__options proposal__voting__options--touchscreen">
        {voteType === "LIST" &&
          props.votingCriteria.items?.map((item, idx) => {
            const value = item.value.toString();
            return (
              <div
                className="proposal__voting__option"
                key={idx}
                onClick={() => props.onAnswerChange(value, true)}
              >
                <div
                  className="proposal__voting__option__icon"
                  style={{
                    backgroundColor:
                      ColoredIcons[item.rating]?.color || "#00CC88",
                  }}
                >
                  <i
                    className={
                      "ui-icon " +
                      (ColoredIcons[item.rating]?.icon || "ui-icon-done")
                    }
                  />
                </div>
                <div className="proposal__voting__option__label">
                  {item.shortName || item.name}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      {props.touchScreenOptions
        ? renderMobileVotingOptions()
        : renderVotingOptions()}
    </>
  );
};

export default VotingOptions;
