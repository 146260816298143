import { TableProps } from "../../../../presentational/Table/Table";

export const columns: TableProps<string>["columns"] = [
  { header: "Дата и время", accessor: "date", className: "idea-transfer-history-modal__date-column" },
  { header: "Вид изменения", accessor: "status", className: "idea-transfer-history-modal__status-column" },
  { header: "Комментарий", accessor: "comment", className: "idea-transfer-history-modal__comment-column" },
  { header: "Автор", accessor: "author", className: "idea-transfer-history-modal__author-column" },
];

export const columnsMobile = columns.slice(1);
