import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { AttachmentPreview } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";

import "./PersonalPost.scss";
import PageTitle from "../../../../presentational/PageTitle/PageTitle";
import PersonalPostControls from "./PersonalPostControls/PersonalPostControls";
import PersonalPostComments from "./PersonalPostComments/PersonalPostComments";
import ArrowBack from "../../../../presentational/Controls/ArrowBack/ArrowBack";
import PersonalPostStatistic from "./PersonalPostStatistic/PersonalPostStatistic";
import ContentLoader from "../../../../presentational/Loaders/ContentLoader/ContentLoader";
import { getBlogPost } from "../../../../../store/actions/BlogActions";
import { RootState } from "../../../../../types/State.interface";
import CommentService from "../../../../../services/commentService";
import linkifyHtml from "linkifyjs/html";

const PersonalPost = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const post = useSelector((state: RootState) => state.blog.activePost);
  const { postId, userId } = useParams<{ postId: string; userId: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  const initPost = () => {
    setLoading(true);
    dispatch(getBlogPost(postId, null, () => setLoading(false)));
  };

  useEffect(() => {
    initPost();
  }, [postId, post?.active]);

  return (
    <div className="personal-post">
      <div className="personal-post__post white-block">
        <ArrowBack
          text="Все записи в личном блоге"
          onClick={() => history.push(`/users/user/blog/${userId}`)}
        />

        <ContentLoader isLoading={isLoading}>
          {post && (
            <>
              <PageTitle text={post.title} />

              <PersonalPostStatistic />

              <div
                className="personal-post__post__text"
                dangerouslySetInnerHTML={{
                  __html: linkifyHtml(
                    CommentService.CkBBCodeToHtml(post.rawContent),
                    { target: "blank" }
                  ),
                }}
              ></div>

              {post.attachments?.length > 0 && (
                <div className="personal-post__post__images scroll-x">
                  {post.attachments.map((attachment) => (
                    <AttachmentPreview
                      attachment={attachment}
                      key={attachment.id}
                    />
                  ))}
                </div>
              )}

              {loggedIn && (
                <PersonalPostControls
                  discussionId={post.discussionId}
                  postId={post.id}
                  hidden={post.hidden}
                  active={post.active}
                  post={post}
                  onAddComment={() =>
                    history.push({
                      pathname: `/users/user/post/${(params as any).userId}/${
                        (params as any).postId
                      }`,
                      state: { updateFirstPage: true },
                    })
                  }
                />
              )}
            </>
          )}

          {!post && <h2>Пост не найден</h2>}
        </ContentLoader>
      </div>

      {!isLoading && post?.discussionId && post?.commentsEnabled && (
        <PersonalPostComments post={post}/>
      )}
    </div>
  );
};

export default PersonalPost;
