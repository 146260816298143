import { useParams } from "react-router-dom";
import { SelectionFolders } from "../../../../../utils/constants/proposal";
import {
  SELECTION_SET_IS_TAG_EDITING,
} from "../../../../../store/actions/SelectionActions";
import { declOfNum } from "../../../../../utils";
import ClosingCard from "../../../../presentational/Cards/ClosingCard/ClosingCard";
import SelectionEditTagForm from "../SelectionEditTagForm/SelectionEditTagForm";
import { SelectionTagFilter } from "./SelectionTagFilter";
import { SelectionInputsFilters } from "./SelectionInputsFilters";
import { useDispatch, useSelector } from "react-redux";
import { selectionIsTagEditingSelector, selectionTagFilterSelector } from "../../../../../store/selectors/selection";

export function SelectionFilters() {
  const { folder } = useParams<{ folder: SelectionFolders }>();
  const dispatch = useDispatch();
  
  const isTagEditing = useSelector(selectionIsTagEditingSelector);
  const tagFilter = useSelector(selectionTagFilterSelector);

  const handleCloseTagEditing = () => {
    dispatch({ type: SELECTION_SET_IS_TAG_EDITING, payload: false });
  };

  return (
    <div className="selection-page__block-indent selection-page__filters">
      {folder === SelectionFolders.ACCEPTED && <SelectionTagFilter />}

      {isTagEditing && !!tagFilter && (
        <ClosingCard isOpened onClose={handleCloseTagEditing} className="selection-page__filters-edit-tag-card">
          <SelectionEditTagForm />
        </ClosingCard>
      )}

      {!!tagFilter && (
        <div className="selection-page__tag-idea-counter">
          {tagFilter.proposalCount || 0} {declOfNum(tagFilter.proposalCount, ["идея", "идеи", "идей"])}
        </div>
      )}

      <SelectionInputsFilters />
    </div>
  );
}
