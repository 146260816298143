import React, { useEffect, useState } from "react";
import { ChatMessage, ChatMessageStatus } from "../chat.types";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../types/State.interface";
import dayjs from "dayjs";
import { Button, Loader } from "@crowd/ui-kit";
import { hideInfoModal, showInfoModal } from "../../../../../../../store/actions/LayoutActions";
import classNames from "classnames";
import { Attachment, FileMimeType } from "../../../../../../../types/Common.interface";
import { getFileNameToFileExtension } from "../../../../../../../utils/fileUtils";
import ImageView from "./AttachmentView/ImageView/ImageView";
import PDFView from "./AttachmentView/PDFView/PDFView";
import "./MessageBubble.scss";
import { getTitleByStatus } from "../chat.utils";

export enum MessageRemoveType {
  REMOVE_ONLY_ME = "REMOVE_ONLY_ME",
  REMOVE_ALL = "REMOVE_ALL",
}

interface MessageBubbleProps {
  message: ChatMessage;
  onRemove?: (message: ChatMessage, type: MessageRemoveType) => void;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message, onRemove }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  const isMine = (message: ChatMessage) => message.sender.id === currentUser.id;

  const isDeleted = (message: ChatMessage) => (message.status === ChatMessageStatus.DELETED_BY_ME) || (message.status === ChatMessageStatus.DELETED_BY_PEER)

  const canRemove = (message: ChatMessage) => isMine(message) && message.status === ChatMessageStatus.VISIBLE;

  const canShowTools = (message) => canRemove(message);

  const handleRemoveMessage = (message) => {
    const buttonsList = (
      <div className="message-bubble__modal-buttons">
        <Button
          type="outlined"
          size="m"
          text="Удалить у меня"
          onClick={() => {
            onRemove(message, MessageRemoveType.REMOVE_ONLY_ME);
            dispatch(hideInfoModal());
          }}
        />
        <Button
          type="outlined"
          size="m"
          text="Удалить у всех"
          onClick={() => {
            onRemove(message, MessageRemoveType.REMOVE_ALL);
            dispatch(hideInfoModal());
          }}
        />
        <Button type="outlined" size="m" text="Отмена" onClick={() => dispatch(hideInfoModal())} />
      </div>
    );

    dispatch(showInfoModal("Удалить сообщение?", null, buttonsList, null, "message-bubble__modal"));
  };

  const renderAttachment = (attachment: Attachment) => {
    const extension = getFileNameToFileExtension(attachment.fileName);
    switch (extension) {
      case FileMimeType.JPEG:
      case FileMimeType.PNG:
      case FileMimeType.JPG: {
        return <ImageView file={attachment} />;
      }
      case FileMimeType.PDF: {
        return <PDFView file={attachment} />;
      }

      default:
        return undefined;
    }
  };

  return (
    <div
      className={classNames(
        "message-bubble",
        {
          "message-bubble--mine": isMine(message),
          "message-bubble--peer": !isMine(message),
          "message-bubble--deleted": isDeleted(message)
        }
      )}
    >
      <div className="message-bubble__body">
        {isDeleted(message) && (
          <span className="ui-icon-close-round" />
        )}
        <div className="message-bubble__text">
          {getTitleByStatus(message.status, message.text)}
          {message.status !== ChatMessageStatus.SENDING && (
            <div className="message-bubble__time">{dayjs(message.created).format("HH:mm")}</div>
          )}
          {message.status === ChatMessageStatus.SENDING && (
            <div className="message-bubble__sending">
              <Loader />
            </div>
          )}
        </div>
        {Boolean(message.attachments.length) && (
          <div className="message-bubble__attachments">{message.attachments?.map(renderAttachment)}</div>
        )}
      </div>
      {canShowTools(message) && (
        <div className="message-bubble__tools">
          {canRemove(message) && (
            <button className="ui-icon ui-icon-delete simple-button" onClick={(e) => handleRemoveMessage(message)}></button>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBubble;
