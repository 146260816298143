import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import Root from "./Root";
import {
  isIE,
  isChrome,
  isChromium,
  engineName,
  engineVersion,
  isEdge,
  fullBrowserVersion,
  isOpera,
  isFirefox,
  isSafari,
  isYandex,
  isLegacyEdge,
} from "react-device-detect";

const MIN_EDGE = 93;
const MIN_CHROME = 64;
const MIN_FIREFOX = 58;
const MIN_SAFARI = 12;
const MIN_OPERA = 64;
const MIN_YANDEX = 17; // 2017 last for XP and Vista
const MIN_CHROMIUM = 56; // 2017
const BLINK = "Blink"; // Chrome and other similar browsers
const MIN_BLINK = 64; // Chrome v 64
const WEBKIT = "WebKit"; // safari
const MIN_WEBKIT = 605; // Safari v 12

const isLegacyBrowser = () => {
  const bv = fullBrowserVersion;
  const ev = Number.parseInt(engineVersion, 10);

  return (
    isIE ||
    isLegacyEdge ||
    (isEdge && Number.parseInt(bv) < MIN_EDGE) ||
    (isChrome && Number.parseInt(bv) < MIN_CHROME) ||
    (isOpera && Number.parseInt(bv) < MIN_OPERA) ||
    (isFirefox && Number.parseInt(bv) < MIN_FIREFOX) ||
    (isSafari && Number.parseInt(bv) < MIN_SAFARI) ||
    (isYandex && Number.parseInt(bv) < MIN_YANDEX) ||
    (isChromium && Number.parseInt(bv) < MIN_CHROMIUM) ||
    (engineName === BLINK && ev < MIN_BLINK) ||
    (engineName === WEBKIT && ev < MIN_WEBKIT)
  );
};

if (isLegacyBrowser()) {
  document.getElementById("root").innerHTML =
    '<div class="old-browser-warning">' +
    "<h2>Ваш браузер устарел. Пожалуйста, обновите браузер.</h2>" +
    "<div>" +
    '<a href="https://www.google.com/chrome/" class="google-link" alt="Скачать Google Chrome" title="Скачать Google Chrome"></a>' +
    '<a href="https://www.mozilla.org/ru/firefox/" class="firefox-link" alt="Скачать Mozilla Firefox" title="Скачать Mozilla Firefox"></a>' +
    '<a href="https://www.microsoft.com/ru-ru/edge/" class="edge-link" alt="Скачать Microsoft Edge" title="Скачать Microsoft Edge"></a>' +
    "</div>" +
    "</div>";
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Root store={store} />
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
