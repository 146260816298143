import React from "react";
import dayjs from "dayjs";

import { IWarning } from "../../../../../services/warningService";
import "./WarningItems.scss";

interface IWarningItemsProps {
  warnings: IWarning[];
}

const WarningItems = ({ warnings }: IWarningItemsProps) => {
  return (
    <div className="warning-items">
      {warnings.length === 0 && (
        <div className="warning-items__no-warnings-msg">
          Не найдено ни одного предупреждения.
        </div>
      )}

      {warnings.map((warn: IWarning) => (
        <div className="warning-items__item">
          <div className="warning-items__item__row">
            <div className="warning-items__item__row__title">Дата создания</div>
            <div className="warning-items__item__row__content">{dayjs(warn.created).format("DD.MM.YYYY HH:mm")}</div>
          </div>

          <div className="warning-items__item__row">
            <div className="warning-items__item__row__title">Автор</div>
            <div className="warning-items__item__row__content">{warn.author?.firstName} {warn.author?.lastName}</div>
          </div>

          <div className="warning-items__item__row">
            <div className="warning-items__item__row__title">Текст предупреждения</div>
            <div className="warning-items__item__row__content">{warn.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WarningItems;
