import React, { useState } from "react";

import { User } from "../../../../types/User.interface";
import { useOuterClick } from "../../../../utils/hooks/useOnOutsideClick";
import FadeIn from "../../../presentational/FadeIn/FadeIn";
import { Button } from "@crowd/ui-kit";

import "./FrontmenCard.scss";
import { getAvatarSrc } from "../../../../utils";
import { StatusCode } from "react-stickynode";

interface Props {
  frontmen?: User;
  onSendMessageClick?: Function;
  setDetailed: Function;
  isTouch: boolean;
}

const FrontmenCard = ({
  frontmen,
  onSendMessageClick,
  setDetailed,
  isTouch,
}: Props) => {
  const [showButton, setShowButton] = useState(false);
  const cardInnerRef = useOuterClick<HTMLInputElement>((event: any) => {
    if (isTouch) {
      setShowButton(false);
    }
  });
  const hasDetails = !!frontmen.description;

  return (
    <div className="team__frontmen__card" ref={cardInnerRef}>
      <div
        className={
          "team__frontmen__card__content " + (hasDetails ? "pointer" : "")
        }
        onMouseEnter={() => !hasDetails && !isTouch && setShowButton(true)}
        onMouseLeave={() => !hasDetails && !isTouch && setShowButton(false)}
        onClick={() => {
          if (hasDetails) {
            setDetailed((state) => ({ ...state, frontmen }));
          } else {
            isTouch && setShowButton(!showButton);
          }
        }}
      >
        <img
          className="team__frontmen__card__picture"
          src={getAvatarSrc(frontmen?.pictureId)}
          alt="Лицо проекта"
        />
        <div className="team__frontmen__card__block">
          <div className="team__frontmen__card__name">
            <div>{`${frontmen?.firstName} ${frontmen?.lastName}`}</div>
          </div>

          {showButton && (
            <FadeIn>
              <Button
                text={"Задать вопрос"}
                type={"filled"}
                size={"m"}
                onClick={(event: MouseEvent) => {
                  event.stopPropagation();
                  typeof onSendMessageClick === "function" &&
                    onSendMessageClick(frontmen);
                }}
              />
            </FadeIn>
          )}

          <div
            className={`team__frontmen__card__position ${
              showButton ? "invisible" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: frontmen?.position,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default FrontmenCard;
