import CommentService, {
  AddCommentParams,
  CommentsGetParams,
  DeleteCommentParams,
  LoadCommentsResponse,
  LoadResponsesParams,
  RecentCommentsParams,
  ToggleSubscribeParams,
  UpdateCommentParams,
  VoteCommentsParams,
  VotedUsersParams,
} from "../../services/commentService";
import { AxiosResponse } from "axios";
import { Comment } from "../../types/Comment.interface";
import DiscussionService, { RootCommentsParams } from "../../services/discussionService";
import PersonalService from "../../services/personalService";
import { ApiResponse, ApiStatusCode, ApiValidationError } from "../../types/Common.interface";
import { showMosruForm } from "./LayoutActions";
import { showErrorToast, showSuccessToast } from "./ToastActions";
import { ERR_MSG } from "../../services/api";

export const GET_RECENT_COMMENTS = "GET_RECENT_COMMENTS";
export const GET_ROOT_COMMENTS = "GET_ROOT_COMMENTS";
export const GET_ANSWERS_COMMENTS = "GET_ANSWERS_COMMENTS";
export const GET_COMMENT_REPLIES = "GET_COMMENT_REPLIES";
export const GET_COMMENTS_COMPLETE = "GET_COMMENTS_COMPLETE";
export const GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED";
export const ADD_COMMENT_COMPLETE = "ADD_COMMENT_COMPLETE";
export const ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED";
export const LOAD_COMMENT_RESPONSES = "LOAD_COMMENT_RESPONSES";
export const UPDATE_COMMENT_COMPLETE = "UPDATE_COMMENT_COMPLETE";
export const DELETE_COMMENT_COMPLETE = "DELETE_COMMENT_COMPLETE";
export const RESTORE_COMMENT_COMPLETE = "RESTORE_COMMENT_COMPLETE";
export const COMMENT_SUBSCRIBE_COMPLETE = "COMMENT_SUBSCRIBE_COMPLETE";
export const COMMENT_UNSUBSCRIBE_COMPLETE = "COMMENT_UNSUBSCRIBE_COMPLETE";
export const SET_COMMENT_VOTED_USERS = "SET_COMMENT_VOTED_USERS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_VOTED_PAGINATION = "SET_VOTED_PAGINATION";
export const CLEAR_VOTED_PAGINATION = "CLEAR_VOTED_PAGINATION";

export const getComments = (params: CommentsGetParams, successCb?: (data: ApiResponse<Comment[]>) => any) => (dispatch) => {
  CommentService.get(params).then((res: ApiResponse<Comment[]>) => {
    if (res.status === ApiStatusCode.OK) {
      dispatch({ type: GET_COMMENTS_COMPLETE });
      successCb && successCb(res);
    } else {
      dispatch({ type: GET_COMMENTS_FAILED });
    }
  });
};

export const getRecentComments = (params: RecentCommentsParams) => (dispatch) => {
  CommentService.getRecentComments(params)
    .then(({ data }: AxiosResponse<Comment>) => {
      dispatch({
        type: GET_RECENT_COMMENTS,
        payload: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getRootComments = (params: RootCommentsParams) => (dispatch) => {
  DiscussionService.getRootComments(params)
    .then(({ data }: AxiosResponse<Comment>) => {
      dispatch({
        type: GET_ROOT_COMMENTS,
        payload: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getAnswersComments = () => (dispatch) => {
  PersonalService.getAnswersDiscussions().then((data: AxiosResponse<Comment>) => {
    dispatch({
      type: GET_ANSWERS_COMMENTS,
      payload: data?.data,
    });
  });
};

export const commentVote = (data: VoteCommentsParams, successCb?: () => any) => (dispatch) => {
  CommentService.vote(data).then((res) => {
    if (res.status === ApiStatusCode.OK) {
      successCb && successCb();
      return;
    }

    dispatch(showErrorToast(res.message));
  });
};

export const commentUnVote = (data: VoteCommentsParams, successCb?: () => any) => (dispatch) => {
  CommentService.unVote(data).then((res) => {
    if (res.status === ApiStatusCode.OK) {
      successCb && successCb();
      return;
    }

    dispatch(showErrorToast(res.message));
  });
};

export const getVotedUsers = (data: VotedUsersParams) => (dispatch) => {
  dispatch(setLoading(true));
  CommentService.getVotedUsers(data).then((res) => {
    dispatch(setLoading(false));
    if (res.status === ApiStatusCode.OK) {
      dispatch(setVotedUsers(res.data));
      dispatch(setVotedPagination(res.paging));
      return;
    }

    dispatch(showErrorToast(res.message));
  });
};

export const setVotedUsers = (payload) => ({
  type: SET_COMMENT_VOTED_USERS,
  payload: payload,
});

export const clearVotedUsers = () => ({
  type: CLEAR_VOTED_PAGINATION,
});

export const setLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload: payload,
});

export const setVotedPagination = (payload) => ({
  type: SET_VOTED_PAGINATION,
  payload: payload,
});

export const toggleSubscribe = (data: ToggleSubscribeParams, successCb?: () => any) => (dispatch) => {
  CommentService.toggleSubscribe(data).then((res) => {
    if (res.status === ApiStatusCode.OK) {
      dispatch({ type: `COMMENT_${data.type.toUpperCase()}_COMPLETE` });
      successCb && successCb();
    }
    if (res.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
      dispatch(showMosruForm());
    }
  });
};

export const addComment =
  (
    data: AddCommentParams,
    successCb?: (data: ApiResponse<Comment>) => any,
    finallyCb?: () => any,
    validationErrors?: (errors: ApiValidationError[]) => any
  ) =>
  (dispatch) => {
    CommentService.add(data)
      .then((res: ApiResponse<Comment>) => {
        if (res.status === ApiStatusCode.OK) {
          dispatch({ type: ADD_COMMENT_COMPLETE, payload: res.data });
          successCb && successCb(res);
        } else if (res.status === ApiStatusCode.ILLEGAL_INPUT) {
          dispatch({ type: ADD_COMMENT_FAILED });
          dispatch(showErrorToast(res.message));

          validationErrors && res.validationErrors && validationErrors(res.validationErrors);
        } else {
          dispatch({ type: ADD_COMMENT_FAILED });
          dispatch(showErrorToast(res.message));
        }
      })
      .finally(() => {
        finallyCb && finallyCb();
      });
  };

export const loadCommentResponses =
  (params: LoadResponsesParams, successCb?: (data: ApiResponse<LoadCommentsResponse>) => any) => (dispatch) => {
    CommentService.loadResponses(params).then((res: ApiResponse<LoadCommentsResponse>) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: LOAD_COMMENT_RESPONSES });
        successCb && successCb(res);
      }
    });
  };

export const updateComment =
  (data: UpdateCommentParams, successCb?: (data: ApiResponse<Comment>) => any, finallyCb?: () => any) => (dispatch) => {
    CommentService.update(data)
      .then((res: ApiResponse<Comment>) => {
        if (res.status === ApiStatusCode.OK) {
          dispatch({ type: UPDATE_COMMENT_COMPLETE, payload: res.data });
          dispatch(showSuccessToast("Комментарий успешно обновлен."));
          successCb && successCb(res);
        } else {
          dispatch(showErrorToast(res.message));
        }
      })
      .finally(() => {
        finallyCb && finallyCb();
      });
  };

export const deleteComment = (data: DeleteCommentParams, comment: Comment, successCb?: (comment) => any) => (dispatch) => {
  CommentService.delete(data)
    .then((res) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: DELETE_COMMENT_COMPLETE });
        dispatch(showSuccessToast("Комментарий успешно удален."));
        successCb && successCb(comment);
        return;
      }

      dispatch(showErrorToast(res.message));
    })
    .catch((err) => {
      dispatch(showErrorToast(ERR_MSG));
      console.warn(err);
    });
};

export const restoreComment = (commentId: string, successCb?: (comment: Comment) => any) => (dispatch) => {
  CommentService.restore(commentId)
    .then(({ message, status, data }) => {
      if (ApiStatusCode.OK === status) {
        dispatch({ type: RESTORE_COMMENT_COMPLETE });
        dispatch(showSuccessToast("Комментарий успешно восстановлен."));
        successCb && successCb(data);
        return;
      }

      dispatch(showErrorToast(message));
    })
    .catch((err) => {
      dispatch(showErrorToast(ERR_MSG));
      console.warn(err);
    });
};
