import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox } from "@crowd/ui-kit/build";
import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";

import AddCommentForm from "../../../../../containers/Forms/AddCommentForm/AddCommentForm";
import Tooltip from "../../../../../presentational/Tooltip/Tooltip";
import BlogService from "../../../../../../services/blogService";
import { deleteBlogPost, updateBlogPostActivity } from "../../../../../../store/actions/BlogActions";
import { RootState } from "../../../../../../types/State.interface";
import { BlogPost } from "../../../../../../types/BlogPost.interface";
import BlogModal from "../../../../../containers/Modals/BlogModal/BlogModal";
import UserAvatar from "../../../../../presentational/UserAvatar/UserAvatar";
import PostAddFavorite from "../../../../../presentational/PostAddFavorite/PostAddFavorite";
import { hideInfoModal, showConfirmInfoModal } from "../../../../../../store/actions/LayoutActions";
import "./PersonalPostControls.scss";
import { showErrorToast } from "../../../../../../store/actions/ToastActions";
import { ERR_MSG } from "../../../../../../services/api";
import { ApiStatusCode } from "../../../../../../types/Common.interface";

interface PersonalPostControlsProps {
  discussionId: string;
  postId: string;
  hidden: boolean;
  active: boolean;
  post: BlogPost;
  onAddComment: (comment) => any;
}

const PersonalPostControls = ({ discussionId, postId, hidden, active, post, onAddComment }: PersonalPostControlsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCommentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [postHidden, setPostHidden] = useState<boolean>(hidden);
  const [postActive, setPostActive] = useState<boolean>(active);
  const [isBlogModalOpen, setBlogModalOpen] = useState<boolean>(false);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const [displayOnMain, setDisplayOnMain] = useState<boolean>(post.displayMain);

  useEffect(() => {
    if ((history.location.state as any)?.openCommentForm) {
      setCommentModalOpen(true);
    }
  }, [history.location.state]);

  const editPost = () => {
    setBlogModalOpen(true);
  };

  const removePost = () => {
    dispatch(
      showConfirmInfoModal("Вы действительно хотите удалить пост?", () => {
        dispatch(
          deleteBlogPost(postId, () => {
            history.push(`/users/user/blog/${post.author.id}`);
          })
        );
        dispatch(hideInfoModal());
      })
    );
  };

  const togglePostVisibility = () => {
    dispatch(
      showConfirmInfoModal(`Вы действительно хотите ${postHidden ? "показать" : "скрыть"} пост?`, () => {
        BlogService.changePostVisibility(postId, postHidden ? "show" : "hide")
          .then(({ status, message }) => {
            if (status === ApiStatusCode.OK) {
              setPostHidden(!postHidden);
              return;
            }

            if (status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
              dispatch(showErrorToast("Ошибка прав доступа"));
              return;
            }

            dispatch(showErrorToast(message || ERR_MSG));
          })
          .catch(() => dispatch(showErrorToast(ERR_MSG)));
        dispatch(hideInfoModal());
      })
    );
  };

  const togglePostActivity = () => {
    const typeStr = post.active ? "завершить" : "возобновить";
    dispatch(
      showConfirmInfoModal(`Вы действительно хотите ${typeStr} активность в посте?`, () => {
        dispatch(
          updateBlogPostActivity(postId, post.active ? "stopActivity" : "resumeActivity", (act) => {
            setPostActive(act);
          })
        );
        dispatch(hideInfoModal());
      })
    );
  };

  const togglePostDisplayMain = (value) => {
    const question = value
      ? "Вы действительно хотите опубликовать пост на главной странице?"
      : "Вы действительно хотите скрыть пост с главной страницы?";
    dispatch(
      showConfirmInfoModal(question, () => {
        BlogService.setDisplayOnMain(postId, value)
          .then(({ status, message }) => {
            if (status === ApiStatusCode.OK) {
              setDisplayOnMain(!displayOnMain);
              return;
            }

            if (status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
              dispatch(showErrorToast("Ошибка прав доступа"));
              return;
            }

            dispatch(showErrorToast(message || ERR_MSG));
          })
          .catch(() => dispatch(showErrorToast(ERR_MSG)));
        dispatch(hideInfoModal());
      })
    );
  };

  const isAuthor = (): boolean => {
    return currentUser.id === post?.author.id;
  };

  const getActivityTooltip = (): string => {
    if (isDisableActivityBtn()) {
      return "Активность завершена командой проекта";
    }

    return postActive ? "Завершить активность" : "Возобновить активность";
  };

  const getActivityClass = (): string => {
    let cls = "personal-post-controls__icon";

    if (isDisableActivityBtn()) {
      cls += " personal-post-controls__disable";
    }

    return cls;
  };

  const isDisableActivityBtn = (): boolean => {
    return post.activityStopper?.teamMember && !currentUser.teamMember;
  };

  const getVisibilityTooltip = (): string => {
    if (isDisableVisibilityBtn()) {
      return "Пост скрыт командой проекта";
    }

    return postHidden ? "Показать" : "Скрыть";
  };

  const isDisableVisibilityBtn = (): boolean => {
    return post.author.id === currentUser.id && !post.permissions.includes("BLOG_POST_HIDE");
  };

  const getVisibilityClass = (): string => {
    let cls = "personal-post-controls__icon";

    if (isDisableVisibilityBtn()) {
      cls += " personal-post-controls__disable";
    }

    return cls;
  };

  return (
    <>
      <div className="personal-post-controls">
        <div className="personal-post-controls__buttons">
          {post?.discussionPermissions.includes("DISCUSSION_ADD_COMMENT") && (
            <>
              <Button text={"Написать комментарий"} type={"filled"} size={"m"} onClick={() => setCommentModalOpen(true)} />
              <Button
                text={"Комментировать"}
                type={"outlined"}
                size={"m"}
                onClick={() => setCommentModalOpen(true)}
                icon={<span className="ui-icon-comments ui-icon" />}
              />
            </>
          )}

          <div className="flex">
            {((isAuthor() && !post?.activityStopper?.teamMember) || currentUser.teamMember) && (
              <div>
                <Tooltip text={"Редактировать"} idx={postId + "edit"} className={"personal-post-controls__icon"}>
                  <i className="ui-icon ui-icon-edit" onClick={editPost} />
                </Tooltip>
              </div>
            )}

            {(isAuthor() || currentUser.teamMember) && (
              <div>
                <Tooltip text={getVisibilityTooltip()} idx={postId + "hidePost"} className={getVisibilityClass()}>
                  <i
                    className={"ui-icon ui-icon-visibility" + (postHidden ? "" : "-off")}
                    onClick={isDisableVisibilityBtn() ? () => {} : togglePostVisibility}
                  />
                </Tooltip>
              </div>
            )}

            {(isAuthor() || currentUser.teamMember) && (
              <div>
                <Tooltip text={getActivityTooltip()} idx={postId + "active"} className={getActivityClass()}>
                  <i
                    className={"ui-icon ui-icon-" + (postActive ? "nocomments" : "comments")}
                    onClick={isDisableActivityBtn() ? () => {} : togglePostActivity}
                  />
                </Tooltip>
              </div>
            )}

            {(isAuthor() || currentUser.teamMember) && (
              <div>
                <Tooltip text={"Удалить"} idx={postId + "delete"} className={"personal-post-controls__icon"}>
                  <i className="ui-icon ui-icon-delete" onClick={removePost} />
                </Tooltip>
              </div>
            )}

            {currentUser.teamMember && (
              <div>
                <Checkbox
                  html="Отображать на главной"
                  defaultValue={displayOnMain}
                  onChange={(value) => togglePostDisplayMain(value)}
                  key={"displayMain"}
                />
              </div>
            )}
          </div>
        </div>

        {post.activityStopper?.teamMember && (currentUser.teamMember || currentUser.id === post.author?.id) && (
          <section className="flex">
            <UserAvatar user={post.activityStopper} />
            <div className="personal-post-controls__end-activity">
              <div className="personal-post-controls__user-name">
                {post.activityStopper.firstName} {post.activityStopper.lastName}
              </div>
              <div>Завершил активность</div>
            </div>
          </section>
        )}
      </div>

      {isCommentModalOpen && (
        <AddCommentForm discussionId={discussionId} cancel={() => setCommentModalOpen(false)} onAddComment={onAddComment} />
      )}

      {isBlogModalOpen && <BlogModal onSuccess={() => setBlogModalOpen(false)} onClose={() => setBlogModalOpen(false)} post={post} />}
    </>
  );
};

export default PersonalPostControls;
