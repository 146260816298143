import { useSelector } from "react-redux";
import { profileUserDetailsSelector } from "../../../../../store/selectors/profile";
import { declOfNum } from "../../../../../utils";
import { SelectionProposalCounters } from "../../../../../utils/constants/proposal";
import { selectionCountsSelector } from "../../../../../store/selectors/selection";

export function SelectionUnreviewed() {
  const { chiefExpert } = useSelector(profileUserDetailsSelector);
  const counts = useSelector(selectionCountsSelector);

  return (
    chiefExpert &&
    Boolean(counts[SelectionProposalCounters.UNREVIEWED]) && (
      <div className="selection-page__unreviewed">
        <i className="ui-icon ui-icon-visibility-off" />
        {counts[SelectionProposalCounters.UNREVIEWED]}
        {declOfNum(counts[SelectionProposalCounters.UNREVIEWED], [
          " Нерассмотренная идея",
          " Нерассмотренные идеи",
          " Нерассмотренных идей",
        ])}
      </div>
    )
  );
}
