import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import "./Faq.scss";
import { getFaqTabs } from "./constants";
import MessageModal from "../../containers/Modals/MessageModal/MessageModal";
import { Button, Select } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { Player, ControlBar, BigPlayButton } from "video-react";
import { setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import FaqCard from "./FaqCard/FaqCard";
import { RootState } from "../../../types/State.interface";
import { tabletWidth } from "../../../utils/constants/widthConstants";
import { useMediaQuery } from "react-responsive";
import { AppContext } from "../../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";

const FaqPage = () => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.userDetails);
  const promoUrl = useSelector((state: RootState) => state.common.environment?.promoUrl);
  const [faqTabs, setFaqTabs] = useState(getFaqTabs(promoUrl));
  const [activeTab, setActiveTab] = useState(faqTabs[0]);
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    const actions = appContext.sudirService.getActions(ComponentType.FAQ);
    if (actions.length) {
      const sendMessageAction = actions.find((act) => act.type === AuthActionType.SendMessage);
      if (sendMessageAction) {
        askQuestion();
      }
    }
  }, []);

  useEffect(() => {
    const tabs = getFaqTabs(promoUrl);
    setFaqTabs(tabs);
    if (!activeTab.name || activeTab.name === tabs[0].name) setActiveTab(tabs[0]);
  }, [promoUrl]);

  const getTabByName = (name: string) => {
    return faqTabs.find((tab) => tab.name === name) || faqTabs[0];
  };

  const askQuestion = () => {
    if (!user.loggedIn) {
      const data: AuthActionParam = {
        component: ComponentType.FAQ,
        type: AuthActionType.SendMessage,
      };

      const sendMessageAction = new AuthAction(data);
      return appContext.sudirService.authWithActions([sendMessageAction]);
    }

    setMessageModalIsOpen(true);
  };

  const renderStagesInfo = (activeTab) => {
    return (
      <div className="faq__stages">
        <p className="faq__tab-heading">При проведении проекта предусмотрены 4 вида этапов:</p>
        <div className="faq__stages-wrap">
          {activeTab.cards.map((card) => (
            <FaqCard card={card} />
          ))}
        </div>
      </div>
    );
  };

  const renderSendInfo = (activeTab) => {
    return (
      <div className="faq__send">
        <p className="faq__tab-text">
          Отправить идею по заявленным темам проекта можно только в ходе этапа «Предлагай». Ознакомиться с перечнем тем и датами по сбору
          идей вы можете в разделе «График проекта». Идеи, отправленные не на этапе «Предлагай» (например, в виде комментариев или личных
          писем), не будут учтены.
        </p>
        <p className="faq__tab-heading mt-32">Чтобы отправить идею по теме проекта:</p>

        <div className="faq__send-wrap">
          {activeTab.cards1.map((card) => (
            <FaqCard card={card} />
          ))}
        </div>

        <p className="faq__tab-text mt-32">
          Следить за изменением статусов ваших идей вы можете в личном кабинете в подразделе «Мои идеи и их статусы» или через оповещения в
          подразделе «Уведомления».
        </p>

        <p className="faq__tab-heading mt-32">В ходе экспертного отбора идеи могут иметь следующие статусы:</p>

        <div className="faq__status-wrap">
          {activeTab.cards2.map((card) => (
            <FaqCard card={card} />
          ))}
        </div>

        <p className="faq__tab-text mt-32">
          Если ваша идея была отклонена, вы можете ознакомиться с причиной в уведомлении в личном кабинете и, учитывая комментарий эксперта,
          отредактировать и направить ее снова на рассмотрение до завершения этапа «Предлагай».
        </p>

        <p className="faq__tab-heading mt-32">После завершения экспертного отбора принятые экспертами идеи получат следующие статусы:</p>

        <div className="faq__adopt-wrap">
          {activeTab.cards3.map((card) => (
            <FaqCard card={card} />
          ))}
        </div>
      </div>
    );
  };

  const renderQuestionsInfo = (activeTab) => {
    return (
      <div className="faq__questions">
        <p className="faq__tab-text">
          В случае возникновения технических сбоев, а также по иным возникающим в процессе использования платформы вопросам и предложениям
          вы можете:
        </p>

        <ol>
          <li>Задать вопрос координаторам или экспертам проекта в разделе «Команда».</li>
          <li>
            Написать сообщение в службу поддержки, для этого нажмите на ссылку «Помощь», расположенную внизу страницы слева. В случае
            возникновения проблемы в форме описания сообщения прикрепите снимок экрана. Изображение лучше передаст суть возникшей проблемы,
            и специалист сможет быстрее вам помочь.
          </li>
        </ol>

        <p className="faq__tab-heading mt-32">Сделать снимок экрана можно следующим образом:</p>

        <p className="faq__tab-subheading mt-40">ОS Windows</p>

        <div className="faq__questions-wrap">
          {activeTab.cards1.map((card) => (
            <FaqCard card={card} />
          ))}
        </div>

        <p className="faq__tab-subheading mt-40">Mac OS</p>

        <div className="faq__os-wrap">
          {activeTab.cards2.map((card) => (
            <FaqCard card={card} />
          ))}
        </div>
      </div>
    );
  };

  const renderText = (activeTab) => {
    return <div dangerouslySetInnerHTML={{ __html: activeTab.content }} />;
  };

  const renderHtml = (activeTab) => {
    switch (activeTab.alias) {
      case "stages":
        return renderStagesInfo(activeTab);
      case "send":
        return renderSendInfo(activeTab);
      case "questions":
        return renderQuestionsInfo(activeTab);
      default:
        return "";
    }
  };

  return (
    <section className={"faq"}>
      <PageTitle text={"Вопросы и ответы"} />

      <div className="faq__wrapper app__text">
        <div className="faq__tabs-wrapper">
          <div className="faq__tabs">
            {isTablet ? (
              <Select
                items={faqTabs}
                closeOnSelect={true}
                value={getTabByName(activeTab.name)}
                bindTo="name"
                onItemSelect={(item) => setActiveTab(getTabByName(item.name))}
              />
            ) : (
              <>
                {faqTabs.map((tab, key) => (
                  <div
                    className={`faq__tab ${tab.name === activeTab.name ? "faq__tab_active" : ""}`}
                    key={key}
                    onClick={() => setActiveTab(getTabByName(tab.name))}
                  >
                    {tab.name}
                  </div>
                ))}
              </>
            )}
          </div>

          <div className={`faq__content faq__content--${activeTab.alias}`}>
            {activeTab.content ? renderText(activeTab) : renderHtml(activeTab)}

            {activeTab.attachments &&
              activeTab.attachments.map((attachment) => {
                if (attachment.videoId) {
                  return (
                    <Player
                      playsInline
                      disablePictureInPicture
                      key={attachment.videoId}
                      poster={process.env.PUBLIC_URL + "/images/" + attachment.preview}
                    >
                      <source src={process.env.PUBLIC_URL + "/video/" + attachment.videoId} />
                      <ControlBar autoHide={false} className="my-class" />
                      <BigPlayButton position="center" />
                    </Player>
                  );
                }

                return <></>;
              })}
          </div>
        </div>
      </div>

      {messageModalIsOpen && (
        <MessageModal
          isOpen={messageModalIsOpen}
          toProjectStaff={true}
          canDraft={false}
          sentOptions={{
            modal: {
              message: "Сообщение успешно отправлено. Ответ придет вам на личную почту платформы.",
            },
          }}
          draftOptions={{
            toast: {
              message: "Черновик успешно сохранен.",
            },
          }}
          onClose={() => setMessageModalIsOpen(false)}
        />
      )}
    </section>
  );
};

export default FaqPage;
