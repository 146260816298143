import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "../../../../types/State.interface";
import { getAvatarSrc } from "../../../../utils";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import "./LoggedInAvatar.scss";

const LoggedInAvatar = () => {
  const user = useSelector((state: RootState) => state.user.userDetails);
  const countAllUnread = useSelector((state: RootState) => state.notifications.countAllUnread);

  function getCounter() {
    const sum =
      (countAllUnread?.inboxCount || 0) + (countAllUnread?.commentResponseCount || 0) + (countAllUnread?.unreadNotificationsCount || 0) + countAllUnread?.unreadDialogMessageCount || 0;
    return (sum >= 100 && "99+") || sum;
  }

  return (
    <EventsTrackWrapperClick id={["HEADER", "AVATAR_AUTHORIZED", "CLICK"]}>
      <Link
        className="logged-in-avatar pointer"
        to={"/profile"}
      >
        <img src={getAvatarSrc(user?.pictureId)} alt="Личный кабинет" />
        {Boolean(getCounter()) && (
          <span className="logged-in-avatar__notifications">{getCounter()}</span>
        )}
      </Link>
    </EventsTrackWrapperClick>
  );
};

export default LoggedInAvatar;
