import { API, httpParamsSerialize } from "./api";
import StackTrace from "stacktrace-js";

const LoggerService = {
  registerError: (error) => {
    StackTrace.fromError(error)
      .then((err) => {
        StackTrace.report(
          err,
          `//${window.location.hostname}:${process.env.REACT_APP_PORT}/api/log/jsError`,
          {
            type: "PLATFORM React boundary",
            url: window.location.href,
            userId: null,
            agent: window.navigator.userAgent,
            date: new Date(),
            msg: error.toString(),
          } as any
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default LoggerService;
