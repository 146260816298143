import React from "react";
import { Link } from "react-router-dom";
import CommentService from "../../../../services/commentService";
import PostAddFavorite, {
  PostType,
} from "../../PostAddFavorite/PostAddFavorite";
import { CardLabel } from "../CardLabel/CardLabel";

import "./ProfileFavoriteCard.scss";

const ProfileFavoriteCard = (props) => {
  const getLink = () => {
    return props.post.stageId
      ? `/discussion/${props.post.stageId}`
      : `/users/user/post/${props.post.author?.id}/${props.post.ownerId}`;
  };

  return (
    <div className="profile-favorite-card post-card">
      <header className="profile-favorite-card__header post-card__header">
        <Link to={getLink()} className="post-card--hb">
          {props.post.title}
        </Link>

        <PostAddFavorite
          post={props.post}
          postType={PostType.FAVORITE}
          onChange={props.onChange}
        />
      </header>

      <Link to={getLink()}>
        <main
          className="profile-favorite-card__body post-card--bn"
          dangerouslySetInnerHTML={{
            __html: CommentService.CkBBCodeToHtml(props.post.rawContent),
          }}
        ></main>
      </Link>

      <footer className="profile-favorite-card__footer">
        <CardLabel post={props.post} />
      </footer>
    </div>
  );
};

export default ProfileFavoriteCard;
