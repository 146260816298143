import { MailMessage, MailTypeEnum } from "../types/Mail.interface";
import { API } from "./api";
import { IBaseResponse } from "../App.interface";

export type SendMailDestination = "FACILITATOR" | "EXPERT" | "SUPPORT";

export interface SendMailRequest {
  attachments: string[];
  destination?: SendMailDestination;
  draftId?: string;
  recipients?: string[];
  replyTo?: string;
  tag?: string;
  text: string;
  thread?: string;
  title: string;
}

export interface SaveMailRequest {
  attachments: string[];
  draftId?: string | null;
  recipients: string[];
  replyTo?: string;
  text: string;
  thread?: string;
  title: string;
}

export interface DeleteMailRequest {
  messageId: string;
  withThread: boolean;
}

export interface ReadMailRequest {
  id: string;
}

const baseUrl = "/api/mail";

const MailService = {
  send: (data: SendMailRequest) => {
    return API.post(`${baseUrl}/send`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  save: (data: SaveMailRequest) => {
    return API.post(`${baseUrl}/save`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  read: (data: ReadMailRequest) => {
    return API.post(`${baseUrl}/read`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  delete: (data: DeleteMailRequest) => {
    return API.post(`${baseUrl}/delete`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getUserMailCount: (): Promise<IBaseResponse<any[]>> => {
    return API.get(`${baseUrl}/getFoldersInfo`)
      .then((data) => {
        return data?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getByType: (page, size, type: MailTypeEnum) => {
    return API.get(
      `${baseUrl}/getByType?page=${page}&size=${size}&type=${type}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getThreads: (page, size): Promise<any> => {
    return API.get(`${baseUrl}/getThreads?page=${page}&size=${size}`).then(
      ({ data }) => {
        return data;
      }
    );
  },

  getThreadItems: (threadId: string): Promise<IBaseResponse<MailMessage[]>> => {
    return API.get(`${baseUrl}/getThreadItems?thread=${threadId}`).then(
      ({ data }) => {
        return data;
      }
    );
  },

  getById: (mailId: string): Promise<IBaseResponse<MailMessage>> => {
    return API.get(`${baseUrl}/get?id=${mailId}`).then(({ data }) => {
      return data;
    });
  },
};

export default MailService;
