import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import "./ExactIdea.scss";
import useQuery from "../../../../../utils/hooks/useQuery";
import ArrowBack from "../../../../presentational/Controls/ArrowBack/ArrowBack";
import ProposalCard from "../../../../presentational/Cards/ProposalCard/ProposalCard";
import { mapTypeToBackArrow } from "../MyIdeas.constants";
import ProposalService from "../../../../../services/proposalService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { ProposalTypeEnum } from "../../../../../types/Proposal.interface";
import { Loader } from "@crowd/ui-kit";

const ExactIdea = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const params = useParams<{ proposalId: string }>();

  const [proposal, setProposal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (params.proposalId) {
      fetchProposalById(params.proposalId);
    }
  }, []);

  const fetchProposalById = async (proposalId) => {
    try {
      setIsLoading(true);
      const { data, status, message } = await ProposalService.getById(proposalId);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }
      setProposal(data);
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />

  return (
    <div className="my-ideas__exact">
      {proposal && (
        <>
          <ArrowBack
            text={"Все " + mapTypeToBackArrow[proposal.status]}
            onClick={() => {
              history.push(`/profile/ideas?type=${proposal.status.toLowerCase()}`);
            }}
          />

          <ProposalCard
            proposal={proposal}
            showHistory={proposal.status === ProposalTypeEnum.REJECTED}
            showRejectReason={proposal.status === ProposalTypeEnum.REJECTED}
            canEdit={proposal.status === ProposalTypeEnum.REJECTED || proposal.status === ProposalTypeEnum.DRAFT}
          />
        </>
      )}
    </div>
  );
};

export default ExactIdea;
