import { API } from "./api";
import {ApiResponse, IPagingParams} from "../types/Common.interface";
import { queryBuilder } from "../utils/urlUtils";
import { ChatMessageRaw, Dialog } from "../components/pages/Profile/Conversations/Components/Chat/chat.types";

export interface GetChatMessagesParams {
   senderId: string;
   recipientId: string;
}

export interface CreateDialogParams {
  recipientId: string;
}

export interface DeleteDialogParams {
  id: string;
}

export interface GetDialogsParams {
  recipientId: string;
}

export interface GetDialogParams {
  id: string;
}

export interface GetMessagesByDialogId extends IPagingParams {
  dialogId: string;
  sort?: string;
}

export interface SendMessagePayload {
  messageId: string;
  dialogId: string;
  senderId: string;
  text: string;
  attachmentIds?: string[];
}

export interface RemoveMessagePayload {
  id: string;
  toAll: boolean;
  deletedByUserId: string;
}

export interface RemoveMessageResponse {
  id: string;
  dialogId: string;
  peerId: string;
  authorId: string;
  deletionStatus: string;
}

export interface ReadMessageResponse {
  dialogId: string;
  recipientId: string;
  senderId: string;
  ids: string[];
}

export interface ReadMessageParams {
  ids: string;
  readByUserId: string;
}


const baseMessageUrl = "/api/messages";
const baseDialogUrl = "/api/dialog";

const ChatService = {
  getChatMessages: (params: GetChatMessagesParams) => {
    return API.get(`${baseMessageUrl}/${params.senderId}/${params.recipientId}`).then(({ data }) => {
      return data;
    });
  },

  getDialogs: (params: IPagingParams) => {
    return API.get<ApiResponse<Dialog[]>>(`${baseDialogUrl}/getByFilter${queryBuilder(params)}`).then(({ data }) => {
      return data;
    });
  },

  getDialogById: (params: GetDialogParams) => {
    return API.get<ApiResponse<Dialog>>(`${baseDialogUrl}/getById${queryBuilder(params)}`).then(({ data }) => {
      return data;
    });
  },

  getMessagesByDialogId: (params: GetMessagesByDialogId) => {
    return API.get<ApiResponse<ChatMessageRaw[]>>(`${baseDialogUrl}/message/getByFilter${queryBuilder(params)}`).then(({ data }) => {
      return data;
    });
  },

  createDialog: (params: CreateDialogParams) => {
    return API.post<ApiResponse<Dialog>>(`${baseDialogUrl}/create`, params).then(({ data }) => {
      return data;
    });
  },

  deleteDialog: (params: DeleteDialogParams) => {
    return API.post<ApiResponse>(`${baseDialogUrl}/delete?id=${params.id}`).then(({ data }) => {
      return data;
    });
  },

  readMessage: (params: ReadMessageParams) => {
    return API.post<ApiResponse>(`${baseDialogUrl}/message/read`, params).then(({ data }) => {
      return data;
    });
  },
};

export default ChatService;
