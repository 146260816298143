export interface ProposalsCount {
  accepted: number;
  drafts: number;
  rejected: number;
  removed: number;
  submitted: number;
}

export interface AnswersCount {
  count: number;
  readCount: number;
  unreadCount: number;
}

export interface PersonalState {
  proposals: any;
  proposalsCount: ProposalsCount;
  comments: any;
  commentsCount: any;
  answersCount: AnswersCount;
  favorite: any;
  favoriteCount: any;
}

export enum DiscussionTypeEnum {
  ALL = "ALL",
  STAGE = "STAGE",
  BLOG = "BLOG",
}

export enum BlogType {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
}
