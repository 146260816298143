import CommonService from "../../services/commonService";

export const GET_ENV_VARIABLES = "GET_ENV_VARIABLES";

export const getEnvVariables = () => (dispatch) => {
  return CommonService.getEnvVariables()
    .then((data) => {
      dispatch({
        type: GET_ENV_VARIABLES,
        payload: data?.data,
      });
    })
    .catch((err) => console.log(err));
};
