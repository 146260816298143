import MailService, { SendMailRequest } from "../../services/mailService";
import { ApiStatusCode } from "../../types/Common.interface";
import { showErrorToast } from "./ToastActions";
import { showOkInfoModal } from "./LayoutActions";

export const SEND_MAIL_COMPLETED = "SEND_MAIL_COMPLETED";
export const SEND_MAIL_FAILED = "SEND_MAIL_FAILED";
export const GET_USER_MAILS = "GET_USER_MAILS";
export const UPDATE_MAIL_DATA = "UPDATE_MAIL_DATA";

export const sendMail =
  (data: SendMailRequest, successCb?: () => any) => (dispatch) => {
    MailService.send(data).then((res) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: SEND_MAIL_COMPLETED });
        dispatch(
          showOkInfoModal(
            null,
            "Сообщение успешно отправлено. Ответ придет вам на личную почту платформы."
          )
        );
        if (successCb) successCb();
      } else {
        dispatch({ type: SEND_MAIL_FAILED });
        dispatch(showErrorToast(res.message));
      }
    });
  };

export const getUserMail = () => (dispatch) => {
  return MailService.getUserMailCount()
    .then((data) => {
      dispatch({
        type: GET_USER_MAILS,
        payload: data?.data || [],
      });

      return data?.data || [];
    })
    .catch((err) => console.log(err));
};
