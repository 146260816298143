import { Button, Input } from "@crowd/ui-kit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { showErrorToast } from "../../../../store/actions/ToastActions";

import "./ChangePasswordForm.scss";

interface Props {
  onSubmit: Function;
  requireCurrentPassword?: boolean;
  title: string;
  renderButton: Function;
  isLoading?: boolean;
  submitButtonLabel: string;
}

const ChangePasswordForm = (props: Props) => {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  const [currentPasswordValidator, setCurrentPasswordValidator] = useState<any>(null);
  const [newPasswordValidator, setNewPasswordValidator] = useState<any>(null);
  const [newPasswordRepeatValidator, setNewPasswordRepeatValidator] = useState<any>(null);

  const onNewPasswordChange = (value) => {
    setNewPassword(value);
    newPasswordRepeatValidator.updateConfig({
      passwordsMustBeEqual: value,
    });
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);

    if (!newPasswordValidator.isValid()) {
      dispatch(showErrorToast("Пароль не соответствует требованиям."));
    } else if (!newPasswordRepeatValidator.isValid()) {
      dispatch(showErrorToast("Пароли не совпадают."));
    } else {
      if (props.requireCurrentPassword && !currentPasswordValidator.isValid()) return;

      const passwordChangeParams = {
        password: newPassword,
        ...(props.requireCurrentPassword && { currentPassword }),
      };

      props.onSubmit(passwordChangeParams);
    }
  };

  //RENDER

  return (
    <div className="change-password">
      <div className="change-password__form">
        <h3>{props.title}</h3>
        <form onSubmit={handleSubmit}>
          {props.requireCurrentPassword && (
            <Input
              label="Текущий пароль"
              type="password"
              value={currentPassword}
              isRequired={true}
              onChange={(value) => setCurrentPassword(value)}
              shouldValidate={true}
              validateRules={{ notEmpty: true }}
              onInitValidator={(validator) => setCurrentPasswordValidator(validator)}
              showError={submitted}
            />
          )}

          <Input
            label="Новый пароль"
            type="password"
            maxLength={36}
            value={newPassword}
            isRequired={true}
            shouldValidate={true}
            onChange={onNewPasswordChange}
            validateRules={{ notEmpty: true }}
            onInitValidator={(validator) => setNewPasswordValidator(validator)}
            showError={submitted}
          />

          <Input
            label="Повтор пароля"
            type="password"
            maxLength={36}
            value={newPasswordRepeat}
            isRequired={true}
            shouldValidate={true}
            onChange={(value) => setNewPasswordRepeat(value)}
            validateRules={{
              notEmpty: true,
              passwordsMustBeEqual: newPassword,
            }}
            onInitValidator={(validator) => setNewPasswordRepeatValidator(validator)}
            showError={submitted}
          />

          <div className="change-password__form__hint">
            <ul>
              {props.requireCurrentPassword && (
                <>
                  <li>Новый пароль должен отличаться как минимум на 5 символов</li>
                  <li>Новый пароль должен отличаться от 5 предыдущих паролей</li>
                </>
              )}
              <li>Длина пароля должна быть не менее 8 символов</li>
              <li>Пароль должен содержать хотя бы одну цифру</li>
              <li>Пароль должен содержать хотя бы одну заглавную букву</li>
              <li>Пароль должен содержать хотя бы одну строчную букву</li>
              <li>{`Пароль должен содержать хотя бы один из символов: ~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \\ | " ' . , : ;`}</li>
            </ul>
          </div>

          <Button type="outlined" text={props.submitButtonLabel} isLoading={props.isLoading} onClick={handleSubmit} />
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
