import { useEffect, useRef } from "react";

const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isMountingRef = useRef(true);

  useEffect(() => {
    if (isMountingRef.current) {
      isMountingRef.current = false;
    } else {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
