import { GET_USER_MAILS, UPDATE_MAIL_DATA } from "../actions/MailActions";

const initialState = {
  inbox: null,
  sent: null,
  draft: null,
  trash: null,
};

export function mailReducer(state: any = initialState, action) {
  switch (action.type) {
    case GET_USER_MAILS:
      return {
        ...state,
        ...action.payload.reduce((acc, curr) => {
          acc[curr.type.toLowerCase()] = curr;
          return acc;
        }, {}),
      };

    case UPDATE_MAIL_DATA:
      return {
        ...state,
        [action.type.type]: action.type.data,
      };

    default:
      return state;
  }
}
