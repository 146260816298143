export const getCardName = (stageType): string => {
  switch (stageType) {
    case "EXPERT_SELECTION":
      return "selection";
    case "GENERATION":
      return "generation";
    case "SIMPLE_DISCUSSION":
    case "PROJECT_DISCUSSION":
      return "discussion";
    case "VOTING":
      return "voting";
    default:
      return "";
  }
};

export const getStageName = (stageType): string => {
  switch (stageType) {
    case "EXPERT_SELECTION":
      return "Отбирай";
    case "GENERATION":
      return "Предлагай";
    case "SIMPLE_DISCUSSION":
    case "PROJECT_DISCUSSION":
      return "Обсуждай";
    case "VOTING":
      return "Оценивай";
    default:
      return "";
  }
};

export const getFrontDescriptions = (stageType) => {
  switch (stageType) {
    case "EXPERT_SELECTION":
      return {
        noPlanned: "Экспертный отбор идей не запланирован",
        anyCurrent: "Экспертный отбор идей",
        anyAnnouncement: "Экспертный отбор идей c",
        allFinished: "Экспертный отбор идей завершен",
        fallbackCase: "Экспертный отбор идей",
      };
    case "GENERATION":
      return {
        noPlanned: "Сбор идей не запланирован",
        anyCurrent: "Подавайте свои идеи",
        anyAnnouncement: "Подавайте свои идеи c",
        allFinished: "Сбор идей завершен",
        fallbackCase: "Подавайте свои идеи",
      };
    case "SIMPLE_DISCUSSION":
    case "PROJECT_DISCUSSION":
      return {
        noPlanned: "Обсуждения с участниками не запланированы",
        anyCurrent: "Общайтесь с другими участниками",
        anyAnnouncement: "Принимайте участие в обсуждении c",
        allFinished: "Нет активных обсуждений",
        fallbackCase: "Принимайте участие в обсуждении",
      };
    case "VOTING":
      return {
        noPlanned: "Голосование за идеи не запланировано",
        anyCurrent: "Голосуйте за идеи других участников",
        anyAnnouncement: "Голосуйте за идеи участников c",
        allFinished: "Голосование за идеи участников завершено",
        fallbackCase: "Голосуйте за идеи участников",
      };
    default:
      return {};
  }
};
