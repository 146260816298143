export interface DatePickerProps {
  type?: DatePickerType;
  date: any;
  disabledDays?: any;
  onChange?: (date) => void;
  onSelectAll?: (date) => void;
  numberOfMonths?: number;
  modifiers?: any;
}

export enum DatePickerType {
  PLAIN,
  RANGED,
}
