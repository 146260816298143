import React, { ReactNode } from "react";
import dayjs from "dayjs";
import { capitalize, getDuration, rusStringToDate, uuidv4 } from "../../../utils";
import "./CalendarStripe.scss";
import { connect } from "react-redux";

dayjs.locale("ru");

interface Props {
  start: dayjs.Dayjs;
  finish: dayjs.Dayjs;
  currentDate: dayjs.Dayjs;
  cellWidth: number;
  cellPerView?: number;
  onMoveLeft: (date: any, jump?) => void;
  onMoveRight: (date: any, jump?) => void;
  renderExtraFn?: (item) => ReactNode;
  showMonth?: boolean;
  center?: boolean;
  highlightActive?: boolean;
  underlineActive?: boolean;
  setTranslate?: any;
  type?: string;
  documentWidth: any;
}

const lightBlue = "#65AAE0";

class CalendarStripe extends React.Component<Props, any> {
  swipeRef: any;
  translateX = 0;
  currentDate = null;
  direction = null;

  constructor(props) {
    super(props);

    this.swipeRef = React.createRef();
    this.currentDate = this.props.currentDate;

    this.setTranslate(this.props.currentDate);

    this.state = {
      scale: this.createScale(),
    };

    this.moveLeft = this.moveLeft.bind(this);
    this.moveRight = this.moveRight.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.move());
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.datesAreEqual(prevProps.currentDate, this.props.currentDate)) {
      const newDate = this.props.currentDate;
      this.currentDate = newDate;
      this.setTranslate(newDate);
      this.move();
      this.forceUpdate();
    }

    if (this.props.documentWidth !== prevProps.documentWidth) {
      this.setTranslate(this.currentDate);
      this.move();
    }
  }

  setTranslate(date) {
    const targetDate = date;
    const daysTotal = this.daysTotal();

    if (this.scrolledToStartMiddle(targetDate)) {
      return (this.translateX = 0);
    }

    if (this.scrolledToEndMiddle(targetDate)) {
      if (daysTotal < this.props.cellPerView) {
        return;
      }

      const endDays = this.props.cellPerView % 2 === 0 ? daysTotal + 1 : daysTotal;
      return (this.translateX = (endDays - this.lineHalf() * 2) * -this.props.cellWidth);
    }

    this.translateX = targetDate.diff(this.firstDay().add(this.lineHalf(), "day"), "day") * -this.props.cellWidth;
  }

  move() {
    if (this.swipeRef?.current) {
      this.swipeRef.current.style.transform = `translateX(${this.translateX}px)`;
      this.props.setTranslate && this.props.setTranslate(this.translateX);
    }
  }

  firstDay = () => this.props.start.startOf("day");

  lastDay = () => this.props.finish.startOf("day");

  daysTotal = () => this.lastDay().diff(this.firstDay(), "day");

  lineHalf = () => {
    return Math.abs(this.props.cellPerView % 2) === 1 ? Math.floor(this.props.cellPerView / 2) : Math.ceil(this.props.cellPerView / 2);
  };

  isToday = (day) => day.format("YY.MM.DD") === dayjs().format("YY.MM.DD");

  datesAreEqual = (a, b) => a.format("YY.MM.DD") === b.format("YY.MM.DD");

  scrolledToStart = (date) => {
    return date.isSame(this.firstDay(), "day");
  };

  scrolledToStartMiddle = (date) => {
    return date.subtract(this.lineHalf(), "day").isBefore(this.firstDay());
  };

  scrolledToEnd = (date) => {
    return date.isSame(this.lastDay(), "day");
  };

  scrolledToEndMiddle = (date) => {
    date.add(this.lineHalf(), "day");
    return date.add(this.lineHalf(), "day").isAfter(this.lastDay());
  };

  createScale() {
    const duration = getDuration(this.props.start, this.props.finish);
    return new Array(Math.max(this.props.cellPerView, duration)).fill(1).map((d, index) => {
      return {
        id: uuidv4(),
        date: dayjs(this.props.start).add(index, "day").locale("ru"),
        isGhost: index >= duration,
      };
    });
  }

  getCurrentDate(date) {
    if (this.props.highlightActive || this.props.underlineActive) return date;
    const targetDate = date;
    const diffLeft = Math.abs(this.getDaysDiff(targetDate, this.firstDay())) - 1;
    const diffRight = Math.abs(this.getDaysDiff(targetDate, this.lastDay())) - 1;
    const toLeft = targetDate.isBefore(this.currentDate, "day");
    const toRight = targetDate.isAfter(this.currentDate, "day");
    if ((toLeft && diffRight >= this.lineHalf()) || (toRight && diffLeft >= this.lineHalf())) {
      if (this.scrolledToStartMiddle(targetDate)) {
        return this.state.scale[0].date;
      }
      if (this.scrolledToEndMiddle(targetDate)) {
        return this.state.scale.at(-1).date;
      }
      return targetDate;
    }
    if (toLeft) {
      return targetDate.subtract(this.lineHalf() - diffRight, "day");
    }
    if (toRight) {
      return targetDate.add(this.lineHalf() + diffLeft, "day");
    }
  }

  commonMove(targetDate) {
    const currentDate = this.getCurrentDate(targetDate);
    this.setTranslate(currentDate);
    this.move();
    this.currentDate = currentDate;
  }

  moveLeft(targetDate, diff) {
    this.commonMove(targetDate);
    this.props.onMoveLeft(this.currentDate, diff);
  }

  moveRight(targetDate, diff) {
    this.commonMove(targetDate);
    this.props.onMoveRight(this.currentDate, diff);
  }

  getContainerWidth() {
    return this.props.cellPerView ? this.props.cellPerView * this.props.cellWidth + "px" : "100%";
  }

  getDaysDiff(a, b) {
    return a.startOf("day").diff(b.startOf("day"), "day");
  }

  swipeToDate(_date, e?) {
    const getDateFromPoint = (e) => {
      const elementsFromPoint = document.elementsFromPoint(e.clientX, e.clientY);
      const cellElement = elementsFromPoint.find((el) => el.classList.contains("calendar-stripe__cell")) as HTMLElement;
      return cellElement && dayjs(rusStringToDate(cellElement.dataset.date));
    };

    const date = e ? getDateFromPoint(e) : _date;
    if (date) {
      const diff = this.getDaysDiff(this.currentDate, date);

      const direction = Math.sign(diff) === -1 ? "right" : "left";
      this[`move${capitalize(direction)}`](date, diff);
    }
  }

  getArrowClassList(direction) {
    let classList = `ui-icon-arrow-slider-${direction} calendar-stripe__arrow calendar-stripe__arrow-${direction}`;
    if (direction === "left" && this.scrolledToStart(this.currentDate)) {
      classList += " calendar-stripe__arrow--disabled";
    }
    if (direction === "right" && this.scrolledToEnd(this.currentDate)) {
      classList += " calendar-stripe__arrow--disabled";
    }

    return classList;
  }

  getActiveColor = (date) => {
    return this.props.highlightActive
      ? date.format("DD.MM.YYYY") === this.currentDate.format("DD.MM.YYYY") && lightBlue
      : this.isToday(date) && lightBlue;
  };

  getClassListForCell = (cell) => {
    let classlist = "calendar-stripe__cell";

    if (this.props.highlightActive) {
      if (cell.date.format("DD.MM.YYYY") === this.currentDate.format("DD.MM.YYYY")) {
        classlist += " calendar-stripe__cell--active";
      }
    } else {
      if (this.isToday(cell.date)) {
        classlist += " calendar-stripe__cell--today";
      }
    }

    if (cell.isGhost) {
      classlist += " calendar-stripe__cell--ghost";
    }

    return classlist;
  };

  renderScale() {
    return this.state.scale.map((item) => (
      <div data-date={item.date.format("DD.MM.YYYY")} className={this.getClassListForCell(item)} key={item.id}>
        <div
          className="calendar-stripe__cell-date"
          style={{
            width: this.props.cellWidth || "44px",
          }}
        >
          {item.date.format("DD")}
        </div>
        <div className="calendar-stripe__cell-week">{item.date.format("dd")}</div>

        {this.props.underlineActive && item.date.format("DD.MM.YYYY") === this.currentDate.format("DD.MM.YYYY") && (
          <div className="calendar-stripe__cell-underline"></div>
        )}

        <div>{this.props.renderExtraFn && this.props.renderExtraFn(item)}</div>
      </div>
    ));
  }

  render() {
    return (
      <div className="calendar-stripe" style={{ width: this.getContainerWidth() }}>
        <span className={this.getArrowClassList("left")} onClick={() => this.moveLeft(this.currentDate.subtract(1, "day"), 1)}></span>

        <div className="calendar-stripe__content">
          {this.props.showMonth && <div className="calendar-stripe__month">{this.currentDate.format("MMMM")}</div>}

          <div className="calendar-stripe__swipable" ref={this.swipeRef}>
            {this.state.scale && this.renderScale()}
          </div>

          <div className="calendar-stripe__controls" onClick={(event) => this.swipeToDate(null, event)}></div>

          {this.props.underlineActive && <div className="calendar-stripe__underline"></div>}
        </div>
        <span className={this.getArrowClassList("right")} onClick={() => this.moveRight(this.currentDate.add(1, "day"), -1)}></span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documentWidth: state.globalEvents.width,
  };
};

export default connect(mapStateToProps, null)(CalendarStripe);
