import { ApiResponse } from "../types/Common.interface";
import { API } from "./api";

export interface Band {
  desktopAttachmentId: string;
  tabletAttachmentId: string;
  phoneAttachmentId: string;
  link: string;
  identifier: string;
}
export interface BandsResponse {
  bands: Band[];
  duration: number | null;
}

const baseSettingsUrl = "/api/settings";

const CommonService = {
  getEnvVariables: () => {
    return API.get(`${baseSettingsUrl}/env`).then((data) => {
      return data?.data;
    });
  },

  getLicense: () => {
    return API.get(`${baseSettingsUrl}/license`).then(({ data }) => {
      return data;
    });
  },
  getBands: () => {
    return API.get<ApiResponse<BandsResponse>>(`${baseSettingsUrl}/bands`).then(({ data }) => data);
  },
};

export default CommonService;
