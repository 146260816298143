import acceptedActive from "../../../../../assets/selection/accepted-active.svg";
import accepted from "../../../../../assets/selection/accepted.svg";
import forwardedActive from "../../../../../assets/selection/forwarded-active.svg";
import forwarded from "../../../../../assets/selection/forwarded.svg";
import incomingActive from "../../../../../assets/selection/incoming-active.svg";
import rejected from "../../../../../assets/selection/rejected.svg";
import rejectedActive from "../../../../../assets/selection/rejected-active.svg";
import incoming from "../../../../../assets/selection/incoming.svg";
import { SelectionFolders } from "../../../../../utils/constants/proposal";

export const selectionFolderButtonsFolderToIconMap = {
  [SelectionFolders.INCOMING]: {
    default: incoming,
    active: incomingActive,
  },
  [SelectionFolders.ACCEPTED]: {
    default: accepted,
    active: acceptedActive,
  },
  [SelectionFolders.REJECTED]: {
    default: rejected,
    active: rejectedActive,
  },
  [SelectionFolders.FORWARDED]: {
    default: forwarded,
    active: forwardedActive,
  },
};
