import { showMosruForm } from "../store/actions/LayoutActions";
import { store } from "../store/store";

export enum AuthActionType {
  OpenGenerationModal,
  Redirect,
  OpenBlogModal,
  WriteComment,
  NavigateToVotingPage,
  ProposeIdea,
  SendMessage,
  OpenUserDetailsModal,
  RedirectAFterRegForm,
  VISIT_LAST_PAGE,
  OpenCommentForm,
  PostAddFavorite,
}

export enum ComponentType {
  MainPage,
  StagesTapeCard,
  ParticipantsPage,
  MyBlog,
  StageList,
  ExpertCardList,
  Discussion,
  CommentCardList,
  FAQ,
  Team,
  UserModal,
  ProjectParticipants,
  AFTER_REGISTRATION_FORM,
  PostAddFavorite,
}

export interface AuthActionParam {
  component: ComponentType;
  args?: any;
  type: AuthActionType | any;
}

let instance = null;

export class SudirService {
  private _actions: AuthAction[] | any = [];

  constructor() {
    if (!instance) {
      instance = this;
      this.getActionsFromUrl();
    }
    return instance;
  }

  getActions(component) {
    const componentActions = this._actions.filter((action) => action.component === component);
    this._actions = this._actions.filter((action) => action.component !== component);

    return componentActions;
  }

  set actions(value: AuthAction[]) {
    this._actions = value;
  }

  getActionsFromUrl() {
    const urlSearch = new URLSearchParams(window.location.search);
    const actions = urlSearch.get("actions");
    if (actions) {
      try {
        const decodedActions = decodeURIComponent(actions);
        const parsed = JSON.parse(decodedActions);
        this.actions = parsed.map((p) => new AuthAction(p));
      } catch (error) {
        console.error("Ошибка при разборе JSON:", error);
      } finally {
        this.clear();
      }
    }
  }

  authWithActions(actions: AuthAction[], preventRedirectToLastPage = false) {
    store.dispatch(showMosruForm(this.generateReturnUrlParameter(window.location.href, actions, preventRedirectToLastPage)));
  }

  generateReturnUrlParameter(returnUrl, actions?: AuthAction[], preventRedirectToLastPage = false) {
    let _returnUrl = returnUrl;
    if (actions) {
      const data: AuthActionParam = {
        component: ComponentType.AFTER_REGISTRATION_FORM,
        type: AuthActionType.VISIT_LAST_PAGE,
        args: {
          lastVisitedPage: window.location.pathname,
        },
      };
      const redirectAfterRegFormAction = new AuthAction(data);
      const allActions = preventRedirectToLastPage ? actions : [...actions, redirectAfterRegFormAction];
      const stringifiedActions = JSON.stringify(allActions);
      const encodedActions = encodeURIComponent(stringifiedActions);
      _returnUrl += (returnUrl.includes("?") ? "&" : "?") + `actions=${encodedActions}`;
    }
    const encodedReturnUrl = encodeURIComponent(_returnUrl);
    return `returnUrl=${encodedReturnUrl}`;
  }

  generateActionsLink(returnLocation, actions?: AuthAction[]) {
    let link = returnLocation;
    if (actions) {
      const stringified = JSON.stringify(actions);
      const encoded = encodeURIComponent(stringified);
      link += (returnLocation.includes("?") ? "&" : "?") + `actions=${encoded}`;
    }
    return link;
  }

  clear() {
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete("actions");
    const resultString = urlObj.toString();
    window.history.pushState({}, "", resultString);
  }
}

export class AuthAction {
  component: ComponentType;
  args: any;
  type;

  constructor(action: AuthActionParam) {
    this.component = action.component;
    this.args = action.args;
    this.type = action.type;
  }
}
