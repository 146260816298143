import PersonalService from "../../services/personalService";

export const GET_PERSONAL_PROPOSALS = "GET_PERSONAL_PROPOSALS";
export const GET_PERSONAL_PROPOSALS_COUNT = "GET_PERSONAL_PROPOSALS_COUNT";
export const GET_PERSONAL_COMMENTS = "GET_PERSONAL_COMMENTS";
export const GET_PERSONAL_COMMENTS_COUNT = "GET_PERSONAL_COMMENTS_COUNT";
export const GET_FAVORITE_DISCUSSIONS = "GET_FAVORITE_DISCUSSIONS";
export const GET_PERSONAL_ANSWERS_COUNT = "GET_PERSONAL_ANSWERS_COUNT";
export const GET_FAVORITE_DISCUSSIONS_COUNT = "GET_FAVORITE_DISCUSSIONS_COUNT";

export const getPersonalProposals = (params?) => (dispatch) => {
  return PersonalService.getProposals()
    .then(({ data }) => {
      dispatch({ type: GET_PERSONAL_PROPOSALS, payload: data });
    })
    .catch((err) => console.log(err));
};

export const getPersonalProposalsCount = (params?) => (dispatch) => {
  return PersonalService.getProposalsCount()
    .then(({ data }) => {
      dispatch({ type: GET_PERSONAL_PROPOSALS_COUNT, payload: data });
    })
    .catch((err) => console.log(err));
};

export const getPersonalComments = (params?) => (dispatch) => {
  return PersonalService.getComments()
    .then(({ data }) => {
      dispatch({ type: GET_PERSONAL_COMMENTS, payload: data });
    })
    .catch((err) => console.log(err));
};

export const getPersonalCommentsCount = (params?) => (dispatch) => {
  return PersonalService.getCommentsCount()
    .then(({ data }) => {
      dispatch({ type: GET_PERSONAL_COMMENTS_COUNT, payload: data });
    })
    .catch((err) => console.log(err));
};

export const getPersonalAnswersCount = () => (dispatch) => {
  return PersonalService.getAnswersCount()
    .then((data) => {
      dispatch({ type: GET_PERSONAL_ANSWERS_COUNT, payload: data?.data });
    })
    .catch((err) => console.log(err));
};

export const getFavoriteDiscussions = (params?) => (dispatch) => {
  return PersonalService.getFavoriteDiscussions()
    .then(({ data }) => {
      dispatch({ type: GET_FAVORITE_DISCUSSIONS, payload: data });
    })
    .catch((err) => console.log(err));
};

export const getFavoriteDiscussionsCount = (params?) => (dispatch) => {
  return PersonalService.getFavoriteDiscussions()
    .then(({ data }) => {
      dispatch({ type: GET_FAVORITE_DISCUSSIONS_COUNT, payload: data });
    })
    .catch((err) => console.log(err));
};
