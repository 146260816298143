import React, { Fragment, useEffect, useState } from "react";

import "./ComplaintModal.scss";
import {
  ComplaintAddParams,
  ComplaintModalProps,
} from "../../../../types/Complaint.interface";
import UserAvatar from "../../../presentational/UserAvatar/UserAvatar";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import { useDispatch, useSelector } from "react-redux";
import { addComplaint } from "../../../../store/actions/ComplainActions";
import { Button } from "@crowd/ui-kit";
import { showMosruForm } from "../../../../store/actions/LayoutActions";
import { RootState } from "../../../../types/State.interface";
import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";
import { renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";
import EventsTrackClickTextarea from "../../EventsTrack/wrappers/helpers/EventsTrackClickTextarea";
import EventsTrackClickButton from "../../EventsTrack/wrappers/helpers/EventsTrackClickButton";

const ComplaintModal = ({
  user,
  type,
  comment,
  onClose,
}: ComplaintModalProps) => {
  const dispatch = useDispatch();
  const [complaintValue, setComplaintValue] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (!currentUser.loggedIn) {
      onClose();
      dispatch(showMosruForm());
    }
  }, []);

  const getTitle = (): string => {
    switch (type) {
      case "USER":
        return "Жалоба на участника";
      case "COMMENT":
        return "Жалоба на комментарий участника";
      default:
        return "";
    }
  };

  const submitForm = async () => {
    setSubmitted(true);
    if (complaintValue) {
      const formData: ComplaintAddParams = {
        id: (comment || user)?.id as string,
        text: complaintValue,
        type,
      };
      setIsSubmitting(true);
      dispatch(
        addComplaint(
          formData,
          () => {
            setIsSubmitting(false);
            onClose();
          },
          () => setIsSubmitting(false)
        )
      );
    }
  };

  const renderUser = () => (
    <div className={"complaint-modal__user"}>
      {!!user && <UserAvatar user={user} />}
      <div className="complaint-modal__user-name">
        {user?.firstName} {user?.lastName}
      </div>
      {renderHatIfUserNameIsHidden(user, currentUser)}
    </div>
  );

  return (
    <SubmitForm
      classNames="complaint-modal"
      submitText={"Пожаловаться"}
      isOpen={true}
      onClose={onClose}
      isSubmitting={isSubmitting}
      onSubmit={submitForm}
    >
      <Fragment key={"header"}>{getTitle()}</Fragment>
      <Fragment key={"subhead"}>
        {type === "USER" && renderUser()}
        {type === "COMMENT" && comment && (
          <CommentCard
            comment={comment}
            background={"#F2F4F7"}
            commentInfoHintType={"SHORT"}
            readonly={true}
          />
        )}
      </Fragment>
      <Fragment key={"body"}>
        <EventsTrackClickTextarea
          value={complaintValue}
          onChange={setComplaintValue}
          placeholder={"Опишите суть жалобы"}
          hint={"Не более 400 символов"}
          maxLength={400}
          showError={submitted}
          shouldValidate={true}
          validateRules={{
            notEmpty: true,
            maxLength: 400,
          }}
        />
      </Fragment>
      <Fragment key={"footer"}>
        <EventsTrackClickButton
          text={"Отменить"}
          type={"outlined-grey"}
          size={"m"}
          onClick={onClose}
          params={{
            partial: true,
          }}
        />
      </Fragment>
    </SubmitForm>
  );
};

export default ComplaintModal;
