import React from "react";

import Tooltip from "../../../presentational/Tooltip/Tooltip";
import {
  Project,
  ProjectStatistic,
} from "../../../../types/Projects.interface";
import "./MainActionsStat.scss";

interface MainActionsStatProps {
  project: Project;
  projectStat: ProjectStatistic;
}

const MainActionsStat = ({ project, projectStat }: MainActionsStatProps) => {
  return project && projectStat ? (
    <div>
      <div className="main__actions-stat">
        <div className="stats-block">
          <Tooltip text={"Участников"} idx={project.id + "user"}>
            <div className="ui-icon-user ui-icon" />
          </Tooltip>
          <span className="stats-block__number">
            {projectStat.participantCount || 0}
          </span>
        </div>
        <div className="stats-block">
          <Tooltip text={"Идей"} idx={project.id + "idea"}>
            <div className="ui-icon-idea ui-icon" />
          </Tooltip>
          <span className="stats-block__number">
            {projectStat.proposalCount || 0}
          </span>
        </div>
        <div className="stats-block">
          <Tooltip text={"Комментариев"} idx={project.id + "comments"}>
            <div className="ui-icon-comments ui-icon" />
          </Tooltip>
          <span className="stats-block__number">
            {projectStat.commentCount || 0}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MainActionsStat;
