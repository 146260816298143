import { UserGender, UserRole } from "../types/User.interface";

export const nameIfHidden = "Пользователь платформы";

export const getDisplayName = (user) => {
  return user?.deletedSudir ? nameIfHidden : `${user?.firstName} ${user?.lastName}`;
};

export const canShowHiddenNameAttribute = (currentUser) => currentUser.teamMember;

export const renderHatIfUserNameIsHidden = (postOwner, loggedInUser) => {
  if (!postOwner || postOwner.deletedSudir || postOwner.showName) return;

  const isTeamMember = loggedInUser?.roles?.some((role) =>
    [
      UserRole.PROJECT_BOSS,
      UserRole.MANAGER,
      UserRole.FACILITATOR,
      UserRole.PROJECT_FACILITATOR,
      UserRole.ADMINISTRATOR,
      UserRole.ANALYST,
      UserRole.FACILITATOR_IZRG,
    ].includes(role)
  );
  if (!isTeamMember) return;
  return <span className="ui-icon ui-icon-hidden user-name-hidden-icon"></span>;
};

export const genderizeWord = (string: string, gender: UserGender) => {
  switch (string) {
    case "предложил": {
      return gender === UserGender.FEMALE ? "предложила" : "предложил";
    }
    case "опубликовал": {
      return gender === UserGender.FEMALE ? "опубликовала" : "опубликовал";
    }
    case "оставил": {
      return gender === UserGender.FEMALE ? "оставила" : "оставил";
    }
    default: {
      return string;
    }
  }
};
