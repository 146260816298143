import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  ApiStatusCode,
  PagingResponseParameters,
} from "../../../../../types/Common.interface";
import useQuery from "../../../../../utils/hooks/useQuery";
import ProposalService from "../../../../../services/proposalService";
import PageTitle from "../../../../presentational/PageTitle/PageTitle";
import { ProposalInterface } from "../../../../../types/Proposal.interface";
import PersonalPagination from "../../PersonalPagination/PersonalPagination";
import ProposalCard from "../../../../presentational/Cards/ProposalCard/ProposalCard";
import ContentLoader from "../../../../presentational/Loaders/ContentLoader/ContentLoader";
import "./PersonalProposals.scss";
import { scrollToTop } from "../../../../../utils";

const COUNT_PER_PAGE = 10;

const PersonalProposals = () => {
  const query = useQuery();
  const page: number = isNaN(Number.parseInt(query.page))
    ? 0
    : Number.parseInt(query.page) - 1;
  const { userId } = useParams<{ userId: string }>();
  const [proposals, setProposals] = useState<ProposalInterface[]>([]);
  const [paging, setPaging] = useState<PagingResponseParameters>({
    pageSize: COUNT_PER_PAGE,
    pageNumber: page,
  });
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [isLoading, setLoading] = useState<boolean>(false);

  const loadProposals = async (curPageNum: number) => {
    setLoading(true);

    const proposalResp = await ProposalService.getProposalsByUserId(userId, {
      size: COUNT_PER_PAGE,
      page: curPageNum,
    });

    if (proposalResp.status === ApiStatusCode.OK) {
      setProposals(proposalResp.data);
      setPaging(proposalResp.paging);
      setCurrentPage(curPageNum);
      scrollToTop();
    } else {
      console.warn(proposalResp);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadProposals(currentPage).then();
  }, []);

  return (
    <div className="personal-proposals">
      <PageTitle text={"Принятые идеи"} />
      <ContentLoader isLoading={isLoading}>
        {proposals.length === 0 && (
          <div className="empty-content">
            У участника отсутствуют принятые идеи
          </div>
        )}

        {proposals.length > 0 && (
          <div className="personal-proposals__list">
            {proposals.map((proposal) => (
              <ProposalCard
                proposal={proposal}
                key={proposal?.id}
                titleLink={`/users/user/proposal/${proposal.authorId}/${proposal.id}`}
              />
            ))}
          </div>
        )}
      </ContentLoader>

      <PersonalPagination
        paging={paging}
        onPageChange={loadProposals}
        paginationName={"page"}
        curPageNum={currentPage}
      ></PersonalPagination>
    </div>
  );
};

export default PersonalProposals;
