import dayjs, { Dayjs } from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  sizesMap,
  legendMap,
  maxFoldInRows,
  stageMargin,
  dotterSize,
  tabletHeaderHeight,
  tabletLineHeight,
  tabletLineMargin,
} from "./StageList.constants";
import { MobileDirection, StageListEntity, StageListMobileEntity } from "./StageList.models";
import { SizeType } from "./StageList.interface";

import "./StageList.scss";
import "dayjs/locale/ru";
import { Button } from "@crowd/ui-kit";
import CalendarStripe from "../../../presentational/CalendarStripe/CalendraStripe";
import NodeModal from "../NodeModal/NodeModal";
import { AuthActionType, ComponentType } from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { useHistory } from "react-router-dom";
import { calculateRemainedTime, getDuration } from "../../../../utils";
import { StageTypes } from "../../../../types/Stage.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { stageTypesMap } from "./StageList.constants";
import Dotter from "../../../presentational/Controls/Dotter/Dotter";
import StageMobileCard from "../StageMobileCard/StageMobileCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { useForceUpdate } from "../../../../utils/hooks/useForceUpdate";
import { checkShowStage } from "./StageList.helper";

const mobileCellCount = 7;
const defaultCellCount = 12;

const StageList = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  let canSetDate = true;

  const isPhone: boolean = useMediaQuery({
    query: `(max-width: ${phoneWidth}px)`,
  });

  const isTablet: boolean = useMediaQuery({
    query: `(max-width: ${tabletWidth}px)`,
  });

  const isDesktop: boolean = useMediaQuery({
    query: `(min-width: ${tabletWidth}px)`,
  });

  const documentWidth = useSelector((state: RootState) => state.globalEvents.width);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const scroll = useSelector((state: RootState) => state.globalEvents.scroll);

  const translateX = useRef(0);

  const [swiper, setSwiper] = useState(null);
  const [entities, setEntities] = useState(null);
  const [mobileEntities, setMobileEntities] = useState(null);
  const [mobileEntitiesMap, setMobileEntitiesMap] = useState(null);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [entity, setEntity] = useState<StageListEntity | StageListMobileEntity | null | any>(null);
  const [foldedOut, setFoldedOut] = useState(false);
  const [cellPerView, setCellPerView] = useState<number>(isPhone ? mobileCellCount : defaultCellCount);

  const elementsRef = useRef<HTMLDivElement | {}>({});
  const chartRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDate(date);
    forceUpdate();
  }, [documentWidth]);

  useEffect(() => {
    if (isPhone) {
      if (scroll > 100) {
        headerRef.current.classList.add("stage-list__header--fixed");
      } else if (scroll < 20 && headerRef.current.classList.contains("stage-list__header--fixed")) {
        headerRef.current.classList.remove("stage-list__header--fixed");
        window.scrollBy(0, 70);
      }
    }
  }, [scroll]);

  const _setEntities = () => {
    const nodes = props.nodes.filter((node) => node.stages.some(checkAccsess));
    const entities = nodes.sort(compare).map((node) => {
      const options = {
        title: node.title,
        description: node.description,
        stages: filterStages(node.stages),
        from: props.start,
        to: props.finish,
        forDiscussionStage: node.forDiscussionStage,
      };

      return new StageListEntity(options);
    });

    setEntities(entities);
  };

  const findNodeBystage = (stage, nodes) => nodes.find((n) => n.stages.find((s) => s?.id === stage?.id));

  const _setMobileEntities = () => {
    const duration = getDuration(props.start, props.finish);
    const allStages = props.nodes.map((n) => filterStages(n.stages)).flat();

    const mobileEntitiesMap = {};
    new Array(duration).fill(null).forEach((cell, id) => {
      const date = props.start.startOf("day").add(id, "day");

      const stages = allStages.filter((stage) => isStageActiveOnDay(stage, date));

      const node = findNodeBystage(stages[0], props.nodes);
      const options = {
        title: node?.title,
        description: node?.description,
        date,
        stages,
      };
      mobileEntitiesMap[getFormatDate(date)] = new StageListMobileEntity(options);
    });
    setMobileEntitiesMap(mobileEntitiesMap);

    const entities = Object.values(mobileEntitiesMap).sort((ent1: any, ent2: any) => ent1.date.valueOf() - ent2.date.valueOf());

    setMobileEntities(entities);
  };

  useEffect(() => {
    _setEntities();
    _setMobileEntities();

    // ACTIONS AFTER AUTH
    const actions = appContext.sudirService.getActions(ComponentType.StageList);
    if (actions.length) {
      const redirectAction = actions.find((act) => act.type === AuthActionType.Redirect);
      if (redirectAction) {
        history.push(redirectAction.args.redirectUrl);
      }
    }
  }, []);

  useEffect(() => {
    if (swiper) {
      canSetDate = false;
      swiper?.slideTo(entityToIndex());
    }
  }, [swiper]);

  const moveTimeline = () => {
    Object.entries(elementsRef.current).forEach(([, element]: any) => {
      if (element) {
        element.style.transform = `translateX(${translateX.current}px)`;
      }
    });
  };

  const getSizeByDeviceType = (ent) => {
    const paddingOffset = 48;
    if (isPhone) {
      return Math.trunc((documentWidth - paddingOffset) / cellPerView);
    }

    if (isTablet) {
      return Math.trunc((documentWidth - paddingOffset) / cellPerView);
    }

    const leftTitleOffset = documentWidth * 0.4;
    const desktop = sizesMap["desktop"][ent]();
    const calcDesktop = Math.trunc((documentWidth - leftTitleOffset) / cellPerView);

    return calcDesktop > desktop ? desktop : calcDesktop;
  };

  const compareByTime = (a, b) => {
    let time1 = +calculateRemainedTime(a, "minute")[0];
    let time2 = +calculateRemainedTime(b, "minute")[0];
    return time1 - time2;
  };

  const compareByDate = (new1List: any[], new2List: any[], activity: "start" | "finish") => {
    const final1 = new1List.sort((a, b) => new Date(a[activity]).getTime() - new Date(b[activity]).getTime())[0];
    const final2 = new2List.sort((a, b) => new Date(a[activity]).getTime() - new Date(b[activity]).getTime())[0];
    return new Date(final1[activity]).getTime() - new Date(final2[activity]).getTime();
  };

  const compare = (node1, node2) => {
    let a = node1.stages;
    let b = node2.stages;

    let active1 = a.filter((s) => s.status === "STARTED");
    let active2 = b.filter((s) => s.status === "STARTED");
    if (active1.length || active2.length) {
      if (active1.length && active2.length) {
        const generation1 = active1.filter((stage) => stage.type === StageTypes.GENERATION);
        const generation2 = active2.filter((stage) => stage.type === StageTypes.GENERATION);

        if (generation1.length || generation2.length) {
          if (generation1.length && generation2.length) {
            return compareByTime(generation1[0], generation2[0]);
          }

          return generation2.length - generation1.length;
        }

        const voting1 = active1.filter((stage) => stage.type === StageTypes.VOTING);
        const voting2 = active2.filter((stage) => stage.type === StageTypes.VOTING);

        if (voting1.length || voting2.length) {
          if (voting1.length && voting2.length) {
            return compareByTime(voting1[0], voting2[0]);
          }

          return voting2.length - voting1.length;
        }

        const selection1 = active1.filter((stage) => stage.type === StageTypes.EXPERT_SELECTION);
        const selection2 = active2.filter((stage) => stage.type === StageTypes.EXPERT_SELECTION);

        if (selection1.length || selection2.length) {
          if (selection1.length && selection2.length) {
            return compareByTime(selection1[0], selection2[0]);
          }

          return selection2.length - selection1.length;
        }

        const discussion1 = active1.filter((stage) => stage.type === StageTypes.SIMPLE_DISCUSSION);
        const discussion2 = active2.filter((stage) => stage.type === StageTypes.SIMPLE_DISCUSSION);

        if (discussion1.length || discussion2.length) {
          if (discussion1.length && discussion2.length) {
            return compareByTime(discussion1[0], discussion2[0]);
          }

          return discussion2.length - discussion1.length;
        }
      }

      return active2.length - active1.length;
    }

    let new1 = a.filter((s) => s.status === "NEW");
    let new2 = b.filter((s) => s.status === "NEW");

    if (new1.length || new2.length) {
      if (new1.length && new2.length) {
        return compareByDate(new1, new2, "start");
      }

      return new2.length - new1.length;
    }

    let finished1 = a.filter((s) => s.status === "FINISHED");
    let finished2 = b.filter((s) => s.status === "FINISHED");

    if (finished1.length && finished2.length) {
      return compareByDate(finished1, finished2, "finish");
    }

    return 0;
  };

  const getFormatDate = (date) => date.format("DD.MM.YYYY");

  const getCurrentFormatDate = () => date.format("DD.MM.YYYY");

  const getProjectDuration = () => getDuration(props.start, props.finish);

  const getFirstDate = () => props.start;

  const getLastDate = () => props.finish;

  const getNodesContainerStyles = () => {
    return {
      width: getProjectDuration() * getSizeByDeviceType(SizeType.CELL_WIDTH),
    };
  };

  const checkAccsess = (stage) => {
    return props.accessibleIds.includes(stage?.id);
  };

  const filterStages = (stages) => {
    return stages.filter(
      (stage) =>
        checkAccsess(stage) ||
        ((!user.expert || !user.chiefExpert) &&
          stage.type === StageTypes.EXPERT_SELECTION &&
          checkAccsess(stages?.find((subStage) => subStage.type === StageTypes.GENERATION)))
    );
  };

  const getClassesForCell = (cell) => {
    let classList = "stage-list__cell";
    if (cell.fragments?.length) classList += " stage-list__cell--filled";
    return classList;
  };

  const isStageActiveOnDay = (stage, date) => {
    const t1 = dayjs(stage.start).startOf("day").valueOf() <= date.startOf("day").valueOf();
    const t2 = dayjs(stage.finish).startOf("day").valueOf() >= date.startOf("day").valueOf();
    return t1 && t2;
  };

  // RENDER

  const renderTimeline = () => {
    if (entities || mobileEntities) {
      return (
        <CalendarStripe
          type={""}
          start={props.start}
          finish={props.finish}
          currentDate={date}
          center={true}
          cellWidth={getSizeByDeviceType(SizeType.CELL_WIDTH)}
          cellPerView={cellPerView}
          onMoveLeft={(date, diff) => {
            setDate(date);
            swiper && forceSlide(swiper.realIndex - Math.abs(diff));
          }}
          onMoveRight={(date, diff) => {
            setDate(date);
            swiper && forceSlide(swiper.realIndex + Math.abs(diff));
          }}
          highlightActive={isPhone}
          underlineActive={isPhone}
          setTranslate={(trans) => {
            translateX.current = trans;
            moveTimeline();
          }}
        />
      );
    }
  };

  const renderCell = (cell) => {
    const { showNotCompleted = false } = props;

    return (
      <div
        className={getClassesForCell(cell)}
        style={{ width: getSizeByDeviceType(SizeType.CELL_WIDTH) + "px" }}
        data-date={cell.date.format("DD.MM.YYYY")}
        key={cell.id}
      >
        {cell.fragments
          .sort((a, b) => a.priority - b.priority)
          .map((fragment, index) => (
            <span
              key={fragment.title + index}
              onClick={() => props.setFragment(fragment)}
              className="stage-list__cell--color"
              style={{
                backgroundColor: checkShowStage(showNotCompleted, fragment.stage.stageActivity, fragment.stage.type)
                  ? fragment?.disableColor
                  : fragment.color,
                width: "100%",
              }}
            ></span>
          ))}
      </div>
    );
  };

  const renderFoldBtn = () => {
    return (
      <div className="stage-list__foldbtn">
        <Button
          type={"outlined"}
          size="m"
          onClick={() => {
            setFoldedOut(!foldedOut);
          }}
          icon={<span className={`ui-icon-chevron-${foldedOut ? "up" : "down"}`}></span>}
          text={foldedOut ? "Свернуть" : "Все темы"}
        />
      </div>
    );
  };

  const renderLegend = () => {
    return (
      <div className="stage-list__legend">
        {legendMap[props.type].items.map((item, index) => (
          <div className="legend-item" key={item.title + index}>
            <span className="legend-item__mark" style={{ backgroundColor: item.color }}></span>
            <span className="legend-item__title">{item.title}</span>
          </div>
        ))}

        {entities?.length > 5 && renderFoldBtn()}
      </div>
    );
  };

  const currentMobileEntity = () => {
    return mobileEntities.find((ent) => ent.date.format("DD.MM.YYYY") === date.format("DD.MM.YYYY"));
  };

  const entityToIndex = () => mobileEntities?.indexOf(currentMobileEntity());

  const indexToEntity = (index) => mobileEntities.indexOf(currentMobileEntity());

  const dayFromStart = () => {
    return date.add(1, "day").startOf("day").diff(props.start.startOf("day"), "day");
  };

  const forceSlide = (index) => {
    canSetDate = false;
    swiper?.slideTo(index);
  };

  const onSlideChange = (swiper) => {
    if (canSetDate) {
      const entity = swiper.swipeDirection === "next" ? mobileEntities[entityToIndex() + 1] : mobileEntities[entityToIndex() - 1];

      setDate(entity.date);
      canSetDate = false;
    }
  };

  const getDottIndex = () => {
    const actualIndex = entityToIndex();
    if (actualIndex || actualIndex === 0) {
      return Math.trunc(actualIndex / (mobileEntities.length / dotterSize));
    }
  };

  const handleDottClick = (index) => {
    if (index === 0) {
      setDate(getFirstDate());
      forceSlide(index);
      return;
    }

    if (index === dotterSize - 1) {
      setDate(getLastDate());
      forceSlide(mobileEntities.length - 1);
      return;
    }

    const idx = Math.ceil((mobileEntities.length / dotterSize) * index);
    setDate(mobileEntities[idx].date);
    forceSlide(idx);
  };

  const removeFinished = (stagelist: StageListMobileEntity, date) => {
    const isClosedNow = (stage) => dayjs(stage.finish).valueOf() <= dayjs().valueOf();

    stagelist.directions.forEach((direction) => {
      if (direction.stages.every(isClosedNow)) {
        direction.stages = [];
      }
    });

    return stagelist;
  };

  const renderList = () => {
    return (
      <React.Fragment>
        <div className="stage-list__header" ref={headerRef}>
          {props.title && (
            <React.Fragment>
              <div className="stage-list__info-title">{props.title}</div>
            </React.Fragment>
          )}
          {renderTimeline()}
        </div>

        {isPhone && (
          <React.Fragment>
            <div className="project-timing">
              <i className="ui-icon ui-icon-clock"></i>
              <span className="project-timing__day">{date && dayFromStart()}</span>
              -й день проекта
            </div>

            <div className="stage-mobile-cards">
              <Swiper autoHeight={true} spaceBetween={20} onSlideChange={onSlideChange} onInit={(swiper) => setSwiper(swiper)}>
                {date &&
                  mobileEntities?.map((ent: StageListMobileEntity) => (
                    <SwiperSlide key={ent.id} data-date={getFormatDate(ent.date)}>
                      {Boolean(ent.directions.length) ? (
                        <>
                          {ent.directions.map((direction: MobileDirection, id) => (
                            <>
                              {direction.stages.length ? (
                                <StageMobileCard
                                  key={id}
                                  type={direction.type}
                                  stages={direction.stages}
                                  showNotCompleted={props?.showNotCompleted}
                                  isPhone={isPhone}
                                  onStageClick={(stage) =>
                                    props.setFragment({
                                      title: stageTypesMap[stage.type.toLowerCase()].title,
                                      stage,
                                    })
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <div className="stage-mobile-cards__empty">Активных этапов нет</div>
                      )}
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>

            <Dotter size={dotterSize} activeIndex={getDottIndex()} onClick={handleDottClick} />
          </React.Fragment>
        )}

        {!isPhone && (
          <div className="stage-lines">
            {entities?.map((entity: StageListEntity, index) => (
              <div
                className="stage-line"
                key={entity.id}
                style={{
                  display: foldedOut ? "flex" : index > 4 ? "none" : "flex",
                }}
              >
                <div className="stage-line__title">
                  <button
                    className="stage-line__button simple-btn"
                    onClick={() => {
                      entity.forDiscussionStage
                        ? props.setFragment({
                            stage: entity.stages[0],
                            title: entity.stages[0].title,
                            titleOfRow: entity.row.title,
                          })
                        : setEntity(entity);
                    }}
                  >
                    <span>{entity.title}</span>
                  </button>
                </div>
                <div className="stage-line__blocks" ref={chartRef}>
                  <div className="stage-line__dragg" ref={(el) => (elementsRef.current[entity.id] = el)} style={getNodesContainerStyles()}>
                    <div className="stage-line__row">{entity.row.cells.map(renderCell)}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="stage-list">
      {props.nodes && (
        <>
          <div className="stage-list__foldable">{renderList()}</div>

          {!isPhone && renderLegend()}

          {entity && <NodeModal entity={entity} onClose={() => setEntity(null)} />}
        </>
      )}
    </div>
  );
};

export default React.memo(StageList);
