import React, { ReactNode } from "react";
import cn from "classnames";

import "./TableList.scss";

interface Column<T extends string> {
  header: ReactNode;
  accessor: T;
  renderValue?: (data: { row: DataRow<T>; value: any }) => ReactNode;
  className?: string;
}

type DataRow<T extends string> = {
  [K in T]: any;
};

export interface TableProps<T extends string> {
  columns: Column<T>[];
  data: DataRow<T>[];
  onRowClick?: (row: DataRow<T>) => void;
  classNames?: string;
  dataHeaderKey: string;
}

export function TableList<T extends string>({ columns, data, onRowClick, classNames, dataHeaderKey }: TableProps<T>) {
  return (
    <div className={cn("crowd-table-list", classNames)}>
      {data.map((item) => (
        <div className="crowd-table-list__row">
          <div className="crowd-table-list__header">{item[dataHeaderKey]}</div>
          <div className="crowd-table-list__columns">
            {columns.map((column) => (
              <div onClick={() => onRowClick?.(item)} className={cn("crowd-table-list__column", column.className)}>
                <div className="crowd-table-list__column-header">{column.header}</div>
                <div className="crowd-table-list__column-value">{item[column.accessor]}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
