import React from "react";
import PageTitle from "../../presentational/PageTitle/PageTitle";

import "./StageHeader.scss";

export interface Props {
  title: string;
  type: "GENERATION" | "DISCUSSION" | "VOTING";
  children?: React.ReactNode;
  description?: string;
  classNames?: string;
}

export const StageHeader = (props: Props) => {
  const titlePrefix = {
    GENERATION: "Предлагай",
    DISCUSSION: "Обсуждай",
    VOTING: "Оценивай",
  };

  return (
    <div className={`stage__header ${props.classNames || ""}`}>
      <PageTitle text={`${titlePrefix[props.type]}: ${props.title}`} />

      <div className="stage__header__stats">{props.children}</div>

      {props.description && (
        <div
          className="stage__header__description"
          dangerouslySetInnerHTML={{ __html: props.description }}
        ></div>
      )}
    </div>
  );
};

export default StageHeader;
