import UserService from "../../services/userService";
import { ApiResponse, ApiStatusCode } from "../../types/Common.interface";
import { User } from "../../types/User.interface";

export const GET_TOP_PARTICIPANTS = "GET_TOP_PARTICIPANTS";

export const getTopParticipants = (successCb?: (data: ApiResponse<User[]>) => any) => (dispatch) => {
    UserService.getTopParticipants().then((res) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ 
          type: "GET_TOP_PARTICIPANTS",
          payload: res.data
        });
        successCb && successCb(res);
      }
    });
  };