import { SizeType } from "./StageList.interface";

const stageTypesMap = {
  generation: {
    title: "Сбор идей участников",
    color: "#65AAE0",
    priority: 0,
    disableColor: "#d0e5f6",
    mobileTitle: "Предлагай",
    mobileColor: "#8CC6ED",
    mobilePriority: 0,
  },
  project_discussion: {
    title: "Обсуждай",
    color: "#36bb9b",
    priority: 4,
    disableColor: "#c2eae1",
    mobileTitle: "Обсуждай",
    mobileColor: "#EDD6C3",
    mobilePriority: 2,
  },
  expert_selection: {
    title: "Экспертный отбор идей",
    color: "#A69CEF",
    priority: 1,
    disableColor: "#e4e1fa",
    mobileTitle: "Экспертный отбор идей",
    mobileColor: "#A69CEF",
    mobilePriority: 3,
  },
  improvement: {
    title: "Выбирай",
    color: "#efb143",
    disableColor: "#fae7c6",
    priority: 4,
  },
  voting: {
    title: "Голосование за лучшие идеи",
    color: "#1473E6",
    priority: 2,
    disableColor: "#b8d5f7",
    mobileTitle: "Оценивай",
    mobileColor: "#1473E680",
    mobilePriority: 1,
  },
  simple_discussion: {
    title: "Тематическое обсуждение",
    color: "#FFC38D",
    priority: 3,
    disableColor: "#ffeddc",
    mobileTitle: "Обсуждай",
    mobileColor: "#EDD6C3",
    mobilePriority: 2,
  },
};

const legendMap = {
  propose: {
    items: [stageTypesMap.generation, stageTypesMap.expert_selection, stageTypesMap.voting],
  },
  discuss: {
    items: [stageTypesMap.simple_discussion],
  },
};

const sizesMap = {
  desktop: {
    [SizeType.CELL_WIDTH]: () => 60,
    [SizeType.CELL_MARGIN_TOP]: () => 2,
    [SizeType.CELL_MARGIN_BOT]: () => 12,
    maxFoldInRows: 5,
  },
  tablet: {
    [SizeType.CELL_WIDTH]: (maxWidth, documentWidth) => {
      return Math.min(55, 55 - (maxWidth - documentWidth) / 10);
    },
    [SizeType.CELL_MARGIN_TOP]: () => 2,
    [SizeType.CELL_MARGIN_BOT]: () => 8,
    maxFoldInRows: 5,
  },
  phone: {
    [SizeType.CELL_WIDTH]: () => 60,
    [SizeType.CELL_MARGIN_TOP]: () => 2,
    [SizeType.CELL_MARGIN_BOT]: () => 12,
    maxFoldInRows: 5,
  },
};

const cellWidth = 60;
const cellWidthTab = 55;
const cellWidthPhone = 55;

const stageMargin = 60;
const cellMarginTop = 2;
const cellMarginBot = 12;
const maxFoldInRows = 5;

const dotterSize = 7;

const tabletHeaderHeight = 106;
const tabletLineHeight = 110;
const tabletLineMargin = 12;

export {
  cellWidth,
  cellWidthTab,
  cellWidthPhone,
  legendMap,
  stageTypesMap,
  stageMargin,
  maxFoldInRows,
  cellMarginTop,
  cellMarginBot,
  sizesMap,
  dotterSize,
  tabletHeaderHeight,
  tabletLineHeight,
  tabletLineMargin,
};
