import { ChatMessageRaw } from "../../components/pages/Profile/Conversations/Components/Chat/chat.types";

export const ON_NEW_CHAT_MESSAGE = "ON_NEW_CHAT_MESSAGE";
export const ON_HISTORY_CHAT_MESSAGES = "ON_HISTORY_CHAT_MESSAGES";
export const ON_REMOVE_CHAT_MESSAGES = "ON_REMOVE_CHAT_MESSAGES";
export const ON_READ_CHAT_MESSAGES = "ON_READ_CHAT_MESSAGES";
export const ON_EMPTY_CHAT_CACHE = "ON_EMPTY_CHAT_CACHE";

interface NewMessagePayload {
  peerId: string;
  message: ChatMessageRaw;
  isMine: boolean;
}

interface HistoryMessagesPayload {
  mineMessages: {
    id: string;
    messages: ChatMessageRaw[];
  };
  peerMessages: {
    id: string;
    messages: ChatMessageRaw[];
  };
}

interface RemoveMessagePayload {
  id: string;
  peerId: string;
  deletionStatus: string;
  isMine: boolean;
}

interface ReadMessagePayload {
  ids: string[];
  peerId: string;
}

interface EmptyChatCachePayload {
  peerId: string;
}

export const onNewChatMessage = (payload: NewMessagePayload) => (dispatch) => {
  return dispatch({
    type: ON_NEW_CHAT_MESSAGE,
    payload,
  });
};

export const onHistoryChatMessages = (payload: HistoryMessagesPayload) => (dispatch) => {
  return dispatch({
    type: ON_HISTORY_CHAT_MESSAGES,
    payload,
  });
};

export const onRemoveChatMessage = (payload: RemoveMessagePayload) => (dispatch) => {
  return dispatch({
    type: ON_REMOVE_CHAT_MESSAGES,
    payload,
  });
};

export const onReadChatMessage = (payload: ReadMessagePayload) => (dispatch) => {
  return dispatch({
    type: ON_READ_CHAT_MESSAGES,
    payload,
  });
};

export const onEmptyChatCache = (payload: EmptyChatCachePayload) => (dispatch) => {
  return dispatch({
    type: ON_EMPTY_CHAT_CACHE,
    payload,
  });
};
