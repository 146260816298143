import React, { useEffect } from "react";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getProjectRules } from "../../../store/actions/ProjectActions";
import { RootState } from "../../../types/State.interface";
import "./Rules.scss";
import {
  setAppColor,
  showFooter,
  showHeader,
} from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import { getPlainFromHtml, unescapeHtml } from "../../../utils";
import CommentService from "../../../services/commentService";

const RulesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectRules());
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  return (
    <section className={"rules"}>
      <PageTitle text={"Правила публикации контента на платформе проекта"} />

      <RulesList />
    </section>
  );
};

const RulesList = () => {
  const rules = useSelector((state: RootState) => state.project.rules);
  return (
    <div
      className="rules__wrapper app__text"
      dangerouslySetInnerHTML={{ __html: rules }}
    />
  );
};

export default RulesPage;
