import React from "react";

import "./ProfilePic.scss";
import { getAvatarSrc } from "../../../../../utils";

interface ProfilePicProps {
  imageId: string;
  onClick: (evt: any) => any;
  showHoverEffect: boolean;
}

const ProfilePic = (props: ProfilePicProps) => {
  const onClick = (event) => {
    props.onClick(event);
  };

  return (
    <div className="profile-pic" onClick={onClick}>
      {props.imageId && (
        <img
          className="profile-pic__img"
          src={getAvatarSrc(props.imageId)}
          alt="Аватар пользователя"
        />
      )}

      {props.showHoverEffect && (
        <div className="profile-pic__overlay">
          <div className="profile-pic__overlay-img"></div>
          <div className="profile-pic__overlay-text">
            Изменить <br /> фото
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePic;
