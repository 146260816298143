import { Modal, Button } from "@crowd/ui-kit";
import { useState, Fragment, useEffect } from "react";
import { SelectionFolders } from "../../../../../utils/constants/proposal";
import { selectionIdeasSelector } from "../../../../../store/selectors/selection";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useSelectionParams } from "../../helpers/params";

// 20 минут на обработку идеи во входящих
const reservationPeriodSec = 1200;

export function SelectionReservationModal() {
  const { folder } = useSelectionParams();

  const ideas = useSelector(selectionIdeasSelector);

  const [reservationExpiredModalOpen, setReservationExpiredModalOpen] = useState(false);

  useEffect(() => {
    if (folder === SelectionFolders.INCOMING && ideas?.[0]?.reservation?.timestamp) {
      const reservationExpired = dayjs(ideas[0].reservation.timestamp).add(reservationPeriodSec, "second");
      const tid = setInterval(() => {
        if (dayjs().diff(reservationExpired) >= 0) {
          setReservationExpiredModalOpen(true);
        }
      }, 1000);

      return () => clearInterval(tid);
    }
  }, [ideas, folder]);

  const handleModalClose = () => {
    window.location.reload();
  };

  return (
    reservationExpiredModalOpen && (
      <Modal isOpen onClose={handleModalClose}>
        <Fragment key="body">Время рассмотрения идеи истекло. Пожалуйста, обновите страницу.</Fragment>
        <Fragment key="footer">
          <Button type="filled" text="Обновите страницу" onClick={() => window.location.reload()} />
        </Fragment>
      </Modal>
    )
  );
}
