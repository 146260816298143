import React, { useEffect, useMemo, useState } from "react";
import {
  AppEditor,
  Button,
  Checkbox,
  Input,
  FileSelector,
} from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import classNames from "classnames";

import { BlogModalProps } from "./BlogModal.interface";
import {
  defaultHeaderMaxLength,
  defaultHeaderMaxLengthTM,
  defaultPostMaxLength,
  defaultPostMaxLengthTM,
  maxFilesNumber,
  maxFileSizeMB,
} from "./BlogModal.constants";
import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";
import BlogService, {
  BlogParams,
  UpdateBlogPostParams,
} from "../../../../services/blogService";
import { RootState } from "../../../../types/State.interface";
import { updateBlogPost } from "../../../../store/actions/BlogActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import PreviewModal from "../PreviewModal/PreviewModal";
import { Adapter } from "../../../../services/adapter";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import "./BlogModal.scss";
import ProfileBlogCard from "../../../presentational/Cards/ProfileBlogCard/ProfileBlogCard";
import allowedExtensions from "../../../../utils/constants/allowedExtensions";
import { showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import CommentService from "../../../../services/commentService";

const BlogModal = ({
  onSuccess,
  onClose,
  post,
  fromStage,
  fromDraft,
}: BlogModalProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const headerMaxLength =
    user.teamMember || user.expert || user.chiefExpert
      ? defaultHeaderMaxLengthTM
      : defaultHeaderMaxLength;
  const postMaxLength =
    user.teamMember || user.expert || user.chiefExpert
      ? defaultPostMaxLengthTM
      : defaultPostMaxLength;

  const enableDrafts = !fromStage;
  const showAllowCommentsFlag = !fromStage;

  const [titleValidator, setTitleValidator] = useState(null);
  const [mineBlog, setMineBlog] = useState(null);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [files, setFiles] = useState<any[]>([]);
  const [canComment, setCanComment] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isEditMode] = useState<boolean>(!!post && !fromDraft);
  const [inPreviewMode, setInPreviewMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isEditMode || fromDraft) {
      setTitle(post.title);
      setFiles(post.attachments);
      showAllowCommentsFlag && setCanComment(post.commentsEnabled);
    }

    BlogService.getMine()
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          setMineBlog(response.data);
          return;
        }

        dispatch(showErrorToast(response.message));
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  }, []);

  const update = () => {
    const formData: UpdateBlogPostParams = {
      postId: post.id,
      attachments: [...files.map((f) => f.id)],
      blogId: post.blogId,
      content: CommentService.CkHtmlToBBCode(text),
      date: null,
      title,
    };

    if (showAllowCommentsFlag) formData.commentsEnabled = canComment;
    if (enableDrafts) formData.draft = post.draft;

    dispatch(
      updateBlogPost(formData, () => {
        onSuccess();
      })
    );
  };

  const submit = (toDraft = false) => {
    if (!toDraft) {
      setSubmitted(true);
      if (!isValid()) return;
    }

    if (isEditMode || (toDraft && post?.draft)) return update();

    const formData: BlogParams = {
      attachments: [...files.map((f) => f.id)],
      blogId: mineBlog.id,
      commentsEnabled: canComment,
      content: CommentService.CkHtmlToBBCode(text),
      date: null,
      draft: toDraft,
      title,
    };

    setIsSubmitting(true);
    BlogService.add(formData)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          const msg = toDraft
            ? "Черновик успешно сохранен."
            : "Пост успешно опубликован.";
          dispatch(showSuccessToast(msg));
          onSuccess(!toDraft && post?.draft ? post : null);
          setSubmitted(false);
          return;
        }

        dispatch(showErrorToast(response.message));
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFilesUploadingChange = (uploading) => {
    setIsUploading(uploading)
  }

  const getBlogPreviewData = () => {
    const partials = {
      title,
      text,
      author: user,
      attachments: files,
      createdTimestamp: dayjs().format("DD.MM.YYYY HH:mm"),
    };
    return Adapter.toPost(partials);
  };

  const textareaOnChange = (value) => {
    setText(value);
  };

  const isValid = () => {
    return titleValidator?.isValid();
  };

  return (
    <SubmitForm
      classNames="blog-modal"
      submitText={isEditMode ? "Сохранить" : "Опубликовать"}
      isOpen={true}
      onClose={onClose}
      isSubmitting={isSubmitting}
      onSubmit={() => submit()}
    >
      <React.Fragment key="header">
        {isEditMode
          ? fromStage
            ? "Редактирование поста"
            : "Редактировать запись в личном блоге"
          : "Запись в личном блоге"}
      </React.Fragment>

      <React.Fragment key="body">
        <Input
          value={title}
          onChange={setTitle}
          placeholder={"Заголовок"}
          isRequired={true}
          maxLength={headerMaxLength}
          hint={`Не более ${headerMaxLength} символов`}
          shouldValidate={true}
          validateRules={{
            notEmpty: true,
            minLength: 10,
            maxLength: headerMaxLength,
          }}
          onInitValidator={(validator) => setTitleValidator(validator)}
          showError={submitted}
        />

        <AppEditor
          defaultText={
            post ? CommentService.wrapParagraph(CommentService.CkBBCodeToHtml(post.rawContent)) : ""
          }
          text={text}
          onChange={textareaOnChange}
          validateRules={{
            maxLength: postMaxLength,
          }}
          editorSittings={{
            placeholder: "Введите текст",
          }}
          hint={`Не более ${postMaxLength} символов`}
          showError={submitted}
          excludeTools={!user.teamMember && ["iframe", "html"]}
        />

        <FileSelector
          defaultFiles={post?.attachments || []}
          text={
            <span className={classNames("reply-modal__file-selector", { "disabled-btn": isUploading })}>
              <i className={classNames("ui-icon ui-icon-attach", { "disabled-btn": isUploading })} />
              Прикрепить файл
            </span>
          }
          upload={true}
          maxFiles={maxFilesNumber}
          maxFileSizeMB={maxFileSizeMB}
          allowedExtensions={allowedExtensions}
          showInfoModal={(msg) => {
            dispatch(showErrorInfoModal(msg));
          }}
          isMultiple={true}
          onChange={(files) => {
            setFiles(files);
          }}
          attachmentsLength={files.length}
          onUploadingChange={handleFilesUploadingChange}
        />

        {showAllowCommentsFlag && (
          <Checkbox
            defaultValue={canComment}
            onChange={setCanComment}
            html="Разрешить комментирование"
          />
        )}
      </React.Fragment>

      <React.Fragment key="footer">
        <Button
          type={"outlined-grey"}
          text="Отменить"
          size="m"
          onClick={onClose}
        />

        {inPreviewMode && (
          <PreviewModal onClose={() => setInPreviewMode(false)}>
            <ProfileBlogCard
              post={getBlogPreviewData()}
              backgroundColor={"#F2F4F7"}
            />
          </PreviewModal>
        )}

        {!fromStage && !isEditMode && (
          <>
            <Button
              type={"outlined"}
              text="Предпросмотр"
              size="m"
              onClick={() => setInPreviewMode(true)}
            />
            <Button
              type={"outlined"}
              text="Сохранить черновик"
              size="m"
              onClick={() => submit(true)}
            />
          </>
        )}
      </React.Fragment>
    </SubmitForm>
  );
};

export default BlogModal;
