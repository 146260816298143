import { Modal } from "@crowd/ui-kit";
import { FC, Fragment } from "react";
import SelectionIdeaInfo from "../SelectionIdeaInfo/SelectionIdeaInfo";
import { SelectionIdeaModalProps } from "./SelectionIdeaModal.interface";

const SelectionIdeaModal: FC<SelectionIdeaModalProps> = ({ idea, isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Fragment key="body">
      <SelectionIdeaInfo idea={idea} />
    </Fragment>
  </Modal>
);

export default SelectionIdeaModal;
