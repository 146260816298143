import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@crowd/ui-kit";
import GenerationFormContainer from "../GenerationContainer/GenerationFormContainer";
import { Stage } from "../../../../types/Stage.interface";
import "./GenerationModal.scss";

export interface GenerationModalProps {
  stage: Stage;
  open: boolean;
  showModalDescription?: boolean;
  onStageSelect: (stage: Stage) => void;
  onFormClose?: () => void;
  onStageModalClose?: () => void;
  onClose: () => void;
}

const GenerationModal = ({ stage, onStageSelect, open, onFormClose, onStageModalClose, onClose }: GenerationModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOnStageModalOpen = () => {
    const parentElement = modalRef.current.parentElement;
    const siblingElement = parentElement.previousSibling;
    parentElement.style.display = "none";
    if (siblingElement instanceof HTMLElement) {
      siblingElement.style.display = "none";
    }
  };

  return (
    <Modal
      classNames="generation-modal"
      isOpen={open}
      onClose={() => onClose()}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
      ref={modalRef}
    >
      <React.Fragment key="header">
        <div className="generation-modal__title">Предложить идею</div>
        <div id="generation-modal__icon"></div>
      </React.Fragment>

      <React.Fragment key="body">
        {stage && (
          <GenerationFormContainer
            stage={stage}
            showFormDescription={true}
            showTaskDescription={false}
            showFullScreenButton={true}
            showDraftButton={false}
            onStageSelect={onStageSelect}
            onFormClose={onFormClose}
            onStageModalOpen={handleOnStageModalOpen}
            onStageModalClose={onStageModalClose}
          />
        )}
      </React.Fragment>
    </Modal>
  );
};

export default GenerationModal;
