import { GET_BAD_WORDS } from "../actions/BadWords";

export interface BadWordsState {
  list: any;
}

const initialState: BadWordsState = {
  list: null,
};

export function badWordsReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_BAD_WORDS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
}
