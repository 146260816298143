import React, { useEffect, useState, useRef } from 'react';
import { Button, Select } from '@crowd/ui-kit';
import "./VotingFilter.scss";

interface VotingFilterProps {
  title: string;
  items: string[];
  placeholder?: string;
  onFilterApply?: (value: string) => void;
  onFilterClear?: () => void;
  isFiltered?: boolean;
  setFilterItemValue?: (string) => void;
  filterItemValue?: (string);
}

export const VotingFilter: React.FC<VotingFilterProps> = ({
  title,
  items,
  placeholder,
  onFilterApply,
  onFilterClear,
  isFiltered,
  filterItemValue,
}) => {
  

  const [selectedData, setSelectedData] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectionChanged, setSelectionChanged] = useState(false);

  useEffect(() => {
    setSelectedData(filterItemValue || '');
    setSelectionChanged(false);
  }, [filterItemValue])

  useEffect(() => {
    setIsButtonDisabled(!selectedData || (isFiltered && !selectionChanged));
  }, [selectedData, isFiltered, selectionChanged])

  const handleResetClick = () => {
    setSelectedData('');
    onFilterClear && onFilterClear();
    setSelectionChanged(false);
  }

  const handleSelect = (item) => {
    setSelectedData(item)
    setSelectionChanged(true);
  }

  return (
    <div className='voting-filter__container'>
      <Select
        classNames='filter__select'
        label={title}
        items={items}
        placeholder={placeholder}
        closeOnSelect={true}
        onItemSelect={(item) => handleSelect(item)}
        value={selectedData}
      />
      <div className='buttons__container'>
        <Button
          classNames={`filter__button--apply ${isButtonDisabled ? 'inactive' : ''}`}
          type={'filled'}
          text={"Применить фильтр"}
          onClick={() => onFilterApply && onFilterApply(selectedData)}
          size={'m'}
          isDisabled={isButtonDisabled}
        />
        {selectedData && (
          <Button
            classNames='filter__button--reset'
            type={'rounded-outlined'}
            text='Очистить фильтр'
            onClick={handleResetClick}
            size={'m'}
          />
        )}
      </div>
    </div>
  )
};
