import DiscussionService, {
  DiscussionByTypeParams,
} from "../../services/discussionService";
import { Discussion } from "../../types/Discussion.interface";
import { AxiosResponse } from "axios";

export const GET_DISCUSSION_BY_TYPE = "GET_DISCUSSION_BY_TYPE";

export const getDiscussionByType =
  (params: DiscussionByTypeParams) => (dispatch) => {
    return DiscussionService.getDiscussionByType(params)
      .then((data: AxiosResponse<Discussion[]>) => {
        const result = { [params.type as string]: data?.data };
        dispatch({
          type: GET_DISCUSSION_BY_TYPE,
          payload: result,
        });
      })
      .catch((err) => console.log(err));
  };
