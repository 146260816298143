import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import BlogService from "../../../../services/blogService";
import {
  hideInfoModal,
  showConfirmInfoModal,
} from "../../../../store/actions/LayoutActions";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { mapStageToName, mapStageToTitle } from "../ActualPage.constants";
import { User } from "../../../../types/User.interface";
import "./ActualPost.scss";

interface ActualPostProps {
  post: any;
  stages: any[];
  onRemove: () => void;
  children?: React.ReactNode;
  user: User;
  onEdit: (post: any) => void;
}

const ActualPost = ({
  children,
  post,
  onRemove,
  onEdit,
  stages,
  user,
}: ActualPostProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getStageLink = (stage) => {
    return `/${mapStageToName[stage.type]}/${stage.id}`;
  };

  const remove = () => {
    setLoading(true);
    BlogService.deleteBlogPost(post.id)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          dispatch(showSuccessToast("Пост успешно удален"));
          return onRemove();
        }

        dispatch(showErrorToast);
      })
      .catch(() => {
        dispatch(showErrorToast);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hasStages = () => {
    return Object.values(stages).some((s: any) => s.items.length);
  };

  // RENDER

  const renderActiveDirections = () => {
    return (
      post.properties?.showActiveDirection &&
      hasStages() && (
        <div className="actual-post__stages">
          {Object.entries(stages).map((entry: any, idx) => {
            if (!entry[1].items.length)
              return <React.Fragment key={idx}></React.Fragment>;

            return (
              <div className="actual-post__stage" key={entry[0] + idx}>
                <h5 className="actual-post__stage-title">
                  {mapStageToTitle(entry[1].items.length > 1)[entry[0]]}
                </h5>
                <ol className="actual-post__stage-list">
                  {entry[1].items.map((i) => (
                    <li key={i.id}>
                      <Link to={getStageLink(i)}>{i.title}</Link>
                    </li>
                  ))}
                </ol>
              </div>
            );
          })}
        </div>
      )
    );
  };

  const renderFooter = () => {
    const onRemove = (): void => {
      dispatch(
        showConfirmInfoModal(
          "Вы действительно хотите удалить пост?",
          () => {
            remove();
            dispatch(hideInfoModal());
          },
          () => dispatch(hideInfoModal()),
          loading
        )
      );
    };

    return (
      <div className="actual-post__footer">
        <div className="actual-post__footer__controls">
          {user.teamMember && (
            <>
              <button className="simple-btn" onClick={() => onEdit(post)}>
                <span className="ui-icon-edit"></span>
              </button>
              <button className="simple-btn" onClick={onRemove}>
                <span className="ui-icon-delete"></span>
              </button>
            </>
          )}
        </div>
        {children} {/* Date slider */}
      </div>
    );
  };

  const renderPost = () => {
    if (!post) return;

    return (
      <div className={`actual-post ${children && "actual-post--slotted"}`}>
        <div className={"actual-post__wrapper"}>
          <h3>{post.title}</h3>
          <em className="actual-post__time">
            {dayjs(post.createdTimestamp).format("DD.MM.YYYY HH:mm")}
          </em>
          <div
            className="actual-post__text"
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          ></div>

          {renderActiveDirections()}
          {renderFooter()}
        </div>
      </div>
    );
  };

  return <>{renderPost()}</>;
};

export default ActualPost;
