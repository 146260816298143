import { ProposalInterface } from "../../../../../types/Proposal.interface";
import { declOfNum } from "../../../../../utils";
import { SelectionFolders } from "../../../../../utils/constants/proposal";
import { genderizeWord } from "../../../../../utils/user.utils";
import UserInfo from "../../../../presentational/UserInfo/UserInfo";
import { EIdeaProcessType } from "../../constants/constants";
import SelectionAcceptForm from "../SelectionAcceptForm/SelectionAcceptForm";
import SelectionForwardForm from "../SelectionForwardForm/SelectionForwardForm";
import { SelectionIdeaActions } from "../SelectionIdeaActions/SelectionIdeaActions";
import SelectionIdeaInfo from "../SelectionIdeaInfo/SelectionIdeaInfo";
import SelectionMoveForm from "../SelectionMoveForm/SelectionMoveForm";
import { useIsMobile, useIsShortDesktop, useIsTablet } from "../../../../../utils/hooks/useMedia";
import "./SelectionIdea.scss";
import { SelectionDeclineProposalForm } from "../SelectionDeclineProposalForm/SelectionDeclineProposalForm";
import {
  selectionIdeaProcessIdSelector,
  selectionIdeaProcessTypeSelector,
  selectionIdeasExpertsSelector,
} from "../../../../../store/selectors/selection";
import { useDispatch, useSelector } from "react-redux";
import { profileUserDetailsSelector } from "../../../../../store/selectors/profile";
import {
  selectionGetCounts,
  selectionGetIdeas,
  selectionGetTags,
  SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY,
  selectionSetIdeaProcess,
} from "../../../../../store/actions/SelectionActions";
import { SelectionIdeaRejectComment } from "./SelectionIdeaRejectComment";
import { SelectionIdeaForwardComment } from "./SelectionIdeaForwardComment";
import { useSelectionParams } from "../../helpers/params";
import cn from "classnames";

interface TProps {
  idea: ProposalInterface;
}

export function SelectionIdea({ idea }: TProps) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isShortDesktop = useIsShortDesktop();

  const { stageId, folder } = useSelectionParams();

  const dispatch = useDispatch();

  const ideasExperts = useSelector(selectionIdeasExpertsSelector);
  const { chiefExpert } = useSelector(profileUserDetailsSelector);
  const ideaProcessId = useSelector(selectionIdeaProcessIdSelector);
  const ideaProcessType = useSelector(selectionIdeaProcessTypeSelector);

  const setIdeaShowTransferHistory = (idea: ProposalInterface) =>
    dispatch({ type: SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY, payload: idea });

  const handleIdeaAccept = () => {
    dispatch(selectionGetCounts(stageId));
    dispatch(
      selectionGetIdeas({
        stageId,
        folder,
      })
    );
    dispatch(selectionGetTags(stageId));
  };

  const handleFormClose = () => {
    dispatch(selectionSetIdeaProcess(undefined));
  };

  const expert = ideasExperts[idea.id] ? (
    <UserInfo
      user={ideasExperts[idea.id]}
      addInfoType="role"
      iconButton={{
        icon: "ui-icon ui-icon-ic-restore-24px selection-page__idea-user-info-icon",
        callback: () => {
          setIdeaShowTransferHistory(idea);
        },
        tooltip: {
          text: "История перемещения идеи",
        },
      }}
      size={isTablet || isMobile ? "XS" : "M"}
    />
  ) : null;

  return (
    <div key={idea.id} className="selection-page__idea selection-idea selection-page__block-indent">
      <UserInfo
        user={idea.author}
        addInfoType="last-visit"
        addNameText={`${genderizeWord("предложил", idea.author.gender)} ${idea.author.submittedProposals || 0} ${declOfNum(
          idea.author.submittedProposals || 0,
          ["идею", "идеи", "идей"]
        )}`}
      />

      <div className="selection-page__idea__info">
        {!isShortDesktop && !isMobile && idea.main && <i className="ic ui-icon-Component4073 selection-page__idea__info__main-mark" />}
        <SelectionIdeaInfo
          idea={idea}
          showHistory
          canEditTag={!idea.main && chiefExpert}
          showEditTag={ideaProcessId === idea.id && ideaProcessType === EIdeaProcessType.TAG_EDITING}
          showIsMain={isShortDesktop || isMobile}
        />
      </div>

      <div
        className={cn("selection-page__idea__control selection-page__block-indent", {
          "selection-page__idea__control--short-margin": idea.tag,
        })}
      >
        <SelectionIdeaActions idea={idea} />
        {!isMobile && expert}
      </div>

      {isMobile && (
        <div
          className={cn("selection-page__block-indent selection-idea__expert", {
            "selection-idea__expert--small-margin": ideaProcessId === idea.id,
          })}
        >
          {expert}
        </div>
      )}

      {ideaProcessId === idea.id && (
        <>
          {ideaProcessType === EIdeaProcessType.ACCEPTING && (
            <SelectionAcceptForm stageId={stageId} idea={idea} onSuccess={handleIdeaAccept} onClose={handleFormClose} />
          )}
          {ideaProcessType === EIdeaProcessType.DECLINING && <SelectionDeclineProposalForm proposal={idea} onClose={handleFormClose} />}
          {ideaProcessType === EIdeaProcessType.FORWARDING && <SelectionForwardForm idea={idea} onClose={handleFormClose} />}
          {ideaProcessType === EIdeaProcessType.MOVING && <SelectionMoveForm idea={idea} onClose={handleFormClose} />}
        </>
      )}

      {folder === SelectionFolders.REJECTED && idea.id !== ideaProcessId && <SelectionIdeaRejectComment idea={idea} />}
      {folder === SelectionFolders.FORWARDED && idea.id !== ideaProcessId && <SelectionIdeaForwardComment idea={idea} />}
    </div>
  );
}
