import { User } from "../../types/User.interface";
import { GET_TOP_PARTICIPANTS } from "../actions/ParticipantsActions";

export interface ParticipantsState {
  topParticipants: {
    data: User[];
    isLoading: boolean;
  };
}

const initialState: ParticipantsState = {
  topParticipants: {
    data: null,
    isLoading: false,
  },
};

export function participantsReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_TOP_PARTICIPANTS:
      return { ...state, topParticipants: { ...state.topParticipants, data: action.payload } };

    default:
      return state;
  }
}
