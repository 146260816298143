import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PersonalService from "../../../../services/personalService";
import { moduleCards } from "../Profile.constants";
import { ModuleTypeEnum } from "../Profile.interface";
import ProfileSubContent from "../ProfileSubContent/ProfileSubContent";
import "./MyFavorite.scss";

import { showErrorToast } from "../../../../store/actions/ToastActions";
import { getUrlParam } from "../../../../utils/urlUtils";
import ProfileFavoriteCard from "../../../presentational/Cards/ProfileFavoriteCard/ProfileFavoriteCard";
import { mapTypeToEmptyText } from "./MyFavorite.constants";
import { getProfileTab } from "../Profile.helper";
import useQuery from "../../../../utils/hooks/useQuery";

const MyFavorite = () => {
  const dispatch = useDispatch();
  const { type } = useQuery();

  const [tab, setTab] = useState(getProfileTab(type, getTabs()));
  const [page, setPage] = useState(getPageFromUrl("page"));
  const [blogs, setBlogs] = useState(null);
  const [paging, setPaging] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPosts();
  }, [tab.type, page]);

  // RENDER

  const loadPosts = () => {
    let query: any = {
      page,
      size: 10,
      type: tab.type,
    };

    setLoading(true);
    PersonalService.getFavoritePosts(query)
      .then((response) => {
        setBlogs(response.data);
        setPaging(response.paging);
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => setLoading(false));
  };

  function getTabs() {
    return moduleCards.find((m) => m.type === ModuleTypeEnum.FAVORITES).tabs;
  }

  function getPageFromUrl(pageName) {
    return Math.max(
      Number(getUrlParam(pageName, window.location.search)) - 1,
      0
    );
  }

  /////////

  const renderNoNotifications = () => {
    return <div className="empty-content">{mapTypeToEmptyText(tab.type)}</div>;
  };

  return (
    <div className="my-favorite">
      <ProfileSubContent
        title="Избранные посты"
        tabs={getTabs()}
        onTabChange={(tab) => {
          setTab(tab);
          setPage(0);
        }}
        onPageChange={(page) => setPage(page)}
        paging={paging}
        isLoading={loading}
      >
        <div className="my-favorite__content">
          {blogs?.length
            ? blogs?.map((blog) => (
                <div>
                  {/* <CommentCard
                    blogPost={blog}
                    showLabel={true}
                    showUserAvatar={false}
                  /> */}
                  <ProfileFavoriteCard
                    post={blog}
                    onChange={() => {
                      loadPosts();
                    }}
                  />
                </div>
              ))
            : renderNoNotifications()}
        </div>
      </ProfileSubContent>
    </div>
  );
};

export default MyFavorite;
