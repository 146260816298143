import { ProjectState } from "../../types/Projects.interface";
import {
  GET_CURRENT_PROJECT,
  GET_PROJECT_RULES,
  GET_PROJECT_STATISTIC,
  GET_PROJECT_STATUS,
  GET_PROJECT_STRUCTURE,
} from "../actions/ProjectActions";

const initialState: ProjectState = {
  status: null,
  current: null,
  projectLoadedAtLeastOnce: false,
  structure: null,
  statistic: null,
  rules: "",
};

export function projectReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_CURRENT_PROJECT:
      return { ...state, current: action.payload, projectLoadedAtLeastOnce: true } as ProjectState;
    case GET_PROJECT_STATISTIC:
      return { ...state, statistic: action.payload };
    case GET_PROJECT_STRUCTURE:
      return { ...state, structure: action.payload };
    case GET_PROJECT_RULES:
      return { ...state, rules: action.payload };
    case GET_PROJECT_STATUS:
        return { ...state, status: action.payload };
    default:
      return state;
  }
}
