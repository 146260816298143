import dayjs from "dayjs";
import { StageTypes } from "../../../../types/Stage.interface";

export function timeToPercentage(timestamp) {
  const t = dayjs(timestamp);
  const secondsInADay = 24 * 60 * 60;
  const hours = t.hour() * 60 * 60;
  const minutes = t.minute() * 60;
  const seconds = t.second();
  const totalSeconds = hours + minutes + seconds;

  return (100 * totalSeconds) / secondsInADay;
}

export const checkShowStage = (showNotCompleted, stageActivity, type) => {
  return (showNotCompleted && stageActivity) || (showNotCompleted && type === StageTypes.EXPERT_SELECTION);
};
