import { Children, cloneElement, ReactElement } from "react";
import { EventsTrackConfig, EventsTrackId, EventsTrackParams } from "../../types/EventsTrack.interface";
import eventsTrackConfigParams from "./eventsTrackConfigParams";
import { sendStats } from "./stats";

const getTrackAttributes = (
  id: EventsTrackId | (EventsTrackId | EventsTrackId[])[],
  type: "click" | "scroll" | "swipe" = "click",
  params?: EventsTrackParams
) => {
  const ids = (Array.isArray(id) ? id : [id]).flat().filter(Boolean);
  if (!ids.length) {
    return null;
  }
  const addProps: { [K: string]: any } = {
    ["data-track-id"]: JSON.stringify(ids),
    ["data-track-type"]: type,
  };
  if (params?.replace?.length) {
    addProps["data-track-replace"] = JSON.stringify(params.replace);
  }
  if (params?.partial) {
    addProps["data-track-partial"] = true;
  }
  if (params?.remember) {
    addProps["data-track-remember"] = true;
  }
  if (params?.rememberPrev) {
    addProps["data-track-remember-prev"] = params.rememberPrev;
  }
  return addProps;
};

type Params = Parameters<typeof getTrackAttributes>;

export const renderWithTrackId = (children: ReactElement, id: Params[0], type: Params[1], params?: EventsTrackParams): ReactElement => {
  const child = Children.only(children);

  const addProps = getTrackAttributes(id, type, params);
  if (!addProps) {
    return children;
  }

  return cloneElement(child, addProps);
};

export const setTrackIdToEl = (el: HTMLElement, id: Params[0], type: Params[1], params?: EventsTrackParams) => {
  if (el) {
    const addProps = getTrackAttributes(id, type, params);
    if (addProps) {
      Object.entries(addProps).forEach(([k, v]) => {
        el.setAttribute(k, v);
      });
    }
  }
};

// @TODO art
export const serviceName = "crowd";

export const eventsTrackConfig: EventsTrackConfig = {
  params: eventsTrackConfigParams,
  callback: (data) => {
    const dataSend = {
      [serviceName]: data,
    };
    sendStats(dataSend);
  },
};
