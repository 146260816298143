import React, { useContext, useEffect } from "react";
import {  useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../Root";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";

import { RootState } from "../../../../types/State.interface";
import ExpertPostCard from "../../../presentational/Cards/ExpertPostCard/ExpertPostCard";
import { ExpertCardListProps } from "./ExpertCardList.interface";
import "./ExpertCardList.scss";

export default function ExpertCardList(props: ExpertCardListProps) {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    const actions = appContext.sudirService.getActions(
      ComponentType.ExpertCardList
    );
    if (actions.length) {
      const redirectAction = actions.find(
        (act) => act.type === AuthActionType.Redirect
      );
      if (redirectAction) {
        history.push(redirectAction.args.redirectUrl, {
          openCommentForm: true,
        });
      }
    }
  }, []);

  const onWriteComment = (post) => {
    const redirectUrl = `/users/user/post/${post.author.id}/${post.id}`;

    if (!user.loggedIn) {
      let containerData: AuthActionParam = {
        component: ComponentType.ExpertCardList,
        type: AuthActionType.Redirect,
        args: {
          redirectUrl,
        },
      };

      const action = new AuthAction(containerData);
      return appContext.sudirService.authWithActions([action]);
    }

    history.push(redirectUrl, { openCommentForm: true });
  };

  return (
    <div className="expert-card-list">
      {props.posts.map((post, idx) => {
        return (
          <ExpertPostCard
            key={idx}
            post={post}
            onWriteComment={(post) => onWriteComment(post)}
          />
        );
      })}
    </div>
  );
}
