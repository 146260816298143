import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Checkbox } from "@crowd/ui-kit";
import { clone, formatProjectDates } from "../../../utils";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import { RootState } from "../../../types/State.interface";
import "./Timetable.scss";
import { setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import StageList from "./StageList/StageList";
import { getProjectStructure, setProjectStructure } from "../../../store/actions/ProjectActions";
import { getAccessibleIds } from "../../../store/actions/StageActions";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import TimetableLoader from "../../presentational/Loaders/TimetableLoader/TimetableLoader";
import { StageTypes } from "../../../types/Stage.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../utils/constants/widthConstants";
import FragmentModal from "./FragmentModal/FragmentModal";
import Switcher from "../../presentational/Controls/Switcher/Switcher";

const TimetablePage = () => {
  const isPhone: boolean = useMediaQuery({
    query: `(max-width: ${phoneWidth}px)`,
  });

  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.current);
  const projectStructure = useSelector((state: RootState) => state.project.structure);
  const accessibleIds = useSelector((state: RootState) => state.stage.accessibleIds);
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [fragment, setFragment] = useState<any | null>(null);
  const [isShowStages, setIsShowStages] = useState(false);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());
    dispatch(getProjectStructure());
    dispatch(getAccessibleIds());
    return () => {
      dispatch(setProjectStructure(null));
    };
  }, []);

  const getProposeNodes = () => {
    const propose = getNestedNodes(projectStructure, null, StageTypes.SIMPLE_DISCUSSION);
    return propose;
  };

  const getDiscussionNodes = () => {
    const virtualNodes = [];
    const stages = getDiscussionStages(projectStructure);

    stages.forEach((stage) => {
      virtualNodes.push({
        id: stage.id,
        title: stage.title,
        description: stage.description,
        stages: [stage],
        forDiscussionStage: true,
      });
    });

    return virtualNodes;
  };

  function getNestedNodes(node, type?, exclude?) {
    const nodes = [];

    function helper(parent) {
      if (!parent.nodes) return;

      parent.nodes.forEach((node) => {
        if (!node.stages) return;

        let _s = node.stages;

        if (type) {
          _s = _s.filter((stage) => stage.type === type);
        }

        if (exclude) {
          _s = _s.filter((stage) => stage.type !== exclude);
        }

        if (_s.length) {
          const copy = clone(node);
          copy.stages = _s;
          nodes.push(copy);
        }

        helper(node);
      });
    }

    helper(node);

    return nodes;
  }

  function getDiscussionStages(node) {
    const stages = [];

    function helper(parent) {
      if (!parent.nodes) return;

      parent.stages
        .filter((stage) => stage.type === StageTypes.SIMPLE_DISCUSSION)
        .forEach((stage) => {
          stages.push(clone(stage));
        });

      parent.nodes.forEach((node) => {
        if (!node.stages) return;

        node.stages
          .filter((stage) => stage.type === StageTypes.SIMPLE_DISCUSSION)
          .forEach((stage) => {
            stages.push(clone(stage));
          });

        helper(node);
      });
    }

    helper(node);

    return stages;
  }

  const loaded = () => {
    return projectStructure && accessibleIds;
  };

  const renderHead = () => {
    return (
      <>
        <PageTitle text="График проекта" />
        <div className="timetable-page__date">{formatProjectDates(project?.start, project?.finish)}</div>

        {(loaded() && (
          <FadeIn>
            {user?.participant && !user?.teamMember && (
              <div className="timetable-page__switch">
                <Switcher defaultValue={isShowStages} onChange={(checked) => setIsShowStages(checked)} html="Показать непройденные этапы" />
              </div>
            )}

            {isPhone ? (
              <StageList
                key="3"
                accessibleIds={accessibleIds}
                create={{
                  from: dayjs(project.start).subtract(1, "week"),
                  to: dayjs(project.finish).add(1, "week"),
                }}
                start={dayjs(project?.start)}
                finish={dayjs(project?.finish)}
                nodes={[...getProposeNodes(), ...getDiscussionNodes()]}
                setFragment={setFragment}
                showNotCompleted={isShowStages}
              />
            ) : (
              <>
                <StageList
                  key="1"
                  title="Предлагай идеи и выбирай лучшие"
                  type="propose"
                  accessibleIds={accessibleIds}
                  create={{
                    from: dayjs(project.start).subtract(1, "week"),
                    to: dayjs(project.finish).add(1, "week"),
                  }}
                  start={dayjs(project?.start)}
                  finish={dayjs(project?.finish)}
                  nodes={getProposeNodes()}
                  setFragment={setFragment}
                  showNotCompleted={isShowStages}
                />

                <div className="divider"></div>

                <StageList
                  key="2"
                  title="Обсуждай с другими участниками"
                  type="discuss"
                  accessibleIds={accessibleIds}
                  create={{
                    from: dayjs(project.start).subtract(1, "week"),
                    to: dayjs(project.finish).add(1, "week"),
                  }}
                  start={dayjs(project?.start)}
                  finish={dayjs(project?.finish)}
                  nodes={getDiscussionNodes()}
                  setFragment={setFragment}
                  showNotCompleted={isShowStages}
                />
              </>
            )}

            {fragment && <FragmentModal fragment={fragment} onClose={() => setFragment(null)} />}
          </FadeIn>
        )) || <TimetableLoader />}
      </>
    );
  };

  return <div className="timetable-page">{project && renderHead()}</div>;
};

export default React.memo(TimetablePage);
