import { cloneElement, forwardRef } from "react";
import { renderWithTrackId } from "../../../../utils/eventsTrack";
import useEventsTrackParentId from "../../../../utils/hooks/useEventsTrackParentId";
import { EventsTrackWrapperBaseProps } from "./EventsTrackWrapper.interface";

const EventsTrackWrapperBase = forwardRef<unknown, EventsTrackWrapperBaseProps>(({ children, id, type, params, needParent }, ref) => {
  const parentEventsTrackId = useEventsTrackParentId();
  const clonedChildren = ref ? cloneElement(children, { ref }) : children;

  if (needParent) {
    return parentEventsTrackId ? renderWithTrackId(clonedChildren, [parentEventsTrackId, id].flat(), type, params) : clonedChildren;
  }
  return renderWithTrackId(clonedChildren, id, type, params);
});

export default EventsTrackWrapperBase;
