import React, { useEffect, useState } from "react";

export const getFaqTabs = (promoUrl = "") => {
  return [
    {
      name: "Что такое платформа Правительства Москвы «Город идей»?",
      alias: "main",
      content:
        `<p>Платформа Правительства Москвы «Город идей» позволяет обеспечить диалог между жителями и органами исполнительной власти по ключевым вопросам развития столицы. Пользователи платформы могут принимать участие в тематических проектах, предлагая свои идеи по заданным темам, а также <a href="${promoUrl}/propose" target="_blank">предлагать идеи</a> по благоустройству города и <a href="${promoUrl}/ideas?page=0" target="_blank">подтверждать реализацию</a> идей городом.</p>` +
        "<p>Посмотрев видео, вы можете ознакомиться подробнее с тем, как проходят проекты.</p>",

      attachments: [
        {
          videoId: "Faq1.mp4",
          preview: "fcover1.jpg",
        },
      ],
    },

    {
      name: "Как устроена платформа проекта?",
      alias: "arrange",
      content:
        "<p>Предлагаем вам посмотреть видео о ключевых разделах платформы, на которой проводятся проекты.</p>",

      attachments: [
        {
          videoId: "Faq2.mp4",
          preview: "fcover2.jpg",
        },
      ],
    },

    {
      name: "Чем регулируется работа на платформе проекта?",
      alias: "control",
      content:
        '<p>Для получения доступа к платформе проекта каждый участник соглашается с условиями <a href="/license" target="blank">Пользовательского соглашения</a>, которое регламентирует полномочия участников проекта, правила и условия использования платформы проекта. Для участия в проекте пользователи должны соблюдать правила публикации контента на платформе проекта, которые позволяют сделать работу участников более комфортной и продуктивной. В случае несоблюдения правил публикации контента или пользовательского соглашения команда проекта оставляет за собой право применить соответствующие меры.',
    },

    {
      name: "Кто рассматривает идеи и отвечает за проведение проекта?",
      alias: "review",
      content:
        "<p>На проекте работают эксперты и координаторы проекта. Экспертами проекта являются представители органов исполнительной власти города Москвы и подведомственных им организаций. Они рассматривают идеи участников и отвечают на вопросы по теме проекта.</p>" +
        "<p>Координаторы проекта ответственны за проведение проекта и помогают участникам в организационных моментах. Они отвечают на вопросы по текущему проекту, рассказывают об этапах работы и обеспечивают соблюдение правил работы на проекте.</p>" +
        "<p>Также в проектах принимают участие лица проекта. Это известные горожане, неравнодушные к современным тенденциям развития города и информационных систем, интересующиеся темой текущего проекта.</p>",
    },

    {
      name: "Какие этапы предусмотрены при проведении проекта?",
      alias: "stages",
      cards: [
        {
          head: {
            title: "Шаг 1",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            heading: "Этап «Предлагай»",
            text: "Участники формулируют и подают свои идеи по заявленным темам. Идеи должны соответствовать критериям отбора.",
            background: "#E4F5F9",
          },
          footer: {
            text: "В противном случае они будут отклоняться экспертами и не смогут выйти на последующее голосование в рамках этапа «Оценивай»",
          },
          background: "#EEFBFF",
        },
        {
          head: {
            title: "Шаг 2",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            heading: "Этап «Отбирай»",
            text: "Эксперты проекта изучают все идеи  и проверяют их на предмет соответствия заданным критериям отбора. Экспертный отбор проходит параллельно этапу «Предлагай».",
            background: "#E4E4F9",
          },
          footer: {
            text: "Благодаря экспертному отбору на голосование выходят только содержательные и качественные идеи, соответствующие заданным критериям.",
          },
          background: "#EEFBFF",
        },
        {
          head: {
            title: "Шаг 3",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            heading: "Этап «Оценивай»",
            text: "Финальный этап проекта. Участники оценивают идеи с точки зрения необходимости реализации их в городе.",
            background: "#CFEBF9",
          },
          footer: {
            text: "Лучшие идеи по итогам проектов реализуются городом.",
          },
          background: "#EEFBFF",
        },
        {
          body: {
            heading: "Этап «Обсуждай»",
            text: "Участники делятся своим мнением по вопросам, связанным с темой проекта. Тематические обсуждения могут проходить в течение всего проекта.",
          },
          footer: {
            text: "Все комментарии, полученные в ходе тематических обсуждений, передаются экспертам для рассмотрения и анализа.",
          },
          background: "#F9E6DE",
        },
      ],
    },

    {
      name: "Как подать идею на проекте и отслеживать её статус?",
      alias: "send",
      cards1: [
        {
          head: {
            title: "1",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            text: "На главной странице в карточке этапа «Предлагай» выберите тему из списка.",
          },
          background: "#E4F5F9",
        },
        {
          head: {
            title: "2",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            text: "В появившейся форме ознакомьтесь с критериями отбора идей.",
          },
          background: "#E4E4F9",
        },
        {
          head: {
            title: "3",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            text: "Вернитесь к форме описания идеи и заполните все обязательные поля.",
          },
          background: "#CFEBF9",
        },
        {
          head: {
            title: "4",
            icon: "ui-thin-icon-arrow-right-black",
          },
          body: {
            text: "Нажмите кнопку «Отправить».",
          },
          footer: {
            text: "<b>Важно:</b> после отправки идеи внести изменения в описание идеи нельзя.",
          },
          background: "#f9e6de",
        },
      ],
      cards2: [
        {
          head: {
            icon: "ui-thin-icon-note",
          },
          body: {
            heading: "«На рассмотрении»",
            text: "Если эксперты еще не приняли решение по вашей идее.",
          },
          background: "#E4E4F9",
        },
        {
          head: {
            icon: "ui-thin-icon-close-thin",
          },
          body: {
            heading: "«Отклонена»",
            text: "Если идея противоречила заявленным критериям отбора или нарушала <a href='/license' target='_blank'>Пользовательское соглашение.</a>",
          },
          background: "#E4E4F9",
        },
      ],

      cards3: [
        {
          head: {
            icon: "ui-thin-icon-docs",
          },
          body: {
            heading: "«Схожая»",
            text: "Если идея принята, но уступает по полноте и точности описания другой идее со схожим предложением, получившей статус «Выбор экспертов».",
          },
          background: "#CFEBF9",
        },
        {
          head: {
            icon: "ui-thin-icon-medal",
          },
          body: {
            heading: "«Выбор экспертов»/ «Финалист»",
            text: "Идея, признанная экспертами наиболее полно и точно описанной и/или не имеющая схожих, получает статус «Выбор экспертов». Сразу после старта этапа «Оценивай» статус такой идеи меняется на «Финалист».",
          },
          background: "#CFEBF9",
        },
      ],
    },

    {
      name: "Куда обратиться, если у вас возникли вопросы?",
      alias: "questions",
      cards1: [
        {
          head: {
            title: "1",
            icon: "ui-thin-icon-push",
          },
          body: {
            text: "Нажмите на клавиатуре кнопку <strong>Print Screen</strong> (иногда она называется <strong>PrntScrn</strong>, <strong>PrtScr</strong> или <strong>PrtSc</strong>).",
          },
          background: "#EEFBFF",
        },
        {
          head: {
            title: "2",
            icon: "ui-thin-icon-console",
          },
          body: {
            text: "Откройте любой из установленных графических редакторов, например <strong>Paint.</strong>",
          },
          background: "#DEF4F9",
        },
        {
          head: {
            title: "3",
            icon: "ui-thin-icon-dialog",
          },
          body: {
            text: "Выберите в меню пункт <strong>«Правка»</strong> ⟶ <strong>«Вставить»</strong> или воспользуйтесь комбинацией клавиш <strong>Ctrl + V.</strong>",
          },
          background: "#EEFBFF",
        },
        {
          head: {
            title: "4",
            icon: "ui-thin-icon-mount",
          },
          body: {
            text: "Сохраните снимок экрана на вашем компьютере.",
          },
          background: "#DEF4F9",
        },
      ],

      cards2: [
        {
          head: {
            title: "1",
            icon: "ui-thin-icon-push",
          },
          body: {
            text: "Чтобы сделать снимок всего экрана, нажмите на клавиатуре сочетание клавиш <strong>⌘ cmd + Shift + 3.</strong>",
          },
          background: "#EEFBFF",
        },
        {
          head: {
            title: "2",
            icon: "ui-thin-icon-mount",
          },
          body: {
            text: "Снимок экрана в формате PNG автоматически появится на вашем рабочем столе или в папке, которую вы указали в настройках вашего Mас.",
          },
          background: "#DEF4F9",
        },
      ],
    },
  ];
};
