import { Select } from "@crowd/ui-kit";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import NotificationsService from "../../../../services/notificationsService";
import PersonalService from "../../../../services/personalService";
import { showConfirmInfoModal } from "../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { useForceUpdate } from "../../../../utils/hooks/useForceUpdate";
import { getUrlParam } from "../../../../utils/urlUtils";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import DatePicker from "../../../presentational/Controls/DatePicker/DatePicker";
import { DatePickerType } from "../../../presentational/Controls/DatePicker/DatePicker.interface";
import { ElementObserver } from "../../../presentational/ElementObserver/ElementObserver";
import { ElementObserverItem } from "../../../presentational/ElementObserver/ElementObserverItem/ElementObserverItem";
import { moduleCards } from "../Profile.constants";
import { ModuleTypeEnum } from "../Profile.interface";
import ProfileSubContent from "../ProfileSubContent/ProfileSubContent";
import { Tabs } from "./MyAnswers.constants";
import { AnwersTypeEnum } from "./MyAnswers.interface";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import "./MyAnswers.scss";
import { getProfileTab } from "../Profile.helper";
import useQuery from "../../../../utils/hooks/useQuery";
import { getCountAllUnread } from "../../../../store/actions/NotificationsActions";

const MyAnswers = () => {
  const forceUpdate = useForceUpdate();
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const [answers, setAnswers] = useState(null);
  const [paging, setPaging] = useState(null);
  const [date, setDate] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [tab, setTab] = useState(getProfileTab(query.type, getTabs()));
  const [page, setPage] = useState(getPageFromUrl("page"));
  const [type, setType] = useState(getTypeFromUrl());
  const [loading, setLoading] = useState(false);
  const [disabledDays, setDisabledDays] = useState({
      after: dayjs().toDate(),
      before: dayjs().subtract(1, "month").toDate(),
  })

  useEffect(() => {
    loadAnswers();
  }, [tab.type, type, date, page]);

  // API calls

  const loadAnswers = () => {
    let query: any = {
      type: tab.type,
      page,
      size: 10,
    };

    if (type === AnwersTypeEnum.UNREAD) {
      query.newOnly = type === AnwersTypeEnum.UNREAD;
    }

    if (date && date.from && date.to) {
      query.from = dayjs(date.from).format("YYYY-MM-DD");
      query.to = dayjs(date.to).format("YYYY-MM-DD");
    }

    setLoading(true);
    PersonalService.getAnswersDiscussions(query)
      .then((response) => {
        setAnswers(response.data);
        setPaging(response.paging);
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => {
        setLoading(false);
      });
  };

  const markRead = (comment) => {
    if (!comment.notificationProcessed) {
      NotificationsService.markRead(comment.notificationId)
        .then((response) => {
          comment.notificationProcessed = true;
          dispatch(getCountAllUnread());
        })
        .catch((err) => {
          comment.notificationProcessed = false;
        })
        .finally(() => {
          forceUpdate();
        });
    }
  };

  const markAllAsRead = () => {
    PersonalService.markAllRead()
      .then((response) => {
        if (response.data.status === ApiStatusCode.OK) {
          dispatch(showSuccessToast(response.data.message));
          loadAnswers();
          return;
        }

        dispatch(showErrorToast(response.data.message));
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  };

  function getTabs() {
    return moduleCards.find((m) => m.type === ModuleTypeEnum.ANSWERS).tabs;
  }

  function getTypeFromUrl() {
    return (
      getUrlParam("type", history.location.search)?.toUpperCase() ||
      AnwersTypeEnum.ALL
    );
  }

  const getSelectValue = () => {
    return Tabs.find((i) => i.value === type) || null;
  };

  const switchByType = (value) => {
    if (value === AnwersTypeEnum.MARK_ALL_AS_READ) {
      return dispatch(
        showConfirmInfoModal(
          "Вы действительно хотите отметить все ответы как прочитанные?",
          () => markAllAsRead()
        )
      );
    }

    setType(value);
  };

  function getPageFromUrl(pageName) {
    return Math.max(
      Number(getUrlParam(pageName, window.location.search)) - 1,
      0
    );
  }

  // RENDER

  const renderPanel = () => {
    const modifiers = { start: from, end: to };
    const onHandleChange = (date) => {
      setDate(date)
      setDisabledDays(
        {
          after: dayjs().toDate(),
          before: dayjs(date.from).toDate(),
        }
      )
    }

    return (
      <div className="my-anwers__panel">
        <Select
          buttonTitle={"Дополнительно"}
          items={Tabs}
          closeOnSelect={true}
          value={getSelectValue()}
          bindTo="title"
          onItemSelect={(item) => switchByType(item.value)}
        />

        <DatePicker
          type={DatePickerType.RANGED}
          onChange={onHandleChange}
          date={date}
          modifiers={modifiers}
          disabledDays={disabledDays}
        />
      </div>
    );
  };

  // RENDER

  const renderList = () => {
    return (
      <ElementObserver
        delayBeforeNotify={1000}
        autoUnobserve={true}
        onIntersect={(comment) => markRead(comment)}
      >
        {answers?.map((answer) => (
          <ElementObserverItem
            data={answer}
            id={date + answer.id}
            key={answer.id}
          >
            <CommentCard
              comment={answer}
              showLabel={true}
              showInfoIcon={false}
              canModify={false}
              background={answer.notificationProcessed ? "white" : "#DBF4FF"}
            />
          </ElementObserverItem>
        ))}
      </ElementObserver>
    );
  };

  const renderEmpty = () => (
    <div className="empty-content">
      У вас нет ни одного ответа на ваши комментарии
    </div>
  );

  return (
    <div className="my-anwers">
      <ProfileSubContent
        title="Ответы на мои комментарии"
        tabs={getTabs()}
        paging={paging}
        panel={renderPanel()}
        onTabChange={(tab) => {
          setTab(tab);
          setPage(0);
        }}
        onPageChange={(page) => setPage(page)}
        isLoading={loading}
      >
        <div className="my-anwers__content">
          {answers?.length ? renderList() : renderEmpty()}
        </div>
      </ProfileSubContent>
    </div>
  );
};

export default MyAnswers;
