import * as _sanitize from "sanitize-html";

export class Sanitizer {
  static sanitize(dirty) {
    return _sanitize.sanitize(dirty);
  }

  static sanitizeQuote(dirty) {
    return _sanitize(dirty, {
      allowedTags: ["a", "div", "pre", "p"],
      allowedAttributes: {
        a: ["href", "class", "target"],
        div: ["class", "contenteditable"],
        pre: ["class"],
      },
    });
  }
}
