import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@crowd/ui-kit";
import { Modal } from "@crowd/ui-kit/build";

import { logout } from "../../../../store/actions/UserActions";
import "./ResendEmailModal.scss";
import UserService from "../../../../services/userService";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { IBaseResponse } from "../../../../App.interface";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";

interface ResendEmailModalProps {
  onClose: () => void;
  isOpened: boolean;
}

const ResendEmailModal = ({ onClose, isOpened }: ResendEmailModalProps) => {
  const dispatch = useDispatch();
  const [blockedTime, setBlockedTime] = useState(0);
  const [blockedCounter, setBlockedCounter] = useState(
    setInterval(() => {}, 10 ** 10)
  );

  const blocked = () => blockedTime > 0;

  const closeAndLogout = () => {
    dispatch(logout());
    onClose();
  };

  const sendVerify = () => {
    UserService.verifyEmail()
      .then((response: IBaseResponse<any>) => {
        if (response.status === ApiStatusCode.OK) {
          dispatch(showSuccessToast("Письмо успешно отправлено."));
          return;
        }

        if (response.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
          dispatch(
            showErrorToast(
              "Произошла ошибка. Попробуйте заново войти в аккаунт и повторить попытку."
            )
          );
          return;
        }

        dispatch(showErrorToast(response.message));
      })
      .catch((err) => {
        console.warn(err);
        dispatch(showErrorToast(err.message));
      })
      .finally(() => {
        setBlockedTime(30);
      });
  };

  useEffect(() => {
    if (blockedTime > 0) {
      clearInterval(blockedCounter);

      const counter = setInterval(() => setBlockedTime(blockedTime - 1), 1000);
      setBlockedCounter(counter);
    }
  }, [blockedTime]);

  return (
    <Modal
      classNames={"resend-email-modal"}
      root="resend-email-modal__root"
      isOpen={!!isOpened}
      onClose={closeAndLogout}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <Fragment key={"body"}>
        <div className="resend-email-modal__info">
          <h3 className="resend-email-modal__title">
            ВЫ НЕ ЗАВЕРШИЛИ РЕГИСТРАЦИЮ!
          </h3>
          <span className="resend-email-modal__text">
            Чтобы завершить регистрацию, перейдите по ссылке из письма,
            отправленного на ваш электронный адрес с темой “Подтверждение
            регистрации на crowd.mos.ru”.
          </span>
        </div>
        <div className="resend-email-modal__buttons">
          <Button
            type="filled"
            isDisabled={blocked()}
            onClick={sendVerify}
            text={
              !blocked()
                ? "Повторно отправить письмо"
                : `Повторная отправка через ${blockedTime}`
            }
          />
          <Button text="Выйти" type="outlined" onClick={closeAndLogout} />
        </div>
      </Fragment>
    </Modal>
  );
};

export default ResendEmailModal;
