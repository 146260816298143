import { Fragment, useMemo } from "react";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { IdeaControlParam } from "../../types/types";
import { ProposalInterface, ProposalTypeEnum } from "../../../../../types/Proposal.interface";
import Tooltip from "../../../../presentational/Tooltip/Tooltip";
import { Button, Select } from "@crowd/ui-kit";
import { EIdeaProcessType, selectionFolderToProcessType } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectionIdeaProcessIdSelector, selectionIdeaProcessTypeSelector } from "../../../../../store/selectors/selection";
import {
  selectionGetIdeas,
  SELECTION_SET_IS_TAG_EDITING,
  selectionSetIdeaProcessAndType,
  selectionSetIdeaProcess,
} from "../../../../../store/actions/SelectionActions";
import { profileUserDetailsSelector } from "../../../../../store/selectors/profile";
import { showConfirmInfoModal, showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import ProposalService from "../../../../../services/proposalService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { useSelectionParams } from "../../helpers/params";
import { SelectionFolders } from "../../../../../utils/constants/proposal";

interface TProps {
  idea: ProposalInterface;
}

export function SelectionIdeaActions({ idea }: TProps) {
  const isMobile = useIsMobile();
  const { stageId, folder } = useSelectionParams();

  const dispatch = useDispatch();

  const ideaProcessId = useSelector(selectionIdeaProcessIdSelector);
  const ideaProcessType = useSelector(selectionIdeaProcessTypeSelector);
  const { chiefExpert } = useSelector(profileUserDetailsSelector);

  const handleActionSelect = (action?: (idea: ProposalInterface) => void) => {
    dispatch({ type: SELECTION_SET_IS_TAG_EDITING, payload: false });
    action?.(idea);
  };

  const nextIdea = (idea) => {
    dispatch(
      selectionGetIdeas({
        stageId,
        folder,
        proposalId: idea.id,
      })
    );
  };

  const setMainIdea = (idea: ProposalInterface) => {
    dispatch(
      showConfirmInfoModal("Вы действительно хотите сделать идею главной в группе?", async () => {
        try {
          const { status, message } = await ProposalService.selectionSetMain({ stageId, proposalId: idea.id });
          if (status !== ApiStatusCode.OK) {
            throw new Error(message);
          }
          dispatch(
            selectionGetIdeas({
              stageId,
              folder,
            })
          );
        } catch (e: any) {
          dispatch(showErrorInfoModal(e.message));
        }
      })
    );
  };

  const ideaControlDefaultAction = (ideaProcessType: EIdeaProcessType) => () => {
    console.log(idea.title, ideaProcessType);
    if (selectionFolderToProcessType[folder] !== ideaProcessType) {
      dispatch(selectionSetIdeaProcessAndType(idea.id, ideaProcessType));
    } else {
      dispatch(selectionSetIdeaProcess(undefined));
    }
  };

  const ideaControlParams = useMemo<IdeaControlParam[]>(() => {
    return [
      {
        isActive: (idea) =>
          idea.status === ProposalTypeEnum.ACCEPTED || (ideaProcessId === idea.id && ideaProcessType === EIdeaProcessType.ACCEPTING),
        isDisabled: (idea) =>
          ![ProposalTypeEnum.SUBMITTED, ProposalTypeEnum.FORWARDED].includes(idea.status) &&
          !(chiefExpert && ProposalTypeEnum.REJECTED === idea.status),
        action: ideaControlDefaultAction(EIdeaProcessType.ACCEPTING),
        tooltipText: "Принять",
        labelText: "Принята",
        icon: "ui-icon-done-symbol",
        ideaProcessType: EIdeaProcessType.ACCEPTING,
      },
      {
        isActive: (idea) =>
          idea.status === ProposalTypeEnum.REJECTED || (ideaProcessId === idea.id && ideaProcessType === EIdeaProcessType.DECLINING),
        isDisabled: (idea) =>
          ![ProposalTypeEnum.SUBMITTED, ProposalTypeEnum.FORWARDED].includes(idea.status) &&
          !(chiefExpert && ProposalTypeEnum.ACCEPTED === idea.status),
        action: ideaControlDefaultAction(EIdeaProcessType.DECLINING),
        tooltipText: "Отклонить",
        labelText: "Отклонена",
        icon: "ui-icon-remove-round",
        ideaProcessType: EIdeaProcessType.DECLINING,
      },
      {
        isActive: (idea) =>
          idea.status === ProposalTypeEnum.FORWARDED || (ideaProcessId === idea.id && ideaProcessType === EIdeaProcessType.FORWARDING),
        isDisabled: (idea) => ![ProposalTypeEnum.SUBMITTED].includes(idea.status),
        action: ideaControlDefaultAction(EIdeaProcessType.FORWARDING),
        tooltipText: "Передать",
        labelText: "Передана",
        icon: "ui-icon-send-message",
        ideaProcessType: EIdeaProcessType.FORWARDING,
      },
      {
        isActive: (idea) => ideaProcessId === idea.id && ideaProcessType === EIdeaProcessType.MOVING,
        isDisabled: (idea) => ![ProposalTypeEnum.SUBMITTED, ProposalTypeEnum.FORWARDED].includes(idea.status) && !chiefExpert,
        action: ideaControlDefaultAction(EIdeaProcessType.MOVING),
        tooltipText: "Переместить",
        labelText: "Перемещена",
        icon: "ui-icon-drive-file-move",
        ideaProcessType: EIdeaProcessType.MOVING,
      },
      {
        isHidden: (idea) => !chiefExpert || idea.selectedMain || ![ProposalTypeEnum.ACCEPTED].includes(idea.status),
        action: setMainIdea,
        tooltipText: "Выбрать главной",
        icon: "ui-icon-Component4073",
      },
      {
        isHidden: (idea) => ![ProposalTypeEnum.SUBMITTED].includes(idea.status),
        action: nextIdea,
        tooltipText: "Пропустить",
        icon: "ui-icon-play-next",
      },
    ];
  }, [stageId, ideaProcessId, ideaProcessType, folder]);

  if (isMobile) {
    const items = ideaControlParams
      .filter(
        (item) =>
          (selectionFolderToProcessType[folder] !== undefined && selectionFolderToProcessType[folder] === item.ideaProcessType) ||
          ((!item.isDisabled || !item.isDisabled?.(idea)) && (!item.isHidden || !item.isHidden?.(idea)))
      )
      .map((item) => ({
        action: item.action,
        label: item.labelText ?? item.tooltipText,
        ideaProcessType: item.ideaProcessType,
      }));

    const currentValue =
      (idea.id === ideaProcessId && items.find((item) => item.ideaProcessType !== undefined && item.ideaProcessType === ideaProcessType)) ||
      items.find(
        (item) => selectionFolderToProcessType[folder] !== undefined && selectionFolderToProcessType[folder] === item.ideaProcessType
      ) ||
      {};

    return (
      <Select
        label={folder === SelectionFolders.INCOMING ? "Решение по идее" : "Изменить решение по идее"}
        items={items}
        bindTo="label"
        value={currentValue}
        onItemSelect={(item, isAutoSelected) => {
          if (!isAutoSelected) {
            handleActionSelect(item?.action);
          }
        }}
        closeOnSelect
        restrictTo="258px"
        openSide="bottom"
      />
    );
  }

  return (
    <>
      {ideaControlParams.map((item, i) => (
        <Fragment key={i}>
          {!item.isHidden?.(idea) &&
            (item.isActive?.(idea) ? (
              <div className="selection-page__idea__current-state">
                <i className={`ic ${item.icon}`} /> {item.labelText}
              </div>
            ) : (
              <Tooltip
                idx={`idea-action-${idea.id}-${i}`}
                text={item.tooltipText}
                className={
                  (ideaProcessId === idea.id && ideaProcessType !== EIdeaProcessType.TAG_EDITING) || item.isDisabled?.(idea)
                    ? "disabled"
                    : ""
                }
              >
                <Button type="text-button" text="" icon={item.icon} onClick={() => handleActionSelect(item.action)} />
              </Tooltip>
            ))}
        </Fragment>
      ))}
    </>
  );
}
