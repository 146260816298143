import "./SelectionFolderButtons.scss";
import { Button } from "@crowd/ui-kit";
import { selectionFolderToCounterMap, SelectionFolders, SelectionFoldersNames } from "../../../../../utils/constants/proposal";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { Badge } from "../../../../presentational/Badge/Badge";
import cn from "classnames";
import { selectionFolderButtonsFolderToIconMap } from "./SelectionFolderButtons.icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SELECTION_SET_IDEAS_CURRENT_PAGE, SELECTION_SET_IS_FORWARDED_SHOW_ALL } from "../../../../../store/actions/SelectionActions";
import { selectionCountsSelector } from "../../../../../store/selectors/selection";

interface TFolderButtonProps {
  text?: string;
  count?: number;
  isActive?: boolean;
  folder: SelectionFolders;
  onClick: (clickedFolder: SelectionFolders) => void;
}

function SelectionFolderButton({ text, count, isActive, folder, onClick }: TFolderButtonProps) {
  const isMobile = useIsMobile();

  if (isMobile) {
    const icon = selectionFolderButtonsFolderToIconMap[folder][isActive ? "active" : "default"];

    return (
      <div className={cn("folder-button", { "folder-button--active": isActive })} onClick={() => onClick(folder)}>
        <Badge text={count}>
          <img src={icon} alt={text} className="folder-button__icon" />
        </Badge>
        <span>{text}</span>
      </div>
    );
  }

  return (
    <Button
      text={`${text}${typeof count === "undefined" ? "" : ` ${count}`}`}
      type={isActive ? "filled" : "outlined"}
      onClick={() => onClick(folder)}
    />
  );
}

export function SelectionFolderButtons() {
  const dispatch = useDispatch();

  const history = useHistory();
  const { folder, stageId } = useParams<{ folder: SelectionFolders; stageId: string }>();

  const counts = useSelector(selectionCountsSelector);

  function handleFolderButtonClick(clickedFolder: SelectionFolders) {
    dispatch({ type: SELECTION_SET_IDEAS_CURRENT_PAGE, payload: 0 });
    folder !== clickedFolder && history.push(`/select/${clickedFolder}/${stageId}`);
  }

  return (
    !!counts && (
      <div className="folder-buttons">
        {Object.entries(SelectionFoldersNames).map(([folderItem, text], i) => {
          const count = counts[selectionFolderToCounterMap[folderItem]];
          return (
            <SelectionFolderButton
              key={i}
              text={text}
              count={count}
              isActive={folderItem === folder}
              folder={folderItem as SelectionFolders}
              onClick={handleFolderButtonClick}
            />
          );
        })}
      </div>
    )
  );
}
