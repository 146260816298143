import React from "react";

import { showMosruForm } from "../../../../store/actions/LayoutActions";
import { useDispatch, useSelector } from "react-redux";
import "./LoggedOut.scss";
import { RootState } from "../../../../types/State.interface";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

const LoggedOut = () => {
  const dispatch = useDispatch();

  const promoUrl = useSelector(
    (state: RootState) => state.common.environment?.promoUrl
  );

  return (
    <div className="logged-out cr-header__link">
      <EventsTrackWrapperClick id={["HEADER", "BUTTON", "CLICK"]} params={{ replace: ["Войти"], remember: true }}>
        <span onClick={() => dispatch(showMosruForm())}>Войти</span>
      </EventsTrackWrapperClick>
      {promoUrl && (
        <Tooltip
          text={"Перейти на платформу «Город идей»"}
          idx={"goToPromoFromLoggedOut"}
        >
          <EventsTrackWrapperClick id={["HEADER", "ICON", "CLICK"]} params={{ replace: ["Домой"] }}>
            <a href={promoUrl}>
              <i className="ui-icon ui-icon-home"></i>
            </a>
          </EventsTrackWrapperClick>
        </Tooltip>
      )}
    </div>
  );
};

export default LoggedOut;
