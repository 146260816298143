const mapStageToTitle = (plural) => {
  return {
    GENERATION: `Подавайте свои идеи по ${plural ? "темам" : "теме"}:`,
    SIMPLE_DISCUSSION: `Общайтесь с другими участниками в ${
      plural ? "обсуждениях" : "обсуждении"
    }:`,
    VOTING: `Голосуйте за идеи других участников по ${
      plural ? "темам" : "теме"
    }:`,
  };
};

const mapStageToName = {
  GENERATION: "generation",
  SIMPLE_DISCUSSION: "discussion",
  PROJECT_DISCUSSION: "discussion",
  VOTING: "voting",
  EXPERT_SELECTION: "selection",
};

export { mapStageToTitle, mapStageToName };
