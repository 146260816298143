import { httpParamsSerialize, API } from "./api";
import { ApiStatusCode, IPagingParams, PagingParameters } from "../types/Common.interface";
import { Comment } from "../types/Comment.interface";
import { IBaseListResponse, IBaseResponse } from "../App.interface";
import dayjs from "dayjs";
import { escapeHtml, getPlainFromHtml, replaceHtmlEntites } from "../utils";

declare var CKEDITOR: any;

const baseUrl = "/api/comments";

export interface VoteCommentsParams {
  id: string;
}

export interface RecentCommentsParams {
  after?: string;
  before?: string;
  filter: "STAGE" | "BLOG" | "ANY";
  size?: number;
}

export interface CommentsGetParams extends PagingParameters {
  commentId?: string;
  discussionId?: string;
}

export interface getAllMineCommentsParams extends PagingParameters {
  authorId: string;
}

export type ToggleSubscribeType = "subscribe" | "unsubscribe";

export interface ToggleSubscribeParams {
  discussionId: string;
  type: ToggleSubscribeType;
}

export interface AddCommentParams {
  attachments: string[];
  discussionId: string;
  parentId?: string;
  text: string;
}

export interface VotedUsersParams {
  commentId: string;
  size: number;
  page: number;
}

export interface UpdateCommentParams {
  attachments: string[];
  id: string;
  text: string;
}

export interface LoadCommentsResponse {
  comments: Comment[];
  commentsLeft: number;
}

export interface LoadResponsesParams {
  amount: number;
  commentId: string;
}

export interface LoadResponsesByCommentIDsParams {
  amount: number;
  ids: string[];
}

export interface DeleteCommentParams {
  id: string;
  permanent: boolean;
  withChildren: boolean;
}

const toHTMLQuote = (content: string): string => {
  let plain = content;

  const formQuote = (quote, isBB) => {
    let quoteAuthor;
    let quoteText;
    if (isBB) {
      quoteAuthor = quote.substring(quote.indexOf("[quote=") + 7, quote.indexOf("]"));
      quoteText = quote.substring(quote.indexOf("]") + 1, quote.indexOf("[/quote]"));
    } else {
      quoteAuthor = quote.substring(quote.indexOf("<blockquote ") + 12, quote.indexOf(">"));
      quoteText = quote.substring(quote.indexOf(">") + 1, quote.indexOf("</blockquote>"));
    }

    const result = quote.replace(
      quote,
      '<div class="textAreaEditor__quote" contenteditable="false">' +
        (quoteAuthor ? '<div class="textAreaEditor__quote__author">' + quoteAuthor + "</div>" : "") +
        '<div class="textAreaEditor__quote__text"><pre>' +
        quoteText +
        "</pre></div>" +
        "</div>"
    );

    return result;
  };

  let quotations = plain.match(/\[quote(.|\n)*?quote\]/g);
  let isBB = true;
  if (!quotations) {
    quotations = plain.match(/<blockquote(.|\n)*?blockquote>/g);
    isBB = false;
  }
  if (quotations) {
    quotations.forEach((quote, idx) => {
      plain = plain.replace(quote, formQuote(quote, isBB));
    });
  }

  return plain;
};

const toBlockquote = (string) => {
  const commentEl = document.createElement("section");
  commentEl.innerHTML = string;

  commentEl.querySelectorAll(".textAreaEditor__quote").forEach((qEl) => {
    const nameEl = qEl.querySelector(".textAreaEditor__quote__author");
    const textEl = qEl.querySelector(".textAreaEditor__quote__text");

    if (nameEl) {
      qEl.outerHTML = `<blockquote ${nameEl.textContent}>${textEl.textContent}</blockquote>`;
      return;
    }

    qEl.outerHTML = `<blockquote>${textEl.textContent}</blockquote>`;
  });

  commentEl.querySelectorAll("div").forEach((el) => {
    el.outerHTML = "\n" + el.innerHTML;
  });

  commentEl.innerHTML = commentEl.innerHTML.replaceAll("<div><br></div>", "\n");

  return replaceHtmlEntites(commentEl.innerHTML);
};

const toBBQuote = (string) => {
  const commentEl = document.createElement("section");
  commentEl.innerHTML = string;

  commentEl.querySelectorAll(".textAreaEditor__quote").forEach((qEl) => {
    const nameEl = qEl.querySelector(".textAreaEditor__quote__author");
    const textEl = qEl.querySelector(".textAreaEditor__quote__text");

    if (nameEl) {
      qEl.outerHTML = `[quote=${nameEl.textContent}]${textEl.textContent}[/quote]`;
      return;
    }

    qEl.outerHTML = `[quote]${textEl.textContent}[/quote]`;
  });

  commentEl.querySelectorAll("div").forEach((el) => {
    el.outerHTML = "\n" + el.innerHTML;
  });

  commentEl.innerHTML = commentEl.innerHTML.replaceAll("<div><br></div>", "\n");

  return replaceHtmlEntites(commentEl.innerHTML);
};

const CommentService = {
  get: (params: CommentsGetParams | string): Promise<IBaseListResponse<Comment>> => {
    // Получение блока комментариев для указанной дискуссии. Или блока комментариев, содержащего указанный комментарий.
    let url = `${baseUrl}/get?${httpParamsSerialize(params)}`;
    return API.get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  getById: (commentId) => {
    let url = `${baseUrl}/get?commentId=${commentId}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  getRecentComments: (params?: RecentCommentsParams) => {
    // Получение последних комментариев на площадке из всех доступных пользователю обсуждений.
    let url = `${baseUrl}/recent/get?${httpParamsSerialize(params)}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  getCommentsByUserId: (
    userId: string,
    paging: IPagingParams,
    sort?: string[],
    from?: Date,
    to?: Date
  ): Promise<IBaseListResponse<Comment>> => {
    let params: any = { userId, ...paging, sort };
    if (from) {
      params.from = dayjs(from).format("YYYY-MM-DD");
    }
    if (to) {
      params.to = dayjs(to).format("YYYY-MM-DD");
    }
    params = httpParamsSerialize(params);

    return API.get(`${baseUrl}/getByFilter?${params}`).then(({ data }) => {
      return data;
    });
  },
  toggleSubscribe: ({ discussionId, type }: ToggleSubscribeParams) => {
    return API.post(`${baseUrl}/${type}`, { discussionId })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  vote: (data: VoteCommentsParams) => {
    return API.post(`${baseUrl}/vote`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  unVote: (data: VoteCommentsParams) => {
    return API.post(`${baseUrl}/deleteVote`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getVotedUsers: (data: VotedUsersParams) => {
    return API.get(`${baseUrl}/vote/users`, {
      params: {
        ...data,
      },
    })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  add: (data: AddCommentParams) => {
    return API.post(`${baseUrl}/add`, data).then(({ data }) => {
      return data;
    });
  },
  loadResponses: (params: LoadResponsesParams) => {
    // Получение ответов к указанному комментарию в режиме обсуждения, т.е. вернутся все потомки.
    const url = `${baseUrl}/loadResponses?${httpParamsSerialize(params)}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  loadResponsesByCommentIDs: (params: LoadResponsesByCommentIDsParams) => {
    // Получение ответов к указанному комментарию в режиме обсуждения, т.е. вернутся все потомки.
    const url = `${baseUrl}/loadResponsesByCommentIds?${httpParamsSerialize(params)}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  update: (data: UpdateCommentParams) => {
    return API.post(`${baseUrl}/update`, data).then(({ data }) => {
      return data;
    });
  },
  delete: (data: DeleteCommentParams) => {
    return API.post(`${baseUrl}/delete`, data).then(({ data }) => {
      return data;
    });
  },
  restore: (id: string, withChildren: boolean = true): Promise<IBaseResponse<Comment>> => {
    return API.post(`${baseUrl}/restore`, { id, withChildren }).then(({ data }) => {
      return data;
    });
  },

  markRead: (commentId: string, isNew: boolean): Promise<IBaseResponse<any>> => {
    return API.post(`${baseUrl}/setNew`, {
      commentId,
      new: isNew,
    })
      .then(({ data }) => {
        if (ApiStatusCode.OK !== data.status) {
          console.warn(data);
        }

        return data;
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  setTop: (id: string, top: boolean): Promise<IBaseResponse<Comment>> => {
    return API.post(`${baseUrl}/setTop`, { id, top })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  toBBQuote,
  toHTMLQuote,
  toBlockquote,

  getPlainCommentText: (htmlCommentText: string): string => {
    const commentEl = document.createElement("section");
    commentEl.innerHTML = htmlCommentText;

    commentEl.querySelectorAll(".textAreaEditor__quote").forEach((qEl) => {
      qEl.remove();
    });

    return commentEl.innerText;
  },

  CkBBCodeToHtml(code) {
    code = this.replaceLink(code);

    var bbcodeFilter = new CKEDITOR.htmlParser.filter();
    var fragment = CKEDITOR.htmlParser.fragment.fromBBCode(code);
    var writer = new CKEDITOR.htmlParser.basicWriter();

    fragment.writeHtml(writer, bbcodeFilter);
    let result = writer.getHtml(true);
    result = this.replaceIMG(result);
    result = this.replaceIFRAME(result);

    return result;
  },

  CkHtmlToBBCode(html) {
    if (window["CKEDITOR"]) {
      const editor = CKEDITOR.instances["ckeditor"];
      if (editor) {
        editor.insertHtml(html);
        const data = editor.getData();
        editor.setData("");
        return data;
      }
    }
  },

  replaceLink(code) {
    let text = code;
    const links = text.match(/\[url\](.*?)\[\/url\]/gi);
    if (links) {
      links.forEach((link) => {
        let result = link.replace("[url]", "");
        result = result.replace("[/url]", "");
        text = text.replace(link, result);
      });
    }

    return text;
  },

  replaceIMG(html) {
    let text = html;
    const images = text.match(/\<span bbcode="img"(.|\n)*?span>/g);
    if (images) {
      images.forEach((img) => {
        const src = getPlainFromHtml(img);
        text = text.replace(img, `<img src="${src}" />`);
      });
    }

    const domParser = new DOMParser();
    const document = domParser.parseFromString(text, "text/html");
    Array.from(document.querySelectorAll("p")).forEach((paragraph) => {
      const imageElement = Array.from(paragraph.children).find((el) => el instanceof HTMLImageElement);
      if (imageElement) {
        const alignment = paragraph.attributes["bbcode"].value;
        const div = document.createElement("div");
        div.style.textAlign = alignment;
        div.appendChild(imageElement);
        paragraph.parentNode.replaceChild(div, paragraph);
        return div;
      }
    });

    return document.body.innerHTML;
  },

  replaceIFRAME(html) {
    let text = html;
    const iframes = text.match(/\<iframe(.|\n)*?iframe>/g);
    if (iframes) {
      iframes.forEach((iframe) => {
        const src = getPlainFromHtml(iframe);
        text = text.replace(iframe, `<iframe src="${src}" />`);
      });
    }

    return text;
  },

  wrapParagraph(html) {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(html, "text/html");
    const nodes: ChildNode[] = [];
    const res = [];
    let isLastParagraph = true;
    document.body.childNodes.forEach((node) => nodes.push(node));
    nodes.forEach((node) => {
      if (node instanceof HTMLParagraphElement) {
        res.push(node);
        isLastParagraph = true;
      } else if (node.nodeType === 3 && !node.textContent?.replace(/\s+/g, "").length && isLastParagraph) {
        res.push(node);
      } else {
        if (isLastParagraph) {
          const p = document.createElement("p");
          p.appendChild(node);
          res.push(p);
          isLastParagraph = false;
        } else {
          res[res.length - 1]?.appendChild(node);
        }
      }
    });
    return res.map((node) => node.outerHTML || node.textContent).join("");
  },
};

export default CommentService;
