import React from "react";

import "./FadeIn.scss";

const FadeIn = (props) => {
	return(
		<div className="fade-in">
			{
				props.children
			}
		</div> 
	)
};

export default FadeIn;
