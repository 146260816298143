import React, { Fragment, useEffect, useState } from "react";
import { Button, FileSelector, Input, Textarea } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";

import "./MessageModal.scss";
import Tabs from "../../../presentational/Tabs/Tabs";
import { messageTabs } from "./MessageModal.constants";
import MailService, {
  SaveMailRequest,
  SendMailRequest,
} from "../../../../services/mailService";
import {
  showConfirmInfoModal,
  showOkInfoModal,
} from "../../../../store/actions/LayoutActions";
import { User } from "../../../../types/User.interface";
import { RootState } from "../../../../types/State.interface";
import { MailMessage } from "../../../../types/Mail.interface";
import DynamicUserList from "../../DynamicUserList/DynamicUserList";
import { ApiStatusCode } from "../../../../types/Common.interface";
import allowedExtensions from "../../../../utils/constants/allowedExtensions";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import { showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import { renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";
import EventsTrackModal from "../../EventsTrack/wrappers/helpers/EventsTrackModal";

interface MessageOptions {
  message: string;
}

interface SendOptions {
  modal?: MessageOptions;
  toast?: MessageOptions;
}

export interface MessageModalProps {
  recipients?: User[];
  isOpen: boolean;
  onClose: () => void;
  isDynamic?: boolean;
  canDraft?: boolean;
  draftData?: MailMessage;
  toProjectStaff?: boolean;
  onSuccess?: Function;
  sentOptions?: SendOptions;
  draftOptions?: SendOptions;
}

const MessageModal = ({
  recipients,
  isDynamic,
  isOpen,
  toProjectStaff,
  canDraft,
  draftData,
  onClose,
  onSuccess,
  sentOptions,
  draftOptions,
}: MessageModalProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const [currentTab, setCurrentTab] = useState(
    toProjectStaff ? messageTabs[0] : null
  );
  const [themeValue, setThemeValue] = useState(draftData?.title || "");
  const [textValue, setTextValue] = useState(draftData?.text || "");
  const [files, setFiles] = useState<any>(null);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [user] = useState<User>(
    (!isDynamic && recipients && recipients[0]) || null
  );
  const [dynamicRecipients, setDynamicRecipients] = useState(
    (isDynamic && recipients) || []
  );

  const submitForm = async () => {
    setSubmitted(true);

    if (formValid()) {
      const formData: SendMailRequest = {
        attachments: files?.length ? files.map((file) => file.id) : [],
        recipients: user ? [user.id] : dynamicRecipients.map((r) => r.id),
        text: textValue,
        title: themeValue,
      };

      if (toProjectStaff) {
        formData.destination = currentTab.id;
      }

      setSubmitting(true);
      MailService.send(formData)
        .then((response) => {
          if (response.status === ApiStatusCode.OK) {
            //Show modal message if present
            sentOptions?.modal?.message &&
              dispatch(showOkInfoModal(null, sentOptions.modal.message));

            //Show toast message if present
            sentOptions?.toast?.message &&
              dispatch(showSuccessToast(sentOptions.toast.message));

            onClose();
            onSuccess && onSuccess(draftData);
            return;
          }

          dispatch(showErrorToast(response.message));
        })
        .catch((err) => dispatch(showErrorToast(err.message)))
        .finally(() => setSubmitting(false));
    }
  };

  const saveAsDraft = () => {
    const formData: SaveMailRequest = {
      attachments: files?.length ? files.map((file) => file.id) : [],
      recipients: user ? [user.id] : dynamicRecipients.map((r) => r.id),
      text: textValue,
      title: themeValue,
    };

    if (draftData?.id) formData.draftId = draftData.id;

    MailService.save(formData).then((response) => {
      if (response.status === ApiStatusCode.OK) {
        //Show modal message if present
        draftOptions?.modal?.message &&
          dispatch(showOkInfoModal(null, draftOptions.modal.message));
        //Show toast message if present
        draftOptions?.toast?.message &&
          dispatch(showSuccessToast(draftOptions.toast.message));
        onClose();
        onSuccess && onSuccess();
      } else {
        dispatch(
          showErrorToast(
            response.message ||
              "При обращении к серверу произошла ошибка. Пожалуйста, повторите попытку позже."
          )
        );
      }
    });
  };

  const formValid = () =>
    Boolean(
      textValue &&
        themeValue &&
        (user || dynamicRecipients.length || toProjectStaff)
    );

  const renderSubhead = () => {
    if (user) {
      return renderRecipientUser(user);
    }

    if (isDynamic) {
      return (
        <DynamicUserList
          defaultUsers={dynamicRecipients || []}
          endpoint={`/api/users/find?&type=ALL&query=`}
          isInvalid={
            (submitted && !Boolean(user || dynamicRecipients.length)) || false
          }
          onResult={(userList) => setDynamicRecipients(userList)}
          showError={submitted}
        />
      );
    }

    return (
      <Tabs
        currentTabId={currentTab?.id || ""}
        tabs={messageTabs}
        onTabClick={setCurrentTab}
        isUseMobileTab={false}
      />
    );
  };

  const renderRecipientUser = (u: User) => (
    <p className={"message-modal__user"}>
      Кому:
      <p className="message-modal__user-name">
        {u?.firstName} {u?.lastName}
      </p>
      {renderHatIfUserNameIsHidden(u, currentUser)}
    </p>
  );

  const handleOnClose = (e) => {
    if (themeValue || textValue) {
      e?.stopPropagation();

      return dispatch(
        showConfirmInfoModal(
          "Текст письма будет потерян, хотите сохранить черновик?",
          {
            buttonText: "Да",
            onClick: saveAsDraft,
          },
          { buttonText: "Нет", onClick: onClose }
        )
      );
    }

    onClose();
  };

  return (
    <EventsTrackModal
      isOpen={isOpen}
      onClose={handleOnClose}
      classNames={"message-modal"}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <Fragment key={"header"}>Новое сообщение</Fragment>

      <Fragment key={"subhead"}>{renderSubhead()}</Fragment>

      <Fragment key={"body"}>
        <Input
          value={themeValue}
          onChange={setThemeValue}
          placeholder={"Тема сообщения"}
          showError={submitted}
          shouldValidate={true}
          validateRules={{
            notEmpty: true,
          }}
        />

        <Textarea
          value={textValue}
          onChange={setTextValue}
          placeholder={"Текст сообщения"}
          maxLength={1500}
          hint={"Не более 1500 символов"}
          showError={submitted}
          shouldValidate={true}
          validateRules={{
            notEmpty: true,
            maxLength: 1500,
          }}
        />

        <FileSelector
          defaultFiles={draftData?.attachments || []}
          text={
            <span className={"message-modal__file-selector"}>
              <i className={"ui-icon ui-icon-attach"} />
              Прикрепить файл
            </span>
          }
          upload={true}
          allowedExtensions={allowedExtensions}
          showInfoModal={(msg) => {
            dispatch(showErrorInfoModal(msg));
          }}
          isMultiple={false}
          onChange={setFiles}
          attachmentsLength={files?.length || 0}
        />
      </Fragment>
      <Fragment key={"footer"}>
        <Button
          text={"Отменить"}
          type={"outlined-grey"}
          size={"m"}
          onClick={handleOnClose}
        />

        {canDraft && (
          <Button
            text={"Сохранить черновик"}
            type={"outlined"}
            size={"m"}
            onClick={saveAsDraft}
          />
        )}

        <Button
          text={"Отправить"}
          type={"filled"}
          size={"m"}
          isLoading={submitting}
          onClick={submitForm}
        />
      </Fragment>
    </EventsTrackModal>
  );
};

export default MessageModal;
