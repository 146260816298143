import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { AppEditor, Button, Input, Loader, Modal } from "@crowd/ui-kit";
import { useDispatch } from "react-redux";

import { BlogPostSummary } from "../../../../types/BlogPost.interface";
import { AddBlogpostSummaryParams } from "../../../../services/blogService";
import {
  addBlogpostSummary,
  updateBlogpostSummary,
} from "../../../../store/actions/BlogActions";
import { ApiResponse } from "../../../../types/Common.interface";
import CommentService from "../../../../services/commentService";
import "./addPostSummary.scss";

export interface AddPostSummaryProps {
  isOpen: boolean;
  onClose: () => any;
  summary: BlogPostSummary;
  postId: string;
  onAddBlogPostSummary?: (summary: BlogPostSummary) => any;
}

const AddPostSummary = ({
  isOpen,
  onClose,
  summary,
  postId,
  onAddBlogPostSummary,
}: AddPostSummaryProps) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (summary) {
      setTitle(summary.title);
      setText(summary.content);
    }
  }, [summary]);

  const formValid = (): boolean => !!(title && text);

  const successSubmitForm = ({ data }: ApiResponse<BlogPostSummary>) => {
    onAddBlogPostSummary(data);
    onClose();
  };

  const errSubmitForm = () => {
    setSubmitting(false);
  };

  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitted(true);
    if (formValid()) {
      const data: AddBlogpostSummaryParams = {
        title: title,
        content: CommentService.CkHtmlToBBCode(text),
        postId: postId,
      };

      dispatch(
        !summary
          ? addBlogpostSummary(data, successSubmitForm, errSubmitForm)
          : updateBlogpostSummary(data, successSubmitForm, errSubmitForm)
      );
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} classNames="summary-modal">
      <Fragment key={"header"}>
        {summary ? "Редактировать резюме" : "Добавить резюме"}
      </Fragment>

      <Fragment key={"body"}>
        {!submitting ? (
          <>
            <Input
              value={title}
              onChange={setTitle}
              placeholder={"Заголовок резюме"}
              isRequired={true}
              shouldValidate={true}
              validateRules={{ notEmpty: true }}
              showError={submitted}
            />

            <AppEditor
              defaultText={
                summary ? CommentService.CkBBCodeToHtml(summary.rawContent) : ""
              }
              text={text}
              onChange={setText}
              showError={submitted}
              validateRules={{
                notEmpty: true,
              }}
              editorSittings={{
                placeholder: "Текст резюме",
              }}
            />
          </>
        ) : (
          <Loader />
        )}
      </Fragment>

      {!submitting && (
        <Fragment key={"footer"}>
          <Button
            text={"Отменить"}
            type={"outlined-grey"}
            size={"m"}
            onClick={onClose}
          />
          <Button
            text={"Сохранить"}
            type={"filled"}
            size={"m"}
            onClick={submitForm}
          />
        </Fragment>
      )}
    </Modal>
  );
};

export default AddPostSummary;
