import { Checkbox } from "@crowd/ui-kit";
import React, { ReactNode, useMemo, useState } from "react";
import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";
import cn from "classnames";

import "./Table.scss";

interface Column<T extends string> {
  header: ReactNode;
  accessor: T;
  renderValue?: (data: { row: DataRow<T>; value: any }) => ReactNode;
  sortEnabled?: boolean;
  className?: string;
}

type DataRow<T extends string> = {
  [K in T]: any;
};

export interface TableProps<T extends string> {
  columns: Column<T>[];
  data: DataRow<T>[];
  onRowClick?: (row: DataRow<T>) => void;
  activeRow?: DataRow<T>;
  selectEnabled?: boolean;
  classNames?: string;
  onSelect?: (data: DataRow<T>[]) => void;
}

const Table = <T extends string>({ columns, data, onRowClick, activeRow, selectEnabled = false, classNames, onSelect }: TableProps<T>) => {
  const [sort, setSort] = useState<[T, "desc" | "asc"]>();
  const [selected, setSelected] = useState<DataRow<T>[]>([]);
  const selectedSet = useMemo(() => new Set(selected), [selected]);

  useUpdateEffect(() => {
    onSelect?.(selected);
  }, [selected]);

  const renderData = useMemo(() => {
    const [f, d] = sort || [];
    return f
      ? data.slice().sort((a, b) => (d === "desc" ? String(b[f]).localeCompare(String(a[f])) : String(a[f]).localeCompare(String(b[f]))))
      : data;
  }, [data, sort]);

  const columnsAdd = useMemo<typeof columns>(
    () =>
      selectEnabled
        ? [
            {
              header: "",
              accessor: undefined,
              className: "crowd-table__select-column",
              renderValue: ({ row }) => (
                <Checkbox
                  defaultValue={selectedSet.has(row)}
                  onChange={(value) => setSelected((prev) => (value ? [...prev, row] : prev.filter((item) => item !== row)))}
                />
              ),
            },
            ...columns,
          ]
        : columns,
    [selectEnabled, columns, selectedSet]
  );

  return (
    <div className={cn("crowd-table", { "crowd-table_interactive": onRowClick }, classNames)}>
      <table>
        <thead>
          <tr>
            {columnsAdd.map((column, idx) => (
              <th key={idx} className={column.className}>
                {column.sortEnabled ? (
                  <>
                    {
                      <span
                        className={`crowd-table__sort ${column.accessor === sort?.[0] ? "crowd-table__sort_active" : ""}`}
                        onClick={() => {
                          const [f, d] = sort || [];
                          setSort([column.accessor, column.accessor === f ? (d === "asc" ? "desc" : "asc") : "desc"]);
                        }}
                      >
                        {typeof column.header === "string" ? <span>{column.header}</span> : column.header}
                      </span>
                    }
                  </>
                ) : (
                  column.header
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderData?.map((row, rowIndex) => (
            <tr className={activeRow === row ? "crowd-table__row-active" : ""} key={rowIndex} onClick={() => onRowClick?.(row)}>
              {columnsAdd.map((column, columnIndex) => (
                <td key={columnIndex} className={column.className}>
                  {column.renderValue ? column.renderValue({ row, value: row?.[column.accessor] }) : row?.[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
