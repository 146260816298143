import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "@crowd/ui-kit";

import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";
import "./AddPostModal.scss";
import BlogService from "../../../../services/blogService";

import Validator from "@crowd/ui-kit/build/classes/Validator";

const AddPostModal = (props) => {
  const [title, setTitle] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [teamPost, setTeamPost] = useState(props.teamPosts[0]);
  const [validator, setValidator] = useState<Validator>(null);

  useEffect(() => {
    validator?.updateConfig(getRules());
  }, [title]);

  const submit = () => {
    setSubmitted(true);

    if (validator.isValid()) {
      props.onAttach({ ...teamPost, title: title || teamPost.title });
      props.onClose();
    }
  };

  const getRules = () => {
    let rules = {
      maxLength: 800,
    };

    if (title) {
      rules["minLength"] = 10;
    }

    return rules;
  };

  return (
    <SubmitForm
      classNames="blog-modal"
      submitText={"Добавить"}
      isOpen={true}
      onClose={props.onClose}
      onSubmit={submit}
    >
      <React.Fragment key="header">Добавление / удаление поста</React.Fragment>

      <React.Fragment key="body">
        <Select
          items={props.teamPosts}
          value={teamPost}
          bindTo={"title"}
          closeOnSelect={true}
          restrictBy={10}
          placeholder="Выберите пост команды проекта \ эксперта \ лица проекта"
          isDisabled={false}
          selectFirstItem={true}
          onItemSelect={(post) => setTeamPost(post)}
          isInvalid={false}
        />

        <Input
          value={title}
          onChange={setTitle}
          placeholder={"Альтернативный заголовок"}
          isRequired={true}
          maxLength={800}
          hint={"Не более 800 символов"}
          shouldValidate={true}
          validateRules={getRules()}
          showError={submitted}
          onInitValidator={setValidator}
        />
      </React.Fragment>

      <React.Fragment key="footer">
        <Button
          type={"outlined-grey"}
          text="Отменить"
          size="m"
          onClick={props.onClose}
        />
      </React.Fragment>
    </SubmitForm>
  );
};

export default AddPostModal;
