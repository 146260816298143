// @flow

import { Checkbox, Button } from "@crowd/ui-kit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import NotificationsService, {
  NotificationData,
} from "../../../../services/notificationsService";
import { ApiStatusCode } from "../../../../types/Common.interface";
import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";
import {
  ChannelTypeEnum,
  NotificationConfugurationFormProps,
  NotificationSet,
} from "./NotificationConfugurationForm.interface";
import "./NotificationConfugurationForm.scss";
import { showSuccessToast } from "../../../../store/actions/ToastActions";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import { useMediaQuery } from "react-responsive";
import MobailHeaderInModal from "../../Modals/MobailModal/MobailHeaderInModal";

const NotificationConfigurationForm = (
  props: NotificationConfugurationFormProps
) => {
  const dispatch = useDispatch();
  const [sound, setSound] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationSet[]>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  useEffect(() => {
    NotificationsService.getSettings().then(({ data }) => {
      setSound(data.sound);
      setNotifications(data.notifications);
    });
  }, []);

  const hasChannel = (notification, type) => {
    return Boolean(notification.channels.find((ch) => ch.channel === type));
  };

  const getChannelByType = (notification, type) => {
    return notification.channels.find((ch) => ch.channel === type);
  };

  const updateSetting = (value, stageType, channelType) => {
    const notification = notifications.find((n) => n.type === stageType);
    const channel = notification.channels.find(
      (ch) => ch.channel === channelType
    );
    channel.value = value;
    setNotifications([...notifications]);
  };

  const submit = () => {
    setIsSubmitting(true);

    const formData = {
      sound,
      notifications,
    } as NotificationData;

    NotificationsService.update(formData)
      .then((response) => {
        if (response.data?.status === ApiStatusCode.OK) {
          dispatch(showSuccessToast("Настройки успешно сохранены."));
          props.onClose();
          return;
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // RENDER

  return (
    <SubmitForm
      classNames="notification-form"
      submitText={"Сохранить"}
      isOpen={true}
      onClose={() => props.onClose()}
      isSubmitting={isSubmitting}
      onSubmit={submit}
    >
      <React.Fragment key="header">Настройка уведомлений</React.Fragment>

      <React.Fragment key="body">
        <div className="notification-form__headers">
          <div>Типы уведомлений</div>
          <div>Блок «Уведомления»</div>
          <div>E-mail</div>
        </div>

        <div className="notification-form__notifications">
          {notifications &&
            notifications.map((notification: NotificationSet) => (
              <div
                key={notification.type}
                className="notification-form__notification"
              >
                <div>{notification.description}</div>

                <div className="center">
                  {hasChannel(notification, ChannelTypeEnum.SITE) && (
                    <Checkbox
                      defaultValue={
                        getChannelByType(notification, ChannelTypeEnum.SITE)
                          ?.value
                      }
                      readonly={
                        !getChannelByType(notification, ChannelTypeEnum.SITE)
                          ?.editable
                      }
                      onChange={(value) =>
                        updateSetting(
                          value,
                          notification.type,
                          ChannelTypeEnum.SITE
                        )
                      }
                    />
                  )}
                </div>

                <div className="center">
                  {hasChannel(notification, ChannelTypeEnum.MAIL) && (
                    <Checkbox
                      defaultValue={
                        getChannelByType(notification, ChannelTypeEnum.MAIL)
                          ?.value
                      }
                      readonly={
                        !getChannelByType(notification, ChannelTypeEnum.MAIL)
                          ?.editable
                      }
                      onChange={(value) =>
                        updateSetting(
                          value,
                          notification.type,
                          ChannelTypeEnum.MAIL
                        )
                      }
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>

      <React.Fragment key="footer">
        <Button
          type={"outlined"}
          text="Отменить"
          size="m"
          onClick={props.onClose}
        />
      </React.Fragment>
    </SubmitForm>
  );
};

export default NotificationConfigurationForm;
