import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AppColorsEnum } from "../../../App.interface";
import {
  hideFooter,
  hideHeaderLinks,
  setAppColor,
  showHeader,
  showHeaderLinks,
} from "../../../store/actions/LayoutActions";

import "./NotFoundPage.scss";

const NotFoundPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));
    dispatch(showHeader());
    dispatch(hideHeaderLinks());
    dispatch(hideFooter());

    return () => {
      dispatch(showHeaderLinks());
    };
  }, []);

  const goMain = () => {
    props.history.push("/");
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className="not-found-page">
      <div className="not-found-page__inner">
        <p className="not-found-page__hint">Что-то пошло не так…</p>
        <h1>404</h1>
        <p className="not-found-page__redirect">
          Страница не найдена. <br />
          Начните с <Link to="/">главной</Link>.
        </p>

        <span className="not-found-page__decoration decoration-type-1">?</span>
        <span className="not-found-page__decoration decoration-type-2">?</span>
        <span className="not-found-page__decoration decoration-type-3">#</span>
        <span className="not-found-page__decoration decoration-type-4">#</span>
      </div>
    </div>
  );
};

export default NotFoundPage;
