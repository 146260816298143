import DayPickerInput from "react-day-picker/DayPickerInput";
import React from "react";

import { DatePickerBase } from "../PickerBase";
import dayjs from "dayjs";
import ReactDOM from "react-dom";
import { Button } from "@crowd/ui-kit";

import "./RangedPicker.scss";

class RangedDatePicker extends DatePickerBase {
  private picker;
  class = "RangedDatePicker";
  toRef;
  fromRef;
  active;
  dates;
  isAllDates = true;

  constructor(picker) {
    super();

    this.picker = picker;
    const { from = null, to = null } = this.picker.props?.date || {};
    this.dates = {
      from,
      to,
    };
    if (from || to) {
      this.isAllDates = false;
    }
  }

  showFromMonth() {
    const { from, to } = this.dates;
    if (!from) {
      return;
    }
    if (dayjs(to).diff(dayjs(from), "months") < 2) {
      this.toRef.getDayPicker().showMonth(from);
    }
  }

  handleFromChange = (from) => {
    if (this.dates.to && from > this.dates.to) {
      from = this.dates.to;
    }
    this.dates = { ...this.dates, from};
    this.isAllDates = false;
    this.picker.props.onChange(this.dates);
    this.picker.forceUpdate();
  };

  handleToChange = (to) => {
    this.dates = { ...this.dates, to };
    this.isAllDates = false;
    this.picker.props.onChange(this.dates);
    this.showFromMonth();
    this.picker.forceUpdate();
  };

  takeAll = (ev) => {
    ev.stopPropagation();

    this.isAllDates = true;
    this.dates = { from: null, to: null };

    this.picker.props.onChange(this.dates);
    this.picker.forceUpdate();
  };

  renderFooter = (ref?) => {
    if (!ref?.daypicker) return;

    return ReactDOM.createPortal(
      <div onMouseDown={this.takeAll} className="RangedDatePicker__footer">
        <Button type="filled" text="Весь период" size="m" onClick={() => {}} />
      </div>,
      ref.daypicker.dayPicker
    );
  };

  onShow = (ref) => {
    this.active = ref;
    this.picker.forceUpdate();
  };

  getClassesForInput = (base) => {
    let classes = base;
    if (this.isAllDates) classes += " all-dates";
    return classes;
  };

  render() {
    const { from, to } = this.dates;
    const modifiers = { start: from, end: to };
    return (
      <>
        <div className={this.getClassesForInput("PickerFrom")}>
          <DayPickerInput
            ref={(el) => {
              this.fromRef = el;
            }}
            value={this.isAllDates ? "Весь период" : dayjs(from).format("DD.MM.YYYY")}
            placeholder="-"
            dayPickerProps={{
              ...this.baseCalendarProps,
              selectedDays: [from, { from, to }],
              toMonth: to,
              modifiers,
              numberOfMonths: 2,
              onDayClick: () => this.toRef.getInput().focus(),
              firstDayOfWeek: 1,
              disabledDays: this.picker.props.disabledDays || { after: to },
            }}
            onDayPickerShow={() => this.onShow(this.fromRef)}
            onDayChange={this.handleFromChange}
          />
          {from && <div className="PickerFrom-dash">–</div>}
        </div>

        <div className={this.getClassesForInput("PickerTo")}>
          <DayPickerInput
            ref={(el) => {
              this.toRef = el;
            }}
            value={to && dayjs(to).format("DD.MM.YYYY")}
            placeholder=""
            dayPickerProps={{
              ...this.baseCalendarProps,
              selectedDays: [from, { from, to }],
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
              firstDayOfWeek: 1,
              disabledDays: this.picker.props.disabledDays || { before: from },
            }}
            onDayPickerShow={() => this.onShow(this.toRef)}
            onDayChange={this.handleToChange}
          />
        </div>

        <i className="ui-icon ui-icon-date" onClick={() => this.onShow(this.fromRef)} />

        {this.renderFooter(this.active)}
      </>
    );
  }
}

export default RangedDatePicker;
