import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import DialogList from "./Components/DialogList/DialogList";
import ChatService from "../../../../services/chat.service";
import { ApiStatusCode, PagingResponseParameters } from "../../../../types/Common.interface";
import useQuery from "../../../../utils/hooks/useQuery";
import Pagination from "../../../presentational/Pagination/Pagination";
import { debounce, getPageFromUrl, scrollTop } from "../../../../utils";
import { Loader } from "@crowd/ui-kit";
import DynamicUserList from "../../../containers/DynamicUserList/DynamicUserList";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../store/actions/ToastActions";
import DialogCard from "./Components/DialogCard/DialogCard";

import "./Conversations.scss";
import { Dialog } from "./Components/Chat/chat.types";
import { hideInfoModal, showConfirmInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import { RootState } from "../../../../types/State.interface";
import { onEmptyChatCache } from "../../../../store/actions/SocketActions";
import { decrementUnreadDialogMessageCount } from "../../../../store/actions/NotificationsActions";

const DIALOGS_PER_PAGE = 10;

interface ConversationsProps {}

const Conversations: React.FC<ConversationsProps> = ({}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const peerMessages = useSelector<RootState>((state: RootState) => state.chat.peerToMe);

  const [candidatesToChat, setCandidatesToChat] = useState([]);
  const [dialogs, setDialogs] = useState<Dialog[]>(null);
  const [loadingDialogs, setLoadingDialogs] = useState(false);
  const [deletingDialogId, setDeletingDialogId] = useState(null);
  const [page, setPage] = useState(getPageFromUrl(history));
  const [pagination, setPagination] = useState<PagingResponseParameters>(null);

  useEffect(() => {
    if (query.userId) {
      createNewDialog(query.userId);
    }
  }, [query.userId]);

  const debouncedFetchDialogs = debounce((silent = false) => fetchDialogs(silent), 100);

  useEffect(() => {
    debouncedFetchDialogs();
  }, [page]);

  useEffect(() => {
    debouncedFetchDialogs(true);
  }, [peerMessages]);

  const fetchDialogs = async (isSilent = false) => {
    !isSilent && setLoadingDialogs(true);
    try {
      const response = await ChatService.getDialogs({ page, size: DIALOGS_PER_PAGE });
      if (response.status === ApiStatusCode.OK) {
        setDialogs(response.data);
        setPagination(response.paging);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDialogs(false);
    }
  };

  const deleteDialog = (dialog: Dialog) => {
    dispatch(
      showConfirmInfoModal("Удалить всю историю сообщений?", async () => {
        setDeletingDialogId(dialog.id);
        try {
          const response = await ChatService.deleteDialog({ id: dialog.id });
          if (response.status === ApiStatusCode.OK) {
            if (dialogs.length === 1 && page > 0) {
              setPage((page) => page - 1);
            }

            dispatch(onEmptyChatCache({ peerId: dialog.peer.id }));
            dispatch(showSuccessToast("Сообщения успешно удалены"));
            dispatch(decrementUnreadDialogMessageCount(dialog.unreadCount));
          }
        } catch (error) {
          console.log(error);
        } finally {
          setDeletingDialogId(null);
        }
      })
    );
  };

  const createNewDialog = async (peerId) => {
    dispatch(showInfoModal(null, null, <Loader />, null, "chat-create-dialog__modal"));
    try {
      const response = await ChatService.createDialog({ recipientId: peerId });
      if (response.status === ApiStatusCode.OK) {
        const newDialog = response.data;
        return history.push(`/profile/mail/${newDialog.id}`);
      }
      if (response.status === ApiStatusCode.CHAT_ALREADY_EXISTS || response.status === ApiStatusCode.CHAT_UNKNOWN_USER) {
        return dispatch(showWarningToast(response.message));
      }
      dispatch(showErrorToast(response.message));
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(showErrorToast(error.message));
      } else {
        dispatch(showErrorToast("Непредвиденная ошибка"));
      }
    } finally {
      dispatch(hideInfoModal());
    }
  };

  return (
    <div className="conversations">
      <div className="conversations__title">Личные сообщения</div>

      <div className="conversations__wrapper">
        <DynamicUserList
          defaultUsers={candidatesToChat || []}
          endpoint={`/api/users/find?&type=ALL&query=`}
          onResult={(userList) => createNewDialog(userList[0].id)}
        />

        {Boolean(dialogs) && !loadingDialogs ? (
          <DialogList>
            {dialogs.map((dialog) => (
              <DialogCard key={dialog.id} onDelete={deleteDialog} isDeleting={dialog.id === deletingDialogId} dialog={dialog} />
            ))}
          </DialogList>
        ) : (
          <Loader />
        )}

        {Boolean(dialogs?.length === 0) && <div className="empty-content">У вас нет сообщений</div>}

        {Boolean(pagination?.totalPages) && pagination?.totalPages > 1 && !loadingDialogs && (
          <Pagination
            totalPages={pagination?.totalPages || 0}
            onPageChange={(event) => {
              scrollTop();
              setPage(event.selected);
            }}
            currentPage={page}
            pageName="page"
          />
        )}
      </div>
    </div>
  );
};

export default Conversations;
