import { Frame } from "stompjs";
import { ChatMessageRaw } from "../../../pages/Profile/Conversations/Components/Chat/chat.types";
import { ReadMessageResponse, RemoveMessageResponse } from "../../../../services/chat.service";
import { displayError } from "../../../../utils";

export function frameToChatMessageRaw(frame: Frame): ChatMessageRaw | null {
  try {
    const message = JSON.parse(frame.body);
    if (
      typeof message.id !== "string" ||
      typeof message.text !== "string" ||
      !Array.isArray(message.attachments) ||
      typeof message.recipientId !== "string" ||
      typeof message.senderId !== "string" ||
      typeof message.read !== "boolean" ||
      typeof message.created !== "string"
    ) {
      displayError("STOMP parse message", new Error("Некоторые поля отсутствуют или имеют неверный тип"))
      return null;
    }
    return message;
  } catch (error) {
    displayError("STOMP parse message", error)
    return null;
  }
}

export function frameToChatRemoveResponse(frame: Frame): RemoveMessageResponse | null {
  try {
    const response = JSON.parse(frame.body);
    if (
      typeof response.id !== "string" ||
      typeof response.dialogId !== "string" ||
      typeof response.peerId !== "string" ||
      typeof response.authorId !== "string" ||
      typeof response.deletionStatus !== "string"
    ) {
      displayError("STOMP parse message", new Error("Некоторые поля отсутствуют или имеют неверный тип"))
      return null;
    }
    return response;
  } catch (error) {
    displayError("STOMP parse message", error)
    return null;
  }
}

export function frameToChatReadResponse(frame: Frame): ReadMessageResponse | null {
  try {
    const response = JSON.parse(frame.body);
    if (
      typeof response.dialogId !== "string" ||
      typeof response.recipientId !== "string" ||
      typeof response.senderId !== "string" ||
      !Array.isArray(response.ids)
    ) {
      displayError("STOMP parse message", new Error("Некоторые поля отсутствуют или имеют неверный тип"))
      return null;
    }
    return response;
  } catch (error) {
    displayError("STOMP parse message", error)
    return null;
  }
}
