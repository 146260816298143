import { User } from "./User.interface";

export interface MailState {
  inbox: any;
  sent: any;
  drafts: any;
  trash: any;
}

export enum MailTypeEnum {
  INBOX = "INBOX",
  SENT = "SENT",
  DRAFTS = "DRAFTS",
  TRASH = "TRASH",
}

export interface MailMessage {
  answered: boolean;
  attachments: any[];
  author: User;
  comment: any;
  commentAuthor: any;
  commentRecipient: any;
  created: string;
  draft: boolean;
  folderId: string;
  forwarded: false;
  id: string;
  read: boolean;
  readByRecipient: boolean;
  recipients: User[];
  replyToItemId: string;
  sent: string;
  tag: string;
  text: string;
  thread: string;
  title: string;
  type: MailTypeEnum;
  updated: string;
}
