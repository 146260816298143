const commentMock = {
  attachments: null,
  author: null,
  blogId: null,
  chooser: null,
  chosen: null,
  chosenTimestamp: null,
  complaintMade: null,
  date: null,
  deleted: null,
  deletedByAuthor: null,
  discussionId: null,
  discussionOwner: null,
  discussionPermissions: null,
  discussionStatistics: null,
  discussionTitle: null,
  discussionType: null,
  downVotes: null,
  hasResponses: null,
  id: null,
  interesting: null,
  lastEditDate: null,
  lastEditUser: null,
  new: null,
  nodeTemplateId: null,
  notificationId: null,
  notificationProcessed: null,
  parentId: null,
  permissions: null,
  personalDiscussion: null,
  replyTo: null,
  responses: null,
  responsesSkipped: null,
  rootId: null,
  stageId: null,
  stagePermissions: null,
  text: null,
  rawText: null,
  title: null,
  upVotes: null,
  voted: null,
};

export { commentMock };
