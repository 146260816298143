import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { EventsTrackConfig, EventsTrackControl, EventsTrackData, EventsTrackId } from "../../../types/EventsTrack.interface";
import { RootState } from "../../../types/State.interface";
import { getTimestamp, isObject, sprintf } from "../../../utils";
import { useHistory, Prompt } from "react-router-dom";
import { LocalStorageService } from "../../../services/LocalStorage.service";
import EventsTrackControlContext from "./EventsTrackControlContext";

interface Props {
  config: EventsTrackConfig;
}

const maxDeep = 10;

/**
 * находит самую глубокую запись в объекте data и заменяет последнее событие на mergeData
 */
const mergeDeepestEventDataObjectHelper = (data: EventsTrackData, mergeData: EventsTrackData): EventsTrackData => {
  let d = data;
  let k = Object.keys(d)[0];
  const res = {};
  let link = res;
  let i = 0;
  while (k && i < 20) {
    if (isObject(d[k])) {
      link[k] = {};
      link = link[k];
      d = d[k];
      k = Object.keys(d)[0];
    } else {
      break;
    }
    i++;
  }
  Object.entries(mergeData).forEach(([mk, mv]) => {
    link[mk] = mv;
  });
  return res;
};

const EventsTrackContainer: FC<Props> = ({ config, children }) => {
  const { params, callback } = config;
  const lastEventTrackDataRef = useRef<EventsTrackData>(null);
  const eventsTrackScrollCounterTrigger = useSelector((state: RootState) => state.layout.eventsTrackScrollCounterTrigger);
  const history = useHistory();
  const pageTimeRef = useRef(getTimestamp());
  const prevPathnameRef = useRef(history.location.pathname);
  const touchStartPos = useRef(0);
  const eTouchMove = useRef<TouchEvent>();
  const intersectionMapRef = useRef(new Map());

  const handlerSend = useCallback(
    (target, addIds?: EventsTrackId | EventsTrackId[]) => {
      const trackIds: EventsTrackId[] = [
        ...(JSON.parse(target.getAttribute("data-track-id")) || []),
        ...(Array.isArray(addIds) ? addIds : [addIds]).filter(Boolean),
      ];
      const data = trackIds.reduce<EventsTrackData>((acc, trackId) => mergeDeepestEventDataObjectHelper(acc, params[trackId] || {}), {});
      if (Object.keys(data).length) {
        const replace: string[] = target.hasAttribute("data-track-replace") ? JSON.parse(target.getAttribute("data-track-replace")) : [];
        let sendData = replace.length ? JSON.parse(sprintf(JSON.stringify(data), ...replace)) : data;

        const partial = target.hasAttribute("data-track-partial");
        const remember = target.hasAttribute("data-track-remember");
        const rememberPrev = target.hasAttribute("data-track-remember-prev");
        // если нужно добавить событие к предыдущему
        if (partial && !lastEventTrackDataRef.current) {
          return;
        } else if (partial) {
          sendData = mergeDeepestEventDataObjectHelper(lastEventTrackDataRef.current, sendData);
        }

        callback(sendData);
        // запоминаем последнее событие или забываем
        if (remember) {
          lastEventTrackDataRef.current = sendData;
        } else if (!rememberPrev) {
          lastEventTrackDataRef.current = null;
        }
      }
    },
    [params, callback]
  );

  // колбэк для отслеживания click событий
  const handlerClick = useCallback(
    function (e) {
      // ищем ближайший элемент с аттрибутом data-track-type="click" (не более 10 итераций)
      for (
        let target = e.target, i = 0;
        target && target !== e.currentTarget && target !== document.body && i < maxDeep;
        target = target.parentNode, i++
      ) {
        if (target.getAttribute("data-track-type") === "click") {
          handlerSend(target);
          break;
        }
      }
    },
    [handlerSend]
  );

  const handlerTouchStart = useCallback((e: TouchEvent) => {
    touchStartPos.current = e.touches[0]?.clientX || 0;
  }, []);

  const handlerTouchMove = useCallback((e) => {
    eTouchMove.current = e;
  }, []);

  const handlerTouchEnd = useCallback(
    (e) => {
      if (!eTouchMove.current) {
        return;
      }

      // ищем ближайший элемент с аттрибутом data-track-type="swipe" (не более 10 итераций)
      for (
        let target = eTouchMove.current.target as HTMLElement, i = 0;
        target && target !== e.currentTarget && target !== document.body && i < maxDeep;
        target = target.parentNode as HTMLElement, i++
      ) {
        if (target.getAttribute("data-track-type") === "swipe") {
          const id = (eTouchMove.current.touches[0]?.clientX || 0) < touchStartPos.current ? "SWIPE_LEFT" : "SWIPE_RIGHT";
          handlerSend(target, id);
          break;
        }
      }

      eTouchMove.current = undefined;
    },
    [handlerSend]
  );

  // колбэк при уходе со страницы (перед сменой урла react router или beforeunload)
  const leavePageCallback = useCallback(
    (e: Location | BeforeUnloadEvent) => {
      const nextPathname = "pathname" in e ? e.pathname : null;
      // отправляем событие если beforeunload или pathname действительно поменялся в react router
      if (nextPathname !== document.location.pathname) {
        const currentTime = getTimestamp();
        callback({
          leave: {
            page_time: currentTime - pageTimeRef.current,
          },
        });
        pageTimeRef.current = currentTime;
      }

      if (lastEventTrackDataRef.current) {
        LocalStorageService.saveData("lastEventTrack", JSON.stringify(lastEventTrackDataRef.current));
      }

      return true;
    },
    [callback]
  );

  const control = useMemo<EventsTrackControl>(
    () => ({
      setLastEventTrackData: (data) => {
        lastEventTrackDataRef.current = data;
      },
      getLastEventTrackData: () => lastEventTrackDataRef.current,
    }),
    []
  );

  useEffect(() => {
    const lastEventTrack = LocalStorageService.getData("lastEventTrack");
    if (lastEventTrack) {
      lastEventTrackDataRef.current = JSON.parse(lastEventTrack);
      LocalStorageService.removeData("lastEventTrack");
    }
  }, []);

  // слушатель IntersectionObserver для scroll событий
  useEffect(() => {
    const minRatio = 0;
    const maxRatio = 0.5;
    const observer = new IntersectionObserver(
      (entries) => {
        if (document.documentElement.scrollHeight > document.documentElement.clientHeight) {
          entries.forEach((entry) => {
            const prevIntersected = intersectionMapRef.current.get(entry.target);
            // отправка срабатывает после показа блока на половину или более
            // при условии, что до этого блок полностью побывал за пределами вьюпорта
            if (!prevIntersected && entry.intersectionRatio >= maxRatio) {
              const trackIds: EventsTrackId[] = JSON.parse(entry.target.getAttribute("data-track-id"));
              const data = trackIds.reduce<EventsTrackData>(
                (acc, trackId) => mergeDeepestEventDataObjectHelper(acc, params[trackId] || {}),
                {}
              );
              const replace: string[] = entry.target.hasAttribute("data-track-replace")
                ? JSON.parse(entry.target.getAttribute("data-track-replace"))
                : [];
              const sendData = replace.length ? JSON.parse(sprintf(JSON.stringify(data), ...replace)) : data;
              callback(sendData);
              intersectionMapRef.current.set(entry.target, true);
            } else if (prevIntersected && entry.intersectionRatio <= minRatio) {
              intersectionMapRef.current.set(entry.target, false);
            }
          });
        }
      },
      {
        threshold: [minRatio, maxRatio],
      }
    );

    const nodes = document.body.querySelectorAll('[data-track-type="scroll"]');
    intersectionMapRef.current.clear();
    nodes.forEach((node) => {
      observer.observe(node);
      intersectionMapRef.current.set(node, true);
    });
    return () => {
      observer.disconnect();
    };
  }, [params, callback, eventsTrackScrollCounterTrigger]);

  // событие при входе в приложение
  useEffect(() => {
    const referrer = document.referrer;
    const referrerHost = referrer ? new URL(referrer).host : null;
    if (referrerHost === document.location.host) {
      // переход с того же хоста
      callback({ view: "true" });
    } else {
      // переход с внешнего источника/прямой переход
      const eventParams: { [K: string]: any } = {
        referrer: referrer || "прямой переход",
      };
      const utmKeys = ["utm_source", "utm_medium"];
      new URLSearchParams(document.location.search).forEach((v, k) => {
        if (utmKeys.includes(k)) {
          if (!eventParams.utm) {
            eventParams.utm = {};
          }
          eventParams.utm[k] = v;
        }
      });
      callback({ view: eventParams });
    }
  }, []);

  // событие при переходе через react router
  useEffect(() => {
    const unlistenHistory = history.listen((location) => {
      if (prevPathnameRef.current !== location.pathname) {
        callback({ view: "true" });
        prevPathnameRef.current = location.pathname;
        intersectionMapRef.current.forEach((_, k) => {
          intersectionMapRef.current.set(k, true);
        });
      }
    });

    return () => unlistenHistory();
  }, [history, callback]);

  // событие при beforeunload
  useEffect(() => {
    window.addEventListener("beforeunload", leavePageCallback);
    return () => window.removeEventListener("beforeunload", leavePageCallback);
  }, [leavePageCallback]);

  // слушатель кликов и свайпа на body, тк есть элементы вне react дерева (например иконка чата)
  useEffect(() => {
    document.body.addEventListener("click", handlerClick, true);
    document.body.addEventListener("touchstart", handlerTouchStart, true);
    document.body.addEventListener("touchmove", handlerTouchMove, true);
    document.body.addEventListener("touchend", handlerTouchEnd, true);
    return () => {
      document.body.removeEventListener("click", handlerClick, true);
      document.body.removeEventListener("touchstart", handlerTouchStart, true);
      document.body.removeEventListener("touchmove", handlerTouchMove, true);
      document.body.removeEventListener("touchend", handlerTouchEnd, true);
    };
  }, [handlerClick]);

  return (
    <EventsTrackControlContext.Provider value={control}>
      <Prompt when={true} message={leavePageCallback} />
      {children}
    </EventsTrackControlContext.Provider>
  );
};

export default EventsTrackContainer;
