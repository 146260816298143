import { httpParamsSerialize, API } from "./api";
import { PagingParameters } from "../types/Common.interface";
import { DiscussionParamsTypeString } from "../types/Discussion.interface";

const baseUrl = "/api/discussion";

export interface RootCommentsParams extends PagingParameters {
  discussionId: string;
}
export interface DiscussionByTypeParams {
  stageId?: string;
  type?: DiscussionParamsTypeString;
}

const DiscussionService = {
  getRootComments: (params?: RootCommentsParams) => {
    let url = `${baseUrl}/rootComments?${httpParamsSerialize(params)}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },
  getDiscussionByType: (params: DiscussionByTypeParams) => {
    let url = `${baseUrl}/getByType?${httpParamsSerialize(params)}`;
    return API.get(url).then((data) => {
      return data?.data;
    });
  },
  getById: (id) => {
    let url = `${baseUrl}/get?id=${id}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },
};

export default DiscussionService;
