import { SelectFilterType } from "../../../../types/Selection.interface";
import { SelectionFolders } from "../../../../utils/constants/proposal";

export const enum EIdeaProcessType {
  ACCEPTING,
  DECLINING,
  FORWARDING,
  MOVING,
  TAG_EDITING,
}

export const selectionFolderToProcessType = {
  [SelectionFolders.ACCEPTED]: EIdeaProcessType.ACCEPTING,
  [SelectionFolders.REJECTED]: EIdeaProcessType.DECLINING,
  [SelectionFolders.FORWARDED]: EIdeaProcessType.FORWARDING,
};

export const filterTypesSearchSelect: SelectFilterType[] = [
  { value: "title", text: "По заголовку" },
  { value: "text", text: "По тексту" },
  { value: "authorId", text: "По автору" },
  { value: "expertId", text: "По эксперту" },
];

export const filterFieldsSearchSelect = filterTypesSearchSelect.map(({ value }) => value);
