import React from "react";
import "./CardLabel.scss";

export const CardLabel = (props) => {
  return (
    <div className="card-label">
      <span
        className={
          props.post?.stageId ? "card-label--discussion" : "card-label--blog"
        }
      >
        {props.post?.stageId ? "ОБСУЖДЕНИЯ" : "БЛОГИ"}
      </span>
    </div>
  );
};
