import { ProposalState } from "../../types/Proposal.interface";
import {
  GET_DRAFTS,
  GET_PROPOSAL_COUNT,
  STORE_LOCAL_DRAFT,
  CLEAR_LOCAL_DRAFT,
} from "../actions/ProposalActions";

const initialState: ProposalState = {
  drafts: null,
  count: null,
  storedDraft: null,
};

export function proposalReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_DRAFTS:
      return { ...state, drafts: action.payload };

    case GET_PROPOSAL_COUNT:
      return { ...state, count: action.payload };

    case STORE_LOCAL_DRAFT:
      return { ...state, storedDraft: action.payload };

    case CLEAR_LOCAL_DRAFT:
      return { ...state, storedDraft: null };

    default:
      return state;
  }
}
