import React, { useEffect } from "react";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import "./About.scss";
import { setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import { getAvatarSrc, isObjectValuesEmpty } from "../../../utils";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../utils/constants/widthConstants";

const AboutPage = () => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.current);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  const logoSrc =
    isPhone && !!project.platformLogo?.mobile
      ? project.platformLogo.mobile
      : !isPhone && isTablet && !!project.platformLogo?.tabletPc
      ? project.platformLogo.tabletPc
      : !isTablet && project.platformLogo?.pc;

  return (
    <section className={"about"}>
      <PageTitle text={"О проекте"} />

      <div className="about__wrapper">
        <div className="about__content app__text" dangerouslySetInnerHTML={{ __html: project?.description || "" }} />

        {!!logoSrc && (
          <div className="about__logo">
            <img src={getAvatarSrc(logoSrc)} alt="" />
          </div>
        )}
      </div>
    </section>
  );
};

export default AboutPage;
