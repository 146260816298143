import { useEffect, useState } from "react";
import { Button, Loader } from "@crowd/ui-kit";

import "./ProposalHistory.scss";
import { ProposalInterface } from "../../../../../types/Proposal.interface";
import PersonalService from "../../../../../services/personalService";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { useDispatch } from "react-redux";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import ProposalHistoryCard from "./ProposalHistoryCard/ProposalHistoryCard";

interface ProposalHistoryProps {
  proposal: ProposalInterface;
}

const ProposalHistory = ({ proposal }: ProposalHistoryProps) => {
  const dispatch = useDispatch();

  const [foldedOut, setFoldedOut] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [historyList, setHistoryList] = useState(null);

  useEffect(() => {
    if (!historyList) {
      if (foldedOut) {
        fetchProposal(proposal);
      }
    }
  }, [foldedOut]);

  const fetchProposal = async (proposal) => {
    setIsFetching(true);
    try {
      const { status, message, data } = await PersonalService.getEditedHistory(proposal.id);
      if (status === ApiStatusCode.OK) {
        setHistoryList(data);
      } else {
        throw new Error(message);
      }
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    } finally {
      setIsFetching(false);
    }
  };

  const renderContent = () => {
    if (!foldedOut) return;
    return historyList?.map((item) => <ProposalHistoryCard key={item.id} proposal={item} />);
  };

  if (!proposal.hasEdited) return null;

  return (
    <div className="proposal-history">
      <div className="proposal-history__header">
        <Button
          type={"outlined"}
          size="m"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setFoldedOut(!foldedOut);
          }}
          icon={<span className={`ui-icon-chevron-${foldedOut ? "up" : "down"}`}></span>}
          text="История изменения текста идеи"
        />
        {isFetching && (
          <div className="proposal-history__loader">
            <Loader />
          </div>
        )}
      </div>
      <div className="proposal-history__body">{renderContent()}</div>
    </div>
  );
};

export default ProposalHistory;
