import { Checkbox } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { SELECTION_SET_IS_FORWARDED_SHOW_ALL } from "../../../../../store/actions/SelectionActions";
import "./SelectionForwardedShowAll.scss";
import { profileUserDetailsSelector } from "../../../../../store/selectors/profile";
import { UserRole } from "../../../../../types/User.interface";
import { useEffect } from "react";

export function SelectionForwardedShowAll() {
  const dispatch = useDispatch();
  const currentUser = useSelector(profileUserDetailsSelector);

  const handleChange = (checked: boolean) => {
    dispatch({ type: SELECTION_SET_IS_FORWARDED_SHOW_ALL, payload: checked });
  };

  useEffect(() => {
    dispatch({ type: SELECTION_SET_IS_FORWARDED_SHOW_ALL, payload: false });

    return () => {
      dispatch({ type: SELECTION_SET_IS_FORWARDED_SHOW_ALL, payload: false });
    };
  }, []);

  if (!currentUser.roles.includes(UserRole.CHIEF_EXPERT)) return null;

  return (
    <div className="selection-forwarded-show-all">
      <Checkbox html="Показать все переданные идеи" onChange={handleChange} />
    </div>
  );
}
