import React from "react";

import { User } from "../../../types/User.interface";
import "./UserCrumb.scss";

export interface UserCrumbProps {
  user?: User;
  onRemove: Function;
}

const UserCrumb = ({ user, onRemove }: UserCrumbProps) => {
  return (
    <div className="user-crumb">
      <div className="user-crumb__name">
        {user.firstName + " " + user.lastName}
      </div>
      <div className="ui-icon-close" onClick={() => onRemove(user)}></div>
    </div>
  );
};

export default UserCrumb;
