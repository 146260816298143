import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ApiStatusCode,
  PagingResponseParameters,
} from "../../../../../types/Common.interface";
import useQuery from "../../../../../utils/hooks/useQuery";
import BlogService from "../../../../../services/blogService";
import PersonalPostCard from "./PersonalPostCard/PersonalPostCard";
import { BlogPost } from "../../../../../types/BlogPost.interface";
import PageTitle from "../../../../presentational/PageTitle/PageTitle";
import PersonalPagination from "../../PersonalPagination/PersonalPagination";
import ContentLoader from "../../../../presentational/Loaders/ContentLoader/ContentLoader";
import "./PersonalBlog.scss";
import { scrollToTop } from "../../../../../utils";

const COUNT_PER_PAGE = 10;

const PersonalBlog = () => {
  const query = useQuery();
  const page: number = isNaN(Number.parseInt(query.page))
    ? 0
    : Number.parseInt(query.page) - 1;
  const { userId } = useParams<{ userId: string }>();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [paging, setPaging] = useState<PagingResponseParameters>({
    pageSize: COUNT_PER_PAGE,
    pageNumber: page,
  });
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadPosts(currentPage).then();
  }, []);

  const loadPosts = async (curPageNum: number) => {
    setLoading(true);

    const postsResp = await BlogService.getBlogPostsByUserId(userId, {
      size: COUNT_PER_PAGE,
      page: curPageNum,
    });

    if (postsResp.status === ApiStatusCode.OK) {
      setPosts(postsResp.data);
      setPaging(postsResp.paging);
      setCurrentPage(curPageNum);
      scrollToTop();
    } else {
      console.warn(postsResp);
    }

    setLoading(false);
  };

  return (
    <div className="personal-blog">
      <PageTitle text={"Личный блог"} />

      <ContentLoader isLoading={isLoading}>
        {posts.length === 0 && (
          <div className="empty-content">
            У участника отсутствуют опубликованные записи
          </div>
        )}

        {posts.length > 0 && (
          <div className="personal-blog__posts">
            {posts.map((post) => (
              <div className="personal-blog__posts__post" key={post?.id}>
                <PersonalPostCard
                  post={post}
                  userId={userId}
                ></PersonalPostCard>
              </div>
            ))}
          </div>
        )}
      </ContentLoader>

      <PersonalPagination
        paging={paging}
        onPageChange={loadPosts}
        paginationName={"page"}
        curPageNum={currentPage}
      ></PersonalPagination>
    </div>
  );
};

export default PersonalBlog;
