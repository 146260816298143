// @flow
import {
  DELETE_NOTIFICATION_SUCCESS,
  NOTIFICATIONS_COUNT_ALL_UNREAD,
  NOTIFICATIONS_GET_PAGINATED,
  READ_NOTIFICATION_SUCCESS,
  DECREMENT_UNREAD_DIALOG_MESSAGE_COUNT
} from "../actions/NotificationsActions";
import { NotificationsState } from "../../types/Notifications.interface";

const initialState = {
  countAllUnread: null,
  notifications: [],
  pagination: null,
  error: "",
  isFetching: false,
};

export function notificationsReducer(state: NotificationsState = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_COUNT_ALL_UNREAD:
      return {
        ...state,
        countAllUnread: action.payload,
      };

    case READ_NOTIFICATION_SUCCESS:
      const readIDS = action.payload.ids;
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          readIDS.includes(notification.id) ? { ...notification, processed: true } : notification
        ),
      };

    case DELETE_NOTIFICATION_SUCCESS:
      const deletedID = action.payload.id;
      return {
        ...state,
        notifications: [...state.notifications.filter((notification) => notification.id !== deletedID)],
      };

    case NOTIFICATIONS_GET_PAGINATED:
      return {
        ...state,
        notifications: Array.isArray(state.notifications) ? [...state.notifications, ...action.payload.data] : action.payload.data,
        pagination: action.payload.paging,
      };

    case DECREMENT_UNREAD_DIALOG_MESSAGE_COUNT:
      return {
        ...state,
        countAllUnread: {
          ...state.countAllUnread,
          unreadDialogMessageCount: state.countAllUnread.unreadDialogMessageCount - action.payload,
        },
      };

    default:
      return state;
  }
}
