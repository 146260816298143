import axios from "axios";
import React from "react";

import { store } from "../store/store";
import { getUser, logout } from "../store/actions/UserActions";
import { ApiStatusCode } from "../types/Common.interface";
import { showErrorToast } from "../store/actions/ToastActions";
import { showMaintenanceModal } from "../store/actions/LayoutActions";

export const ERR_MSG = "При обращении к серверу произошла ошибка. Пожалуйста, повторите попытку позже.";

const redirectPaths = ["/signInFail", "/notStarted", "/changePassword"];

export const API = axios.create({
  headers: {
    Accept: "application/json",
    "crowd-app": "platform"
  },
  timeout: 100000,
});

API.interceptors.request.use(
  (config) => {
    // axios doesn't set content-type on get (cause there is no data), but sometimes backend requires it
    // data = {} fixes that
    if (config.method === "get") {
      config.data = {};
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (res) => {
    const redirectPathName = redirectPaths.find((lc) => res.request.responseURL?.includes(lc));
    if (redirectPathName) {
      if (window.location.pathname !== redirectPathName) {
        window.location.href = res.request.responseURL;
        return;
      }
    }

    if (res.data.status === ApiStatusCode.NEED_TO_PASSWORD_CHANGE) {
      const redirectPath = `${window.location.protocol}//${window.location.host}/changepassword`;
        if (window.location.href !== redirectPath) {
          window.location.href = redirectPath;
        }
    }

    if (res.data.status === ApiStatusCode.SIGNED_OUT) {
      store.dispatch(logout());
    }

    if (res.data.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
      store.dispatch(getUser());
    }

    return res;
  },
  (err) => {
    //handle 404
    if (err.toString().includes("404")) {
      return;
    }

    //No backend
    if (err.toString().includes("504")) {
      return (document.location.href = document.location.origin + "/unreachable.html");
    }

    //handle 502
    if (err.toString().includes("502")) {
      document.location.href = document.location.origin + "/construction.html";
      return;
    }

    store.dispatch(showErrorToast(ERR_MSG));
    // store.dispatch(showMaintenanceModal());
  }
);

export function httpParamsSerialize(params) {
  if (!params) return "";
  const esc = encodeURIComponent;
  let query = Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");

  return query;
}
