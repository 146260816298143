import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";

import "./Carousel.scss";

SwiperCore.use([Navigation]);

interface Props {
  items: any[];
  children: JSX.Element;
  bindTo?: string;
  spaceBetween?: number;
  slidesPerView?: number;
  slidesPerColumn?: number;
  loop: boolean;
  navClass?: string;
  onSlideChange?: () => {};
  initialSlide?: number;
  disableSwipe?: boolean;
  bottomNav?: boolean;
}

const Carousel = (props: Props) => {
  const handleSlideChange = () => {
    if (props.onSlideChange && typeof props.onSlideChange === "function") {
      props.onSlideChange();
    }
  };

  const getNavigation = () => {
    if (props.navClass) {
      return {
        nextEl: `.${props.navClass} .carousel-slider__next`,
        prevEl: `.${props.navClass} .carousel-slider__prev`,
      };
    } else {
      return false;
    }
  };

  return (
    <>
      {!props.disableSwipe && (
        <div className="carousel-slider">
          {props.navClass && (
            <div
              className={
                "ui-icon ui-icon-arrow-slider-left carousel-slider__prev"
              }
              key="prev"
            ></div>
          )}
          <Swiper
            slidesPerView={props.slidesPerView}
            slidesPerColumn={props.slidesPerColumn}
            slidesPerColumnFill={"row"}
            loop={props.loop}
            navigation={getNavigation()}
            onSlideChange={handleSlideChange}
            initialSlide={props.initialSlide || 0}
            autoHeight={props.bottomNav}
          >
            {props.items.map((item, key) => (
              <SwiperSlide key={key}>
                {React.createElement(props.children.type, {
                  [props.bindTo || "item"]: item,
                  ...props.children.props,
                })}
              </SwiperSlide>
            ))}
          </Swiper>
          {props.navClass && (
            <div
              className={
                "ui-icon ui-icon-arrow-slider-right carousel-slider__next"
              }
              key="next"
            ></div>
          )}
        </div>
      )}

      {props.disableSwipe && (
        <div className={"carousel-one-page"}>
          {props.items.map((item, key) => (
            <div
              className="carousel-one-page__item"
              key={key}
              style={{
                width: `calc(100% / ${props.slidesPerView})`,
              }}
            >
              {React.createElement(props.children.type, {
                [props.bindTo || "item"]: item,
                ...props.children.props,
              })}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Carousel;
