import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useParams, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ProposalTypeEnum,
  ProposalsRequestParams,
  VotingPageParams,
} from "../../../types/Voting.interface";
import { FilterState } from "../../../types/Filter.interface";
import { RootState } from "../../../types/State.interface";
import { PagingResponseParameters } from "../../../types/Common.interface";
import { Stage } from "../../../types/Stage.interface";

import VotingService from "../../../services/votingService";
import StageService from "../../../services/stageService";
import {
  showInfoModal,
  hideInfoModal,
  setAppColor,
  showHeader,
  showFooter,
} from "../../../store/actions/LayoutActions";

import { getUrlParam, setUrlParam } from "../../../utils/urlUtils";

import StageHeader from "../../presentational/StageHeader/StageHeader";
import ItemLifecycle from "../../presentational/ItemLifecycle/ItemLifecycle";
import ItemStats from "../../presentational/ItemStats/ItemStats";
import StageSelectorModal from "../../containers/Modals/StageSelectorModal/StageSelectorModal";
import VotingProposalList from "./VotingProposalList/VotingProposalList";

import Tabs, { Tab } from "../../presentational/Tabs/Tabs";
import "./Voting.scss";
import { Button, Loader } from "@crowd/ui-kit";
import { AppColorsEnum } from "../../../App.interface";
import {
  phoneWidth,
  tabletWidth,
} from "../../../utils/constants/widthConstants";
import { VotingFilter } from "./VotingFilter/VotingFIlter";
import { displayError } from "../../../utils";

const VotingPage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isPC = !isPhone && !isTablet;
  const initialStageId = useParams<VotingPageParams>().stageId;
  const [isLoading, setIsLoading] = useState(true);
  const [proposals, setProposals] = useState([]);
  const [pagingData, setPagingData] = useState<PagingResponseParameters>({
    totalPages: 0,
  });
  const PAGE_SIZE = 20;
  const projectStatistics = useSelector(
    (state: RootState) => state.project.statistic
  );
  const [showStageStatistics, setShowStageStatistics] = useState(false);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const isExpertOrTeamMember = currentUser.expert || currentUser.teamMember;
  const [dynamicStageVotesCount, setDynamicStageVotesCount] = useState(0);
  const [filterState, setFilterState] = useState<FilterState>({});
  const [filterItemValue, setFilterItemValue] = useState<string>("");
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [isSearchFilterParams, setIsSearchFilterParams] = useState(false)

  const getVotingFolders = (counters?: any): Tab[] => {
    return [
      {
        id: "PENDING",
        name: "Неоцененные",
        show: true,
        count: counters?.votable || 0,
        iconClass: "ui-icon-idea-pending",
        activeIconClass: "ui-icon-idea-pending-filled",
      },
      {
        id: "VOTED",
        name: "Оцененные",
        show: true,
        count: counters?.voted || 0,
        iconClass: "ui-icon-idea-voted",
        activeIconClass: "ui-icon-idea-voted-filled",
      },
      {
        id: "SKIPPED",
        name: "Отложенные",
        show: true,
        count: counters?.skipped || 0,
        iconClass: "ui-icon-idea-skipped",
        activeIconClass: "ui-icon-idea-skipped-filled",
      },
      {
        id: "MINE",
        name: "Мои идеи",
        show: true,
        count: counters?.mine || 0,
        iconClass: "ui-icon-idea-my",
      },
    ];
  };
  const getVotingFolderById = (id) => {
    return votingFolders.find((folder) => folder.id === id);
  };

  const getInitialFolder = (): ProposalTypeEnum => {
    const type: any = getUrlParam("type", location.search) || "";
    if (
      !isExpertOrTeamMember &&
      Object.values(ProposalTypeEnum).includes(type)
    ) {
      return ProposalTypeEnum[type];
    } else {
      return ProposalTypeEnum.PENDING;
    }
  };
  const allStages = useSelector((state: RootState) => state.stage.allStages);
  const [votingFolders, setVotingFolders] = useState(getVotingFolders());
  const [currentFolderId, setCurrentFolderId] = useState<ProposalTypeEnum>(
    getInitialFolder()
  );
  const [stageId, setStageId] = useState(initialStageId);
  const [stage, setStage] = useState<any>(null);
  const [otherVotingStages, setOtherVotingStages] = useState<any>([]);
  const [votingStageSelectorModalOpen, setVotingStageSelectorModalOpen] =
    useState(false);
  const [votingStageSelectorText, setVotingStageSelectorText] = useState({
    header: "",
    message: "",
    listHeader: "",
  });
  const [votingStageSelectorButtons, setVotingStageSelectorButtons] =
    useState<JSX.Element>(<></>);
  const [isAfterVote, setIsAfterVote] = useState(false);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.LIGHTBLUE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  // INIT
  // TODO prevent page load if not logged in/not allowed
 
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterValue = searchParams.get('filter');

    if (filterValue && !filterItemValue) {
      setIsSearchFilterParams(true);
      setFilterItemValue(filterValue);
      setIsFiltered(true);
    } else {
      loadProposals();
    }
  }, [stageId, currentFolderId, isSearchFilterParams]);
 
  useEffect(() => {
    const _votingStages = allStages.filter(
      (_stage) =>
        _stage.type === "VOTING" &&
        _stage.status === "STARTED" &&
        _stage.id !== stageId
    );

    setOtherVotingStages(_votingStages);
  }, [allStages, stageId]);

  useEffect(() => {
    try {
      const fetchFilter = async (id) => {
        const { data: allStages } = await StageService.getStages();
        
        const targetStage = allStages.find(stage => stage.id === id);
        if (!targetStage) return;
    
        const relatedStage = allStages.find(stage =>
          stage.node?.id === targetStage.node.id && stage.type === 'GENERATION'
        );
        if (!relatedStage) return;
    
        const listItem = relatedStage.taskSpecification?.items.find(item => item.type === 'LIST');
        if (!listItem?.filter) return;
    
        const { filter } = listItem;
        setFilterState({
          showFilter: filter.showVoting,
          title: filter.value ? filter.value : listItem.title,
          items: listItem.items,
        });
      };
      fetchFilter(stageId);
    } catch (error) {
      displayError('Fetch filter Error:', error)
    }

  }, [stageId])

  const renderButtons = (buttons, forStageSelector?, noDefaultButton?) => {
    const callButtonAction = (button) => {
      if (button.url) {
        history.push(button.url);
      } else if (button.action) {
        button.action();
      }
    };

    if (buttons.length === 0) return <></>;

    return (
      <>
        {buttons.map((button, key) => (
          <Button
            type={button.type || "filled"}
            text={button.label}
            onClick={() => callButtonAction(button)}
            size="m"
            key={key}
          />
        ))}
        {!noDefaultButton && (
          <Button
            type="outlined"
            text="Закрыть"
            onClick={() => {
              if (forStageSelector) {
                setVotingStageSelectorModalOpen(false);
              }

              dispatch(hideInfoModal());
            }}
            size="m"
            key="close"
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (isAfterVote) {
      setIsAfterVote(false);
      if (isFiltered && proposals?.length === 0) {
        afterLastFilteredVoteModalCall()
      };
      const currentProposals = getVotingFolderById(currentFolderId)?.count || 0;
      const noProposalsLeftInFolder = currentProposals === 0;
      if (noProposalsLeftInFolder) afterLastVoteModalCall();
    }
    const pendingProposals = getVotingFolderById(ProposalTypeEnum.PENDING)?.count || 0;
    const skippedProposals = getVotingFolderById(ProposalTypeEnum.SKIPPED)?.count || 0;
    const noProposalsLeft = pendingProposals === 0 && skippedProposals === 0;
    setShowStageStatistics(noProposalsLeft);
  }, [votingFolders]);

  const loadProposals = () => {
    if (!stageId) return;

    setIsAfterVote(!!isAfterVote);

    const params: ProposalsRequestParams = {
      stageId,
      type: currentFolderId,
      size: PAGE_SIZE,
      page: 0,
    };

    if (isFiltered) {
      params.filterItemValue = filterItemValue;
    }

    setIsLoading(true);

    const requests = [
      VotingService.getProposalsByStageId(params),
      VotingService.getProposalFolderCounters(stageId),
    ];

    if (!stage || stageId !== stage.id)
      requests.push(StageService.getStages(stageId));

    Promise.all(requests)
      .then(([proposals, counters, stage]) => {
        if (stage && stage.data) {
          setStage(stage.data);
          setDynamicStageVotesCount(stage.data.stats?.hitCount || 0);
        }
        setProposals(proposals.data);
        setPagingData(proposals.paging);
        setVotingFolders(getVotingFolders(counters.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  const preloadProposalsOnScroll = (updateExistingProposals, isAfterVote?) => {
    if (!stageId) return;

    setIsAfterVote(!!isAfterVote);

    const params: ProposalsRequestParams = {
      stageId,
      type: currentFolderId,
    };

    if (isFiltered) {
      params.filterItemValue = filterItemValue;
    }

    const currentPage = pagingData.pageNumber;

    if (updateExistingProposals) {
      params.size = proposals.length;
      params.page = 0;
    } else {
      params.size = PAGE_SIZE;
      params.page = currentPage + 1;
    }

    VotingService.getProposalsByStageId(params)
      .then((response) => {
        if (updateExistingProposals) {
          setProposals(response.data);
          updateFolderCounters();
        } else {
          setProposals([...proposals, ...response.data]);
          setPagingData(response.paging);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const updateFolderCounters = () => {
    if (!stageId) return;

    VotingService.getProposalFolderCounters(stageId)
      .then((response) => {
        setVotingFolders(getVotingFolders(response.data));
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const afterLastFilteredVoteModalCall = () => {
    const onInfoModalClose = () => {
      onFilterClear();
      setFilterItemValue('');
      dispatch(hideInfoModal());
      setIsLoading(true);
    }
    if (isFiltered && currentFolderId === ProposalTypeEnum.PENDING && proposals.length === 0) {
      const message = "Спасибо! Вы оценили все выбранные идеи";
      const buttons = [
        {
          label: "Закрыть",
          action: onInfoModalClose,
        }
      ];
  
      dispatch(showInfoModal(message, null, renderButtons(buttons, null, true)));
    }
  }

  const afterLastVoteModalCall = () => {
    let buttons: {}[] = [];
    let message = "";
    const PENDING = ProposalTypeEnum.PENDING;
    const SKIPPED = ProposalTypeEnum.SKIPPED;
    const VOTED = ProposalTypeEnum.VOTED;
    const pendingFolder = getVotingFolderById(PENDING);
    const skippedFolder = getVotingFolderById(SKIPPED);
    const votedFolder = getVotingFolderById(VOTED);
    let noDefaultButton = false;

    if (currentFolderId === PENDING) {
      
      if (skippedFolder?.count > 0) {
        // Если есть отложенные идеи и пользователь оценил все идеи в папке "Неоцененные"
        message =
          "Все идеи в этой вкладке оценены. Вы хотите перейти к списку отложенных идей?";
        buttons = [
          {
            label: "Перейти к списку",
            action: () => onFolderClick(skippedFolder),
          },
        ];
      } else {
        // Пользователь оценил все сообщения в папке "Неоцененные", папка "Отложенные" пустая
        message = "Спасибо, вы оценили все идеи.";
        buttons = [
          {
            label: "Хорошо",
            action: () => {
              dispatch(hideInfoModal());
              history.push("/");
            },
          },
        ];
        noDefaultButton = true;
      }
    } else if (currentFolderId === SKIPPED) {
      if (pendingFolder?.count > 0) {
        // Если в папке "Отложенные" оцениваем последнюю отложенную идею, и есть другие в папке "Неоцененные"
        message =
          "Больше нет отложенных идей. Вы хотите перейти к списку неоцененных идей?";
        buttons = [
          {
            label: "Перейти к списку",
            action: () => onFolderClick(pendingFolder),
          },
        ];
      } else {
        // В папке "Неоцененные" не осталось идей, и пользователь оценил все идеи в папке "Отложенные"
        message =
          "У вас больше нет отложенных идей. Хотите перейти к другим идеям?";
        buttons = [
          {
            label: "К оцененным идеям",
            action: () => onFolderClick(votedFolder),
          },
        ];
      }
    }

    if (message && buttons.length > 0) {
      if (otherVotingStages.length > 0) {
        const listHeader = "Оценить идеи по теме:";
        setVotingStageSelectorButtons(renderButtons(buttons, true));
        setVotingStageSelectorText({ header: "", message, listHeader });
        setVotingStageSelectorModalOpen(true);
      } else {
        dispatch(
          showInfoModal(message, null, renderButtons(buttons, noDefaultButton))
        );
      }
    }
  };

  const onFolderClick = (folder) => {
    if (folder.id !== "PENDING") {
      resetFilter();
    }
    setCurrentFolderId(ProposalTypeEnum[folder.id]);
    setUrlParam("type", folder.id, history);
    setVotingStageSelectorModalOpen(false);
    dispatch(hideInfoModal());
  };

  const renderChangeTopicButton = () => {
    const stageSelectorText = {
      header: "Выбор темы",
      message:
        "Вы можете выбрать тему, по которой доступна оценка идей, из предложенного ниже списка.",
      listHeader: "Оценить идеи по теме:",
    };

    return (
      currentUser?.participant &&
      otherVotingStages.length > 0 && (
        <div className="voting__change-topic-button">
          <Button
            type="outlined"
            text="Оценить по другой теме"
            onClick={() => {
              setVotingStageSelectorText(stageSelectorText);
              setVotingStageSelectorModalOpen(true);
            }}
            size="m"
          />
        </div>
      )
    );
  };
  
  const onFilterClear = async () => {
    try {
      const params: ProposalsRequestParams = {
        stageId,
        type: currentFolderId,
        size: PAGE_SIZE,
        page: 0,
      }
      setIsLoading(true)
      const response = await VotingService.getProposalsByStageId(params);
      setProposals(response.data);
      setPagingData(response.paging);
      resetFilter();
    } catch (error) {
      displayError("GET Proposals Error:", error)
    } finally {
      setIsLoading(false);
    }
  }

  const onFilterSearch = async (selectedValue) => {
    try {
      setFilterItemValue(selectedValue);
      const params: ProposalsRequestParams = {
        stageId,
        type: currentFolderId,
        size: PAGE_SIZE,
        filterItemValue: selectedValue,
        page: 0,
      };
      setIsLoading(true)
      const response = await VotingService.getProposalsByStageId(params);
      setProposals(response.data);
      setPagingData(response.paging);
      setIsFiltered(true);
      
      const currentSearchParams = new URLSearchParams(history.location.search);
      currentSearchParams.set('filter', selectedValue);
      history.push({ pathname: history.location.pathname, search: currentSearchParams.toString() });
    } catch (error) {
      displayError("GET Proposals Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const resetFilter = () => {
    setIsFiltered(false);
    setFilterItemValue('');

    const currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.delete('filter');
    history.push({ search: currentSearchParams.toString() });
  }

  const renderFilter = (filterState, onSearch, onClear) => {
    const {title, showFilter, items } = filterState;
    const handleFilterApply = (selectedValue) => {
      onSearch(selectedValue);
    }
    const handleFilterClear = () => {
      onClear()
    }

    if (showFilter) {
      return (
        <VotingFilter
          title={title}
          items={items.map((item) => item.value)}
          placeholder="Выберите значение из списка"
          onFilterApply={handleFilterApply}
          onFilterClear={handleFilterClear}
          isFiltered={isFiltered}
          setFilterItemValue={setFilterItemValue}
          filterItemValue={filterItemValue}
        />
      );
    }
  }

  const renderActionBar = () => {
    return (
      <div
        className={`voting__action-bar ${
          stage.status === "STARTED" && !isExpertOrTeamMember ? "" : "no-tabs"
        }`}
      >
        {stage.status === "NEW" && (
          <div className="voting__action-bar__stage-announce--wrapper">
            <div className="voting__action-bar__stage-announce">
              Этап ещё не стартовал
            </div>
            {isPhone && renderChangeTopicButton()}
          </div>
        )}

        {stage.status === "STARTED" && !isExpertOrTeamMember && (
          <Tabs
            currentTabId={currentFolderId}
            tabs={votingFolders.filter((folder) => folder.show)}
            onTabClick={onFolderClick}
            isUseMobileTab={true}
          />
        )}

        {stage.status === "STARTED" && isExpertOrTeamMember && (
          <div className="voting__is-team-member">
            Вы не можете голосовать, так как представляете команду проекта.
          </div>
        )}

        {stage.status === "FINISHED" && (
          <div className="voting__action-bar__stage-finished--wrapper">
            <div className="voting__action-bar__stage-finished">
              Этап завершен!
            </div>
            {isPhone && renderChangeTopicButton()}
          </div>
        )}

        {!isPhone && renderChangeTopicButton()}
      </div>
    );
  };

  const showItemLifeCycle = () => {
    const finished = stage.status === "FINISHED";
    return !finished || (finished && isPC);
  };

  return (
    <section className="voting">
      {isLoading && <Loader />}
      
      {stage && (
        <StageHeader
          title={stage.title}
          type="VOTING"
          description={stage.description}
        >
          {showItemLifeCycle() && <ItemLifecycle item={stage} type={"STAGE"} />}
          <ItemStats
            icon={"done"}
            count={dynamicStageVotesCount}
            type={"VOTES"}
          />
        </StageHeader>
      )}

      {stage?.status && renderActionBar()}
      
      {stage?.status && currentFolderId === ProposalTypeEnum.PENDING
      && renderFilter(filterState, onFilterSearch, onFilterClear)}
      <div className="stage-body-wrapper">
        {stage && stage.status === "STARTED" && (
          <>
            <VotingProposalList
              proposals={proposals}
              currentFolderId={currentFolderId}
              pagingData={pagingData}
              stage={stage}
              isExpertOrTeamMember={isExpertOrTeamMember}
              onPageClick={loadProposals}
              onInfiniteScroll={preloadProposalsOnScroll}
              afterNewVote={() =>
                setDynamicStageVotesCount(dynamicStageVotesCount + 1)
              }
              isLoading={isLoading}
              isFiltered={isFiltered}
            />
            {isPhone && renderChangeTopicButton()}
          </>
        )}

        {/* {stage &&
          showStageStatistics &&
          (projectStatistics?.stages[stage.id] ? (
            <StageStats
              type={"VOTING"}
              stageStatistics={projectStatistics.stages[stage.id]}
              currentUser={currentUser}
            />
          ) : (
            <Loader />
          ))} */}
      </div>

      <StageSelectorModal
        isOpen={votingStageSelectorModalOpen}
        close={() => {
          setVotingStageSelectorButtons(<></>);
          setVotingStageSelectorModalOpen(false);
        }}
        header={votingStageSelectorText.header}
        message={votingStageSelectorText.message}
        listHeader={votingStageSelectorText.listHeader}
        onSelect={(stage) => {
          setFilterState({})
          resetFilter();
          setStageId(stage.id);
          setCurrentFolderId(ProposalTypeEnum.PENDING);
        }}
        getStageLink={(stage: Stage) => `/voting/${stage.id}`}
        stages={otherVotingStages}
        buttons={votingStageSelectorButtons}
      />
    </section>
  );
};

export default VotingPage;
