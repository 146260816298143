import React, { useEffect } from "react";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

import "./CaptchaMosRu.scss";

interface Props {
  captchaValue: string;
  captchaImageBase64: string;
  setCaptchaValue: (val: string) => void;
  refreshCaptcha: () => void;
}

const CaptchaMosRu = ({ captchaValue, captchaImageBase64, setCaptchaValue, refreshCaptcha }: Props) => {
  useEffect(() => {
    refreshCaptcha();
  }, []);

  // RENDER

  return (
    <div className="captcha-mosru">
      <EventsTrackWrapperClick id={["ENTER_TEXT", "CLICK"]} params={{ replace: ["Проверочный код"], partial: true, rememberPrev: true }}>
        <input
          value={captchaValue}
          onChange={(e) => {
            setCaptchaValue(e.target.value);
          }}
          type="text"
          placeholder="Проверочный код"
          className="cr-input__input"
        ></input>
      </EventsTrackWrapperClick>
      <img src={captchaImageBase64} alt="Проверочный код" />
      <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: ["Обновить код"], partial: true, rememberPrev: true }}>
        <div className="refresh" onClick={refreshCaptcha}>
          <span className="ui-icon ui-icon-refresh"></span>
          <span className="refresh__text">Обновить код</span>
        </div>
      </EventsTrackWrapperClick>
    </div>
  );
};

export default CaptchaMosRu;
