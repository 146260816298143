import React, { useEffect, useState } from "react";

import "./DialogCard.scss";
import UserAvatar from "../../../../../presentational/UserAvatar/UserAvatar";
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday'
import { Link } from "react-router-dom";
import { ChatMessageStatus, Dialog } from "../Chat/chat.types";
import { User } from "../../../../../../types/User.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";
import { getTitleByStatus } from "../Chat/chat.utils";
import { getDisplayName } from "../../../../../../utils/user.utils";

dayjs.extend(isToday);
interface DialogCardProps {
  dialog: Dialog;
  isDeleting: boolean;
  onDelete: (dialog) => void;
}

const DialogCard: React.FC<DialogCardProps> = ({ dialog, isDeleting, onDelete }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const getUnreadCount = (dialog) => (dialog?.unreadCount > 99 ? "99+" : dialog.unreadCount);

  const formatDate = (date) => {
    const parsedDate = dayjs(date);
    return parsedDate.isToday() ? parsedDate.format("HH:mm") : parsedDate.format("DD.MM.YYYY");
  };

  return (
    <Link to={`/profile/mail/${dialog.id}`} className={classNames("dialog-card", { "dialog-card--deleting": isDeleting })}>
      <div className="dialog-card__wrapper">
        <UserAvatar user={dialog.peer} size={isPhone ? "XS" : "M"} />
        <div className="dialog-card__message">
          <div className="dialog-card__name">{getDisplayName(dialog.peer)}</div>
          <div className="dialog-card__text">
            {getTitleByStatus(dialog.lastMessageStatus, dialog.lastMessageText)}
          </div>
        </div>
        <div className="dialog-card__info">
          <div className="dialog-card__time">{formatDate(dialog?.lastMessageCreated)}</div>
          {Boolean(dialog.unreadCount) && <div className="dialog-card__count">{getUnreadCount(dialog)}</div>}
        </div>
        <div className="dialog-card__tools">
          <button
            title="Удалить"
            className="dialog-card__tools-delete simple-btn"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onDelete(dialog);
            }}
          >
            <span className="ui-icon ui-icon-delete"></span>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default DialogCard;
