import { FC } from "react";
import UserAvatar from "../UserAvatar/UserAvatar";
import { UserInfoProps } from "./UserInfo.types";
import "./UserInfo.scss";
import { relativeTime } from "../../../utils";
import { UserRoleLabels } from "../../../utils/constants/user";
import Tooltip from "../Tooltip/Tooltip";
import { getDisplayName } from "../../../utils/user.utils";
import { useIsMobile } from "../../../utils/hooks/useMedia";
import cn from "classnames";

const UserInfo: FC<UserInfoProps> = ({ addNameText, addInfoType, user, pictureUrl, iconButton, size }) => {
  const isMobile = useIsMobile();

  const renderAddInfo = () => {
    const cls = "user-info__add-info user-info__sub-text";
    switch (addInfoType) {
      case "last-visit":
        return <div className={cls}>{user.online ? "Онлайн" : (user.lastActivityDate && relativeTime(user.lastActivityDate)) || ""}</div>;

      case "role":
        return <div className={cls}>{UserRoleLabels[user.highestRole]}</div>;

      default:
        return;
    }
  };

  const renderIconButton = () => {
    if (!iconButton) return null;

    const button = <button className={`${iconButton.icon} user-info__icon-button`} onClick={iconButton?.callback}></button>;
    return (
      <div className="user-info__icon-button-wrapper">
        {iconButton.tooltip ? (
          <Tooltip text={iconButton.tooltip?.text} idx={"UserInfoIconTooltip"}>
            {button}
          </Tooltip>
        ) : (
          button
        )}
      </div>
    );
  };

  return (
    <div className={cn("user-info", { "user-info--icon-button": iconButton })}>
      <UserAvatar user={user} pictureUrl={pictureUrl} size={size ?? (isMobile ? "XS" : "M")} />
      <div className="user-info__name">
        <div className="user-info__name-text">{getDisplayName(user)}</div>
        {!!addNameText && <span className="user-info__sub-text">{addNameText}</span>}
      </div>
      {renderAddInfo()}
      {renderIconButton()}
      <div></div>
    </div>
  );
};

export default UserInfo;
