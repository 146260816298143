import { useDispatch, useSelector } from "react-redux";
import { SELECTION_SET_IDEAS_CURRENT_PAGE } from "../../../../../store/actions/SelectionActions";
import {
  selectionIdeasPaginationSelector,
  selectionIsStageFinishedSelector,
  selectionPageSelector,
} from "../../../../../store/selectors/selection";
import { scrollToTop } from "../../../../../utils";
import Pagination from "../../../../presentational/Pagination/Pagination";

export function SelectionPagination() {
  const dispatch = useDispatch();

  const ideasPagination = useSelector(selectionIdeasPaginationSelector);
  const isStageFinished = useSelector(selectionIsStageFinishedSelector);
  const page = useSelector(selectionPageSelector);

  const handlePageChange = (event) => {
    dispatch({ type: SELECTION_SET_IDEAS_CURRENT_PAGE, payload: event.selected });
    scrollToTop();
  };

  return (
    ideasPagination?.totalElements > 0 &&
    !isStageFinished && (
      <Pagination totalPages={ideasPagination?.totalPages || 0} onPageChange={handlePageChange} currentPage={page} pageName="page" />
    )
  );
}
