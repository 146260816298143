import React from "react";
import AttachmentView from "../AttachmentView";
import { Attachment } from "../../../../../../../../../types/Common.interface";
import "./ImageView.scss";

export interface ImageViewProps {
  file: Attachment;
}

const ImageView = ({ file }: ImageViewProps) => {
  return (
    <AttachmentView file={file}>
      <div className="image-view">
        <img key={file.id} className="image-view__image" src={`/uploads/thumbnails/get/${file.id}`} alt={file.fileName} />
      </div>
    </AttachmentView>
  );
};

export default ImageView;
