import React, { PropsWithChildren} from "react";
import "./DialogList.scss";

interface DialogListProps {}

const DialogList: React.FC<PropsWithChildren<DialogListProps>> = ({ children }) => {
  return (
    <div className="dialog-list">
      {children}
    </div>
  );
};

export default DialogList;
