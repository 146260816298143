import React from "react";
import { Modal } from "@crowd/ui-kit";

import "./InfoModal.scss";

interface Props {
  isOpened: boolean;
  onClose: () => {};
  title?: string;
  message: string;
  children: React.ReactNode;
  classNames?: string;
}
const InfoModal = (props: Props) => {
  return (
    <Modal
      classNames={`info-modal ${props.classNames || ""}`}
      root="info-modal__root"
      isOpen={!!props.isOpened}
      onClose={props.onClose}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <React.Fragment key={"header"}>{props.title}</React.Fragment>

      <React.Fragment key={"subhead"}>
        <div className="info-modal__message" dangerouslySetInnerHTML={{ __html: props.message }}></div>
      </React.Fragment>

      <React.Fragment key={"footer"}>
        <div className="info-modal__buttons">{props.children}</div>
      </React.Fragment>
    </Modal>
  );
};

export default InfoModal;
