import React from "react";

import "./MobileTab.scss";
import { Tab } from "../Tabs";

interface TabProps {
  tab: Tab;
  currentTabId: string | number;
  onTabClick: (tab: Tab) => void;
}

const MobileTab = ({ tab, currentTabId, onTabClick }: TabProps) => {
  const getIconClass = (tab): string => {
    return tab.id === currentTabId
      ? tab.activeIconClass || tab.iconClass
      : tab.iconClass;
  };

  const getTabCls = (): string => {
    return tab.id === currentTabId ? "mobile-tab active-tab" : "mobile-tab";
  };

  return (
    <div key={tab.id} onClick={() => onTabClick(tab)} className={getTabCls()}>
      <i className={"ui-icon " + getIconClass(tab)}>
        {typeof tab.count === "number" && (
          <span className="count">{tab.count}</span>
        )}
      </i>
      <div
        className="tabs-mobile__title"
        dangerouslySetInnerHTML={{ __html: tab.name }}
      ></div>
    </div>
  );
};

export default MobileTab;
