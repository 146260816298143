import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import "./SelectionTagSwitcher.scss";

interface TProps {
  prevTag: () => void;
  nextTag: () => void;
}

export function SelectionTagSwitcher({ prevTag, nextTag }: TProps) {
  const isMobile = useIsMobile();

  return (
    <div className="selection-tag-switcher">
      <div onClick={prevTag}>
        <i className="ui-icon ui-icon-keyboard-arrow-left" />
        Предыдущая {!isMobile && "группа"}
      </div>
      <div onClick={nextTag}>
        Следующая {!isMobile && "группа"}
        <i className="ui-icon ui-icon-keyboard-arrow-right" />
      </div>
    </div>
  );
}
