import { Environment } from "../../types/Common.interface";
import { GET_ENV_VARIABLES } from "../actions/CommonActions";

export interface CommonState {
  environment: Environment;
}

const initialState: CommonState = {
  environment: null,
};

export function commonReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ENV_VARIABLES:
      return { ...state, environment: action.payload };
    default:
      return state;
  }
}
