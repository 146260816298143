import React from "react";
import Pagination from "../../../presentational/Pagination/Pagination";
import { PagingResponseParameters } from "../../../../types/Common.interface";

import "./PersonalPagination.scss";

interface PersonalPaginationProps {
  paging: PagingResponseParameters;
  curPageNum: number;
  paginationName: string;
  onPageChange: (pageNum: number) => any;
}

const PersonalPagination = ({
  paging,
  curPageNum,
  onPageChange,
  paginationName,
}: PersonalPaginationProps) => {
  return (
    <>
      {paging && paging.totalPages > 1 && (
        <div className="personal-pagination">
          <Pagination
            totalPages={paging.totalPages || 0}
            onPageChange={(event: { selected: number }) => {
              onPageChange(event.selected);
            }}
            currentPage={curPageNum}
            pageName={paginationName}
          />
        </div>
      )}
    </>
  );
};

export default PersonalPagination;
