import ExtraNav from "../ExtraNav/ExtraNav";
import React from "react";
import { useHistory } from "react-router";
import "./ActionTeamBlog.scss";

interface ActionTeamBlogProps {
  teamPosts: any[];
}

const ActionTeamBlog = ({ teamPosts }: ActionTeamBlogProps) => {
  const history = useHistory();

  return (
    <ExtraNav
      key={"blog"}
      title={"Блоги команды проекта"}
      posts={[teamPosts[0]]}
      moreLink={teamPosts.length > 1 && "/teamBlogs"}
      onChange={(post) =>
        history.push(`/users/user/post/${post.author.id}/${post.id}`)
      }
    />
  );
};

export default ActionTeamBlog;
