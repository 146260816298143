import { CommentState, Pagination } from "../../types/Comment.interface";
import {
  ADD_COMMENT_COMPLETE,
  GET_ANSWERS_COMMENTS,
  SET_COMMENT_VOTED_USERS,
  GET_RECENT_COMMENTS,
  GET_ROOT_COMMENTS,
  UPDATE_COMMENT_COMPLETE,
  SET_IS_LOADING,
  SET_VOTED_PAGINATION,
  CLEAR_VOTED_PAGINATION,
} from "../actions/CommentActions";

const initialState: CommentState = {
  isLoading: false,
  recentComments: null,
  rootComments: null,
  answersComments: null,
  lastComment: null,
  votedUsers: [],
  votedUsersPags: {} as Pagination,
};

export function commentReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_RECENT_COMMENTS:
      return { ...state, recentComments: action.payload };

    case GET_ROOT_COMMENTS:
      return {
        ...state,
        rootComments: state.rootComments ? [...state.rootComments, ...action.payload] : action.payload,
      };
    case GET_ANSWERS_COMMENTS:
      return { ...state, answersComments: action.payload };
    case ADD_COMMENT_COMPLETE:
      return { ...state, lastComment: action.payload };
    case UPDATE_COMMENT_COMPLETE:
      return { ...state, lastComment: action.payload };
    case SET_COMMENT_VOTED_USERS:
      return { ...state, votedUsers: [...state.votedUsers, ...action.payload] };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_VOTED_PAGINATION:
      return { ...state, votedUsersPags: action.payload };
    case CLEAR_VOTED_PAGINATION:
      return { ...state, votedUsers: [], votedUsersPags: {} };
    default:
      return state;
  }
}
