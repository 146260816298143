import { User } from "./User.interface";

export interface Comment {
  attachments: AttachmentsComment[];
  author: User;
  blogId: string;
  chooser: User;
  chosen: boolean;
  chosenTimestamp: string;
  complaintMade: boolean;
  date: string;
  deleted: boolean;
  deletedByAuthor: boolean;
  discussionId: string;
  discussionOwner: User;
  discussionPermissions: DiscussionPermissionsCommentString[];
  discussionStatistics: DiscussionStatisticsComment;
  discussionTitle: string;
  discussionType: DiscussionTypeCommentString;
  downVotes: number;
  hasResponses: boolean;
  id: string;
  interesting: boolean;
  lastEditDate: string;
  lastEditUser: User;
  new: boolean;
  nodeTemplateId: string;
  notificationId: string;
  notificationProcessed: boolean;
  parentId: string;
  permissions: PermissionsCommentString[];
  personalDiscussion: boolean;
  replyTo: User;
  responses: any[];
  responsesSkipped: number;
  rootId: string;
  stageId: string;
  stagePermissions: StagePermissionsCommentString[];
  text: string;
  rawText: string;
  title: string;
  upVotes: number;
  voted: boolean;
  top: boolean;
}

export enum DiscussionPermissionsComment {
  DISCUSSION_ADD_COMMENT = "DISCUSSION_ADD_COMMENT",
  DISCUSSION_ADD_COMMENT_REPLY = "DISCUSSION_ADD_COMMENT_REPLY",
  DISCUSSION_READ = "DISCUSSION_READ",
}
export type DiscussionPermissionsCommentString = keyof typeof DiscussionPermissionsComment;

export interface DiscussionStatisticsComment {
  discussionId: string;
  newComments: number;
  subscribed: boolean;
  subscription: boolean;
  totalComments: number;
  totalParticipants: number;
  totalSubscriptions: number;
}

export interface AttachmentsComment {
  fileName: string;
  hasThumbnail: boolean;
  id: string;
  size: number;
}

export enum DiscussionTypeComment {
  PROPOSAL,
  PROPOSAL_IMPROVEMENT,
  PROPOSAL_ITEM,
  BLOG_POST,
  BLOG_POST_DRAFT,
  COMMENT,
  PROJECT_NODE,
  BLOG,
  BLOG_POST_SUMMARY,
  STAGE,
  USER,
  MAIL_MESSAGE,
  ADMIN_WIKI_PAGE,
  DISCUSSION,
  PROJECT,
  PROMO_FEEDBACK_ATTACHMENT,
  FEEDBACK_ANSWER_ATTACHMENT,
  NEWS_ITEM,
  BANNER,
  BANNER_PHONE,
  BANNER_TAB,
  VIDEO,
  PREVIEW,
  PROJECT_NOTIFICATION_HEADER,
  PROJECT_NOTIFICATION_FOOTER,
  USER_IDEA_POTENTIAL,
  USER_IDEA_CLEAN,
  USER_IDEA_WATERMARK,
  USER_IDEA_LOGO,
  PROJECT_LOGO_VERTICAL,
  PROJECT_LOGO_HORIZONTAL,
  PROJECT_LOGO_SQUARE,
}
export type DiscussionTypeCommentString = keyof typeof DiscussionTypeComment;

export enum PermissionsComment {
  COMMENT_EDIT = "COMMENT_EDIT", // Редактировать комментарий
  COMMENT_VOTE = "COMMENT_VOTE", // Проголосовать за комментарий
  COMMENT_CHOOSE = "COMMENT_CHOOSE", // Отбор комментария
  COMMENT_UNCHOOSE = "COMMENT_UNCHOOSE", // Отбор комментария
  COMMENT_INTEREST = "COMMENT_INTEREST", // Добавить комментарий в интересное
  COMMENT_DELETE = "COMMENT_DELETE", // Удалить комментарий
  COMMENT_RESTORE = "COMMENT_RESTORE", // Восстановить комментарий
  COMMENT_DELETE_PERMANENT = "COMMENT_DELETE_PERMANENT", // Удалить комментарий навсегда
  COMMENT_HIDE = "COMMENT_HIDE", // Скрыть комментарий
  COMMENT_MAKE_COMPLAINT = "COMMENT_MAKE_COMPLAINT", // Пожаловаться на комментарий
  COMMENT_ASK_FACILITATOR = "COMMENT_ASK_FACILITATOR", // Задать вопрос фасилитатору
  COMMENT_CREATE_STRUCTURE = "COMMENT_CREATE_STRUCTURE", // Сформировать структуру
  COMMENT_THANK = "COMMENT_THANK", // Сказать "Спасибо"
  COMMENT_VIEW_VOTES = "COMMENT_VIEW_VOTES", // Просматривать голоса
  COMMENT_VIEW_EDITOR = "COMMENT_VIEW_EDITOR", // Просмотр имени ЧКП, редактировавшего комментарий
  COMMENT_REPLY = "COMMENT_REPLY", // Ответить на комментарий
}

export type PermissionsCommentString = keyof typeof PermissionsComment;

export enum StagePermissionsComment {
  STAGE_VIEW_CHOSEN,
  STAGE_VIEW_STRUCTURES,
  STAGE_EDIT_STRUCTURES,
  STAGE_APPROVE_STRUCTURES,
  STAGE_CANCEL,
  STAGE_STOP,
  STAGE_SUBMIT_PROPOSAL,
  STAGE_CONFIRM_FINISH,
  STAGE_VIEW_FINISH_CONDITIONS,
  STAGE_MAKE_VOTES,
  STAGE_ALTER_RESULTS,
}
export type StagePermissionsCommentString = keyof typeof StagePermissionsComment;

export type Pagination = {
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
};

export interface CommentState {
  isLoading: boolean;
  recentComments: Comment[] | null;
  rootComments: Comment[] | null;
  answersComments: Comment[] | null;
  lastComment: Comment;
  votedUsers: User[];
  votedUsersPags: Pagination;
}
