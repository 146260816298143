import { API } from "./api";

const baseUrl = "/api/badWord";

const BadWordsService = {
  getAll: () => {
    return API.get(`${baseUrl}/getAll`).then((data) => {
      return data?.data;
    });
  },
};

export default BadWordsService;
