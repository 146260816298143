import { ProposalTypeEnum } from "../../../../types/Proposal.interface";

const mapTypeToEmptyText = {
  [ProposalTypeEnum.SUBMITTED]:
    "На рассмотрении у экспертов проекта нет ваших идей",
  [ProposalTypeEnum.ACCEPTED]: "У вас нет принятых идей",
  [ProposalTypeEnum.REJECTED]: "У вас нет идей, отклоненных экспертами",
  [ProposalTypeEnum.REMOVED]: "У вас нет идей, удаленных экспертами проекта",
  [ProposalTypeEnum.DRAFT]: "У вас нет сохраненных черновиков идей",
};

const mapTypeToBackArrow = {
  [ProposalTypeEnum.SUBMITTED]: "на рассмотрении",
  [ProposalTypeEnum.ACCEPTED]: "принятые",
  [ProposalTypeEnum.REJECTED]: "отклоненные",
  [ProposalTypeEnum.REMOVED]: "удаленные",
  [ProposalTypeEnum.DRAFT]: "черновики",
};

export { mapTypeToEmptyText, mapTypeToBackArrow };
