import axios from "axios";

const axiosInstance = axios.create({
  headers: { "Content-Type": "application/json" },
  timeout: 100000,
});

export function httpParamsSerialize(params) {
  const esc = encodeURIComponent;
  let query = Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");

  return query;
}

export function fetchRequest(url, params, method?, headers?, cancelToken?) {
  headers = headers || {
    contentType: "application/json",
  };

  if (method === "POST") {
    return axiosInstance.post(url, params, {
      headers: { ...headers },
      cancelToken: cancelToken,
    });
  } else {
    return axiosInstance.get(url, {
      cancelToken: cancelToken,
    });
  }
}
