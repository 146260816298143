import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, AttachmentPreview } from "@crowd/ui-kit";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";

import { showErrorToast, showSuccessToast } from "../../../../store/actions/ToastActions";
import { showConfirmInfoModal } from "../../../../store/actions/LayoutActions";
import { ProposalCardProps } from "./ProposalCard.interface";
import { proposalStatusColorMap } from "./ProposalCard.constants";
import ProposalService from "../../../../services/proposalService";
import { storeLocalDraft } from "../../../../store/actions/ProposalActions";
import { ERR_MSG } from "../../../../services/api";
import "./ProposalCard.scss";
import { tabletWidth } from "../../../../utils/constants/widthConstants";
import Tooltip from "../../Tooltip/Tooltip";
import RejectReason from "./RejectReason/RejectReason";
import { ProposalTypeEnum } from "../../../../types/Proposal.interface";
import ProposalHistory from "./ProposalHistory/ProposalHistory";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { LocalStorageKeys, LocalStorageService } from "../../../../services/LocalStorage.service";
import { GenerationDraft } from "../../../pages/Generation/GenerationForm/GenerationForm";

const ProposalCard = ({
  proposal,
  fromGeneration,
  index,
  onEditDraftStart,
  onDeleteDraftStart,
  onDeleteDraftSuccess,
  onDeleteDraftFailure,
  titleLink,
  showHistory = false,
  showRejectReason = false,
  onEditDraft,
  canEdit,
  canDelete,
}: ProposalCardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  const showStageName = !fromGeneration;
  const showItemNumbers = proposal.items?.length > 1;

  const getItemTitle = (item) => {
    let title = "";
    const taskItems = proposal.stage?.taskItems || [];
    if (taskItems.length > 0 && !!item?.metaId) {
      title = taskItems.find((taskItem) => taskItem.id === item?.metaId)?.title || "";
    }
    return title;
  };

  const editDraft = (e, proposal) => {
    e.stopPropagation();
    e.preventDefault();

    const draft: GenerationDraft = {
      stageId: proposal.stage.id,
      id: proposal.id,
      editableProposalId: proposal.editableProposalId,
      draftId: proposal.draftId,
      title: proposal.title,
      values: proposal.items.reduce((acc, curr) => {
        acc[curr.metaId] = curr.valueRaw || curr.value;
        return acc;
      }, {}),
      files: proposal.attachments.map((file) => ({ id: file.id, name: file.fileName })),
      rejectReason: {
        text: proposal.reason,
        comment: proposal.rejectComment,
      },
    };

    onEditDraft ? onEditDraft(draft) : onEditDraftDefault(draft);
  };

  const onEditDraftDefault = (draft) => {
    LocalStorageService.saveData(LocalStorageKeys.GenerationDraft, JSON.stringify(draft));
    window.open(
      `/generation/${proposal.stageId}?loadDraft=1&isDeclined=${proposal.status === ProposalTypeEnum.REJECTED}&showReason=${Boolean(
        proposal.reason
      )}`,
      "_blank"
    );
  };

  const deleteDraft = (proposal) => {
    dispatch(showConfirmInfoModal("Вы действительно хотите удалить черновик?", () => deleteDraftRequest(proposal)));
  };

  const deleteDraftRequest = (proposal) => {
    onDeleteDraftStart && onDeleteDraftStart();

    ProposalService.deleteDraft(proposal.draftId)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          dispatch(showSuccessToast("Черновик успешно удален."));
          onDeleteDraftSuccess && onDeleteDraftSuccess();
        } else {
          dispatch(showErrorToast(response.message || ERR_MSG, false));
        }
      })
      .catch((err) => {
        dispatch(showErrorToast(err.message || ERR_MSG, false));
        onDeleteDraftFailure && onDeleteDraftFailure();
      });
  };

  const canShowActions = () => {
    return canEdit || canDelete;
  };

  const canShowRejectReason = () => {
    return showRejectReason && Boolean(proposal.reason);
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        {showStageName && <div className="proposal-card__stageName">{proposal.stage.title}</div>}
        <h5 className="proposal-card__title break-word">
          {proposal.title || `Без названия ${index + 1} от ${dayjs(proposal.submissionDate).format("DD.MM.YYYY,HH:mm")}`}
        </h5>
        <div className="proposal-card__date">
          {dayjs(proposal.lastSubmitted || proposal.submissionDate).format("DD.MM.YYYY HH:mm") + " "}
          <span style={{ color: proposalStatusColorMap[proposal.status] }}>{proposal.statusTitle}</span>
        </div>

        <div className="proposal-card__description">
          {proposal.items?.map((item, idx) => (
            <div className={`proposal-card__description__item ${showItemNumbers ? "multiple" : ""}`} key={idx}>
              <div className="proposal-card__description__item__title break-word">
                {showItemNumbers && <div className="proposal-card__description__item__number">{`${item.orderNumber + 1}. `}</div>}
                {getItemTitle(item)}
              </div>
              <div
                className="proposal-card__description__item__value break-word"
                dangerouslySetInnerHTML={{ __html: !!item.valueRaw ? item.valueRaw : "<i>Не указано</i>" }}
              >
                {}
              </div>
            </div>
          ))}
        </div>

        {canShowRejectReason() && (
          <RejectReason
            reason={{
              text: proposal.reason,
              comment: proposal.rejectComment,
            }}
            type={proposal.status as any}
          />
        )}

        {proposal.attachments?.length > 0 && (
          <div className="proposal-card__attachments scroll-x">
            {proposal.attachments.map((attachment) => (
              <AttachmentPreview attachment={attachment} key={attachment.id} />
            ))}
          </div>
        )}

        {canShowActions() && (
          <div className="proposal-card__actions">
            {canEdit ? (
              <>
                {isTablet ? (
                  proposal.status === ProposalTypeEnum.REJECTED ? (
                    <i className="ic ui-icon-edit" onClick={(e) => editDraft(e, proposal)} />
                  ) : (
                    <Tooltip text={"Редактировать черновик"} idx={index + "-edit"}>
                      <i className="ic ui-icon-edit" onClick={(e) => editDraft(e, proposal)} />
                    </Tooltip>
                  )
                ) : (
                  <Button text="Редактировать" onClick={(e) => editDraft(e, proposal)} type="filled" size="m" />
                )}
              </>
            ) : null}

            {canDelete ? (
              <>
                {isTablet ? (
                  <Tooltip text={`Удалить черновик`} idx={index + "-delete"}>
                    <i className="ic ui-icon-delete" onClick={() => deleteDraft(proposal)} />
                  </Tooltip>
                ) : (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteDraft(proposal);
                    }}
                    text="Удалить"
                    type="outlined"
                    size="m"
                  />
                )}
              </>
            ) : null}
          </div>
        )}

        {showHistory && <ProposalHistory proposal={proposal} />}
      </React.Fragment>
    );
  };

  return <div className="proposal-card">{titleLink ? <Link to={`${titleLink}`}>{renderContent()}</Link> : renderContent()}</div>;
};

export default ProposalCard;
