import { filterTypesSearchSelect } from "../../components/pages/Selection/constants/constants";
import { TSelectionState } from "../../types/Selection.interface";
import {
  SELECTION_SET_GENERATION_STAGE,
  SELECTION_SET_COUNTS,
  SELECTION_SET_TAGS,
  SELECTION_SET_REJECT_REASONS,
  SELECTION_SET_EXPERT,
  SELECTION_SET_IDEAS,
  SELECTION_SET_IDEAS_LOADING,
  SELECTION_SET_IDEAS_PAGINATION,
  SELECTION_SET_IDEAS_CURRENT_PAGE,
  SELECTION_SET_IDEA_PROCESS_TYPE,
  SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY,
  SELECTION_SET_FILTER,
  SELECTION_SET_FILTER_TYPE_SEARCH,
  SELECTION_SET_SEARCH_FILTER_VALUE,
  SELECTION_SET_FILTER_SEARCH_RES,
  SELECTION_SET_SELECTED_TAGS_FOR_COMBINING,
  SELECTION_SET_IDEAS_FOR_COMBINING,
  SELECTION_SET_IS_TAG_EDITING,
  SELECTION_SET_IS_COMBINING_TAGS,
  SELECTION_SET_IS_STAGE_FINISHED,
  SELECTION_SET_IDEA_PROCESS_ID,
  SELECTION_SET_IS_FORWARDED_SHOW_ALL,
} from "../actions/SelectionActions";

const initialState: TSelectionState = {
  generationStage: null,
  counts: null,
  tags: [],
  rejectReasons: [],
  experts: [],
  ideas: [],
  ideasLoading: false,
  ideasPagination: null,
  page: 0,
  filter: {},
  ideaProcessId: undefined,
  ideaProcessType: undefined,
  ideaShowTransferHistory: undefined,
  filterTypeSearch: filterTypesSearchSelect[0],
  searchFilterValue: "",
  filterSearchRes: [],
  selectedTagsForCombining: [],
  ideasForCombining: undefined,
  isTagEditing: false,
  isCombiningTags: false,
  isStageFinished: false,
  isForwardedShowAll: false,
};

export function selectionReducer(state = initialState, action: any): TSelectionState {
  switch (action.type) {
    case SELECTION_SET_GENERATION_STAGE:
      return { ...state, generationStage: action.payload };
    case SELECTION_SET_IDEA_PROCESS_ID:
      return { ...state, ideaProcessId: action.payload };
    case SELECTION_SET_IDEA_PROCESS_TYPE:
      return { ...state, ideaProcessType: action.payload };
    case SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY:
      return { ...state, ideaShowTransferHistory: action.payload };
    case SELECTION_SET_FILTER:
      return { ...state, filter: action.payload };
    case SELECTION_SET_FILTER_TYPE_SEARCH:
      return { ...state, filterTypeSearch: action.payload };
    case SELECTION_SET_SEARCH_FILTER_VALUE:
      return { ...state, searchFilterValue: action.payload };
    case SELECTION_SET_FILTER_SEARCH_RES:
      return { ...state, filterSearchRes: action.payload };
    case SELECTION_SET_SELECTED_TAGS_FOR_COMBINING:
      return { ...state, selectedTagsForCombining: action.payload };
    case SELECTION_SET_IDEAS_FOR_COMBINING:
      return { ...state, ideasForCombining: action.payload };
    case SELECTION_SET_IS_TAG_EDITING:
      return { ...state, isTagEditing: action.payload };
    case SELECTION_SET_IS_COMBINING_TAGS:
      return { ...state, isCombiningTags: action.payload };
    case SELECTION_SET_COUNTS:
      return { ...state, counts: action.payload };
    case SELECTION_SET_TAGS:
      return { ...state, tags: action.payload };
    case SELECTION_SET_REJECT_REASONS:
      return { ...state, rejectReasons: action.payload };
    case SELECTION_SET_EXPERT:
      return { ...state, experts: action.payload };
    case SELECTION_SET_IDEAS:
      return { ...state, ideas: action.payload, ideaProcessId: undefined };
    case SELECTION_SET_IDEAS_LOADING:
      return { ...state, ideasLoading: action.payload };
    case SELECTION_SET_IDEAS_PAGINATION:
      return { ...state, ideasPagination: action.payload };
    case SELECTION_SET_IDEAS_CURRENT_PAGE:
      return { ...state, page: action.payload };
    case SELECTION_SET_IS_STAGE_FINISHED:
      return { ...state, isStageFinished: action.payload };
    case SELECTION_SET_IS_FORWARDED_SHOW_ALL:
      return { ...state, isForwardedShowAll: action.payload };
    default:
      return state;
  }
}
