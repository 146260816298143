import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import "./PersonalComments.scss";
import {
  ApiStatusCode,
  PagingResponseParameters,
} from "../../../../../types/Common.interface";
import useQuery from "../../../../../utils/hooks/useQuery";
import PersonalComment from "./PersonalComment/PersonalComment";
import { Comment } from "../../../../../types/Comment.interface";
import CommentService from "../../../../../services/commentService";
import PageTitle from "../../../../presentational/PageTitle/PageTitle";
import PersonalPagination from "../../PersonalPagination/PersonalPagination";
import DatePicker from "../../../../presentational/Controls/DatePicker/DatePicker";
import ContentLoader from "../../../../presentational/Loaders/ContentLoader/ContentLoader";
import { DatePickerType } from "../../../../presentational/Controls/DatePicker/DatePicker.interface";
import { scrollToTop } from "../../../../../utils";

const COUNT_PER_PAGE = 10;

const PersonalComments = () => {
  const query = useQuery();
  const page: number = isNaN(Number.parseInt(query.page))
    ? 0
    : Number.parseInt(query.page) - 1;
  const { userId } = useParams<{ userId: string }>();
  const [comments, setComments] = useState<Comment[]>([]);
  const [paging, setPaging] = useState<PagingResponseParameters>({
    pageSize: COUNT_PER_PAGE,
    pageNumber: page,
  });
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [period, setPeriod] = useState<{ from: Date; to: Date }>({
    from: null,
    to: null,
  });

  useEffect(() => {
    loadComments(currentPage).then();
  }, [period]);

  const loadComments = async (curPageNum: number) => {
    setLoading(true);

    const commentResp = await CommentService.getCommentsByUserId(
      userId,
      { size: COUNT_PER_PAGE, page: curPageNum },
      ["date,ASC"],
      period.from,
      period.to
    );

    if (commentResp.status === ApiStatusCode.OK) {
      setComments(commentResp.data);
      setPaging(commentResp.paging);
      setCurrentPage(curPageNum);
      scrollToTop();
    } else {
      console.warn(commentResp);
    }

    setLoading(false);
  };

  return (
    <div className="personal-comments">
      <div className="personal-comments__header">
        <PageTitle text={"Комментарии"} />
        <DatePicker
          type={DatePickerType.RANGED}
          onChange={(period) => {
            setPeriod(period);
          }}
          date={new Date()}
          modifiers={period}
        />
      </div>

      <ContentLoader isLoading={isLoading}>
        {comments.length === 0 && (
          <div className="empty-content">
            {period.from === null && period.to === null
              ? "Участник не оставил комментарии в обсуждениях"
              : "У пользователя нет комментариев за выбранный период"}
          </div>
        )}

        {comments.length > 0 && (
          <div className="personal-comments__list">
            {comments.map((comment: Comment) => (
              <div
                className="personal-comments__list__comment"
                key={comment.id}
              >
                <PersonalComment comment={comment} />
              </div>
            ))}
          </div>
        )}
      </ContentLoader>

      <PersonalPagination
        paging={paging}
        onPageChange={loadComments}
        paginationName={"page"}
        curPageNum={currentPage}
      ></PersonalPagination>
    </div>
  );
};

export default PersonalComments;
