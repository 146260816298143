export const TeamGroupHeaders: any = {
  experts: {
    title: "Эксперты проекта",
    description:
      "Экспертами проекта являются представители органов исполнительной власти г. Москвы и подведомственных им организаций. Они отбирают идеи участников и отвечают на вопросы, которые могут возникнуть в ходе проекта.",
  },
  frontmen: {
    title: "Лица проекта",
    description:
      "Лицами проекта являются известные горожане, неравнодушные к современным тенденциям развития города и информационных систем.",
  },
  coordinators: {
    title: "Координаторы проекта",
    description:
      "Координаторы помогают участникам в организационных моментах. Отвечают на вопросы по текущему проекту и рассказывают об этапах работы на платформе.",
  },
};

export const slidesPerView = {
  frontmen: 3,
  coordinators: 5,
  details: 1,
};

export const teamTypeConstant = {
  experts: "experts",
  frontmen: "frontmen",
  coordinators: "coordinators",
};

export const handleRole = (userType, userInfo) => {
  const role = {
    icon: null,
    name: null,
  };
  if (userType === "coordinators") {
    if (userInfo?.projectBoss) {
      role.icon = "coordinator";
      role.name = "Руководитель проекта";
    } else if (userInfo?.manager) {
      role.name = "Менеджер проекта";
    } else {
      role.name = "Координатор проекта";
    }
  }

  if (userType === "experts") {
    if (userInfo?.chiefExpert) {
      role.icon = "expert";
      role.name = "Главный эксперт проекта";
    } else {
      role.name = "Эксперт проекта";
    }
  }
  return { ...role };
};

export const handleSocialNetworks = (userInfo) => {
  const sanctioned = ["facebook", "twitter"];
  return userInfo?.socialNetwork
    ? Object.keys(userInfo.socialNetwork)
        .filter((k) => !sanctioned.includes(k))
        .filter((key) => !!userInfo.socialNetwork[key])
        .map((key) => {
          return { type: key, link: userInfo.socialNetwork[key] };
        })
    : [];
};

export const orderInfoExprets = (isTablet: boolean, index: number): number => {
  const stringExperts = isTablet ? 2 : 3;
  return Math.floor(index / stringExperts) * stringExperts + stringExperts;
};
