import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./HiddenFIOWarning.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import { HiddenFIOWarningProps } from "./HiddenFIOWarning.types";

const tooltipText = `Указанные Вами имя и фамилия не отображаются на платформе, так как нарушают <a href=${
  window.location.origin + "/license"
} target='_blank'>Пользовательское соглашение</a>. Пожалуйста, обратитесь в службу технической поддержки для получения дополнительной информации.`;

const HiddenFIOWarning: FC<HiddenFIOWarningProps> = ({ inline = false }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)` });

  const tooltipPlace = useMemo(() => {
    if (isPhone) return "bottom";
    if (isTablet) return "left";
    return "right";
  }, [isTablet, isPhone]);

  const handleClick = (event) => {
    event?.stopPropagation();
  };
  return (
    <div className={`hidden-fio-warning ${inline ? "hidden-fio-warning_inline" : ""}`} onClick={handleClick}>
      {inline ? (
        <>
          <span className="ic ui-icon-info hidden-fio-warning__icon"></span>
          <span className="hidden-fio-warning_inline__text" dangerouslySetInnerHTML={{ __html: tooltipText }} />
        </>
      ) : (
        <Tooltip
          idx={"hidden-fio-warning-tooltip"}
          text={tooltipText}
          place={tooltipPlace}
          isOuter={true}
          clickable={true}
          globalEventOff="click"
          overridePosition={({ left, top }, _, __, node) => {
            const { width } = node.getBoundingClientRect();
            return { left: isPhone ? (window.innerWidth - width) / 2 : left, top };
          }}
        >
          <span className="ic ui-icon-info hidden-fio-warning__icon"></span>
        </Tooltip>
      )}
    </div>
  );
};

export default HiddenFIOWarning;
