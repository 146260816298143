import { PagingResponseParameters } from "./types/Common.interface";

// TODo move all to the Common.interface

export enum AppColorsEnum {
  LIGHTBLUE = "lightblue",
  DARKBLUE = "darkblue",
  WHITE = "white",
  LIGHTGRAY = "lightgray",
}

export enum FooterTextColorsEnum {
  WHITE = "white",
  GRAY = "gray",
}

export interface IBaseResponse<T> {
  data: T;
  message: string;
  status: number;
}

export interface IBaseListResponse<T> {
  data: Array<T>;
  message: string;
  paging: PagingResponseParameters;
  status: number;
}
