import { useEffect, useState } from "react";
import { debounce } from "../index";
import { ITextQuote } from "../../components/containers/Forms/AddCommentForm/AddCommentForm";

const useQuoteWindowSelect = () => {
  const getSelection = (): { authorName: string, text: string } => {
    if (window.getSelection().focusNode) {
      const commentEl: any = findParentBySelector(window.getSelection().focusNode, ".comment-card__wrapper");

      if (commentEl) {
        const authorName = commentEl.querySelector(".comment-card__author-name").innerText;
        const text = window.getSelection().toString();
        return { authorName, text };
      }
    }

    return { authorName: null, text: window.getSelection()?.toString() || null };
  };

  const [selectedText, setSelectedText] = useState<ITextQuote>(getSelection());

  const setText = () => setSelectedText(getSelection());

  useEffect(() => {
    document.addEventListener("selectionchange", debounce(setText, 300));
    return () =>
      document.removeEventListener("selectionchange", debounce(setText, 300));
  }, []);

  return selectedText;
};

function collectionHas(a, b) {
  for(let i = 0, len = a.length; i < len; i ++) {
    if(a[i] === b) return true;
  }
  return false;
}

function findParentBySelector(elm, selector) {
  const all = document.querySelectorAll(selector);
  let cur = elm.parentNode;
  while(cur && !collectionHas(all, cur)) {
    cur = cur.parentNode;
  }

  return cur;
}

export default useQuoteWindowSelect;
