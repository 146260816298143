import { DiscussionTypeEnum } from "../../../../types/Personal.interface";

export const mapTypeToEmptyText = (type) => {
  switch (type) {
    case DiscussionTypeEnum.ALL:
      return "У вас нет постов,  добавленных в избранное";
    case DiscussionTypeEnum.STAGE:
      return "У вас нет постов,  добавленных в избранное на этапе «Обсуждай»";
    case DiscussionTypeEnum.BLOG:
      return "У вас нет постов,  добавленных в избранное в личных блогах";
    default:
      return "Нет постов";
  }
};
