import { Tab } from "../../../presentational/Tabs/Tabs";
import { SendMailDestination } from "../../../../services/mailService";

export interface MessageTab extends Tab {
  id: SendMailDestination;
}

export const messageTabs: MessageTab[] = [
  {
    id: "FACILITATOR",
    name: "Координатору",
  },
  {
    id: "EXPERT",
    name: "Эксперту",
  },
];
