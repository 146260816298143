export interface NotificationConfugurationFormProps {
  onClose: () => void;
}

export enum StageTypeEnum {
  STAGE_START = "STAGE_START",
  STAGE_EXTEND = "STAGE_EXTEND",
  STAGE_STOP = "STAGE_STOP",
  PROJECT_BLOG_POST_PUBLISHED = "PROJECT_BLOG_POST_PUBLISHED",
  PERSONAL_BLOG_POST_PUBLISHED = "PERSONAL_BLOG_POST_PUBLISHED",
  SUMMARY_PUBLISHED = "SUMMARY_PUBLISHED",
  PROJECT_DIARY_POST_PUBLISHED = "PROJECT_DIARY_POST_PUBLISHED",
  NEW_COMMENT_PROJECT_BLOG = "NEW_COMMENT_PROJECT_BLOG",
  NEW_COMMENT_PROJECT_DISCUSSION = "NEW_COMMENT_PROJECT_DISCUSSION",
  NEW_COMMENT_REPLY = "NEW_COMMENT_REPLY",
  NEW_COMMENT_PERSONAL_POST = "NEW_COMMENT_PERSONAL_POST",
  NEW_COMMENT_FAVORITE_POST = "NEW_COMMENT_FAVORITE_POST",
  USER_COMPLAINT = "USER_COMPLAINT",
  DISCUSSION_FINISH_CONFIRMED = "DISCUSSION_FINISH_CONFIRMED",
  SELECTION_FINISH_CONFIRMED = "SELECTION_FINISH_CONFIRMED",
  VOTING_FINISH_CONFIRMED = "VOTING_FINISH_CONFIRMED",
  SUMMARY_SUBMITTED = "SUMMARY_SUBMITTED",
}

export enum ChannelTypeEnum {
  SITE = "SITE",
  MAIL = "MAIL",
}

export interface Channel {
  channel: ChannelTypeEnum;
  defaultValue: boolean;
  editable: boolean;
  value: boolean;
}

export interface NotificationSet {
  description: string;
  type: StageTypeEnum;
  channels: Channel[];
}
