import React from "react";

import "./UserAvatar.scss";
import { User } from "../../../types/User.interface";
import { getAvatarSrc } from "../../../utils";

export enum UserAvatarSize {
  XS = 40,
  S = 48,
  M = 64,
  L = 96,
  XL = 128,
  XXL = 192,
}

export interface UserAvatarProps {
  user?: User;
  pictureUrl?: string;
  size?: keyof typeof UserAvatarSize;
}

const UserAvatar = ({ user, size = "S", pictureUrl }: UserAvatarProps) => {
  const getClasses = (): string => {
    const baseClass = `user-avatar`;
    let classes = `${baseClass} ${baseClass}__${size.toLowerCase()}`;
    if (user?.online) classes += ` ${baseClass}__online`;
    if (user?.expert || user?.chiefExpert) classes += ` ${baseClass}__expert`;
    if (user?.frontman) classes += ` ${baseClass}__frontman`;
    if (user?.projectBoss || user?.facilitator || user?.projectFacilitator || user?.manager) classes += ` ${baseClass}__facilitator`;
    if (user?.blocked) classes += ` ${baseClass}__blocked`;
    return classes;
  };

  const userPictureId = user && !user.showName ? `${(user.gender || "male").toLowerCase()}_1` : user?.pictureId;

  const renderByUser = () =>
    !user?.blocked ? (
      <img src={getAvatarSrc(userPictureId)} alt={user?.firstName} />
    ) : (
      <i className="ui-icon-lock ui-icon" style={{ color: "white" }} />
    );

  const renderByUrl = () => <img src={pictureUrl} alt={pictureUrl} />;

  return (
    <div className={`user-avatar__container user-avatar__${size.toLowerCase()}`}>
      <div
        className={getClasses()}
        style={{
          backgroundImage: !user?.blocked ? `url(${getAvatarSrc(userPictureId)})` : "none",
        }}
      >
        {!!user ? renderByUser() : renderByUrl()}
      </div>
    </div>
  );
};

export default UserAvatar;
