import React from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Button } from "@crowd/ui-kit";
import { useMediaQuery } from "react-responsive";

import "./PersonalPostCard.scss";
import { BlogPost } from "../../../../../../types/BlogPost.interface";
import PostAddFavorite from "../../../../../presentational/PostAddFavorite/PostAddFavorite";
import { getPlainFromHtml } from "../../../../../../utils";
import CommentService from "../../../../../../services/commentService";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";
import linkifyHtml from "linkifyjs/html";

interface PersonalPostProps {
  post: BlogPost;
  userId: string;
}

const CONTENT_LEN = 1000;

const PersonalPostCard = ({ post, userId }: PersonalPostProps) => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const readPost = (): void => {
    history.push(`/users/user/post/${userId}/${post.id}`);
  };

  const getClassesForRoot = () => {
    let classlist = "personal-post-card post-card";

    const length = getPlainFromHtml(post.content).length;
    if (length > CONTENT_LEN) {
      classlist += " post-card--shorten-10";
    }

    return classlist;
  };

  const getText = (content) => {
    let _content = CommentService.CkBBCodeToHtml(content);
    _content = linkifyHtml(_content, {
      target: "blank",
    });
    return _content;
  };

  return (
    <div className={getClassesForRoot()}>
      <div className="personal-post-card__title">
        <span>{post.title}</span>
        {post.commentsEnabled && post.active && !post.hidden && <PostAddFavorite post={post} tooltipPlace="top" />}
      </div>
      <div className="personal-post-card__created">
        {dayjs(post.createdTimestamp).format("DD.MM.YYYY HH:mm")}
      </div>
      <div
        className="personal-post-card__content post-card--bn"
        dangerouslySetInnerHTML={{
          __html: getText(post.rawContent),
        }}
      ></div>

      <div className="personal-post-card__read">
        <Button
          type={isPhone ? "outlined" : "filled"}
          text="Читать"
          onClick={readPost}
          size={"m"}
        ></Button>
      </div>
    </div>
  );
};

export default PersonalPostCard;
