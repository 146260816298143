import React from "react";
import dayjs from "dayjs";

import { IWarning } from "../../../../../services/warningService";
import "./WarningTable.scss";

interface IWarningTableProps {
  warnings: IWarning[];
}

const WarningTable = ({ warnings }: IWarningTableProps) => {
  return (
    <div className="warning-table">
      {warnings.length === 0 && (
        <div className="warning-table__no-warnings-msg">
          Не найдено ни одного предупреждения.
        </div>
      )}

      {warnings.length > 0 && (
        <table cellSpacing="0">
          <thead>
            <tr>
              <th>Дата создания</th>
              <th>Автор</th>
              <th>Текст предупреждения</th>
            </tr>
          </thead>

          <tbody>
            {warnings.map((warn: IWarning) => (
              <tr key={warn.id}>
                <td>{dayjs(warn.created).format("DD.MM.YYYY HH:mm")}</td>
                <td>
                  {warn.author?.firstName} {warn.author?.lastName}
                </td>
                <td>{warn.text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WarningTable;
