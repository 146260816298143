import { Button } from "@crowd/ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { AppContext } from "../../../../Root";
import PersonalService from "../../../../services/personalService";
import {
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";
import { BlogType } from "../../../../types/Personal.interface";
import { BlogPost } from "../../../../types/BlogPost.interface";
import BlogService from "../../../../services/blogService";
import BlogModal from "../../../containers/Modals/BlogModal/BlogModal";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import { moduleCards } from "../Profile.constants";
import { ModuleTypeEnum, IModuleCardTab } from "../Profile.interface";
import ProfileSubContent from "../ProfileSubContent/ProfileSubContent";
import { mapTypeToEmptyText } from "./MyBlog.constants";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import "./MyBlog.scss";
import { getUrlParam } from "../../../../utils/urlUtils";
import ProfileBlogCard from "../../../presentational/Cards/ProfileBlogCard/ProfileBlogCard";
import { showConfirmInfoModal } from "../../../../store/actions/LayoutActions";
import { getProfileTab } from "../Profile.helper";
import useQuery from "../../../../utils/hooks/useQuery";

const MyBlog = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { type } = useQuery();

  const [dataCount, setDataCount] = useState(null);
  const [tab, setTab] = useState<IModuleCardTab>(
    getProfileTab(type, getTabs())
  );
  const [blogModalOpen, setBlogModalOpen] = useState(false);
  const [page, setPage] = useState(getPageFromUrl("page"));
  const [draftData, setDraftData] = useState<BlogPost>(null);
  const [posts, setPosts] = useState(null);
  const [paging, setPaging] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCountData();
    loadPosts();
  }, [page, tab.type]);

  useEffect(() => {
    !blogModalOpen && window.scrollTo(0, 0);
  }, [blogModalOpen]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    const actions = appContext.sudirService.getActions(ComponentType.MyBlog);
    if (actions.length) {
      const openBlogModal = actions.find(
        (act) => act.type === AuthActionType.OpenBlogModal
      );
      if (openBlogModal) {
        setBlogModalOpen(true);
      }
    }
  }, []);

  // Redirect from Participants page
  useEffect(() => {
    if ((history.location.state as any)?.openBlogModal) {
      setBlogModalOpen(true);
    }
  }, []);

  // RENDER

  const loadPosts = () => {
    let query: any = {
      page,
      size: 10,
      drafts: tab.type === BlogType.DRAFT,
    };

    setLoading(true);
    PersonalService.getPosts(query)
      .then((response) => {
        setPosts(response.data);
        setPaging(response.paging);
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => setLoading(false));
  };

  const loadCountData = () => {
    PersonalService.getPostsCount()
      .then((response) => {
        setDataCount(response.data);
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  };

  function getTabs(): IModuleCardTab[] {
    const tabs = moduleCards.find((m) => m.type === ModuleTypeEnum.BLOG).tabs;

    if (dataCount) {
      return tabs.map((tab) => {
        const newTab = { ...tab };
        if (tab.type === "PUBLISHED") {
          newTab.count = dataCount.postCount;
        } else if (tab.type === "DRAFT") {
          newTab.count = dataCount.draftCount;
        }
        return newTab;
      });
    }

    return tabs;
  }

  function getPageFromUrl(pageName) {
    return Math.max(
      Number(getUrlParam(pageName, window.location.search)) - 1,
      0
    );
  }

  const handleDraftEdit = (draft) => {
    setDraftData(draft);
    setBlogModalOpen(true);
  };

  const handlePostRemove = (post, silent?) => {
    if (silent) {
      removePost(post, silent);
    } else {
      dispatch(
        showConfirmInfoModal(
          `Вы действительно хотите удалить ${
            post.draft ? "черновик" : "пост"
          }?`,
          () => removePost(post)
        )
      );
    }
  };

  const removePost = (post: BlogPost, silent?: boolean) => {
    BlogService.deleteBlogPost(post.id)
      .then((response) => {
        if (response.status === 0) {
          loadPosts();
          !silent &&
            dispatch(
              showSuccessToast(
                post.draft ? "Черновик успешно удален." : "Пост успешно удален."
              )
            );
          return;
        }

        !silent && dispatch(showErrorToast(response.message));
      })
      .catch((err) => {
        !silent && dispatch(showErrorToast(err.message));
      })
      .finally(() => {
        loadCountData();
      });
  };

  const renderEmpty = () => (
    <div className="empty-content">{mapTypeToEmptyText[tab.type]}</div>
  );

  return (
    <div className="my-blog">
      <ProfileSubContent
        title="Мой личный блог"
        tabs={getTabs()}
        onTabChange={(tab) => setTab(tab)}
        paging={paging}
        isLoading={loading}
        onPageChange={(page) => setPage(page)}
      >
        <div className="my-blog__content">
          <Button
            type="filled"
            size="m"
            text="Написать в блог"
            onClick={() => setBlogModalOpen(true)}
          />

          {posts?.length
            ? posts?.map((post) => (
                <ProfileBlogCard
                  key={post.id}
                  post={post}
                  onEdit={handleDraftEdit}
                  onRemove={handlePostRemove}
                />
                // <CommentCard blogPost={post} showUserAvatar={false} />
              ))
            : renderEmpty()}
        </div>
      </ProfileSubContent>

      {blogModalOpen && (
        <BlogModal
          onSuccess={(draftToDelete) => {
            if (draftToDelete) handlePostRemove(draftToDelete, true);
            setDraftData(null);
            loadPosts();
            loadCountData();
            setBlogModalOpen(false);
          }}
          onClose={() => {
            setDraftData(null);
            setBlogModalOpen(false);
          }}
          post={draftData}
          fromDraft={!!draftData}
        />
      )}
    </div>
  );
};

export default MyBlog;
