import { Button, Loader, Select } from "@crowd/ui-kit";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProposalService from "../../../../../services/proposalService";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { ProposalMoveRequest, ProposalNode } from "../../../../../types/Proposal.interface";
import ClosingCard from "../../../../presentational/Cards/ClosingCard/ClosingCard";
import { SelectionMoveFormProps } from "./SelectionMoveForm.interface";
import "./SelectionMoveForm.scss";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { selectionGetCounts, selectionGetIdeas } from "../../../../../store/actions/SelectionActions";
import { useParams } from "react-router-dom";
import { TSelectionParams } from "../../types/types";

const SelectionMoveForm: FC<SelectionMoveFormProps> = ({ idea, onClose }) => {
  const isMobile = useIsMobile();
  const { stageId, folder } = useParams<TSelectionParams>();

  const dispatch = useDispatch();

  const [selectedNode, setSelectedNode] = useState<ProposalNode>(null);
  const [nodes, setNodes] = useState<ProposalNode[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const move = async () => {
    try {
      setIsSubmitting(true);

      const query: ProposalMoveRequest = {
        stageId,
        proposalId: idea.id,
        targetNodeId: selectedNode.id,
      };
      const { status, message } = await ProposalService.selectionMove(query);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      setIsSubmitting(false);

      dispatch(selectionGetCounts(stageId));
      dispatch(
        selectionGetIdeas({
          stageId,
          folder,
        })
      );

      onClose();
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    ProposalService.getAlternativeNodes(stageId, idea.id).then((data) => setNodes(data.data));
  }, [stageId, idea.id]);

  return (
    <ClosingCard isOpened onClose={onClose} className="selection-move-form" type={isMobile ? "gray" : "blue"}>
      {!nodes ? (
        <Loader />
      ) : (
        <>
          <label>Выберите направление</label>
          <Select
            value={selectedNode}
            items={nodes}
            bindTo="title"
            onItemSelect={setSelectedNode}
            closeOnSelect
            label="Направление"
            restrictTo="258px"
            openSide="bottom"
          />
          <div className="selection-move-form__buttons">
            <Button
              classNames="selection-move-form__submit"
              type="filled"
              text="Выбрать"
              isDisabled={!selectedNode}
              isLoading={isSubmitting}
              onClick={move}
              size="m"
            />
          </div>
        </>
      )}
    </ClosingCard>
  );
};

export default SelectionMoveForm;
