import React, { useMemo } from "react";

import { User } from "../../../../types/User.interface";

import { Button, AttachmentPreview } from "@crowd/ui-kit";
import "./DetailsCard.scss";
import { getAvatarSrc } from "../../../../utils";
import SocialNetworks from "../../../presentational/SocialNetworks/SocialNetworks";
import { ISocialLink } from "../../../presentational/SocialNetworks/SocialNetwork.interface";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import MobailModal from "../../../containers/Modals/MobailModal/MobailModal";
import Carousel from "../../../presentational/Carousel/Carousel";
import { handleRole, slidesPerView, handleSocialNetworks, TeamGroupHeaders } from "../Team.constants";
import CommentService from "../../../../services/commentService";

interface Props {
  userInfo?: User;
  setDetailedUser?: Function;
  onClose?: Function;
  userType?: String;
  teamList?: any;
}
const DetailBody = ({ userInfo, setDetailedUser, userType }: Props) => {
  const socialNetworkArray = useMemo(() => handleSocialNetworks(userInfo), []);
  const userRole = useMemo(() => handleRole(userType, userInfo), []);
  const networks: ISocialLink[] = socialNetworkArray;

  const generateButton = () => {
    return (
      <div className="team__user-card--detailed__attachments">
        {userType !== "coordinators" && userInfo?.mediaAttachments?.length > 0 && (
          <div className="team__user-card--detailed__attachments_img">
            {/* todo slider, video lightbox (promo NextModal) */}
            {userInfo.mediaAttachments.map((attachment, idx) => {
              if (attachment.type === "IMAGE") {
                return <AttachmentPreview attachment={attachment.imageAttachment} key={idx} />;
              } else if (attachment.type === "VIDEO") {
                return (
                  <AttachmentPreview
                    attachment={attachment.videoAttachment}
                    key={idx}
                    previewAttachmentId={attachment.imageAttachment.id}
                  />
                );
              }
            })}
          </div>
        )}
        <Button
          text={"Задать вопрос"}
          type={"filled"}
          size={"m"}
          onClick={() => typeof setDetailedUser === "function" && setDetailedUser(userInfo)}
        />
      </div>
    );
  };
  const generateUserName = () => {
    return (
      <div>
        <div className="team__user-card--detailed__name">
          {`${userInfo?.firstName} ${userInfo?.lastName}`}
          {userRole.icon && (
            <Tooltip
              text={userRole.icon === "expert" ? "Главный эксперт" : "Руководитель проекта"}
              idx={"mainExpert" + userInfo?.id}
              place="top"
            >
              <div className={`role-icon ${userRole.icon === "expert" ? "chief-expert-icon" : "project-boss-icon"}`}></div>
            </Tooltip>
          )}
        </div>
        <div className="team__user-card--detailed__role">{userRole.name}</div>
      </div>
    );
  };

  return (
    <div className={`${userType} team__user-card--detailed`}>
      <div className="team__user-card--detailed-wrapper">
        <div className="team__user-card--detailed__info">
          <img
            className="team__user-card--detailed__picture"
            src={getAvatarSrc(userInfo?.pictureId)}
            alt={TeamGroupHeaders[`${userType}`].title}
          ></img>
          <div className="team__user-card--detailed__info_text">
            {generateUserName()}

            {userType === "frontmen" && <SocialNetworks networks={networks}></SocialNetworks>}
          </div>
        </div>

        <div className="team__user-card--detailed__details">
          <div
            className="team__user-card--detailed__description"
            dangerouslySetInnerHTML={{
              __html: userInfo?.description && CommentService.CkBBCodeToHtml(userInfo.description),
            }}
          ></div>
          {generateButton()}
        </div>
      </div>
    </div>
  );
};
const MobileDetailedView = ({ userInfo, setDetailedUser, onClose, userType, teamList }: Props) => {
  return (
    <MobailModal classNames={`${userType}-team__modal`} isOpen={!!userType} onClose={() => onClose()}>
      <React.Fragment key="head"></React.Fragment>
      <React.Fragment key="body">
        <>
          <div className="arrow-back__wrapper" onClick={() => onClose()}>
            <div className="ui-icon ui-icon-arrow-back team__user-card--detailed__close"></div>
            <div className="arrow-back__wrapper_text">{TeamGroupHeaders[`${userType}`].title}</div>
          </div>
          <div className="team__phone_wrapper">
            <div className="team__phone_wrapper__list">
              {userType === "experts" ? (
                <DetailBody userInfo={userInfo} setDetailedUser={setDetailedUser} userType={userType} />
              ) : (
                <Carousel
                  items={teamList[`${userType}`]}
                  bindTo={"userInfo"}
                  loop={true}
                  disableSwipe={teamList[`${userType}`].length <= slidesPerView.details}
                  slidesPerView={slidesPerView.details}
                  navClass={"team__phone_wrapper__list"}
                  initialSlide={teamList[`${userType}`].findIndex((coordinator) => coordinator.id === userInfo?.id)}
                  bottomNav={true}
                >
                  <DetailBody userType={userType} setDetailedUser={setDetailedUser} />
                </Carousel>
              )}
            </div>
          </div>
        </>
      </React.Fragment>
    </MobailModal>
  );
};

export default MobileDetailedView;
