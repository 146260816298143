import React from "react";

import "./SocialNetwork.scss";
import { ISocialLink } from "./SocialNetwork.interface";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

interface Props {
  networks: ISocialLink[];
}

const SocialNetworks = ({ networks }: Props) => {
  const socialNetworkIcons = {
    facebook: "svg-icon svg-icon-facebook",
    twitter: "svg-icon svg-icon-twitter",
    instagram: "svg-icon svg-icon-instagram",
    vkontakte: "svg-icon svg-icon-vk",
    odnoklassniki: "svg-icon svg-icon-odnoklassniki",
    telegram: "svg-icon svg-icon-telegram",
  };

  return (
    <div className="social-network">
      {networks.map((link: ISocialLink, index: number) => (
        <EventsTrackWrapperClick
          key={link.link + index}
          id={["LINK", "CLICK"]}
          needParent
          params={{ replace: [link.type ? link.type.charAt(0).toUpperCase() + link.type.slice(1) : ""] }}
        >
          <a href={link.link} target="_blank" rel="noopener noreferrer">
            <span className={(link.class || socialNetworkIcons[link.type]) + " social-network__item"}></span>
          </a>
        </EventsTrackWrapperClick>
      ))}
    </div>
  );
};

export default SocialNetworks;
