import { Adapter } from "../../services/adapter";
import { RootState } from "../../types/State.interface";
import { ProfileState } from "../../types/User.interface";
import {
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  GET_EXPERTS,
  LOGOUT,
  SET_AVATAR_ID,
  TEAM_LOGIN_FAIL,
  TEAM_LOGIN_HANDLE,
  TEAM_LOGIN_SUCCESS,
  UPDATE_PROFILE_AVATAR_FAIL,
  UPDATE_PROFILE_AVATAR_REQUEST,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPLOAD_PROFILE_AVATAR_FAIL,
  UPLOAD_PROFILE_AVATAR_REQUEST,
  USER_SESSION_FAILED,
  USER_SESSION_HANDLE,
  USER_SESSION_SUCCESS,
} from "../actions/UserActions";

const initialState: ProfileState = {
  userDetails: null,
  loggedIn: false,
  error: "",
  isFetching: false,
  experts: null,
};

export function profileReducer(state: ProfileState = initialState, action: any) {
  switch (action.type) {
    case TEAM_LOGIN_HANDLE:
    case USER_SESSION_HANDLE:
    case UPDATE_PROFILE_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case UPDATE_PROFILE_AVATAR_REQUEST:
    case UPLOAD_PROFILE_AVATAR_REQUEST:
      return { ...state, isFetching: true, error: "" };

    case TEAM_LOGIN_SUCCESS:
    case USER_SESSION_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userDetails: action.payload,
        loggedIn: action.payload.loggedIn,
      };

    case TEAM_LOGIN_FAIL:
    case USER_SESSION_FAILED:
    case UPDATE_PROFILE_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case UPDATE_PROFILE_AVATAR_FAIL:
    case UPLOAD_PROFILE_AVATAR_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    case GET_EXPERTS:
      return { ...state, experts: action.payload };
    case LOGOUT:
      return {
        ...state,
        userDetails: Adapter.toUser({
          firstName: "anonymous",
          lastName: "anonymous",
          id: "_-1",
        }),
        loggedIn: false,
      };
    case SET_AVATAR_ID:
      return {
        ...state,
        userDetails: { ...state.userDetails, pictureId: action.payload },
      };

    default:
      return state;
  }
}

export const selectIsUserReady = ({ user: { userDetails } }: RootState) =>
  !!userDetails?.loggedIn && !userDetails.partiallyFilled && !userDetails.needToCompleteQuestionnaire && userDetails.emailVerified;
