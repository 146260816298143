import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  showInfoModal,
  hideInfoModal,
  hideHeader,
  hideFooter,
  showHeader,
  showFooter,
  setAppColor,
} from "../../../store/actions/LayoutActions";
import UserService from "../../../services/userService";
import { getUser } from "../../../store/actions/UserActions";
import { findUrlParam, declination } from "../../../utils";

import { RootState } from "../../../types/State.interface";
import ChangePasswordForm from "../../containers/Forms/ChangePasswordForm/ChangePasswordForm";
import { PasswordChangeParams } from "../../../types/User.interface";
import { Button } from "@crowd/ui-kit";
import { showErrorToast, showSuccessToast } from "../../../store/actions/ToastActions";
import { AppColorsEnum } from "../../../App.interface";

const ChangePasswordPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const user = useSelector((state: RootState) => state.user);
  const redirectUrl = findUrlParam("redirectUrl", location.search);
  const [isLoading, setIsLoading] = useState(false);
  const firstTime =
    findUrlParam("firstTime", location.search) === "true" ||
    (user.loggedIn &&
      (typeof user.userDetails?.passwordLastChanged === "undefined" ||
        user.userDetails?.passwordLastChanged === null));

  const redirectAfterProfileUpdate = () => {
    if (redirectUrl) {
        history.replace(redirectUrl);
    } else {
        window.location.href = window.location.origin + "/teamLogin";
    }
  };

  useEffect(() => {
    if (!user.loggedIn || !user.userDetails.passwordExpired && user.userDetails.passwordLastChanged) {
      return history.push('/');
    }

    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(hideHeader());
    dispatch(hideFooter());
    return () => {
      dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));
      dispatch(showHeader());
      dispatch(showFooter());
    }
  }, []);

  const submitForm = (params: PasswordChangeParams) => {
    setIsLoading(true);
    UserService.changePassword(params)
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(
            showSuccessToast("Пароль успешно изменен.")
          );
          dispatch(getUser(redirectAfterProfileUpdate));
        } else {
          dispatch(
            showErrorToast(response.data.message)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const title = useMemo(() => {
    if (firstTime) {
      return "Изменение пароля";
    } else if (user.userDetails?.passwordLastChanged) {
      const _minute = 1000 * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      const passwordLastChangedDate = new Date(
        user.userDetails.passwordLastChanged
      );
      const now = new Date();
      const distance = Math.abs(
        passwordLastChangedDate.getTime() - now.getTime()
      );
      const daysLeft = Math.floor(distance / _day);
      const daysDeclined = declination(daysLeft, [" день", " дня", " дней"]);

      return `Требуется обновить пароль. Последний раз пароль был изменен ${daysLeft} ${daysDeclined} назад.`;
    } else {
      return "Требуется обновить пароль.";
    }
  }, [firstTime])

  const renderButton = (buttonText) => {
    return (
      <Button
        type="outlined"
        text={buttonText}
        onClick={() => dispatch(hideInfoModal())}
      />
    );
  };

  return (
    <ChangePasswordForm
      requireCurrentPassword={!firstTime}
      onSubmit={submitForm}
      title={title}
      renderButton={renderButton}
      isLoading={isLoading}
      submitButtonLabel="Сохранить"
    />
  );
};

export default ChangePasswordPage;
