import { AnwersTypeEnum } from "./MyAnswers.interface";

const Tabs = [
  {
    title: "Все",
    value: AnwersTypeEnum.ALL,
  },
  {
    title: "Непрочитанные",
    value: AnwersTypeEnum.UNREAD,
  },
  {
    title: "Отметить все как прочитанные",
    value: AnwersTypeEnum.MARK_ALL_AS_READ,
  },
];

export { Tabs };
