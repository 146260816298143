import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import ReCAPTCHA from "react-google-recaptcha";
import CaptchaMosRu from "../../../presentational/CaptchaMosRu/CaptchaMosRu";
import { ApiStatusCode } from "../../../../types/Common.interface";

import { FileSelector, Input, Select, Textarea } from "@crowd/ui-kit";

import "./SupportForm.scss";
import { RootState } from "../../../../types/State.interface";
import { User } from "../../../../types/User.interface";
import SupportService from "./SupportForm.service";
import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";
import { showErrorToast, showSuccessToast } from "../../../../store/actions/ToastActions";
import { showErrorInfoModal } from "../../../../store/actions/LayoutActions";
import EventsTrackClickInput from "../../EventsTrack/wrappers/helpers/EventsTrackClickInput";
import EventsTrackClickTextarea from "../../EventsTrack/wrappers/helpers/EventsTrackClickTextarea";
import EventsTrackClickSelect from "../../EventsTrack/wrappers/helpers/EventsTrackClickSelect";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

const types = ["Регистрация и авторизация", "Участие в проекте", "Техническая ошибка", "Общие вопросы"];

const maxFiles = 10;
const maxTotalSizeMB = 10;
const maxAllowedDescriptionLength = 700;

interface SupportFormProps {
  close: () => any;
  isOpened: boolean;
}

const SupportForm = (props: SupportFormProps) => {
  const dispatch = useDispatch();
  // const CaptchaKey = useSelector(
  //   (state: RootState) => state.common.environment.captcha
  // );

  const user: User = useSelector((state: RootState) => state.user.userDetails);
  const loggedIn: boolean = useSelector((state: RootState) => state.user.loggedIn);

  // const recaptchaRef = React.useRef();
  const [captchaValue, setCaptchaValue] = useState<any>(undefined);
  const [captchaId, setCaptchaId] = useState("");
  const [captchaImageBase64, setCaptchaImageBase64] = useState("");
  const [email, setEmail] = useState("");
  const [emailValidator, setEmailValidator] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [descriptionValidator, setDescriptionValidator] = useState<any>(null);
  const [type, setType] = useState(types[0]);
  const [images, setImages] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => setEmail(loggedIn ? user?.email : ""), [loggedIn, user?.email]);

  const refreshCaptcha = () => {
    SupportService.generateCaptcha().then((response) => {
      if (response && response.status === 0) {
        setCaptchaId(response.data.id);
        setCaptchaImageBase64(response.data.content);
      }
    });
  };

  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);
    if (formValid()) {
      setSubmitting(true);
      const formData = {};

      formData["from"] = "PLATFORM";
      formData["email"] = email;
      formData["message"] = description;
      formData["type"] = type;
      if (images.length) {
        formData["attachments"] = images.map(img => img.id);
      }

      if (!isVerifiedUser()) {
        formData["captcha"] = captchaValue;
        formData["captchaId"] = captchaId;
      }

      SupportService.submitIssue(formData)
        .then((response) => {
          if (response && response.status === 0) {
            const workHoursMessage =
              " \nОбратите внимание, что служба технической поддержки наиболее оперативно отвечает на сообщения в будние дни с 9:00 до 18:00.";
            const isHoursNeeds = () => new Date().getHours() < 18 && new Date().getHours() >= 9;
            const msg = "Письмо в техподдержку успешно отправлено." + (isHoursNeeds() ? "" : workHoursMessage);

            dispatch(showSuccessToast(msg));
            props.close();
          } else {
            if (response.status === ApiStatusCode.CAPTCHA_VALIDATION_ERROR) {
              refreshCaptcha();
              setCaptchaValue("");
            }
            dispatch(showErrorToast(response.message));
          }

          setSubmitting(false);
        })
        .catch((err) => dispatch(showErrorToast(err.message)));
    }
  };

  const isVerifiedUser = () => loggedIn && user?.emailVerified;

  const isCaptchaValid = () => (isVerifiedUser() ? true : captchaValue);

  const formValid = () => {
    return emailValidator.isValid() && descriptionValidator.isValid() && isCaptchaValid();
  };

  const renderContent = () => {
    return (
      <div className="support-form">
        <EventsTrackClickInput
          placeholder="Введите email"
          isRequired={true}
          value={email}
          onChange={(value) => setEmail(value)}
          shouldValidate={true}
          validateRules={{
            email: true,
          }}
          onInitValidator={(validator) => setEmailValidator(validator)}
          showError={submitted}
        />

        <EventsTrackClickTextarea
          value={description}
          placeholder="Сообщение"
          maxLength={maxAllowedDescriptionLength}
          isRequired={true}
          onChange={(value) => setDescription(value)}
          shouldValidate={true}
          validateRules={{
            minLength: 10,
            maxLength: maxAllowedDescriptionLength,
          }}
          onInitValidator={(validator) => setDescriptionValidator(validator)}
          showError={submitted}
        />

        <EventsTrackClickSelect value={type} items={types} closeOnSelect={true} label="Категория вопроса" onItemSelect={(type) => setType(type)} />

        {!isVerifiedUser() && (
          <div className={`recaptcha-wrapper ${submitted && !captchaValue ? "recaptcha-wrapper--error" : ""}`}>
            {/* <ReCAPTCHA ref={recaptchaRef} sitekey={CaptchaKey} style={{ marginTop: "20px" }} onChange={setCaptchaValue} /> */}
            <CaptchaMosRu
              captchaValue={captchaValue}
              captchaImageBase64={captchaImageBase64}
              refreshCaptcha={refreshCaptcha}
              setCaptchaValue={(val) => setCaptchaValue(val)}
            />
          </div>
        )}

        <FileSelector
          upload={true}
          text={
            <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: ["Прикрепить файл"], partial: true, rememberPrev: true }}>
              <span className="flex">
                <i className="ui-icon ui-icon-attach" />
                Прикрепить файл
              </span>
            </EventsTrackWrapperClick>
          }
          hint={`Вы можете загрузить до ${maxFiles} изображений совокупным объемом до ${maxTotalSizeMB} Мб., допустимы форматы .jpeg, .jpg, .png`}
          isMultiple
          maxFiles={maxFiles}
          maxTotalSizeMB={maxTotalSizeMB}
          allowedExtensions={["image/jpeg", "image/png"]}
          onChange={(images) => setImages(images)}
          showInfoModal={(msg) => {
            dispatch(showErrorInfoModal(msg));
          }}
          attachmentsLength={images.length}
        />
      </div>
    );
  };

  // user={user}
  return (
    <SubmitForm isSubmitting={submitting} isOpen={props.isOpened} onClose={props.close} onSubmit={submitForm}>
      <React.Fragment key="header">Сообщение в службу поддержки</React.Fragment>
      <React.Fragment key="body">{renderContent()}</React.Fragment>
      <React.Fragment key="footer"></React.Fragment>
    </SubmitForm>
  );
};

export default SupportForm;
