import { AppEditor, Button, Checkbox, Input } from "@crowd/ui-kit";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppColorsEnum } from "../../../../App.interface";
import { setAppColor } from "../../../../store/actions/LayoutActions";
import { RootState } from "../../../../types/State.interface";
import DatePicker from "../../../presentational/Controls/DatePicker/DatePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";

import TimeInput from "../../../presentational/Controls/TimeInput/TimeInput";
import PreviewModal from "../../../containers/Modals/PreviewModal/PreviewModal";
import "./ComposePost.scss";
import { Adapter } from "../../../../services/adapter";
import AddPostModal from "../AddPostModal/AddPostModal";
import BlogService from "../../../../services/blogService";
import { ApiStatusCode } from "../../../../types/Common.interface";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import ExpertPostCard from "../../../presentational/Cards/ExpertPostCard/ExpertPostCard";
import CommentService from "../../../../services/commentService";
import { rusStringToDate } from "../../../../utils";

dayjs.extend(customParseFormat);
dayjs.locale("ru");

const ComposePost = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const project = useSelector((state: RootState) => state.project.current);

  const [time, setTime] = useState(getInitialTime());
  const [date, setDate] = useState(getInitialDate());
  const [title, setTitle] = useState(getInitialTitle());
  const [text, setText] = useState("");
  const [pinned, setPinned] = useState(getInitialPinned());
  const [titleValidator, setTitleValidator] = useState(null);

  const [teamPosts, setTeamPosts] = useState(null);
  const [teamPost, setTeamPost] = useState(null);

  const [inPreviewMode, setInPreviewMode] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showActiveDirection, setShowActiveDirection] = useState(
    getInitialActiveDirection()
  );
  const [important, setImportant] = useState(getInitialImportant());

  const [addPostModalOpen, setAddPostModalOpen] = useState(false);

  useEffect(() => {
    BlogService.getTeamPosts({ type: "USER" as const, size: 100 })
      .then((response) => {
        setTeamPosts(response.data);
      })
      .catch((err) => {
        dispatch(showErrorToast(err.message));
      });
  }, []);

  useEffect(() => {
    if (teamPosts && props.editedPost) {
      setTeamPost(
        teamPosts.find(
          (p) => p.id === props.editedPost?.properties?.linkToTeamPost
        )
      );
    }
  }, [teamPosts]);

  const submit = () => {
    setSubmitted(true);

    if (formValid()) {
      const formData: any = {
        postId: props.editedPost?.id || null,
        blogId: "project_diary_id",
        date: getDate(),
        title,
        content: CommentService.CkHtmlToBBCode(text),
        important,
        pinned,
        properties: {
          linkToTeamPost: teamPost?.id,
          showActiveDirection,
          alternativeTitle: teamPost?.title,
        },
      };

      const request = props.editedPost
        ? BlogService.updateBlogPost(formData)
        : BlogService.add(formData);

      setSubmitting(true);
      request
        .then((response) => {
          if (response.status === ApiStatusCode.OK) {
            dispatch(showSuccessToast(response.message));
            props.onAddPost(response.data);
            dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));
            return;
          }

          dispatch(showErrorToast(response.message));
        })
        .catch((err) => {
          dispatch(showErrorToast(err.message));
        })
        .finally(() => setSubmitting(false));
    }
  };

  const formValid = () => {
    return time && date && titleValidator.isValid();
  };

  function getInitialTime() {
    return dayjs(props.editedPost?.createdTimestamp).format("HH:mm") || null;
  }

  function getInitialDate() {
    return (
      dayjs(props.editedPost?.createdTimestamp).format("DD.MM.YYYY") ||
      dayjs().format("DD.MM.YYYY")
    );
  }

  function getInitialTitle() {
    return props.editedPost ? props.editedPost.title : "";
  }

  function getInitialText() {
    return props.editedPost
      ? CommentService.CkBBCodeToHtml(props.editedPost.rawContent)
      : "";
  }

  function getInitialActiveDirection() {
    return props.editedPost
      ? props.editedPost.properties?.showActiveDirection
      : null;
  }

  function getInitialPinned() {
    return props.editedPost ? props.editedPost.pinned : false;
  }

  function getInitialImportant() {
    return props.editedPost ? props.editedPost.important : false;
  }

  const getPostPreviewData = () => {
    const partials = {
      title,
      text,
      author: user,
      createdTimestamp: dayjs().format("DD.MM.YYYY HH:mm"),
    };
    return Adapter.toPost(partials);
  };

  const getDate = () => {
    if (!date || !time) return null;

    return dayjs(rusStringToDate(date))
      .set("hour", +time.split(":")[0])
      .set("minute", +time.split(":")[1])
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  };

  return (
    <div className="compose-post">
      <h4>Новая запись</h4>

      <div className="compose-post__controls">
        <div className="compose-post__time">
          <TimeInput value={time} onChange={setTime} />
          <DatePicker
            onChange={(date) => setDate(dayjs(date).format("DD.MM.YYYY"))}
            date={date}
            disabledDays={{
              before: dayjs(project.start).toDate(),
              after: dayjs(project.finish).toDate(),
            }}
          />
        </div>

        <Input
          placeholder="Заголовок"
          maxLength={1000}
          value={title}
          onChange={(value) => setTitle(value)}
          shouldValidate={true}
          validateRules={{
            maxLength: 1000,
            minLength: 10,
          }}
          onInitValidator={(validator) => setTitleValidator(validator)}
          showError={submitted}
        />

        <AppEditor
          defaultText={getInitialText()}
          text={text}
          onChange={(value) => setText(value)}
          validateRules={{
            maxLength: 1000000,
          }}
          editorSittings={{
            placeholder: "Введите текст",
          }}
          hint={"Не более 1000000 символов"}
          showError={submitted}
        />

        {!teamPost && (
          <Button
            type="outlined"
            text="Добавить пост"
            icon={<span className="ui-icon ui-icon-add"></span>}
            size="m"
            onClick={() => setAddPostModalOpen(true)}
          />
        )}

        {teamPost && (
          <div className="compose-post__team-post">
            <Button
              type="outlined"
              text="Удалить пост"
              icon={<span className="ui-icon ui-icon-close-round"></span>}
              size="m"
              onClick={() => setTeamPost(null)}
            />

            <div>{teamPost.title}</div>
          </div>
        )}

        <Checkbox
          html="Закрепить пост"
          defaultValue={pinned}
          onChange={(value) => setPinned(value)}
          key={"pin"}
        />

        <Checkbox
          html="Добавить список этапов"
          defaultValue={showActiveDirection}
          onChange={(value) => setShowActiveDirection(value)}
          key={"add"}
        />

        <Checkbox
          html="Важный"
          defaultValue={important}
          onChange={(value) => setImportant(value)}
          key={"imp"}
        />
      </div>

      <div className="compose-post__footer">
        <Button
          type="outlined-grey"
          text="Отменить"
          size="m"
          onClick={() => {
            props.onClose();
            dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));
          }}
        />
        <Button
          type="outlined"
          text="Предпросмотр"
          size="m"
          onClick={() => setInPreviewMode(true)}
        />
        <Button
          type="filled"
          text="Сохранить"
          size="m"
          onClick={submit}
          isLoading={submitting}
        />
      </div>

      {inPreviewMode && (
        <PreviewModal onClose={() => setInPreviewMode(false)}>
          <ExpertPostCard post={getPostPreviewData()} background={"#F2F4F7"} />
        </PreviewModal>
      )}

      {addPostModalOpen && teamPosts && (
        <AddPostModal
          teamPosts={teamPosts}
          onAttach={(post) => setTeamPost(post)}
          onClose={() => setAddPostModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ComposePost;
