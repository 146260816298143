import React from "react";
import ContentLoader from "react-content-loader";
import "./UserLoader.scss";

export default function UserLoader(props) {
  return (
    <div className="user-loader">
      <ContentLoader
        height="100%"
        width="100%"
        viewBox="0 0 800 575"
        gradientRatio={0.2}
        speed={2}
        {...props}
        backgroundColor="#e8e8e8"
        foregroundColor="#ecebeb"
        title="Загрузка..."
      >
        {/* titles */}

        <circle cx="20" cy="20" r="20" />
        <rect x="50" y="9" rx="2" ry="2" width="17%" height="11" />
        <rect x="50" y="25" rx="2" ry="2" width="13%" height="8" />

        <circle cx="20" cy="80" r="20" />
        <rect x="50" y="69" rx="2" ry="2" width="17%" height="11" />
        <rect x="50" y="85" rx="2" ry="2" width="13%" height="8" />
      </ContentLoader>
    </div>
  );
}
