import { API } from "./api";
import { IBaseResponse } from "../App.interface";
import { User } from "../types/User.interface";

const url = "/api/warning";

interface IWarningParams {
  text: string;
  userId: string;
}

export interface IWarning {
  id: string;
  created: string; // date "2021-06-07T14:43:30.421+03:00"
  text: string;
  author: User;
}

const WarningService = {
  getWarnings: (userId: string): Promise<IBaseResponse<IWarning[]>> => {
    return API.get(`${url}/get?userId=${userId}`).then(({ data }) => {
      return data;
    });
  },

  addWarning: (data: IWarningParams): Promise<IBaseResponse<string>> => {
    return API.post(`${url}/add`, data).then(({ data }) => {
      return data;
    });
  },
};

export default WarningService;