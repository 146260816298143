import { Button } from "@crowd/ui-kit";
import React from "react";

import "./Tabs.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../utils/constants/widthConstants";
import MobileTab from "./MobileTab/MobileTab";

export interface Tab {
  id: string | number;
  name?: string;
  count?: number;
  disabled?: boolean;
  iconClass?: string;
  activeIconClass?: string;
  show?: boolean;
}

interface TabsProps {
  currentTabId: string | number;
  tabs: Tab[] | any;
  onTabClick: (tab: any) => any;
  onTabLeave?: (tab: any) => void;
  getTabLabel?: (tab: Tab) => string;
  classes?: string;
  isUseMobileTab?: boolean;
}

const Tabs = ({
  currentTabId,
  tabs = [],
  onTabClick,
  onTabLeave,
  getTabLabel,
  classes,
  isUseMobileTab,
}: TabsProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const getButtonType = (tabId) => {
    return currentTabId === tabId ? "filled" : "outlined";
  };

  const getTabText = (tab) => {
    let label = tab.name || "";
    if (getTabLabel) {
      label = getTabLabel(tab);
    }
    return label + (typeof tab.count === "number" ? ` ${tab.count}` : "");
  };

  const _onTabClick = (tab) => {
    onTabLeave?.(tabs.find((tab) => tab.id === currentTabId));
    onTabClick?.(tab);
  }

  return (
    <>
      {isMobile && isUseMobileTab ? (
        <div className="tabs-mobile">
          {tabs.map((tab) => (
            <MobileTab
              key={tab.id}
              tab={tab}
              currentTabId={currentTabId}
              onTabClick={_onTabClick}
            />
          ))}
        </div>
      ) : (
        <div className={`tabs ${classes || ""}`}>
          {tabs.map((tab, key) => (
            <Button
              type={getButtonType(tab.id)}
              size="m"
              onClick={() => _onTabClick(tab)}
              key={key}
              text={getTabText(tab)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Tabs;
