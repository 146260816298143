import { DiscussionState } from "../../types/Discussion.interface";
import { GET_DISCUSSION_BY_TYPE } from "../actions/DiscussionActions";

const initialState: DiscussionState = {
  byType: null,
};

export function discussionReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_DISCUSSION_BY_TYPE:
      return {
        ...state,
        byType: {
          ...state.byType,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
