import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "@crowd/ui-kit";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import CommentService from "../../../../services/commentService";
import { RootState } from "../../../../types/State.interface";
import { StageListEntity } from "../StageList/StageList.models";
import { StageTypes } from "../../../../types/Stage.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";

import "./NodeModal.scss";

enum ButtonType {
  CLOSE = "CLOSE",
  PARTICIPATE = "PARTICIPATE",
}

const participationStages = [StageTypes.GENERATION, StageTypes.VOTING];

const NodeModal = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const getNodeDate = (entity) => {
    let start = entity.stages
      .map((stage) => stage.start)
      .reduce((acc, curr) => {
        if (!acc) return new Date(curr);

        return new Date(curr) < acc ? new Date(curr) : acc;
      }, null);

    let finish = entity.stages
      .map((stage) => stage.finish)
      .reduce((acc, curr) => {
        if (!acc) return new Date(curr);

        return new Date(curr) > acc ? new Date(curr) : acc;
      }, null);

    return { start, finish };
  };

  const getFormattedDate = (start, finish) => {
    if (!start || !finish) return;

    return (
      <>
        {dayjs(start).format("DD.MM")} - {dayjs(finish).format("DD.MM.YYYY")}
      </>
    );
  };

  const getContent = () => {
    return CommentService.CkBBCodeToHtml(props.entity.description);
  };

  const getStageName = (stage): string => {
    switch (stage.type) {
      case "EXPERT_SELECTION":
        return "selection";
      case "GENERATION":
        return "generation";
      case "SIMPLE_DISCUSSION":
      case "PROJECT_DISCUSSION":
        return "discussion";
      case "VOTING":
        return "voting";
      default:
        return "";
    }
  };

  const getStageUrl = (stage) => {
    return `/${getStageName(stage)}/${stage.id}`;
  };

  const participate = (stage) => {
    const url = getStageUrl(stage);

    if (!user.loggedIn) {
      const data: AuthActionParam = {
        component: ComponentType.StageList,
        type: AuthActionType.Redirect,
        args: {
          redirectUrl: url,
        },
      };

      const redirectToStage = new AuthAction(data);
      return appContext.sudirService.authWithActions([redirectToStage]);
    }

    history.push(url);
  };

  const getActiveStages = (entity: StageListEntity) => entity.stages.filter((s) => s.status === "STARTED") || [];

  const getParticipationStages = (entity: StageListEntity) =>
    getActiveStages(entity).filter((stage) => participationStages.includes(stage.type));

  const hasParticipationStages = (entity: StageListEntity) => Boolean(getParticipationStages(entity).length);

  const closeButtonAvailable = (entity: StageListEntity) => {
    const { participant, teamMember, chiefExpert, expert } = user;

    if (teamMember || chiefExpert || expert) return true;

    if (hasParticipationStages(entity)) {
      if (user.loggedIn) {
        if (participant) return false;
      }
    }

    return true;
  };

  const renderButton = (type: ButtonType) => {
    const { entity } = props;

    switch (type) {
      case ButtonType.CLOSE: {
        return closeButtonAvailable(entity) && <Button type="outlined" text="Закрыть" size="m" onClick={props.onClose}></Button>;
      }

      case ButtonType.PARTICIPATE: {
        return (
          user?.participant &&
          hasParticipationStages(entity) && (
            <Button type="filled" text="Участвовать" size="m" onClick={() => participate(getParticipationStages(entity)[0])}></Button>
          )
        );
      }

      default:
        <></>;
    }
  };

  return (
    <Modal isOpen={true} onClose={props.onClose} classNames="node-modal">
      <React.Fragment key="subhead">
        {props.entity.title}

        <div>
          <em>{getFormattedDate(getNodeDate(props.entity).start, getNodeDate(props.entity).finish)}</em>
        </div>
      </React.Fragment>
      <React.Fragment key="body">
        <div dangerouslySetInnerHTML={{ __html: getContent() }}></div>
      </React.Fragment>
      <React.Fragment key="footer">
        {renderButton(ButtonType.CLOSE)}
        {renderButton(ButtonType.PARTICIPATE)}
      </React.Fragment>
    </Modal>
  );
};

export default NodeModal;
