import BlogService, {
  AddBlogpostSummaryParams,
  UpdateBlogPostParams,
  GetAllPersonalPostsParams,
} from "../../services/blogService";
import { AxiosResponse } from "axios";
import { BlogPost, BlogPostSummary } from "../../types/BlogPost.interface";
import {
  ApiResponse,
  ApiStatusCode,
  PagingParameters,
} from "../../types/Common.interface";
import { showErrorToast, showSuccessToast } from "./ToastActions";

export const GET_ALL_PERSONAL_POSTS = "GET_ALL_PERSONAL_POSTS";
export const GET_MINE_BLOG = "GET_MINE_BLOG";
export const GET_BLOG_POST = "GET_BLOG_POST";
export const DELETE_BLOGPOST_COMPLETE = "DELETE_BLOGPOST_COMPLETE";
export const UPDATE_BLOGPOST_COMPLETE = "UPDATE_BLOGPOST_COMPLETE";
export const UPDATE_BLOGPOST_ACTIVITY_COMPLETE =
  "UPDATE_BLOGPOST_ACTIVITY_COMPLETE";
export const ADD_BLOGPOST_SUMMARY_COMPLETE = "ADD_BLOGPOST_SUMMARY_COMPLETE";
export const ADD_BLOGPOST_SUMMARY_FAILED = "ADD_BLOGPOST_SUMMARY_FAILED";
export const UPDATE_BLOGPOST_SUMMARY_COMPLETE =
  "UPDATE_BLOGPOST_SUMMARY_COMPLETE";
export const UPDATE_BLOGPOST_SUMMARY_FAILED = "UPDATE_BLOGPOST_SUMMARY_FAILED";
export const APPROVE_BLOGPOST_COMPLETE = "APPROVE_BLOGPOST_COMPLETE";
export const APPROVE_BLOGPOST_FAILED = "APPROVE_BLOGPOST_FAILED";

export const getBlogPost =
  (
    id: string,
    successCb?: (data: ApiResponse<BlogPost>) => any,
    finallyCb?: (data: ApiResponse<BlogPost>) => void
  ) =>
  (dispatch) => {
    return BlogService.getBlogPost(id).then((res: ApiResponse) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: GET_BLOG_POST, payload: res.data });
        successCb && successCb(res);
      } else {
        console.warn(res.message);
      }

      finallyCb && finallyCb(res);
    });
  };

export const getAllPersonalPosts =
  (params?: GetAllPersonalPostsParams) => (dispatch) => {
    return BlogService.getAllPersonalPosts(params)
      .then((data: AxiosResponse<BlogPost[]>) => {
        dispatch({
          type: GET_ALL_PERSONAL_POSTS,
          payload: data?.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const getMineBlog = (params?: PagingParameters) => (dispatch) => {
  return BlogService.getMine()
    .then(({ data }: AxiosResponse<BlogPost[]>) => {
      dispatch({
        type: GET_MINE_BLOG,
        payload: data,
      });
    })
    .catch((err) => console.log(err));
};

export const deleteBlogPost =
  (id: string, successCb?: () => any) => (dispatch) => {
    return BlogService.deleteBlogPost(id).then((res: ApiResponse) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: DELETE_BLOGPOST_COMPLETE });
        dispatch(showSuccessToast("Пост успешно удален."));
        successCb && successCb();
        return;
      }

      dispatch(showErrorToast(res?.message || "Ошибка удаления поста."));
    });
  };

export const updateBlogPost =
  (
    data: UpdateBlogPostParams,
    successCb?: (res: ApiResponse<BlogPost>) => any
  ) =>
  (dispatch) => {
    return BlogService.updateBlogPost(data).then(
      (res: ApiResponse<BlogPost>) => {
        if (res.status === ApiStatusCode.OK) {
          dispatch({ type: UPDATE_BLOGPOST_COMPLETE, payload: res.data });
          dispatch(
            showSuccessToast(
              `${data.draft ? "Черновик поста" : "Пост"} успешно обновлен.`
            )
          );
          successCb && successCb(res);
          return;
        }

        dispatch(showErrorToast(res?.message || "Ошибка обновления поста."));
      }
    );
  };

export const updateBlogPostActivity =
  (
    postId: string,
    operation: "stopActivity" | "resumeActivity",
    successCb?: (active: boolean, res: ApiResponse<any>) => void
  ) =>
  (dispatch) => {
    return BlogService.changePostActivity(postId, operation).then(
      (res: ApiResponse<any>) => {
        if (res.status === ApiStatusCode.OK) {
          dispatch({
            type: UPDATE_BLOGPOST_ACTIVITY_COMPLETE,
            payload: operation === "resumeActivity",
          });
          successCb && successCb(operation === "resumeActivity", res);
          return;
        }

        dispatch(showErrorToast(res.message));
      }
    );
  };

export const addBlogpostSummary =
  (
    data: AddBlogpostSummaryParams,
    successCb?: (res: ApiResponse<BlogPostSummary>) => any,
    errorCb?: (err) => any
  ) =>
  (dispatch) => {
    return BlogService.addBlogpostSummary(data).then(
      (res: ApiResponse<BlogPostSummary>) => {
        if (res.status === ApiStatusCode.OK) {
          dispatch({ type: ADD_BLOGPOST_SUMMARY_COMPLETE });
          dispatch(showSuccessToast("Резюме успешно добавлено."));
          successCb && successCb(res);
        } else {
          dispatch({ type: ADD_BLOGPOST_SUMMARY_FAILED });
          dispatch(showErrorToast(res.message));
          errorCb && errorCb(res.message);
        }
      }
    );
  };

export const updateBlogpostSummary =
  (
    data: AddBlogpostSummaryParams,
    successCb?: (res: ApiResponse<BlogPostSummary>) => any,
    errorCb?: (err) => any
  ) =>
  (dispatch) => {
    return BlogService.updateBlogpostSummary(data).then(
      (res: ApiResponse<BlogPostSummary>) => {
        if (res.status === ApiStatusCode.OK) {
          dispatch({ type: UPDATE_BLOGPOST_SUMMARY_COMPLETE });
          dispatch(showSuccessToast("Резюме успешно обновлено."));
          successCb && successCb(res);
        } else {
          dispatch({ type: UPDATE_BLOGPOST_SUMMARY_FAILED });
          dispatch(showErrorToast(res.message));
          errorCb && errorCb(res.message);
        }
      }
    );
  };

export const approveBlogpostSummary =
  (id: string, successCb?: () => any) => (dispatch) => {
    return BlogService.approveBlogpostSummary(id).then((res: ApiResponse) => {
      if (res.status === ApiStatusCode.OK) {
        dispatch({ type: APPROVE_BLOGPOST_COMPLETE });
        dispatch(showSuccessToast("Резюме успешно опубликовано."));
        successCb && successCb();
      } else {
        dispatch({ type: APPROVE_BLOGPOST_FAILED });
        dispatch(showErrorToast(res.message));
      }
    });
  };
