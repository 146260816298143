import { Swiper, SwiperSlide } from "swiper/react";
import "./LikePopupBody.scss";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearVotedUsers, getVotedUsers } from "../../../../../store/actions/CommentActions";
import { RootState } from "../../../../../types/State.interface";
import { getAvatarSrc } from "../../../../../utils";
import { Loader } from "@crowd/ui-kit";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

interface LikePopupBodyProps {
  onClick: (user) => void;
  onClose: () => void;
  commentId: string;
}

const step = 6;

const LikePopupBody = ({ onClick, onClose, commentId }: LikePopupBodyProps) => {
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.comment.votedUsers);
  const isLoading = useSelector((state: RootState) => state.comment.isLoading);
  const pagination = useSelector((state: RootState) => state.comment.votedUsersPags);

  const onClickHandler = (user) => {
    onClick(user);
    onClose();
  };

  const fetchVotedUsers = (page, size) => {
    dispatch(getVotedUsers({ commentId: commentId, page: page, size: size }));
  };

  const onSlideChangeTransitionEnd = (swiper) => {
    if (!users.length || users.length >= pagination.totalElements) return;
    if (users.length - step * 2 > swiper.activeIndex) return;
    fetchVotedUsers(pagination.pageNumber + 1, 12);
  };

  useEffect(() => {
    fetchVotedUsers(0, 18);
    return () => {
      dispatch(clearVotedUsers());
    };
  }, []);

  return (
    <Swiper
      className="likePopup"
      slidesPerView={step}
      slidesPerGroup={step}
      spaceBetween={8}
      width={280}
      onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
      centeredSlides={!users.length}
    >
      {users.map((user) => (
        <SwiperSlide key={user.id}>
          <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} params={{ replace: ["Участник"], partial: true, remember: true }}>
            <div className="likePopup__avatar" onClick={() => onClickHandler(user)}>
              <img src={getAvatarSrc(user.pictureId)} alt="Пользователь" className="likePopup__avatarImg" />
            </div>
          </EventsTrackWrapperClick>
        </SwiperSlide>
      ))}

      {isLoading && !users.length && <Loader />}
    </Swiper>
  );
};

export default LikePopupBody;
