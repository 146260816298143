import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import PersonalService, {
  CommentsQueryParams,
} from "../../../../services/personalService";
import { DiscussionTypeEnum } from "../../../../types/Personal.interface";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import DatePicker from "../../../presentational/Controls/DatePicker/DatePicker";
import { DatePickerType } from "../../../presentational/Controls/DatePicker/DatePicker.interface";
import { moduleCards } from "../Profile.constants";
import { ModuleTypeEnum } from "../Profile.interface";
import ProfileSubContent from "../ProfileSubContent/ProfileSubContent";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import "./MyComments.scss";
import { getUrlParam } from "../../../../utils/urlUtils";
import useQuery from "../../../../utils/hooks/useQuery";
import { getProfileTab } from "../Profile.helper";

const MyComments = () => {
  const dispatch = useDispatch();
  const { type } = useQuery();

  const [dataCount, setDataCount] = useState(null);
  const [tab, setTab] = useState(getProfileTab(type, getTabs()));
  const [page, setPage] = useState(getPageFromUrl("page"));
  const [comments, setComments] = useState(null);
  const [paging, setPaging] = useState(null);
  const [date, setDate] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabledDays, setDisabledDays] =  useState({
    after: dayjs().toDate(),
    before: dayjs().subtract(1, "month").toDate(),
  })

  useEffect(() => {
    tab && loadComents();
  }, [tab, page, date]);

  // API calls

  const loadComents = () => {
    let query: CommentsQueryParams = {
      type: tab.type as DiscussionTypeEnum,
      page,
      size: 10,
    };

    if (date && date.from && date.to) {
      query.from = dayjs(date.from).format("YYYY-MM-DD");
      query.to = dayjs(date.to).format("YYYY-MM-DD");
    }

    setLoading(true);
    PersonalService.getComments(query)
      .then((response) => {
        setComments(response.data);
        setPaging(response.paging);
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => {
        setLoading(false);
      });
  };

  const renderPanel = () => {
    const modifiers = { start: from, end: to };
    const onHandleChange = (date) => {
      setDate(date)
      setDisabledDays(
        {
          after: dayjs().toDate(),
          before: dayjs(date.from).toDate(),
        }
      )
    }

    return (
      <div className="my-comments__panel">
        <DatePicker
          type={DatePickerType.RANGED}
          onChange={onHandleChange}
          date={date}
          modifiers={modifiers}
          disabledDays={disabledDays}
        />
      </div>
    );
  };

  function getTabs() {
    const tabs = moduleCards.find(
      (m) => m.type === ModuleTypeEnum.COMMENTS
    ).tabs;

    if (dataCount) {
      return tabs.map((tab) => {
        const newTab = { ...tab };
        newTab.count = dataCount[newTab.type.toLowerCase()];
        return newTab;
      });
    }

    return tabs;
  }

  function getPageFromUrl(pageName) {
    return Math.max(
      Number(getUrlParam(pageName, window.location.search)) - 1,
      0
    );
  }

  const handleRemoveComment = () => {
    if (comments.length === 1 && page > 0) {
      setPaging((paging) => ({...paging, pageNumber: paging.pageNumber - 1}))
      setPage((page) => page - 1);
    } else {
      loadComents();
    }
  }

  const renderList = () =>
    comments?.map((comment) => {
      const formLink = (item, shouldNavigate?, shouldOpen?) => {
        const isDiscussion = item.stageId;
        if (isDiscussion) {
          return `/discussion/${item.stageId}/${item.id}?navigate=true`;
        }

        return `/users/user/post/${item.author.id}/${item.postId}/${item.id}?navigate=true`;
      };
      return (
        <CommentCard
          key={comment.id}
          formNavigationLinkFn={formLink}
          comment={comment}
          commentInfoHintType={"FULL"}
          showUserAvatar={false}
          showLabel={true}
          showInfoIcon={false}
          onRemoveComment={handleRemoveComment}
        />
      );
    });

  const renderEmpty = () => (
    <div className="empty-content">Вы не написали ни одного комментария</div>
  );

  // RENDER

  return (
    <div className="my-comments">
      <ProfileSubContent
        title="Мои комментарии"
        tabs={getTabs()}
        paging={paging}
        panel={renderPanel()}
        onTabChange={(tab) => {
          setTab(tab);
          setPage(0);
        }}
        onPageChange={(page) => setPage(page)}
        isLoading={loading}
      >
        <div className="my-comments__content">
          {comments?.length ? renderList() : renderEmpty()}
        </div>
      </ProfileSubContent>
    </div>
  );
};

export default MyComments;
