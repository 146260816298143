import { Button, Checkbox, Search } from "@crowd/ui-kit";
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AppColorsEnum } from "../../../App.interface";
import { AppContext } from "../../../Root";
import BlogService, { GetAllPersonalPostsParams } from "../../../services/blogService";
import UserService, { FindUserParams } from "../../../services/userService";
import { setAppColor, showFooter, showHeader, showUserModal } from "../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../types/Common.interface";
import { RootState } from "../../../types/State.interface";
import { getAvatarSrc, relativeTime } from "../../../utils";
import { getUrlParam } from "../../../utils/urlUtils";
import UserModal from "../../containers/Modals/UserModal/UserModal";
import CommentCard from "../../presentational/Cards/CommentCard/CommentCard";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import Pagination from "../../presentational/Pagination/Pagination";
import UserAvatar from "../../presentational/UserAvatar/UserAvatar";
import { User } from "../../../types/User.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import UserLoader from "../../presentational/Loaders/UserLoader/UserLoader";
import "./ParticipantsPage.scss";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import { shortDesktopWidth } from "../../../utils/constants/widthConstants";
import { useMediaQuery } from "react-responsive";
import { getDisplayName, renderHatIfUserNameIsHidden } from "../../../utils/user.utils";
import { District } from "../../../types/District.interface";
import DistrictService from "../../../services/districtService";

const ParticipantsPage = () => {
  const isDescktop = useMediaQuery({
    query: `(min-width: ${shortDesktopWidth}px)`,
  });
  const scrollToRef = useRef<null | HTMLDivElement>(null);

  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const [value, setValue] = useState("");
  const [districtSearch, setDistrictSearch] = useState("");
  const [onlineOnly, setOnlineOnly] = useState(false);
  const [users, setUsers] = useState<User[]>(null);
  const [blogs, setBlogs] = useState(null);
  const [districts, setDistricts] = useState<District[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<District>(null);
  const [userPage, setUserPage] = useState(getPageFromUrl("userPage"));
  const [blogPage, setBlogPage] = useState(getPageFromUrl("blogPage"));
  const [userPaging, setUserPaging] = useState(null);
  const [blogPaging, setBlogPaging] = useState(null);
  const [participant, setParticipant] = useState<User>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParticipantsFocused, setSearchParticipantsFocused] = useState(false);
  const searchParticipantsRef = useRef<Search>();
  const isSelectedDistrict = !!selectedDistrict;

  const filteredDistricts = useMemo(() => {
    const search = districtSearch.toLowerCase().trim();
    if (!search) {
      return districts;
    }
    return districts.filter(({ name }) => name.toLowerCase().includes(search));
  }, [districtSearch, districts]);

  useEffect(() => {
    dispatch(showHeader());
    dispatch(showFooter());
    dispatch(setAppColor(AppColorsEnum.DARKBLUE));

    DistrictService.getByQuery().then(({ data }) => setDistricts(data));
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    const actions = appContext.sudirService.getActions(ComponentType.ParticipantsPage);
    if (actions.length) {
      const redirectAction = actions.find((act) => act.type === AuthActionType.Redirect);
      if (redirectAction) {
        history.push(redirectAction.args.redirectUrl);
      }
    } else {
      const actions = appContext.sudirService.getActions(ComponentType.ProjectParticipants);
      if (actions?.length) {
        const openUserDetailsModalAction = actions.find((act) => act.type === AuthActionType.OpenUserDetailsModal);
        if (openUserDetailsModalAction) {
          UserService.getPerson(openUserDetailsModalAction.args.userId).then(({ data }) => {
            dispatch(showUserModal(data));
          });
        }
      }
    }
    
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [value, userPage, onlineOnly, isDescktop, selectedDistrict]);

  useEffect(() => {
    fetchBlogs(blogPage);
  }, [blogPage]);

  useEffect(() => {
    const onFocus = () => {
      setSearchParticipantsFocused(true);
      if (isSelectedDistrict) {
        setDistrictSearch("");
      }
    };
    const onBlur = () => {
      setSearchParticipantsFocused(false);
    };

    const el = searchParticipantsRef.current?.inputRef.current;
    el?.addEventListener("focus", onFocus);
    el?.addEventListener("blur", onBlur);
    return () => {
      el?.removeEventListener("focus", onFocus);
      el?.removeEventListener("blur", onBlur);
    };
  }, [isSelectedDistrict]);

  // API
  const fetchUsers = () => {
    const query = {
      query: value,
      type: "PARTICIPANT",
      sort: ["fullName,ASC"],
      size: isDescktop ? 16 : 8,
      page: userPage,
      onlineOnly,
    } as FindUserParams;

    if (selectedDistrict) {
      query.districtId = selectedDistrict.id;
    }

    setIsLoading(true);
    UserService.findUser(query)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          setUsers(response.data);
          setUserPaging(response.paging);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchBlogs = (page) => {
    const query = {
      size: 3,
      page,
      includeTeamMemberPosts: false,
    } as GetAllPersonalPostsParams;

    BlogService.getAllPersonalPosts(query).then((response) => {
      if (response.status === ApiStatusCode.OK) {
        setBlogs(response.data);
        setBlogPaging(response.paging);
      }
    });
  };

  // Get
  function getPageFromUrl(pageName) {
    return Math.max(Number(getUrlParam(pageName, window.location.search)) - 1, 0);
  }

  const goToBlog = () => {
    const goPath = "/profile/blog";

    if (!currentUser.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.ParticipantsPage,
        type: AuthActionType.Redirect,
        args: {
          redirectUrl: goPath,
        },
      };

      const personalBlogContainerData: AuthActionParam = {
        component: ComponentType.MyBlog,
        type: AuthActionType.OpenBlogModal,
        args: {
          openModal: true,
        },
      };

      const redirectToBlog = new AuthAction(thisContainerData);
      const openBlogModal = new AuthAction(personalBlogContainerData);
      return appContext.sudirService.authWithActions([redirectToBlog, openBlogModal], true);
    }

    history.push({
      pathname: goPath,
      search: "",
      state: { openBlogModal: true },
    });
  };

  // RENDER
  const renderBlogCard = () => {
    return (
      <div className="p-blog-card">
        <div className="p-blog-card__title">В ходе проекта вы можете писать в личном блоге по интересующим вас темам проекта.</div>
        <Button text={"Написать в блог"} type={"filled"} size={"m"} onClick={goToBlog} />
      </div>
    );
  };

  const renderUserList = () => {
    if (!users) return;

    return (
      <div className="participants__list">
        {users.map((user: User) => (
          <div className="participants__user" onClick={() => setParticipant(user)} key={user.id}>
            <UserAvatar user={user} size={"M"} pictureUrl={getAvatarSrc(user.pictureId)} />
            <div className="participants__user-info">
              <div className="participants__user-name">
                {getDisplayName(user)}
                {renderHatIfUserNameIsHidden(user, currentUser)}
              </div>

              <div className="participants__user-date">
                {user.online ? "Онлайн" : (user.lastActivityDate && relativeTime(user.lastActivityDate)) || ""}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderUserPaging = () => {
    return (
      <>
        {Boolean(userPaging?.totalPages) && userPaging?.totalPages > 1 && (
          <Pagination
            totalPages={userPaging?.totalPages || 0}
            onPageChange={(event) => setUserPage(event.selected)}
            currentPage={userPage}
            pageName="userPage"
          />
        )}
      </>
    );
  };

  const renderBlogList = () => {
    if (!blogs) return;

    return (
      <div className="participants__blog-list" ref={scrollToRef}>
        {blogs.map((b) => (
          <CommentCard key={b.id} blogPost={b} />
        ))}
      </div>
    );
  };

  const renderBlogPaging = () => {
    return (
      <>
        {Boolean(blogPaging?.totalPages) && blogPaging?.totalPages > 1 && (
          <Pagination
            totalPages={blogPaging?.totalPages || 0}
            onPageChange={(event) => {
              setBlogPage(event.selected);
              if (scrollToRef.current) scrollToRef.current.scrollIntoView();
            }}
            currentPage={blogPage}
            pageName="blogPage"
          />
        )}
      </>
    );
  };

  return (
    <section className="participants">
      <div className="participants__wrapper">
        <div className="participants__head">
          <PageTitle text={"Участники проекта"} />
          <div className="participants__head__searches">
            <Search
              ref={searchParticipantsRef}
              value={searchParticipantsFocused ? districtSearch : selectedDistrict?.name || districtSearch}
              items={filteredDistricts}
              bindTo="name"
              placeholder="Название района"
              onChange={setDistrictSearch}
              onItemSelect={(value) => {
                setSelectedDistrict(value);
                searchParticipantsRef.current?.inputRef.current?.blur();
              }}
              isWithList
              isRounded
              isCloseOnSelect
              sortByAlphabet
              icon={
                <>
                  {!!selectedDistrict && (
                    <span
                      className="ui-icon ui-icon-close participants__search-icon_close"
                      onClick={() => {
                        setSelectedDistrict(undefined);
                        searchParticipantsRef.current?.inputRef.current?.focus();
                      }}
                    />
                  )}
                  <span
                    className={`ui-icon participants__search-icon ${
                      searchParticipantsFocused ? "ui-icon-chevron-up" : "ui-icon-chevron-down"
                    }`}
                    onMouseDown={(e) => {
                      searchParticipantsRef.current?.inputRef.current?.[searchParticipantsFocused ? "blur" : "focus"]();
                      e.preventDefault();
                    }}
                  />
                </>
              }
            />
            <Search
              bindTo="title"
              value={value}
              placeholder="Поиск участника"
              items={[]}
              onChange={(value) => {
                setUserPage(0);
                setValue(value);
              }}
              isWithList={false}
              isRounded={true}
              isMultiplePerRow={true}
              isClearPlaceholderOnSelect={true}
              isCloseOnSelect={true}
              icon={<span className="ui-icon ui-icon-search participants__search-icon"></span>}
            />
          </div>
          <Checkbox
            html="В сети"
            onChange={(value) => {
              setUserPage(0);
              setOnlineOnly(value);
            }}
          />
        </div>

        <div className="participants__body">
          {isLoading ? <UserLoader /> : <FadeIn>{renderUserList()}</FadeIn>}

          {renderUserPaging()}
        </div>
      </div>

      <div className="participants__footer">
        <h2 className="participants__ftitle">Личные блоги участников проекта</h2>
        <div className="participants__blog">
          {renderBlogCard()}
          {renderBlogList()}
          {renderBlogPaging()}
        </div>
      </div>

      {participant && <UserModal userId={participant.id} onClose={() => setParticipant(null)} />}
    </section>
  );
};

export default ParticipantsPage;
