import { Button, Select, Textarea } from "@crowd/ui-kit";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProposalInterface, RejectProposalQuery } from "../../../../../types/Proposal.interface";
import "./SelectionDeclineProposalForm.scss";
import ProposalService from "../../../../../services/proposalService";
import { showConfirmInfoModal, showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import ClosingCard from "../../../../presentational/Cards/ClosingCard/ClosingCard";
import Validator from "@crowd/ui-kit/build/classes/Validator";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { stageSelector } from "../../../../../store/selectors/stage";
import {
  selectionFilterSelector,
  selectionRejectReasonsSelector,
  selectionTagsNormalizedSelector,
} from "../../../../../store/selectors/selection";
import { selectionGetCounts, selectionGetIdeas, selectionGetTags, selectionSetFilter } from "../../../../../store/actions/SelectionActions";
import { useSelectionParams } from "../../helpers/params";

interface DeclineProposalFormProps {
  proposal: ProposalInterface;
  onClose: () => void;
}

export const SelectionDeclineProposalForm: FC<DeclineProposalFormProps> = ({ proposal, onClose }) => {
  const isMobile = useIsMobile();
  const { folder } = useSelectionParams();

  const dispatch = useDispatch();

  const stage = useSelector(stageSelector);
  const tagsNormalized = useSelector(selectionTagsNormalizedSelector);
  const rejectReasons = useSelector(selectionRejectReasonsSelector);
  const filter = useSelector(selectionFilterSelector);

  const tag = tagsNormalized[proposal.tagId];

  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState("");
  const [commentValidator, setCommentValidator] = useState<Validator>(null);
  const [submitting, setSubmitting] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const isLastProposal = () => !!tag && tag.proposalCount === 1;

  const handleDecline = () => {
    if (isLastProposal()) {
      dispatch(
        showConfirmInfoModal(
          `При отклонении последней идеи в группе вся группа будет удалена. </br> Вы действительно хотите отклонить идею?`,
          decline,
          onClose
        )
      );
    } else {
      decline();
    }
  };

  const decline = async () => {
    try {
      setSubmitting(true);
      const query: RejectProposalQuery = {
        id: proposal.id,
        stageId: stage.id,
        reasonId: reason.id,
        comment: comment || null,
      };
      const { status, message } = await ProposalService.reject(query);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      setSubmitting(false);

      if (filter.tagId && tagsNormalized[filter.tagId]?.proposalCount === 1) {
        dispatch(selectionSetFilter(Object.fromEntries(Object.entries(filter).filter(([k]) => k !== "tagId"))));
      } else {
        dispatch(
          selectionGetIdeas({
            stageId: stage.id,
            folder,
          })
        );
      }
      dispatch(selectionGetCounts(stage.id));
      dispatch(selectionGetTags(stage.id));

      onClose();
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
      setSubmitting(false);
    }
  };

  const isFormValid = () => {
    return !!reason && (!comment || commentValidator?.isValid());
  };

  return (
    <ClosingCard isOpened onClose={onClose} type={isMobile ? "gray" : "blue"}>
      <div className="decline-proposal-form">
        <label className="decline-proposal-form__title">Выберите причину отклонения</label>
        <div className="decline-proposal-form__body">
          <Select
            items={rejectReasons}
            value={reason}
            bindTo="title"
            onItemSelect={(item) => setReason(item)}
            label="Причина отклонения"
            closeOnSelect
            restrictTo="258px"
            openSide="bottom"
          />
          {showComment && (
            <Textarea
              value={comment}
              placeholder={"Ваш комментарий"}
              hint={"Не более 1500 символов"}
              isRequired={false}
              onChange={setComment}
              shouldValidate
              maxLength={1500}
              validateRules={{
                minLength: 3,
                maxLength: 1500,
              }}
              onInitValidator={setCommentValidator}
              showError={!!comment}
            />
          )}
        </div>
        <div className="decline-proposal-form__footer">
          {!showComment && <Button type="filled" onClick={() => setShowComment(true)} text="Написать комментарий" size="m" />}
          <Button type="filled" onClick={handleDecline} text="Отклонить" size="m" isDisabled={!isFormValid()} isLoading={submitting} />
        </div>
      </div>
    </ClosingCard>
  );
};
