import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { RootState } from "../../../../../types/State.interface";
import { Notification } from "../../../../../types/Notifications.interface";
import "./Notifications.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../../utils/constants/widthConstants";
import { readNotifications, removeNotification } from "../../../../../store/actions/NotificationsActions";
import { useForceUpdate } from "../../../../../utils/hooks/useForceUpdate";

const Notifications = () => {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const notifications = useSelector((state: RootState) => state.notifications.notifications);
  const unreadCount = useSelector((state: RootState) => state.notifications.countAllUnread?.unreadNotificationsCount || 0);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | any>(null);
  const notificationsRef: { current: Notification[] } = useRef();

  const getUnreadNotifications = (swiper) => {
    const { realIndex } = swiper;
    const _notifications = notificationsRef.current;
    const first = _notifications[realIndex];
    const second = _notifications[realIndex + 1] ? _notifications[realIndex + 1] : null;
    const notificationsToRead = isTablet ? [first, second] : [first];

    return notificationsToRead.filter((n) => Boolean(n) && !n.processed);
  };

  const read = (notifications) => {
    const ids = notifications.map((n) => n.id);

    if (ids.length > 0) {
      dispatch(readNotifications({ ids }));
    }
  };

  const readAfterRemove = () => {
    setTimeout(() => {
      readNotification(swiperInstance);
      forceUpdate();
    });
  };

  const remove = async () => {
    const { realIndex } = swiperInstance;
    const _notifications = notificationsRef.current;
    const current = _notifications[realIndex];
    const second = _notifications[realIndex + 1] ? _notifications[realIndex + 1] : null;
    dispatch(removeNotification({ id: current.id }, readAfterRemove));
    if (isTablet && second) dispatch(removeNotification({ id: second.id }, readAfterRemove));
  };

  const readNotification = (swiper) => {
    const notifications = getUnreadNotifications(swiper);
    read(notifications);
  };

  function getCounter() {
    return (unreadCount >= 100 && "99+") || unreadCount;
  }

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  useEffect(() => {
    if (!swiperInstance) return;
    readNotification(swiperInstance);
  }, [swiperInstance]);

  const renderList = () => {
    return (
      <Swiper
        spaceBetween={10}
        slidesPerView={isTablet && !isPhone ? 2 : 1}
        loop={false}
        onInit={readNotification}
        onSwiper={setSwiperInstance}
        onSlideChange={readNotification}
      >
        {notificationsRef.current?.map((notification, key) => (
          <SwiperSlide className="" key={key}>
            <div className="notification__time">{dayjs(notification.timestamp).format("DD.MM.YYYY HH:mm")}</div>

            <div
              className="notification__body"
              dangerouslySetInnerHTML={{
                __html: notification.shortText,
              }}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="notification blank">
        <div className="notification__head">Уведомления</div>
        <div className="notification__empty">Новых уведомлений пока нет</div>
      </div>
    );
  };

  const renderMain = () => {
    if (!notificationsRef.current) return null;
    return renderList();
  };

  const renderNotifications = () => {
    return (
      <>
        <div className="notification">
          <div className="notification__head">
            <p className="notification__head-title">
              Уведомления
              {Boolean(unreadCount) && <span className="notification__head-counter">{getCounter()}</span>}
            </p>

            {Boolean(notifications?.length) && (
              <div className="notification__head-tools">
                <div className="notification__head-arrows">
                  <span className="ui-icon-arrow-slider-left icon-arrow" onClick={() => swiperInstance?.slidePrev()}></span>
                  <span className="ui-icon-arrow-slider-right icon-arrow" onClick={() => swiperInstance?.slideNext()}></span>
                </div>
                <button onClick={remove} className="simple-btn">
                  <span className="ui-icon-close"></span>
                </button>
              </div>
            )}
          </div>

          {renderMain()}

          {Boolean(notifications?.length) && (
            <div className="notification__footer">
              <Link to={"/profile/notifications"}>Посмотреть все</Link>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="profile-notifications">
      {notifications ? <>{notifications?.length ? renderNotifications() : renderEmpty()}</> : <></>}
    </div>
  );
};

export default Notifications;
