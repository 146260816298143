import CalendarStripe from "../../../presentational/CalendarStripe/CalendraStripe";
import dayjs, { Dayjs } from "dayjs";
import ExtraNav from "../ExtraNav/ExtraNav";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { useHistory } from "react-router";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import { shuffle } from "../../../../utils";
import "./ActualAside.scss";

interface ActualAsideProps {
  date: Dayjs;
  actualPosts: any[];
  batchedPosts: any[];
}

const ActualAside = ({ date, actualPosts, batchedPosts }: ActualAsideProps) => {
  const project = useSelector((state: RootState) => state.project.current);
  const history = useHistory();
  const [randomPosts, setRandomPosts] = useState<any[]>(null);

  useEffect(() => {
    if (batchedPosts && date) {
      randomizePosts();
    }
  }, [batchedPosts, date]);

  const randomizePosts = () => {
    if (!date) return;

    const posts = Object.entries(batchedPosts)
      .filter((post) => {
        return post[0] !== date.format("DD.MM.YYYY");
      })
      .map((p) => p[1])
      .flat();

    setRandomPosts(
      shuffle(posts)
        .sort((a: any, b: any) => b.pinned - a.pinned)
        .slice(0, 2)
    );
  };

  const setPrevPost = (date) => {
    history.push("/actual?date=" + date.format("DD.MM.YYYY"));
  };

  const setNextPost = (date) => {
    history.push("/actual?date=" + date.format("DD.MM.YYYY"));
  };

  const renderExtraFn = (item) => {
    const post = actualPosts.find(
      (post) =>
        dayjs(post.createdTimestamp).format("YY.MM.DD") ===
        item.date.format("YY.MM.DD")
    );

    if (post) {
      return post.important ? (
        <Tooltip
          isOuter={true}
          text={"Важная информация"}
          idx={post.id}
          className={"actual-page__imp"}
        >
          <div className="ui-icon ui-icon-lightning"></div>
        </Tooltip>
      ) : (
        <Tooltip
          isOuter={true}
          text={"Ознакомьтесь <br> с информацией"}
          idx={post.id}
          className={"actual-page__inf"}
        >
          <div className="ui-icon ui-icon-info"></div>
        </Tooltip>
      );
    }
  };

  return (
    <aside className="actual-aside">
      <CalendarStripe
        start={dayjs(project.start)}
        finish={dayjs(project.finish)}
        currentDate={date}
        cellWidth={52}
        cellPerView={7}
        onMoveLeft={setPrevPost}
        onMoveRight={setNextPost}
        renderExtraFn={renderExtraFn}
        showMonth={true}
        center={true}
        highlightActive={true}
      />

      {Boolean(randomPosts?.length) && (
        <ExtraNav
          key={"read"}
          title={"Читайте также"}
          posts={randomPosts}
          onChange={(post) =>
            history.push(
              `/actual?date=${dayjs(post.createdTimestamp).format(
                "DD.MM.YYYY"
              )}`
            )
          }
        />
      )}
    </aside>
  );
};

export default ActualAside;
