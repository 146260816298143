import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "./MessageCreatePanel.scss";
import allowedExtensions from "../../../../../../../utils/constants/allowedExtensions";
import { FileSelector } from "@crowd/ui-kit";
import { hideInfoModal, showErrorInfoModal, showInfoModal } from "../../../../../../../store/actions/LayoutActions";
import { Loader } from "@crowd/ui-kit";
import { Attachment } from "../../../../../../../types/Common.interface";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../../utils/constants/widthConstants";

interface MessageCreatePanelProps {
  onSend: (payload: { text: string; attachments: Attachment[] }) => void;
}

const MAX_TEXT_LENGTH = 1500;
const MAX_FILES_LENGTH = 5;
const MAX_TOTAL_SIZE_MB = 5;
const MAX_FILE_SIZE_MB = 5;
const MAX_TOTAL_SIZE_ERROR = `Суммарный объем файлов не должен превышать ${MAX_TOTAL_SIZE_MB} мб. Пожалуйста, уменьшите объем файлов.`

const MessageCreatePanel: React.FC<MessageCreatePanelProps> = ({ onSend }) => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const handlerRef = useRef<HTMLSpanElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  useEffect(() => {
    isUploading 
      ? dispatch(showInfoModal(null, null, <Loader/>, null, 'chat-files-loading__modal' ))
      : dispatch(hideInfoModal())
  }, [isUploading])

  const trimTextAndSetCursor = (currentText) => {
    let trimmedText = currentText;

    // Ограничиваем количество символов
    if (trimmedText.length > MAX_TEXT_LENGTH) {
      trimmedText = trimmedText.substring(0, MAX_TEXT_LENGTH);
      contentRef.current.innerText = '';
      contentRef.current.appendChild(document.createTextNode(trimmedText));
    }

    // Передвигаем курсор в конец текста
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(contentRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  
    return trimmedText;
  };
  
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const text = contentRef.current.innerText;
      sendMessage(text);
    }
  };

  const handleFilesUploadingChange = (uploading) => {
    setIsUploading(uploading)
  }

  const handleInput = (e) => {
    const currentText = contentRef.current.innerText;
    if (currentText.length > MAX_TEXT_LENGTH) {
        const trimmedText = trimTextAndSetCursor(currentText);
        setContent(trimmedText);
    } else {
        setContent(currentText);
    }
};

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
  
    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
  
    const range = selection.getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    let fullText = contentRef.current.innerText;

    const trimmedText = trimTextAndSetCursor(fullText)
    setContent(trimmedText);
  };

  const handleCopy = (e) => {
  };

  const handleCut = (e) => {
    setTimeout(() => {
      setContent(e.target.innerText);
    }, 0);
  };

  const sendMessage = (value: string) => {
    onSend({ text: value, attachments: files });
    invalidate();
  };

  const invalidate = () => {
    setContent("");
    contentRef.current.innerText = "";
    setFiles([]);
  };

  const canSendMessage = () => {
    const totalSizeMB = files.reduce((total, file) => total + file.bytesSize / 1024 / 1024, 0);
    if (totalSizeMB > MAX_TOTAL_SIZE_MB) return false;
    return content.length <= MAX_TEXT_LENGTH && (content.length > 0 || files.length > 0);
  };

  const canAttachFiles = () => {
    const totalSizeMB = files.reduce((total, file) => total + file.bytesSize / 1024 / 1024, 0);
    return totalSizeMB <= MAX_TOTAL_SIZE_MB && files.length < MAX_FILES_LENGTH && !isUploading;
  };

  return (
    <div className="message-create">
      <div className="message-create__inner">
        <div className="message-create__header" style={{ display: files.length ? "block" : "none" }}>
          <div className="message-create__attachments">
            <FileSelector
              variant="reduced"
              handlerRef={handlerRef}
              defaultFiles={[]}
              upload={true}
              text={""}
              isMultiple={true}
              allowedExtensions={allowedExtensions}
              maxTotalSizeMB={MAX_TOTAL_SIZE_MB}
              maxFiles={MAX_FILES_LENGTH}
              maxFileSizeMB={MAX_FILE_SIZE_MB}
              onChange={(files) => {
                setFiles(files);
              }}
              showInfoModal={(msg) => {
                dispatch(showErrorInfoModal(msg));
              }}
              attachmentsLength={files.length}
              displayMaxFileSizeErrorInlineText={MAX_TOTAL_SIZE_ERROR}
              onUploadingChange={handleFilesUploadingChange}
            />
          </div>
        </div>
        <div className="message-create__body">
          <div
            className="message-create__input"
            contentEditable={true}
            onInput={handleInput}
            onPaste={handlePaste}
            onCopy={handleCopy}
            onCut={handleCut}
            onKeyDown={handleKeyDown}
            ref={contentRef}
            placeholder="Введите сообщение..."
          />


          <div className="message-create__extra">
            <div className="message-create__count"> 
            {content.length || 0}/{MAX_TEXT_LENGTH}
            </div>
            <div className="message-create__tools">
              <span className={classNames("ui-icon", "ui-icon-attach", "simple-button", { "disabled-btn": !canAttachFiles() })} ref={handlerRef} title="Добавить файл"></span>
              <button
                title="Отправить сообщение"
                className={classNames("ui-icon", "ui-icon-send-message-filled", "simple-btn", { "disabled-btn": !canSendMessage() })}
                onClick={() => sendMessage(content)}
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCreatePanel;
