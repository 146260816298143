import React from "react";
import { Link } from "react-router-dom";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

import "./Logo.scss";

const Logo = (props) => {
  const { onLogoClick } = props;

  const handleClick = () => {
    if (onLogoClick) onLogoClick();
  };

  return (
    <EventsTrackWrapperClick id={["HEADER", "CROWD_LOGO", "CLICK"]}>
      <Link to={`/`}>
        <div className="logo" onClick={handleClick}></div>
      </Link>
    </EventsTrackWrapperClick>
  );
};

export default Logo;
