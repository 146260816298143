import { ToastState } from "../../types/Toast.interface";
import {
  SHOW_ERROR_TOAST,
  SHOW_SUCCESS_TOAST,
  SHOW_WARNING_TOAST,
} from "../actions/ToastActions";

const initialState: ToastState = {
  appearance: null,
  text: "",
  autoDismiss: true,
};

export function toastReducer(state = initialState, action: any) {
  switch (action.type) {
    case SHOW_SUCCESS_TOAST:
      return {
        ...state,
        appearance: "success",
        text: action.payload.text,
        autoDismiss: action.payload.autoDismiss,
      };
    case SHOW_ERROR_TOAST:
      return {
        ...state,
        appearance: "error",
        text: action.payload.text,
        autoDismiss: action.payload.autoDismiss,
      };
    case SHOW_WARNING_TOAST:
      return {
        ...state,
        appearance: "warning",
        text: action.payload.text,
        autoDismiss: action.payload.autoDismiss,
      };
    default:
      return state;
  }
}
