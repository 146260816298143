import { DynamoList } from "@crowd/ui-kit";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import { User } from "../../../types/User.interface";

import UserAvatar from "../../presentational/UserAvatar/UserAvatar";
import UserCrumb from "../../presentational/UserCrumb/UserCrumb";

import "./DynamicUserList.scss";
import { renderHatIfUserNameIsHidden } from "../../../utils/user.utils";

export enum DynamicUserListRepresentation {
  SINGLE = "SINGLE",
  MANY = "MANY",
}

export interface DynamicUserListProps {
  defaultUsers: User[];
  onResult: (userList: User[]) => void;
  endpoint: string;
  isInvalid?: boolean;
  showError?: boolean;
  representation?: DynamicUserListRepresentation;
}

const DynamicUserList = ({
  defaultUsers,
  endpoint,
  isInvalid,
  representation = DynamicUserListRepresentation.SINGLE,
  onResult,
}: DynamicUserListProps) => {
  const currentUser: User = useSelector((state: RootState) => state.user.userDetails);

  const [value, setValue] = useState("");
  const [userList, setUserList] = useState<User[]>(defaultUsers || []);

  const addUser = (user) => {
    setUserList([...userList, user]);
  };

  const removeUser = (user) => {
    const _userList = [...userList.filter((u) => u.id !== user.id)];
    setUserList(_userList);
    onResult(_userList);
  };

  const isUserSelected = (user: User): boolean => {
    return !!userList.find((u: User) => u.id === user.id);
  };

  const handleMouseDown = (user: User) => {
    setValue("");
    switch (representation) {
      case DynamicUserListRepresentation.SINGLE: {
        onResult && onResult([user]);
        break;
      }
      case DynamicUserListRepresentation.MANY: {
        if (!isUserSelected(user)) {
          addUser(user);
        }
        onResult && onResult([user, ...userList]);
        break;
      }
      default: {
        return;
      }
    }
  };

  const renderItem = (user: User) => {
    return (
      <div key={user.id} className="dy-user-list__item" onMouseDown={() => handleMouseDown(user)}>
        <UserAvatar user={user} />
        <div className="dy-user-list__user-name">{user.firstName + " " + user.lastName}</div>
        {renderHatIfUserNameIsHidden(user, currentUser)}
      </div>
    );
  };

  const excludeItem = (item) => {
    return item.id !== currentUser.id;
  };

  const renderList = () => {
    if (representation === DynamicUserListRepresentation.SINGLE) return;
    return (
      <>
        {userList.length > 0 && (
          <div className="dy-user-list__list">
            {userList.map((u) => {
              return <UserCrumb user={u} key={u.id} onRemove={removeUser} />;
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="dy-user-list">
      <DynamoList
        placeholder="Кому"
        value={value}
        endpoint={endpoint}
        isInvalid={isInvalid}
        debounceTime={500}
        bindTo="item"
        onChange={(item) => {
          console.log(item);
        }}
        renderItemFn={renderItem}
        notFoundError={"По вашему запросу пользователи не найдены"}
        excludeItemFn={excludeItem}
      />
      {renderList()}
    </div>
  );
};

export default DynamicUserList;
