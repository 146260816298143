import { Project } from "../types/Projects.interface";
import dayjs from "dayjs";

import { Stage } from "../types/Stage.interface";
import { getUrlParam } from "./urlUtils";

export const displayError = (title, err) => {
  const style = `
    color: red;
    border: 2px dashed red;
    padding: 10px;
    margin: 10px 0;
    display: inline-block;
  `;

  console.log(`%c${title} | ${err}`, style);
};

export function setCookie(name, value, options: any = {}) {
  options = {
    path: "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export function declination(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}
export const itemIsCurrent = (item: Project | Stage): boolean => {
  return dayjs().valueOf() > dayjs(item.start).valueOf() && dayjs().valueOf() < dayjs(item.finish).valueOf();
};
export const itemIsAnnouncement = (item: Project | Stage): boolean => {
  return item ? dayjs(item.start).diff(dayjs(), "minute") > 0 : false;
};
export const itemIsFinished = (item: Project | Stage): boolean => {
  return item ? dayjs(item.finish).diff(dayjs(), "minute") < 0 : false;
};

export function calculateRemainedTime(item: Project | Stage, unit?: "minute" | "hour" | "day") {
  let diff = Math.abs(dayjs(itemIsCurrent(item) ? item.finish : item.start).diff(dayjs(), "minute"));
  let res = " ";
  if (diff < 60 || unit === "minute") {
    res = diff + 1 + declination(diff + 1, [" минута", " минуты", " минут"]);
    return res.split(" ");
  }
  if (diff < 60 * 24 || unit === "hour") {
    diff = Math.ceil(diff / 60);
    res = diff + declination(diff, [" час", " часа", " часов"]);
    return res.split(" ");
  }
  if (diff > 60 * 24 || unit === "day") {
    diff = Math.ceil(diff / 60 / 24);
    res = diff + declination(diff, [" день", " дня", " дней"]);
    return res.split(" ");
  }
}

export function formatProjectDates(start, finish) {
  return dayjs(start).format("DD.MM") + " - " + dayjs(finish).format("DD.MM.YYYY");
}

export function sortArrByValue<T>(arr: T[], value, sortType = "ASC"): T[] {
  const _arr = [...arr];

  return _arr.sort((a, b) => {
    const _a = a[value];
    const _b = b[value];
    if (_a === _b) return 0;
    else if (_a === null) return 1;
    else if (_b === null) return -1;
    else if (_a > _b) return sortType === "ASC" ? 1 : -1;
    else return sortType === "ASC" ? -1 : 1;
  });
}

export const getRandomNumber = (maxNumber): number => {
  const min = 0;
  const max = Math.floor(maxNumber);
  return Math.floor(Math.random() * (max - min)) + min;
};

export function relativeTime(date) {
  date = /T/.test(date) ? date : +date;
  let diff = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);
  // Секунды
  if (diff < 60) return "Только что";
  // Минуты
  diff = Math.round(diff / 60);
  if (diff < 60) {
    return (diff > 1 ? diff : "1") + " " + plural(diff, ["минуту", "минуты", "минут"]) + " назад";
  }
  // Часы
  diff = Math.round(diff / 60);
  if (diff < 24) {
    return (diff > 1 ? diff : "1") + " " + plural(diff, ["час", "часа", "часов"]) + " назад";
  }
  // Дни
  diff = Math.round(diff / 24);
  return (diff > 1 ? diff : "1") + " " + plural(diff, ["день", "дня", "дней"]) + " назад";
}

export function plural(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

export const copyToClipboard = (link: string) => {
  const field = document.createElement("textarea");
  field.innerText = `${window.location.origin}${link}`;
  document.body.appendChild(field);
  field.select();
  document.execCommand("copy");
  field.remove();
};

export function isGuid(stringToTest) {
  if (stringToTest[0] === "{") {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(stringToTest);
}

export function getAvatarSrc(imageId) {
  if (!imageId) return;

  if (isGuid(imageId) || imageId === "userid_admin") {
    // timestamp disables the cache!
    return "/uploads/get/" + imageId + "?time=" + Number.parseInt(new Date().getTime() / 1000 + "");
  } else {
    try {
      return require(`../assets/user/${imageId}.png`).default;
    } catch (e) {
      console.log(e);
    }
  }
}

export function declOfNum(number, words) {
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]];
}

export function getUserPoints(user, withContent?) {
  let points = 0;

  if (user.userType === "STANDARD") {
    points = 500;
  }

  if (user.userType === "SHORT") {
    points = 200;
  }

  if (user.userType === "full") {
    points = 1000;
  }

  return withContent ? points + " " + declOfNum(points, [" балл", " балла", " баллов"]) : points;
}

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function throttle<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
  let lastCall: number = 0;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let lastArgs: Parameters<T> | null = null;

  const throttled = (...args: Parameters<T>) => {
    const now = Date.now();
    const remaining = delay - (now - lastCall);

    lastArgs = args;

    if (remaining <= 0 || remaining > delay) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      lastCall = now;
      func(...lastArgs);
      lastArgs = null;
    } else if (!timeoutId && lastArgs) {
      timeoutId = setTimeout(() => {
        lastCall = Date.now();
        timeoutId = null;
        func(...(lastArgs as Parameters<T>));
        lastArgs = null;
      }, remaining);
    }
  };

  return throttled;
}

export const isElementWhollyInViewport = (el: HTMLElement): boolean => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * @description
 * escaped - &lt;p&gt;asdasd&lt;strong&gt;asdasd&lt;/strong&gt;asdasd!&lt;/p&gt;
 * unescaped result - <p>asdasd<strong>asdasd</strong>asdasd!</p>
 */
export const unescapeHtml = (escapedHtml: string): string => {
  const el = document.createElement("div");
  const result = [];

  el.innerHTML = escapedHtml;
  el.childNodes.forEach((node) => result.push(node.nodeValue));

  return result.join("");
};

export function scrollToTop(smooth = true) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? "smooth" : "auto",
  });
}

export function getDuration(start, finish) {
  return start && finish ? dayjs(finish).startOf("day").diff(dayjs(start).startOf("day"), "day") + 1 : 0;
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function getCurrentTimeString() {
  const date = new Date();

  return `${date.getHours()}:${date.getMinutes()}`;
}

export function hideElement(selector, multiple?) {
  const elements = multiple ? document.querySelectorAll(selector) : [document.querySelector(selector)];
  elements.forEach((el) => el && (el.style.display = "none"));
  return () => {
    elements.forEach((el) => el && (el.style.display = "flex"));
  };
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getPlainFromHtml(html: string): string {
  const tempEl = document.createElement("div");
  tempEl.innerHTML = html;
  return tempEl.textContent || tempEl.innerText || "";
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

//20.12.2020
export function rusStringToDate(text): Date {
  return new Date(text.replace(/(\d+).(\d+).(\d+)/, "$3/$2/$1"));
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function truncate(string, maxLength): string {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  }

  return string;
}

export function scrollTop(smooth = true) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? "smooth" : "auto",
  });
}

export function escapeHtml(unsafe) {
  return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}

export var replaceHtmlEntites = (function () {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">",
  };
  return function (s) {
    return s.replace(translate_re, function (match, entity) {
      return translate[entity];
    });
  };
})();

export function stripHtml(text) {
  return text.replace(/(<([^>]+)>)/gi, "");
}

export function stripHtmlExcludeLinksAndQuotes(text) {
  return text.replace(/<(?!\/?(a|blockquote)(?=>|\s.*>))\/?.*?>/gi, "");
}

export function findUrlParam(parameter, searchQuery?) {
  return new URLSearchParams(searchQuery).get(parameter);
}

export function isObject(value) {
  return Object.prototype.toString.call(value) === "[object Object]";
}

export function isObjectValuesEmpty(object) {
  return Object.values(object).every((value) => value === null || value === undefined || value === "");
}

export const getLocalDate = (date: Date | string) => {
  const preparedDate = date instanceof Date ? date : new Date(date);
  return new Date(preparedDate.getTime() - preparedDate.getTimezoneOffset() * 60000).toISOString().split("T")[0];
};

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);
export const escapeRegExp = (string: string) => {
  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, "\\$&") : string || "";
};

export async function imageIdToBlob(attachmentId: string, fileName?: string) {
  try {
    const response = await fetch(`/uploads/get/${attachmentId}`);
    const blob = await response.blob();
    const file = new File([blob], fileName || "filename", { type: blob.type });

    return { file: file, id: attachmentId };
  } catch (error) {
    console.error(error);
  }
}

export const getFileSize = (file, si = false, dp = 1) => {
  let bytes = file.size;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " б";
  }

  const units = ["Кб", "Мб", "Гб", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp).replace(/\./g, ",") + " " + units[u];
};

export function getPageFromUrl(history) {
  return Math.max(Number(getUrlParam("page", history.location.search)) - 1, 0);
}

export const getCleanBase64 = (str) => str?.substring(str.indexOf(",") + 1);

export const getTimestamp = (): number => Math.round(Date.now() / 1000);

export const sprintf = (str: string, ...args: (string | number)[]): string => {
  const gen = function* () {
    yield* args;
  };
  const iterator = gen();
  return str.replace(/\%s/g, (match) => {
    const replaceValue = String(iterator.next().value);
    return typeof replaceValue !== "undefined" ? replaceValue : match;
  });
};

export const defaultPDFImage = `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="33" viewBox="0 0 50 33"><defs><style>.a{fill:#7e7e7e;font-size:28px;font-family:PTSans-Bold, PT Sans;font-weight:700;}</style></defs><text class="a" transform="translate(25 25)"><tspan x="-24.178" y="5">PDF</tspan></text></svg>`;
