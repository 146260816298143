import dayjs, { Dayjs } from "dayjs";
import { getDuration, uuidv4 } from "../../../../utils";
import { stageTypesMap } from "./StageList.constants";
import { CellFragment } from "./StageList.interface";

export enum DirectionEnum {
  GENERATION = "GENERATION",
  EXPERT_SELECTION = "EXPERT_SELECTION",
  SIMPLE_DISCUSSION = "SIMPLE_DISCUSSION",
  PROJECT_DISCUSSION = "PROJECT_DISCUSSION",
  VOTING = "VOTING",
}

export class MobileDirection {
  date: dayjs.Dayjs;
  type: DirectionEnum;
  private _stages: StageListEntity[];

  constructor(date, type, stages) {
    this.date = date;
    this.type = type;
    this.stages = stages;
  }

  get stages() {
    if (this.isToday(this.date)) {
      return this._stages.filter((s) => !this.isClosedNow(s));
    } else {
      return this._stages;
    }
  }
  set stages(value) {
    this._stages = value;
  }

  isClosedNow(stage) {
    return dayjs(stage.finish).valueOf() <= dayjs().valueOf();
  }

  isToday(date) {
    return date.startOf("day").valueOf() === dayjs().startOf("day").valueOf();
  }
}

export class StageListMobileEntity {
  id: string;
  title: string;
  description: string;
  date: dayjs.Dayjs;
  directions: MobileDirection[];

  constructor(options) {
    this.id = uuidv4();
    this.title = options.title;
    this.description = options.description;
    this.date = options.date;
    this.directions = this.createDirections(options.stages).sort(
      (mDirection1, mDirection2) =>
        stageTypesMap[mDirection1.type.toLowerCase()].mobilePriority - stageTypesMap[mDirection2.type.toLowerCase()].mobilePriority
    );
  }

  createDirections(stages) {
    const stagesByType = {};

    stages.forEach((stage) => {
      if (!stagesByType[stage.type]) {
        stagesByType[stage.type] = [];
      }

      stagesByType[stage.type].push(stage);
    });

    return Object.entries(stagesByType).map(([type, stages]) => new MobileDirection(this.date, type, stages));
  }
}

export class StageListEntity {
  id: string;
  title: string;
  description: string;
  stages: any;
  row: TimelineRow;
  isVisible: boolean;
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  forDiscussionStage: boolean;

  constructor(options) {
    this.id = uuidv4();
    this.title = options.title;
    this.description = options.description;
    this.stages = options.stages;
    this.from = options.from;
    this.to = options.to;
    this.isVisible = options.isVisible;
    this.forDiscussionStage = options.forDiscussionStage;
    this.row = this.createRow(this.title, this.stages, this.from, this.to);
  }

  createRow(title, stages, from, to) {
    const options = {
      title,
      stages,
      from,
      to,
    };
    return new TimelineRow(options);
  }
}

export class TimelineRow {
  title: string;
  stages: any;
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  extraBounds: number;
  cells: any[];

  constructor(options) {
    this.title = options.title;
    this.stages = options.stages;
    this.from = options.from;
    this.to = options.to;
    this.cells = this.createCells(this.from, this.to);
    this.paint();
  }

  createCells(from, to) {
    const duration = getDuration(from, to);

    return new Array(duration).fill(null).map((cell, id) => new TimelineCell(from.add(id, "day")));
  }

  paint() {
    this.stages.forEach((stage) => {
      const stageStart = dayjs(stage.start).startOf("day");
      const stageEnd = dayjs(stage.finish).startOf("day");

      let type = stageTypesMap[stage.type.toLowerCase()];

      this.cells.forEach((cell) => {
        if (cell.date.startOf("day").isBefore(stageStart) || cell.date.startOf("day").isAfter(stageEnd)) return;

        const fragment: CellFragment = {
          ...type,
          stage,
          titleOfRow: this.title,
        };

        cell.addFragment(fragment);
      });
    });
  }
}

export class TimelineCell {
  id: string;
  date: dayjs.Dayjs;
  fragments: CellFragment[] = [];

  constructor(date) {
    this.id = uuidv4();
    this.date = date;
  }

  addFragment(fragment: CellFragment) {
    const _fragments = [...this.fragments, fragment].sort((a, b) => a.priority - b.priority).slice(0, 1);
    this.fragments = _fragments;
  }
}
