import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import { User } from "../../../../types/User.interface";

import { Button, AttachmentPreview } from "@crowd/ui-kit";

import "./DetailsCard.scss";
import { getAvatarSrc } from "../../../../utils";
import SocialNetworks from "../../../presentational/SocialNetworks/SocialNetworks";
import { ISocialLink } from "../../../presentational/SocialNetworks/SocialNetwork.interface";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import { tabletWidth } from "../../../../utils/constants/widthConstants";
import { handleRole, handleSocialNetworks, TeamGroupHeaders } from "../Team.constants";
import CommentService from "../../../../services/commentService";
interface Props {
  userInfo?: User;
  setDetailedUser?: Function;
  onClose?: Function;
  userType?: String;
}

const DetailedView = ({ userInfo, setDetailedUser, onClose, userType }: Props) => {
  const userRole = useMemo(() => handleRole(userType, userInfo), []);
  const isTablet: boolean = useMediaQuery({
    query: `(max-width: ${tabletWidth}px)`,
  });
  const socialNetworkArray = useMemo(() => handleSocialNetworks(userInfo), []);
  const networks: ISocialLink[] = socialNetworkArray;

  const generateButton = () => {
    return (
      <div className="team__user-card--detailed__attachments">
        {userType !== "coordinators" && userInfo?.mediaAttachments?.length > 0 && (
          <div className="team__user-card--detailed__attachments_img">
            {/* todo slider, video lightbox (promo NextModal) */}
            {userInfo.mediaAttachments.map((attachment, idx) => {
              if (attachment.type === "IMAGE") {
                return <AttachmentPreview attachment={attachment.imageAttachment} key={idx} />;
              } else if (attachment.type === "VIDEO") {
                return (
                  <AttachmentPreview
                    attachment={attachment.videoAttachment}
                    key={idx}
                    previewAttachmentId={attachment.imageAttachment.id}
                  />
                );
              }
            })}
          </div>
        )}
        <Button
          text={"Задать вопрос"}
          type={"filled"}
          size={"m"}
          onClick={() => typeof setDetailedUser === "function" && setDetailedUser(userInfo)}
        />
      </div>
    );
  };
  const generateUserName = () => {
    return (
      <div>
        <div className="team__user-card--detailed__name">
          {`${userInfo?.firstName} ${userInfo?.lastName}`}
          {userRole.icon && (
            <Tooltip
              text={userRole.icon === "expert" ? "Главный эксперт" : "Руководитель проекта"}
              idx={"mainExpert" + userInfo?.id}
              place="top"
            >
              <div className={`role-icon ${userRole.icon === "expert" ? "chief-expert-icon" : "project-boss-icon"}`}></div>
            </Tooltip>
          )}
        </div>
        <div className="team__user-card--detailed__role">{userRole.name}</div>
      </div>
    );
  };

  return (
    <div className={`${userType} team__user-card--detailed`}>
      <div className="ui-icon ui-icon-close team__user-card--detailed__close" onClick={() => onClose()}></div>
      <div className="team__user-card--detailed-wrapper">
        <div className="team__user-card--detailed__info">
          <img
            className="team__user-card--detailed__picture"
            src={getAvatarSrc(userInfo?.pictureId)}
            alt={TeamGroupHeaders[`${userType}`].title}
          ></img>

          {!isTablet && generateUserName()}

          {userType !== "coordinators" && <SocialNetworks networks={networks}></SocialNetworks>}
        </div>

        <div className="team__user-card--detailed__details">
          {isTablet && generateUserName()}
          <div
            className="team__user-card--detailed__description"
            dangerouslySetInnerHTML={{
              __html: userInfo?.description && CommentService.CkBBCodeToHtml(userInfo.description),
            }}
          ></div>
          {!isTablet && generateButton()}
        </div>
      </div>

      {isTablet && generateButton()}
    </div>
  );
};

export default DetailedView;
