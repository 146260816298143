import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import useQuery from "../../../utils/hooks/useQuery";
import { getUrlParam } from "../../../utils/urlUtils";

import "./Pagination.scss";

interface Props {
  totalPages: number;
  onPageChange: (item: { selected: number }) => any;
  currentPage: number;
  pageName: string;
}

const Pagination = (props: Props) => {
  const history = useHistory();
  const query = useQuery();

  const [page, setPage] = useState(props.currentPage || 0);

  useEffect(() => {
    formSearch();
  }, []);

  useEffect(() => {
    if (props.currentPage !== page) {
      setPage(props.currentPage);
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (query[props.pageName] && +query[props.pageName] - 1 !== page) {
      props.onPageChange({ selected: +query[props.pageName] - 1 });
    }
  }, [query[props.pageName]]);

  useEffect(() => {
    formSearch();
  }, [page]);

  function formSearch() {
    if (+query[props.pageName] - 1 === page) return;

    const _query = { ...query, [props.pageName]: page + 1 };
    let _search = Object.keys(_query)
      .map((k) => (k += `=${_query[k]}`))
      .join("&");

    query[props.pageName]
      ? history.push({
          search: "?" + _search,
        })
      : history.replace({ search: "?" + _search });
  }

  return (
    <ReactPaginate
      previousLabel={""}
      nextLabel={""}
      containerClassName={"pagination"}
      pageClassName={"pagination__page"}
      activeClassName={"active"}
      disabledClassName={"disabled"}
      previousClassName={"directions ui-icon ui-icon-keyboard-arrow-left"}
      nextClassName={"directions ui-icon ui-icon-keyboard-arrow-right"}
      pageCount={props.totalPages}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      onPageChange={(item) => {
        setPage(item.selected);
        props.onPageChange({ selected: item.selected });
      }}
      forcePage={page}
      disableInitialCallback={true}
    />
  );
};

export default Pagination;
