import { Button, AttachmentPreview } from "@crowd/ui-kit";
import linkifyHtml from "linkifyjs/html";
import React from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CommentService from "../../../../services/commentService";
import { relativeTime, truncate } from "../../../../utils";
import Tooltip from "../../Tooltip/Tooltip";

import "./ProfileBlogCard.scss";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
interface ProfileBlogCardProps {
  post: any;
  backgroundColor?: string;
  onEdit?: (post) => void;
  onRemove?: (post) => void;
}

const ProfileBlogCard = ({
  post,
  backgroundColor,
  onEdit,
  onRemove,
}: ProfileBlogCardProps) => {
  const history = useHistory();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const renderFooterDraft = () => {
    return (
      <>
        <Tooltip
          text={"Редактировать черновик"}
          idx={post.id + "-edit"}
          className={"mail-card__buttons-icon"}
        >
          <i className="ic ui-icon-edit" onClick={() => onEdit(post)} />
        </Tooltip>
        <Tooltip
          text={"Удалить черновик"}
          idx={post.id + "-delete"}
          className={"mail-card__buttons-icon"}
        >
          <i className="ic ui-icon-delete" onClick={() => onRemove(post)} />
        </Tooltip>
      </>
    );
  };

  const renderFooterPlain = () => {
    return (
      <Button
        text={"Читать дальше"}
        type={isPhone ? "outlined" : "filled"}
        size={"m"}
        onClick={() =>
          history.push(`/users/user/post/${post.author?.id}/${post.id}`)
        }
      />
    );
  };

  const getText = (content) => {
    let _content = CommentService.CkBBCodeToHtml(content);
    _content = linkifyHtml(_content, { target: "blank" });
    return post.author.teamMember ? _content : truncate(_content, 1500);
  };

  return (
    <div
      className="profile-blog-card post-card"
      style={{ backgroundColor: backgroundColor || "white" }}
    >
      <header className="post-card__header">
        <div className="profile-blog-card__info">
          <p className="post-card--hb">{post.title || "Без заголовка"}</p>
          <p className="post-card--hi">{relativeTime(post.createdTimestamp)}</p>
        </div>
      </header>

      <main className="profile-blog-card__body">
        <p
          className="post-card--bn"
          dangerouslySetInnerHTML={{
            __html: getText(post.rawContent),
          }}
        ></p>

        {post.draft && post.attachments?.length > 0 && (
          <div className="profile-blog-card__attachments scroll-x">
            {post.attachments.map((attachment) => (
              <AttachmentPreview attachment={attachment} key={attachment.id} />
            ))}
          </div>
        )}
      </main>

      <footer className="profile-blog-card__footer">
        {post.draft ? renderFooterDraft() : renderFooterPlain()}
      </footer>
    </div>
  );
};

export default ProfileBlogCard;
