import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";

import { setAppColor, showFooter, showHeader } from "../../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../../App.interface";
import Tabs, { Tab } from "../../../presentational/Tabs/Tabs";
import UserService from "../../../../services/userService";
import { User } from "../../../../types/User.interface";
import UserAvatar from "../../../presentational/UserAvatar/UserAvatar";
import "./PersonalHeader.scss";
import Tooltip from "../../../presentational/Tooltip/Tooltip";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import MessageModal from "../../../containers/Modals/MessageModal/MessageModal";
import ComplaintModal from "../../../containers/Modals/ComplaintModal/ComplaintModal";
import WarningModal from "../../../containers/Modals/WarningModal/WarningModal";
import { RootState } from "../../../../types/State.interface";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { getDisplayName, renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";

enum PersonalSectionTypeEnum {
  blog = "blog",
  post = "post",
  proposals = "proposals",
  comments = "comments",
}

interface IPersonalParams {
  type: PersonalSectionTypeEnum;
  userId: string;
  postId?: string;
}

const PersonalHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { userId, type } = useParams<IPersonalParams>();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [currentTabId, setCurrentTabId] = useState<string | number>(null);
  const [user, setUser] = useState<User>(null);
  const [isMsgModalOpen, setMsgModalOpen] = useState<boolean>(false);
  const [isComplModalOpen, setComplModalOpen] = useState<boolean>(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    type === PersonalSectionTypeEnum.blog && loadUser();
  }, [type]);

  useEffect(() => {
    if (user) {
      _setTabs();
    }
  }, [user]);

  const getCurrentTab: (path: string) => string = () => {
    return type === PersonalSectionTypeEnum.blog || type === PersonalSectionTypeEnum.post ? PersonalSectionTypeEnum.blog : type;
  };

  const loadUser = async () => {
    const { data: user, status } = await UserService.getPerson(userId);
    setUser(user);

    if (ApiStatusCode.OK !== status) {
      history.push("/");
      dispatch(showErrorToast("Пользователь не найден"));
      return;
    }
  };

  const _setTabs = () => {
    setTabs(
      [
        {
          id: PersonalSectionTypeEnum.blog,
          name: "Записи в личном блоге",
          count: user.personalPostCount,
          iconClass: "ui-icon-blog",
        },
        {
          id: PersonalSectionTypeEnum.proposals,
          name: "Принятые идеи",
          count: user.acceptedProposals,
          iconClass: "ui-icon-accepted-idea",
        },
        {
          id: PersonalSectionTypeEnum.comments,
          name: "Комментарии",
          count: user.commentCount,
          iconClass: "ui-icon-comment-reply",
        },
      ].filter((tab) => tab.id !== "proposals" || user.participant)
    );
  };

  const initPage = async () => {
    dispatch(setAppColor(AppColorsEnum.LIGHTBLUE));
    dispatch(showHeader());
    dispatch(showFooter());
    await loadUser();
    setCurrentTabId(getCurrentTab(location.pathname));
  };

  const switchTab = (tabId: string | number): void => {
    setCurrentTabId(tabId);
    history.push(`/users/user/${tabId}/${userId}`);
  };

  useEffect(() => {
    initPage().then();
  }, [userId]);

  /**
   * @description кнопка "Предупредить участника" доступна авторизованной роли
   * ЧКП на личных страницах участников, экспертов, лиц проекта"
   */
  const isWarningAccepted = (): boolean => {
    return currentUser.teamMember && (user.participant || user.expert || user.chiefExpert || user.frontman);
  };

  /**
   * @description кнопка "Пожаловаться" доступна авторизованным ролям
   * участник, эксперт, лицо проекта на личных страницах участников, лиц проекта.
   */
  const isComplaintAccepted = (): boolean => {
    return (
      (currentUser.participant || currentUser.expert || currentUser.chiefExpert || currentUser.frontman) &&
      (user.participant || user.frontman)
    );
  };

  return (
    <div className="personal-user">
      {user && (
        <>
          <div className="personal-user__user">
            <div>{<UserAvatar user={user} size={"M"} />}</div>

            <div className="personal-user__user__info">
              <div className="personal-user__user__name">
                {getDisplayName(user)}
                {renderHatIfUserNameIsHidden(user, currentUser)}
              </div>
              <div className="personal-user__user__place">
                {!user.expertOrStaff && !user.frontman ? (
                  <>
                    {" "}
                    {!!user.moscowRegion && (
                      <span>
                        {user.moscowRegion} округ{!!user.moscowDistrict && ", "}
                      </span>
                    )}
                    {!!user.moscowDistrict && <span>район {user.moscowDistrict}</span>}
                  </>
                ) : (
                  <span>{user.position}&nbsp;</span>
                )}
              </div>
            </div>
          </div>

          <div className="personal-user__controls">
            <div className="personal-user__controls__send">
              {currentUser.loggedIn && (
                <Tooltip text={"Написать сообщение"} idx={user.id + "message"}>
                  <a href={`${window.location.origin}/profile/mail?userId=${user.id}`} target="_balnk">
                    <span className="ui-icon ui-icon-mail pointer" />
                  </a>
                </Tooltip>
              )}

              <span className="personal-user__controls__send__separator"></span>

              {isComplaintAccepted() && (
                <Tooltip text={"Пожаловаться"} idx={user.id + "appeal"}>
                  <span
                    className="ui-icon ui-icon-appeal pointer"
                    onClick={() => {
                      setComplModalOpen(true);
                    }}
                  />
                </Tooltip>
              )}

              {isWarningAccepted() && (
                <Tooltip text={"Предупредить участника"} idx={user.id + "warning"}>
                  <span
                    className="ui-icon ui-icon-appeal pointer"
                    onClick={() => {
                      setWarningModalOpen(true);
                    }}
                  />
                </Tooltip>
              )}
            </div>
            <div className="personal-user__controls__white_line"></div>
            <div className="personal-user__controls__tabs">
              <Tabs
                currentTabId={currentTabId}
                tabs={tabs}
                onTabClick={({ id }) => {
                  switchTab(id);
                }}
                isUseMobileTab={true}
              />
            </div>
          </div>
        </>
      )}

      {isMsgModalOpen && (
        <MessageModal
          recipients={[user]}
          isDynamic={false}
          canDraft={true}
          isOpen={isMsgModalOpen}
          sentOptions={{
            modal: {
              message: "Сообщение успешно отправлено.",
            },
          }}
          draftOptions={{
            toast: {
              message: "Черновик успешно сохранен",
            },
          }}
          onClose={() => setMsgModalOpen(false)}
        />
      )}

      {isComplModalOpen && <ComplaintModal user={user} type={"USER"} onClose={() => setComplModalOpen(false)} />}

      {isWarningModalOpen && (
        <WarningModal isOpen={isWarningModalOpen} onClose={() => setWarningModalOpen(false)} userId={userId}></WarningModal>
      )}
    </div>
  );
};

export default PersonalHeader;
