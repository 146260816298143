import React, { useEffect, useState } from "react";

import "./FaqCard.scss";

const FaqCard = (props) => {
  return (
    <div
      className="faq-card"
      style={{ backgroundColor: props.card.background }}
    >
      {props.card.head && (
        <div className="faq-card__header">
          <div className="faq-card__header-title">{props.card.head.title}</div>
          <div className="faq-card__header-icon">
            <span className={props.card.head.icon}></span>
          </div>
        </div>
      )}

      <div
        className="faq-card__body"
        style={{ backgroundColor: props.card.body.background }}
      >
        <div className="faq-card__body-heading">{props.card.body.heading}</div>
        <div
          className="faq-card__body-text"
          dangerouslySetInnerHTML={{ __html: props.card.body.text }}
        ></div>
      </div>

      {props.card.footer && (
        <div className="faq-card__footer">
          <div className="faq-card__footer-text" dangerouslySetInnerHTML={{ __html: props.card.footer.text }}></div>
        </div>
      )}
    </div>
  );
};

export default FaqCard;
