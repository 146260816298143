import React, { Suspense, useEffect, lazy } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { Guard } from "./Routing.types";
import { RootState } from "../types/State.interface";
import { Loader } from "@crowd/ui-kit";

interface EnhancedRouteProps extends RouteProps {
  guards?: Guard[];
  lazyImport?: any;
}

const EnhancedRoute: React.FC<EnhancedRouteProps> = ({ guards, lazyImport, path, exact, component: Component }) => {
  const state = useSelector((state: RootState) => state);

  for (const guard of guards) {
    const { allowCondition, redirectIfFail } = guard(state);
    if (!allowCondition) {
      return <Redirect to={redirectIfFail} />;
    }
  }

  if (lazyImport) {
    const LazyComponent = lazy(lazyImport);
    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => (
          <Suspense fallback={<Loader />}>
            <LazyComponent {...props} />
          </Suspense>
        )}
      />
    );
  }

  return <Route path={path} exact={exact} component={Component} />;
};

export default EnhancedRoute;
