import dayjs, { Dayjs } from "dayjs";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";

interface ActualDateSliderProps {
  date: Dayjs;
}

const ActualDateSlider = ({ date }: ActualDateSliderProps) => {
  const prevDate = date.subtract(1, "day").add(1, "minute");
  const nextDate = date.add(1, "day").add(1, "minute");
  const project = useSelector((state: RootState) => state.project.current);

  return (
    <div className="actual-post__slide">
      {prevDate.isAfter(dayjs(project.start).startOf("day")) && (
        <Link to={`/actual?date=${prevDate.format("DD.MM.YYYY")}`}>
          <span className="ui-icon-arrow-slider-left"></span>
          {prevDate.format("DD MMMM")}
        </Link>
      )}

      {dayjs(nextDate).isBefore(dayjs(project.finish)) && (
        <Link to={`/actual?date=${nextDate.format("DD.MM.YYYY")}`}>
          {nextDate.format("DD MMMM")}
          <span className="ui-icon-arrow-slider-right"></span>
        </Link>
      )}
    </div>
  );
};

export default ActualDateSlider;
