import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import { ProposalInterface } from "../../../../../types/Proposal.interface";
import ClosingCard from "../../../../presentational/Cards/ClosingCard/ClosingCard";

interface TProps {
  idea: ProposalInterface;
}

export function SelectionIdeaRejectComment({ idea }: TProps) {
  const isMobile = useIsMobile();

  return (
    <ClosingCard isOpened type={isMobile ? "gray" : "blue"} className="selection-idea__reject-reason-card">
      <label className="selection-page__card-label-info">Причина отклонения</label>
      <div className="break-word">{idea.reason}</div>
      {!!idea.rejectComment && (
        <>
          <label className="selection-page__card-label-info">Комментарий</label>
          <div className="break-word">{idea.rejectComment}</div>
        </>
      )}
    </ClosingCard>
  );
}
