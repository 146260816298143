import React from "react";
import { stageTypesMap } from "../StageList/StageList.constants";

import "./StageMobileCard.scss";
import { checkShowStage } from "../StageList/StageList.helper";

interface StageMobileCard {
  type: string;
  stages?: any;
  onCardClick?: () => void;
  onStageClick?: (stage) => void;
  isPhone?: boolean;
  showNotCompleted?: boolean;
}

export default function StageMobileCard(props: StageMobileCard) {
  const { showNotCompleted = false } = props;
  const getType = (type) => stageTypesMap[type.toLowerCase()];

  return (
    <div
      className={"stage-mobile-card"}
      onClick={(ev) => {
        ev.stopPropagation();
        props.onCardClick && props.onCardClick();
      }}
      style={{
        background: getType(props.type).mobileColor,
      }}
    >
      <div className="stage-mobile-card__title">{props.isPhone ? getType(props.type).mobileTitle : getType(props.type).title}</div>

      <div className="stage-mobile-card__directions">
        {props.stages?.map((s, id) => (
          <button
            className={`stage-mobile-card__direction simple-btn ${
              checkShowStage(showNotCompleted, s.stageActivity, s.type) ? "stage-mobile-card__direction--disable" : ""
            } `}
            key={s.id}
            onClick={(ev) => {
              ev.stopPropagation();
              props.onStageClick && props.onStageClick(props.stages[id]);
            }}
          >
            {s.title}
          </button>
        ))}
      </div>
    </div>
  );
}
