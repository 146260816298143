import React from "react";
import { Link } from "react-router-dom";
import { calculateRemainedTime } from "../../../../utils";
import "./StageSelectorModal.scss";
import { Stage } from "../../../../types/Stage.interface";
import { Modal } from "@crowd/ui-kit";

interface StageItemProps {
  stage: Stage;
  onSelect: (stage: Stage) => void;
  close: () => void;
}

const StageItem: React.FC<StageItemProps> = ({ stage, onSelect, close }) => {
  const [_time, _unit] = calculateRemainedTime(stage);
  const handleItemClick = () => {
    close();
    onSelect(stage);
  };

  return (
    <div className="stage-selector-modal__stage-item">
      <div className="stage-selector-modal__stage-item__title" onClick={handleItemClick}>
        {stage.title}
      </div>
      <div className="stage-selector-modal__stage-item__countdown">
        {_time} {_unit} до завершения
      </div>
    </div>
  );
};

interface StageSelectorModalProps {
  isOpen: boolean;
  classname?: string;
  close: () => void;
  header?: React.ReactNode;
  message?: string;
  listHeader?: string;
  stages?: Stage[];
  buttons?: React.ReactNode;
  getStageLink?: (stage: Stage) => string;
  onSelect: (stage: Stage) => void;
}

const StageSelectorModal: React.FC<StageSelectorModalProps> = ({
  isOpen,
  classname,
  close,
  header,
  message,
  listHeader,
  stages,
  buttons,
  getStageLink,
  onSelect,
  ...otherProps
}) => {

  const getClassNamesForRoot = () => {
    let classlist = "stage-selector-modal";
    if (classname) {
      classlist += ` ${classname}`
    }
    return classlist;
  }

  return (
    <Modal isOpen={isOpen} onClose={close} classNames={getClassNamesForRoot()} {...otherProps}>
      {header && <React.Fragment key="header">{header}</React.Fragment>}
      {(message || listHeader || stages?.length) && (
        <React.Fragment key="body">
          {message && (
            <div className="crowd-modal__description stage-selector-modal__description" dangerouslySetInnerHTML={{ __html: message }}></div>
          )}
          {listHeader && <div className="crowd-modal__subhead stage-selector-modal__subhead">{listHeader}</div>}
          {stages?.length && (
            <div className="stage-selector-modal__stage-list">
              {stages.map((stage, index) => (
                <StageItem key={index} stage={stage} onSelect={onSelect} close={close} />
              ))}
            </div>
          )}
        </React.Fragment>
      )}
      {Boolean(buttons) && <React.Fragment key="footer">{buttons}</React.Fragment>}
    </Modal>
  );
};

export default StageSelectorModal;
