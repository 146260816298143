import React, { useState } from "react";
import Tabs from "../../../presentational/Tabs/Tabs";
import { IModuleCardTab } from "../Profile.interface";
import "./ProfileSubContent.scss";
import { ProfileSubContentProps } from "./ProfileSubContent.interface";
import Pagination from "../../../presentational/Pagination/Pagination";
import { scrollToTop } from "../../../../utils";
import { getUrlParam } from "../../../../utils/urlUtils";
import { useHistory } from "react-router";
import CommentsListLoader from "../../../presentational/Loaders/CommentsListLoader/CommentsListLoader";
import FadeIn from "../../../presentational/FadeIn/FadeIn";
import useQuery from "../../../../utils/hooks/useQuery";
import { pageConstants } from "./ProfileSubContent.constants";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";

const ProfileSubContent = (props: ProfileSubContentProps) => {
  const history = useHistory();
  const query = useQuery();
  const isPhone: boolean = useMediaQuery({
    query: `(max-width: ${phoneWidth}px)`,
  });
  const [tab, setTab] = useState<IModuleCardTab>(getTab());
  const [, setPage] = useState(getPageFromUrl());

  function setSearch(type, page) {
    let search = "?";
    if (type) {
      search += `type=${type.toLowerCase()}`;
    }

    search += `&page=${page + 1}`;

    history.push({
      pathname: history.location.pathname,
      search: search,
    });
  }

  function getPageFromUrl() {
    return Math.max(
      Number(getUrlParam("page", history.location.search)) - 1,
      0
    );
  }

  function getTab() {
    const { type } = query;
    if (type) {
      return props.tabs.find((t) => t.type.toLowerCase() === type.toLowerCase());
    }

    return props.tabs?.[0];
  }
  // RENDER

  const renderPaging = () => {
    return (
      <div className="my-ideas__paging">
        {Boolean(props.paging?.totalPages) && props.paging?.totalPages > 1 && (
          <Pagination
            totalPages={props.paging?.totalPages || 0}
            onPageChange={(event) => {
              setPage(event.selected);
              props.onPageChange(event.selected);
              scrollToTop();
            }}
            currentPage={props.paging?.pageNumber || 0}
            pageName="page"
          />
        )}
      </div>
    );
  };

  const getClassList = () => {
    let classList = "profile-sub";
    if (props.module) classList += ` ${props.module}__subcontent`;
    if (isPhone && pageConstants.includes(props.title)) {
      classList += " mobailPositionBtn";
    }

    if (props.classList) {
      classList += " " + props.classList;
    }

    return classList;
  };

  return (
    <div className={getClassList()}>
      <div className="profile-sub__head">
        <div className={`profile-sub__title`}>{props.title}</div>
        {props.tabs && (
          <div className="profile-sub__tabs">
            <Tabs
              currentTabId={tab?.id}
              tabs={props.tabs}
              onTabClick={(tab) => {
                setTab(tab);
                setSearch(tab.type, null);
                props.onTabChange(tab);
              }}
              isUseMobileTab={true}
            />
          </div>
        )}
        {props.panel && <div className="profile-sub__panel">{props.panel}</div>}
      </div>
      <div className="profile-sub__content">
        {props.isLoading ? (
          <CommentsListLoader />
        ) : (
          <FadeIn>{props.children}</FadeIn>
        )}
      </div>
      {props.paging && renderPaging()}
    </div>
  );
};

export default ProfileSubContent;
