import { BlogPostState } from "../../types/BlogPost.interface";
import {
  GET_ALL_PERSONAL_POSTS,
  GET_BLOG_POST,
  GET_MINE_BLOG,
  UPDATE_BLOGPOST_ACTIVITY_COMPLETE,
  UPDATE_BLOGPOST_COMPLETE,
} from "../actions/BlogActions";
import {
  ADD_COMMENT_COMPLETE,
  COMMENT_SUBSCRIBE_COMPLETE,
  COMMENT_UNSUBSCRIBE_COMPLETE,
  DELETE_COMMENT_COMPLETE,
} from "../actions/CommentActions";

const initialState: BlogPostState = {
  allPersonalPosts: null,
  mineBlog: null,
  activePost: null,
  activePostCommentCount: 0,
  activePostFavoriteCount: 0,
};

export function blogReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_PERSONAL_POSTS:
      return { ...state, allPersonalPosts: action.payload };
    case GET_MINE_BLOG:
      return { ...state, mineBlog: action.payload };
    case GET_BLOG_POST:
      return {
        ...state,
        activePost: action.payload,
        activePostCommentCount:
          action.payload.discussionStatistics.totalComments,
        activePostFavoriteCount:
          action.payload.discussionStatistics.totalSubscriptions,
      };
    case UPDATE_BLOGPOST_COMPLETE:
      return { ...state, activePost: action.payload };
    case UPDATE_BLOGPOST_ACTIVITY_COMPLETE:
      return {
        ...state,
        activePost: { ...state.activePost, active: action.payload },
      };
    case ADD_COMMENT_COMPLETE:
      return {
        ...state,
        activePostCommentCount: state.activePostCommentCount + 1,
      };
    case DELETE_COMMENT_COMPLETE:
      return {
        ...state,
        activePostCommentCount: state.activePostCommentCount - 1,
      };
    case COMMENT_SUBSCRIBE_COMPLETE:
      return {
        ...state,
        activePostFavoriteCount: state.activePostFavoriteCount + 1,
      };
    case COMMENT_UNSUBSCRIBE_COMPLETE:
      return {
        ...state,
        activePostFavoriteCount: state.activePostFavoriteCount - 1,
      };
    default:
      return state;
  }
}
