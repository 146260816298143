import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import UserService from "../../../services/userService";
import { setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { useOuterClick } from "../../../utils/hooks/useOnOutsideClick";
import { AppColorsEnum } from "../../../App.interface";
import MessageModal from "../../containers/Modals/MessageModal/MessageModal";
import Carousel from "../../presentational/Carousel/Carousel";
import { Loader } from "@crowd/ui-kit";

import { TeamGroupHeaders, slidesPerView, teamTypeConstant, orderInfoExprets } from "./Team.constants";
import ExpertCard from "./ExpertCard/ExpertCard";
import FrontmenCard from "./FrontmenCard/FrontmenCard";
import CoordinatorCard from "./CoordinatorCard/CoordinatorCard";
import DetailedView from "./DetailsCard/DetailedView";

import "./Team.scss";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import { phoneWidth, tabletWidth } from "../../../utils/constants/widthConstants";
import MobileDetailedView from "./DetailsCard/MobileDetailedView";
import useQuery from "../../../utils/hooks/useQuery";
import { AppContext } from "../../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import { RootState } from "../../../types/State.interface";
import { useHistory } from "react-router-dom";

const TeamPage = () => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: expertId } = useQuery();

  const user = useSelector((state: RootState) => state.user.userDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [experts, setExperts] = useState([]);
  const [frontmen, setFrontmen] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [isTeamMemberLoaded, setIsTeamMemberLoaded] = useState(false);
  const [messageRecipient, setMessageRecipient] = useState(null);
  const [detailCard, setDetailCard] = useState({
    frontmen: null,
    coordinators: null,
    experts: null,
  });

  const [mobailTeam, setMobailTeam] = useState({
    type: null,
    data: null,
    windowHeight: null,
  });

  const frotmenWithDetailsList = useMemo(() => frontmen.filter((frontmen) => !!frontmen.description), [frontmen]);

  const coordinatorsWithDetailList = useMemo(() => coordinators.filter((coordinator) => !!coordinator.description), [coordinators]);

  const isPhone: boolean = useMediaQuery({
    query: `(max-width: ${phoneWidth}px)`,
  });
  const isTablet: boolean = useMediaQuery({
    query: `(max-width: ${tabletWidth}px)`,
  });
  const isTabletSmaller: boolean = useMediaQuery({
    query: `(max-width: ${710}px)`,
  });
  const isTouch = isPhone || isTablet;

  const headerHeight = isPhone ? 65 : 81;

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (isTeamMemberLoaded) {
      const actions = appContext.sudirService.getActions(ComponentType.Team);
      if (actions.length) {
        const sendMessageAction = actions.find((act) => act.type === AuthActionType.SendMessage);
        if (sendMessageAction) {
          const teamMember = [...frontmen, ...experts, ...coordinators].find((member) => member.id === sendMessageAction.args.userId);
          teamMember && navigateToChat(teamMember);
        }
      }
    }
  }, [isTeamMemberLoaded]);

  useEffect(() => {
    dispatch(showHeader());
    dispatch(showFooter());
    dispatch(setAppColor(AppColorsEnum.LIGHTGRAY));

    loadTeam().then(() => scrollToExpert(expertId));
  }, []);

  useEffect(() => {
    if (isPhone) {
      let isAllNull = true;
      for (var key in detailCard) {
        if (detailCard[key]) {
          isAllNull = false;
          const windowHeight = window.scrollY;
          setMobailTeam({
            type: key,
            data: detailCard[key],
            windowHeight: windowHeight,
          });
        }
      }
      isAllNull && closeMobailModalDetails();
    }
  }, [detailCard]);

  const closeDetailCard = () => {
    setDetailCard({
      frontmen: null,
      coordinators: null,
      experts: null,
    });
  };

  const closeMobailModalDetails = () => {
    if (mobailTeam.type) {
      document.scrollingElement.scrollTop = mobailTeam.windowHeight;
    }

    setMobailTeam({
      type: null,
      data: null,
      windowHeight: null,
    });
  };

  const scrollToExpert = (id: string): void => {
    if (id) {
      const expertEl: HTMLElement = document.getElementById(id);
      expertEl.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });

      const yOffset = -headerHeight - 10;
      const y = expertEl.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const loadTeam = () => {
    setIsLoading(true);

    const requests = [UserService.getExperts(), UserService.getFrontmen(), UserService.getCoordinators()];

    return Promise.all(requests)
      .then(([{ data: experts }, { data: frontmen }, { data: coordinators }]) => {
        setExperts(experts);
        setFrontmen(frontmen);
        setCoordinators(coordinators);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsTeamMemberLoaded(true);
      });
  };

  const navigateToChat = (teamMember) => {
    if (!user.loggedIn) {
      const data: AuthActionParam = {
        component: ComponentType.Team,
        type: AuthActionType.SendMessage,
        args: { userId: teamMember.id },
      };

      const sendMessageAction = new AuthAction(data);
      return appContext.sudirService.authWithActions([sendMessageAction], true);
    }

    history.push(`/profile/mail?userId=${teamMember.id}`);
  };

  const renderTeamGroupHeader = (type, isShowDescription: boolean = true) => {
    return (
      <div className="team__group-header">
        <div className="team__group-header__title">{TeamGroupHeaders[type].title}</div>
        {isShowDescription && <div className="team__group-header__description">{TeamGroupHeaders[type].description}</div>}
      </div>
    );
  };

  const renderExperts = () => {
    return (
      <div className="team__experts">
        {renderTeamGroupHeader("experts")}
        <div className="team__experts__list">
          {experts.map((expert, key) => (
            <React.Fragment key={key}>
              <ExpertCard
                expert={expert}
                onSendMessageClick={navigateToChat}
                isMsgModalOpen={messageModalOpen}
                setDetailCard={setDetailCard}
                isPhone={isPhone}
                isTouch={isTouch}
                order={key}
              />
              {detailCard.experts?.id === expert.id && !isPhone && (
                <div className="team__experts__expert-card__wrapper" style={{ order: orderInfoExprets(isTablet, key) }}>
                  <DetailedView
                    userInfo={expert}
                    setDetailedUser={navigateToChat}
                    onClose={closeDetailCard}
                    userType={teamTypeConstant.experts}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  /**
   * @description закрываем блок подробностей лица проекта при клике снаружи блока
   */
  const frontmenRef = useOuterClick<HTMLInputElement>((event: any) => {
    if (
      !messageModalOpen &&
      typeof event?.target?.className === "string" &&
      !event?.target?.className?.includes("frontmen__card") &&
      !isPhone
    ) {
      setDetailCard({ ...detailCard, frontmen: null });
    }
  });

  const renderFrontmen = () => {
    return (
      <div className="team__frontmen" ref={frontmenRef}>
        {renderTeamGroupHeader("frontmen", isTablet ? true : !detailCard.frontmen)}

        <div className="team__frontmen__list">
          {detailCard.frontmen && !isPhone ? (
            <Carousel
              items={frotmenWithDetailsList}
              bindTo={"userInfo"}
              loop={true}
              key="frontmenDetail"
              disableSwipe={frotmenWithDetailsList.length <= slidesPerView.details}
              slidesPerView={slidesPerView.details}
              navClass={"team__frontmen__list"}
              initialSlide={frotmenWithDetailsList.findIndex((frontmen) => frontmen.id === detailCard.frontmen.id)}
            >
              <DetailedView setDetailedUser={navigateToChat} onClose={closeDetailCard} userType={teamTypeConstant.frontmen} />
            </Carousel>
          ) : (
            <Carousel
              items={frontmen}
              bindTo={"frontmen"}
              loop={true}
              key="frontmen"
              disableSwipe={frontmen.length <= slidesPerView.frontmen || isPhone}
              slidesPerView={slidesPerView.frontmen}
              navClass={"team__frontmen__list"}
            >
              <FrontmenCard onSendMessageClick={navigateToChat} setDetailed={setDetailCard} isTouch={isTouch} />
            </Carousel>
          )}
        </div>
      </div>
    );
  };

  /**
   * @description закрываем блок подробностей координатора при клике снаружи блока
   */
  const coordinatorRef = useOuterClick<HTMLInputElement>((event: any) => {
    if (
      !messageModalOpen &&
      typeof event?.target?.className === "string" &&
      !event?.target?.className?.includes("coordinators__card") &&
      !isPhone
    ) {
      setDetailCard((state) => ({ ...state, coordinators: null }));
    }
  });

  const calcCoordinatorsSlidePerView = () => {
    if (isTabletSmaller) return 2;
    if (isTablet) return 3;
    return slidesPerView.coordinators;
  };

  const renderCoordinators = () => {
    return (
      <div className="team__coordinators" ref={coordinatorRef}>
        {renderTeamGroupHeader("coordinators", isTablet ? true : !detailCard.coordinators)}

        <div className="team__coordinators__list">
          {detailCard.coordinators && !isPhone ? (
            <Carousel
              items={coordinatorsWithDetailList}
              bindTo={"userInfo"}
              loop={true}
              key="coordinatorsDetail"
              disableSwipe={coordinatorsWithDetailList.length <= slidesPerView.details}
              slidesPerView={slidesPerView.details}
              navClass={"team__coordinators__list"}
              initialSlide={coordinatorsWithDetailList.findIndex((coordinator) => coordinator.id === detailCard.coordinators.id)}
            >
              <DetailedView setDetailedUser={navigateToChat} onClose={closeDetailCard} userType={teamTypeConstant.coordinators} />
            </Carousel>
          ) : (
            <Carousel
              items={coordinators}
              bindTo={"coordinator"}
              loop={true}
              key="coordinators"
              disableSwipe={coordinators.length <= slidesPerView.coordinators || isPhone}
              slidesPerView={calcCoordinatorsSlidePerView()}
              navClass={"team__coordinators__list"}
            >
              <CoordinatorCard onSendMessageClick={navigateToChat} setDetailed={setDetailCard} isTouch={isTouch} />
            </Carousel>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading && (
        <section className="team__loader">
          <Loader />
        </section>
      )}

      {!isLoading && (
        <section className="team white-block">
          {!isTablet && !isPhone && <PageTitle text={"Команда проекта"} />}

          {renderExperts()}
          {frontmen.length > 0 && renderFrontmen()}
          {renderCoordinators()}
        </section>
      )}
      {/* TODO adjust text and attachment limits */}
      {messageModalOpen && (
        <MessageModal
          recipients={[messageRecipient]}
          isDynamic={false}
          canDraft={true}
          isOpen={messageModalOpen}
          sentOptions={{
            modal: {
              message: "Сообщение успешно отправлено. Ответ придет вам на личную почту платформы.",
            },
          }}
          draftOptions={{
            toast: {
              message: "Черновик успешно сохранен",
            },
          }}
          onClose={() => setTimeout(() => setMessageModalOpen(false))}
        />
      )}
      {isPhone && mobailTeam.type && (
        <MobileDetailedView
          userInfo={mobailTeam.data}
          setDetailedUser={navigateToChat}
          onClose={closeDetailCard}
          userType={mobailTeam.type}
          teamList={{
            frontmen: frotmenWithDetailsList,
            coordinators: coordinatorsWithDetailList,
          }}
        />
      )}
    </>
  );
};
export default TeamPage;
