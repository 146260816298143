import { serviceName } from "..";

const STATS_GIF_URL = "https://stats.mos.ru/eds.gif?o=";

let mosId = null;

const getTimestamp = (): number => Math.round(Date.now() / 1000);

const getMosId = (): string => {
  mosId =
    mosId ||
    document.cookie.replace(
      /(?:(?:^|.*;\s*)mos_id\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) || null;
  return mosId;
};

const convertDataToSendable = data =>
  encodeURIComponent(JSON.stringify({
    eventType: serviceName,
    eventSrc: document.location.href,
    eventDst: "stats",
    eventObject: data,
    mosId: getMosId(),
    eventTime: getTimestamp()
  }));

export const sendStats = data => {
  const dataToSend = convertDataToSendable(data);
  /*
  *const request = new XMLHttpRequest();
  *request.open("GET", `${STATS_GIF_URL}${dataToSend}`);
  *request.send();
  *return request;
  */
  // eslint-disable-next-line no-undef
  const img = new Image();
  img.src = `${STATS_GIF_URL}${dataToSend}`;
};