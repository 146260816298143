import React, { Children } from "react";

interface Props {
  data?: any;
  id?: string;
}

interface State {}

export class ElementObserverItem extends React.Component<Props, State> {
  render() {
    return (
      <React.Fragment key={this.props.id}>{this.props.children}</React.Fragment>
    );
  }
}
