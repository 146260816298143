import { EventsTrackConfig } from "../../types/EventsTrack.interface";

const eventsTrackConfigParams: EventsTrackConfig["params"] = {
  CLICK: {
    click: true,
  },
  DOCSCROLL: {
    Доскролл: true,
  },

  EMPTY: {
    "%s": {},
  },

  BUTTON: {
    "Кнопка %s": {},
  },
  ICON: { "Иконка %s": {} },
  LINK: { "Ссылка %s": {} },
  ENTER_TEXT: { "Ввод текста %s": {} },
  OPEN_LIST: { "Раскрытие списка %s": {} },
  BUTTON_CLICK_OPEN: { click: "Действие кнопки: Open" },
  BUTTON_CLICK_CLOSE: { click: "Действие кнопки: Close" },
  SWIPE_LEFT: {
    "Свайп влево": true,
  },
  SWIPE_RIGHT: {
    "Свайп вправо": true,
  },

  HEADER: {
    Хэдер: {},
  },
  ICON_LIST: {
    "Иконка Список": {},
  },
  FOOTER: {
    Футер: {},
  },
  PAGE_MAIN: {
    "Первая страница": {},
  },

  CROWD_LOGO: {
    "Логотип Город идей": {},
  },
  ABOUT_PROJECT: {
    "О проекте": {},
  },
  TEAM: { Команда: {} },
  PARTICIPANTS: { Участники: {} },
  PROJECT_SCHEDULE: { "График проекта": {} },
  HELP: { Помощь: {} },
  AVATAR_AUTHORIZED: { "Аватар авторизованного пользователя": {} },

  PROJECT_DISCUSSION: {
    "Обсуждаем на проекте": {},
  },
  BLOCK_CARD_STEPS: { "Блок Карточки": {} },
  BLOCK_ACTUAL: { "Блок Актуальное": {} },
  BLOCK_EXPERTS: { "Блок Эксперты проекта": {} },
  BLOCK_PARTICIPANTS: { "Блок Участники проекта": {} },
  BLOCK_DISCUSSION_COMMENTS: { "Блок Комментарии в обсуждениях": {} },
  BLOCK_PRIVATE_BLOGS: { "Блок Личные блоги": {} },

  PROJECT_DESC: { "Описание проекта": {} },
  PARTICIPANTS_APPEAL: { "Обращение к участникам": {} },
  PROJECT_RULES: { "Правила проекта": {} },
  CARD: { "Карточка %s": {} },
};
export default eventsTrackConfigParams;
