import { API } from "../../../../services/api";

const SupportService = {
  submitIssue: (params) => {
    return API.post("/api/feedback/submit", params).then(({ data }) => {
      return data;
    });
  },

  generateCaptcha: () => {
    return API.get("/api/captcha/generate").then(({ data }) => {
      return data;
    });
  },
};

export default SupportService;
