// @flow
import { fetchRequest, httpParamsSerialize } from "../../utils/axiosConfig";
import UserService, { FindUserParams } from "../../services/userService";
import { User } from "../../types/User.interface";
import { ApiResponse, ApiStatusCode } from "../../types/Common.interface";
import { Adapter } from "../../services/adapter";
import { userMock } from "../../mocks/user";

export const TEAM_LOGIN_HANDLE = "TEAM_LOGIN_HANDLE";
export const TEAM_LOGIN_SUCCESS = "TEAM_LOGIN_SUCCESS";
export const TEAM_LOGIN_FAIL = "TEAM_LOGIN_FAIL";

export const USER_SESSION_HANDLE = "USER_SESSION_HANDLE";
export const USER_SESSION_SUCCESS = "USER_SESSION_SUCCESS";
export const USER_SESSION_FAILED = "USER_SESSION_FAILED";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const UPDATE_PROFILE_AVATAR_REQUEST = "UPDATE_PROFILE_AVATAR_REQUEST";
export const UPDATE_PROFILE_AVATAR_SUCCESS = "UPDATE_PROFILE_AVATAR_SUCCESS";
export const UPDATE_PROFILE_AVATAR_FAIL = "UPDATE_PROFILE_AVATAR_FAIL";

export const UPLOAD_PROFILE_AVATAR_REQUEST = "UPLOAD_PROFILE_AVATAR_REQUEST";
export const UPLOAD_PROFILE_AVATAR_SUCCESS = "UPLOAD_PROFILE_AVATAR_SUCCESS";
export const UPLOAD_PROFILE_AVATAR_FAIL = "UPLOAD_PROFILE_AVATAR_FAIL";

export const GET_EXPERTS = "GET_EXPERTS";

export const LOGOUT = "LOGOUT";

export const SET_AVATAR_ID = "SET_AVATAR_ID";

export const teamLogin = (params, onSuccess, onFail) => (dispatch) => {
  return UserService.login(params).then((response) => {
    // if (response.status === ApiStatusCode.PLATFORM_USER_NOT_FOUND) {
    //   dispatch({
    //     type: TEAM_LOGIN_FAIL,
    //     payload: response.message,
    //   });

    //   return;
    // }

    if (response.status !== ApiStatusCode.OK) {
      return onFail(response);
    }

    dispatch(getUser(onSuccess));
  });
};

export const getUser = (successCallback?) => (dispatch) => {
  dispatch({
    type: USER_SESSION_HANDLE,
  });

  return UserService.getUser().then((userData) => {
    if (userData?.status === ApiStatusCode.USER_BLOCKED) {
      const partials = {
        blocked: true,
      };
      const data = Adapter.toUser(partials);
      return dispatch({
        type: USER_SESSION_SUCCESS,
        payload: { loggedIn: false, ...data },
      });
    }

    if (!userData || !userData.data || userData.data.id === "_-1") {
      return dispatch({
        type: USER_SESSION_SUCCESS,
        payload: { loggedIn: false, ...(userData?.data || userMock) },
      });
    }

    dispatch({
      type: USER_SESSION_SUCCESS,
      payload: { loggedIn: true, ...userData.data },
    });

    if (typeof successCallback === "function") successCallback(userData.data);
  });
};

export const getExperts = () => (dispatch) => {
  UserService.getExperts()
    .then(({ data }) => {
      dispatch({
        type: GET_EXPERTS,
        payload: data,
      });
    })
    .catch((err) => console.error(err));
};

export const logout = (history?) => (dispatch) => {
  UserService.logout();
};

export const updateProfile = (params, onSuccess?) => (dispatch) => {
  return UserService.updateUser(params).then((response) => {
    if (response.status === ApiStatusCode.OK) {
      //ASK BACKEND TO RETURN UPDATED USERINFO
      dispatch(getUser());
      onSuccess && onSuccess();
    }
  });
};

export function changePassword(params) {
  return async function (dispatch) {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });

    const url = "/api/profile/changePassword";

    try {
      let response = await fetchRequest(url, params, "POST");
      response = response.data;

      if (response.status === ApiStatusCode.OK) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: CHANGE_PASSWORD_FAIL,
          error: true,
          payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        error: true,
        payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
      });
    }
  };
}

export function handleChangeAvatar(params) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_PROFILE_AVATAR_REQUEST,
    });

    const query = httpParamsSerialize(params);

    const url = `api/userpicture/setDefault?${query}`;

    try {
      let response = await fetchRequest(url, {}, "POST");
      response = response.data;

      if (response.status === ApiStatusCode.OK) {
        dispatch({
          type: UPDATE_PROFILE_AVATAR_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UPDATE_PROFILE_AVATAR_FAIL,
          error: true,
          payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_PROFILE_AVATAR_FAIL,
        error: true,
        payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
      });
    }
  };
}

export function handleUploadAvatar(params) {
  return async function (dispatch) {
    dispatch({
      type: UPLOAD_PROFILE_AVATAR_REQUEST,
    });

    const query = httpParamsSerialize(params.coords);
    const url = `api/userpicture/upload?${query}`;
    const data = Uint8Array.from(atob(params.src.match(/,(.*)$/)[1]), function (c) {
      return c.charCodeAt(0);
    });

    try {
      let response = await fetchRequest(url, data, "POST", {
        "Content-Type": params.type,
      });
      response = response.data;

      if (response.status === ApiStatusCode.OK) {
        dispatch({
          type: UPLOAD_PROFILE_AVATAR_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UPLOAD_PROFILE_AVATAR_FAIL,
          error: true,
          payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
        });
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_PROFILE_AVATAR_FAIL,
        error: true,
        payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
      });
    }
  };
}

export const findUser = (params: FindUserParams, successCb?: (data: ApiResponse<User[]>) => any) => (dispatch) => {
  UserService.findUser(params).then((res) => {
    if (res.status === ApiStatusCode.OK) {
      dispatch({ type: `FIND_USERS_${params.type}` });
      successCb && successCb(res);
    }
  });
};
