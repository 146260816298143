import { createContext, useContext } from "react";
import { TSelectionParams } from "../types/types";
import { SelectionFolders } from "../../../../utils/constants/proposal";

export const SelectionParamsContext = createContext<TSelectionParams>({
  folder: SelectionFolders.ACCEPTED,
  stageId: "",
});

export const useSelectionParams = () => {
  return useContext(SelectionParamsContext);
};