import { Input } from "@crowd/ui-kit";
import { InputProps } from "@crowd/ui-kit/build/components/Input/Input.types";
import { FC, useEffect, useRef } from "react";
import { setTrackIdToEl } from "../../../../../utils/eventsTrack";

const EventsTrackClickInput: FC<InputProps> = (props) => {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    const el = ref.current;
    setTrackIdToEl(el, ["ENTER_TEXT", "CLICK"], "click", { replace: [props.label as string || props.placeholder], partial: true, rememberPrev: true });
  }, []);

  return <Input ref={ref} {...props} />;
};

export default EventsTrackClickInput;
