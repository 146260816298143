import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { calculateRemainedTime } from "../../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { stageSelector } from "../../../../../store/selectors/stage";
import { SELECTION_SET_IS_STAGE_FINISHED } from "../../../../../store/actions/SelectionActions";

export function SelectionRemained() {
  const dispatch = useDispatch();

  const stage = useSelector(stageSelector);

  const [remained, setRemained] = useState("");
  const [stageFinishIsComing, setStageFinishIsComing] = useState(false);

  useEffect(() => {
    if (!stage) {
      return;
    }

    const _setRemained = () => {
      if (dayjs(stage.finish) < dayjs()) {
        setRemained("Этап завершен");
        dispatch({ type: SELECTION_SET_IS_STAGE_FINISHED, payload: true });
        setStageFinishIsComing(false);
        return;
      }

      const res = calculateRemainedTime(stage);
      setRemained(res ? `${res[0]} ${res[1]} до завершения этапа` : "");
      setStageFinishIsComing(dayjs(stage.finish) < dayjs().add(1, "day"));
    };

    _setRemained();

    const tid = setInterval(_setRemained, 60000);

    return () => clearInterval(tid);
  }, [stage]);

  return (
    !!remained && (
      <div className={`selection-page__remained ${stageFinishIsComing ? "selection-page__remained_finishing" : ""}`}>
        <i className="ui-icon ui-icon-clock" />
        {remained}
      </div>
    )
  );
}
