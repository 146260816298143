import React from "react";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import "./ModuleCard.scss";

const ModuleCard = (props) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  return (
    <div className="module-card" onClick={() => props.onClick(props.module)}>
      <div className="module-card__head">
        <span
          className={`module-card__icon ${
            isPhone ? props.module.iconPhone : props.module.icon
          }`}
        ></span>
      </div>
      <div className="module-card__body">
        {isPhone && props.module.titlePhone
          ? props.module.titlePhone
          : props.module.title}
      </div>
      <div className="module-card__footer">
        <span className="module-card__count">
          {Boolean(props.module.count) && props.module.count}
        </span>
        {Boolean(props.module.newCount) && (
          <span className="module-card__countNew">{props.module.newCount}</span>
        )}
      </div>
    </div>
  );
};

export default ModuleCard;
