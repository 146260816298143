import {
  SHOW_INFOMODAL,
  HIDE_INFOMODAL,
  SHOW_MAINTENANCE_MODAL,
  HIDE_MAINTENANCE_MODAL,
  SHOW_MOSRU_AUTH_FORM,
  TOGGLE_DOCUMENT_BLOCKED,
  WIDEN_PAGE,
  LESSEN_PAGE,
  HIDE_MOSRU_AUTH_FORM,
  SHOW_USER_MODAL,
  HIDE_USER_MODAL,
  SHOW_HEADER,
  HIDE_HEADER,
  SHOW_FOOTER,
  HIDE_FOOTER,
  SET_APP_COLOR,
  HIDE_HEADER_LINKS,
  SHOW_HEADER_LINKS,
  SHOW_CHAT,
  HIDE_CHAT,
  EVENTS_TRACK_SCROLL_UPDATE,
} from "../actions/LayoutActions";
import { User } from "../../types/User.interface";
import { AppColorsEnum, FooterTextColorsEnum } from "../../App.interface";

export interface LayoutState {
  pageLessened: boolean;
  showInfoModal: boolean;
  showUserModal: boolean;
  infoModalTitle: string;
  infoModalMessage: string;
  infoModalJSX: string;
  infoModalOnDismiss: () => void;
  infoModalClassNames: string;
  showMaintenanceModal: boolean;
  showMosRuAuthForm: boolean;
  returnUrl: string;
  documentIsBlocked: boolean;
  selectedUser: User | null;
  appColor: AppColorsEnum;
  footerTextColor: FooterTextColorsEnum;
  isHeaderVisible: boolean;
  isFooterVisible: boolean;
  headerLinksVisible: boolean;
  isChatVisible: boolean;
  eventsTrackScrollCounterTrigger: number;
}

const initialState: LayoutState = {
  pageLessened: true,
  showInfoModal: false,
  infoModalTitle: "",
  infoModalMessage: "",
  infoModalJSX: "",
  infoModalOnDismiss: () => {},
  infoModalClassNames: "",
  showMaintenanceModal: false,
  showMosRuAuthForm: false,
  returnUrl: "",
  documentIsBlocked: false,
  showUserModal: false,
  selectedUser: null,
  appColor: AppColorsEnum.WHITE,
  footerTextColor: FooterTextColorsEnum.GRAY,
  isHeaderVisible: false,
  isFooterVisible: false,
  headerLinksVisible: true,
  isChatVisible: true,
  eventsTrackScrollCounterTrigger: 0, // триггер для переинициализации IntersectionObserver в EventsTrackContainer
};

export function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MOSRU_AUTH_FORM:
      return {
        ...state,
        showMosRuAuthForm: true,
        returnUrl: action.payload,
      };

    case HIDE_MOSRU_AUTH_FORM:
      return {
        ...state,
        showMosRuAuthForm: false,
        returnUrl: "",
      };

    case SHOW_INFOMODAL:
      return {
        ...state,
        showInfoModal: true,
        infoModalTitle: action.payload.title,
        infoModalMessage: action.payload.message,
        infoModalJSX: action.payload.jsx || state.infoModalJSX,
        infoModalOnDismiss: action.payload.onDismiss,
        infoModalClassNames: action.payload.classNames || state.infoModalClassNames,
      };

    case HIDE_INFOMODAL:
      if (state.infoModalOnDismiss) state.infoModalOnDismiss();
      return {
        ...state,
        showInfoModal: false,
        infoModalTitle: "",
        infoModalMessage: "",
        infoModalClassNames: "",
      };

    case SHOW_MAINTENANCE_MODAL:
      return {
        ...state,
        showMaintenanceModal: true,
      };

    case HIDE_MAINTENANCE_MODAL:
      return {
        ...state,
        showMaintenanceModal: false,
      };

    case SHOW_USER_MODAL:
      return {
        ...state,
        showUserModal: true,
        selectedUser: action.payload,
      };

    case HIDE_USER_MODAL:
      return {
        ...state,
        showUserModal: false,
        selectedUser: null,
      };

    case TOGGLE_DOCUMENT_BLOCKED:
      return {
        ...state,
        documentIsBlocked: action.payload,
      };

    case WIDEN_PAGE:
      return {
        ...state,
        pageLessened: false,
      };

    case LESSEN_PAGE:
      return {
        ...state,
        pageLessened: true,
      };

    case SET_APP_COLOR:
      return {
        ...state,
        appColor: action.payload.appColor,
        footerTextColor: action.payload.footerTextColor,
      };

    case SHOW_HEADER:
      return {
        ...state,
        isHeaderVisible: true,
      };

    case HIDE_HEADER:
      return {
        ...state,
        isHeaderVisible: false,
      };

    case SHOW_FOOTER:
      return {
        ...state,
        isFooterVisible: true,
      };

    case HIDE_FOOTER:
      return {
        ...state,
        isFooterVisible: false,
      };

    case HIDE_HEADER_LINKS:
      return {
        ...state,
        headerLinksVisible: false,
      };

    case SHOW_HEADER_LINKS:
      return {
        ...state,
        headerLinksVisible: true,
      };

    case HIDE_CHAT:
      return {
        ...state,
        isChatVisible: false,
      };

    case SHOW_CHAT:
      return {
        ...state,
        isChatVisible: true,
      };

    case EVENTS_TRACK_SCROLL_UPDATE:
      return {
        ...state,
        eventsTrackScrollCounterTrigger: state.eventsTrackScrollCounterTrigger + 1,
      };

    default:
      return state;
  }
}
