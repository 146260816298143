import { FC, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { hideInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import "./SudirSessionError.scss";
import { Button } from "@crowd/ui-kit";

const messageText = `Пожалуйста, повторите попытку позже. <br> В случае повторения ошибки напишите в раздел «Помощь», указав адрес почты или телефон, которые используете для входа в личный кабинет портала <a href="https://www.mos.ru/">mos.ru</a>.`;

const SudirSessionError: FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authError = searchParams.get("authError") === "true";

    if (authError) {
      dispatch(
        showInfoModal(messageText, "Ошибка авторизации", <Button type="outlined" text="Хорошо" onClick={() => dispatch(hideInfoModal())} />)
      );

      history.replace(location.pathname);
    }
  }, []);

  return <>{children}</>;
};

export default SudirSessionError;
