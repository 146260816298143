import React from "react";
import "./ItemStats.scss";
import { declination } from "../../../utils";
import { useMediaQuery } from "react-responsive";

import {
  phoneWidth,
  tabletWidth,
} from "../../../utils/constants/widthConstants";
export interface ItemStatsProps {
  icon: string;
  text?: string;
  isMain?: boolean;
  type?: "COMMENTS" | "VOTES" | "IDEAS";
  count?: number;
}

export const ItemStats = ({
  icon,
  text,
  isMain = false,
  type,
  count,
}: ItemStatsProps) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const hideIcon = !isMain && isPhone && icon === "clock";
  const hideTextPart =
    !isMain && isTablet && icon !== "clock" && type === "COMMENTS";

  const getCount = (count) => {
    return `<b>${count}</b>`;
  };

  const getTextByType = (options: string[]) => {
    return (
      getCount(count) + (hideTextPart ? "" : ` ${declination(count, options)}`)
    );
  };

  const getText = (): string => {
    switch (type) {
      case "COMMENTS":
        return getTextByType([" комментарий", " комментария", " комментариев"]);
      case "IDEAS":
        return getTextByType([" идея", " идеи", " идей"]);
      case "VOTES":
        return getTextByType([" оценка", " оценки", " оценок"]);
      default:
        return text || "";
    }
  };

  return (
    <div
      className={`item__stats ${
        isMain ? "item__stats-main" : "item__stats-common"
      }`}
    >
      {!hideIcon && (
        <i className={`${isMain ? "ui-icon" : ""} ui-icon-${icon}`} />
      )}

      <div dangerouslySetInnerHTML={{ __html: getText() }} />
    </div>
  );
};

export default ItemStats;
