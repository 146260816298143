import { API } from "./api";
import { QuestionnaireFieldReg } from "../types/Projects.interface";
import { IBaseResponse } from "../App.interface";

const baseUrl = "/api/project";

const ProjectService = {
  getProject: () => {
    return API.get(`${baseUrl}/get`).then((data) => {
      return data?.data;
    });
  },
  getProjectStatus: () => {
    return API.get(`${baseUrl}/getProjectStatus`).then((data) => {
      return data?.data;
    });
  },
  getStatistics: () => {
    return API.get(`${baseUrl}/getCounters`).then((data) => {
      return data?.data;
    });
  },
  getNode: (id) => {
    return API.get(`${baseUrl}/node/get?id=${id}`).then(({ data }) => {
      return data;
    });
  },
  addNode: (node) => {
    return API.post(`${baseUrl}/node/add`, node).then(({ data }) => {
      return data;
    });
  },
  deleteNode: (id: string) => {
    return API.post(`${baseUrl}/node/delete`, { id }).then(({ data }) => {
      return data;
    });
  },
  updateNode: (node) => {
    return API.post(`${baseUrl}/node/update`, node).then(({ data }) => {
      return data;
    });
  },
  setClosed: (closed: boolean) => {
    return API.post(`${baseUrl}/setClosed`, { closed }).then(({ data }) => {
      return data;
    });
  },
  setupProject: (project) => {
    return API.post(`${baseUrl}/setup`, project).then(({ data }) => {
      return data;
    });
  },
  getCriteria: (raw = false) => {
    // Получение критериев отбора идей
    return API.get(`${baseUrl}/criteria?raw=${raw}`).then(({ data }) => {
      return data;
    });
  },
  getRestrictions: (raw = false) => {
    // Получение ограничений на проекте
    return API.get(`${baseUrl}/restrictions?raw=${raw}`).then(({ data }) => {
      return data;
    });
  },
  getRules: (raw = false) => {
    // Получение правил работы
    return API.get(`${baseUrl}/rules?raw=${raw}`).then(({ data }) => {
      return data;
    });
  },
  getSpeech: (raw = false) => {
    // Получение обращения к участникам
    return API.get(`${baseUrl}/speech?raw=${raw}`).then(({ data }) => {
      return data;
    });
  },
  getStructure: () => {
    // Получение данных для построения графика проекта
    return API.get(`${baseUrl}/structure`).then(({ data }) => {
      return data;
    });
  },

  getProjectStructure: () => {
    return API.get("/api/project/structure").then(({ data }) => {
      return data;
    });
  },

  getAccessibleIds: () => {
    return API.get("/api/stage/getAccessibleIds").then(({ data }) => {
      return data;
    });
  },

  getQuestionnaire: () => {
    return API.get("/api/project/questionnaire").then(({ data }) => {
      return data;
    });
  },

  registerQuestionnaire: (fields: QuestionnaireFieldReg[]) => {
    return API.post("/api/questionnaire/register", { fields }).then(({ data }) => {
      return data;
    });
  },

  stopStage: (id: string): Promise<IBaseResponse<void>> => {
    return API.post("/api/project/stage/stop", { id }).then(({ data }) => data);
  },

  confirmFinishStage: (id: string): Promise<IBaseResponse<void>> => {
    return API.post("/api/project/stage/confirmFinish", { id }).then(({ data }) => data);
  },
};

export default ProjectService;
