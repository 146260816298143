import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { RootState } from "../../../types/State.interface";
import ArrowBack from "../../presentational/Controls/ArrowBack/ArrowBack";
import EditInfo from "./Edit/EditInfo";
import MyMails from "./MyMails/MyMails";
import Menu from "./Menu/Menu";
import "./Profile.scss";
import { getFavoriteDiscussions, getPersonalComments, getPersonalProposals } from "../../../store/actions/PersonalActions";
import { getAnswersComments } from "../../../store/actions/CommentActions";
import { getAllPersonalPosts } from "../../../store/actions/BlogActions";
import MyIdeas from "./MyIdeas/MyIdeas";
import MyComments from "./MyComments/MyComments";
import MyNotifications from "./MyNotifications/MyNotifications";
import { setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import MyBlog from "./MyBlog/MyBlog";
import ProfilePic from "./avatar/ProfilePic/ProfilePic";
import CropAvatarModal from "./avatar/CropAvatarModal/CropAvatarModal";
import AvatarModal from "./avatar/AvatarModal/AvatarModal";
import { getUser } from "../../../store/actions/UserActions";
import MyAnswers from "./MyAnswers/MyAnswers";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import MyFavorite from "./MyFavorite/MyFavorite";
import { phoneWidth } from "../../../utils/constants/widthConstants";
import HiddenFIOWarning from "./HiddenFIOWarning/HiddenFIOWarning";
import ExactIdea from "./MyIdeas/ExactIdea/ExactIdea";
import Conversations from "./Conversations/Conversations";
import Chat from "./Conversations/Components/Chat/Chat";
import classNames from "classnames";
import { profilePaths } from "./Profile.constants";

const ProfilePage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const ideas = useSelector((state: RootState) => state.personal.proposals);
  const comments = useSelector((state: RootState) => state.personal.comments);
  const answers = useSelector((state: RootState) => state.comment.answersComments);
  const favorites = useSelector((state: RootState) => state.personal.favorite);
  const notes = useSelector((state: RootState) => state.blog.allPersonalPosts);

  const [isEditAvatarModalOpen, setEditAvatarModalOpen] = useState(false);
  const [isCropAvatarModalOpened, setCropAvatarModalOpened] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);

  const isPhone: boolean = useMediaQuery({
    query: `(max-width: ${phoneWidth}px)`,
  });

  useEffect(() => {
    dispatch(showHeader());
    dispatch(showFooter());
    if (!ideas) dispatch(getPersonalProposals());
    if (!comments) dispatch(getPersonalComments());
    if (!answers) dispatch(getAnswersComments());
    dispatch(getAllPersonalPosts());
    if (!favorites) dispatch(getFavoriteDiscussions());
  }, []);

  useEffect(() => {
    const [tr, path, subpath] = location.pathname.split("/");
    dispatch(setAppColor(subpath ? AppColorsEnum.LIGHTBLUE : AppColorsEnum.WHITE));
  }, [location.pathname]);

  const canRenderProfileInfo = () => location.pathname === "/profile" || (isCurrentPath(profilePaths) && !isPhone);

  const canRenderArrow = () => isCurrentPath(profilePaths);

  const isCurrentPath = (paths) => paths.includes(location.pathname);

  // RENDER
  const renderHead = () => {
    return (
      <div className={classNames("profile-header", { "profile-header--empty": !(canRenderArrow() || canRenderProfileInfo()) })}>
        {canRenderProfileInfo() && (
          <div className="profile-header__user">
            <ProfilePic imageId={user.pictureId} onClick={() => setEditAvatarModalOpen(true)} showHoverEffect={true} />

            <div className="profile-header__apart">
              <div className="profile-header__names">
                {user?.firstName}
                <br></br>
                {user?.lastName}
              </div>
              {location.pathname !== "/profile/edit" && (
                <span className="profile-header__edit" onClick={() => history.push("/profile/edit")}>
                  <span className="ui-icon-edit"></span>
                  <span>Редактировать данные</span>
                  {!user.showName && <HiddenFIOWarning />}
                </span>
              )}
            </div>
          </div>
        )}
        {canRenderArrow() && <ArrowBack text="Меню личного кабинета" onClick={() => history.push("/profile")} />}
      </div>
    );
  };

  const renderAvatarModal = () => {
    return (
      <>
        {isEditAvatarModalOpen && (
          <AvatarModal
            isOpened={isEditAvatarModalOpen}
            onClose={() => {
              setEditAvatarModalOpen(false);
            }}
            gender={user.gender}
            pictureId={user.pictureId}
            upload={true}
            onSuccess={() => {
              dispatch(getUser());
            }}
            onSelectFile={(file) => {
              setAvatarFile(file);
              setCropAvatarModalOpened(true);
            }}
            isPhone={isPhone}
          ></AvatarModal>
        )}

        {isCropAvatarModalOpened && (
          <CropAvatarModal
            isOpened={isCropAvatarModalOpened}
            onClose={() => setCropAvatarModalOpened(false)}
            file={avatarFile}
            onSuccess={() => {
              dispatch(getUser());
              setEditAvatarModalOpen(false);
            }}
          ></CropAvatarModal>
        )}
      </>
    );
  };

  return (
    <section className="profile-page">
      {renderHead()}
      {renderAvatarModal()}

      <Switch>
        <Route exact path="/profile" component={Menu} />
        <Route exact path="/profile/edit" component={() => <EditInfo onAvatarClick={() => setEditAvatarModalOpen(true)} />} />
        <Route exact path="/profile/mail" component={Conversations} />
        <Route exact path="/profile/mail/:id" component={Chat} />
        <Route exact path="/profile/ideas" component={MyIdeas} />
        <Route exact path="/profile/ideas/:proposalId" component={ExactIdea} />
        <Route exact path="/profile/comments" component={MyComments} />
        <Route exact path="/profile/answers" component={MyAnswers} />
        <Route exact path="/profile/blog" component={MyBlog} />
        <Route exact path="/profile/favorites" component={MyFavorite} />
        <Route exact path="/profile/notifications" component={MyNotifications} />
      </Switch>
    </section>
  );
};

export default ProfilePage;
