const addresses = [
	{
		"name": "Восточный",
		"districts": [
			"Богородское",
			"Вешняки",
			"Восточный",
			"Восточное Измайлово",
			"Гольяново",
			"Ивановское",
			"Измайлово",
			"Косино-Ухтомский",
			"Метрогородок",
			"Новогиреево",
			"Новокосино",
			"Перово",
			"Преображенское",
			"Северное Измайлово",
			"Соколиная Гора",
			"Сокольники"
		]
	},
	{
		"name": "Западный",
		"districts": [
			"Дорогомилово",
			"Внуково",
			"Крылатское",
			"Кунцево",
			"Можайский",
			"Ново-Переделкино",
			"Очаково-Матвеевское",
			"Проспект Вернадского",
			"Раменки",
			"Солнцево",
			"Тропарёво-Никулино",
			"Филёвский Парк",
			"Фили-Давыдково"
		]
	},
	{
		"name": "Зеленоградский",
		"districts": [
			"Матушкино",
			"Савёлки",
			"Старое Крюково",
			"Силино",
			"Крюково"
		]
	},
	{
		"name": "Новомосковский",
		"districts": [
			"поселение Воскресенское",
			"поселение Внуковское",
			"поселение Десёновское",
			"поселение Кокошкино",
			"поселение Марушкинское",
			"поселение Московский",
			"поселение «Мосрентген»",
			"поселение Рязановское",
			"поселение Сосенское",
			"поселение Филимонковское",
			"поселение Щербинка"
		]
	},
	{
		"name": "Северный",
		"districts": [
			"Аэропорт",
			"Беговой",
			"Бескудниковский",
			"Войковский",
			"Восточное Дегунино",
			"Головинский",
			"Дмитровский",
			"Западное Дегунино",
			"Коптево",
			"Левобережный",
			"Молжаниновский",
			"Савёловский",
			"Сокол",
			"Тимирязевский",
			"Ховрино",
			"Хорошёвский"
		]
	},
	{
		"name": "Северо-Восточный",
		"districts": [
			"Алексеевский",
			"Алтуфьевский",
			"Бабушкинский",
			"Бибирево",
			"Бутырский",
			"Лианозово",
			"Лосиноостровский",
			"Марфино",
			"Марьина Роща",
			"Останкинский",
			"Отрадное",
			"Ростокино",
			"Свиблово",
			"Северный",
			"Северное Медведково",
			"Южное Медведково",
			"Ярославский"
		]
	},
	{
		"name": "Северо-Западный",
		"districts": [
			"Куркино",
			"Митино",
			"Покровское-Стрешнево",
			"Северное Тушино",
			"Строгино",
			"Хорошёво-Мнёвники",
			"Щукино",
			"Южное Тушино"
		]
	},
	{
		"name": "Троицкий",
		"districts": [
			"поселение Вороновское",
			"поселение Киевский",
			"поселение Клёновское",
			"поселение Краснопахорское",
			"поселение Михайлово-Ярцевское",
			"поселение Новофёдоровское",
			"поселение Первомайское",
			"поселение Роговское",
			"поселение Троицк",
			"поселение Щаповское"
		]
	},
	{
		"name": "Центральный",
		"districts": [
			"Арбат",
			"Басманный",
			"Замоскворечье",
			"Красносельский",
			"Мещанский",
			"Пресненский",
			"Таганский",
			"Тверской",
			"Хамовники",
			"Якиманка"
		]
	},
	{
		"name": "Юго-Восточный",
		"districts": [
			"Выхино-Жулебино",
			"Капотня",
			"Кузьминки",
			"Лефортово",
			"Люблино",
			"Марьино",
			"Некрасовка",
			"Нижегородский",
			"Печатники",
			"Рязанский",
			"Текстильщики",
			"Южнопортовый"
		]
	},
	{
		"name": "Юго-Западный",
		"districts": [
			"Академический",
			"Гагаринский",
			"Зюзино",
			"Коньково",
			"Котловка",
			"Ломоносовский",
			"Обручевский",
			"Северное Бутово",
			"Тёплый Стан",
			"Черёмушки",
			"Южное Бутово",
			"Ясенево"
		]
	},
	{
		"name": "Южный",
		"districts": [
			"Бирюлёво Восточное",
			"Бирюлёво Западное",
			"Братеево",
			"Даниловский",
			"Донской",
			"Зябликово",
			"Москворечье-Сабурово",
			"Нагатино-Садовники",
			"Нагатинский Затон",
			"Нагорный",
			"Орехово-Борисово Северное",
			"Орехово-Борисово Южное",
			"Царицыно",
			"Чертаново Северное",
			"Чертаново Центральное",
			"Чертаново Южное"
		]
	}
]

export default addresses