import cn from "classnames";
import "./Badge.scss";

interface TProps {
  text: string | number;
  color?: string;
  className?: string;
  wrapperClassName?: string;
  children?: React.ReactNode;
}

export function Badge({ text, color = "var(--base-white)", wrapperClassName, className, children }: TProps) {
  return (
    <div className={cn("badge__container", wrapperClassName)}>
      {children}
      <div className={cn("badge__item", className)} style={{ backgroundColor: color }}>
        {text}
      </div>
    </div>
  );
}
