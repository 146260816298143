import { combineReducers } from "redux";

import { profileReducer } from "./profile";
import { layoutReducer } from "./layout";
import { stageReducer } from "./stage";
import { projectReducer } from "./project";
import { blogReducer } from "./blog";
import { notificationsReducer } from "./notifications";
import { commentReducer } from "./comment";
import { discussionReducer } from "./discussion";
import { proposalReducer } from "./proposal";
import { personalReducer } from "./personal";
import { mailReducer } from "./mail";
import { commonReducer } from "./common";
import { toastReducer } from "./toast";
import { globalEventsReducer } from "./globalEvents";
import { badWordsReducer } from "./badWords";
import { participantsReducer } from "./participants";
import { chatReducer } from "./chat";
import { selectionReducer } from "./selection";

export const rootReducer = combineReducers({
  user: profileReducer,
  layout: layoutReducer,
  project: projectReducer,
  stage: stageReducer,
  blog: blogReducer,
  comment: commentReducer,
  notifications: notificationsReducer,
  discussion: discussionReducer,
  proposal: proposalReducer,
  personal: personalReducer,
  mail: mailReducer,
  common: commonReducer,
  toast: toastReducer,
  globalEvents: globalEventsReducer,
  badWords: badWordsReducer,
  participants: participantsReducer,
  chat: chatReducer,
  selection: selectionReducer,
});
