import { ComplaintAddParams } from "../types/Complaint.interface";
import { API } from "./api";

const baseUrl = "/api/complaints";

const ComplaintService = {
  addComplaint: (data: ComplaintAddParams) => {
    // Создание жалобы на пользователя.
    return API.post(`${baseUrl}/add`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default ComplaintService;
