import { Button } from "@crowd/ui-kit";
import { ButtonProps } from "@crowd/ui-kit/build/components/Button/Button.types";
import { FC, useEffect, useRef } from "react";
import { EventsTrackId, EventsTrackParams } from "../../../../../types/EventsTrack.interface";
import { setTrackIdToEl } from "../../../../../utils/eventsTrack";
import useEventsTrackParentId from "../../../../../utils/hooks/useEventsTrackParentId";

const EventsTrackClickButton: FC<ButtonProps & { params?: EventsTrackParams; needParent?: boolean }> = ({
  params,
  needParent,
  ...otherProps
}) => {
  const ref = useRef<Button>();
  const parentEventsTrackId = useEventsTrackParentId();

  useEffect(() => {
    const el = ref.current?.getRef().current;
    const ids: EventsTrackId[] = ["BUTTON", "CLICK"];

    if (needParent) {
      if (!parentEventsTrackId) {
        return;
      }
      ids.unshift(...[parentEventsTrackId].flat());
    }

    setTrackIdToEl(el, ids, "click", { ...params, replace: params?.replace || [otherProps.text] });
  }, []);

  return <Button ref={ref} {...otherProps} />;
};

export default EventsTrackClickButton;
