import { Button } from "@crowd/ui-kit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppColorsEnum } from "../../../App.interface";
import { hideFooter, hideHeaderLinks, setAppColor, showFooter, showHeader, showHeaderLinks } from "../../../store/actions/LayoutActions";
import SupportForm from "../../containers/Forms/SupportForm/SupportForm";

import "./PrivatePage.scss";

const PrivatePage = (props) => {
  const dispatch = useDispatch();

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(hideHeaderLinks());
    dispatch(hideFooter());

    return () => {
      dispatch(showHeaderLinks());
      dispatch(showFooter());
    };
  }, []);

  const mosRuLogin = () => (window.location.href = "/oauth2/authorization/sudir");

  return (
    <section className="private-page">
      <div className="private-page__container">
        <img src={require("../../../assets/private1.svg").default} />

        <div className="private-page__container-text">
          <p>
          На платформе «Город идей» проходит проект «Твой город – твоё дело». Если вам пришло приглашение – авторизуйтесь с помощью своего логина и пароля от mos.ru.
          </p>
          <p>
          Если вы не можете присоединиться к проекту, напишите в раздел «Помощь», указав данные от личного кабинета портала mos.ru.
          </p>
        </div>
        <Button text={"ВОЙТИ ЧЕРЕЗ MOS.RU"} color={"#CE2124"} type={"filled"} size={"m"} onClick={() => mosRuLogin()} />

        <button className="simple-btn help-btn" onClick={() => setIsHelpFormOpen(true)}>
          <i className="ui-icon-help ui-icon" />
          Помощь
        </button>
      </div>

      {isHelpFormOpen && <SupportForm isOpened={isHelpFormOpen} close={() => setIsHelpFormOpen(false)} />}
    </section>
  );
};

export default PrivatePage;
