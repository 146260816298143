import React, { useState } from "react";

import { User } from "../../../../types/User.interface";
import { useOuterClick } from "../../../../utils/hooks/useOnOutsideClick";

import FadeIn from "../../../presentational/FadeIn/FadeIn";
import { Button } from "@crowd/ui-kit";

import "./ExpertCard.scss";
import { getAvatarSrc } from "../../../../utils";
import Tooltip from "../../../presentational/Tooltip/Tooltip";

interface ExpertCardProps {
  expert?: User;
  onSendMessageClick?: Function;
  isMsgModalOpen: boolean;
  setDetailCard?: Function;
  isPhone: boolean;
  isTouch: boolean;
  order: number;
}

const ExpertCard = ({
  expert,
  onSendMessageClick,
  isMsgModalOpen,
  setDetailCard,
  isPhone,
  isTouch,
  order
}: ExpertCardProps) => {
  const [showButton, setShowButton] = useState(false);
  const cardInnerRef = useOuterClick<HTMLInputElement>((event: any) => {
    if (
      !isMsgModalOpen &&
      typeof event?.target?.className === "string" &&
      !event?.target?.className?.includes("team__experts__expert-card") &&
      !isPhone
    ) {
      setDetailCard((state) => ({ ...state, experts: null }));
    }
    if (isTouch) {
      setShowButton(false);
    }
  });
  const hasDetails = !!expert.description;

  // const position =
  //   "Начальник управления информатизации и информационных технологий Департамента культуры г. Москвы";

  return (
    <>
      <div
        id={expert.id}
        className="team__experts__expert-card"
        ref={cardInnerRef}
        onMouseEnter={() => !hasDetails && !isTouch && setShowButton(true)}
        onMouseLeave={() => !hasDetails && !isTouch && setShowButton(false)}
        style={{order}}
      >
        <div
          className={
            "team__experts__expert-card--basic" + (hasDetails ? " pointer" : "")
          }
          onClick={() => {
            if (hasDetails) {
              setDetailCard((state) => ({ ...state, experts: expert }));
            } else {
              isTouch && setShowButton(!showButton);
            }
          }}
        >
          <img
            className="team__experts__expert-card__picture"
            src={getAvatarSrc(expert?.pictureId)}
            alt="Эксперт проекта"
          />
          <div className="team__experts__expert-card__info">
            <div className="team__experts__expert-card__name">
              {`${expert?.firstName} ${expert?.lastName}`}
              {expert?.chiefExpert && (
                <Tooltip
                  text={"Главный эксперт"}
                  idx={"mainExpert" + expert?.id}
                  place="top"
                >
                  <div className="role-icon chief-expert-icon"></div>
                </Tooltip>
              )}
            </div>

            {showButton && (
              <FadeIn>
                <Button
                  text={"Задать вопрос"}
                  type={"filled"}
                  size={"m"}
                  onClick={() =>
                    typeof onSendMessageClick === "function" &&
                    onSendMessageClick(expert)
                  }
                />
              </FadeIn>
            )}

            <div
              className={`team__experts__expert-card__position ${
                showButton ? "invisible" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: expert?.position,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertCard;
