import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import "./Footer.scss";
import { ISocialLink } from "../../presentational/SocialNetworks/SocialNetwork.interface";
import SocialNetworks from "../../presentational/SocialNetworks/SocialNetworks";
import SupportForm from "../Forms/SupportForm/SupportForm";
import EventsTrackParentIdContext from "../EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackWrapperScroll from "../EventsTrack/wrappers/EventsTrackWrapperScroll";

const Footer = () => {
  const footerTextColor = useSelector((state: RootState) => state.layout.footerTextColor);
  const [modalOpened, setModalOpened] = useState(false);

  const networks: ISocialLink[] = [
    { class: "svg-icon svg-icon-vk", link: "https://vk.com/crowdmosru", type: "vkontakte" },
    // {
    //   class: "svg-icon svg-icon-facebook",
    //   link: "https://www.facebook.com/crowdmosru",
    // },
  ];

  return (
    <>
      {
        <EventsTrackParentIdContext.Provider value="FOOTER">
          <EventsTrackWrapperScroll id={["DOCSCROLL"]} needParent>
            <footer className={`footer footer-${footerTextColor}`}>
              <div className="footer__links">
                <div className="footer__info">
                  <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Помощь"], remember: true }}>
                    <span className="footer__link footer__link-help" onClick={() => setModalOpened(true)}>
                      Помощь
                      <span className="link-divider"></span>
                    </span>
                  </EventsTrackWrapperClick>
                  <EventsTrackWrapperClick id={["LINK", "CLICK"]} needParent params={{ replace: ["Пользовательское соглашение"] }}>
                    <Link className="footer__link footer__link-user-agreement" to="/license" target="_blank">
                      Пользовательское соглашение
                      <span className="link-divider"></span>
                    </Link>
                  </EventsTrackWrapperClick>
                  <EventsTrackWrapperClick
                    id={["LINK", "CLICK"]}
                    needParent
                    params={{ replace: ["Соглашение о пользовании информационными системами и ресурсами города Москвы"] }}
                  >
                    <a
                      className="footer__link footer__link-use-agreement"
                      href="https://www.mos.ru/legal/rules/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Соглашение о пользовании информационными системами и ресурсами города Москвы
                      <span className="link-divider"></span>
                    </a>
                  </EventsTrackWrapperClick>
                  <EventsTrackWrapperClick
                    id={["LINK", "CLICK"]}
                    needParent
                    params={{ replace: ["Политика в отношении обработки персональных данных"] }}
                  >
                    <a
                      className="footer__link footer__link-data-agreement"
                      href="https://www.mos.ru/dit/documents/informatcionnaia-bezopasnost/view/274898220/"
                      target="_blank"
                    >
                      Политика в отношении обработки персональных данных
                    </a>
                  </EventsTrackWrapperClick>

                  <div className="footer__copyright_mobile">{`© Краудсорсинг-проекты Правительства Москвы, ${new Date().getFullYear()}`}</div>
                </div>

                <div className="footer__social">
                  <SocialNetworks networks={networks} />
                </div>
              </div>

              <div className="footer__copyright">{`© Краудсорсинг-проекты Правительства Москвы, ${new Date().getFullYear()}`}</div>

              {modalOpened && <SupportForm isOpened={modalOpened} close={() => setModalOpened(false)} />}
            </footer>
          </EventsTrackWrapperScroll>
        </EventsTrackParentIdContext.Provider>
      }
    </>
  );
};

export default Footer;
