import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from "@crowd/ui-kit";

import { getNotifications } from "../../../../store/actions/NotificationsActions";
import ModuleCard from "../../../presentational/Cards/ModuleCard/ModuleCard";
import { moduleCards, notificationCountPerRequest } from "../Profile.constants";
import "./Menu.scss";
import PersonalService from "../../../../services/personalService";
import { mapResponseToCountData } from "./Menu.helper";
import Notifications from "./Notifications/Notifications";
import { RootState } from "../../../../types/State.interface";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import { IModuleCard, ModuleTypeEnum } from "../Profile.interface";
import { getUserMail } from "../../../../store/actions/MailActions";

const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const countAllUnread = useSelector((state: RootState) => state.notifications.countAllUnread);

  const [ideasCount, setIdeasCount] = useState(null);
  const [commentsCount, setCommentsCount] = useState(null);
  const [answersCount, setAnswersCount] = useState(null);
  const [mailCount, setMailCount] = useState(null);
  const [blogsCount, setBlogsCount] = useState(null);
  const [favoritesCount, setFavoriteCount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCounterData();
    dispatch(getNotifications(0, notificationCountPerRequest));
  }, []);

  function loadCounterData() {
    const reqs = [
      PersonalService.getProposalsCount(),
      PersonalService.getCommentsCount(),
      PersonalService.getAnswersCount(),
      PersonalService.getPostsCount(),
      PersonalService.getFavoriteCount(),
    ];
    Promise.all(reqs)
      .then(([ideas, comments, answers, blog, favorite]) => {
        setIdeasCount(mapResponseToCountData(ideas, "ideas"));
        setCommentsCount(mapResponseToCountData(comments, "comments"));
        setAnswersCount(mapResponseToCountData(answers, "answers"));
        setBlogsCount(mapResponseToCountData(blog, "blog"));
        setFavoriteCount(mapResponseToCountData(favorite, "favorite"));
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => setLoading(false));
  }

  const getCards = () => {
    let _moduleCards = [...moduleCards];

    let counters: any = {
      [ModuleTypeEnum.IDEAS]: ideasCount,
      [ModuleTypeEnum.COMMENTS]: commentsCount,
      [ModuleTypeEnum.ANSWERS]: answersCount,
      [ModuleTypeEnum.MAIL]: { newCount: countAllUnread?.unreadDialogMessageCount },
      [ModuleTypeEnum.BLOG]: blogsCount,
      [ModuleTypeEnum.FAVORITES]: favoritesCount,
    };

    if (!user.teamMember) {
      _moduleCards = _moduleCards.filter((c) => ![ModuleTypeEnum.REPORTS, ModuleTypeEnum.MANAGE].includes(c.type));
    }

    if (user.teamMember) {
      _moduleCards = _moduleCards.filter((c) => c.type !== ModuleTypeEnum.IDEAS);
    }

    return _moduleCards.map((card) => {
      return { ...card, ...counters[card.type] };
    });
  };

  const renderContent = () => {
    return (
      <>
        <div className="profile-menu__сards">
          {getCards().map((card, index: number) => (
            <div key={index} className="profile-menu__cwrap">
              <ModuleCard
                module={card}
                onClick={(card) => {
                  card.externalLink
                    ? (window.location.href = window.location.origin + card.externalLink)
                    : history.push("/profile/" + card.type.toLowerCase());
                }}
              />
            </div>
          ))}
        </div>

        <div className="profile-menu__notifications">
          <Notifications />
        </div>
      </>
    );
  };

  return <div className="profile-menu">{loading ? <Loader /> : renderContent()}</div>;
};

export default Menu;
