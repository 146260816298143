import React from "react";
import AttachmentView from "../AttachmentView";
import { Attachment } from "../../../../../../../../../types/Common.interface";
import "./PDFView.scss";

const DefaultPDFImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="33" viewBox="0 0 50 33">
    <style>{`.a { fill: #7e7e7e; font-size: 28px; font-family: 'PTSans-Bold', 'PT Sans'; font-weight: 700; }`}</style>
    <text className="a" transform="translate(25 25)">
      <tspan x="-24.178" y="0">
        PDF
      </tspan>
    </text>
  </svg>
);

export interface PDFViewProps {
  file: Attachment;
}

const PDFView = ({ file }: PDFViewProps) => {
  return (
    <AttachmentView file={file}>
      <div className="pdf-view">
        <div className="pdf-view__text">{file.fileName.split(".")[0]}</div>
        <DefaultPDFImage />
      </div>
    </AttachmentView>
  );
};

export default PDFView;
