import { Comment } from "../types/Comment.interface";
import { commentMock } from "../mocks/comment";
import { postMock } from "../mocks/post";
import { BlogPost } from "../types/BlogPost.interface";
import { User } from "../types/User.interface";
import { userMock } from "../mocks/user";

export class Adapter {
  static toComment(data: Partial<Comment>): Comment {
    return { ...commentMock, ...data };
  }
  static toPost(data: Partial<BlogPost>): BlogPost {
    return { ...postMock, ...data };
  }
  static toUser(data: Partial<User>): User {
    return { ...userMock, ...data };
  }
}
