import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

import "./ExtraNav.scss";

interface ExtraNavProps {
  title: string;
  posts: any[];
  moreLink?: string;
  onChange: (post) => void;
}

const greyLine = "1px solid #d7d9e0";

const ExtraNav = (props: ExtraNavProps) => {
  const renderContent = () => {
    if (!props.posts) return;

    return (
      <div className="extra-nav">
        <div className="extra-nav__title">{props.title}</div>

        {props.posts.map((post, idx) => (
          <div
            className="extra-nav__post"
            style={{ borderBottom: !props.moreLink && greyLine }}
            key={post?.id + idx}
            onClick={() => props.onChange(post)}
          >
            <div className="extra-nav__post-title">{post?.title}</div>
            <em className="extra-nav__post-time">
              {dayjs(post?.createdTimestamp).format("DD.MM.YYYY HH:mm")}
            </em>
          </div>
        ))}

        {props.moreLink && (
          <div className="extra-nav__more">
            <Link to={props.moreLink}>Посмотреть все</Link>
          </div>
        )}
      </div>
    );
  };

  return <>{renderContent()}</>;
};

export default ExtraNav;
