import React, { CSSProperties, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import StagesTapeCard from "../../../presentational/Cards/StagesTapeCard/StagesTapeCard";
import { useSelector } from "react-redux";

import { RootState } from "../../../../types/State.interface";
import { Stage, StageType } from "../../../../types/Stage.interface";
import "./StagesTape.scss";
import { StagesTapeItem } from "./StagesTape.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";

import "swiper/swiper-bundle.css";
import EventsTrackWrapperSwipe from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperSwipe";

SwiperCore.use([Pagination]);

const StagesTape = () => {
  const getInitialStages = (): StagesTapeItem => {
    let result: any = {
      GENERATION: { items: [] },
      EXPERT_SELECTION: { items: [] },
      SIMPLE_DISCUSSION: { items: [] },
      VOTING: { items: [] },
    };

    user?.expert || user?.chiefExpert
      ? delete result.GENERATION
      : delete result.EXPERT_SELECTION;

    return result;
  };

  const allStages = useSelector((state: RootState) => state.stage.allStages);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const [stages, setStages] = useState<StagesTapeItem>(getInitialStages());
  const [stagesLoaded, setStagesLoaded] = useState<boolean>(false);
  const [currentStageSlideIndex, setCurrentStageSlideIndex] =
    useState<number>(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  useEffect(() => _setStages(), [allStages, user?.id]);

  const _setStages = () => {
    const _stages: StagesTapeItem = getInitialStages();
    if (!stagesLoaded && allStages !== null) setStagesLoaded(true);
    allStages &&
      allStages.forEach((stage) => {
        switch (stage.type) {
          case "GENERATION":
            return _stages.GENERATION?.items.push(stage);
          case "EXPERT_SELECTION":
            return _stages.EXPERT_SELECTION?.items.push(stage);
          case "PROJECT_DISCUSSION":
          case "SIMPLE_DISCUSSION":
            return _stages.SIMPLE_DISCUSSION?.items.push(stage);
          case "VOTING":
            return _stages.VOTING?.items.push(stage);
        }
      });
    setStages(_stages);
  };

  const hasStageWithStatus = (stageItems: Stage[], status): boolean => {
    return stageItems.filter((stage) => stage.status === status).length > 0;
  };

  const fillStages = (
    conditionCallback: (items: Stage[]) => boolean
  ): StagesTapeItem => {
    const stagesResult = {};

    Object.keys(stages).forEach((stageKey) => {
      const items = stages[stageKey].items;
      if (conditionCallback(items)) {
        stagesResult[stageKey] = items;
      }
    });

    return stagesResult;
  };

  /*
   * Возвращает объект этапов с определенным порядком ключей (StageType):
   * 1. активные
   * 2. предстоящие
   * 3. завершенные
   */
  const getSortedStages = (): StagesTapeItem => {
    return {
      ...fillStages((items) => hasStageWithStatus(items, "STARTED")), // Активные
      ...fillStages(
        (items) =>
          hasStageWithStatus(items, "NEW") &&
          !hasStageWithStatus(items, "STARTED")
      ), // Предстоящие
      ...fillStages(
        (items) =>
          !hasStageWithStatus(items, "NEW") &&
          !hasStageWithStatus(items, "STARTED")
      ), // Завершенные
    };
  };

  const getSlideStyles = (index: number): CSSProperties => {
    return {
      pointerEvents:
        isMobile && index !== currentStageSlideIndex ? "none" : "auto",
    };
  };

  return (
    <>
      <div
        className="stages-tape"
        style={{ display: isMobile ? "none" : "flex" }}
      >
        {Object.keys(stages).map((key) => {
          return (
            <div className="stages-tape__item" key={key}>
              <StagesTapeCard
                type={key as StageType}
                stages={stages[key as StageType]?.items}
                stagesLoaded={stagesLoaded}
              />
            </div>
          );
        })}
      </div>

      <EventsTrackWrapperSwipe id={[]} needParent>
        <div
          className="stages-tape"
          style={{ display: isMobile ? "flex" : "none" }}
        >
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            loop={true}
            loopAdditionalSlides={1}
            width={260}
            pagination={{ el: ".swiper-pagination", type: "bullets" }}
            onSlideChange={() => {
              setCurrentStageSlideIndex(swiperInstance?.realIndex || 0);
            }}
            onSwiper={(instance) => {
              setSwiperInstance(instance);
            }}
          >
            {Object.keys(getSortedStages()).map((key, index) => {
              return (
                <SwiperSlide key={key}>
                  <div
                    className="stages-tape__item"
                    style={getSlideStyles(index)}
                  >
                    <StagesTapeCard
                      type={key as StageType}
                      stages={stages[key as StageType]?.items}
                      stagesLoaded={stagesLoaded}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </EventsTrackWrapperSwipe>
    </>
  );
};

export default StagesTape;
