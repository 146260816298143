import { useEffect, useRef, useState } from "react";
import { Button, AttachmentPreview } from "@crowd/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { CommentCardProps } from "./CommentCard.interface";
import UserAvatar from "../../UserAvatar/UserAvatar";
import { User } from "../../../../types/User.interface";
import { UserUnblockRequestData } from "../../../containers/Forms/UserBlockingForm/UserBlockingForm.interface";
import { debounce, declination, relativeTime, copyToClipboard, isElementWhollyInViewport, truncate } from "../../../../utils";
import PostAddFavorite from "../../PostAddFavorite/PostAddFavorite";
import CommentService, { VoteCommentsParams } from "../../../../services/commentService";
import { RootState } from "../../../../types/State.interface";
import {
  Comment,
  DiscussionPermissionsComment,
  DiscussionPermissionsCommentString,
  DiscussionTypeComment,
  PermissionsComment,
} from "../../../../types/Comment.interface";
import { BlogPost } from "../../../../types/BlogPost.interface";
import { showUserModal, showConfirmInfoModal } from "../../../../store/actions/LayoutActions";
import ComplaintModal from "../../../containers/Modals/ComplaintModal/ComplaintModal";
import { clearVotedUsers, commentUnVote, commentVote, deleteComment, restoreComment } from "../../../../store/actions/CommentActions";
import UserService from "../../../../services/userService";
import Tooltip from "../../Tooltip/Tooltip";
import AddCommentForm from "../../../containers/Forms/AddCommentForm/AddCommentForm";
import AnchorLink from "react-anchor-link-smooth-scroll";
import UserBlockingForm from "../../../containers/Forms/UserBlockingForm/UserBlockingForm";
import { ApiStatusCode } from "../../../../types/Common.interface";
import { showErrorToast, showSuccessToast } from "../../../../store/actions/ToastActions";
import { ERR_MSG } from "../../../../services/api";
import "./CommentCard.scss";
import { Link } from "react-router-dom";
import CommentLoader from "../../Loaders/CommentLoader/CommentsListLoader";
import { Sanitizer } from "../../../../services/sanitizer";
import linkifyHtml from "linkifyjs/html";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import { getDisplayName, renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";
import LikePopupBody from "./LikePopupBody/LikePopupBody";
import Popup from "../../Popup/Popup";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickButton from "../../../containers/EventsTrack/wrappers/helpers/EventsTrackClickButton";
const maxTextLength = 1500;

const CommentCard = ({
  readonly,
  blogPost,
  parentComment,
  comment,
  showFooter = true,
  showInfoIcon = true,
  background,
  commentInfoHintType = "DEFAULT",
  addComment = false,
  loadAnswers = false,
  canShowReplyButton = true,
  comments,
  onAddComment,
  onUpdateComment,
  onRemoveComment,
  showUserAvatar = true,
  showLabel = false,
  canModify = true,
  showAnswers = false,
  openForm = false,
  formNavigationLinkFn = null,
  onOutputAPI = () => {},
  onShowForm,
  authorType,
}: CommentCardProps) => {
  const dispatch = useDispatch();
  const commentRef = useRef(null);

  const [voted, setVoted] = useState<boolean>(false);
  const [voteUpStat, setVoteUpStat] = useState<number>(comment?.upVotes || 0);
  const [isCommentNew, setCommentNew] = useState<boolean>(comment?.new || null);
  const [isCopiedLink, setIsCopiedLink] = useState<boolean>(false);
  const [isOpenComplaintModal, setIsOpenComplaintModal] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [showAddCommentForm, setShowAddCommentForm] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [editedComment, setEditedComment] = useState<Comment>(null);
  const [answers, setAnswers] = useState<Comment[]>(null);
  const [showAllAnswers, setShowAllAnswers] = useState(showAnswers);
  const [loading, setLoading] = useState(false);

  const project = useSelector((state: RootState) => state.project.current);
  const votedUsers = useSelector((state: RootState) => state.comment.votedUsers);
  const votedUsersLoading = useSelector((state: RootState) => state.comment.isLoading);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isOwnComment = user?.id === comment?.author?.id;
  const authorTypePart = authorType === "participant" ? " участника" : authorType === "author" ? " автора" : "";

  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const LocalApi = {
    openForm: (state) => isCommentAllow(PermissionsComment.COMMENT_REPLY) && setShowAddCommentForm(state),
  };

  useEffect(() => {
    if (comment) {
      setVoted(!!comment.voted);
    }
  }, [comment]);

  useEffect(() => {
    if (showAnswers) {
      setShowAllAnswers(true);
    }
  }, [showAnswers]);

  useEffect(() => {
    setAnswers(comment?.responses || []);
  }, [comment?.responses]);

  useEffect(() => {
    if (isCommentNew) {
      subscribeResize();
    }

    return () => {
      unsubscribeResize();
    };
  }, []);

  const isExpert = () => {
    return user.expert || user.chiefExpert;
  };

  const isCommentAllow = (permission: PermissionsComment) => comment?.permissions?.includes(permission);
  const isDiscussionAllow = (permission: DiscussionPermissionsCommentString) => comment?.discussionPermissions?.includes(permission);
  const canVote = isCommentAllow(PermissionsComment.COMMENT_VOTE);

  const markCommentAsRead = (): void => {
    if (commentRef.current && isElementWhollyInViewport(commentRef.current)) {
      CommentService.markRead(comment.id, false).then(({ status }) => {
        if (ApiStatusCode.OK === status) {
          setCommentNew(false);
          unsubscribeResize();
        }
      });
    }
  };

  const debouncedMarkComment = debounce(markCommentAsRead, 200);

  const subscribeResize = (): void => {
    document.addEventListener("scroll", debouncedMarkComment);
    window.addEventListener("resize", debouncedMarkComment);
  };

  const unsubscribeResize = (): void => {
    document.removeEventListener("scroll", debouncedMarkComment);
    window.removeEventListener("resize", debouncedMarkComment);
  };

  const vote = (data: VoteCommentsParams) => {
    if (!canVote || voted) return;
    dispatch(
      commentVote(data, () => {
        setVoted(true);
        setVoteUpStat(voteUpStat + 1);
      })
    );
  };

  const unVote = (data: VoteCommentsParams) => {
    if (!canVote || !voted) return;
    dispatch(
      commentUnVote(data, () => {
        setVoted(false);
        setVoteUpStat(voteUpStat - 1);
      })
    );
  };

  const blockShow = (): boolean => {
    return comment.author.permissions.includes("USER_BLOCK");
  };

  const blockForeverShow = (): boolean => {
    return (
      comment.author.blocked &&
      (comment.author.permissions.includes("USER_BLOCK_PERMANENT") || comment.author.permissions.includes("USER_REQUEST_BLOCKING"))
    );
  };

  const unblockShow = (): boolean => {
    return comment.author.blocked && comment.author.permissions.includes("USER_UNBLOCK");
  };

  const topShow = () =>
    project?.hasLanding &&
    (user.projectBoss || user.facilitator || user.projectFacilitator || user.facilitatorIzrg) &&
    comment.discussionType === "BLOG_POST";

  const toggleTopComment = () => {
    dispatch(
      showConfirmInfoModal(
        comment.top
          ? "Комментарий снимется с публикации на лендинге как топ-мнение участников. Вы уверены?"
          : "Комментарий отобразится на лендинге как топ-мнение участников. Вы уверены?",
        () =>
          CommentService.setTop(comment.id, !comment.top).then(({ status, data }) => {
            if (status === ApiStatusCode.OK) {
              onUpdateComment(data);
            }
          })
      )
    );
  };

  const likeStatsShow = (): boolean => {
    return isCommentAllow(PermissionsComment.COMMENT_VIEW_VOTES) && voteUpStat > 0 && !isExpert();
  };

  const likeShow = (): boolean => user.loggedIn && isCommentAllow(PermissionsComment.COMMENT_VOTE);
  const finishedActionLikeShow = (): boolean =>
    user.loggedIn && !isCommentAllow(PermissionsComment.COMMENT_VOTE) && likeStatsShow() && !user?.teamMember && !isOwnComment;
  const likeNoVotedShow = (): boolean => (likeShow() && !voted) || finishedActionLikeShow();
  const likeVotedShow = (): boolean => likeShow() && voted;

  const unblockUser = () => {
    dispatch(showConfirmInfoModal("Вы действительно хотите разблокировать участника?", () => _unblockUser()));
  };

  const showError = (msg = ERR_MSG) => dispatch(showErrorToast(msg));

  const _unblockUser = () => {
    const formData = {
      type: "PLATFORM",
      userId: comment.author.id,
    } as UserUnblockRequestData;

    UserService.unblockUser(formData)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          onUpdateComment(comment);
          dispatch(showSuccessToast("Пользователь разблокирован."));
          return;
        }

        showError(response.message);
      })
      .catch((err) => showError(err.message));
  };

  const _showUserModal = (user) => {
    dispatch(showUserModal(user));
  };

  const copyLink = () => {
    setIsCopiedLink(true);
    !isCopiedLink && copyToClipboard(getItemUrl(comment));
  };

  const getItemUrl = (item: Comment | BlogPost | any, openForm?, postPage?) => {
    if (typeof formNavigationLinkFn === "function") {
      if (openForm) {
        return formNavigationLinkFn(item, true, true);
      }

      return formNavigationLinkFn(item, true);
    }

    if (item.stageId) {
      let link = `/discussion/${item.stageId}/${item.id}?navigate=true`;
      if (openForm) link += "&openForm=true";
      return link;
    }

    if (postPage) {
      return `/users/user/post/${blogPost.author?.id}/${blogPost.id}`;
    }

    return `/users/user/blog/${item.author?.id}`;
  };

  const editComment = () => {
    setShowAddCommentForm(true);
    setEditedComment(comment);
  };

  const removeComment = (
    permanent: boolean = false,
    removeText: string = "Вы действительно хотите удалить выбранный комментарий?",
    removeBranchText: string = "Вы уверены, что хотите удалить всю ветку комментариев?"
  ) => {
    const remove = (withChildren: boolean = false) =>
      dispatch(
        deleteComment(
          {
            id: comment.id,
            permanent,
            withChildren,
          },
          comment,
          () => onRemoveComment(comment)
        )
      );

    const askRemoveThread = () =>
      dispatch(
        showConfirmInfoModal(
          removeBranchText,
          () => remove(true),
          () => (permanent ? () => {} : remove(false))
        )
      );

    if (permanent) {
      comment.hasResponses ? askRemoveThread() : dispatch(showConfirmInfoModal(removeText, () => remove()));
      return;
    }

    dispatch(
      showConfirmInfoModal(removeText, () => {
        user.teamMember && comment.hasResponses ? askRemoveThread() : remove();
      })
    );
  };

  const getClassesForRoot = () => {
    let classes = "comment-card";
    if (readonly) classes += " comment-card--readonly";
    return classes;
  };

  const getCardHTML = () => {
    let text;
    if (blogPost?.rawContent) {
      text = CommentService.CkBBCodeToHtml(blogPost.rawContent);
      text = linkifyHtml(text, {
        target: "blank",
      });
    } else {
      text = CommentService.toHTMLQuote(comment?.rawText || "");
      text = Sanitizer.sanitizeQuote(text);
    }

    const author = comment?.author || blogPost.author;
    return author.teamMember ? text : truncate(text, maxTextLength);
  };

  const canShowReplyBtn = () => {
    if (!canShowReplyButton) return;
    if (showAddCommentForm) return;
    if (readonly) return;
    if (user.loggedIn && !isCommentAllow(PermissionsComment.COMMENT_REPLY)) {
      return;
    }

    return true;
  };

  const canShowFooter = () => {
    if (readonly) return;
    return true;
  };

  const canShowInfoIcon = () => {
    if (readonly) return;
    return true;
  };

  const canShowAnsers = () => {
    if (readonly) return;
    return true;
  };

  const togglePoppup = () => {
    isOpenPopup ? setIsOpenPopup(false) : setIsOpenPopup(true);
  };

  const closePopup = () => {
    isOpenPopup && setIsOpenPopup(false);
  };

  // RENDER

  const renderFooter = () => (
    <div
      ref={commentRef}
      className="comment-card__footer"
      style={{
        justifyContent: canModify || showLabel || blogPost ? "space-between" : "flex-end",
      }}
    >
      {showLabel && (
        <div className="comment-card__label">
          <span className={comment?.stageId ? "comment-card__label-discussion" : "comment-card__label-blog"}>
            {comment?.stageId ? "ОБСУЖДЕНИЯ" : "БЛОГИ"}
          </span>
        </div>
      )}

      {Boolean(blogPost) && (
        <EventsTrackClickButton
          type={isPhone ? "text-button" : "filled"}
          size={"m"}
          text={"Читать"}
          hrefTarget={"_self"}
          linkUrl={`/users/user/post/${blogPost.author?.id}/${blogPost.id}`}
          onClick={() => {}}
          needParent
        />
      )}

      {Boolean(comment) && (
        <>
          {canModify && (
            <div className="flex">
              {canShowReplyBtn() && (
                <div className="comment-card__answer-btn">
                  <EventsTrackClickButton
                    type={isPhone ? "text-button" : "filled"}
                    size={"m"}
                    text={"Ответить"}
                    linkUrl={addComment ? "" : getItemUrl(comment, true)}
                    hrefTarget={"_self"}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      addComment && ev.preventDefault();
                      onShowForm
                        ? onShowForm(comment, ev)
                        : isCommentAllow(PermissionsComment.COMMENT_REPLY) && addComment && setShowAddCommentForm(true);
                    }}
                    needParent
                    params={{
                      remember: true,
                    }}
                  />
                </div>
              )}

              <div className={"comment-card__user-icons"}>
                {isCommentAllow(PermissionsComment.COMMENT_EDIT) && addComment && (
                  <Tooltip text={"Редактировать"} idx={comment.id + "edit"} className={"comment-card__user-icon"}>
                    <i className="ui-icon ui-icon-edit" onClick={editComment} />
                  </Tooltip>
                )}

                {isCommentAllow(PermissionsComment.COMMENT_DELETE_PERMANENT) && (
                  <Tooltip text={"Удалить навсегда"} idx={comment.id + "delete-permanent"} className={"comment-card__user-icon"}>
                    <i
                      className="ui-icon ui-icon-delete-forever"
                      onClick={() =>
                        removeComment(
                          true,
                          "Вы действительно хотите навсегда удалить выбранный комментарий?",
                          "Вы уверены, что хотите навсегда удалить всю ветку комментариев?"
                        )
                      }
                    />
                  </Tooltip>
                )}

                {isCommentAllow(PermissionsComment.COMMENT_DELETE) && !!onRemoveComment && (
                  <Tooltip text={"Удалить"} idx={comment.id + "delete"} className={"comment-card__user-icon"}>
                    <i className="ui-icon ui-icon-delete" onClick={() => removeComment(false)} />
                  </Tooltip>
                )}

                {blockShow() && (
                  <div className="comment-card__stats-item comment-card__block-icon" onClick={() => setIsOpenBlockModal(true)}>
                    <Tooltip text={"Заблокировать пользователя"} idx={comment.id + "block"}>
                      <i className="ui-icon ui-icon-lock" />
                    </Tooltip>
                  </div>
                )}

                {blockForeverShow() && (
                  <div className="comment-card__stats-item comment-card__block-icon" onClick={() => setIsOpenBlockModal(true)}>
                    <Tooltip text={"Заблокировать навсегда"} idx={comment.id + "block-forever"}>
                      <i className="ui-icon ui-icon-lock" />
                    </Tooltip>
                  </div>
                )}

                {unblockShow() && (
                  <div className="comment-card__stats-item comment-card__block-icon" onClick={() => unblockUser()}>
                    <Tooltip text={"Разблокировать пользователя"} idx={comment.id + "unblock"}>
                      <i className="ui-icon ui-icon-unlock" />
                    </Tooltip>
                  </div>
                )}
                {topShow() && (
                  <Tooltip
                    text={comment.top ? "Снять топ-мнение" : "Отметить топ-мнение"}
                    idx={comment.id + "top"}
                    className={`comment-card__user-icon ${comment.top ? "comment-card__user-icon_selected" : ""}`}
                  >
                    <i
                      className={`ui-icon ${comment.top ? "ui-icon-top-comment-selected" : "ui-icon-top-comment"}`}
                      onClick={() => toggleTopComment()}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          <div className="comment-card__stats">
            <div className={`comment-card__stats-item ${!canVote ? "comment-card__stats-item--disabled" : ""}`}>
              {likeNoVotedShow() && (
                <EventsTrackWrapperClick id={["ICON", "CLICK"]} needParent params={{ replace: ["Like"] }}>
                  <i className="ui-icon ui-icon-like" onClick={() => vote({ id: comment?.id })} />
                </EventsTrackWrapperClick>
              )}
              {likeVotedShow() && (
                <EventsTrackWrapperClick id={["ICON", "CLICK"]} needParent params={{ replace: ["Like"] }}>
                  <i className="ui-icon ui-icon-like-fill" onClick={() => unVote({ id: comment?.id })} />
                </EventsTrackWrapperClick>
              )}
              {likeStatsShow() && (
                <div className="comment-card__popupWrapper">
                  <Popup
                    body={<LikePopupBody onClick={_showUserModal} onClose={closePopup} commentId={comment?.id} />}
                    isOpen={isOpenPopup}
                    className={`comment-card__popup ${votedUsers.length <= 6 && !votedUsersLoading ? "comment-card__offset" : ""}`}
                    onClose={closePopup}
                  >
                    <EventsTrackWrapperClick
                      id={["BUTTON", "CLICK"]}
                      needParent
                      params={{ replace: ["Количество лайков"], remember: true }}
                    >
                      <div
                        className="comment-card__stats comment-card__like"
                        onClick={() => {
                          dispatch(clearVotedUsers());
                          togglePoppup();
                        }}
                      >
                        {voteUpStat}
                      </div>
                    </EventsTrackWrapperClick>
                  </Popup>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const renderCommentInfoHint = () => {
    const shortType = relativeTime(comment?.date);
    const title = comment?.discussionTitle?.trim();
    const defaultType = (
      <>
        в {comment.stageId ? "обсуждении" : "блоге"}{" "}
        <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: [`${title} Обсуждай`] }}>
          <Link to={getItemUrl(comment as Comment)}>«{title}»</Link>
        </EventsTrackWrapperClick>
      </>
    );
    const fullType = (
      <>
        ответил(а) {shortType} {defaultType}
      </>
    );

    return (
      <>
        {commentInfoHintType === "SHORT" && shortType}
        {commentInfoHintType === "DEFAULT" && defaultType}
        {commentInfoHintType === "FULL" && fullType}
      </>
    );
  };

  const renderDeletedComment = () => {
    return (
      <>
        <div className={"comment-card__deleted"}>
          <div>{comment.deletedByAuthor ? "Комментарий удален автором" : "Комментарий удален"}</div>

          <div>
            {isCommentAllow(PermissionsComment.COMMENT_RESTORE) && (
              <Tooltip text={"Восстановить комментарий"} idx={comment.id + "edit"} className="pointer">
                <i
                  className="ui-icon ui-icon-restore"
                  onClick={() =>
                    dispatch(
                      showConfirmInfoModal("Вы уверены, что хотите восстановить комментарий?", () =>
                        dispatch(restoreComment(comment.id, onUpdateComment))
                      )
                    )
                  }
                />
              </Tooltip>
            )}
          </div>
        </div>

        {renderListAnswer()}
      </>
    );
  };

  const renderForWhomComment = () => {
    const commentParent = comments?.find((c) => c.id === comment.parentId) || parentComment;
    if (commentParent && commentParent.author) {
      return (
        <AnchorLink href={`#${commentParent.id}`} onClick={(e) => e.stopPropagation()} offset={250}>
          <span className="comment-card__replied-to">{` для ${commentParent.author.firstName} ${commentParent.author.lastName}`}</span>
        </AnchorLink>
      );
    }
    return "";
  };

  const renderCommentCard = () => (
    <>
      <div
        className="comment-card__wrapper"
        style={{
          background: isCommentNew ? "#DBF4FF" : background || "white",
        }}
      >
        <div className="comment-card__content">
          <div className="comment-card__info">
            <div className="comment-card__info-common">
              {showUserAvatar && (
                <EventsTrackWrapperClick
                  id={["BUTTON", "CLICK"]}
                  needParent
                  params={{ replace: [`Аватар${authorTypePart}`], remember: true }}
                >
                  <div className="comment-card__avatar" onClick={() => _showUserModal((blogPost || comment)?.author as User)}>
                    <UserAvatar user={(blogPost || comment)?.author as User} size={"M"} />
                  </div>
                </EventsTrackWrapperClick>
              )}
              <div>
                <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: [`ФИО${authorTypePart}`], remember: true }}>
                  <div className="comment-card__author-name-wrapper" onClick={() => _showUserModal((blogPost || comment)?.author as User)}>
                    <span className="comment-card__author-name">{getDisplayName(blogPost?.author || comment?.author)}</span>
                    {renderHatIfUserNameIsHidden(blogPost?.author || comment?.author, user)}
                    {!!comment && !!comments && renderForWhomComment()}
                  </div>
                </EventsTrackWrapperClick>
                <div className="comment-card__info-hint">
                  {Boolean(blogPost) && relativeTime(blogPost?.createdTimestamp)}
                  {Boolean(comment) && renderCommentInfoHint()}
                </div>
              </div>
            </div>
            {showInfoIcon && canShowInfoIcon() && (
              <div className="comment-card__info-icon">
                {!!comment && (
                  <Tooltip text={isCopiedLink ? "Ссылка скопирована" : "Скопировать ссылку"} idx={comment.id + "copy"}>
                    <EventsTrackWrapperClick id={["ICON", "CLICK"]} needParent params={{ replace: ["Скопировать ссылку"] }}>
                      <div className="ui-icon-link ui-icon" onClick={() => copyLink()} onMouseLeave={() => setIsCopiedLink(false)} />
                    </EventsTrackWrapperClick>
                  </Tooltip>
                )}
                {!!blogPost && blogPost.commentsEnabled && blogPost.active && !blogPost.hidden && <PostAddFavorite post={blogPost} />}
              </div>
            )}
          </div>
          <div className="comment-card__block">
            {Boolean(blogPost) && (
              <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: [blogPost?.title || ""] }}>
                <a href={getItemUrl(blogPost, false, true)} className="comment-card__title">
                  {blogPost?.title}
                </a>
              </EventsTrackWrapperClick>
            )}
            <div
              className="comment-card__text"
              dangerouslySetInnerHTML={{
                __html: getCardHTML(),
              }}
            />

            {comment?.attachments?.length > 0 && (
              <div className="comment-card__images scroll-x">
                {comment?.attachments.map((attachment) => (
                  <AttachmentPreview attachment={attachment} key={attachment.id} />
                ))}
              </div>
            )}

            {showFooter && canShowFooter() && renderFooter()}
          </div>

          {!!comment && isOpenComplaintModal && (
            <ComplaintModal type={"COMMENT"} comment={comment} onClose={() => setIsOpenComplaintModal(false)} />
          )}

          {!!comment && isOpenBlockModal && (
            <UserBlockingForm comment={comment} onClose={() => setIsOpenBlockModal(false)} onUpdateComment={onUpdateComment} />
          )}
        </div>
      </div>

      {addComment && !!comment && showAddCommentForm && (
        <AddCommentForm
          comment={editedComment}
          parentId={comment.id}
          discussionId={comment.discussionId}
          cancel={() => setShowAddCommentForm(false)}
          onAddComment={(comment) => {
            onAddComment(comment);
          }}
          onUpdateComment={(comment) => {
            onUpdateComment(comment);
          }}
        />
      )}

      {canShowAnsers() && renderListAnswer()}
    </>
  );

  const renderShowMoreAnswers = () => {
    const moreCount = answers.length - 1;
    const titles = ["ответ", "ответа", "ответов"];
    return (
      <div className="comment-card__more" onClick={() => setShowAllAnswers(!showAllAnswers)}>
        <i className={`ui-icon ${showAllAnswers ? "ui-icon-chevron-up" : "ui-icon-chevron-down"}`} />
        {`${showAllAnswers ? "Скрыть" : "Еще"} ${moreCount} ${declination(answers.length - 1, titles)}`}
      </div>
    );
  };

  const renderListAnswer = () => {
    return !!answers?.length ? (
      <div className="comment-card__answers">
        {(showAllAnswers ? answers : answers.slice(0, 1)).map((answer) => (
          <div className="comment-card__answers__answer" key={answer.id}>
            <CommentCard
              onOutputAPI={onOutputAPI}
              commentInfoHintType={"SHORT"}
              parentComment={comment}
              comment={answer}
              comments={answers}
              addComment={addComment}
              onAddComment={onAddComment}
              onUpdateComment={onUpdateComment}
              onRemoveComment={onRemoveComment}
              onShowForm={onShowForm}
              canModify={canModify}
              readonly={readonly}
              formNavigationLinkFn={formNavigationLinkFn}
            />
          </div>
        ))}
        {answers.length > 1 && renderShowMoreAnswers()}
      </div>
    ) : (
      <></>
    );
  };

  // TODO переделать лоадеры для кейсов, когда надо оставлять список всех комментариев в видимости, просто добавив/удалив/обновив конкретный
  // при временном размонтировании компонента CommentCard возникают проблемы к скроллу до только что оставленного ответа
  return (
    <div className={getClassesForRoot()} id={comment?.id} ref={(ref) => comment && onOutputAPI({ id: comment.id, api: LocalApi })}>
      {loading ? (
        <CommentLoader />
      ) : (
        <>{!!comment && (comment.deletedByAuthor || comment.deleted) ? renderDeletedComment() : renderCommentCard()}</>
      )}
    </div>
  );
};

export default CommentCard;
