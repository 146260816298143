import { Search } from "@crowd/ui-kit";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppColorsEnum } from "../../../App.interface";
import BlogService from "../../../services/blogService";
import {
  setAppColor,
  showFooter,
  showHeader,
} from "../../../store/actions/LayoutActions";
import { showErrorToast } from "../../../store/actions/ToastActions";
import { scrollTop } from "../../../utils";
import useQuery from "../../../utils/hooks/useQuery";
import CommentCard from "../../presentational/Cards/CommentCard/CommentCard";
import DatePicker from "../../presentational/Controls/DatePicker/DatePicker";
import { DatePickerType } from "../../presentational/Controls/DatePicker/DatePicker.interface";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import CommentListLoader from "../../presentational/Loaders/CommentListLoader/CommentListLoader";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import Pagination from "../../presentational/Pagination/Pagination";

import "./TeamBlogs.scss";

const TeamBlogs = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState(null);
  const [page, setPage] = useState(+query.page - 1 || 0);
  const [paging, setPaging] = useState(null);
  const [date, setDate] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.LIGHTBLUE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  useEffect(() => {
    if (page || page === 0) {
      const query = {
        page: page,
        ...(searchValue && { query: searchValue }),
        ...(date?.from && { from: dayjs(date.from).format("YYYY-MM-DD") }),
        ...(date?.to && { to: dayjs(date.to).format("YYYY-MM-DD") }),
        sort: "createdTimestamp,desc",
        type: "USER" as const,
      };

      setLoading(true);
      BlogService.getTeamPosts(query)
        .then((response) => {
          setPosts(response.data);
          setPaging(response.paging);
        })
        .catch((err) => dispatch(showErrorToast(err.message)))
        .finally(() => setLoading(false));
    }
  }, [page, searchValue, date]);

  const renderNoPosts = () => {
    return "Постов нет";
  };

  const renderBlogList = () => {
    if (!posts) return;
    if (!posts.length) return renderNoPosts();

    return (
      <div className="team-blogs__list">
        {posts.map((post) => {
          return <CommentCard key={post.id} blogPost={post} />;
        })}
      </div>
    );
  };

  const renderPagination = () => {
    return (
      <React.Fragment>
        {Boolean(paging?.totalPages) && paging?.totalPages > 1 && (
          <Pagination
            totalPages={paging?.totalPages || 0}
            onPageChange={(event) => {
              scrollTop();
              setPage(event.selected);
            }}
            currentPage={page}
            pageName="page"
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <section className="team-blogs">
      <div className="team-blogs__head">
        <PageTitle text="Личные блоги команды проекта" />

        <div className="team-blogs__head-controls">
          <DatePicker
            type={DatePickerType.RANGED}
            onChange={(date) => setDate(date)}
            date={date}
          />

          {/* <Search
            bindTo="title"
            value={searchValue}
            placeholder="Поиск участника"
            items={[]}
            onChange={(value) => {
              setPage(0);
              setSearchValue(value);
            }}
            isWithList={false}
            isRounded={true}
            isMultiplePerRow={true}
            isClearPlaceholderOnSelect={true}
            isCloseOnSelect={true}
            icon={
              <span className="ui-icon ui-icon-search participants__search-icon"></span>
            }
          /> */}
        </div>
      </div>

      {!loading ? <FadeIn>{renderBlogList()}</FadeIn> : <CommentListLoader />}

      {renderPagination()}
    </section>
  );
};

export default TeamBlogs;
