import React from "react";
import { Route } from "react-router-dom";

import SpeechPage from "../components/pages/Speech/Speech";
import TimetablePage from "../components/pages/Timetable/Timetable";
import DiscussionPage from "../components/pages/Discussion/Discussion";
import TeamPage from "../components/pages/Team/Team";

const indexedRoutes = [
  <Route exact path="/nodes" component={TimetablePage} key={"timetable"} />,
  <Route
    exact
    path="/discussion/:stageId/:commentId?"
    component={DiscussionPage}
    key={"discussion"}
  />,

  <Route exact path="/speech" component={SpeechPage} key={"speech"} />,
  <Route exact path="/team" component={TeamPage} key={"team"} />,
];

export default indexedRoutes;
