import React from "react";
import { Attachment } from "../../../../../../../../types/Common.interface";
import "./AttachmentView.scss";

export interface AttachmentViewProps {
  file: Attachment;
  children: React.ReactNode;
}

const AttachmentView = ({ file, children }: AttachmentViewProps) => {
  const title = `Открыть ${file.fileName}`;

  return (
    <div className="attachment-view">
      <a 
        href={`/uploads/get/${file.id}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={title}
        title={title}
        className="attachment-view__link"
      >
        {children}
      </a>
    </div>
  );
};

export default AttachmentView;