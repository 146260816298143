import { API, httpParamsSerialize } from "./api";

import { deleteCookie } from "../utils";
import { PagingParameters } from "../types/Common.interface";
import { IBaseResponse } from "../App.interface";
import { User } from "../types/User.interface";

export interface FindUserParams extends PagingParameters {
  onlineOnly?: boolean;
  query?: string;
  districtId?: string;
  type: "PARTICIPANT" | "TEAM" | "EXPERT" | "ALL";
}

export interface IPersonalCountStatistic {
  blogPosts: number;
  comments: number;
  proposals: number;
  userId: string;
}

const UserService = {
  login: (data) => {
    return API.post("/api/login", data).then(({ data }) => {
      return data;
    });
  },

  getUser: () => {
    return API.get("/api/users/current").then((data) => {
      return data?.data;
    });
  },

  blockUser: (data) => {
    return API.post("/api/users/block", data).then(({ data }) => {
      return data;
    });
  },

  requestToBlockUser: (data) => {
    return API.post("/api/users/blockRequest", data).then(({ data }) => {
      return data;
    });
  },

  unblockUser: (data) => {
    return API.post("/api/users/unblock", data).then(({ data }) => {
      return data;
    });
  },

  getExperts: () => {
    return API.get("/api/users/experts").then(({ data }) => {
      return data;
    });
  },

  getCoordinators: () => {
    return API.get("/api/users/coordinators").then(({ data }) => {
      return data;
    });
  },

  getFrontmen: () => {
    return API.get("/api/users/frontmen").then(({ data }) => {
      return data;
    });
  },

  getTopParticipants: () => {
    return API.get("/api/users/topParticipants")
      .then((data) => {
        return data?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  findUser: (params: FindUserParams) => {
    return API.get(`/api/users/find?${httpParamsSerialize(params)}`)
      .then((data) => {
        return data?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  updateUser: (query) => {
    return API.post("/api/profile/update", query).then(({ data }) => {
      return data;
    });
  },

  getPerson: (id): Promise<IBaseResponse<User>> => {
    const urlProfile = "/api/users/get?id=" + id;

    return API.get(urlProfile).then(({ data }) => {
      return data;
    });
  },

  addToProject: (userId, projectId) => {
    return API.post("/api/profile/addToProject", {
      userId: userId,
      projectId: projectId,
    }).then(({ data }) => {
      return data;
    });
  },

  uploadDefaultPicture: (id) => {
    return API.post("/api/userpicture/setDefault?index=" + id).then(({ data }) => {
      return data;
    });
  },

  uploadPicture: (params) => {
    const query = httpParamsSerialize(params.coords);
    const url = `/api/userpicture/upload?${query}`;
    const data = Uint8Array.from(atob(params.src.match(/,(.*)$/)[1]), function (c) {
      return c.charCodeAt(0);
    });
    const headers = {
      headers: { "Content-Type": params.type },
    };

    return API.post(url, data, headers).then(({ data }) => {
      return data;
    });
  },

  verifyEmail: (): Promise<IBaseResponse<any>> => {
    return API.get("/api/profile/resendEmailVerification")
      .then((response: any) => {
        return response.data;
      })
      .catch((err) => console.warn(err));
  },

  setNewPassword: (params) => {
    return API.post("/api/profile/newPassword", params).then((data) => {
      return data;
    });
  },

  changePassword: (params) => {
    return API.post("/api/personal/profile/updatePassword", params).then((data) => {
      return data;
    });
  },

  sendRestoreEmail: (params) => {
    return API.get("/api/profile/restorePassword", { params }).then((data) => {
      return data;
    });
  },

  restorePassword: (params) => {
    return API.post("/api/profile/restorePassword", params).then((data) => {
      return data;
    });
  },

  logout: function () {
    return window.location.href = '/logout';
  },
};

export default UserService;
