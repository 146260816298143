import React from "react";

import { Loader } from "@crowd/ui-kit";

interface ContentLoaderProps {
  children: React.ReactNode;
  isLoading: boolean;
}

const ContentLoader = ({ children, isLoading }: ContentLoaderProps) => {
  return <>{isLoading ? <Loader /> : children}</>;
};

export default ContentLoader;
