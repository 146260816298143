import React, { ChangeEvent, FC, useEffect, useLayoutEffect, useRef, useState } from "react";

import "./TextAreaEditor.scss";
import CommentService from "../../../../services/commentService";
import { declOfNum } from "../../../../utils";

export interface TextAreaEditorProps {
  maxLength: number;
  placeholder?: string;
  value: string;
  error?: string;
  onChange?: (value) => void;
  onClick?: (event) => void;
  onSetCurrentLink?: (link) => void;
  onSetSelection?: (selection) => void;
  showError?: boolean;
  isRequired?: boolean;
  mustContainLetters?: boolean;
  restrictForeignSymbByPercent?: null | number;
  restrictUppercaseSymbByPercent?: null | number;
  getRef?: (ref) => void;
  onInitValidator?: (validator) => void;
  beforeValidaton?: () => boolean;
  validationLeftCount?: () => boolean;
}

//Работа с компонентом ведется только через refs, так как при изменении стейта сбивается позиционирование каретки.
const TextAreaEditor: FC<TextAreaEditorProps> = ({
  placeholder = "",
  value = "",
  onChange = () => {},
  onClick = () => {},
  onSetCurrentLink = () => {},
  onSetSelection = () => {},
  getRef = () => {},
  onInitValidator = () => {},
  validationLeftCount = () => true,
  beforeValidaton = () => true,
  showError = false,
  isRequired = false,
  mustContainLetters = false,
  restrictForeignSymbByPercent = null,
  restrictUppercaseSymbByPercent = null,
  maxLength,
  error = "",
}) => {
  const [validator, setValidator] = useState(null);
  const [errorList, setErrorList] = useState<string[]>([]);
  const currentRef = useRef(null);

  const updateEditorValue = (val) => {
    //firefox fix
    const unBRed = val.replaceAll("<br>", "");
    onChange(unBRed);
  };

  const isValid = () => {
    const errors = [];

    if (isRequired && checkEmpty()) {
      errors.push("Поле не должно быть пустым");
    } else if (mustContainLetters && !checkMustContainLetters()) {
      errors.push("Текст поля должен содержать буквы");
    }
    if (maxLength && !checkMaxLength()) {
      errors.push(`Не более ${maxLength} ${declOfNum(maxLength, ["символа", "символов", "символов"])}`);
    }

    if (restrictForeignSymbByPercent && !checkRestrictForeignSymbByPercent()) {
      errors.push(`Не более ${restrictForeignSymbByPercent}% латинских букв от общего числа символов`);
    }
    if (restrictUppercaseSymbByPercent && !checkRestrictUppercaseSymbByPercent()) {
      errors.push(`Не более ${restrictUppercaseSymbByPercent}% заглавных букв от общего числа символов`);
    }
    setErrorList(errors);
    return !errors.length;
  };

  const checkEmpty = () => !currentRef.current?.textContent;

  const checkMaxLength = () => currentRef.current?.textContent.trim().length <= maxLength;

  const checkMustContainLetters = () => {
    const letterCount = (currentRef.current?.textContent.match(/[ЁёА-яA-z]/g) || []).length;
    return currentRef.current?.textContent.length === 0 || letterCount > 0;
  };

  const checkRestrictForeignSymbByPercent = () => {
    return !conditionIsAboveAllowedPercent(currentRef.current?.textContent, restrictForeignSymbByPercent, /[A-z]/g);
  };

  const checkRestrictUppercaseSymbByPercent = () => {
    return !conditionIsAboveAllowedPercent(currentRef.current?.textContent, restrictUppercaseSymbByPercent, /[ЁА-ЯA-Z]/g);
  };
  const conditionIsAboveAllowedPercent = (val, percent, regex) => {
    const conditionSymbolCount = (val.match(regex) || []).length;
    const trimmedValueLength = val.replace(/\s+/g, "").length;
    const conditionAboveAllowedPercent = (conditionSymbolCount / trimmedValueLength) * 100 > percent;

    return conditionAboveAllowedPercent;
  };

  const getQuoteLen = (): number => {
    return CommentService.getPlainCommentText(currentRef?.current?.innerHTML || "")?.length;
  };

  const onkeydown = (event) => {
    const selection = window.getSelection();
    if (event.keyCode === 8 && selection.anchorNode.parentNode instanceof HTMLAnchorElement && selection.isCollapsed) {
      event.preventDefault();
      selection.anchorNode.parentElement.remove();
      updateEditorValue(currentRef.current.innerHTML);
      onSetSelection(null);
    }
  };

  const onEditorClick = (ev) => {
    if (ev.target instanceof HTMLAnchorElement) {
      onSetCurrentLink(ev.target);
    }
    if (ev.target instanceof HTMLDivElement) {
      _setSelection();
    }
    onClick(ev);
  };

  const _setSelection = () => {
    const selection = window.getSelection();
    const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);
    const backward = (!position && selection.anchorOffset > selection.focusOffset) || position === Node.DOCUMENT_POSITION_PRECEDING;
    const select = {
      focusNode: selection.focusNode,
      focusOffset: selection.focusOffset,
      backward: backward,
      text: selection.toString(),
    };

    onSetSelection(select);
  };

  const canShowErrorTooltip = () => {
    return showError && Boolean(errorList?.length);
  };

  useEffect(() => {
    getRef(currentRef.current);
  }, [currentRef.current]);

  useEffect(() => {
    const validator = { isValid: isValid };
    setValidator(validator);
    onInitValidator(validator);
    isValid();
  }, [value]);

  const renderLeftCount = (): React.ReactNode => {
    const len = getQuoteLen();

    if (len === 0) {
      return <div className="app-editor__hint">Не более {maxLength} символов</div>;
    }

    if (len > maxLength) {
      return (
        <div className="cr-textarea__length">
          В поле слишком много символов. Превышение на {len - maxLength} {declOfNum(len, ["символ", "символа", "символов"])}.
        </div>
      );
    }

    return (
      <span className="cr-textarea__length">
        Осталось {maxLength - len} {declOfNum(maxLength - len, ["символ", "символа", "символов"])}.
      </span>
    );
  };

  return (
    <div className="textAreaEditor">
      <div
        onClick={onEditorClick}
        className="textAreaEditor__editable"
        ref={currentRef}
        contentEditable={true}
        placeholder={placeholder}
        onBeforeInput={(e) => {
          if (beforeValidaton() && getQuoteLen() >= maxLength) {
            e.preventDefault();
          }
        }}
        onPaste={(event) => {
          event.preventDefault();
          if (beforeValidaton() && getQuoteLen() >= maxLength) {
            return;
          }

          const text = event.clipboardData.getData("text/plain").replaceAll("\r", "");
          document.execCommand("insertText", false, text);
        }}
        onKeyDown={onkeydown}
        onInput={(event: ChangeEvent<HTMLInputElement>) => {
          const target: HTMLElement = event.nativeEvent.target as HTMLElement;
          updateEditorValue(target.innerHTML);
          _setSelection();
        }}
      ></div>

      {canShowErrorTooltip() && (
        <div className={"cr-textarea__tooltip"}>
          {errorList.map((err, key) => (
            <span key={key}>{err}</span>
          ))}
        </div>
      )}

      {error && <p className={"textAreaEditor__error "}>{error}</p>}

      {validationLeftCount() && renderLeftCount()}
    </div>
  );
};

export default TextAreaEditor;
