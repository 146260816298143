import { User } from "../../types/User.interface";
import { ButtonData } from "../../types/Common.interface";
import { AppColorsEnum } from "../../App.interface";
import { footerTextColorsMap } from "../../utils/constants/appColors";
import { Button } from "@crowd/ui-kit/build";
import React from "react";
import { debounce } from "../../utils";
import { SudirService } from "../../services/sudirService";

export const SHOW_INFOMODAL = "SHOW_INFOMODAL";
export const HIDE_INFOMODAL = "HIDE_INFOMODAL";
export const SHOW_MAINTENANCE_MODAL = "SHOW_MAINTENANCE_MODAL";
export const HIDE_MAINTENANCE_MODAL = "HIDE_MAINTENANCE_MODAL";
export const SHOW_MOSRU_AUTH_FORM = "SHOW_MOSRU_AUTH_FORM";
export const HIDE_MOSRU_AUTH_FORM = "HIDE_MOSRU_AUTH_FORM";
export const TOGGLE_DOCUMENT_BLOCKED = "TOGGLE_DOCUMENT_BLOCKED";
export const WIDEN_PAGE = "WIDEN_PAGE";
export const LESSEN_PAGE = "LESSEN_PAGE";
export const SHOW_USER_MODAL = "SHOW_USER_MODAL";
export const HIDE_USER_MODAL = "HIDE_USER_MODAL";
export const SET_APP_COLOR = "SET_APP_COLOR";
export const SHOW_HEADER = "SHOW_HEADER";
export const HIDE_HEADER = "HIDE_HEADER";
export const SHOW_FOOTER = "SHOW_FOOTER";
export const HIDE_FOOTER = "HIDE_FOOTER";
export const SHOW_HEADER_LINKS = "SHOW_HEADER_LINKS";
export const HIDE_HEADER_LINKS = "HIDE_HEADER_LINKS";
export const SHOW_CHAT = "SHOW_CHAT";
export const HIDE_CHAT = "HIDE_CHAT";
export const EVENTS_TRACK_SCROLL_UPDATE = "EVENTS_TRACK_SCROLL_UPDATE";

export const showInfoModal = (message, title?, jsx?, onDismiss?, classNames?) => (dispatch) => {
  return dispatch({
    type: SHOW_INFOMODAL,
    payload: { message, title, jsx, onDismiss, classNames },
  });
};

export const hideInfoModal = () => (dispatch) => {
  return dispatch({
    type: HIDE_INFOMODAL,
  });
};

export const showMaintenanceModal = () => (dispatch) => {
  return dispatch({
    type: SHOW_MAINTENANCE_MODAL,
  });
};

export const hideMaintenanceModal = () => (dispatch) => {
  return dispatch({
    type: HIDE_MAINTENANCE_MODAL,
  });
};

export const showConfirmInfoModal =
  (text: string, yes: ButtonData | (() => void), no?: ButtonData | (() => void), loading?: boolean, onDismiss?: () => void) =>
  (dispatch) => {
    dispatch(
      showInfoModal(
        text,
        null,
        <>
          <Button
            type="filled"
            size="m"
            text={("buttonText" in yes && yes.buttonText) || "Да"}
            isLoading={loading}
            onClick={() => {
              dispatch(hideInfoModal());
              "onClick" in yes ? yes.onClick() : typeof yes === "function" && yes();
            }}
          />

          <Button
            type="filled"
            size="m"
            text={(no && "buttonText" in no && no.buttonText) || "Нет"}
            onClick={() => {
              dispatch(hideInfoModal());
              no && "onClick" in no ? no.onClick() : typeof no === "function" && no();
            }}
          />
        </>,
        () => {
          onDismiss && onDismiss();
        }
      )
    );
  };

export const showOkInfoModal = (title, text: string, onClickOk?: () => void, onDismiss?: () => void, classNames?) => (dispatch) => {
  dispatch(
    showInfoModal(
      text,
      title,
      <Button
        type="outlined"
        size="m"
        text="Хорошо"
        onClick={() => {
          dispatch(hideInfoModal());
          onClickOk && onClickOk();
        }}
      />,
      () => {
        onDismiss && onDismiss();
      },
      classNames
    )
  );
};

export const showErrorInfoModal = (text, title?, onDismiss?: () => void) => (dispatch) => {
  dispatch(
    showInfoModal(
      text,
      title,
      <Button
        type="outlined"
        size="m"
        text="Закрыть"
        onClick={() => {
          dispatch(hideInfoModal());
          onDismiss && onDismiss();
        }}
      />,
      () => {
        onDismiss && onDismiss();
      }
    )
  );
};

// export const hideConfirmInfoModal = () => (dispatch) => {
//   dispatch(hideInfoModal());
// };

export const showMosruForm = (returnUrl?) => (dispatch) => {
  return dispatch({
    type: SHOW_MOSRU_AUTH_FORM,
    payload: typeof returnUrl !== "undefined" ? returnUrl : new SudirService().generateReturnUrlParameter(window.location.href),
  });
};

export const hideMosruForm = () => (dispatch) => {
  return dispatch({
    type: HIDE_MOSRU_AUTH_FORM,
  });
};

export const showUserModal = (user: User) => (dispatch) => {
  return dispatch({
    type: SHOW_USER_MODAL,
    payload: user,
  });
};

export const hideUserModal = () => (dispatch) => {
  return dispatch({
    type: HIDE_USER_MODAL,
  });
};

export const documentIsBlocked = (value) => (dispatch) => {
  return dispatch({
    type: TOGGLE_DOCUMENT_BLOCKED,
    payload: value,
  });
};

export const widenPage = () => (dispatch) => {
  return dispatch({
    type: WIDEN_PAGE,
  });
};

export const lessenPage = () => (dispatch) => {
  return dispatch({
    type: LESSEN_PAGE,
  });
};

export const setAppColor = (appColor: AppColorsEnum) => (dispatch) => {
  return dispatch({
    type: SET_APP_COLOR,
    payload: {
      appColor,
      footerTextColor: footerTextColorsMap[appColor],
    },
  });
};

export const showHeader = () => (dispatch) => {
  return dispatch({
    type: SHOW_HEADER,
  });
};

export const hideHeader = () => (dispatch) => {
  return dispatch({
    type: HIDE_HEADER,
  });
};

export const showFooter = () => (dispatch) => {
  return dispatch({
    type: SHOW_FOOTER,
  });
};

export const hideFooter = () => (dispatch) => {
  return dispatch({
    type: HIDE_FOOTER,
  });
};

export const hideHeaderLinks = () => (dispatch) => {
  return dispatch({
    type: HIDE_HEADER_LINKS,
  });
};

export const showHeaderLinks = () => (dispatch) => {
  return dispatch({
    type: SHOW_HEADER_LINKS,
  });
};

export const hideChat = () => (dispatch) => {
  return dispatch({
    type: HIDE_CHAT,
  });
};

export const showChat = () => (dispatch) => {
  return dispatch({
    type: SHOW_CHAT,
  });
};

const eventsTrackScrollUpdateDebounce = debounce((dispatch) => {
  dispatch({
    type: EVENTS_TRACK_SCROLL_UPDATE,
  });
}, 1000);
export const eventsTrackScrollUpdate = () => eventsTrackScrollUpdateDebounce;
