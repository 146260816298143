import React from "react";
import "react-day-picker/lib/style.css";
import { DatePickerProps, DatePickerType } from "./DatePicker.interface";
import "./DatePicker.scss";
import { DatePickerBase } from "./pickers/PickerBase";
import PlainDatePicker from "./pickers/PlainPicker/PlainPicker";
import RangedDatePicker from "./pickers/RangedPicker/RangedPicker";

export class DatePicker extends React.Component<DatePickerProps, any> {
  private picker: DatePickerBase;
  ref;

  constructor(props) {
    super(props);

    this.picker = this.create(props.type);
    this.ref = React.createRef();
  }

  create(type) {
    switch (type) {
      case DatePickerType.PLAIN:
        return new PlainDatePicker(this);
      case DatePickerType.RANGED:
        return new RangedDatePicker(this);
      default:
        return new PlainDatePicker(this);
    }
  }

  getPositionClass() {
    if (this.ref?.current) {
      const { left, right } = this.ref.current.getBoundingClientRect();
      return left > window.innerWidth / 2
        ? "cr-datepicker__position--left"
        : "cr-datepicker__position--right";
    }

    return "";
  }

  getClasses() {
    let classes = "cr-datepicker";
    classes += ` ${this.picker.class}`;
    classes += ` ${this.getPositionClass()}`;
    return classes;
  }

  render() {
    return (
      <div className={this.getClasses()} ref={this.ref}>
        {this.picker.render()}
      </div>
    );
  }
}

export default DatePicker;
