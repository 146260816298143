import React, { useEffect, useState } from "react";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import UserAvatar from "../../presentational/UserAvatar/UserAvatar";

import "./Speech.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import {
  setAppColor,
  showFooter,
  showHeader,
} from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";

const SpeechPage = () => {
  const dispatch = useDispatch();
  const customer = useSelector(
    (state: RootState) => state.project.current?.speech
  );

  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.LIGHTBLUE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  return (
    <section className={"speech"}>
      <PageTitle text={"Обращение к участникам"} />

      <div className="speech__wrapper">
        <div className="speech__customer">
          <UserAvatar size={"XXL"} pictureUrl={customer?.photo} />
          <div className="speech__customer_text">
            <div className="speech__customer_text-name">{customer?.name}</div>
            <div className="speech__customer_text-position">
              {customer?.position}
            </div>
          </div>
          {/*
             РЕАЛИЗАЦИЯ ПОД ВОПРОСОМ
          */}

          {/*{openInfo && (*/}
          {/*  <div className="speech__customer-info">*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*<i*/}
          {/*  className={`ui-icon ui-icon-chevron-down speech__customer-icon ${*/}
          {/*    openInfo ? "speech__customer-icon_revert" : ""*/}
          {/*  }`}*/}
          {/*  onClick={(e) => setOpenInfo(!openInfo)}*/}
          {/*  title={"Дополнительная информация"}*/}
          {/*/>*/}
        </div>
        <div
          className="speech__content"
          dangerouslySetInnerHTML={{ __html: customer?.content || "" }}
        />
      </div>
    </section>
  );
};

export default SpeechPage;
