import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { hideMosruForm } from "../../../../store/actions/LayoutActions";
import { RootState } from "../../../../types/State.interface";
import "./AuthModal.scss";
import { Button, Modal } from "@crowd/ui-kit";
import { store } from "../../../../store/store";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import EventsTrackClickButton from "../../EventsTrack/wrappers/helpers/EventsTrackClickButton";
import EventsTrackModal from "../../EventsTrack/wrappers/helpers/EventsTrackModal";

type AuthFormProps = {
  edp?: string;
};

const AuthModal = ({ edp = "/oauth2/authorization/sudir" }: AuthFormProps) => {
  const returnUrl = useSelector((state: RootState) => state.layout.returnUrl);
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state: RootState) => state.layout.showMosRuAuthForm
  );
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  useEffect(() => {
    if (isOpen && isMobile) {
      setTimeout(() => {
        const modalEl: HTMLDivElement = document.querySelector(
          ".react-responsive-modal-container"
        );
        if (modalEl) {
          modalEl.style.height = "initial";
          modalEl.style.backgroundColor = "#fff";
        }
      });
    }
  }, [isOpen, isMobile]);

  const login = (e) => {
    e.preventDefault();
    window.location.href = window.location.origin + edp + (returnUrl ? `?${returnUrl}` : "");
  };

  const signUp = (e) => {
    e.preventDefault();
    window.location.href = window.location.origin + edp + (returnUrl ? `?${returnUrl}` : "") + (returnUrl ? "&reg=true" : "?reg=true");
  };

  const authTxt = "Войти через mos.ru";
  const regTxt = "Регистрация";
  return (
    <EventsTrackModal
      isOpen={isOpen}
      onClose={() => dispatch(hideMosruForm())}
      classNames="auth-modal"
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <React.Fragment key="body">
        <EventsTrackClickButton
          type="outlined"
          size="m"
          text={authTxt}
          onClick={login}
          icon={<div className="mos-ru-icon"></div>}
          classNames={"mos-ru"}
          params={{
            replace: [authTxt],
            rememberPrev: true,
            partial: true,
          }}
        />
        <EventsTrackClickButton
          type="outlined"
          size="m"
          text={regTxt}
          onClick={signUp}
          params={{
            replace: [regTxt],
            partial: true,
          }}
        />
      </React.Fragment>
    </EventsTrackModal>
  );
};

export default AuthModal;
