import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import { ChatMessage, ChatMessageRaw, ChatMessageStatus, MessageGroup } from "./chat.types";

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.locale("ru");

export function groupMessagesByDate(messages: ChatMessage[]): MessageGroup[] {
  const grouped: Record<string, { label: string; messages: ChatMessage[] }> = {};

  messages.forEach((message) => {
    const messageDate = dayjs(message.created);
    const formattedDate = messageDate.format("DD.MM.YYYY");
    let dateLabel = messageDate.format("D MMMM");

    if (!grouped[formattedDate]) {
      grouped[formattedDate] = { label: dateLabel, messages: [] };
    }

    grouped[formattedDate].messages.push(message);
  });

  return Object.keys(grouped).map((date) => ({
    date,
    label: grouped[date].label,
    messages: grouped[date].messages,
  }));
}

export function chatMessageRawToChatMessage(messageRaw: ChatMessageRaw, currentUser, peer) {
  const { recipientId, senderId, ...rest } = messageRaw as ChatMessageRaw;
  const chatMessage: ChatMessage = {
    ...rest,
    sender: senderId === currentUser.id ? currentUser : peer,
    recipient: recipientId === currentUser.id ? currentUser : peer
  };
  return chatMessage;
}

export function getTitleByStatus(status, text) {
  if (status === ChatMessageStatus.DELETED_BY_ME) {
    return "Вы удалили данное сообщение";
  }
  if (status === ChatMessageStatus.DELETED_BY_PEER) {
    return "Сообщение удалено отправителем";
  }
  return text;
}
