import { API, httpParamsSerialize } from "./api";
import { IPagingParams } from "../types/Common.interface";
import { BlogPost } from "../types/BlogPost.interface";
import { IBaseListResponse, IBaseResponse } from "../App.interface";

export interface BlogParams {
  attachments: string[];
  blogId: string;
  commentsEnabled?: boolean;
  content: string;
  date: string;
  draft?: boolean;
  title: string;
}

export interface UpdateBlogPostParams extends BlogParams {
  postId: string;
}

export interface AddBlogpostSummaryParams {
  content: string;
  postId: string;
  title: string;
}

export interface GetTeamPostsParams {
  from?: string;
  to?: string;
  page?: number;
  query?: string;
  sort?: string;
  size?: number;
  type?: "USER" | "PROJECT" | "PROJECT_DIARY" | "STAGE";
}

export interface GetAllPersonalPostsParams {
  includeTeamMemberPosts?: boolean;
  onlyDisplayMain?: boolean;
  page?: number;
  size?: number;
  sort?: string[];
}

const baseBlogUrl = "/api/blogs/blog";
const basePostUrl = "/api/blogs/post";
const basePostsUrl = "/api/blogs/posts";

const BlogService = {
  getBlog: (id: string) => {
    return API.get(`${baseBlogUrl}/get?id=${id}`).then(({ data }) => {
      return data;
    });
  },
  getBlogPostsByUserId: (userId: string, paging: IPagingParams): Promise<IBaseListResponse<BlogPost>> => {
    const params = httpParamsSerialize({ userId, ...paging });
    return API.get(`${basePostsUrl}/user?${params}`).then(({ data }) => {
      return data;
    });
  },
  getBlogPost: (id: string): Promise<IBaseResponse<BlogPost>> => {
    return API.get(`${basePostUrl}/get?id=${id}`).then(({ data }) => {
      return data;
    });
  },
  getAllPersonalPosts: (params?: GetAllPersonalPostsParams) => {
    // Получение всех персональных постов пользователей.
    let url = `${basePostUrl}/getAll?${httpParamsSerialize(params)}`;
    return API.get(url).then((data) => {
      return data?.data;
    });
  },
  getMine: () => {
    let url = `${baseBlogUrl}/getMine`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },
  getByUser: (userId: string) => {
    return API.get(`${baseBlogUrl}/getByUser?userId=${userId}`).then(({ data }) => {
      return data.data;
    });
  },
  getByBlogId: (blogId: string, sort?) => {
    let query = `${basePostUrl}/byBlogId?blogId=${blogId}`;
    if (sort) {
      query += `&sort=${sort}`;
    }
    return API.get(query).then(({ data }) => {
      return data;
    });
  },

  getAllByBlogId: (blogId: string, sort?, showHidden = true) => {
    let query = `${basePostUrl}/allByBlogId?blogId=${blogId}&showHidden=${showHidden}`;
    if (sort) {
      query += `&sort=${sort}`;
    }
    return API.get(query).then(({ data }) => {
      return data;
    });
  },

  getTeamPosts: (params?: GetTeamPostsParams) => {
    const _query = httpParamsSerialize(params);
    return API.get(`/api/blogs/getTeamPosts?${_query}`).then(({ data }) => {
      return data;
    });
  },

  deleteBlogPost: (id: string) => {
    return API.post(`${basePostUrl}/delete`, { id }).then(({ data }) => {
      return data;
    });
  },
  updateBlogPost: (data: UpdateBlogPostParams) => {
    return API.post(`${basePostUrl}/update`, data).then(({ data }) => {
      return data;
    });
  },

  add: (data: BlogParams) => {
    return API.post(`${basePostUrl}/add`, data).then(({ data }) => {
      return data;
    });
  },

  changePostVisibility: (postId: string, operation: "hide" | "show"): Promise<{ message: string; status: number }> => {
    return API.post(`${basePostUrl}/${operation}`, { id: postId }).then(({ data }) => {
      return data;
    });
  },

  changePostActivity: (postId: string, operation: "stopActivity" | "resumeActivity"): Promise<{ message: string; status: number }> => {
    return API.post(`${basePostUrl}/${operation}`, { id: postId }).then(({ data }) => {
      return data;
    });
  },

  addBlogpostSummary: (data: AddBlogpostSummaryParams) => {
    return API.post(`${basePostUrl}/summary/add`, data).then(({ data }) => {
      return data;
    });
  },
  updateBlogpostSummary: (data: AddBlogpostSummaryParams) => {
    return API.post(`${basePostUrl}/summary/update`, data).then(({ data }) => {
      return data;
    });
  },
  approveBlogpostSummary: (id: string) => {
    return API.post(`${basePostUrl}/summary/approve`, { id }).then(({ data }) => {
      return data;
    });
  },

  setDisplayOnMain: (postId: string, displayMain: boolean) => {
    return API.post(`${basePostUrl}/setDisplayMain`, { postId, displayMain }).then(({ data }) => {
      return data;
    });
  },
};

export default BlogService;
