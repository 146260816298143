// @flow

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Button, Textarea } from "@crowd/ui-kit";

import UserService from "../../../../services/userService";
import { RootState } from "../../../../types/State.interface";
import { ApiStatusCode } from "../../../../types/Common.interface";
import SubmitForm from "../../../presentational/Forms/SubmitForm/SubmitForm";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import { UserBlockingFormProps, UserBlockingRequestData, ProposeUserBlockingRequestData } from "./UserBlockingForm.interface";
import "./UserBlockingForm.scss";
import { showErrorToast, showSuccessToast } from "../../../../store/actions/ToastActions";
import { showConfirmInfoModal } from "../../../../store/actions/LayoutActions";

const UserBlockingForm = (props: UserBlockingFormProps) => {
  const dispatch = useDispatch();
  const projectBoss = useSelector((state: RootState) => state.user.userDetails.projectBoss);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [reason, setReason] = useState("");
  const [blockingType, setBlockingType] = useState(projectBoss ? "forever" : "request-forever");
  const [blockingPlace, setBlockingPlace] = useState("PLATFORM");

  const blockUser = (formData) => {
    UserService.blockUser(formData)
      .then((response) => {
        if (response.status === ApiStatusCode.OK) {
          props.onUpdateComment && props.onUpdateComment(props.comment);
          dispatch(showSuccessToast("Пользователь заблокирован."));
          props.onClose();
          return;
        }

        if (response.status === ApiStatusCode.USER_ALREADY_BLOCKED) {
          dispatch(showErrorToast("Пользователь уже заблокирован."));
          return;
        }

        dispatch(showErrorToast(response.message));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submit = () => {
    setSubmitted(true);
    if (!blockingType || !blockingPlace || !reason.length) return;
    setIsSubmitting(true);

    const formData = {
      commentId: props.comment.id,
      reason: reason,
      type: blockingPlace,
      userId: props.comment.author.id,
    } as UserBlockingRequestData;

    if (blockingType === "temporary") {
      const currentDate = new Date();
      formData.expireDate = currentDate.setDate(currentDate.getDate() + 1);
      blockUser(formData);
    } else {
      if (projectBoss) {
        formData.expireDate = null;

        dispatch(
          showConfirmInfoModal(
            "Пользователь будет заблокирован навсегда.",
            {
              buttonText: "Продолжить",
              onClick: () => blockUser(formData),
            },
            { buttonText: "Отмена", onClick: () => setIsSubmitting(false) },
            false,
            () => setIsSubmitting(false)
          )
        );
      } else if (blockingType === "request-forever") {
        const formData = {
          commentId: props.comment.id,
          reason: reason,
          type: blockingPlace,
          userId: props.comment.author.id,
        } as ProposeUserBlockingRequestData;

        UserService.requestToBlockUser(formData)
          .then((response) => {
            if (response.status === ApiStatusCode.OK) {
              dispatch(showSuccessToast("Запрос на постоянную блокировку пользователя отправлен."));
              props.onClose();
            }

            if (response.status === ApiStatusCode.USER_ALREADY_BLOCKED) {
              dispatch(showErrorToast("Пользователь уже заблокирован."));
              return;
            }
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    }
  };

  return (
    <SubmitForm
      classNames="user-blocking-form"
      submitText={"Заблокировать"}
      isOpen={true}
      onClose={() => props.onClose()}
      isSubmitting={isSubmitting}
      onSubmit={submit}
    >
      <React.Fragment key="header">Блокировка участника</React.Fragment>

      <React.Fragment key="body">
        <div className="user-blocking-form__comment">
          <CommentCard
            readonly={true}
            comment={props.comment}
            showFooter={false}
            showInfoIcon={false}
            commentInfoHintType={"SHORT"}
            background={"#F2F4F7"}
          />
        </div>
        <div className="user-blocking-form__options">
          <div className="user-blocking-form__options__type">
            <div className="user-blocking-form__options__radio-group-label">Тип блокировки</div>
            <Radio
              onChange={() => setBlockingType("temporary")}
              id="temporary"
              isSelected={blockingType === "temporary"}
              value="temporary"
              label="Временно"
              isInvalid={(submitted && !blockingType) || false}
              isDisabled={props.comment.author.blocked}
            />
            {projectBoss ? (
              <Radio
                onChange={() => setBlockingType("forever")}
                id="forever"
                isSelected={blockingType === "forever"}
                value="forever"
                label="Постоянно"
                isInvalid={(submitted && !blockingType) || false}
              />
            ) : (
              <Radio
                onChange={() => setBlockingType("request-forever")}
                id="request-forever"
                isSelected={blockingType === "request-forever"}
                value="request-forever"
                label="Запрос на постоянную блокировку"
                isInvalid={(submitted && !blockingType) || false}
              />
            )}
          </div>
          <div className="user-blocking-form__options__place">
            <div className="user-blocking-form__options__radio-group-label">Место блокировки</div>
            <Radio
              onChange={() => setBlockingPlace("PROMO")}
              id="promo"
              isSelected={blockingPlace === "PROMO"}
              value="MALE"
              label="Промо"
              isInvalid={(submitted && !blockingPlace) || false}
            />

            <Radio
              onChange={() => setBlockingPlace("PLATFORM")}
              id="platform"
              isSelected={blockingPlace === "PLATFORM"}
              value="PLATFORM"
              label="Проект"
              isInvalid={(submitted && !blockingPlace) || false}
            />
          </div>
        </div>

        <div className="user-blocking-form__reason">
          <Textarea
            value={reason}
            onChange={setReason}
            placeholder={"Причина блокировки"}
            hint={"Не более 1500 символов"}
            maxLength={1500}
            showError={submitted}
            isRequired={true}
            shouldValidate={true}
            validateRules={{
              notEmpty: true,
              minLength: 1,
              maxLength: 1500,
            }}
          />
        </div>
      </React.Fragment>

      <React.Fragment key="footer">
        <Button type={"outlined"} text="Отменить" size="m" onClick={props.onClose} />
      </React.Fragment>
    </SubmitForm>
  );
};

export default UserBlockingForm;
