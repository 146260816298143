import React, { useContext } from "react";
import { Button, Modal } from "@crowd/ui-kit";
import { Link, useHistory } from "react-router-dom";

import { StageTypes } from "../../../../types/Stage.interface";
import { calculateRemainedTime } from "../../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";

import "./FragmentModal.scss";
import CommentService from "../../../../services/commentService";
import dayjs from "dayjs";

const FragmentModal = (props) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const getStage = (): string => {
    switch (props.fragment.stage.type) {
      case "EXPERT_SELECTION":
        return "selection";
      case "GENERATION":
        return "generation";
      case "SIMPLE_DISCUSSION":
      case "PROJECT_DISCUSSION":
        return "discussion";
      case "VOTING":
        return "voting";
      default:
        return "";
    }
  };

  const getLink = () => {
    return `/${getStage()}/${props.fragment.stage.id}`;
  };

  const getTimeString = () => {
    if (dayjs().valueOf() > dayjs(props.fragment.stage.finish).valueOf()) {
      return "Этап завершен";
    }

    const [_time, _unit] = calculateRemainedTime(props.fragment.stage);
    return `${_time} ${_unit} до ${
      dayjs().valueOf() > dayjs(props.fragment.stage.start).valueOf() ? "завершения" : "старта"
    }`;
  };

  const isExpert = () => {
    return user.expert || user.chiefExpert;
  };

  const participate = () => {
    const url = getLink();

    if (!user.loggedIn) {
      const data: AuthActionParam = {
        component: ComponentType.StageList,
        type: AuthActionType.Redirect,
        args: {
          redirectUrl: url,
        },
      };

      const redirectToStage = new AuthAction(data);
      return appContext.sudirService.authWithActions([redirectToStage], true);
    }

    history.push(url);
  };

  const getName = () => {
    const plain = <React.Fragment>{props.fragment.stage.title}</React.Fragment>;

    if (props.fragment.stage.status === "NEW") {
      return plain;
    }

    if (props.fragment.stage.type === StageTypes.EXPERT_SELECTION) {
      if (isExpert()) {
        return (
          <a
            href={
              window.location.origin +
              `/selection/incoming?stageId=${props.fragment.stage.id}`
            }
          >
            {props.fragment.stage.title}
          </a>
        );
      }

      return plain;
    }

    return <Link to={getLink()}>{props.fragment.stage.title}</Link>;
  };

  const getHeader = () => {
    const { stage, title } = props.fragment;

    if (stage.type === StageTypes.SIMPLE_DISCUSSION)
      return "Тематическое обсуждение";

    return title;
  };

  // RENDER

  const closeButton = () => {
    return (
      <Button
        type="outlined"
        text="Закрыть"
        size="m"
        onClick={props.onClose}
      ></Button>
    );
  };

  const expertButton = () => {
    if (!isExpert()) return;
    if (props.fragment.stage.type !== StageTypes.EXPERT_SELECTION) return;

    return (
      <Button
        type="filled"
        text="Перейти"
        size="m"
        linkUrl={`/select/incoming/${props.fragment.stage.id}`}
        hrefTarget="_self"
      ></Button>
    );
  };

  const participateButton = () => {
    if (
      props.fragment.stage.status === "FINISHED" ||
      props.fragment.stage.status === "NEW"
    )
      return;
    if (props.fragment.stage.type === StageTypes.EXPERT_SELECTION) return;
    if (isExpert()) return;

    return (
      <Button
        type="filled"
        text="Участвовать"
        size="m"
        onClick={participate}
      ></Button>
    );
  };

  return (
    <Modal isOpen={true} onClose={props.onClose} classNames="fragment-modal">
      <React.Fragment key="header">{getHeader()}</React.Fragment>

      <React.Fragment key="body">
        <h4>{getName()}</h4>

        <div>
          <em>{getTimeString()}</em>
        </div>
        <div className="editor-content">
          <p
            dangerouslySetInnerHTML={{
              __html:
                props.fragment.stage.type === "GENERATION"
                  ? props.fragment.stage.shortStructureDescription
                  : CommentService.CkBBCodeToHtml(
                      props.fragment.stage.description
                    ),
            }}
          ></p>
        </div>
      </React.Fragment>

      <React.Fragment key="footer">
        {closeButton()}
        {expertButton()}
        {participateButton()}
      </React.Fragment>
    </Modal>
  );
};

export default FragmentModal;
