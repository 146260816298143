import { ChatMessageRaw, ChatMessageStatus } from "../../components/pages/Profile/Conversations/Components/Chat/chat.types";
import { ON_EMPTY_CHAT_CACHE, ON_HISTORY_CHAT_MESSAGES, ON_NEW_CHAT_MESSAGE, ON_READ_CHAT_MESSAGES, ON_REMOVE_CHAT_MESSAGES } from "../actions/SocketActions";

export enum UpdateType {
  NEW_MESSAGE = "NEW_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  READ_MESSAGE = "READ_MESSAGE",
  LOAD_HISTORY_MESSAGES = "LOAD_HISTORY_MESSAGES",
  EMPTY_CACHE = "EMPTY_CACHE",
}

export interface ChatState {
  peerToMe: Record<string, ChatMessageRaw[]>;
  meToPeer: Record<string, ChatMessageRaw[]>;
  updateType: UpdateType;
}

const initialState: ChatState = {
  peerToMe: {},
  meToPeer: {},
  updateType: null,
};

export function chatReducer(state = initialState, action) {
  switch (action.type) {
    case ON_NEW_CHAT_MESSAGE: {
      const { peerId, message, isMine } = action.payload;
      const messageMap = new Map(state.peerToMe[peerId]?.map((msg) => [msg.id, msg]) || []);

      if (!isMine) {
        messageMap.set(message.id, message);
        return {
          ...state,
          updateType: UpdateType.NEW_MESSAGE,
          peerToMe: { ...state.peerToMe, [peerId]: Array.from(messageMap.values()) },
        };
      } else {
        const messageMap = new Map(state.meToPeer[peerId]?.map((msg) => [msg.id, msg]) || []);
        messageMap.set(message.id, message);
        return {
          ...state,
          updateType: UpdateType.NEW_MESSAGE,
          meToPeer: { ...state.meToPeer, [peerId]: Array.from(messageMap.values()) },
        };
      }
    }

    case ON_HISTORY_CHAT_MESSAGES: {
      const { mineMessages, peerMessages } = action.payload;

      const _updatedMeToPeer = new Map([
        ...(state.meToPeer[mineMessages.id] || []).map((msg) => [msg.id, msg] || []),
        ...mineMessages.messages.map((msg) => [msg.id, msg]),
      ]);
      const _updatedPeerToMe = new Map([
        ...(state.peerToMe[peerMessages.id] || []).map((msg) => [msg.id, msg]),
        ...peerMessages.messages.map((msg) => [msg.id, msg]),
      ]);

      return {
        ...state,
        updateType: UpdateType.LOAD_HISTORY_MESSAGES,
        meToPeer: { ...state.meToPeer, [mineMessages.id]: Array.from(_updatedMeToPeer.values()) },
        peerToMe: { ...state.peerToMe, [peerMessages.id]: Array.from(_updatedPeerToMe.values()) },
      };
    }

    case ON_REMOVE_CHAT_MESSAGES: {
      const { id, deletionStatus, peerId } = action.payload;

      const updatedPeerToMe = state.peerToMe[peerId] ? [...state.peerToMe[peerId]] : [];
      const updatedMeToPeer = state.meToPeer[peerId] ? [...state.meToPeer[peerId]] : [];

      const makeMessageDeleted = (messages) =>
        messages.map((msg: ChatMessageRaw) => {
          if (msg.id === id) {
            return { ...msg, status: deletionStatus, attachments: [] };
          }
          return msg;
        });

      const newPeerToMe = makeMessageDeleted(updatedPeerToMe);
      const newMeToPeer = makeMessageDeleted(updatedMeToPeer);

      return {
        ...state,
        updateType: UpdateType.DELETE_MESSAGE,
        peerToMe: { ...state.peerToMe, [peerId]: newPeerToMe },
        meToPeer: { ...state.meToPeer, [peerId]: newMeToPeer },
      };
    }

    case ON_READ_CHAT_MESSAGES: {
      const { ids, peerId } = action.payload;

      const updatedPeerToMeR = state.peerToMe[peerId] ? [...state.peerToMe[peerId]] : [];
      const updatedMeToPeerR = state.meToPeer[peerId] ? [...state.meToPeer[peerId]] : [];

      const makeMessageReaded = (messages) => messages.map((msg: ChatMessageRaw) => (ids.includes(msg.id) ? { ...msg, read: true } : msg));

      const newPeerToMeR = makeMessageReaded(updatedPeerToMeR);
      const newMeToPeerR = makeMessageReaded(updatedMeToPeerR);

      return {
        ...state,
        updateType: UpdateType.READ_MESSAGE,
        peerToMe: { ...state.peerToMe, [peerId]: newPeerToMeR },
        meToPeer: { ...state.meToPeer, [peerId]: newMeToPeerR },
      };
    }

    case ON_EMPTY_CHAT_CACHE: {
      const { peerId } = action.payload;

      return {
        ...state,
        updateType: UpdateType.READ_MESSAGE,
        peerToMe: { ...state.peerToMe, [peerId]: [] },
        meToPeer: { ...state.meToPeer, [peerId]: [] },
      };
    }
    default:
      return state;
  }
}
