import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import ContentLoader from "../../../../presentational/Loaders/ContentLoader/ContentLoader";
import ProposalCard from "../../../../presentational/Cards/ProposalCard/ProposalCard";
import ArrowBack from "../../../../presentational/Controls/ArrowBack/ArrowBack";
import { ProposalInterface } from "../../../../../types/Proposal.interface";
import { showErrorToast } from "../../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import ProposalService from "../../../../../services/proposalService";
import { ERR_MSG } from "../../../../../services/api";
import "./PersonalProposal.scss";

const PersonalProposal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { proposalId, userId } =
    useParams<{ proposalId: string; userId: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [proposal, setProposal] = useState<ProposalInterface>(null);

  const initProposal = () => {
    setLoading(true);
    ProposalService.getById(proposalId)
      .then(({ data, message, status }) => {
        if (ApiStatusCode.OK === status) {
          setProposal(data);
          return;
        } else {
          dispatch(showErrorToast(message));
        }
      })
      .catch(() => dispatch(showErrorToast(ERR_MSG)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    initProposal();
  }, [proposalId]);

  return (
    <div className="personal-proposal white-block">
      <ArrowBack
        text="Все идеи"
        onClick={() =>
          history.push(
            userId ? `/users/user/proposals/${userId}` : "/profile/ideas"
          )
        }
      />

      <ContentLoader isLoading={isLoading}>
        {proposal && (
          <ProposalCard
            proposal={proposal}
            key={proposal?.id}
          />
        )}
      </ContentLoader>
    </div>
  );
};

export default PersonalProposal;
