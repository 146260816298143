import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { SudirService } from "../src/services/sudirService";
import App from "./App";
import { WebSocketProvider } from "./components/containers/Wrappers/WebSocketProvider/WebSocketProvider";
import EventsTrackContainer from "./components/containers/EventsTrack/EventsTrackContainer";
import { eventsTrackConfig } from "./utils/eventsTrack";
import SudirSessionError from "./components/containers/Wrappers/SudirSessionError/SudirSessionError";
import { mapFrontToBackDomains } from "./utils/constants/env";

const sudirService = new SudirService();
export const AppContext = React.createContext({ sudirService });

const wsUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080/ws"
    : `${
        mapFrontToBackDomains[window.location.host]
          ? `${window.location.protocol}//${mapFrontToBackDomains[window.location.host]}`
          : window.location.origin
      }/ws`;

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <AppContext.Provider value={{ sudirService }}>
        <WebSocketProvider url={wsUrl}>
          <ToastProvider>
            <EventsTrackContainer config={eventsTrackConfig}>
              <SudirSessionError>
                <App />
              </SudirSessionError>
            </EventsTrackContainer>
          </ToastProvider>
        </WebSocketProvider>
      </AppContext.Provider>
    </Router>
  </Provider>
);

export default Root;
