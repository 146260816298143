export interface VotingPageParams {
  stageId: string;
}

export enum ProposalTypeEnum {
  PENDING = "PENDING",
  VOTED = "VOTED",
  SKIPPED = "SKIPPED",
  MINE = "MINE",
}

export interface ProposalsRequestParams {
  stageId: string;
  type: ProposalTypeEnum;
  page?: number;
  size?: number;
  sort?: string[];
  filterItemValue?: string;
}

export interface VotingParams {
  answers: { [id: string]: string };
  proposalId: string;
  stageId: string;
}

export interface SkippingVoteParams {
  proposalId: string;
  skipped: boolean;
  stageId: string;
}
