import { FC, useEffect, useState } from "react";
import CommonService, { Band } from "../../../../services/commonService";
import useIsPhone from "../../../../utils/hooks/useIsPhone";
import useIsTablet from "../../../../utils/hooks/useIsTablet";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ApiStatusCode } from "../../../../types/Common.interface";
import "./BannerLine.scss";

SwiperCore.use([Autoplay]);

const defaultTime = 7;

const BannerLine: FC = () => {
  const isTablet = useIsTablet();
  const isPhone = useIsPhone();
  const [lines, setLines] = useState<Band[]>([]);
  const [duration, setDuration] = useState(defaultTime);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();

  useEffect(() => {
    CommonService.getBands()
      .then(({ status, data, message }) => {
        if (status === ApiStatusCode.OK) {
          if (data.duration) {
            setDuration(data.duration);
          }
          setLines(data.bands);
        } else {
          console.error(message);
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (swiperInstance) {
      if (lines.length > 1) {
        swiperInstance.autoplay.start();
      } else {
        swiperInstance.autoplay.stop();
      }
    }
  }, [lines, swiperInstance]);

  const getLineImgUrl = (line: Band) => {
    return `/uploads/get/${isPhone ? line.phoneAttachmentId : isTablet ? line.tabletAttachmentId : line.desktopAttachmentId}`;
  };

  return (
    lines.length > 0 && (
      <div className="banner-line">
        <div className="banner-line__inner">
          <Swiper
            autoplay={{ delay: duration * 1000, waitForTransition: false }}
            slidesPerView={1}
            loop
            allowTouchMove={false}
            onSwiper={setSwiperInstance}
            speed={1000}
          >
            {lines.map((line, i) => (
              <SwiperSlide key={i}>
                <div className="banner-line__slide">
                  <a
                    className={line.link ? "pointer" : "cursor-default"}
                    href={line.link || ""}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (!line.link) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <img src={getLineImgUrl(line)} alt="" />
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    )
  );
};

export default BannerLine;
