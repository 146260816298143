import Expander from "../../../../presentational/Expander/Expander";
import { projectCurrentSelector } from "../../../../../store/selectors/project";
import { useSelector } from "react-redux";
import "./SelectionCriteria.scss";

export function SelectionCriteria() {
  const project = useSelector(projectCurrentSelector);

  return (
    <Expander
      className="selection-page__block-indent selection-page__expander"
      title="Прочитайте критерии отбора идей"
      titleIcon="ui-icon-warning"
      hiddenElement={<span className="selection-criteria" dangerouslySetInnerHTML={{ __html: project.criteria }} />}
    />
  );
}
