export interface Node {
  title: string;
}

export interface CellFragment {
  titleOfRow: string;
  stage: any;
  percentage: number;
  title: string;
  color: string;
  priority: number;
  start: string;
  finish: string;
}

export enum SizeType {
  CELL_WIDTH = "CELL_WIDTH",
  CELL_MARGIN_TOP = "CELL_MARGIN_TOP",
  CELL_MARGIN_BOT = "CELL_MARGIN_BOT",
}
