import { Fragment, useMemo } from "react";
import { GroupsProps } from "./Groups.interface";
import "./Groups.scss";

const Groups = <T extends { id: number | string; [K: string]: any }>({ items, bindTo, renderItem }: GroupsProps<T>) => {
  const itemsGrouped = useMemo(() => {
    const copy = items.slice();
    copy.sort((a, b) => a[bindTo].localeCompare(b[bindTo]));
    const res: Record<string, T[]> = {};
    copy.forEach((item) => {
      const firstCharacter = item[bindTo].charAt(0);
      const key = /^([A-zА-яёЁ])$/.test(firstCharacter) ? firstCharacter.toUpperCase() : "*";
      if (!res[key]) {
        res[key] = [];
      }
      res[key].push(item);
    });

    return Object.entries(res);
  }, [items]);

  return (
    <div className="groups">
      {itemsGrouped.map(([key, items]) => (
        <div key={key} className="groups__row">
          <div className="groups__row__key">{key}</div>
          <div className="groups__row__items">
            {items.map((item) => (
              <Fragment key={item.id}>{renderItem(item)}</Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Groups;
