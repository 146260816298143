import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Textarea } from "@crowd/ui-kit";

import {
  showErrorToast,
  showSuccessToast,
} from "../../../../store/actions/ToastActions";
import WarningService, { IWarning } from "../../../../services/warningService";
import { ApiStatusCode } from "../../../../types/Common.interface";
import Tabs, { Tab } from "../../../presentational/Tabs/Tabs";
import { IBaseResponse } from "../../../../App.interface";
import WarningTable from "./WarningTable/WarningTable";
import WarningItems from "./WarningItem/WarningItems";
import "./WarningModal.scss";

export interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  onSuccess?: (resp: IBaseResponse<string>) => void;
}

enum WarnModalTab {
  NEW = "NEW",
  PREV = "PREV",
}

const tabs: Tab[] = [
  { id: WarnModalTab.NEW, name: "Новое" },
  { id: WarnModalTab.PREV, name: "Предыдущие" },
];

const MIN_LEN = 1;
const MAX_LEN = 800;

const WarningModal = ({
  isOpen,
  onClose,
  userId,
  onSuccess,
}: WarningModalProps) => {
  const [warning, setWarning] = useState<string>("");
  const [prevWarnings, setPrevWarnings] = useState<IWarning[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [currentTabId, setCurrentTabId] = useState<WarnModalTab>(
    WarnModalTab.NEW
  );
  const TextareaWrapRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const switchTab = (tabId: WarnModalTab): void => {
    setCurrentTabId(tabId);

    if (tabId === WarnModalTab.NEW) {
      focusTextarea();
    }
  };

  const addWarning = () => {
    setSubmitted(true);

    if (warning.length >= MIN_LEN && warning.length <= MAX_LEN) {
      WarningService.addWarning({
        text: warning,
        userId,
      }).then((resp) => {
        if (resp.status === ApiStatusCode.OK) {
          onSuccess && onSuccess(resp);
          onClose();
          dispatch(
            showSuccessToast("Предупреждение участнику успешно отправлено.")
          );
          return;
        }

        dispatch(showErrorToast(resp.message));
        console.warn(resp.message);
      });
    }
  };

  const loadWarning = () => {
    WarningService.getWarnings(userId).then((resp) => {
      if (resp.status === ApiStatusCode.OK) {
        setPrevWarnings(resp.data);
      } else {
        console.warn(resp.message);
      }
    });
  };

  const focusTextarea = () => {
    setTimeout(() => {
      TextareaWrapRef?.current?.querySelector("textarea").focus();
    });
  };

  useEffect(() => {
    loadWarning();
    focusTextarea();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classNames={"warning-modal"}
      adjustPaddings={[".app-wrapper", ".cr-header"]}
      adjustMargins={[".scroll-top-button"]}
    >
      <Fragment key={"header"}>Предупреждение участнику</Fragment>

      <Fragment key={"body"}>
        <Tabs
          currentTabId={currentTabId}
          tabs={tabs}
          onTabClick={({ id }) => {
            switchTab(id);
          }}
          isUseMobileTab={false}
        />

        {WarnModalTab.NEW === currentTabId && (
          <div ref={TextareaWrapRef}>
            <Textarea
              value={warning}
              onChange={setWarning}
              placeholder={"Текст предупреждения"}
              hint={`Не более ${MAX_LEN} символов`}
              maxLength={MAX_LEN}
              shouldValidate={true}
              validateRules={{
                notEmpty: true,
                maxLength: MAX_LEN,
              }}
              showError={submitted}
            />
          </div>
        )}

        {WarnModalTab.PREV === currentTabId && (
          <>
            <WarningTable warnings={prevWarnings} />
            <WarningItems warnings={prevWarnings} />
          </>
        )}
      </Fragment>

      <Fragment key={"footer"}>
        {WarnModalTab.NEW === currentTabId && (
          <>
            <Button
              text={"Отменить"}
              type={"outlined-grey"}
              size={"m"}
              onClick={onClose}
            />

            <Button
              text={"Отправить"}
              type={"filled"}
              size={"m"}
              isLoading={false}
              onClick={addWarning}
            />
          </>
        )}

        {WarnModalTab.PREV === currentTabId && (
          <Button type="outlined" text="Закрыть" size={"m"} onClick={onClose} />
        )}
      </Fragment>
    </Modal>
  );
};

export default WarningModal;
