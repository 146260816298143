import { Button } from "@crowd/ui-kit";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectService from "../../../../../services/projectService";
import ProposalService from "../../../../../services/proposalService";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { SelectionFinishConditions } from "../../../../../types/Proposal.interface";
import { StagePermissionsString } from "../../../../../types/Stage.interface";
import { SelectionInfoProps } from "./SelectionInfo.interface";
import "./SelectionInfo.scss";
import { stageSelector } from "../../../../../store/selectors/stage";
import { getAllStages } from "../../../../../store/actions/StageActions";
import { useParams } from "react-router-dom";
import { useIsMobile, useIsShortDesktop } from "../../../../../utils/hooks/useMedia";
import Popup from "../../../../presentational/Popup/Popup";
import { EPopupSide } from "../../../../presentational/Popup/Popup.constants";
import cn from "classnames";
import { selectionStopStage } from "../../../../../store/actions/SelectionActions";

const availablePermissions: StagePermissionsString[] = ["STAGE_STOP", "STAGE_VIEW_FINISH_CONDITIONS", "STAGE_CONFIRM_FINISH"];

const SelectionInfo: FC<SelectionInfoProps> = () => {
  const { stageId } = useParams<{ stageId: string }>();
  const isMobile = useIsMobile();
  const isShortDesktop = useIsShortDesktop();

  const dispatch = useDispatch();

  const stage = useSelector(stageSelector);

  const [conditions, setConditions] = useState<SelectionFinishConditions>();
  const [confirmedFinish, setConfirmedFinish] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const canRender = useMemo(
    () => stage && !!stage.permissions?.filter((p) => availablePermissions.includes(p)).length,
    [stage?.permissions]
  );

  useEffect(() => {
    if (!stage) {
      dispatch(getAllStages(stageId));
    }
  }, [stage]);

  useEffect(() => {
    stage && ProposalService.getStageFinishConditions(stage.id).then((data) => setConditions(data.data));
  }, [stage]);

  const stopStage = () => {
    dispatch(selectionStopStage());
  };

  const confirmFinish = async () => {
    try {
      const { status, message } = await ProjectService.confirmFinishStage(stage.id);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }
      setConfirmedFinish(true);
      dispatch(getAllStages(stageId));
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    }
  };

  const closePopup = () => setIsPopupOpen(false);

  const icon = (
    <i
      className={cn("ui-icon ui-icon-sports_score_black_24dp selection-info__icon", {
        "selection-info__icon--active": isPopupOpen,
      })}
      onClick={() => setIsPopupOpen((prev) => !prev)}
    />
  );

  const body = stage && (
    <div className="selection-info">
      <div className="selection-info__head">
        Завершение работ
        {!isMobile && !isShortDesktop && icon}
      </div>

      <div className="selection-info__body">
        {stage.permissions.includes("STAGE_STOP") ? (
          <>
            Главный эксперт подтвердил завершение работ на этапе. Теперь вы можете его завершить.
            <Button type="filled" size="m" text="Завершить этап" onClick={stopStage} />
          </>
        ) : stage.finishConfirmed ? (
          <>Вы подтвердили завершение работ на этапе</>
        ) : (
          stage.permissions.includes("STAGE_VIEW_FINISH_CONDITIONS") &&
          !!conditions && (
            <div className="selection-info__criteria">
              <div>
                <strong>Критерии:</strong>
              </div>
              <div>
                <span>Связанный этап генерации завершился</span>
                <i className={`ui-icon ${conditions.generationFinished ? "ui-icon-done-symbol" : "ui-icon-close"}`} />
              </div>
              <div>
                <span>Все группы финализированы</span>
                <i className={`ui-icon ${conditions.allFinalized && conditions.hasAccepted ? "ui-icon-done-symbol" : "ui-icon-close"}`} />
              </div>
              <div>
                <span>Все идеи обработаны</span>
                <i
                  className={`ui-icon ${conditions.allExpertsNotified && conditions.hasAccepted ? "ui-icon-done-symbol" : "ui-icon-close"}`}
                />
              </div>
            </div>
          )
        )}
      </div>

      {stage.permissions.includes("STAGE_CONFIRM_FINISH") && (
        <div className="selection-info__footer">
          {!confirmedFinish ? (
            <Button type="filled" size="m" text="Подтвердить завершение" onClick={confirmFinish} />
          ) : (
            <>Вы подтвердили завершение этапа.</>
          )}
        </div>
      )}
    </div>
  );

  if (!canRender) return null;

  return canRender && (isMobile || isShortDesktop) ? (
    <Popup
      body={body}
      isOpen={isPopupOpen}
      onClose={closePopup}
      side={isMobile ? EPopupSide.CENTER : EPopupSide.RIGHT}
      className="selection-info__popup"
    >
      {icon}
    </Popup>
  ) : (
    body
  );
};

export default SelectionInfo;
