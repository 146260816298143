import { PagingParameters } from "../types/Common.interface";
import { DiscussionTypeEnum } from "../types/Personal.interface";
import { API, httpParamsSerialize } from "./api";

const baseUrl = "/api/personal";

export interface CommentsQueryParams extends PagingParameters {
  type?: DiscussionTypeEnum;
  from?: string;
  to?: string;
}

const PersonalService = {
  getComments: (params?: CommentsQueryParams) => {
    // Получение списка комментариев текущего пользователя.
    return API.get(`${baseUrl}/comments/get?${httpParamsSerialize(params)}`).then(({ data }) => {
      return data;
    });
  },

  getCommentsCount: (params?: PagingParameters) => {
    return API.get(`${baseUrl}/comments/count`).then(({ data }) => {
      return data;
    });
  },

  getFavoriteDiscussions: (params?: PagingParameters) => {
    // Возвращает список дискуссий, на которые подписан пользователь.
    return API.get(`${baseUrl}/discussions/favorite?${httpParamsSerialize(params)}`).then(({ data }) => {
      return data;
    });
  },

  getAnswersDiscussions: (params?: PagingParameters) => {
    // Получение списка ответов на комментарии пользователя.
    return API.get(`${baseUrl}/discussions/get?${httpParamsSerialize(params)}`).then((data) => {
      return data?.data;
    });
  },

  getProposals: (stageId?) => {
    return API.get(`${baseUrl}/proposals/get${stageId ? "?stageId=" + stageId : ""}`).then(({ data }) => data);
  },

  getProposalsCount: () => {
    return API.get(`${baseUrl}/proposals/count`).then((data) => data?.data);
  },

  getAnswersCount: () => {
    return API.get(`${baseUrl}/discussions/count`).then((data) => data?.data);
  },

  getPosts: (query) => {
    return API.get(`${baseUrl}/posts/get?${httpParamsSerialize(query)}`).then(({ data }) => data);
  },

  getPostsCount: () => {
    return API.get(`${baseUrl}/posts/count`).then(({ data }) => data);
  },

  getFavoritePosts: (query) => {
    let url = `${baseUrl}/discussions/favorite?${httpParamsSerialize(query)}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  getFavoriteCount: () => {
    let url = `${baseUrl}/discussions/favorite/count`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  markAllRead: () => {
    return API.post(`${baseUrl}/discussions/readAll`).then((data) => {
      return data;
    });
  },

  getEditedHistory: (proposalId) => {
    let url = `${baseUrl}/proposals/editedHistory?proposalId=${proposalId}`;
    return API.get(url).then(({ data }) => {
      return data;
    });
  },
};

export default PersonalService;
