import React, { useCallback, useEffect, useRef, useState } from "react";
import { ChatMessage, MessageGroup } from "../chat.types";
import MessageBubble, { MessageRemoveType } from "../MessageBubble/MessageBubble";
import { groupMessagesByDate } from "../chat.utils";
import { ElementObserver } from "../../../../../../presentational/ElementObserver/ElementObserver";
import { ElementObserverItem } from "../../../../../../presentational/ElementObserver/ElementObserverItem/ElementObserverItem";

import "./MessageViewWindow.scss";
import { debounce, throttle } from "../../../../../../../utils";
import { Loader } from "@crowd/ui-kit";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../types/State.interface";

export interface MessageWindowController {
  scrollToBottom: () => void;
  scrollToMessageById: (id: string) => void;
}

interface MessageViewWindowProps {
  messageList: ChatMessage[];
  unreadMessage: ChatMessage;
  onInit: (controller: MessageWindowController) => void;
  onScroll?: (e) => void;
  isLoading: boolean;
  onRemove?: (message: ChatMessage, type: MessageRemoveType) => void;
  onRead?: (messages: ChatMessage[]) => void;
}

const MessageViewWindow: React.FC<MessageViewWindowProps> = ({
  messageList,
  unreadMessage,
  isLoading,
  onInit,
  onScroll,
  onRemove,
  onRead,
}) => {
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const messageWindowRef = useRef(null);
  const batchedMessagesRef = useRef({});
  const timeoutRef = useRef(null);

  useEffect(() => {
    onInit({
      scrollToBottom,
      scrollToMessageById,
    });
  }, []);

  const batchMessages = (message: ChatMessage) => {
    if (currentUser.id !== message.sender.id && !message.read && !batchedMessagesRef.current[message.id]) {
      batchedMessagesRef.current[message.id] = message;
    }
  };

  const sendReadMessagesBatch = () => {
    if (Object.keys(batchedMessagesRef.current).length > 0) {
      onRead(Object.values(batchedMessagesRef.current));
      batchedMessagesRef.current = {};
    }
  };

  const scrollToBottom = () => {
    messageWindowRef.current.scrollTo(0, messageWindowRef.current.scrollHeight);
  };

  const scrollToMessageById = (id) => {
    const messageElement = document.querySelector(`[data-id="${id}"]`);
    if (messageElement) {
      const messageViewRect = messageWindowRef.current.getBoundingClientRect();
      const messageElementRect = messageElement.getBoundingClientRect();
      const relativeTop = messageElementRect.top - messageViewRect.top + messageWindowRef.current.scrollTop;
      const scrollPosition = relativeTop - messageViewRect.height / 2;
      messageWindowRef.current.scrollTop = scrollPosition;
    }
  };

  const renderUnreadMessagesIndicator = (message) => {
    if (!unreadMessage || unreadMessage.id !== message.id) return;
    return <div className="message-view__unread">Непрочитанные сообщения</div>;
  };

  const debouncedSendReadMessagesBatch = debounce(sendReadMessagesBatch, 2000);

  const throttledOnScroll = useCallback(
    throttle((e) => {
      onScroll(e);
      debouncedSendReadMessagesBatch();
    }, 10),
    [onScroll]
  );

  const handleIntersect = useCallback(
    (message: ChatMessage) => {
      batchMessages(message);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(sendReadMessagesBatch, 2000);
    },
    [batchMessages, sendReadMessagesBatch]
  );

  return (
    <div className="message-view" ref={messageWindowRef} onScroll={throttledOnScroll}>
      {isLoading && <Loader />}
      {groupMessagesByDate(messageList).map((messageGroup: MessageGroup) => (
        <div className="message-view__group" key={messageGroup.date}>
          <div className="message-view__date">{messageGroup.label}</div>
          <ElementObserver delayBeforeNotify={1000} autoUnobserve={true} onIntersect={handleIntersect}>
            {messageGroup.messages.map((message) => {
              return (
                <ElementObserverItem data={message} id={message.id} key={message.id}>
                  {renderUnreadMessagesIndicator(message)}
                  <MessageBubble message={message} onRemove={onRemove} />
                </ElementObserverItem>
              );
            })}
          </ElementObserver>
        </div>
      ))}
    </div>
  );
};

export default MessageViewWindow;
