import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PersonalService from "../../../../services/personalService";
import ProposalService from "../../../../services/proposalService";
import ProposalCard from "../../../presentational/Cards/ProposalCard/ProposalCard";
import { moduleCards } from "../Profile.constants";
import { ModuleTypeEnum } from "../Profile.interface";
import ProfileSubContent from "../ProfileSubContent/ProfileSubContent";
import { mapTypeToBackArrow, mapTypeToEmptyText } from "./MyIdeas.constants";
import { showErrorToast } from "../../../../store/actions/ToastActions";
import { getUrlParam, queryBuilder } from "../../../../utils/urlUtils";
import "./MyIdeas.scss";
import { getProfileTab } from "../Profile.helper";
import useQuery from "../../../../utils/hooks/useQuery";
import { widenPage, lessenPage } from "../../../../store/actions/LayoutActions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowBack from "../../../presentational/Controls/ArrowBack/ArrowBack";
import { ProposalTypeEnum } from "../../../../types/Proposal.interface";

const MyIdeas = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const params = useParams();

  const [dataCount, setDataCount] = useState(null);
  const [tab, setTab] = useState(getProfileTab(query.type, getTabs()));
  const [proposals, setProposals] = useState(null);
  const [page, setPage] = useState(getPageFromUrl("page"));
  const [paging, setPaging] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDataCount();
  }, []);

  useEffect(() => {
    tab && loadProposals();
  }, [tab, page]);

  const loadDataCount = () => {
    PersonalService.getProposalsCount()
      .then((response) => {
        setDataCount(response.data);
      })
      .catch((err) => dispatch(showErrorToast(err.message)));
  };

  const loadProposals = () => {
    setLoading(true);
    ProposalService.getPersonalProposals(null, tab.type, page, 10)
      .then((response) => {
        setProposals(response.data);
        setPaging(response.paging);
      })
      .catch((err) => dispatch(showErrorToast(err.message)))
      .finally(() => setLoading(false));
  };

  function getTabs() {
    const tabs = moduleCards.find((m) => m.type === ModuleTypeEnum.IDEAS).tabs;

    if (dataCount) {
      return tabs.map((tab) => {
        const newTab = { ...tab };
        newTab.count = dataCount[newTab.type.toLowerCase()];
        return newTab;
      });
    }

    return tabs;
  }

  function getPageFromUrl(pageName) {
    return Math.max(
      Number(getUrlParam(pageName, window.location.search)) - 1,
      0
    );
  }


  // RENDER
  const renderList = () => {
    return (
      <div className="my-ideas__list">
        {proposals?.map((proposal, idx) => (
          <ProposalCard
            proposal={proposal}
            key={proposal.id}
            index={idx}
            onDeleteDraftSuccess={() => {
              loadProposals();
              loadDataCount();
            }}
            titleLink={`/profile/ideas/${proposal.id}`}
            showHistory={tab.type === ProposalTypeEnum.ACCEPTED || tab.type === ProposalTypeEnum.SUBMITTED || tab.type === ProposalTypeEnum.REJECTED }
            showRejectReason={proposal.status === ProposalTypeEnum.REJECTED || proposal.status === ProposalTypeEnum.REMOVED}
            canEdit={proposal.status === ProposalTypeEnum.REJECTED || proposal.status === ProposalTypeEnum.DRAFT}
            canDelete={proposal.status === ProposalTypeEnum.DRAFT}
          />
        ))}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="my-ideas__empty empty-content">
        {mapTypeToEmptyText[tab?.type]}
      </div>
    );
  };


  const getProfileSubContentClassList = () => {
    let classList = "";
    return classList;
  };

  return (
    <div className="my-ideas">
      <ProfileSubContent
        title="Мои идеи и их статусы"
        tabs={getTabs()}
        onTabChange={setTab}
        paging={paging}
        isLoading={loading}
        onPageChange={(page) => setPage(page)}
        classList={getProfileSubContentClassList()}
      >
        <div className="my-ideas__content">
          {proposals?.length ? renderList() : renderEmpty()}
        </div>
      </ProfileSubContent>
    </div>
  );
};

export default MyIdeas;
