import { FileMimeType } from "../types/Common.interface";

export function getFileNameToFileExtension(fileName: string): FileMimeType | undefined {
  const extension = fileName.split(".").pop()?.toUpperCase();
  switch (extension) {
    case "JPEG":
      return FileMimeType.JPEG;
    case "JFIF":
      return FileMimeType.JFIF;
    case "JPG":
      return FileMimeType.JPG;
    case "PNG":
      return FileMimeType.PNG;
    case "WEBP":
      return FileMimeType.WEBP;
    case "PDF":
      return FileMimeType.PDF;
    default:
      return undefined;
  }
}
