import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { rootReducer } from "./reducers";

let middleware: any = [];
if (process.env.NODE_ENV === "development") {
  middleware = [thunk];
} else {
  middleware = [thunk];
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
