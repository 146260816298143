import { FC, useLayoutEffect, useRef, useState } from "react";
import { useOuterClick } from "../../../utils/hooks/useOnOutsideClick";
import { PopupProps } from "./Popup.interface";
import cn from "classnames";
import "./Popup.scss";
import { EPopupSide } from "./Popup.constants";
import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";

const Popup: FC<PopupProps> = ({ isOpen, onClose, header, body, footer, children, className, side: defaultSide }) => {
  const ref = useOuterClick<HTMLDivElement>(onClose);
  const popupRef = useRef<HTMLDivElement>();
  const [side, setSide] = useState<EPopupSide>(defaultSide);

  useLayoutEffect(() => {
    if (isOpen) {
      const { width } = popupRef.current.getBoundingClientRect();
      const { left } = ref.current.getBoundingClientRect();
      setSide(() => (defaultSide !== undefined ? defaultSide : width + left < window.innerWidth ? EPopupSide.LEFT : EPopupSide.RIGHT));
    }
  }, [isOpen]);

  useUpdateEffect(() => {
    defaultSide !== undefined && setSide(defaultSide);
  }, [defaultSide]);

  return (
    <div ref={ref} className={cn("popup__wrapper", className, { "popup__wrapper-center": side === EPopupSide.CENTER })}>
      {children}
      {isOpen && (
        <div
          className={cn("popup", {
            popup_left: side === EPopupSide.LEFT,
            popup_right: side === EPopupSide.RIGHT,
            popup_center: side === EPopupSide.CENTER,
          })}
          ref={popupRef}
        >
          {header && <div className="popup__header">{header}</div>}
          <div className="popup__body">{body}</div>
          {footer && <div className="popup__footer">{footer}</div>}
        </div>
      )}
    </div>
  );
};

export default Popup;
