import React, { useEffect, useState } from "react";
import { User } from "../../../../../../../types/User.interface";
import UserAvatar from "../../../../../../presentational/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../../utils/constants/widthConstants";
import "./PeerPanel.scss";
import { getDisplayName } from "../../../../../../../utils/user.utils";
import { relativeTime } from "../../../../../../../utils";

interface PeerPanelProps {
  peer: User;
  onRemoveDialog: () => void;
  hasMessages: boolean;
}

const PeerPanel: React.FC<PeerPanelProps> = ({ peer, onRemoveDialog, hasMessages }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  return (
    <div className="peer-panel">
      <div className="peer-panel__back">
        <Link to={"/profile/mail"}>
          <span className="ui-icon ui-icon-arrow-back"></span>
        </Link>
      </div>
      <div className="peer-panel__user">
        <UserAvatar user={peer} size={isPhone ? "XS" : "M"} />
        <div className="peer-panel__info">
          <div className="peer-panel__name">{getDisplayName(peer)}</div>
          <div className="peer-panel__status">{peer.online ? "Онлайн" : (peer.lastActivityDate && relativeTime(peer.lastActivityDate)) || ""}</div>
        </div>
      </div>
      {hasMessages && (
        <div className="peer-panel__tools">     
          <button title="Удалить" className="ui-icon ui-icon-delete simple-button" onClick={onRemoveDialog}/>
        </div>
      )}
    </div>
  );
};

export default PeerPanel;
