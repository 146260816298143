import { Select } from "@crowd/ui-kit";
import { ComponentProps, FC, useEffect, useRef, useState } from "react";
import { setTrackIdToEl } from "../../../../../utils/eventsTrack";

const EventsTrackClickSelect: FC<ComponentProps<typeof Select>> = ({ onChangeListVisibility, ...otherProps }) => {
  const ref = useRef<Select>();
  const [isListVisible, setIsListVisible] = useState(false);

  useEffect(() => {
    const input = ref.current?.inputRef.current;
    const list = ref.current?.listRef.current;
    setTrackIdToEl(input, ["OPEN_LIST", isListVisible ? "BUTTON_CLICK_CLOSE" : "BUTTON_CLICK_OPEN"], "click", {
      replace: [otherProps.label || otherProps.placeholder],
      partial: true,
      rememberPrev: true,
    });
    if (isListVisible) {
      list?.children.forEach((item) => {
        setTrackIdToEl(item, ["OPEN_LIST", "EMPTY", "CLICK"], "click", {
          replace: [otherProps.label || otherProps.placeholder, item.innerText],
          partial: true,
          rememberPrev: true,
        });
      });
    }
  }, [isListVisible]);

  return (
    <Select
      ref={ref}
      onChangeListVisibility={(isVisible) => {
        onChangeListVisibility?.(isVisible);
        setIsListVisible(isVisible);
      }}
      {...otherProps}
    />
  );
};

export default EventsTrackClickSelect;
