import { MailTypeEnum } from "../../../types/Mail.interface";
import { BlogType, DiscussionTypeEnum } from "../../../types/Personal.interface";
import { ProposalTypeEnum } from "../../../types/Proposal.interface";
import { IModuleCard, ModuleTypeEnum } from "./Profile.interface";

export const moduleCards: IModuleCard[] = [
  {
    type: ModuleTypeEnum.IDEAS,
    title: "Мои идеи и их статусы",
    icon: "ui-thin-icon-idea ui-thin-icon",
    iconPhone: "ui-icon-idea",
    tabs: [
      {
        id: 0,
        type: ProposalTypeEnum.SUBMITTED,
        name: "На&nbsp;рассмо&shy;трении",
        iconClass: "ui-icon-review-idea",
        activeIconClass: "ui-icon-review-idea-filled",
      },
      {
        id: 1,
        type: ProposalTypeEnum.ACCEPTED,
        name: "Принятые",
        iconClass: "ui-icon-accepted-idea",
        activeIconClass: "ui-icon-accepted-idea-filled",
      },
      {
        id: 2,
        type: ProposalTypeEnum.REJECTED,
        name: "Отклонен&shy;ные",
        iconClass: "ui-icon-reject-idea",
        activeIconClass: "ui-icon-reject-idea-filled",
      },
      {
        id: 4,
        type: ProposalTypeEnum.DRAFT,
        name: "Черновики",
        iconClass: "ui-icon-idea-draft",
        activeIconClass: "ui-icon-idea-draft-filled",
      },
    ],
  },
  {
    type: ModuleTypeEnum.COMMENTS,
    title: "Мои комментарии",
    icon: "ui-thin-icon-comment ui-thin-icon",
    iconPhone: "ui-icon-comments",
    tabs: [
      {
        id: 0,
        type: DiscussionTypeEnum.ALL,
        name: "Все",
        iconClass: "ui-icon-inbox-all",
        activeIconClass: "ui-icon-inbox-all-filled",
      },
      {
        id: 1,
        type: DiscussionTypeEnum.STAGE,
        name: "Обсуждения",
        iconClass: "ui-icon-question-answer",
        activeIconClass: "ui-icon-question-answer-filled",
      },
      {
        id: 2,
        type: DiscussionTypeEnum.BLOG,
        name: "Блоги",
        iconClass: "ui-icon-blogs",
        activeIconClass: "ui-icon-blogs-filled",
      },
    ],
  },
  {
    type: ModuleTypeEnum.ANSWERS,
    title: "Ответы на мои комментарии",
    titlePhone: "Ответы",
    icon: "ui-thin-icon-comment-reply ui-thin-icon",
    iconPhone: "ui-icon-comment-reply",
    tabs: [
      {
        id: 0,
        type: DiscussionTypeEnum.ALL,
        name: "Все",
        iconClass: "ui-icon-inbox-all",
        activeIconClass: "ui-icon-inbox-all-filled",
      },
      {
        id: 1,
        type: DiscussionTypeEnum.STAGE,
        name: "Обсуждения",
        iconClass: "ui-icon-question-answer",
        activeIconClass: "ui-icon-question-answer-filled",
      },
      {
        id: 2,
        type: DiscussionTypeEnum.BLOG,
        name: "Блоги",
        iconClass: "ui-icon-blogs",
        activeIconClass: "ui-icon-blogs-filled",
      },
    ],
  },
  {
    type: ModuleTypeEnum.MAIL,
    title: "Личные сообщения",
    icon: "ui-thin-icon-mail ui-thin-icon",
    iconPhone: "ui-icon-mail",
    tabs: [
      {
        id: 0,
        type: MailTypeEnum.INBOX,
        name: "Входящие",
        iconClass: "ui-icon-inbox",
        activeIconClass: "ui-icon-inbox-filled",
      },
      {
        id: 1,
        type: MailTypeEnum.SENT,
        name: "Отправленные",
        iconClass: "ui-icon-send-message",
        activeIconClass: "ui-icon-send-message-filled",
      },
      {
        id: 2,
        type: MailTypeEnum.TRASH,
        name: "Удаленные",
        iconClass: "ui-icon-delete",
        activeIconClass: "ui-icon-delete-filled",
      },
      {
        id: 3,
        type: MailTypeEnum.DRAFTS,
        name: "Черновики",
        iconClass: "ui-icon-record",
      },
    ],
  },
  {
    type: ModuleTypeEnum.BLOG,
    title: "Записи в личном блоге",
    icon: "ui-thin-icon-record ui-thin-icon",
    iconPhone: "ui-icon-record",
    tabs: [
      {
        id: 0,
        type: BlogType.PUBLISHED,
        name: "Опубликованные посты",
        iconClass: "ui-icon-sent-post",
      },
      {
        id: 1,
        type: BlogType.DRAFT,
        name: "Черновики",
        iconClass: "ui-icon-record",
      },
    ],
  },
  {
    type: ModuleTypeEnum.FAVORITES,
    title: "Избранные посты",
    icon: "ui-thin-icon-favorite ui-thin-icon",
    iconPhone: "ui-icon-favorite",
    tabs: [
      {
        id: 0,
        type: DiscussionTypeEnum.ALL,
        name: "Все",
        iconClass: "ui-icon-inbox-all",
        activeIconClass: "ui-icon-inbox-all-filled",
      },
      {
        id: 1,
        type: DiscussionTypeEnum.STAGE,
        name: "Обсуждения",
        iconClass: "ui-icon-question-answer",
        activeIconClass: "ui-icon-question-answer-filled",
      },
      {
        id: 2,
        type: DiscussionTypeEnum.BLOG,
        name: "Блоги",
        iconClass: "ui-icon-blogs",
        activeIconClass: "ui-icon-blogs-filled",
      },
    ],
  },
  {
    type: ModuleTypeEnum.REPORTS,
    title: "Отчеты",
    icon: "ui-thin-icon-report ui-thin-icon",
    iconPhone: "ui-icon-clipboard-more",
    externalLink: "/personal/reports",
  },
  {
    type: ModuleTypeEnum.MANAGE,
    title: "Управление",
    icon: "ui-thin-icon-tune ui-thin-icon",
    iconPhone: "ui-icon-tune",
    externalLink: "/personal/manage/proposals",
  },
];

export const profilePaths = [
  "/profile/mail",
  "/profile/ideas",
  "/profile/answers",
  "/profile/comments",
  "/profile/blog",
  "/profile/favorites",
  "/profile/edit"
];

export const notificationCountPerRequest = 1000;
