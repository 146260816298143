import DayPickerInput from "react-day-picker/DayPickerInput";
import React from "react";

import { DatePickerBase } from "../PickerBase";
import "./PlainPicker.scss";

class PlainDatePicker extends DatePickerBase {
  private picker: any;
  class = "PlainDatePicker";

  constructor(picker) {
    super();

    this.picker = picker;
  }

  render() {
    return (
      <>
        <span className="PlainDatePicker__title">Дата</span>
        <span className="ui-icon ui-icon-date"></span>
        <DayPickerInput
          value={this.picker.props.date}
          onDayChange={(day) => {
            this.picker.props.onChange(day);
          }}
          dayPickerProps={{
            ...this.baseCalendarProps,
            disabledDays: { ...this.picker.props.disabledDays },
            modifiers: this.picker.props.modifiers && {
              ...this.picker.props.modifiers,
            },
            firstDayOfWeek: 1,
          }}
          inputProps={{
            readOnly: "readonly",
          }}
        />
      </>
    );
  }
}

export default PlainDatePicker;
