import React from "react";

import "./PersonalComment.scss";
import { Comment } from "../../../../../../types/Comment.interface";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import CommentService from "../../../../../../services/commentService";
import { stripHtml } from "../../../../../../utils";
import linkifyHtml from "linkifyjs/html";

interface PersonalCommentProps {
  comment: Comment;
}

const PersonalComment = ({ comment }: PersonalCommentProps) => {
  const isBlog = (): boolean => {
    return !comment.stageId;
  };

  const getSourcePageUrl = (): string => {
    if (isBlog()) {
      return `/users/user/blog/${comment?.discussionOwner?.id}`;
    }

    return `/discussion/${comment.stageId}/${comment.id}?navigate=true`;
  };

  const getText = (text) => {
    let _text = stripHtml(text);
    _text = CommentService.toHTMLQuote(_text);
    _text = linkifyHtml(_text, { target: "blank" });
    return _text;
  };

  return (
    <div className="personal-comment">
      <div className="personal-comment__title">
        <div className="personal-comment__title__type">
          <p>{dayjs(comment.date).format("DD.MM.YYYY HH:mm")}</p>
          <p>{isBlog() ? " в блоге" : " в обсуждении"}</p>
        </div>
        <Link
          className="personal-comment__title__link pointer"
          to={getSourcePageUrl()}
        >
          {comment.discussionTitle}
        </Link>
      </div>

      <div
        className="personal-comment__text"
        dangerouslySetInnerHTML={{
          __html: getText(comment?.rawText),
        }}
      ></div>

      {comment?.attachments?.length > 0 && (
        <div className="personal-comment__images">
          {comment.attachments.map((file, key) => (
            <img
              key={key}
              src={`/uploads/thumbnails/get/${file.id}`}
              alt={""}
            />
          ))}
        </div>
      )}

      <div className="personal-comment__type-label">
        <span
          className={
            isBlog()
              ? "personal-comment__type-label__blog"
              : "personal-comment__type-label__discussion"
          }
        >
          {isBlog() ? "БЛОГИ" : "ОБСУЖДЕНИЯ"}
        </span>
      </div>
    </div>
  );
};

export default PersonalComment;
