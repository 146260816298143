export const SHOW_SUCCESS_TOAST = "SHOW_SUCCESS_TOAST";
export const SHOW_ERROR_TOAST = "SHOW_ERROR_TOAST";
export const SHOW_WARNING_TOAST = "SHOW_WARNING_TOAST";

export const showSuccessToast =
  (text: string, autoDismiss: boolean = true) =>
  (dispatch) => {
    dispatch({ type: SHOW_SUCCESS_TOAST, payload: { text, autoDismiss } });
  };

export const showErrorToast =
  (text: string, autoDismiss: boolean = true) =>
  (dispatch) => {
    dispatch({ type: SHOW_ERROR_TOAST, payload: { text, autoDismiss } });
  };

export const showWarningToast =
  (text: string, autoDismiss: boolean = true) =>
  (dispatch) => {
    dispatch({ type: SHOW_WARNING_TOAST, payload: { text, autoDismiss } });
  };
